import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { adds } from "../../../actions/global/global";
import { Form, TextInput, NotEmpty, DateInput, DateTimeInput, RadioInput } from '@gferrand/react-forms';
import CalendarEventEnt from '../../../entities/CalendarEventEnt';
import { calendarEventOverlaps } from '../../../actions/calendarEvent/calendarEvent';
import { concludedAgreementOverlaps } from '../../../actions/agreement/agreement';
import { replacementOverlaps } from '../../../actions/replacements/replacements';
import CheckboxInput from '../../../components/form/CheckBoxInput';
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import DateUtil from '../../../util/DateUtil';
import moment from 'moment';


class DAddCalendarEventModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            availabilityOverlapError: null,
            calendarEventOverlapError: null,
            replacementOverlapError: null,
            loadingOverlap: false,
            days_available: []
        };

        this.buildCalendarEventForm();
    };

    buildCalendarEventForm() {
        var submitCbk = (data) => {
            var clonedData = { ...data };

            if (clonedData.eventType === "day") {
                clonedData.start_date = DateUtil.toNoon(clonedData.date);
                clonedData.end_date = DateUtil.toMidnight(clonedData.date);
            }

            clonedData.days_available = DateUtil.getDaysAvailableForEvent(clonedData.start_date, clonedData.end_date)

            delete clonedData.eventType;
            delete clonedData.date;

            this.props.onAdds("calendarEvent", clonedData, () => {
                this.props.toggle();

                new Noty({
                    type: "info",
                    layout: 'topRight',
                    theme: 'bootstrap-v4',
                    text: this.props.intl.formatMessage({ id: "CalendarEvent.Add.Success" }),
                    timeout: 6000,
                }).show();
            });
        };

        var changeCbk = (input) => {
            this.validateCalendarEventForm(input);

            if (input.name === "eventType") {
                if (input.value === "hours") {
                    this.calendarEventForm.hide("date");
                    this.calendarEventForm.show("start_date");
                    this.calendarEventForm.show("end_date");
                } else {
                    this.calendarEventForm.setValue('date', moment(this.props.startDate));
                    this.calendarEventForm.show("date");
                    this.calendarEventForm.hide("start_date");
                    this.calendarEventForm.hide("end_date");
                }
            }
        };

        var period = CalendarEventEnt.occasionalPeriod(this.props.startDate, this.props.endDate);

        var data = {
            date: new Date(),
            eventType: "hours",
            start_date: DateUtil.roundMinutes(period.start),
            end_date: DateUtil.roundMinutes(period.end)
        };

        this.calendarEventForm = new Form({
            name: "calendarEvent",
            inputs: [
                new RadioInput("eventType", "Event.Range",
                    [{ label: "Schedule", value: "hours" },
                    { label: "Full.Day", value: "day" }]
                    , [NotEmpty], { groupClassName: " ", className: "srounded mr-3 pl-4 pr-4 lowercase bg-blue text-white mb-1 mb-sm-0" }),

                new DateInput("date", "Date", [NotEmpty], { errorClassName: "react-date-picker-invalid" }),
                new DateTimeInput("start_date", "Start.Date", "fr", [NotEmpty], { timeConstraints: { minutes: { step: 15 } } }),
                new DateTimeInput("end_date", "End.Date", "fr", [NotEmpty], { timeConstraints: { minutes: { step: 15 } } }),
                new TextInput("title", "Title", undefined, [NotEmpty]),
                new TextInput("desc", "Description", 200, []),
                new CheckboxInput("prevent_availabilities", "This.Event.Prevents.Availabilties", [], { lClassName: "control-checkbox" }),
            ],
            submitCbk,
            changeCbk,
            data,
            options: { validateAtStart: false }
        });

        this.checkOverlap(data.start_date, data.end_date, data.type, data.prevent_availabilities, this.state.days_available);

        this.calendarEventForm.hide("date");
    };

    // ================================================================
    // ========================== VALIDATION ==========================
    // ================================================================
    checkOverlap = (start, end, type, preventAvailabilities, days_available) => {
        if(!start || !end) return;

		this.setState({ loadingOverlap: true });
        if (preventAvailabilities) {
            // Check caldendarEvents overlaps
            this.props.onCalendarEventOverlaps(start, end, days_available, type, (res) => {
                this.setState({ calendarEventOverlapError: res.overlaps, loadingOverlap: false });
            });

            this.props.onConcludedAgreementOverlaps(start, end, type, undefined, days_available, (res) => {
                this.setState({ availabilityOverlapError: res.overlaps, loadingOverlap: false });
            });
            this.props.onReplacementOverlaps(start, end, type, null, days_available, (res) => {
                this.setState({ replacementOverlapError: res.overlaps, loadingOverlap: false });
            });
        } else {
            this.setState({ calendarEventOverlapError: null, availabilityOverlapError: null, replacementOverlapError: null, loadingOverlap: false });
        }
    };

    validateCalendarEventForm(input) {
        const rawData = this.calendarEventForm.getRawData();

        var { name, value } = input;
        let days_available = Array.from(this.state.days_available);

        if (name === "start_date") {
            this.calendarEventForm.setValue("end_date", new Date(new Date(input.value).getTime() + (60 * 60 * 1000)));
            this.calendarEventForm.setError("end_date", null);
            days_available = DateUtil.getDaysAvailableForEvent(value, new Date(new Date(input.value).getTime() + (60 * 60 * 1000)));
            this.checkOverlap(value, new Date(new Date(input.value).getTime() + (60 * 60 * 1000)), rawData.type, rawData.prevent_availabilities, days_available);

        } else if (name === "end_date") {
            if (moment(rawData.startDate).isAfter(value)) {
                let startDate = new Date(new Date(input.value).getTime() - (60 * 60 * 1000));
                this.calendarEventForm.setValue("start_date", startDate);
                days_available = DateUtil.getDaysAvailableForEvent(startDate, value);
                this.checkOverlap(startDate, value, rawData.type, rawData.prevent_availabilities, days_available);
            } else {
                days_available = DateUtil.getDaysAvailableForEvent(rawData.start_date, value);
                this.checkOverlap(rawData.start_date, value, rawData.type, rawData.prevent_availabilities, days_available);
            }
        }

        if (name === "prevent_availabilities") {
            days_available = DateUtil.getDaysAvailableForEvent(rawData.start_date, rawData.end_date);
            this.checkOverlap(rawData.start_date, rawData.end_date, rawData.type, value, days_available);
        }

        if (name === "eventType") {
            if (value === 'day') {
                days_available = DateUtil.getDaysAvailableForEvent(this.props.startDate, this.props.startDate);
            }
        }

        if (rawData.eventType === 'day' && name === 'date') {
            days_available = DateUtil.getDaysAvailableForEvent(value, value);
        }

        this.setState({
            days_available
        })
    };

    render() {
        return (
            <React.Fragment>

                <Modal size="lg" isOpen={true} toggle={() => this.props.toggle()} className="simple-modal simple-modal-white srounded">

                    <ModalHeader toggle={() => this.props.toggle()}>
                    </ModalHeader>

                    <ModalBody className="pl-2 pr-2 pl-md-5 pr-md-5 pt-0">
                        <h4 className="w-100 uppercase text-center pb-4"><FormattedMessage id="Add.CalendarEvent" /></h4>

                        <div className="text-center mb-4">
                            Les événements sont uniquement visibles par vous
                        </div>

                        <div className="text-left">
                            {this.calendarEventForm.body()}
                        </div>

                        <div className="text-center text-danger">
                            <small>{(this.state.availabilityOverlapError) && <FormattedMessage id="Overlapping.Existing.Availability.Calendar" />}</small>
                        </div>

                        <div className="text-center text-danger">
                            <small>{(this.state.replacementOverlapError) && <FormattedMessage id="Overlapping.Existing.Availability.Calendar" />}</small>
                        </div>

                        <div className="text-center text-danger">
                            <small>{(this.state.calendarEventOverlapError) && <FormattedMessage id="Overlapping.Existing.CalendarEvent" />}</small>
                        </div>

                        <div className="text-center form-buttons w-100 mt-5 mb-4">
                            {this.calendarEventForm.submitButton("Add", {
                                disabled: (this.state.availabilityOverlapError || this.state.loadingOverlap || this.state.replacementOverlapError),
                                className: "srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green"
                            })}
                        </div>

                    </ModalBody>

                </Modal>

            </React.Fragment>
        );
    };
};

const mapStateToProps = state => {
    return {
        //
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAdds: (objName, obj, cbk) => dispatch(adds(objName, obj, cbk)),
        onCalendarEventOverlaps: (start, end, days_available, type, cbk) => calendarEventOverlaps(start, end, days_available, type, cbk),
        onConcludedAgreementOverlaps: (start, end, type, duration, days_available, cbk) => concludedAgreementOverlaps(start, end, type, duration, days_available, cbk),
        onReplacementOverlaps: (start, end, type, duration, days_available, cbk) => replacementOverlaps(start, end, type, duration, days_available, cbk),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DAddCalendarEventModal));