import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage } from 'react-intl';
import StringUtil from '../../../util/StringUtil';
import 'ladda/dist/ladda-themeless.min.css';

import ptsdImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_ECG.png';
import anxietyDisordersImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_GYNECO_02.png';
import personnalityDisordersImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_OSTEO.png';
import tdahImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_HOMEO_01.png';
import tocImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_HOMEO_SPORT.png';
import moodDisordersImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_ALLERGO.png';
import psychoticDisordersImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_URGENCE.png';


export default class PsychiatristTreatedPathologiesProfile extends Component {

    buildRoundElem = (characteristics, field, i18n, img) => {
        if (!i18n) i18n = StringUtil.capitalize(field);

        return (
            <div className="round-elem d-inline-block" style={{ opacity: (characteristics[field]) ? 1 : .4 }}>
                <div className="">
                    <img src={img} alt="" width="50" height="50" />
                </div>
                <div className="title mt-2"><FormattedMessage id={i18n} /></div>
            </div>
        );
    }

    render() {
        const { psychiatristCharacteristic } = this.props;

        return (
            <React.Fragment>

                <div className="d-inline-block mx-auto w-100">

                    {this.buildRoundElem(psychiatristCharacteristic, "ptsd", undefined, ptsdImg)}
                    {this.buildRoundElem(psychiatristCharacteristic, "anxiety_disorders", "Anxiety.Disorders", anxietyDisordersImg)}
                    {this.buildRoundElem(psychiatristCharacteristic, "personnality_disorders", "Personnality.Disorders", personnalityDisordersImg)}
                    {this.buildRoundElem(psychiatristCharacteristic, "tdah", undefined, tdahImg)}
                    {this.buildRoundElem(psychiatristCharacteristic, "toc", undefined, tocImg)}
                    {this.buildRoundElem(psychiatristCharacteristic, "mood_disorders", "Mood.Disorders", moodDisordersImg)}
                    {this.buildRoundElem(psychiatristCharacteristic, "psychotic_disorders", "Psychotic.Disorders", psychoticDisordersImg)}

                </div>

            </React.Fragment >
        );
    };

}