import APIUrl from "../APIUrl";
import axios from "axios";
import Logger from "../../util/Logger";

//! Version 0.0.1

//! Remember to change the version if any changes are made !

const extractUrl = (obj) => {
    let splittedUrl;
    let objName = obj;
    if (obj.indexOf("/") !== -1) {
        splittedUrl = objName.split("/");
        objName = splittedUrl[0];
        splittedUrl.shift();
    }

    return { objName: objName, params: splittedUrl };
};

// ================================================================================
// =================================== SINGLE =====================================
// ================================================================================

export const CLEAR = "CLEAR";
export const GET = "GET";
export const UPD = "UPD";
export const REMOVE = "REMOVE";

export function clearAction(objName) { return { type: CLEAR, objName: objName } }
export function getAction(objName, obj) { return { type: GET, objName: objName, obj: obj } }
export function updAction(objName, obj) { return { type: UPD, objName: objName, obj: obj } }
export function removeAction(objName, objId) { return { type: REMOVE, objName: objName, objId: objId } }

export const clear = (path, next) => {
    let extracted = extractUrl(path);
    const { objName } = extracted;

    return (dispatch) => {
        dispatch(clearAction(objName));
        dispatch(next);
    };
};

export const add = (path, objData, callback) => {
    let extracted = extractUrl(path);
    const { objName, params } = extracted;

    return async (dispatch) => {
        let url = APIUrl.add.replace("{{objName}}", objName);

        // Add potential query suffixes (if any)
        if (params) for (let p of params) url += "/" + p;

        try {
            const response = await axios.post(url, objData);

            Logger.log(">> Global Axios action - ADD!");
            Logger.log(response.data);

            if (response && response.data) {
                const obj = response.data.message[objName];
                dispatch(getAction(objName, obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }

        } catch (error) {
            throw error;
        }
    };
};

export const get = (path, callback) => {
    let extracted = extractUrl(path);
    const { objName, params } = extracted;

    return async (dispatch) => {
        let url = APIUrl.get.replace("{{objName}}", objName);

        // Add potential query suffixes (if any)
        if (params) for (let p of params) url += "/" + p;

        try {
            const response = await axios.get(url);

            Logger.log(">> Global Axios action - GET!");
            Logger.log(response.data);

            if (response && response.data) {
                const obj = response.data.message[objName];
                dispatch(getAction(objName, obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        } catch (error) {
            throw error;
        }
    };
};

export const getBy = (path, fields, values, callback) => {
    let extracted = extractUrl(path);
    const { objName, params } = extracted;

    return async (dispatch) => {

        try {
            let url = APIUrl.get.replace("{{objName}}", objName);

            // Add potential query suffixes (if any)
            if (params) for (let p of params) url += "/" + p;

            if (!Array.isArray(fields)) fields = [fields];
            if (!Array.isArray(values)) values = [values];

            for (let i = 0; i < fields.length; i++) {
                url += "/" + fields[i] + "/" + values[i];
            }
            const response = await axios.get(url);
            Logger.log(">> Global Axios action - GET BY (" + fields + ": " + values + ") !");
            Logger.log(response.data);

            if (response && response.data) {
                const obj = response.data.message[objName];
                dispatch(getAction(objName, obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        } catch (error) {
            throw error;
        }

    };
};

export const upd = (path, objData, callback) => {
    let extracted = extractUrl(path);
    const { objName, params } = extracted;

    return async (dispatch) => {
        let url = APIUrl.upd.replace("{{objName}}", objName);

        if (params) for (let p of params) url += "/" + p;

        try {
            const response = await axios.put(url, objData);

            Logger.log(">> Global Axios action - UPD!");
            Logger.log(response.data);

            if (response && response.data) {
                const obj = response.data.message[objName];
                dispatch(updAction(objName, obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        } catch (error) {
            throw error;
        }
    }
};



export const remove = (path, obj, callback) => {
    let extracted = extractUrl(path);
    const { objName, params } = extracted;

    return async (dispatch) => {

        let url = APIUrl.remove.replace("{{objName}}", objName);

        // Add potential query suffixes (if any)
        if (params) for (let p of params) url += "/" + p;

        try {
            const response = await axios.delete(url, obj)
            Logger.log(">> Global Axios action - REMOVE!");
            Logger.log(response.data);

            dispatch(removeAction(objName, obj._id));
            if (callback) callback(obj._id);
        } catch (error) {
            throw error;
        }

    };
};

// ================================================================================
// ==================================== MANY ======================================
// ================================================================================

export const ADDS = "ADDS";
export const CLEARS = "CLEARS";
export const GETS = "GETS";
export const UPDS = "UPDS";
export const REMOVES = "REMOVES";

export function addsAction(objName, obj, count) { return { type: ADDS, objName: objName + "s", obj: obj, count: count } }
export function clearsAction(objName) { return { type: CLEARS, objName: objName + "s" } }
export function getsAction(objName, obj, count) { return { type: GETS, objName: objName + "s", obj: obj, count: count } }
export function updsAction(objName, obj) { return { type: UPDS, objName: objName + "s", obj: (Array.isArray(obj)) ? obj : [obj] } }
export function removesAction(objName, objId) { return { type: REMOVES, objName: objName + "s", objId: objId } }


export const adds = (path, objData, callback) => {
    let extracted = extractUrl(path);
    const { objName, params } = extracted;

    return async (dispatch) => {
        try {
            let url = APIUrl.add.replace("{{objName}}", objName);

            // Add potential query suffixes (if any)
            if (params) for (let p of params) url += "/" + p;

            const response = await axios.post(url, objData);

            Logger.log(">> Global Axios action - ADDS!");
            Logger.log(response.data);

            if (response && response.data) {
                const obj = response.data.message[objName];
                dispatch(addsAction(objName, [obj]));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        } catch (error) {
            throw error;
        }

    }
};

export const clears = (path, next) => {
    let extracted = extractUrl(path);
    const { objName } = extracted;

    return (dispatch) => {
        dispatch(clearsAction(objName));
        if (next) dispatch(next);
    };
};

export const gets = (path, callback) => {
    let extracted = extractUrl(path);
    const { objName, params } = extracted;
    return async (dispatch) => {
        try {
            let url = APIUrl.gets.replace("{{objName}}", objName);

            // Add potential query suffixes (if any)
            if (params) for (let p of params) url += "/" + p;

            const response = await axios.get(url);

            Logger.log(">> Global Axios action - GETS!");
            Logger.log(response.data);

            if (response && response.data) {
                const obj = response.data.message[objName + "s"];
                dispatch(getsAction(objName, obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }

        } catch (error) {
            throw error;
        }
    };
};

export const getsBy = (path, fields, values, callback) => {
    let extracted = extractUrl(path);
    const { objName, params } = extracted;
    return async (dispatch) => {
        try {
            let url = APIUrl.gets.replace("{{objName}}", objName);

            // Add potential query suffixes (if any)
            if (params) for (let p of params) url += "/" + p;

            if (!Array.isArray(fields)) fields = [fields];
            if (!Array.isArray(values)) values = [values];

            for (let i = 0; i < fields.length; i++) {
                url += "/" + fields[i] + "/" + values[i];
            }

            const response = await axios.get(url);

            Logger.log(">> Global Axios action - GETS BY (" + fields + ": " + values + ") !");
            Logger.log(response.data);

            if (response && response.data) {
                const obj = response.data.message[objName + "s"];
                const count = response.data.message["count"];

                dispatch(getsAction(objName, obj, count));
                if (callback) callback(obj, count);
            } else {
                if (callback) callback();
            }

        } catch (error) {
            throw error;
        }
    }
};

export const upds = (path, objData, callback) => {
    let extracted = extractUrl(path);
    const { objName, params } = extracted;

    return async (dispatch) => {
        try {
            let url = APIUrl.upd.replace("{{objName}}", objName);

            // Add potential query suffixes (if any)
            if (params) for (let p of params) url += "/" + p;

            const response = await axios.put(url, objData);

            Logger.log(">> Global Axios action - UPDS!");
            Logger.log(response.data);

            if (response && response.data) {
                const obj = response.data.message[objName];
                dispatch(updsAction(objName, obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }

        } catch (error) {
            throw error;
        }
    };
};

export const removes = (path, obj, callback) => {
    let extracted = extractUrl(path);
    const { objName, params } = extracted;

    return async (dispatch) => {
        try {
            let url = APIUrl.remove.replace("{{objName}}", objName);
            // Add potential query suffixes (if any)
            if (params) for (let p of params) url += "/" + p;

            const response = await axios.delete(url + "/" + obj._id);

            Logger.log(">> Global Axios action - REMOVES!");
            Logger.log(response.data);

            dispatch(removesAction(objName, obj._id));
            if (callback) callback(obj._id);
        } catch (error) {
            throw error;
        }
    };
};
