import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import DateUtil from '../../../util/DateUtil';
import ConfModal from "../../../components/modal/ConfModal";
import { removes } from '../../../actions/global/global';

class SCalendarEventModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            disabled: false,
            modal: false
        };
    };

    onDelete = (calendarEvent) => {
        const onConfirm = () => {
            this.props.onRemoves("calendarEvent", calendarEvent);
            this.closeModal();
            this.props.toggle();
        };

        const content = <FormattedMessage id="Delete.Warning" />

        this.setState({
            modal: <ConfModal
                content={content}
                onConfirm={onConfirm}
                onCancel={() => this.closeModal()}
                toggle={() => this.closeModal()} />
        });
    };

    closeModal = () => {
        this.setState({ modal: null });
    }

    render() {
        const { calendarEvents } = this.props;

        return (
            <>
                <Modal isOpen={true} toggle={this.props.toggle} className="simple-modal simple-modal-white srounded">

                    <ModalHeader toggle={this.props.toggle} className="pl-4 ml-0" style={{ border: "none" }}>

                    </ModalHeader>

                    <ModalBody className="p-0 pb-4">

                        {calendarEvents.map((calendarEvent) => 
                            <div>
                                <div className="calendar-event text-left mt-4">

                                    <div className="title">
                                        <h5>{calendarEvent.title}</h5>
        
                                        <div className="dates">
                                            <FormattedMessage id="from" />&nbsp;{DateUtil.toDateWithHour(calendarEvent.start_date)}&nbsp;
                                        <FormattedMessage id="To" />&nbsp;{DateUtil.toDateWithHour(calendarEvent.end_date)}
                                        </div>
                                    </div>
        
                                    <div className="desc">
                                        {calendarEvent.desc}
                                    </div>
        
                                    <div className="prevents">
                                        <i className={(calendarEvent.prevent_availabilities) ? "far fa-check-square" : "far fa-square"} ></i>
                                        &nbsp;&nbsp;<FormattedMessage id="This.Event.Prevents.Availabilties" />
                                    </div>
        
                                </div>
                    
                                <div className="w-100 text-center mt-4 mb-3">
                                    <Button
                                        className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-red"
                                        onClick={() => this.onDelete(calendarEvent)}>
                                        <FormattedMessage id="Delete" />
                                    </Button>
                                </div>
                            </div>
            
                        )}
                    </ModalBody>

                </Modal>

                {this.state.modal}
            </>
        );

    }

}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRemoves: (objName, obj, cbk) => dispatch(removes(objName, obj, cbk))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SCalendarEventModal);