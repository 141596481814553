import axios from "axios";
const rootUrl = process.env.REACT_APP_BE_URL || "http://localhost:7000";

export function getOfficeTimeTableByUser(userId) {
    return dispatch => {
		dispatch(request());
        var url = rootUrl + "/officeTimeTable/" + userId;

        return axios.get(url)
            .then(function (response) {
                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "GETS_OFFICE_TIME_TABLE_REQUEST", getOfficeTimeTable: false}}
	function success(result) { return { type: "GETS_OFFICE_TIME_TABLE_SUCCESS", officeTimeTable: result.officeTimeTable, getOfficeTimeTable: true}}
	function failure() { return { type: "GETS_OFFICE_TIME_TABLE_FAILURE", getOfficeTimeTable: false}}
};