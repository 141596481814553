import axios from "axios";

const rootUrl = process.env.REACT_APP_BE_URL || "http://localhost:7000";

export function addExceptionalClose(exceptionalClose, callback) {

	return dispatch => {
		dispatch(request());

        var url = rootUrl + "/exceptionalClose";

        let params = { exceptionalClose };

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure())
        });
	};

    function request() { return { type: "ADD_EXCEPTIONAL_CLOSE_REQUEST", addExceptionalClose: false, }}
	function success(result) { return { type: "ADD_EXCEPTIONAL_CLOSE_SUCCESS", addExceptionalClose: true, exceptionalClose: result }}
	function failure(error) { return { type: "ADD_EXCEPTIONAL_CLOSE_FAILURE", addExceptionalClose: false, error: error }}
};

export function getsExceptionalClose(params, callback) {

	return dispatch => {
		dispatch(request());

        var url = rootUrl + "/exceptionalCloses";

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure())
        });
	};

    function request() { return { type: "GETS_EXCEPTIONAL_CLOSE_REQUEST", getExceptionalClose: false, }}
	function success(result) { return { type: "GETS_EXCEPTIONAL_CLOSE_SUCCESS", getExceptionalClose: true, exceptionalClose: result }}
	function failure(error) { return { type: "GETS_EXCEPTIONAL_CLOSE_FAILURE", getExceptionalClose: false }}
};

export function editExceptionalClose(exceptionalClose, callback) {

	return dispatch => {
		dispatch(request());

        var url = rootUrl + "/exceptionalClose/" + exceptionalClose._id;

        let params = { exceptionalClose };

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure())
        });
	};

    function request() { return { type: "EDIT_EXCEPTIONAL_CLOSE_REQUEST", editExceptionalClose: false, }}
	function success(result) { return { type: "EDIT_EXCEPTIONAL_CLOSE_SUCCESS", editExceptionalClose: true, exceptionalClose: result }}
	function failure(error) { return { type: "EDIT_EXCEPTIONAL_CLOSE_FAILURE", editExceptionalClose: false, error: error }}
};

export function deleteExceptionalClose(exceptionalCloseId, callback) {
    return dispatch => {
		dispatch(request());
        var url = rootUrl + "/exceptionalClose/" + exceptionalCloseId;

        return axios.delete(url)
            .then(function (response) {
                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                    if (callback) callback(obj);
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "DELETE_EXCEPTIONAL_CLOSE_REQUEST", isDeleteExceptionalClose: false}}
	function success(result) { return { type: "DELETE_EXCEPTIONAL_CLOSE_SUCCESS", isDeleteExceptionalClose: true, exceptionalClose: result }}
	function failure() { return { type: "DELETE_EXCEPTIONAL_CLOSE_FAILURE", isDeleteExceptionalClose: false }}
};

export function getsExceptionalCloseByDoctors(params, callback) {

	return dispatch => {
		dispatch(request());

        var url = rootUrl + "/exceptionalCloses/by-doctors";

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure())
        });
	};

    function request() { return { type: "GETS_EXCEPTIONAL_CLOSE_BY_DOCTORS_REQUEST", getExceptionalCloseByDoctors: false, }}
	function success(result) { return { type: "GETS_EXCEPTIONAL_CLOSE_BY_DOCTORS_SUCCESS", getExceptionalCloseByDoctors: true, exceptionalClose: result }}
	function failure(error) { return { type: "GETS_EXCEPTIONAL_CLOSE_BY_DOCTORS_FAILURE", getExceptionalCloseByDoctors: false }}
};