import React, { Component } from 'react';
import { connect } from 'react-redux';
import Noty from 'noty';
import { FormattedMessage, injectIntl } from 'react-intl';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { upds } from "../../actions/global/global";
import { generateRetrocessionCertificate } from "../../actions/retrocessionCertificate/retrocessionCertificate"
import SignatureInput from '../../components/SignatureInput';

class RetrocessionCertificateSignature extends Component {

    constructor(props) {
        super(props);

        this.state = {
            initials: null,
            signature: null,
            initialsError: null,
            signatureError: null,
            disabled: false,
        }

        this.onConfirm = this.onConfirm.bind(this);
        this.onSignClick = this.onSignClick.bind(this);
    }

    componentDidMount() {
        let agreement = Object.assign({ replacement: this.props.replacement }, { replacement_id: this.props.agreement.replacement_id });

        let data = {
            amount: this.props.amount,
            days_available: this.props.days_available,
            agreement: agreement,
            fromDoctor: this.props.fromDoctor,
            doctor: this.props.doctor,
            substitute: this.props.substitute
        }
        this.props.generateRetrocessionCertificate(data);
    }

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-secondary mx-auto" role="status"></div></div>;

    onSignClick() {
        document.getElementById('sign-drepl').scrollIntoView({
            behavior: 'smooth'
        });
    }
    
    onInitials(initials) {
        this.setState({ initials, initialsError: null });
    }

    onSignature(signature) {
        this.setState({ signature, signatureError: null });
    }

    onConfirm() {
        var initialsError = null;
        var signatureError = null;
        if (!this.state.initials) initialsError = <FormattedMessage id="Initials.Error" />;
        if (!this.state.signature) signatureError = <FormattedMessage id="Signature.Error" />;

        if (initialsError || signatureError) {
            this.setState({ initialsError, signatureError });
            return;
        }

        var data = {
            replacement_id: this.props.agreement.replacement_id,
            fromDoctor: this.props.fromDoctor,
            days_available: this.props.days_available,
            amount: this.props.amount,
            doctor_id: this.props.doctor.doctor_id,
            substitute_id: this.props.substitute.substitute_id,
            doctor: this.props.doctor,
            substitute: this.props.substitute
        };

        if (this.props.fromDoctor === true) {
            data.doctor_signature = this.state.signature;
            data.doctor_initials = this.state.initials;
        } else {
            data.substitute_signature = this.state.signature;
            data.substitute_initials = this.state.initials;
        }
 
        this.setState({ disabled: true });

        this.props.onUpds("retrocessionCertificate/sign", data, () => {
            this.props.toggle();
            this.props.closeModalHome();

            new Noty({
                type: "info",
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: this.props.intl.formatMessage({ id: 'Sign.Retrocession.Certificate' }),
                timeout: 6000,
            }).show();
        });
    }

    render() {
        const { retrocessionCertificateHTML } = this.props;
        
        return (
            <Modal isOpen={true} toggle={this.props.toggle} size="lg" className="simple-modal simple-modal-white srounded">

                <ModalHeader toggle={this.props.onClose}>
                    <button type="button" className="close d-inline-block b-green" aria-label="Close" onClick={this.props.toggle} ><span aria-hidden="true">×</span></button>
                </ModalHeader>

                <ModalBody className="pt-0">

                    <div className="w-100 text-center uppercase mb-4">
                        <h5 className="bold lp-3"><b><FormattedMessage id="Retrocession.Certificate" /></b></h5>
                    </div>

                    {(retrocessionCertificateHTML) ?
                        <div className="text-left">
                            <div className="signature-btn" onClick={this.onSignClick}>
                                <i className="fa icon-ICON__SIGNATURE_GRIS_C"></i>
                            </div>

                            <div className="pdf-wrapper" dangerouslySetInnerHTML={{ __html: retrocessionCertificateHTML }}></div>
                        </div>
                        :
                        this.loading()
                    }

                    <Row className="mt-4">
                        <Col md="6">

                            <div id="sign-drepl" className="m-auto text-center text-uppercase" style={{ width: "350px" }}>
                                <h5><FormattedMessage id="Paraph" /></h5>
                                <SignatureInput
                                    containerId="initials-container"
                                    onEnd={(value) => this.onInitials(value)}
                                    error={this.state.initialsError} />
                            </div>
                        </Col>

                        <Col md="6">

                            <div className="m-auto text-center text-uppercase" style={{ width: "350px" }}>
                                <h5><FormattedMessage id="Signature" /></h5>
                                <SignatureInput
                                    containerId="signature-container"
                                    onEnd={(value) => this.onSignature(value)}
                                    error={this.state.signatureError} />
                            </div>

                        </Col>
                    </Row>

                    <LaddaButton
                        className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green mt-4 mb-3"
                        onClick={this.onConfirm}
                        data-style={EXPAND_LEFT}
                        loading={this.state.disabled}
                        disabled={this.state.disabled}>
                        <FormattedMessage id="Confirmation" />
                    </LaddaButton>

                </ModalBody>

            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        retrocessionCertificateHTML: state.retrocessionCertificate.retrocessionCertificateHTML,
        user: state.global.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onUpds: (objName, obj, cbk) => dispatch(upds(objName, obj, cbk)),
        generateRetrocessionCertificate: (data, cbk) => dispatch(generateRetrocessionCertificate(data, cbk)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RetrocessionCertificateSignature));