
import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';

class TermsPatients extends React.Component {
    render() {

        return (
            <div id="registration" className='example blue-bg'>

                <div className='step-progress'>

                    <div className="topbar navbarbg text-center b-fix">
                        <a href="/#" className="a-unstyled">
                            <div className="hydrogen-logo-sm d-inline-block align-middle"></div>
                            <div className="ml-3 bold d-inline-block align-middle fs-18 lp-3">HYDROGEN</div>
                        </a>
                    </div>

                    <Row className="step step1 mt-5 justify-content-md-center ">

                        <Col lg="8">

                            <Card>

                                <CardBody className="pl-5 pr-5 pb-5 pt-4">

                                    <h1 className="w-100 text-center mt-3">CGU/CGV Section Recherche Clinique</h1>
                                    <h5 className="w-100 text-center mt-3 mb-5">Version en vigueur au 10 Decembre 2021</h5>

                                    <p>L’utilisation du Site édité par la société HYDROGEN www.hydrogen-rempla.com et de son Contenu implique sans réserve l’acceptation des présentes conditions générales d’utilisation (ci-après les « CGU ») par toute personne physique visitant ledit site et désirant, le cas échéant, accéder aux Services qui y sont proposés, la dernière version en date faisant foi. </p>

                                    <h3 className="mt-5 mb-5">ARTICLE 1.	IDENTIFICATION LÉGALE</h3>

                                    <p>Le Site (tel que défini ci-après) est édité par la société HYDROGEN, société par actions simplifiée au capital de 7.500,00 Euros, inscrite au Registre du Commerce et des Sociétés de GRASSE sous le numéro 877 575 597, ayant son siège social sis 1047 Route des Dolines – Allée Pierre ZILLER – 06560 VALBONNE, titulaire exclusive de l’ensemble des droits de propriété intellectuelle sur le Site et son Contenu (ci-après désignée « HYDROGEN »). HYDROGEN exploite une plateforme à usage professionnel (la « Plateforme ») permettant à des médecins / personnels de santé (les « Praticiens ») de dépister des patients pour leur proposer de participer à des protocoles de recherche clinique sur le territoire français (les « Patients »).</p>

                                    <h3 className="mt-5 mb-5">ARTICLE 2.	DÉFINITIONS</h3>

                                    <p>Les termes suivants, commençant par une lettre capitale, qu'ils soient employés au singulier ou au pluriel dans les présentes, y compris au préambule ci-dessus, auront toujours la définition suivante :</p>

                                    <p>2.1.	Compte : désigne le compte créé par l’Utilisateur directement à partir du Site conformément aux conditions et modalités indiquées aux présentes et sur le Site, à partir duquel l’Utilisateur peut accéder aux Services ;</p>

                                    <p>2.2. Contenu : désigne l’ensemble des outils, méthodes, logiciels, algorithmes, savoir-faire, contenu éditorial (notamment textes, photographies, images, dessins, etc.), illustrations et d’une manière générale toute œuvre ou création susceptible d'appropriation intellectuelle utilisé dans le cadre de la publication du Site ;</p>

                                    <p>2.3. Donnée : désigne toute donnée personnelle au sens du Règlement européen 2016/679 du 27 avril 2016 relatif à la protection des personnes à l'égard du traitement des données à caractère personnel et de la libre circulation des données (RGPD) collectées, traitées et hébergées par HYDROGEN ;</p>

                                    <p>2.4. Essai clinique ou Etude clinique ou Essai thérapeutique ou Protocole de recherche clinique : désigne une étude scientifique réalisée chez l’humain, supervisée par le corps médical, pour évaluer la pharmacocinétique, la pharmacodynamie, l’efficacité ou la tolérance d'une méthode diagnostique ou d'un traitement.</p>

                                    <p>2.5. Établissement de santé : désigne toute personne morale ayant une activité d’établissement de santé et employant des médecins diplômés légalement autorisé à exercer ladite activité, en étant à jour du paiement de l’ensemble de ses cotisations professionnelles disposant, à ce titre, d’un identifiant RPPS délivré par l’Ordre National des Médecins français qui s’inscrit sur le Site en vue de bénéficier des Services d’HYDROGEN et qui a la possibilité de souscrire à l’une des offres payantes proposées par HYDROGEN sur son Site ;</p>

                                    <p>2.6. Investigateurs : professionnel de santé qui dirige et surveille la réalisation d’un essai clinique ;</p>

                                    <p>2.7. Licence : désigne la licence de remplacement délivrée par l’Ordre National des Médecins français ;</p>

                                    <p>2.8. Médecin installé : désigne tout Utilisateur ayant la qualité de médecin diplômé, exerçant à titre individuel, de manière groupée ou sous forme de société, légalement autorisé à exercer ladite activité, en étant à jour du paiement de l’ensemble de ses cotisations professionnelles disposant, à ce titre, d’un identifiant RPPS délivré par l’Ordre National des Médecins français, qui s’inscrit sur le Site en vue de bénéficier des Services d’HYDROGEN et qui a la possibilité de vérifier les critères médicaux auprès des patients avant de les adresser à HYDROGEN via son Site, en vue d’une inclusion dans un protocole de recherche clinique ;</p>

                                    <p>2.9. Messages : désigne tout message, recherche, demande, information, commentaire, annonce, disponibilité, proposition, image, texte, vidéo, etc. postés sur la Site et/ou partagé par l’Utilisateur via celui-ci et qui l’est sous sa seule responsabilité ;</p>

                                    <p>2.10. Patient : désigne tout Utilisateur, volontaire sain ou atteint d’une pathologie ou d’une condition physique dont l’état permet d’envisager une inclusion dans un essai clinique ;</p>

                                    <p>2.11. Parties : désigne tout Utilisateur et HYDROGEN, dont l'identité légale est spécifiée ci-avant ainsi que dans les mentions légales du Site ;</p>

                                    <p>2.12. Personnel de santé : désigne une personne qui exerce ses compétences ou son jugement ou fournit un service lié au maintien ou l'amélioration de la santé des individus ou au traitement ou soins des individus blessés, malades, souffrant d'un handicap ou d'une infirmité.</p>

                                    <p>2.13. Praticien : désigne l’ensemble des Médecins installés et Médecins remplaçants ;</p>

                                    <p>2.14. Promoteur : désigne toute personne physique, société ou institution qui prend l’initiative d’un essai clinique et en assume les responsabilités et le financement ;</p>

                                    <p>2.15. Remplaçant : désigne tout médecin diplômé légalement autorisé à exercer ladite activité (en ce compris les internes, qu’ils aient ou non obtenu leur thèse, dès lors qu’ils possèdent une Licence), en étant à jour du paiement de l’ensemble de ses cotisations professionnelles disposant, à ce titre, d’un identifiant RPPS délivré par l’Ordre National des Médecins français qui qui a la possibilité de vérifier les critères médicaux auprès des patients avant de les adresser à HYDROGEN via son Site, en vue d’une inclusion dans un protocole de recherche clinique;</p>

                                    <p>2.16. Section recherche clinique : désigne la partie du site HYDROGEN qui permet aux Praticiens de détecter si leurs Patients peuvent être inclus dans les Essais cliniques présents sur le site et qui permet aux Patients d’accepter les présentes CGU/CGV.</p>

                                    <p>2.17. Services : désigne l’ensemble des Services fournis par HYDROGEN proposant la pré sélection des Patients auprès des Utilisateurs du Site. Les Services sont définis plus précisément sur le Site. Les Médecins installés et les Etablissements de santé ont également la possibilité de souscrire aux services payants d’HYDROGEN selon les conditions et modalités indiquées sur le Site et dans les conditions générales de vente d’HYDROGEN (ci-après les « CGV ») ;</p>

                                    <p>2.18. Site : désigne l’ensemble des programmes informatiques et solutions logicielles ainsi que le Contenu, formant ensemble le site internet disponible à l’adresse suivante : www.hydrogen-rempla.com ;</p>

                                    <p>2.19. Utilisateur : désigne tout Médecin installé, Etablissement de santé ou Remplaçant qui navigue sur le Site, en vue d’accéder aux Services proposés par HYDROGEN sur son Site.</p>

                                    <h3 className="mt-5 mb-5">ARTICLE 3.	ACCEPTATION DE L’UTILISATEUR</h3>

                                    <p>Les présentes CGU/CGV de la Plateforme (les « Conditions Générales d’Utilisation et conditions générales de vente ») ont pour objet de définir les conditions et modalités d’accès, d’utilisation de la Plateforme par les Utilisateurs s’y connectant et les modalités de la navigation sur la Section recherche clinique du site (le « Service »). </p>

                                    <p>Les présentes CGU sont expressément agréés et acceptées par l’Utilisateur, qui déclare et reconnaît en avoir une parfaite connaissance. </p>

                                    <p>En tout état de cause, tout commencement dans l’utilisation du Site soumet l’Utilisateur aux présentes CGU/CGV. L’Utilisateur est dès lors réputé accepter l’intégralité des règles stipulées aux présentes, ainsi que celles qui seraient présentes dans tout document disponible sur le Site, intégré aux présentes CGU/CGV par référence et qui régissent sa relation avec les tiers et HYDROGEN.</p>

                                    <p>Les présentes CGU sont opposables pendant toute la durée d'utilisation du Site par l’Utilisateur et jusqu'à ce que de nouvelles CGU remplacent les présentes.</p>

                                    <p>L’Utilisateur reconnaît également avoir consulté et pris connaissance des mentions légales figurant sur le Site.</p>

                                    <h3 className="mt-5 mb-5">ARTICLE 4.	CONVENTION DE PREUVE</h3>

                                    <p>L’Utilisateur reconnaît que les enregistrements et sauvegardes (en ce compris toute donnée de connexion) réalisés sur le Site (ci-après les « Documents Electroniques ») auront pleine valeur probante entre l’Utilisateur et HYDROGEN. Ainsi, les Documents Electroniques (y compris leur date et heure) feront foi entre les Parties à tout litige.</p>

                                    <p>L’Utilisateur reconnaît par conséquent, dans ses relations contractuelles avec HYDROGEN, la validité et la force probante des courriers électroniques.</p>

                                    <p>De même, les notifications effectuées via le Site sont opposables à l’Utilisateur en tant qu’éléments de preuve.</p>

                                    <h3 className="mt-5 mb-5">ARTICLE 5.	EVOLUTIONS</h3>

                                    <p>HYDROGEN se réserve le droit de modifier les présentes CGU, selon l’évolution technique du Site ou de ses offres de Services, ou en raison de l’évolution de la législation, à sa seule discrétion.</p>

                                    <p>D’une manière générale, l’utilisation du Site par l’Utilisateur est toujours soumise à la version la plus récente des CGU, accessible à l’Utilisateur au moment de cette utilisation. Il appartient à l’Utilisateur de consulter aussi souvent que nécessaire les CGU, accessibles à partir du Site, y compris depuis son Compte.</p>

                                    <h3 className="mt-5 mb-5">ARTICLE 6.	VIOLATION</h3>

                                    <p>Toute violation des présentes CGU/CGV autorise HYDROGEN à refuser pour l’avenir à l’Utilisateur auteur de la violation considérée l’accès au Site, ainsi qu’à clôturer tout Compte lui permettant d’accéder aux Services, sans préjudice des indemnités qui pourraient être réclamées à l’auteur de ladite violation par HYDROGEN.</p>

                                    <h3 className="mt-5 mb-5">ARTICLE 7.	DOMAINE D’APPLICATION DES CGU/CGV</h3>

                                    <p>Les présentes CGU/CGV sont rédigées en langue française. Elles précisent notamment les conditions d’utilisation du Site et conditions générales de vente par l’Utilisateur ainsi que les différentes étapes nécessaires à la création par ce dernier d’un Compte.</p>

                                    <h3 className="mt-5 mb-5">ARTICLE 8.	RECOMMANDATION ET MISES EN GARDE</h3>

                                    <p>La Plateforme n’est pas un service d’urgence ni d'alerte en temps réel. Ainsi, dans l’éventualité où le Patient se trouverait dans une situation d’urgence, ou un cas de dégradation anormale de son état de santé, le Patient reconnait qu’il n’incombe pas à l’Utilisateur d’effectuer un dépistage sur la Plateforme mais que ce dernier dirigera le Patient vers un service d’urgences.</p>

                                    <p>Le Patient reconnait que l’utilisation de la Plateforme n'a pas vocation à se substituer à un suivi médical classique nécessitant un diagnostic et des visites de contrôles auprès de son médecin traitant.</p>

                                    <p>Il s'agit d'un outil permettant la réalisation d’un dépistage ciblé concernant des pathologies et des symptômes précis. Par conséquent, un Patient ne peut être dépisté deux fois pour un même protocole. Le Patient garantit qu’il a le droit d’avoir recours au Service au regard de la règlementation française.</p>

                                    <h3 className="mt-5 mb-5">ARTICLE 9.	COMPTES</h3>

                                    <h4 className="mt-4 mb-4">9.1.	Création du Compte</h4>

                                    <p>La création d’un Compte dans le but d’accéder aux Services qui y sont proposés s'effectue directement à partir du Site grâce au processus mis à la disposition de l'Utilisateur par HYDROGEN.</p>

                                    <h4 className="mt-4 mb-4">9.1.1.	Informations nécessaires à la création du Compte</h4>

                                    <p>Afin de créer son Compte et de pouvoir passer Commande des Services, l'Utilisateur doit remplir le formulaire de création de compte disponible sur le Site et compléter l’ensemble des champs pour lesquels les informations demandées sont obligatoires pour la création de son Compte.</p>

                                    <p>A ce titre, l’Utilisateur garantit que toutes les informations qu’il renseigne dans le formulaire de création de compte sont véridiques et sincères. L’Utilisateur s’engage également à toujours maintenir à jour les informations qui figurent sur son Compte.</p>

                                    <p>Dans ce cadre, il s’engage également à modifier son Compte dans les plus brefs délais suite à toute modification de sa situation administrative ou professionnelle par rapport aux informations qu’il aura précédemment fournies dans le cadre de la création de son Compte (changement de locaux ; installation ; mise en place d’un secrétariat ; collaboration ; logiciel ; pratique médicale ; diplômes ; etc.).</p>

                                    <p>HYDROGEN se réserve le droit de refuser une demande d'inscription au Site par tout Utilisateur qui ne souhaite pas se conformer aux CGU/CGV ou qui ne fournit pas entièrement, ou de bonne foi, les informations demandées. Dans ce cadre, HYDROGEN pourra procéder à des vérifications complémentaires pour toute inscription d’un Utilisateur pour lequel les informations fournies présentent un manque de cohérence.</p>

                                    <p>HYDROGEN pourra alors demander à l’Utilisateur de lui fournir une copie de sa carte de professionnel de santé et/ou d’un justificatif de domicile professionnel et/ou de leur Licence pour les Remplaçants, ou de tout autre document de nature à attester de son identité et de sa qualité de médecin. Dans l’hypothèse où le contrôle réalisé par HYDROGEN fait apparaître une ou plusieurs incohérence(s), HYDROGEN pourra :</p>
                                    <ul>
                                        <li>Proposer des modifications à l’Utilisateur s’il apparaît que ces incohérences sont dues à des erreurs de l’Utilisateur lors de la création de son Compte ;</li>
                                        <li>•	Suspendre puis éventuellement supprimer le Compte de l’Utilisateur dans l’hypothèse où la vérification réalisée par HYDROGEN révèle un usage contraire aux présentes CGU/CGV par l’Utilisateur concerné.</li>
                                    </ul>

                                    <p>Les paramètres de connexion permettant l'accès à leurs Comptes ne sont pas envoyés par HYDROGEN aux Utilisateurs. Dès lors, les Utilisateurs sont responsables de la vérification de la validité de l'adresse e-mail fournie lors de leur enregistrement sur le Site, dans la mesure où cette adresse e-mail fait partie de l'identification à leur Compte. Dans le cas où l'adresse e-mail est incorrecte, l'Utilisateur peut ne pas être en mesure d'accéder à son Compte, sans que HYDROGEN ne puisse en être tenue pour responsable.</p>

                                    <h4 className="mt-4 mb-4">9.1.2.	Validation et mise à disposition du Compte</h4>

                                    <p>Un e-mail qui confirme l’inscription est envoyé à l’adresse indiquée lors de la création du Compte par l’Utilisateur contenant son identifiant ainsi que les différentes informations qu’il a indiquées.</p>

                                    <p>La validation du Compte se fait après vérification des informations fournies et validation du Compte par HYDROGEN, par e-mail à la suite de la fourniture par l’Utilisateur des informations suscitées. Il reçoit un second message (par mail et par SMS) quand son compte est validé et qu’il peut désormais naviguer sur le Site et accéder au Services.</p>

                                    <p>Une fois la création du Compte validée, l’Utilisateur peut immédiatement l’utiliser.</p>

                                    <h4 className="mt-4 mb-4">9.1.3.	Génération du mot de passe</h4>

                                    <p>Lors de la création de son Compte, l’Utilisateur choisit lui-même son mot de passe personnel. L’Utilisateur n’a pas d’obligation de modifier son mot de passe lors de sa première connexion.</p>

                                    <h4 className="mt-4 mb-4">9.1.4.	Contenu du Compte</h4>

                                    <p>Les Utilisateurs accèdent à leur Compte, sous la forme d'une interface personnalisée, après que les administrateurs d’HYDROGEN aient activé leurs paramètres de connexion (identifiant et mot de passe choisi lors de leur inscription sur le Site).</p>

                                    <p>A partir de ce Compte, les Utilisateurs ont accès aux Services, au suivi et à l’historique des Patients qu’ils ont adressés pour les Essais cliniques.</p>

                                    <p>Pour plus d'informations sur le contenu exact et complet de ce Compte, les Utilisateurs sont invités à consulter le Site et à créer un Compte.</p>

                                    <h4 className="mt-4 mb-4">9.2.	Responsabilité des paramètres de connexion</h4>

                                    <p>Il appartient à chaque Utilisateur d’enregistrer, dès sa première connexion, puis de le modifier de manière régulière et au moins tous les trois (3) mois, le mot de passe qu’il s’est lui-même choisi au moment de son enregistrement sur le Site et de s’assurer que son code est composé de lettres et de chiffres de longueur suffisante.</p>

                                    <p>Chaque Utilisateur doit garder ses paramètres de connexion confidentiels. A ce titre, il est rappelé que les paramètres de connexion de l’Utilisateur sont à usage unique et strictement personnel.</p>

                                    <p>Ce dernier est responsable de toutes leurs utilisations, qu’il les ait ou non effectivement ou expressément autorisées. L’Utilisateur ne doit pas céder, prêter ou transférer ses paramètres de connexion à un tiers, ni permettre à un tiers de se connecter à son Compte.</p>

                                    <p>Les Utilisateurs s'engagent en outre à :</p>
                                    <ul>
                                        <li>Informer immédiatement HYDROGEN en cas d'utilisation abusive de leurs paramètres de connexion ou de Compte ou en cas de toute autre violation de la sécurité ;</li>
                                        <li>S’assurer d'être correctement déconnecté de son compte à la fin de chaque session en utilisant la fonction « Déconnexion ».</li>
                                        <li>Il est rappelé que le fait de se faire passer pour quelqu’un en faisant usage d’une ou plusieurs données de toute nature permettant de l’identifier, constitue un délit sanctionné pénalement (article 226-4-1 du code pénal). Toute connexion ou transmission de données effectuée à partir de ce dernier est réputée l’avoir été par l’Utilisateur et sous son entière responsabilité.</li>
                                        <li>En cas de perte, de vol ou de détournement de ses identifiants ou de son mot de passe par un tiers, l’Utilisateur s’engage à en avertir HYDROGEN sans délai en écrivant à l’adresse suivante support@hydrogen-rempla.com. En outre, dans l’hypothèse où l’Utilisateur utiliserait des informations fausses, inexactes, trompeuses, incomplètes ou périmées, HYDROGEN se réserve la possibilité de suspendre ou refuser l’accès, à tout ou partie de la Plateforme, sans préavis, ni indemnité de façon temporaire ou définitive. La responsabilité d’HYDROGEN ou de l’Utilisateur ne saurait être engagée en cas d’usurpation d’identité ou d’utilisation frauduleuse par un tiers du compte d’un Utilisateur ou en cas de communication d’informations fausses, erronées ou incomplètes.</li>
                                    </ul>

                                    <p>HYDROGEN se réserve le droit de procéder à la désactivation de tout identifiant ou mot de passe, choisi par l’Utilisateur, à tout moment, si l’Utilisateur a enfreint l’une des dispositions des présentes CGU/CGV.</p>

                                    <h3 className="mt-5 mb-5">ARTICLE 10.	DESCRIPTION DES SERVICES</h3>

                                    <h4 className="mt-4 mb-4">10.1.	Généralités</h4>

                                    <p>HYDROGEN est une société spécialisée dans la gestion et l’optimisation de l’activité des professionnels de santé. Dans le cadre de son activité, elle a développé un Site permettant aux Utilisateurs d’accéder à ses Services.</p>

                                    <p>Sur le Site, tout Utilisateur peut :</p>
                                    <ul>
                                        <li>Naviguer sur le Site ;</li>
                                        <li>Accéder au Contenu du Site et le consulter ;</li>
                                        <li>Créer un Compte.</li>
                                    </ul>

                                    <p>Depuis son Compte, n'importe quel Utilisateur peut :</p>
                                    <ul>
                                        <li>Accéder aux Services ;</li>
                                        <li>Modifier ses informations personnelles ;</li>
                                        <li>Visualiser les essais cliniques situés dans leur secteur</li>
                                    </ul>

                                    <p>Depuis le lien adressé au patient, n'importe quel Patient peut :</p>
                                    <ul>
                                        <li>Accéder aux Services ;</li>
                                        <li>Modifier ses informations personnelles ;</li>
                                        <li>Accepter les CGU/CGV ;</li>
                                    </ul>

                                    <p>La Plateforme est une solution en ligne permettant à des Utilisateurs de réaliser un dépistage auprès de leurs Patients afin qu’ils transmettent leurs coordonnées de manière dématérialisée, pour bénéficier d’une potentielle inclusion dans un protocole de recherche clinique, dans les conditions décrites aux présentes.</p>

                                    <h4 className="mt-4 mb-4">10.2.	Connexion à la Section recherche clinique</h4>

                                    <p>L’Utilisateur et le Patient peuvent avoir accès à la Plateforme à partir d’un ordinateur, d’une tablette ou d’un téléphone portable.</p>

                                    <p>Une fois sur son compte, l’Utilisateur peut visualiser les études cliniques qui sont actives sur son secteur et sélectionner les Patients qui correspondent aux profils recherchés. Lorsque l’Utilisateur détecte un Patient et entre ses coordonnées (tels que nom, prénom, localisation) dans son espace personnel, le Patient reçoit un SMS de confirmation. Après validation et acceptation des présentes CGV, le service administratif d’HYDROGEN reçoit les coordonnées du Patient sur le Dashboard de l’application ainsi qu’un mail et peut le contacter. </p>

                                    <p>A compter de la réception de l’email de confirmation reçu par HYDROGEN, l’accord du Patient à être contacté est considérée comme définitif.</p>

                                    <p>Pour plus d'informations sur le contenu exact et complet du Site et des Services, les Utilisateurs sont invités à consulter le Site.</p>

                                    <h4 className="mt-4 mb-4">10.3.	Gratuité des Services</h4>

                                    <p>L’utilisation des Services des présentes CGU par les Médecins Installés et les Remplaçants est gratuite.</p>

                                    <h4 className="mt-4 mb-4">10.4.	Transmission des Données Personnelles</h4>

                                    <p>Dans le cadre de la mise en relation des Patients auprès des Investigateurs ou des Promoteurs, HYDROGEN rappelle qu’elle ne réalise qu’une agrégation de Données et informations fournies par les Utilisateurs. En fonction du contrat conclu entre HYDROGEN et son Client, HYDROGEN ne procède pas systématiquement à une vérification des Données des Patients, adressées par les Utilisateurs. Cette responsabilité incombe uniquement et exclusivement aux Utilisateurs, qui sont considérés comme responsables de traitement des données qu’ils saisissent sur le site. Il appartient donc à chaque Utilisateur de vérifier scrupuleusement les informations saisies sur le Site avant toute validation de ce dernier.</p>

                                    <p>Dès lors, HYDROGEN ne pourra en aucun cas être tenue responsable d’une erreur dans l’agrégation des Données et informations fournies par les Utilisateurs. De même, HYDROGEN étant un tiers à la transmission des Données Personnelles, sa responsabilité ne pourra être engagée au titre des difficultés pouvant intervenir lors du processus d’inscription d’un Patient. Dans ce cadre, les Utilisateurs acceptent et s’engagent à signaler immédiatement à HYDROGEN toute difficulté ou incident qu’ils pourraient rencontrer lors d’une quelconque des étapes de mise à disposition des étapes nécessaires à l’agrégation des Données.</p>

                                    <h3 className="mt-5 mb-5">ARTICLE 11.	INDEMNISATION DES UTILISATEURS</h3>

                                    <p>L’accès à la Plateforme est gratuit. Les Utilisateurs peut adresser des Patients après avoir vérifié leur éligibilité. Les montants et les conditions de l’indemnisation peuvent être visualisées à tout moment par l’Utilisateur, dans la section « Faites avancer la recherche clinique », affichée sur la Plateforme. L’Utilisateur est rémunéré par HYDROGEN ou par le promoteur de l’étude, en fonction de la contractualisation effectuée en amont de la mise en ligne. L’indemnisation de l’Utilisateur est versée seulement après l’inclusion du Patient dans l’étude. L’Utilisateur accepte donc de ne pas être rémunéré pour son travail d’orientation, si le Patient ne correspond pas aux critères de sélection de l’étude, lors de sa visite d’inclusion auprès de l’investigateur.</p>

                                    <p>Il est expressément indiqué dans les présentes CGV que le travail de sélection des patients effectué par l’Utilisateur est indemnisé à hauteur du montant indiqué sur la plate-forme. Ce montant est fixé toutes charges comprises, pour chaque Patient adressé via la plate-forme HYDROGEN, sous réserve que ce dernier soit inclus dans l’étude clinique indiquée. L’Utilisateur peut adresser plusieurs Patients à HYDROGEN tant que le nombre de Patients attendu dans l’étude n’est pas atteint. Chaque Utilisateur pourra recevoir autant de versement qu’il aura adressé de Patients dans les études présentées sur le site, à condition que ces Patients soient finalement inclus dans lesdites études. Le montant de cette indemnisation ne peut en aucun cas dépasser 200€ par patient adressé. Cette indemnisation sera adressée par virement bancaire ou par chèque, suivant la préférence de l’Utilisateur, sous 3 mois après la décision d’inclure le Patient.</p>

                                    <p>En application de l'Ordonnance n°2017-49 du 19 janvier 2017 , ratifiée par la Loi n°2019-774 du 24 juillet 2019  et par décret d'application n° 2020-730 du 15 juin 2020 , publié au JO du 17 Juin 2020, l’Utilisateur doit accepter la convention d'hospitalité qui le lie à la société HYDROGEN ou au Promoteur.</p>

                                    <p>Le médecin déclare :</p>

                                    <ul>
                                        <li>Avoir souscrit une assurance responsabilité civile professionnelle.</li>
                                        <li>N’être lié par aucun engagement ou obligation incompatible avec les termes des présentes CGU/CGV.</li>
                                        <li>Avoir le droit et la capacité à conclure les présentes CGU/CGV, en fonction de son statut professionnel, légal et réglementaire et en accord avec les autorités administratives dont il relève, en particulier en ce qui concerne les paiements qui lui sont faits aux termes desdites CGU/CGV et les notifications qu'elles entraînent.</li>
                                        <li>Avoir pris connaissance du protocole joint.</li>
                                    </ul>

                                    <p>L’Utilisateur déclare sur l'honneur et s'engage à veiller à ce que dans le cadre de la conclusion et de l'exécution des présentes CGU/CGV, il n'est pas et ne sera pas en situation de manquer à l'une quelconque de ses obligations déontologiques et/ou légales, civiles et/ou pénales.</p>

                                    <p>L’Utilisateur devra être immatriculé auprès de l’URSSAF en tant que travailleur indépendant. Il fera également son affaire des obligations et formalités de toute nature lui incombant, en matière sociale, fiscale et civile, notamment au titre de son immatriculation au régime social des travailleurs non-salariés. L’Utilisateur s’engage à fournir sur demande une attestation d’URSSAF datant de moins de 6 mois.</p>

                                    <p>L’Utilisateur reconnaît le caractère déterminant de ses déclarations pour HYDROGEN, étant précisé qu'en cas d'inexactitude de celles-ci HYDROGEN ou le Promoteur se réservent le droit de mettre un terme à la convention et, si des sommes ont été versées à l’Utilisateur, de lui en demander la restitution, nonobstant tous dommages intérêts qu’HYDROGEN ou le Promoteur pourraient être amenés à lui demander.</p>

                                    <p>L’Utilisateur s'engage :</p>

                                    <ul>
                                        <li>à remplir avec exactitude les informations demandées dans la section « Recherche clinique » de la plateforme HYDROGEN.</li>
                                        <li>à ne pas conduire personnellement et simultanément d’autres protocoles dans la même indication et avec la même sélection de sujets.</li>
                                        <li>à soumettre pour information les présentes CGU/CGV au Conseil départemental de l'Ordre des Utilisateurs au tableau duquel il est inscrit et ce conformément à l’article L.4113-9 du Code de la santé publique</li>
                                        <li>à respecter les dispositions de la loi n°78-17 du 06 janvier 1978 modifiée et au Règlement 2016/679/UE du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données (RGPD)</li>
                                        <li>Les Utilisateurs qui orientent les Patients, dès lors qu’ils sont médecins hospitaliers ou hospitalo-universitaires, doivent en vertu du décret n° 2011-82 du 20 Janvier 2011 modifiant le décret n° 2007-658 du 2 mai 2007 relatif au cumul d’activités des fonctionnaires (loi n° 2007-148 du 2 février 2007 de modernisation de la fonction publique), obtenir l’autorisation de leur hiérarchie (hospitalière ou hospitalière et universitaire) pour assurer une telle activité dite « accessoire ».</li>
                                    </ul>

                                    <p>Il est également invité à confirmer qu'il accepte pleinement et sans réserve l’intégralité des présentes CGU/CGV, en cochant la case prévue à cet effet. L’Utilisateur peut y accéder en cliquant sur « Lire les conditions générales » et les enregistrer ou les imprimer. A défaut de confirmation, l’Utilisateur ne pourra terminer la procédure d’adressage de son Patient en ligne.</p>

                                    <p>L’Utilisateur qui adresse les coordonnées de son Patient à HYDROGEN, accepte de dédier un temps de sa consultation à cette tâche. Il est précisé que l’Utilisateur qui oriente un Patient pour une étude clinique, le fait car il estime que son Patient peut obtenir un bénéfice en participant à une des études cliniques présentées sur le site HYDROGEN. Aussi le montant de la consultation ne peut être réclamé à HYDROGEN.</p>

                                    <p>A tout moment, l’Utilisateur peut vérifier les caractéristiques des patients inclus et les patients adressés sur son compte personnel. Dès lors que le Patient est inclus dans l’étude clinique, sauf désinscription de la part de l’Utilisateur ou refus de versement, ce dernier recevra la somme indiquée sur la plate-forme.</p>

                                    <h3 className="mt-5 mb-5">ARTICLE 12.	Règles d’usage</h3>

                                    <p>Tout Message publié via le Site l’est sous la seule responsabilité de l’Utilisateur et doit être rédigé dans un langage correct et compréhensible par tous, ce qui exclut expressément toute utilisation du langage dit « SMS ».</p>

                                    <p>Pour une lisibilité optimale du contenu du Site, l’usage des minuscules est fortement recommandé. En effet, les majuscules ou l’accentuation de signes de ponctuation peuvent donner l’impression d’une hausse de ton, néfaste au bon fonctionnement du Site.</p>

                                    <p>Le contenu du Message posté et/ou partagé via le Site doit être le plus clair et lisible possible afin que les Utilisateurs concernés puissent le comprendre, et le cas échéant y répondre. Il doit ainsi contenir des mots clés et être suffisamment précis pour que les Utilisateurs comprennent l’information que l’Utilisateur souhaite délivrer. Aussi, il est important de rédiger ce Message de manière claire et concise mais néanmoins complète.</p>

                                    <p>Tous les Message doivent respecter les présentes règles d’usage, et notamment :</p>
                                    <ul>
                                        <li>Respecter la législation en vigueur,</li>
                                        <li>Ne pas porter atteinte à la vie privée d’autrui,</li>
                                        <li>Ne pas tenir des propos illégaux, notamment à caractère violent, injurieux, diffamatoires, racistes, discriminant, révisionnistes, faisant l’apologie des crimes de guerre, pédophiles, incitant au meurtre, au suicide, à la discrimination ou à la haine, etc.,</li>
                                        <li>Ne pas reproduire, représenter ou diffuser de contenu qui serait la reproduction sans autorisation d’une œuvre, d’une marque, d’un logo, etc. protégée par des droits de propriété intellectuelle ou industrielle,</li>
                                        <li>Ne pas inclure de messages incitant au prosélytisme et affichant les croyances religieuses et/ou politiques de l’Utilisateur,</li>
                                        <li>Ne pas dénoncer ou humilier une personne en la nommant directement via le Site,</li>
                                        <li>Ne pas lancer de sujets à pétition sans lien avec l’objet du Site.</li>
                                    </ul>

                                    <p>L’Utilisateur s’engage donc à adopter une attitude respectueuse en toutes circonstances.</p>

                                    <h4 className="mt-4 mb-4">12.1.	Droits et devoirs de l’Utilisateur</h4>

                                    <p>L’Utilisateur est le seul et unique responsable des Messages qu’il publie sur le Site. Dans ce cadre, il est rappelé à l’Utilisateur que ses Messages sont destinés à être lus par les autres Utilisateurs, sous sa propre responsabilité, ce que l’Utilisateur accepte expressément.</p>

                                    <p>Dès lors, l’Utilisateur est informé que les Messages qu’il poste ou partage via le Site doivent impérativement respecter l’ensemble des règles d’usage détaillées au présent article.</p>

                                    <p>Par ailleurs, la reproduction, la diffusion ou toute autre acte d’utilisation ou d’exploitation, quels qu’en soient le support ou la nature, à des fins commerciales ou non, des Messages des autres utilisateurs du Site, sont soumis à l’accord préalable exprès de ces derniers.</p>

                                    <h4 className="mt-4 mb-4">12.2.	Responsabilité d’HYDROGEN</h4>

                                    <p>HYDROGEN n’entend pas garantir l’exactitude des Messages postées ou partagés via le Site, ni cautionner les opinions pouvant apparaître, les propos tenus étant uniquement l’expression de la pensée de chaque Utilisateur.</p>

                                    <p>HYDROGEN ne saurait être tenu civilement responsable des Messages publiés par l’Utilisateur qui est seul responsable du contenu et de l’usage possible de ces derniers. Ainsi, l’Utilisateur s’engage à garantir HYDROGEN contre toute réclamation de tiers qui se trouveraient lésés par le contenu des Messages qu’il a postés ou partagés via le Site.</p>

                                    <p>En revanche, l’Utilisateur peut, à tout moment, signaler à HYDROGEN tout Message ou tout comportement d’un Utilisateur qui lui semblerait abusif en envoyant un email à l’adresse suivante support@hydrogen-rempla.com; ou bien en adressant un courrier à HYDROGEN.</p>

                                    <p>Dans ce cas, HYDROGEN se réserve le droit discrétionnaire de signaler l’incident à la personne concernée, et dans ce cas de l’enjoindre à prendre les mesures et correctifs appropriés vis-à-vis de la personne responsable en fonction de la gravité de l’évènement, et/ou prendre elle-même les actions qu’elle estime nécessaires au maintien de la réputation et de la probité de son Site (avertissement ; suppression du Compte de l’Utilisateur en cause ; etc.)</p>

                                    <h4 className="mt-4 mb-4">12.3.	Accès aux Messages</h4>

                                    <p>La mise en ligne d’un Message est effectuée automatiquement par HYDROGEN dans ses meilleurs délais après que l’Utilisateur ait soumis son Message via le formulaire concerné.</p>

                                    <p>Les Messages demeurent accessibles sur le site tant que le Service concerné est accessible sur le Site, sous réserve qu’il n’y ait aucun changement de fournisseur ou des caractéristiques principales du Service rendant le Message obsolète.</p>

                                    <p>En publiant un Message, l’Utilisateur comprend et accepte que celui-ci soit diffusé publiquement sur le Site et accessible à tout Utilisateur.</p>

                                    <h4 className="mt-4 mb-4">12.4.	Responsabilité de l’Utilisateur</h4>

                                    <p>L’Utilisateur est seul responsable vis-à-vis des tiers pour les Messages qu'il télécharge, fournit, transfère et partage via le Site dans le cadre de l'utilisation des Services. A ce titre, l’Utilisateur est notamment seul responsable :</p>
                                    <ul>
                                        <li>Du respect de l'ordre public et des bonnes mœurs de ses Messages ;</li>
                                        <li>De la conformité de ces Messages avec la législation et la déontologie médicale, et en particulier en veillant à ce qu'ils ne portent pas atteinte aux droits des tiers, notamment droits de propriété intellectuelle et droits à l’image.</li>
                                    </ul>

                                    <h3 className="mt-5 mb-5">ARTICLE 13.	DONNÉES PERSONNELLES</h3>

                                    <p>HYDROGEN s’engage à ce que les données à caractère personnel de l’Utilisateur soient collectées et traitées conformément à la loi n° 78-17 du 6 janvier 1978 modifiée relative à l'informatique, aux fichiers et aux libertés dite « Informatique et Libertés », et au Règlement (UE) 2016/679 du Parlement et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du Traitement des données à caractère personnel et à la libre circulation de ces données, et à toute législation prise en application de ce Règlement.</p>

                                    <p>Pour en savoir plus sur le traitement de ses données Personnelles, l’Utilisateur est invité à consulter la Politique de traitement des données personnelles de HYDROGEN.</p>

                                    <p>HYDROGEN ne saurait être responsable de l’interprétation et de l’utilisation des Données / Informations figurant sur la Plateforme faites par l’Utilisateur. HYDROGEN n'est pas responsable des avis, des conseils et des questions posées par l’Utilisateur en lien avec les recherches cliniques présentées sur le site. D’une façon plus générale le choix de recruter un patient dans une étude clinique se fait uniquement sous la responsabilité de l’Utilisateur comme indiqué ci-dessus.</p>

                                    <p>En acceptant la transmission de ses coordonnées, l’Utilisateur accepte que les données du patient soient adressées à HYDROGEN sous forme cryptée. A ce titre, l’Utilisateur reconnait qu’avant de transmettre les données de son Patient, il devra accepter la notice d’information de collecte de ses données personnelles. A défaut, le Patient ne pourra pas accéder au Services.</p>

                                    <p>Le décryptage des coordonnées du patient, sur le tableau de bord d’HYDROGEN, est effectif après transmission d’un SMS et validation, qui lui permet d’accepter les CGU/CGV. Le Patient accepte alors expressément que ses données à caractère personnel de santé soient collectées et traitées par le biais de la Plateforme, conformément à la politique de confidentialité de la Plateforme, étant précisé que l’Utilisateur consulté par le Patient et HYDROGEN agissent tous deux en tant que co-responsables de traitement des données personnelles du Patient.</p>

                                    <p>Les membres de la SAS HYDROGEN s’obligent à présenter un haut degré de professionnalisme sur le plan déontologique et notamment s’assurent que les études cliniques présentées sur son Site respectent les caractéristiques ci-dessous :</p>

                                    <ul>
                                        <li>HYDROGEN vérifie que les essais cliniques présents sur sa plate-forme ont reçu un accord d’un Comité de Protection des Personnes (CPP) situé sur le territoire Français.</li>
                                        <li>Les Promoteurs qui souhaitent intégrer des Essais cliniques sur des médicaments ou sur des dispositifs médicaux doivent présenter une attestation de l’Agence Nationale Sur le Médicament (ANSM) valide et à jour.</li>
                                        <li>HYDROGEN s’assure en amont, que les critères de sélection des patients soient compatibles avec ses capacités de recrutement.</li>
                                    </ul>

                                    <p>D’une manière générale, dans les limites autorisées par la loi, la responsabilité d’HYDROGEN ne saurait être engagée pour tout dommage, subi par le Patient, l’Utilisateur ou par un tiers, résultant directement ou indirectement du non-respect par l’Utilisateur d’une quelconque de ses obligations au titre des présentes ou du Protocole auquel il a été confié en utilisant la Plateforme, ou pour toute négligence et/ou utilisation de la Plateforme à d’autres fins que celles connues par l’Utilisateur.</p>

                                    <p>Tout événement indésirable subit par le Patient en lien avec le processus de recrutement ou avec l’étude clinique dans laquelle il s’inscrit volontairement, ne peut être imputé aux services d’HYDROGEN qui n’a qu’un rôle d’apporteur de patients.</p>

                                    <p>Dans les limites autorisées par la loi, la responsabilité d’HYDROGEN ne peut être engagée qu’en cas de faute ou de négligence prouvée et est limitée aux préjudices directs à l’exclusion de tout préjudice indirect, de quelque nature que ce soit, tel que notamment toute perte de chance, frais et coûts, ou préjudice financier, atteinte à l’image et/ou à la réputation intellectuelle.</p>

                                    <h3 className="mt-5 mb-5">ARTICLE 14.	Droit de RETRACTATION</h3>

                                    <p>Conformément au Code de déontologie médicale, le Patient dispose du droit de se rétracter ou d’accéder à ses données personnelles pour les consulter et ou les modifier. Il peut aussi exercer son droit de rétractation légal sans avoir à motiver sa décision. </p>

                                    <p>Pour se faire, il doit indiquer clairement son souhait en adressant à HYDROGEN :</p>

                                    <ul>
                                        <li>Un courrier électronique à l’adresse mail support@hydrogen-rempla.com</li>
                                        <li>ou un courrier postal à l’adresse indiqué en préambule avec indication claire de son identité et ses coordonnées (nom, prénom – coordonnées postales/téléphoniques)</li>
                                        <li>ou en utilisant le modèle de formulaire ci-après, à adresser à la Société, par courrier postal ou email à l’une des adresses ci-dessus :</li>
                                    </ul>

                                    <p>Modèle de formulaire de rétractation : (à compléter et à renvoyer à HYDROGEN de préférence en lettre recommandée avec accusé de réception ou par email)</p>

                                    <p>« À l'attention d’HYDROGEN,</p>

                                    <p>Je, soussigné(e) (nom, prénom), domicilié (adresse) vous notifie par la présente ma rétractation à la visite d’inclusion de l’étude clinique (nom de l’étude) présentée sur la plateforme HYDROGEN. </p>

                                    <p>Accord préalable transmis le : / /</p>

                                    <p>Signature du Patient (uniquement en cas de notification du présent formulaire sur papier) : Date : »</p>

                                    <p>Il est précisé au Patient que l’utilisation du modèle de formulaire de rétractation n’est pas obligatoire.</p>

                                    <h3 className="mt-5 mb-5">ARTICLE 15.	PROPRIÉTÉ INTELLECTUELLE</h3>

                                    <h4 className="mt-4 mb-4">15.1.	Licence d’utilisation du Site et de son Contenu</h4>

                                    <p>Les articles ci-dessous présentent les règles spéciales régissant la Licence concédée à l’Utilisateur. Il est précisé que les autres stipulations plus générales des présentes CGU sont également applicables à ladite concession dont elles sont le complément nécessaire.</p>

                                    <p>Dans le cadre de l’utilisation du Site, l’Utilisateur devra se conformer à la procédure indiquée et aux règles d’utilisation indiquée par HYDROGEN.</p>

                                    <p>HYDROGEN concède à l’Utilisateur, qui l’accepte, pour la durée et le territoire prévus ci-après, une licence non-exclusive, non-transférable et non cessible d’utilisation du Site et de son Contenu, expressément limitée aux actes nécessaires à l’utilisation des Services offerts par le Site et pour permettre leur délivrance à l’Utilisateur, dans la limite des Services effectivement fournis par HYDROGEN sur son Site (ci-après la « Licence »).</p>

                                    <p>L’Utilisateur s’interdit expressément tout autre acte d’utilisation ou d’exploitation, et notamment :</p>
                                    <ul>
                                        <li>Créer des liens vers d’autres sites ;</li>
                                        <li>Toute modification, traduction, adaptation, arrangement, tests ou analyses du Site ou de son Contenu ;</li>
                                        <li>Toute reproduction à destination de tiers ;</li>
                                        <li>Préparer des œuvres dérivées à partir de, modifier, copier ou utiliser le Site ou son Contenu de toute manière, à l’exception de ce qui est expressément autorisé dans les présentes CGU/CGV ;</li>
                                        <li>Supprimer ou altérer les mentions d’identification ou de propriété du Site ou de son Contenu.</li>
                                    </ul>

                                    <p>Dans l’hypothèse où l’Utilisateur envisage d’utiliser le Site ou son Contenu à des fins autres que celles prévues aux présentes, celui-ci en avertira par écrit HYDROGEN qui pourra, à sa seule discrétion, refuser ou autoriser cette utilisation selon les termes et conditions qu’elle définira.</p>

                                    <p>La présente Licence est concédée pour l'utilisation du Site et de son Contenu à l’Utilisateur pour tous les territoires du monde entier pour une période de temps limitée à la durée d’utilisation du Site et de son Contenu par l’Utilisateur.</p>

                                    <h4 className="mt-4 mb-4">15.2.	Garantie de jouissance paisible</h4>

                                    <p>HYDROGEN déclare qu’elle détient ou dispose de tous les droits de propriété intellectuelle nécessaires à l’exécution du Contrat et que rien, en conséquence, ne s’oppose à leur concession à l’Utilisateur.</p>

                                    <p>HYDROGEN garantit également l’absence de nécessité pour l’Utilisateur d’obtenir d’autres licences, cessions ou concessions de droits de propriété intellectuelle d’aucune sorte en vue de l’utilisation des Contenus.</p>

                                    <p>HYDROGEN garantit l’Utilisateur contre toute action en contrefaçon ou toute action tendant à interdire l'utilisation du Site ou de son Contenu. Ainsi, HYDROGEN prendra à sa charge tous dommages et intérêts et frais y afférents (tels que les frais de défense de ses droits en justice) auxquels pourrait être condamné l’Utilisateur par une décision de justice devenue définitive et qui auraient pour base exclusive la démonstration d'une contrefaçon ou d’une atteinte à des droits de propriété intellectuelle qui se rattacherait à l’utilisation des Contenus. </p>

                                    <p>Les dispositions ci-dessus sont soumises aux conditions cumulatives suivantes :</p>
                                    <ul>
                                        <li>que l’Utilisateur ait notifié à bref délai, l'action en justice ou la déclaration ayant précédé cette action, et,</li>
                                        <li>qu’HYDROGEN ait été en mesure d'assurer librement la défense de ses propres intérêts et ceux de l’Utilisateur et que, pour ce faire, l’Utilisateur ait collaboré loyalement à ladite défense en fournissant, en temps utile, tous les éléments, informations et assistance nécessaires pour mener à bien une telle défense qui auraient été demandés par HYDROGEN. </li>
                                    </ul>

                                    <p>Dans le cas où l'interdiction d'utilisation de tout ou partie des éléments composant le Site ou les Contenus serait prononcée en conséquence d'une action en contrefaçon ou d’une action fondée sur une atteinte à des droits de propriété intellectuelle, ou résulterait d'une transaction signée avec le demandeur à l'action précitée, HYDROGEN s'efforcera, à son choix et à ses frais :</p>

                                    <ul>
                                        <li>soit d'obtenir le droit pour l’Utilisateur de poursuivre l'utilisation des éléments visés ;</li>
                                        <li>soit de remplacer les éléments de façon à éviter les manquements précités.</li>
                                    </ul>

                                    <h4 className="mt-4 mb-4">15.3.	Respect des droits de propriété intellectuelle</h4>

                                    <p>HYDROGEN conserve la propriété de tous les droits de propriété intellectuelle qui sont utilisés pour faire fonctionner le Site, et plus généralement tous les éléments reproduits ou utilisés sur ces derniers sont protégés au titre de la propriété intellectuelle, y compris le Contenu (ci-après ensemble les « Eléments »).</p>

                                    <p>Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces Eléments, sans l'accord préalable et écrit de HYDROGEN, sont strictement interdites.</p>

                                    <p>Le fait pour HYDROGEN de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites.</p>

                                    <h4 className="mt-4 mb-4">15.4.	Respect du droit d’auteur - Copyright © - Liens (appartenant à HYDROGEN ou à ses partenaires)</h4>

                                    <p>Le Site respecte le droit d'auteur, ainsi que les droits voisins (ci-après les « Droits d’Auteur »). Tous les droits d’auteur des œuvres protégées reproduites et communiquées sur le Site sont réservés pour le monde entier. Sauf autorisation, toute utilisation des œuvres autres que la reproduction et la consultation individuelles et privées sont interdites.</p>

                                    <h4 className="mt-4 mb-4">15.4.1.	 Reproduction sur support papier</h4>

                                    <p>La reproduction (impression) des pages du Site sur un support papier est autorisée pour un usage strictement privé et non commercial.</p>

                                    <h4 className="mt-4 mb-4">15.4.2.	Reproduction sur support électronique</h4>

                                    <p>La reproduction de tout ou partie du Site sur un support électronique est interdite.</p>

                                    <h4 className="mt-4 mb-4">15.5.	Liens</h4>

                                    <h5>15.5.1.	Création de liens vers le Site</h5>

                                    <p>Le Site autorise la mise en place d’un lien hypertexte pointant vers son Contenu, sous réserve de : </p>
                                    <ul>
                                        <li>Ne pas utiliser la technique du lien profond ("deep linking"), c'est-à-dire que les pages du Site ne doivent pas être imbriquées à l'intérieur des pages d'un autre site, mais accessibles par l’ouverture d’une fenêtre ;</li>
                                        <li>Mentionner la source qui pointera grâce à un lien hypertexte directement sur le Contenu visé ;</li>
                                        <li>Les informations utilisées ne doivent l'être qu'à des fins personnelles, associatives ou professionnelles ;</li>
                                        <li>Toute utilisation à des fins commerciales ou publicitaires est exclue, sauf accord exprès de HYDROGEN ;</li>
                                        <li>Ces autorisations ne s’appliquent pas aux sites internet diffusant des informations à caractère polémique, pornographique, incitant à la haine raciale ou à toute forme de discrimination ou pouvant, dans une plus large mesure, porter atteinte à la sensibilité du plus grand nombre ;</li>
                                        <li>Pour d'autres utilisations, veuillez consulter HYDROGEN.</li>
                                    </ul>

                                    <h4 className="mt-4 mb-4">15.6.	Limitations</h4>

                                    <p>Toutes les marques et autres droits de propriété intellectuelle sur tout Contenu du Site (incluant notamment les informations, textes, images, droits d’auteur, dessins et modèles) et sur la structure du Site appartiennent à HYDROGEN. Aucune utilisation de ce Contenu et des droits de propriété intellectuelle y afférant n'est autorisée sans avoir préalablement requis le consentement écrit et exprès de HYDROGEN. Tous ces droits sont expressément réservés.</p>

                                    <p>L’Utilisateur s'engage à respecter les droits de propriété intellectuelle de HYDROGEN sur chacun des éléments constituant le Site et à faire respecter ces droits par les tiers.</p>

                                    <p>L’Utilisateur s'engage à informer sans délai HYDROGEN de toute atteinte aux droits de propriété intellectuelle de cette dernière qu'il pourra éventuellement constater. En particulier, l’Utilisateur s’interdit de :</p>
                                    <ul>
                                        <li>Vendre, publier, afficher, divulguer ou mettre à la disposition de tout tiers le Contenu du Site mis à disposition par HYDROGEN ;</li>
                                        <li>Effectuer l'ingénierie inverse, décompiler ou désassembler le Contenu du Site, sauf si expressément autorisé par la loi ;</li>
                                        <li>Utiliser ou copier le Contenu du Site d'une manière qui n'est pas expressément autorisée par HYDROGEN ;</li>
                                        <li>Louer, prêter ou utiliser le Contenu du Site dans le but de le partager ou de le mettre à la disposition des tiers ;</li>
                                        <li>Publier tout test ou analyse ou évaluation de la performance relative au Contenu du Site sans autorisation écrite préalable ;</li>
                                        <li>Accéder aux codes sources du logiciel caractérisant le Site.</li>
                                    </ul>

                                    <p>L'Utilisateur s'engage à informer immédiatement HYDROGEN de toute atteinte à la propriété intellectuelle qu'il pourrait remarquer.</p>

                                    <h3 className="mt-5 mb-5">ARTICLE 16.	RESPONSABILITÉ</h3>

                                    <p>HYDROGEN ne pourra être tenue responsable de l’utilisation que font les Utilisateurs du Site. Ces derniers reconnaissent et acceptent qu’ils seront seuls responsables en cas d’utilisation du Site contraire à l’ordre public, à la déontologie médicale, aux bonnes mœurs ou à toute loi ou règlement.</p>

                                    <p>HYDROGEN s’engage à veiller au bon fonctionnement du Site, cependant sa responsabilité ne saurait être engagée pour tous les inconvénients ou dommages inhérents à l'utilisation du réseau Internet, notamment une rupture de service, une intrusion extérieure ou la présence de virus informatiques.</p>

                                    <h3 className="mt-5 mb-5">ARTICLE 17.	FORCE MAJEURE</h3>

                                    <p>Les Parties ne pourront être tenues pour responsables si la non-exécution ou le retard dans l'exécution d'une quelconque de leurs obligations, telles que décrites dans les présentes découle d'un cas de force majeure, au sens de l'article 1218 du Code civil et de la jurisprudence des juridictions françaises.</p>

                                    <p>La Partie constatant l'événement devra sans délai informer l'autre partie de son impossibilité à exécuter sa prestation et s'en justifier auprès de celle-ci. La suspension des obligations ne pourra en aucun cas être une cause de responsabilité pour non-exécution de l'obligation en cause, ni induire le versement de dommages et intérêts ou pénalités de retard.</p>

                                    <p>L'exécution de l'obligation est suspendue pendant toute la durée de la force majeure si elle est temporaire et ne dépasse pas une durée de trente (30) jours. Par conséquent, dès la disparition de la cause de la suspension de leurs obligations réciproques, les Parties feront tous leurs efforts pour reprendre le plus rapidement possible l'exécution normale de leurs obligations contractuelles. A cet effet, la Partie empêchée avertira l'autre de la reprise de son obligation par lettre recommandée avec demande d'avis de réception ou tout acte extrajudiciaire. Si l'empêchement est définitif ou dépasse une durée de trente (30) jours, les présentes seront purement et simplement résolues.</p>

                                    <p>Pendant cette suspension, les Parties conviennent que chacune d’elles prendra à sa charge ses propres frais engendrés par la situation.</p>

                                    <h3 className="mt-5 mb-5">ARTICLE 18.	NOTIFICATION</h3>

                                    <p>Toute notification requise aux termes des présentes conditions devra être envoyée à l'adresse donnée en tête de ce document s’agissant de HYDROGEN, et à l’adresse renseignée par l’Utilisateur lors de la création de son Compte, régulièrement mise à jour le cas échéant.</p>

                                    <p>Toutes notifications effectuées en application des présentes seront valablement effectuées et seront considérées comme reçues dans les conditions suivantes :</p>

                                    <ul>
                                        <li>E-mail : au jour d'envoi ;</li>
                                        <li>Lettre recommandée AR - le lendemain de la date portée sur l’accusé de réception.</li>
                                    </ul>

                                    <h3 className="mt-5 mb-5">ARTICLE 19.	LITIGE ET JURIDICTION </h3>

                                    <p>Les présentes CGU/CGV sont soumises au droit Français.</p>

                                    <p>En vue de trouver ensemble une solution à tout litige qui surviendrait dans l'exécution des présentes, les Parties conviennent de se concerter, en présentielle ou à distance, dans un délai de TRENTE (30) jours à compter de la réception d'une lettre recommandée avec demande d'avis de réception, notifiée par l'une des deux Parties, ou d’un courriel signé numériquement.</p>

                                    <p>Si au terme d'un nouveau délai de trente (30) jours, les Parties ne parvenaient pas à se mettre d'accord sur un compromis ou une solution, en cas de difficulté d’exécution des présentes et de leur suite, tous les litiges auxquels les présentes et les accords qui en découlent pourraient donner lieu, concernant tant leur validité, leur interprétation, leur exécution, leur résolution, leurs conséquences et leurs suites seront soumis au tribunal du siège social d’HYDROGEN au jour de l’assignation.</p>

                                </CardBody>

                            </Card>

                        </Col>

                    </Row>

                </div>

            </div>
        );
    }
}



export default TermsPatients;
