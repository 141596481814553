import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';


export default class InfoModal extends React.Component {

    render() {
        return (
            <Modal isOpen={true} toggle={this.props.toggle} size="md" className="simple-modal simple-modal-green srounded">

                <ModalHeader toggle={this.props.onClose}>
                    <button type="button" className="close d-inline-block b-green" aria-label="Close" onClick={this.props.toggle} ><span aria-hidden="true">×</span></button>
                </ModalHeader>

                <ModalBody className="p-4">

                    {this.props.content}

                    <div className="mt-4 mb-3">
                        <Button 
                            className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-warning btn opacity-1 mr-0"
                            onClick={this.props.toggle}>
                            <FormattedMessage id="Ok" />
                        </Button>
                    </div>

                </ModalBody>

            </Modal>
        );

    }

}
