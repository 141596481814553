export default class FileUtil {

    static extension(fileName) {
        var fragments = fileName.split(".");
        return fragments[fragments.length - 1];
    }

    static extensionIsOk(fileName, acceptedExtensions) {
        var extension = FileUtil.extension(fileName);

        for (let ext of acceptedExtensions) {
            if (extension === ext) return true;
        }

        return false;
    }

    static withoutExtension(fileName) {
        let extension = FileUtil.extension(fileName);
        return fileName.replace("." + extension, "");
    }

    // This will normalize the name to fit file names requirements (no accents, lowercase, special characters etc.)
    static toFileName(name) {
        return name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-z0-9]/gi, '_').toLowerCase();
    }

    static downloadPDF(name, buffer) {
        // Save received data into a PDF file
        let pdf = new Blob([buffer], { type: "application/pdf" });
        
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdf);
        link.download = name + ".pdf";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

}