import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import ClinicalStudyCard from './ClinicalStudyCard';

import { getClinicalStudy } from '../../actions/clinicalStudy/clinicalStudy'

export class ClinicalStudy extends React.Component {

	componentDidMount() {
		if(!this.props.listOfClinicalStudy) {
			this.props.getClinicalStudy();
		}
	}

	render() {
		const { listOfClinicalStudy, isGettingClinicalStudy } = this.props;

		return(
			<React.Fragment>
                <div className="w-100 text-white text-center uppercase lp-2 pb-4">
                    <h4><FormattedMessage id="List.Clinical.Study" /><Badge color="danger" pill>{listOfClinicalStudy && listOfClinicalStudy.length}</Badge></h4>
                </div>

				{isGettingClinicalStudy &&
					<div className="w-100 text-center mt-2 mb-2">
						<div className="spinner-border text-white mx-auto" role="status">
						</div>
					</div>
				}

				{listOfClinicalStudy && listOfClinicalStudy.map((clinicalStudy, key) => 
					<ClinicalStudyCard key={key} clinicalStudy={clinicalStudy} />
				)}
			</React.Fragment>
		)
	}
}

function mapStateToProps(state) {
	return {
		listOfClinicalStudy: state.clinicalStudy.listOfClinicalStudy,
		isGettingClinicalStudy: state.clinicalStudy.isGettingClinicalStudy
	}
}

function mapDispatchToProps(dispatch) {
	return {
		getClinicalStudy: () => dispatch(getClinicalStudy())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalStudy);