import React from 'react';
import { Button } from 'reactstrap';
import { InputComposer, InputComponent } from '@gferrand/react-forms';

export default class AllWeekDaysInput extends InputComposer {

    valid = () => true;

    getComponent() {
        return <AllWeekDaysInputComponent />;
    }

}

class AllWeekDaysInputComponent extends InputComponent {

    constructor(props) {
        super(props);

        this.state = {
            value: [],
            error: null
        };
    }

    __onChange(value){
        if(value === "allDays") {
            if(!this.state.value.includes(value)) {
                this.setState({ value: ['allDays'] }, this.onChange(value));
            }
        } else if(this.state.value.includes(value)) {
            if (this.state.value.length !== 1) {
                this.setState({ value: this.state.value.filter(v => v !== value )}, this.onChange(value));
            }
        } else {
            if (this.state.value.length < 2) {
                this.setState({ value: [...this.state.value.filter(v => v !== "allDays"), value ] }, this.onChange(value));
            }
        } 
    }

    createButton(letter, value) {
        
            return (
                <Button 
                    type="button"
                    className={"radio-btn btn btn-primary " + ((this.state.value.includes(value)) ? "active" : "")}
                    onClick={() => this.__onChange(value)}
                    >
                    <span>{letter}</span>
                </Button>
            )
    }

    createButtonAll(letter, value) {
        return (
            <Button 
                type="button"
                className={"radio-btn btn btn-primary " + ((this.state.value.includes(value)) ? "active" : "")}
                style={{height: "auto", width: "auto"}}
                onClick={() => this.__onChange(value)}
                >
                <span>{letter}</span>
            </Button>
        )
    }

    render() {
        return super.wrap(
            <div id="day" className="btn-group">

                {this.createButton("L", "monday")}
                {this.createButton("M", "tuesday")}
                {this.createButton("M", "wednesday")}
                {this.createButton("J", "thursday")}
                {this.createButton("V", "friday")}
                {this.createButton("S", "saturday")}
                {this.createButtonAll("Tous les jours", "allDays")}
            </div>
        );
    }
}