import React from 'react';


class Footer extends React.Component {
  render() {
    return null;

    // return (
    //   <footer className="footer text-center">
    //     All Rights Reserved by Xtreme admin. Designed and Developed by{' '}
    //     <a href="#/">WrapPixel</a>.
    //   </footer>
    // );
  }
}
export default Footer;
