import React from 'react';
import { connect } from 'react-redux';
import { Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import AppointmentUtil from '../../../util/AppointmentUtil';
import { addExceptionalClose, editExceptionalClose, deleteExceptionalClose } from '../../../actions/exceptionalClose/exceptionalClose';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from 'moment';

const localizer = momentLocalizer(moment);

class SecModalExceptionalClose extends React.Component {

    constructor(props) {
        super(props);

        moment.locale('fr');

        let start_date = moment(this.props.dateNewAppointmentSelected.start).format('YYYY-MM-DD');
        let start_hour = moment(this.props.dateNewAppointmentSelected.start).format('HH:mm');
        let end_date = moment(this.props.dateNewAppointmentSelected.end).format('YYYY-MM-DD');
        let end_hour = moment(this.props.dateNewAppointmentSelected.end).format('HH:mm');

        if (this.props.dateNewAppointmentSelected.original) {
            start_date = moment(this.props.dateNewAppointmentSelected.original.start_date).format('YYYY-MM-DD');
            start_hour = moment(this.props.dateNewAppointmentSelected.original.start_date).format('HH:mm');
            end_date = moment(this.props.dateNewAppointmentSelected.original.end_date).format('YYYY-MM-DD');
            end_hour = moment(this.props.dateNewAppointmentSelected.original.end_date).format('HH:mm');
        }

        this.state = {
            start_date: start_date,
            start_hour: start_hour,
            end_date: end_date,
            end_hour: end_hour,
            error: '',
            closeModal: false,
            deleteModal: false,
            overlap: '',
            overlapModal: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.dayEventCustom = this.dayEventCustom.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this);
        this.closeModalDelete = this.closeModalDelete.bind(this);
        this.closeModalOverlap = this.closeModalOverlap.bind(this);
        this.closeModalSave = this.closeModalSave.bind(this);
    };

    componentDidMount() {
        let overlap = AppointmentUtil.checkOverlapWithAppointment(this.state.start_date, this.state.end_date, this.state.start_hour, this.state.end_hour, this.props.appointmentsByUsers, this.props.selectedUser.id);

        this.setState({
            overlap: overlap
        })
    }

    customToolBar() {
        return [];
    }

    dayEventCustom(event) {
        let css = 'pt-1 pl-1';
        let content = moment(event.event.start).format('HH:mm') + ' ' + event.title;

        if (event.event.css === 'replacement') {
            content = event.event.title;
            css = 'pt-1 bg-occasional';
        } else if (event.event.css === 'emptyAppointment') {
            content = moment(event.event.start).format('HH:mm') + ' ' + event.event.title;
        }

        return (
            <div className={'text-color-black ' + css}>
                {content}
            </div>
        )
    }

    closeModal() {
        this.setState({
            closeModal: true
        })
    }

    closeModalDelete() {
        this.setState({
            deleteModal : false
        })
    }

    closeModalSave() {
        this.setState({
            closeModal: false
        })
    }

    closeModalOverlap() {
        this.setState({
            overlapModal: false
        })
    }

    handleChange(event) {
        let { name, value } = event.currentTarget;

        if (name === 'start_date') {
            let startDate = moment(value).set({
                hour: moment(this.state.start_hour, 'HH:mm').hour(),
                minute: moment(this.state.start_hour, 'HH:mm').minute(),
            });

            let endDate = moment(this.state.end_date).set({
                hour: moment(this.state.end_hour, 'HH:mm').hour(),
                minute: moment(this.state.end_hour, 'HH:mm').minute(),
            });

            if (startDate.isSameOrAfter(endDate, 'minutes')) {
                this.setState({
                    error: 'Vous ne pouvez pas avoir un horaire de fin avant l\'horaire de début'
                })
                return;
            }

            let overlap = AppointmentUtil.checkOverlapWithAppointment(value, this.state.end_date, this.state.start_hour, this.state.end_hour, this.props.appointmentsByUsers, this.props.selectedUser.id);
            
            this.setState({
                overlap: overlap
            })
        } else if (name === 'start_hour') {
            let startDate = moment(this.state.start_date).set({
                hour: moment(value, 'HH:mm').hour(),
                minute: moment(value, 'HH:mm').minute(),
            });

            let endDate = moment(this.state.end_date).set({
                hour: moment(this.state.end_hour, 'HH:mm').hour(),
                minute: moment(this.state.end_hour, 'HH:mm').minute(),
            });

            if (startDate.isSameOrAfter(endDate, 'minutes')) {
                this.setState({
                    error: 'Vous ne pouvez pas avoir un horaire de fin avant l\'horaire de début'
                })
                return;
            }

            let overlap = AppointmentUtil.checkOverlapWithAppointment(this.state.start_date, this.state.end_date, value, this.state.end_hour, this.props.appointmentsByUsers, this.props.selectedUser.id);

            this.setState({
                overlap: overlap
            })
        } else if (name === 'end_date') {
            let startDate = moment(this.state.start_date).set({
                hour: moment(this.state.start_hour, 'HH:mm').hour(),
                minute: moment(this.state.start_hour, 'HH:mm').minute(),
            });

            let endDate = moment(value).set({
                hour: moment(this.state.end_hour, 'HH:mm').hour(),
                minute: moment(this.state.end_hour, 'HH:mm').minute(),
            });

            if (startDate.isSameOrAfter(endDate, 'minutes')) {
                this.setState({
                    error: 'Vous ne pouvez pas avoir un horaire de fin avant l\'horaire de début'
                })
                return;
            }

            let overlap = AppointmentUtil.checkOverlapWithAppointment(this.state.start_date, value, this.state.start_hour, this.state.end_hour, this.props.appointmentsByUsers, this.props.selectedUser.id);

            this.setState({
                overlap: overlap
            })
        } else if (name === 'end_hour') {
            let startDate = moment(this.state.start_date).set({
                hour: moment(this.state.start_hour, 'HH:mm').hour(),
                minute: moment(this.state.start_hour, 'HH:mm').minute(),
            });

            let endDate = moment(this.state.end_date).set({
                hour: moment(value, 'HH:mm').hour(),
                minute: moment(value, 'HH:mm').minute(),
            });

            if (startDate.isSameOrAfter(endDate, 'minutes')) {
                this.setState({
                    error: 'Vous ne pouvez pas avoir un horaire de fin avant l\'horaire de début'
                })
                return;
            }

            let overlap = AppointmentUtil.checkOverlapWithAppointment(this.state.start_date, this.state.end_date, this.state.start_hour, value, this.props.appointmentsByUsers, this.props.user._id);

            this.setState({
                overlap: overlap
            })
        }

        this.setState({
            [name]: value,
            error: ''
        })
    }

    onDelete() {
        this.setState({
            deleteModal: true
        })
    }

    onDeleteConfirmation() {
        this.props.deleteExceptionalClose(this.props.dateNewAppointmentSelected.original._id, this.props.close());
    }

    onSubmit() {
        if (this.state.overlap && this.state.overlapModal === false) {
            this.setState({
                overlapModal: true
            });
            return;
        }

        let exceptionalClose = {
            start_date: this.state.start_date,
            start_hour: this.state.start_hour,
            end_date: this.state.end_date,
            end_hour: this.state.end_hour,
            doctor_id: this.props.selectedUser.id,
            user_id: this.props.user._id,
            overlap: this.state.overlap ? true : false,
            name: this.props.selectedUser.name + ' ' + this.props.selectedUser.first_name
        }

        // cas d'une édition
        if (this.props.dateNewAppointmentSelected.original) {
            exceptionalClose._id = this.props.dateNewAppointmentSelected.original._id;
            this.props.editExceptionalClose(exceptionalClose, this.props.close());
        } else {
            this.props.addExceptionalClose(exceptionalClose, this.props.close());
        }
    }

    render() {
        const { arrayAgreements, appointmentsByUsers } = this.props;
        const appointmentsEvents = (appointmentsByUsers && appointmentsByUsers.length) ? AppointmentUtil.formatAppointments(appointmentsByUsers, this.props.selectedUser.id) : [];
        let concatEvents = arrayAgreements.concat(appointmentsEvents);

        return (
            <>
                <Modal size="lg" isOpen={true} toggle={this.props.dateNewAppointmentSelected.original ? this.closeModal : this.props.close} className="simple-modal simple-modal-white srounded modalAppointmentCustom">

                    <ModalHeader className="header-modal-choice">
                        <div className="text-left">
                            <button type="button" onClick={this.props.dateNewAppointmentSelected.original ? this.closeModal : this.props.close} className="close d-inline-block" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div>
                            {this.props.dateNewAppointmentSelected.original ?
                                'Absences'
                            :
                                'Ajouter une absence'
                            }
                        </div>
                    </ModalHeader>

                    <ModalBody className="row">
                        <Col sm="4" id="calendarModalNewAppointment">
                            <Calendar
                                components={{ 
                                    toolbar: this.customToolBar,
                                    day: {
                                        event: this.dayEventCustom,
                                    },
                                }}
                                formats={{
                                    eventTimeRangeFormat: range =>
                                    ``
                                }}
                                renderable
                                popup={true}
                                step={15}
                                slots={4}
                                min={new Date(0, 0, 0, 7, 0, 0)}
                                max={new Date(0, 0, 0, 20, 0, 0)}
                                localizer={localizer}
                                defaultDate={new Date(this.state.start_date)}
                                date={new Date(this.state.start_date)}
                                onView={{}}
                                events={concatEvents}
                                view={'day'}
                                views={[
                                    'day'
                                ]}
                                slotPropGetter={(date) => AppointmentUtil.customSlotPropGetterDoctor(date, this.props.arrayOfficeHours, this.props.exceptionalOpen, this.props.exceptionalClose)}
                                culture={'fr'}
                                onNavigate={[]}
                                dayLayoutAlgorithm="no-overlap"
                            />
                        </Col>

                        <Col sm="8">
                            <div className='jumbotron'>
                                <FormGroup row className="justify-content-center pt-2">
                                    <Label lg={2}>Date de début de l'absence</Label>
                                    <Col lg={6}>
                                        <Input type="date" name="start_date" value={this.state.start_date} onChange={this.handleChange} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="justify-content-center pt-2">
                                    <Label lg={2}>Heure de début</Label>
                                    <Col lg={6}>
                                        <Input type="time" name="start_hour" value={this.state.start_hour} onChange={this.handleChange} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="justify-content-center pt-2">
                                    <Label lg={2}>Date de fin de l'absence</Label>
                                    <Col lg={6}>
                                        <Input type="date" name="end_date" value={this.state.end_date} onChange={this.handleChange} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="justify-content-center pt-2">
                                    <Label lg={2}>Heure de fin</Label>
                                    <Col lg={6}>
                                        <Input type="time" name="end_hour" value={this.state.end_hour} onChange={this.handleChange} />
                                    </Col>
                                </FormGroup>
                            </div>

                            {this.state.overlap && 
                                <div className='text-danger'>
                                    {this.state.overlap}
                                </div>
                            }
                            {this.state.error && 
                                <div className='text-danger'>
                                    {this.state.error}
                                </div>
                            }
                            {this.props.dateNewAppointmentSelected.original &&
                                this.props.dateNewAppointmentSelected.original.patients.length > 0 &&
                                <div className='text-center pt-3 row justify-content-center'>
                                    Les patients suivants ne peuvent recevoir la notification car le mail de ces patients n’a pas été renseigné : 
                                    <ul className='col-6 pt-3'>
                                        {this.props.dateNewAppointmentSelected.original.patients.map((patient, key) =>
                                            <li key={key} className='d-flex justify-content-center'>{patient.name} {patient.first_name}</li>
                                        )}
                                    </ul>
                                </div>
                            }

                            <Button color="secondary" onClick={this.props.close}>Annuler</Button>
                            <Button color="success" onClick={this.onSubmit}>
                                {this.props.dateNewAppointmentSelected.original ?
                                    'Enregistrer'
                                        :
                                    'Ajouter'    
                                }
                            </Button>
                            {this.props.dateNewAppointmentSelected.original &&
                                <Button color="danger" onClick={this.onDelete}>Supprimer</Button>
                            }
                        </Col>
                    </ModalBody>
                </Modal>

                {this.state.closeModal === true && 
                    <Modal size="lg" isOpen={true} toggle={this.closeModalSave} className="simple-modal simple-modal-white srounded modalCustom">

                        <ModalHeader className="header-modal-choice">
                            <div className="text-left">
                                <button type="button" onClick={this.closeModalSave} className="close d-inline-block" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div>
                                Validation
                            </div>
                        </ModalHeader>

                        <ModalBody className="row">
                            <Col sm="12">
                                Les modifications effectuées n’ont pas été enregistrée. Souhaitez-vous les enregistrer ?
                            </Col>

                        </ModalBody>

                        <ModalFooter className="justify-content-center">
                            <Button color="secondary" onClick={this.props.close}>Quitter</Button>
                            <Button color="success" onClick={this.onSubmit}>Enregistrer</Button>
                        </ModalFooter>
                    </Modal>
                }

                {this.state.deleteModal === true && 
                    <Modal size="lg" isOpen={true} toggle={this.closeModalDelete} className="simple-modal simple-modal-white srounded modalCustom">

                        <ModalHeader className="header-modal-choice">
                            <div className="text-left">
                                <button type="button" onClick={this.closeModalDelete} className="close d-inline-block" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div>
                                Suppression
                            </div>
                        </ModalHeader>

                        <ModalBody className="row">
                            <Col sm="12">
                                Souhaitez-vous supprimer l'absence du {moment(this.state.start_date).format('DD/MM/YYYY')} de {this.state.start_hour} au {moment(this.state.end_date).format('DD/MM/YYYY')} {this.state.end_hour} ?
                                {this.state.overlap &&
                                    <div>L'absence était posée sur des rendez-vous patients. C'est dernier ont possiblement été alertés de l'annulation de leur rendez-vous.</div>
                                }
                            </Col>

                        </ModalBody>

                        <ModalFooter className="justify-content-center">
                            <Button color="secondary" onClick={this.closeModalDelete}>Quitter</Button>
                            <Button color="success" onClick={this.onDeleteConfirmation}>Supprimer</Button>
                        </ModalFooter>
                    </Modal>
                }

                {this.state.overlapModal && 
                    <Modal size="lg" isOpen={true} toggle={this.closeModalOverlap} className="simple-modal simple-modal-white srounded modalCustom">

                        <ModalHeader className="header-modal-choice">
                            <div className="text-left">
                                <button type="button" onClick={this.closeModalOverlap} className="close d-inline-block" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div>
                                Ajouter une absence
                            </div>
                        </ModalHeader>

                        <ModalBody className="row">
                            <Col sm="12">
                                Vous êtes sur le point de poser une absence sur une période durant laquelle des consultations sont déjà prévus.
                            </Col>

                        </ModalBody>

                        <ModalFooter className="justify-content-center">
                            <Button color="secondary" onClick={this.closeModalOverlap}>Annuler</Button>
                            <Button color="success" onClick={this.onSubmit}>Ajouter</Button>
                        </ModalFooter>
                    </Modal>
                }

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
		addExceptionalClose: (exceptionalClose, cbk) => dispatch(addExceptionalClose(exceptionalClose, cbk)),
		editExceptionalClose: (exceptionalClose, cbk) => dispatch(editExceptionalClose(exceptionalClose, cbk)),
		deleteExceptionalClose: (exceptionalCloseId, cbk) => dispatch(deleteExceptionalClose(exceptionalCloseId, cbk)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecModalExceptionalClose);