import axios from 'axios';

const rootUrl = process.env.REACT_APP_BE_URL || "http://localhost:7000";

export function createSecretary(data, callback) {

	return dispatch => {
		dispatch(request());

        var url = rootUrl + "/secretary";

        return axios.post(url, data).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure())
        });
	};

    function request() { return { type: "CREATE_SECRETARY_REQUEST" } }
    function success(secretary) { return { type: "CREATE_SECRETARY_SUCCESS", secretary } }
    function failure(error) { return { type: "CREATE_SECRETARY_FAILURE", error } }

}

export function doctorCreateSecretary(data, callback) {

	return dispatch => {
		dispatch(request());

        var url = rootUrl + "/secretary/doctor";

        return axios.post(url, data).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure())
        });
	};

    function request() { return { type: "DOCTOR_CREATE_SECRETARY_REQUEST" } }
    function success(secretary) { return { type: "DOCTOR_CREATE_SECRETARY_SUCCESS", secretary } }
    function failure(error) { return { type: "DOCTOR_CREATE_SECRETARY_FAILURE", error } }

}