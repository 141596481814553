import React from 'react';
import { connect } from 'react-redux';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Collapse } from "reactstrap";
import { removes, upds } from "../../../actions/global/global";
import Util from '../../../util/Util';
import ConfModal from '../../../components/modal/ConfModal';
import DReplacementContractModal from './DReplacementContractModal';
import EditRetrocessionModal from './EditRetrocessionModal';
import ReplacementEnt from "../../../entities/ReplacementEnt";
import DReplCardBuilder from "../../../builders/DReplCardBuilder";
import DReplacementTitle from './DReplacementTitle';
import SubstituteProfileModal from '../../substitutes/profile/SubstituteProfileModal';
import ModalNegociation from '../../negociation/ModalNegociation';
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";

class DReplacementCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            modal: null,
            collapsed: this.props.openCollapse,
            collapseTopic: this.props.openCollapse  ? "applicants" : false,
            focus: this.props.replacement._id === this.props.replacementId ? true : false
        };

        this.focusRef = React.createRef();
        
        this.openConfModalCancelAgreement = this.openConfModalCancelAgreement.bind(this);
        this.cancelAgreement = this.cancelAgreement.bind(this);
        this.openNegociationModal = this.openNegociationModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openContractModal = this.openContractModal.bind(this);
    }

    componentDidMount() {
        this.focusDiv();
    }

    focusDiv() {
        if (this.state.focus === true) {
            this.focusRef.current.focus();
        } 
    }

    closeModal = (closeByAccept) => {
        if (closeByAccept) {
            this.setState({ modal: null });
        } else {
            if (this.props.updateReplacementAfterAccept) {
                this.props.updateReplacementAfterAccept();
            }
            this.setState({ modal: null });
        }
    }

    onDelete = (e, replacement) => {
        e.stopPropagation();

        const isConcluded = ReplacementEnt.isConcluded(replacement);

        if (isConcluded) return;
        
        let deleteReplacement = true;
        if (replacement.agreements && replacement.agreements.length > 0) {
            replacement.agreements.forEach((elem) => {
                if (!elem.cancelled_by_doctor && !elem.cancelled_by_substitute) {
                    deleteReplacement = false;
                }
            })
        }
        if (deleteReplacement === false) return;

        this.openConfModal(<FormattedMessage id="Delete.Warning" />, () => {
            this.props.onRemoves("replacement", replacement);
            this.closeModal();
        }, () => this.closeModal());
    }

    openConfModal(content, onConfirm, onCancel) {
        this.setState({
            modal: <ConfModal
                content={content}
                onConfirm={onConfirm}
                onCancel={onCancel}
                toggle={() => onCancel()}
            />
        });
    }

    // Modal qui contient le contrat à signer
    openContractModal = (replacement, agreement, applicant, substituteCharacteristic) => {
        const { officeTimeTable } = this.props;

        const isPrivate = ReplacementEnt.isPrivate(replacement);

        var modal = <DReplacementContractModal
            redirectAfterSign={this.props.redirectAfterSign}
            agreement={agreement}
            applicant={applicant}
            substituteCharacteristic={substituteCharacteristic}
            replacement={replacement}
            officeTimeTable={officeTimeTable}
            isPrivate={isPrivate}
            toggle={this.closeModal}
            onSignatureCbk={(agreement) => {
                replacement.agreements = replacement.agreements.filter(a => a._id !== agreement._id);
                replacement.agreements.push(agreement);
            }}
        />;

        if (this.state.modal) {
            this.setState({ modal: null }, () => this.setState({ modal: modal }));
        } else {
            this.setState({ modal: modal });
        }
    }

    openEditRetrocessionModal(e, replacement) {
        e.stopPropagation();

        const isConcluded = ReplacementEnt.isConcluded(replacement);

        if (isConcluded) return;

        this.setState({
            modal: <EditRetrocessionModal replacement={replacement} toggle={this.closeModal} />
        });
    };

    pointingArrow = () => {
        return (!this.state.collapsed) ? <i className="fa fa-angle-down" /> : <i className="fa fa-angle-up" />;
    }

    showApplicants() {
        if (this.state.collapsed) {
            this.setState({ collapsed: false });
            return;
        }

        const { replacement } = this.props;

        if (!replacement.applicants || replacement.applicants.length === 0) return;

        this.setState({ collapsed: !this.state.collapsed, collapseTopic: "applicants" });
    }

    showWatchers() {
        if (this.state.collapsed) {
            this.setState({ collapsed: false });
            return;
        }

        this.setState({ collapsed: !this.state.collapsed, collapseTopic: "watchers" });
    }

    openSubProfileModal(e, replacement, sub, char) {
        e.stopPropagation();
        const agreement = replacement.agreements.find(a => a.substitute_id === sub._id);
        this.setState({
            modal: (
                <SubstituteProfileModal
                    replacement={replacement}
                    substitute={sub}
                    characteristics={char}
                    onSignContract={(replacement, agreement, sub, char) => this.openContractModal(replacement, agreement, sub, char)}
                    // onSignContract={(showSignatureBtn) ? () => this.openContractModal(replacement, agreement, sub, char) : undefined}
                    closeModal={this.closeModal}
                    downloadContract={(agreementId, successCbk) => this.downloadContract(agreementId, successCbk)}
                    sendToCouncil={(agreementId, successCbk) => this.sendToCouncil(agreementId, successCbk)}
                    cancelAgreement={() => this.cancelAgreement(replacement, agreement)}
                    openConfModal={() => this.openConfModalCancelAgreement(replacement, agreement)}
                    onDelete={() => this.onDelete(e, replacement)}
                    openNegociationModal={this.openNegociationModal}
                />
            )
        });
    }

    openConfModalCancelAgreement(replacement, agreement) {
        let content = <FormattedMessage id="Delete.Warning" />
        this.setState({
            modal: <ConfModal
                content={content}
                onConfirm={() => this.cancelAgreement(replacement, agreement)}
                onCancel={this.closeModal}
                toggle={this.closeModal}
            />
        });
    }

    // Fonction pour ouvrir la modal de négociation
    openNegociationModal(substitute_id) {
        this.setState({
            modal: <ModalNegociation
                replacement={this.props.replacement}
                close={this.closeModal} 
                doctor={true}
                substitute={substitute_id}
                />
        })
    }

    cancelAgreement(replacement, agreement) {
        this.props.onUpds("agreement/cancel/" + agreement._id, {}, (newAgreement) => {
            replacement.agreements = replacement.agreements.filter(a => a._id !== agreement._id);
            replacement.agreements.push(newAgreement);

            this.setState({ modal: null });

            const applicant = replacement.applicants.find(app => app.substitute_id === agreement.substitute_id);

            new Noty({
                type: "info",
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: this.props.intl.formatMessage({ id: "Cancel.Agreement.Success" }, { sub: applicant.first_name }),
                timeout: 6000,
            }).show();
        });
    }

    render() {
        const { replacement } = this.props;

        if (!replacement) return null;
        let isPrivate = ReplacementEnt.isPrivate(replacement);
        const isDeclined = isPrivate && ReplacementEnt.isDeclinedBySubstitute(replacement, replacement.availabilityOwner._id)
        const refuseByDoc = isPrivate && ReplacementEnt.refuseByDoc(replacement, replacement.availabilityOwner)

        if (replacement.invitation_by_doctor === true) isPrivate = true;

        return (
            <React.Fragment>

                <div ref={this.focusRef} tabIndex={-1} className="d-replacement-card-dim pb-0 mb-0 mb-4 pt-0 mt-0" style={{ position: "relative" }}>

                    <div className={"d-replacement-card pb-0 mb-0 mb-4 " +
                        ((isPrivate && !isDeclined && !refuseByDoc) ? "clickable" : " ") +
                        ((isPrivate && (isDeclined || refuseByDoc)) ? " opacity-low " : "")}
                        onClick={(e) => {
                            if (isPrivate) {
                                this.openSubProfileModal(e,
                                    replacement,
                                    replacement.availabilityOwner,
                                    replacement.availabilityOwnerCharacteristics
                                );
                            }
                        }
                        }>

                        <Row className="min-height-70 ml-mr-0">
                            <div className="d-inline-block w-100 ml-3" style={{ minHeight: "70px" }}>

                                {replacement.type && 
                                    <div className={"sticker mr-2 text-uppercase bg-" + replacement.type}>
                                        {(replacement.type !== "guard" && !replacement.invitation) && <><FormattedMessage id="Replacement" />&nbsp;</>}
                                        
                                        {replacement.invitation ?
                                            <><FormattedMessage id="Invitation.Replacement" />&nbsp;</>
                                        :
                                            <FormattedMessage id={Util.fstLetUpCase(replacement.type)} />
                                        }

                                    </div>
                                }
                                
                                <div className="d-inline-block align-top ">
                                    <DReplacementTitle
                                        replacement={replacement}
                                        showApplicants={() => this.showApplicants()}
                                        showWatchers={() => this.showWatchers()} />
                                </div>

                                {
                                    DReplCardBuilder.rightSide(replacement,
                                        (e) => this.onDelete(e, replacement),
                                        (e) => this.openEditRetrocessionModal(e, replacement)
                                    )
                                }
                                

                            </div>

                        </Row>

                        {(!isPrivate) &&
                            <Row>

                                <Collapse isOpen={this.state.collapsed} className="w-100 ml-mr-10">

                                    <div className="card-collapse p-3 w-100 text-center">

                                        {(this.state.collapseTopic === "applicants") &&
                                            DReplCardBuilder.applicants(replacement, (e, repl, w, char) => this.openSubProfileModal(e, repl, w, char))
                                        }

                                        {(this.state.collapseTopic === "watchers") &&
                                            DReplCardBuilder.watchers(replacement, (e, repl, w, char) => this.openSubProfileModal(e, repl, w, char))
                                        }

                                    </div>

                                </Collapse>

                                <div className="card-toggler" onClick={() => this.showApplicants()}>
                                    {this.pointingArrow()}
                                </div>

                            </Row>
                        }

                    </div>

                    {(isDeclined) &&
                        <div className="card-overflow">
                            <FormattedMessage id="Cancelled.By.Substitute" />
                        </div>
                    }

                    {(refuseByDoc) &&
                        <div className="card-overflow">
                            <FormattedMessage id="Cancelled.By.You" />
                        </div>
                    }

                </div>

                {this.state.modal}

            </React.Fragment>
        );
    }

}


const mapStateToProps = state => {
    return {
        officeTimeTable: state.global.officeTimeTable
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRemoves: (objName, obj, cbk) => dispatch(removes(objName, obj, cbk)),
        onUpds: (objName, obj, cbk) => dispatch(upds(objName, obj, cbk))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DReplacementCard));