import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { adds } from "../../../actions/global/global";
import OccasionalOrRegularForm from './OccasionalOrRegularForm';
import GuardForm from './GuardForm';

class AddReplacementModal extends React.Component {

	constructor(props) {
		super(props);

		const { availability } = this.props;

		this.state = {
			replType: (availability) ? availability.type : "occasional",
			modal: null
		};

		this.onChangeTypeCbks = [];

		this.replacementForm = <OccasionalOrRegularForm
			{...this.props}
			onAddChangeTypeCbk={(cbk) => this.onChangeTypeCbks.push(cbk)}
			onChangeReplType={(replType) => this.toggle(replType)} />;

		this.guardForm = <GuardForm
			{...this.props}
			onAddChangeTypeCbk={(cbk) => this.onChangeTypeCbks.push(cbk)}
			onChangeReplType={(replType) => this.toggle(replType)} />;
	};

	toggle(replType) {
		const { availability } = this.props;

		if (availability && replType !== availability.type) return;

		this.setState({ replType: replType });

		for (let cbk of this.onChangeTypeCbks) cbk(replType);
	};

	render() {
		return (
			<React.Fragment>

				<Modal size="lg" isOpen={true} toggle={this.props.toggle} className="simple-modal simple-modal-white srounded">

					<ModalHeader toggle={this.props.toggle}>
					</ModalHeader>

					<ModalBody className="pl-2 pr-2 pl-md-5 pr-md-5 pt-0">

						<h4 className="w-100 uppercase text-center pb-4"><FormattedMessage id="New.Replacement.Post" /></h4>

						<div className="mx-auto text-center" style={{ maxWidth: "700px" }}>

							<div className={(this.state.replType === "occasional" || this.state.replType === "regular") ? "" : "d-none"}>
								{this.replacementForm}
							</div>

							<div className={(this.state.replType === "guard") ? "" : "d-none"}>
								{this.guardForm}
							</div>

						</div>

					</ModalBody>

				</Modal>

			</React.Fragment>
		);
	}

};

const mapStateToProps = state => {
	return {
		officeTimeTable: state.global.officeTimeTable
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onAdds: (objName, obj, cbk) => dispatch(adds(objName, obj, cbk))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddReplacementModal);