import React from 'react';
import { connect } from 'react-redux';
import { Form, RangeInput, NotEmpty, RadioInput } from '@gferrand/react-forms';
import { Row, Col } from 'reactstrap';
import { adds } from "../../../actions/global/global";
import ConfModal from "../../../components/modal/ConfModal";
import ReplacementEnt from '../../../entities/ReplacementEnt';
import DateUtil from '../../../util/DateUtil';
import { FormattedMessage, injectIntl } from 'react-intl';
import CheckBoxInput from '../../../components/form/CheckBoxInput';
import TextDateCustomInput from '../../../components/form/TextDateCustomInput';
import { replacementOverlaps } from '../../../actions/replacements/replacements';
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import { concludedAgreementOverlaps } from '../../../actions/agreement/agreement';
import moment from 'moment';

class GuardForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            replacementOverlapError: null,
            loadingOverlap: false,
            lastStartDate: null,
            lastEndDate: null,
            days_available: DateUtil.getDaysAvailableGuard(moment().hours(18).minutes(0).second(0), moment().add(1, 'day').hours(8).minutes(0).second(0), this.props.officeTimeTable)
        };

        this.buildGuardForm();

        this.props.onAddChangeTypeCbk((replType) => {
            if (this.guardForm) this.guardForm.setValue("type", replType);
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.lastStartDate !== this.state.lastStartDate || prevState.lastEndDate !== this.state.lastEndDate) {
            var availability = this.props.availability;
            ReplacementEnt.validateGuard(this.guardForm, availability);
        }
    }

    closeModal() {
        this.setState({ modal: null });
    };

    toggle(tab) {
        this.setState({ 'activeTab': tab });
    };

    openConfModal(content, onConfirm, onCancel) {
        this.setState({
            modal: <ConfModal
                content={content}
                onConfirm={onConfirm}
                onCancel={onCancel}
                toggle={() => onCancel()} />
        });
    };

    row(form, inputName) {
		return (
			<Row className="form-group">
				<Col md="5">{form.getLabel(inputName)}</Col>
				<Col md="7">{form.getInput(inputName)}</Col>
			</Row>
		);
	}

    rowForStartDate(form, inputName) {
		return (
            <>
				<Col md="3" className="mb-date-custom">{form.getLabel(inputName)}</Col>
				<Col md="2">{form.getInput(inputName)}</Col>
            </>
		);
	}

    rowForEndDate(form, inputName) {
		return (
            <>
				<Col md="3" className="m-end-date mb-date-custom">{form.getLabel(inputName)}</Col>
				<Col md="2">{form.getInput(inputName)}</Col>
            </>
		);
	}

    rowForType(form, inputName) {
		return (
            <>
				{form.getInput(inputName)}
            </>
		);
	}

    buildGuardForm() {
        const { availability } = this.props;

        var submitCbk = (data, endCbk) => {
            if (availability) data.availability_id = availability._id;

            data.type = "guard";
            data.days_available = this.state.days_available;
            
            this.props.onAdds("replacement", data, () => {
                endCbk();
                this.props.toggle();
                if (this.props.onSubmitCbk) this.props.onSubmitCbk();

                new Noty({
                    type: "info",
                    layout: 'topRight',
                    theme: 'bootstrap-v4',
                    text: this.props.intl.formatMessage({ id: "Replacement.Add.Success" }),
                    timeout: 6000,
                }).show();
            });
        };

        var cancelCbk = () => {
            this.props.toggle();
        };

        var disableOccasional = () => disableByType("occasional");
        var disableRegular = () => disableByType("regular");
        var disableGuard = () => disableByType("guard");
        var disableByType = (replType) => {
            if (!availability) return false;
            else if (availability.type === replType) return false;
            else return true;
        }

        var availabilityStartDate, availabilityEndDate;
        if (availability) {
            availabilityStartDate = availability.start_date;
            availabilityEndDate = availability.end_date;
        }
        var period = ReplacementEnt.guardPeriod(availabilityStartDate, availabilityEndDate);

        var retrocessionHint;
        if (availability) {
            retrocessionHint = (
                <div className="font-bold text-center text-muted p-1">
                    <FormattedMessage
                        id="Repl.Retrocession.Hint"
                        values={{
                            name: availability.substitute.first_name,
                            retrocession: availability.retrocession_wished
                        }} />
                </div>
            );
        }

        const defaultValues = {
            start_date: period.start,
            end_date: period.end,
            guard_retrocession: (availability) ? availability.retrocession_wished_confort : null
        };

        let choiceRadioInput = new RadioInput("type", "Replacement.Type",
            [
                { label: "Guard.Repl", value: "guard", disabled: disableGuard() }
            ], [NotEmpty], { default: "guard", gClassName: "three-btns-inputs" })

        if (!this.props.availabilityId) {
            choiceRadioInput = new RadioInput("type", "Replacement.Type",
            [
                { label: "Occasional.Repl", value: "occasional", disabled: disableOccasional() },
                { label: "Regular.Repl", value: "regular", disabled: disableRegular() },
                { label: "Guard.Repl", value: "guard", disabled: disableGuard() }
            ], [NotEmpty], { default: "guard", gClassName: "three-btns-inputs" })
        } 

        this.guardForm = new Form({
            name: "replacement",
            inputs: [
                choiceRadioInput,
				new TextDateCustomInput("start_date", "From", [NotEmpty], { placeholder: "jj/mm/aaaa" }),
				new TextDateCustomInput("end_date", "To", [NotEmpty], { placeholder: "jj/mm/aaaa" }),

                new RangeInput("guard_retrocession", "Guard.Retrocession", 60, 100, 2.5, [], { hint: retrocessionHint, suffix: "%", className: "red-range-input" }),
                new CheckBoxInput("parking", "Parking", [], { lClassName: "control-checkbox" }),
                new CheckBoxInput("housing", "Accommodation", [], { lClassName: "control-checkbox" }),
                new CheckBoxInput("office", "Guard.In.Office", [], { lClassName: "control-checkbox" }),
                new CheckBoxInput("visits", "Guard.Visits", [], { lClassName: "control-checkbox" }),
                new CheckBoxInput("paid_by_the_fifteenth", "Paid.By.Fifteenth", [], { lClassName: "control-checkbox" }),
            ],
            submitCbk,
            changeCbk: (input) => this.validateGuardForm(input),
            cancelCbk,
            data: defaultValues
        });
        
        this.checkOverlap(defaultValues.start_date, defaultValues.end_date, "guard", defaultValues.duration);
    };

    // ================================================================
    // ========================== VALIDATION ==========================
    // ================================================================
    
    checkOverlap = (start, end, type, duration) => {
        const { availability } = this.props;

        if(!start || !end) return;

        this.setState({ loadingOverlap: true });

        if (availability) {
            this.props.onConcludedAgreementOverlaps(start, end, type, duration, this.state.days_available, (res) => {
                this.setState({ replacementOverlapError: res.overlaps, loadingOverlap: false });
            });
        } else {
            this.props.onReplacementOverlaps(start, end, type, duration, this.state.days_available, (res) => {
                this.setState({ replacementOverlapError: res.overlaps, loadingOverlap: false });
            });
        }
    };

    validateGuardForm(input) {
        const { name, value } = input;
        const rawData = this.guardForm.getRawData();

        if (name === "type") {
            if (value !== "guard") this.props.onChangeReplType(value);
        };

        if (name === "start_date") {
            let newStartDate = new Date(value);
            if (this.state.lastStartDate && new Date(this.state.lastStartDate).getHours() === 23 && new Date(value).getHours() === 0) {
                newStartDate.setDate(newStartDate.getDate() + 1);
                this.guardForm.setValue("start_date", newStartDate);
                this.checkOverlap(newStartDate, rawData.end_date, "guard", rawData.duration);
            } else if (this.state.lastStartDate && new Date(this.state.lastStartDate).getHours() === 0 && new Date(value).getHours() === 23) {
                newStartDate.setDate(newStartDate.getDate() - 1);
                this.guardForm.setValue("start_date", newStartDate);
                this.checkOverlap(newStartDate, rawData.end_date, "guard", rawData.duration);
            } else {
                if (value <= rawData.end_date) {
                    this.checkOverlap(newStartDate, rawData.end_date, "guard", rawData.duration);
                }
            }
            let days_available = DateUtil.getDaysAvailableGuard(newStartDate, rawData.end_date, this.props.officeTimeTable);
            this.setState({ 
                lastStartDate: newStartDate,
                days_available
            });
        } else if (name === "end_date") {
            let newEndDate = new Date(value);
            if (this.state.lastEndDate && new Date(this.state.lastEndDate).getHours() === 23 && new Date(value).getHours() === 0) {
                newEndDate.setDate(newEndDate.getDate() + 1);
                this.guardForm.setValue("end_date", newEndDate);
                this.checkOverlap(rawData.start_date, newEndDate, "guard", rawData.duration);

            } else if (this.state.lastEndDate && new Date(this.state.lastEndDate).getHours() === 0 && new Date(value).getHours() === 23) {
                newEndDate.setDate(newEndDate.getDate() - 1);
                this.guardForm.setValue("end_date", newEndDate);
                this.checkOverlap(rawData.start_date, newEndDate, "guard", rawData.duration);
            } else {
                if (rawData.start_date <= value) {
                    this.checkOverlap(rawData.start_date, value, "guard", rawData.duration);
                }
            }
            let days_available = DateUtil.getDaysAvailableGuard(rawData.start_date, newEndDate, this.props.officeTimeTable);

            this.setState({ 
                lastEndDate: newEndDate,
                days_available
             });
        }

        var availability = this.props.availability;

        // ===================================================
        // =================== Validation ====================
        // ===================================================

        ReplacementEnt.validateGuard(this.guardForm, availability);
    };

    render() {
        return (
            <React.Fragment>
                <Row className="step step1 mb-4 justify-content-md-center">
					<Col>
                        <Col className="form-group row three-btns-inputs">
                            {this.rowForType(this.guardForm, "type")}
                        </Col>

                        <Row className="form-group">
                            {this.rowForStartDate(this.guardForm, "start_date")}
                            {this.rowForEndDate(this.guardForm, "end_date")}
                        </Row>

                        {this.row(this.guardForm, "guard_retrocession")}
                        {this.row(this.guardForm, "parking")}
                        {this.row(this.guardForm, "housing")}
                        {this.row(this.guardForm, "office")}
                        {this.row(this.guardForm, "visits")}
                        {this.row(this.guardForm, "paid_by_the_fifteenth")}

                        <div className="text-center text-danger">
                            <small>{(this.state.replacementOverlapError) && <FormattedMessage id="Overlapping.Existing.Replacement.Of.Same.Type" />}</small>
                        </div>

                        <div className="mt-5 w-100 mx-auto text-center form-buttons mb-3">
                            {this.guardForm.submitButton("Publish", {
                                disabled: (this.state.replacementOverlapError || this.state.loadingOverlap),
                                className: "srounded pl-5 pr-5 pt-2 pb-2 text-white bg-green btn  opacity-1"
                            })}
                        </div>

                        {this.state.modal}

                    </Col>
                </Row>
            </React.Fragment>
        );
    }
};

const mapStateToProps = state => {
    return {
        officeTimeTable: state.global.officeTimeTable,
        user: state.global.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAdds: (objName, obj, cbk) => dispatch(adds(objName, obj, cbk)),
        onReplacementOverlaps: (start, end, type, duration, days_available, cbk) => replacementOverlaps(start, end, type, duration, days_available, cbk),
		onConcludedAgreementOverlaps: (start, end, type, duration, days_available, cbk) => concludedAgreementOverlaps(start, end, type, duration, days_available, cbk),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GuardForm));