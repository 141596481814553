var values = [
    { label: "Residential.Course", value: "0" },
    { label: "Terminated.Course", value: "1" },
    { label: "Doctor.Thesis", value: "2" }
];

export default class SubstituteStudiesLevel {

    static values(){
        return values;
    }

    static getLabel(nbr){
        for(let value of values){
            if(value.value === nbr) return value.label;
        }
    }

};