
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { deleteReplacement } from '../../../actions/replacements/replacements';
import { Button, Col } from 'reactstrap';
import ConfModal from '../../../components/modal/ConfModal';
import Util from '../../../util/Util';
import DateUtil from '../../../util/DateUtil';


class DCalendarCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null
        };

        this.showReplacement = this.showReplacement.bind(this);
    }

    onDelete = (replacement) => {
        this.openConfModal(<FormattedMessage id="Delete.Warning" />, () => {
            this.props.deleteReplacement(replacement._id);
            this.closeModal();
            if (this.props.onDelete) this.props.onDelete();
        }, () => this.closeModal());
    }

    closeModal() {
        this.setState({ modal: null });
    }

    openConfModal(content, onConfirm, onCancel) {
        this.setState({
            modal: <ConfModal
                content={content}
                onConfirm={onConfirm}
                onCancel={onCancel}
                toggle={() => onCancel()} />
        });
    }

    showReplacement() {
        let path = '/home/me/replacements/public';
        if (this.props.replacement.availability_id || this.props.replacement.invitation_by_doctor === true) {
            path = '/home/me/replacements/private';
        }
        
        this.props.history.push({
            pathname: path,
            state: { 
                replacementId: this.props.replacement._id,
                openCollapse: this.props.replacement.applicants.length
             }
        });
    }

    render() {
        const { replacement, user } = this.props;

        if (!replacement || !user) return null;
        return (
            <React.Fragment>
                <Col sm="8" className="col-centered text-center">
                    <div className="profile-repl mt-5 mb-5">
                        <div className={"profile-repl-title bg-" + replacement.type}>
                            <FormattedMessage id={Util.fstLetUpCase(replacement.type) + ".Fem"} />
                            {(replacement.type === "regular") && <>&nbsp;(<FormattedMessage id={Util.fstLetUpCase(replacement.day[0])} />)</>}
                        </div>

                        <div className="profile-repl-details mb-3">
                            <div>
                                <FormattedMessage id="Dates" />&nbsp;:&nbsp;
                                    <FormattedMessage id="from" />&nbsp;{DateUtil.toDate(replacement.start_date)}&nbsp;
                                </div>
                            <div>
                                <FormattedMessage id="To" />&nbsp;{DateUtil.toDate(replacement.end_date)}
                            </div>

                            <div>
                                <FormattedMessage id="Wished.Retrocession" />&nbsp;:&nbsp;
                                {replacement.type === 'guard' ?
                                    <>
                                        {replacement.guard_retrocession} %
                                    </>
                                    :
                                    <>
                                        {replacement.retrocession} %
                                    </>
                                }
                                </div>

                            <div>
                                <i className="fa icon-ICON__OEIL_GRIS"></i>&nbsp;&nbsp;
                                {replacement.viewers.length}&nbsp;
                                <FormattedMessage id="Views" />
                            </div>

                            <div className="ml-3 mt-4 mb-3 d-flex justify-content-center">
                                <Button
                                    className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-red"
                                    onClick={() => this.onDelete(replacement)}>
                                    <FormattedMessage id="Delete" />
                                </Button>
                            </div>

                            <div className='mt-4 mb-3 clickable link-hoverable link-active' onClick={this.showReplacement}>
                                Aller vers l'annonce
                            </div>

                        </div>

                    </div>
                </Col>
                {this.state.modal}
            </React.Fragment >
        )
    }
};

const mapStateToProps = state => {
    return {
        user: state.global.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        deleteReplacement: (replacementId) => dispatch(deleteReplacement(replacementId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DCalendarCard);