import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Card } from 'reactstrap';
import { clears, getsBy } from '../../../actions/global/global';
import SubstituteProfile from "./SubstituteProfile";
import CGV from '../../subscription/CGV';

// import SubstituteRatingsProfile from "./SubstituteRatingsProfile";
import SubstituteSoftwaresProfile from './SubstituteSoftwaresProfile';
import SubstitutePracticesProfile from './SubstitutePracticesProfile';
import SubstituteDUsProfile from './SubstituteDUsProfile';
import SubstituteDESCsProfile from './SubstituteDESCsProfile';
import Subscription from '../../../enum/Subscription';

class SubstituteAccountProfile extends Component {


    constructor(props) {
        super(props);

        this.state = {
            subscriptions_replacement: null,
            subscriptions_succession: null,
            modal: null
        }

        this.openCGV = this.openCGV.bind(this);
		this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        if (this.props.user) {
            this.fetchData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            this.fetchData();
        }
    }

    fetchData() {
        this.props.onGetsBy("subscription", 'user_id', this.props.user._id, (subscriptions) => {
            if (subscriptions && subscriptions.length > 0) {
                subscriptions.forEach((elem) => {
                    if (elem.subscribe_active === true && (elem.choice === 0 || elem.choice === 1 || elem.choice === 2)) {
                        this.setState({
                            subscriptions_replacement: elem.choice
                        })
                    }
                    if (elem.subscribe_active === true &&  elem.choice === 3) {
                        this.setState({
                            subscriptions_succession: elem.choice
                        })
                    }
                })
            }
        })
    }

    closeModal() {
        this.setState({ modal: null });
    }

	openCGV() {
		this.setState({
			modal: <CGV 
				close={this.closeModal}
				user={this.props.user}
			/>
		})
	}

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    render() {
        const { user, substituteCharacteristic } = this.props;

        if (!user || !substituteCharacteristic) return this.loading();

        return (
            <React.Fragment>

                <div className="w-100 text-white text-center uppercase mb-4 lp-2">
                    <h4><FormattedMessage id="My.Profile" /></h4>
                </div>

                <Row>

                    <Col sm="12" md="6">

                        <Card className="pt-5 rounded-0">
                            <SubstituteProfile
                                substitute={user}
                                characteristics={substituteCharacteristic}
                                showChar={false}
                            />
                        </Card>
                        
                        {user.role === 'substitute' && (this.state.subscriptions_replacement !== null || this.state.subscriptions_succession !== null) &&
                            <Card className="pt-5 rounded-0">
                                <div className="text-center">
                                    <h5><b><FormattedMessage id="My.Subscription" /></b></h5>
                                </div>
                                <div className="pt-1 pb-5 text-center">
                                    {this.state.subscriptions_replacement !== null &&
                                        <div>
                                            <b>
                                                <FormattedMessage id={"Info.Subscribe"} />{Subscription.getLabel(this.state.subscriptions_replacement)}
                                            </b>
                                        </div>
                                    }

                                    {this.state.subscriptions_succession !== null &&
                                        <div>
                                            <b>
                                                <FormattedMessage id={"Info.Subscribe.Abo"} />{Subscription.getLabel(this.state.subscriptions_succession)}
                                            </b>
                                        </div>
                                    
                                    }

                                    <div onClick={this.openCGV} className="clickable collapse-text">
                                        <i>Ouvrir les CGV</i>
                                    </div>
                                </div>
                            </Card>
                        }

                        {/* <Card className="p-5 text-center rounded-0">
                            <div className="text-left">
                                <SubstituteRatingsProfile substitute={user} />
                            </div>
                        </Card> */}

                    </Col>

                    <Col sm="12" md="6">

                        <Card className="p-5 text-center rounded-0" style={{ minHeight: "639px" }}>

                            <div className="text-left">

                                <div className="text-center">
                                    <SubstitutePracticesProfile characteristics={substituteCharacteristic} />
                                </div>

                                <SubstituteSoftwaresProfile characteristics={substituteCharacteristic} />

                                <div className="mt-3 mb-3">
                                    <SubstituteDUsProfile characteristics={substituteCharacteristic} />
                                </div>

                                <div className="mt-3 mb-3">
                                    <SubstituteDESCsProfile characteristics={substituteCharacteristic} />
                                </div>

                            </div>

                        </Card>
                        {this.state.modal}

                    </Col>

                </Row>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
        substituteCharacteristic: state.global.substituteCharacteristic,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubstituteAccountProfile);


