export default function secretary(state = {}, action) {
	switch (action.type) {
		case "CREATE_SECRETARY_REQUEST":
			return Object.assign({}, state, {
				isAddingSecretary: true
			});
		case "CREATE_SECRETARY_SUCCESS":
			return Object.assign({}, state, {
				secretary: action.secretary,
				isAddingSecretary: false
			});
		case "CREATE_SECRETARY_FAILURE":
			return Object.assign({}, state, {
				isAddingSecretary: false
			});
		case "DOCTOR_CREATE_SECRETARY_REQUEST":
			return Object.assign({}, state, {
				isDoctorAddingSecretary: true
			});
		case "DOCTOR_CREATE_SECRETARY_SUCCESS":
			return Object.assign({}, state, {
				secretary: action.secretary,
				isDoctorAddingSecretary: false
			});
		case "DOCTOR_CREATE_SECRETARY_FAILURE":
			return Object.assign({}, state, {
				isDoctorAddingSecretary: false,
				error: action.error
			});
		default:
			return state;
	}
}