import axios from 'axios';

const rootUrl = process.env.REACT_APP_BE_URL || "http://localhost:7000";

export function getRegistrationInfo(id, cbk) {

    return dispatch => {
        dispatch(request());
        var url = rootUrl + "/secretaryManagement/id/" + id;

        return axios.get(url)
            .then(function (response) {

                if (response && response.data) {
                    const obj = response.data.message;
                    if (cbk) cbk(obj)
                    dispatch(success(obj));
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
                dispatch(failure())
            });
    };
    function request() { return { type: "GET_REGISTRATION_INFO_REQUEST" } }
    function success(result) { return { type: "GET_REGISTRATION_INFO_SUCCESS", secretaryManagement: result } }
    function failure() { return { type: "GET_REGISTRATION_INFO_FAILURE" } }
};

export function doctorAcceptManagement(secretaryManagementId, callback) {

    return dispatch => {
        dispatch(request());

        var url = rootUrl + "/secretarymanagement/doctor/acceptmanagement";

        let params = { secretaryManagementId };

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
            .catch(function (err) {
                dispatch(failure())
            });
    };

    function request() { return { type: "ACCEPT_SECRETARY_MANAGEMENT_REQUEST", acceptedsecretaryMaanagement: false } }
    function success() { return { type: "ACCEPT_SECRETARY_MANAGEMENT_SUCCESS", acceptedsecretaryMaanagement: true } }
    function failure(error) { return { type: "ACCEPT_SECRETARY_MANAGEMENT_FAILURE", acceptedsecretaryMaanagement: false } }
};

export function doctorRefuseManagement(secretaryManagementId, callback) {

    return dispatch => {
        dispatch(request());

        var url = rootUrl + "/secretarymanagement/doctor/refusemanagment";

        let params = { secretaryManagementId };

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
            .catch(function (err) {
                dispatch(failure())
            });
    };

    function request() { return { type: "REFUSE_SECRETARY_MANAGEMENT_REQUEST", refusedsecretaryMaanagement: false, } }
    function success() { return { type: "REFUSE_SECRETARY_MANAGEMENT_SUCCESS", refusedsecretaryMaanagement: true } }
    function failure(error) { return { type: "REFUSE_SECRETARY_MANAGEMENT_FAILURE", refusedsecretaryMaanagement: false } }
};

export function doctorDeleteManagement(secretaryManagementId, callback) {

    return dispatch => {
        dispatch(request());

        var url = rootUrl + "/secretarymanagement/doctor/deletemanagement";

        let params = { secretaryManagementId };

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
            .catch(function (err) {
                dispatch(failure())
            });
    };

    function request() { return { type: "DELETE_SECRETARY_MANAGEMENT_REQUEST", deletedsecretaryMaanagement: false, } }
    function success() { return { type: "DELETE_SECRETARY_MANAGEMENT_SUCCESS", deletedsecretaryMaanagement: true } }
    function failure(error) { return { type: "DELETE_SECRETARY_MANAGEMENT_FAILURE", deletedsecretaryMaanagement: false } }
};

export function secretaryAcceptManagement(secretaryManagementId, callback) {

    return dispatch => {
        dispatch(request());

        var url = rootUrl + "/secretaryManagement/secretary/acceptmanagement";

        let params = { secretaryManagementId };

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                dispatch(success());
                if (callback) callback();
            } else {
                if (callback) callback();
            }
        })
            .catch(function (err) {
                dispatch(failure())
            });
    };

    function request() { return { type: "ACCEPT_SECRETARY_MANAGEMENT_REQUEST", acceptedsecretaryMaanagement: false } }
    function success() { return { type: "ACCEPT_SECRETARY_MANAGEMENT_SUCCESS", acceptedsecretaryMaanagement: true } }
    function failure(error) { return { type: "ACCEPT_SECRETARY_MANAGEMENT_FAILURE", acceptedsecretaryMaanagement: false } }
};

export function secretaryRefuseManagement(secretaryManagementId, callback) {

    return dispatch => {
        dispatch(request());

        var url = rootUrl + "/secretarymanagement/secretary/refusemanagement";

        let params = { secretaryManagementId };

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
            .catch(function (err) {
                dispatch(failure())
            });
    };

    function request() { return { type: "REFUSE_SECRETARY_MANAGEMENT_REQUEST", refusedsecretaryMaanagement: false, } }
    function success() { return { type: "REFUSE_SECRETARY_MANAGEMENT_SUCCESS", refusedsecretaryMaanagement: true } }
    function failure(error) { return { type: "REFUSE_SECRETARY_MANAGEMENT_FAILURE", refusedsecretaryMaanagement: false } }
};

export function secretaryDeleteManagement(secretaryManagementId, callback) {

    return dispatch => {
        dispatch(request());

        var url = rootUrl + "/secretarymanagement/secretary/deletemanagement";

        let params = { secretaryManagementId };

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
            .catch(function (err) {
                dispatch(failure())
            });
    };

    function request() { return { type: "DELETE_SECRETARY_MANAGEMENT_REQUEST", deletedsecretaryMaanagement: false, } }
    function success() { return { type: "DELETE_SECRETARY_MANAGEMENT_SUCCESS", deletedsecretaryMaanagement: true } }
    function failure(error) { return { type: "DELETE_SECRETARY_MANAGEMENT_FAILURE", deletedsecretaryMaanagement: false } }
};

export function getAllBySecretary() {
    return dispatch => {
		dispatch(request());
        var url = rootUrl + "/secretaryManagements/all-by-secretary";

        return axios.get(url)
            .then(function (response) {
                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "SECRETARY_FIND_MANAGEMENTS_REQUEST", isGettingManagements: true, isGotManagements: false}}
	function success(result) { return { type: "SECRETARY_FIND_MANAGEMENTS_SUCCESS", secretaryManagements: result.secretaryManagements, isGettingManagements: false, isGotManagements: true}}
	function failure() { return { type: "SECRETARY_FIND__MANAGEMENTSFAILURE", isGettingManagements: false, isGotManagements: false}}
};

export function getActiveBySecretary() {
    return dispatch => {
		dispatch(request());
        var url = rootUrl + "/secretaryManagements/active-by-secretary";

        return axios.get(url)
            .then(function (response) {
                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "SECRETARY_FIND_MANAGEMENTS_REQUEST", isGettingManagements: true, isGotManagements: false}}
	function success(result) { return { type: "SECRETARY_FIND_MANAGEMENTS_SUCCESS", secretaryManagements: result.secretaryManagements, isGettingManagements: false, isGotManagements: true}}
	function failure() { return { type: "SECRETARY_FIND__MANAGEMENTSFAILURE", isGettingManagements: false, isGotManagements: false}}
};

export function getAllByDoctor() {
    return dispatch => {
		dispatch(request());
        var url = rootUrl + "/secretaryManagements/all-by-doctor";

        return axios.get(url)
            .then(function (response) {
                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "DOCTOR_FIND_MANAGEMENTS_REQUEST", isGettingManagements: true, isGotManagements: false}}
	function success(result) { return { type: "DOCTOR_FIND_MANAGEMENTS_SUCCESS", secretaryManagements: result.secretaryManagements, isGettingManagements: false, isGotManagements: true}}
	function failure() { return { type: "DOCTOR_FIND__MANAGEMENTS_FAILURE", isGettingManagements: false, isGotManagements: false}}
};