import React from 'react';
import { Row, Col } from "reactstrap";
import DateUtil from '../../../../util/DateUtil';

const AccountingDailyConsultationTableRow = ({ accountingAnnex, openconsultationsModal, deleteAccoutingLine }) => {
    const { reason, total, date } = accountingAnnex;

    return (
        <Row className="tableRow pt-0 m-0 pl-5">
            <Col className="text-dark pl-3 h-45px lh-45">{DateUtil.toHourWithoutSeconds(date)}</Col>
            <Col className="text-dark h-45px lh-45 pl-0" sm={7}>{reason}</Col>
            <Col className="text-dark h-45px lh-45 pl-5">{total}</Col>
            <Col className="text-right h-45px lh-45">
                <i className="fas fa-pencil-alt clickable mr-5" onClick={() => openconsultationsModal(accountingAnnex)}></i>
                <i className="fas fa-times clickable mr-5" onClick={(e) => deleteAccoutingLine(e, accountingAnnex)}></i>
            </Col>
        </Row>
    );
}

export default AccountingDailyConsultationTableRow;