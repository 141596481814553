import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import 'react-datetime/css/react-datetime.css';
import Stars from '../../../components/misc/Stars';
import ProfileImages from "../../../enum/ProfileImages";
import { Collapse } from 'reactstrap';
import SubstituteStudiesLevel from '../../../enum/SubstituteStudiesLevel';
import Retrocession from '../../../enum/Retrocession';
import RatingEnt from '../../../entities/RatingEnt';
import AddReplacementModal from '../../doctors/replacements/AddReplacementModal';
import SubstituteReplacementProfile from './SubstituteReplacementProfile';
import { clears, getsBy, get, getBy } from '../../../actions/global/global';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import SubstituteCharacteristicsProfile from './SubstituteCharacteristicsProfile';
import ConfModal from "../../../components/modal/ConfModal";
import ReplacementEnt from '../../../entities/ReplacementEnt';
import UserEnt from '../../../entities/UserEnt';
import StringUtil from '../../../util/StringUtil';
import DateUtil from '../../../util/DateUtil';
import Util from '../../../util/Util';
import AgreementEnt from '../../../entities/AgreementEnt';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import SubstituteModalPlanningView from './SubstituteModalPlanningView'
import RetrocessionCertificateHome from '../../retrocessionCertificate/RetrocessionCertificateHome';
import { downloadCertificate } from "../../../actions/retrocessionCertificate/retrocessionCertificate";
import moment from 'moment';

class SubstituteProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: false,
            modal: null,
            disabled: false,
            driving: null,
            transit: null,
            cycling: null,
            mapshown: false,
            showMap: false,
            days: [],
        };

        const { substitute, characteristics } = this.props;

        this.date = moment()

        this.GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

        this.changeCollapse = this.changeCollapse.bind(this);
        this.sendGMAPSRequests = this.sendGMAPSRequests.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.downloadContract = this.downloadContract.bind(this);
        this.openRetrocessionCertificate = this.openRetrocessionCertificate.bind(this);
        this.downloadCertificate = this.downloadCertificate.bind(this);

        this.mapComponent = withScriptjs(withGoogleMap((props) =>
            <GoogleMap
                defaultZoom={11}
                defaultCenter={{ lat: characteristics.location.coordinates[1], lng: characteristics.location.coordinates[0] }}
                defaultOptions={{
                    mapTypeControl: false,
                    fullscreenControl: false,
                    gestureHandling: 'none',
                    zoomControl: false,
                    draggable: false,
                    streetViewControl: false
                }}
            >

                <Marker
                    key={Math.random()}
                    icon={{
                        url: ProfileImages.getSrc(substitute.image), // url
                        scaledSize: new window.google.maps.Size(50, 50), // scaled size
                        origin: new window.google.maps.Point(0, 0), // origin
                        anchor: new window.google.maps.Point(0.5, 0.5) // anchor
                    }}
                    position={{
                        lat: parseFloat(characteristics.location.coordinates[1]),
                        lng: parseFloat(characteristics.location.coordinates[0])
                    }} />
            </GoogleMap>
        ));

    }

    componentDidMount(){
        if(this.props.user.role === "doctor" && !this.props.doctorCharacteristic) {
            this.props.onGet("doctorCharacteristic");
        }
        if(this.props.agreement){
            this.props.onGetBy("retrocessionCertificate", ["replacement_id"], [this.props.agreement.replacement_id])
        }
    }

    changeCollapse(){
        this.setState({ collapsed: !this.state.collapsed })
    }
    
    sendGMAPSRequests() {

        const DistanceMatrixService = new window.google.maps.DistanceMatrixService();

        const geocoder = new window.google.maps.Geocoder();

        const changestate = (name, value) => {
            this.setState({ [name] : value})
        }
        const address1 = this.props.characteristics.address_street + "," + this.props.characteristics.address_city + "," +  this.props.characteristics.address_postcode
        var address1lng; 
        var address1lat;
        const address2lng = this.props.doctorCharacteristic ? this.props.doctorCharacteristic.location.coordinates[0] : null 
        const address2lat = this.props.doctorCharacteristic ? this.props.doctorCharacteristic.location.coordinates[1] : null

        const that = this;

        geocoder.geocode( { 'address' : address1 }, function( results, status ) {
            
            if (status === 'OK') {
                address1lat = results[0].geometry.location.lat()
                address1lng = results[0].geometry.location.lng()

                if (that.state.mapshown === false && that.props.user.role === "doctor") {
        
                    DistanceMatrixService.getDistanceMatrix({
                        destinations: [{ lat: address1lat, lng: address1lng }],
                        origins: [{ lng: address2lng, lat: address2lat }],
                        travelMode: "DRIVING",
                    }, (result) => {
                        if (result.rows[0].elements[0]) {
                            changestate( "driving" , result.rows[0].elements[0] )
                        }      
                    });
                
                    DistanceMatrixService.getDistanceMatrix({
                        destinations: [{ lat: address1lat, lng: address1lng }],
                        origins: [{ lng: address2lng, lat: address2lat }],
                        travelMode: "BICYCLING",
                    }, (result) => {
                        if (result.rows[0].elements[0]) {
                            changestate( "cycling" , result.rows[0].elements[0] )                    
                        }        
                    });
                
                    DistanceMatrixService.getDistanceMatrix({
                        destinations: [{ lat: address1lat, lng: address1lng }],
                        origins: [{ lng: address2lng, lat: address2lat }],
                        travelMode: "TRANSIT",
                    }, (result) => {
                        if (result.rows[0].elements[0]) {
                            changestate( "transit" , result.rows[0].elements[0] )
                        }
                    });
                }   
                that.setState({ mapshown: true })       
            }
        });
        this.setState({ showMap: !this.state.showMap })
    }

    pointingArrow = () => {
        if (!this.state.collapsed) return "+";
        return "-";
    }

    buildRoundElem = (characteristics, field, i18n) => {
        if (!i18n) i18n = StringUtil.capitalize(field);

        return (
            <div className="round-elem">
                <div className={"round" + ((characteristics[field]) ? "-success" : "-failure")}>
                    {(characteristics[field]) ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}
                </div>
                <div className="title"><FormattedMessage id={i18n} /></div>
            </div>
        );
    }

    openReplacementModal(availability) {
        if (!availability) return;
        this.setState({
            modal: <AddReplacementModal
                key={availability.userId}
                userId={availability.userId}
                availabilityId={availability._id}
                availability={availability}
                toggle={this.closeModal}
                onSubmitCbk={() => this.props.onGetsBy("availability", ["id"], [availability._id])}
            />
        });
    }

    closeModal() {
        this.setState({ modal: null });
    }

    downloadContract(e) {
        e.preventDefault();
        e.stopPropagation();
        if (!this.props.agreement.invitation )

        if (!this.state.disabled) {
            this.setState({ disabled: true });

            this.props.downloadContract(this.props.agreement._id, () => this.setState({ disabled: false }));
        }
    }

    sendToCouncil(e, agreementId, substitute) {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.disabled) return;

        this.setState({ disabled: true });

        const onConfirm = () => {
            this.props.sendToCouncil(agreementId, () => this.setState({ disabled: false, modal: null }));
            this.closeModal();
        };

        const onCancel = () => {
            this.setState({ disabled: false });
            this.closeModal();
        };

        const content = <FormattedHTMLMessage id="Confirm.Send.To.Council" values={{ name: substitute.first_name }} />

        this.setState({
            modal: <ConfModal
                content={content}
                onConfirm={onConfirm}
                onCancel={() => onCancel()}
                toggle={() => onCancel()} />
        });
    }

    cancelAgreement(e, agreementId) {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.disabled) return;

        this.setState({ disabled: true });

        const onConfirm = () => {
            this.props.cancelAgreement(agreementId, () => {
                this.setState({ disabled: false });
                if (this.props.onCancelCbk) this.props.onCancelCbk();
            });
        };

        const onCancel = () => {
            this.setState({ disabled: false });
            this.closeModal();
        };

        const content = <FormattedHTMLMessage id="Confirm.Cancel.Agreement" />

        this.setState({
            modal: <ConfModal
                content={content}
                onConfirm={onConfirm}
                onCancel={() => onCancel()}
                toggle={() => onCancel()} />
        });
    }

    openRetrocessionCertificate(event) {
        event.preventDefault();
        event.stopPropagation();
        
        let substitute = Object.assign({}, this.props.agreement.substitute, this.props.agreement.substituteCharacteristic);
        let replacement = this.props.agreement.replacement;

        this.setState({
            modal: <RetrocessionCertificateHome
                    toggle={this.closeModal}
                    substitute={substitute}
                    agreement={this.props.agreement}
                    replacement={replacement}
                    fromDoctor={true}
                />
        })
    }

    downloadCertificate(certificateId, token) {
        this.props.onDownloadCertificate(certificateId, token);
    }

    render() {  
        let { doctorCharacteristic, characteristics, substitute, availability, replacement, user, agreement } = this.props;
        if(!replacement && agreement) replacement = agreement.replacement;
        if (!substitute) return null;

        var showChar = Util.null(this.props.showChar) ? true : this.props.showChar;

        const { rating } = substitute;

        var hasConcludedAgreement = (ReplacementEnt.isConcluded(replacement, substitute) || AgreementEnt.isConcluded(agreement, substitute));

        const isCancelled = (agreement && (agreement.cancelled_by_doctor || agreement.cancelled_by_substitute));
        const isStep3Complete = AgreementEnt.isConcluded(agreement);

        const alreadySent = AgreementEnt.alreadySent(agreement);

        const repl = replacement || ((agreement) ? agreement.replacement : null);
        const hasStarted = (repl && new Date().getTime() > new Date(repl.start_date).getTime());

        let disabledProposeRepl = false;
        if (availability && availability.privateReplacements && availability.privateReplacements.length > 0) {
            if (availability.type === "occasional" && availability.privateReplacements.length >= 3) disabledProposeRepl = true;
            if (availability.type === "regular" && availability.privateReplacements.length >= 3) disabledProposeRepl = true;
            if (availability.type === "guard" && availability.privateReplacements.length >= 1) disabledProposeRepl = true;
        }

        let viewPhone = false;
        if (replacement && replacement.applicants && replacement.applicants.length > 0) {
            // Si ce n'est pas une invitation, on peut voir le numéro
            if (!replacement.availability_id) {
                viewPhone = true;
            } else {
                replacement.applicants.forEach((elem) => {
                    // Si y'a une négo, on peut voir le numéro
                    if (elem.substitute_id === substitute._id && elem.negociation && elem.negociation.length > 0) {
                        viewPhone = true;
                    }
                })
            }
        }

        if (agreement && agreement.substitute_id === substitute._id ) {
            viewPhone = true;
        }

        var hasSignedAgreement;
        let viewReplacement = true;
        if (agreement) {
            if (agreement.doctor_initials) {
                viewReplacement = false;
            }
            hasSignedAgreement = AgreementEnt.isSigned(agreement);
        }
        let days_available;
        if (availability) {
            days_available = availability.days_available;
        }
        let monthSelected = moment().diff(moment(), 'month');
        if(user.role === 'doctor' && availability) {
            monthSelected = moment(this.props.availability.start_date).diff(moment(), 'month');
        } 

        let missions = 0;
        if (availability && availability.substitutesConcludedAgreements) {
            missions = availability.substitutesConcludedAgreements.length;
            if (availability.substitutesConcludedAgreements.length === 1) {
                if (!availability.substitutesConcludedAgreements[0]._id) {
                    missions = 0;
                } 
            } 
        }
        
        let viewRetrocessionCertificate = false;
        if (replacement && this.props.agreement) {
            let startDate = replacement.start_date
            if (replacement.applicants) {
                replacement.applicants.forEach((applicant) => {
                    if (applicant.substitute_id === this.props.agreement.substitute_id) {
                        if (applicant.negociation && applicant.negociation.length > 0) {
                            let lastElement = applicant.negociation[applicant.negociation.length - 1];
                            startDate = lastElement.start_date;
                        }
                    }
                })
            }
            if (moment(startDate).isSameOrBefore(moment(), 'day')) {
                viewRetrocessionCertificate = true;
            }
        }

        let listOfRetrocessionCertificate = [];
        if (replacement && this.props.retrocessionCertificate && this.props.retrocessionCertificate.length > 0) {
            this.props.retrocessionCertificate.forEach((certificate) => {
                if (certificate.doctor_signature && certificate.substitute_signature && !certificate.cancelled_by_doctor && !certificate.cancelled_by_substitute && replacement._id === certificate.replacement_id) {
                    listOfRetrocessionCertificate.push({
                        id: certificate._id,
                        name: certificate.name,
                        token: certificate.token,
                        date: 'Certificat du ' + moment(certificate.createdAt).format('DD/MM/YYYY')
                    })
                }
            })
        }
        return (
            <React.Fragment>

                <div className="w-100 text-center mb-4">
                    <img src={ProfileImages.getSrc(substitute.image)} className="d-block mx-auto mb-3" width="100" alt="" style={{ borderRadius: "100px" }} />

                    <div className="">
                        <div className="d-inline-block  text-center loc-icon-container" onClick={this.sendGMAPSRequests}>
                            <div className="loc-icon-custom"></div>
                        </div>

                        <div className="d-inline-block align-top">

                            <h4 className="mb-0">{(hasConcludedAgreement) ? UserEnt.fullName(substitute) : UserEnt.pseudo(substitute)}</h4>
                            {(characteristics && characteristics.address_components && characteristics.address_components.city) && <div>{characteristics.address_components.city}</div>}
                            <div>{DateUtil.age(substitute.birth_date)} <FormattedMessage id="Years.Old" /></div>
                        </div>

                        <div className="d-inline-block ml-3" style={{ width: "40px" }}>
                        </div>
                    </div>

                    <div className="mb-2" style={{ fontSize: "22px" }}><Stars nbr={RatingEnt.averageAllStars(rating)} /></div>
                    <div className=""><FormattedMessage id="Missions" /> : {missions}</div>
                    <div className=""><FormattedMessage id="Status" /> : <FormattedMessage id={SubstituteStudiesLevel.getLabel(characteristics.studies_level)} /></div>

                    {characteristics.retrocession_wished_confort && 
                        <div><FormattedMessage id="Retrocession.Wished.Confort" /> :&nbsp; 
                            <FormattedMessage id={Retrocession.getLabel(characteristics.retrocession_wished_confort)} />
                        </div>
                    }

                    {availability && availability.type === "regular" &&
                        <div>
                            <FormattedMessage id="Available" />{' '}
                            {availability.day.map((newDay) => 
                                <>
                                    <FormattedMessage id="the.sing" />&nbsp;
                                    <FormattedMessage id={Util.fstLetUpCase(newDay)} />&nbsp;
                                </>
                            )}
                        </div>
                    }

                    {(viewPhone) &&
                        <a href={"tel:" + Util.formatPhoneNumber(substitute.phone_mobile)}>
                            <div className="w-100 text-center mt-3 mb-3">
                                <div className="phone-icon text-blue">
                                    <i className="fa fa-phone"></i>
                                </div>
                                    <div className="text-blue text-center mt-2">
                                    <div className="bold lp-1"><FormattedHTMLMessage id="You.Can.Now.Contact" />&nbsp;{substitute.first_name}&nbsp;!</div>
                                    <div className="mt-1 font-size-18" >{Util.formatPhoneNumber(substitute.phone_mobile)}</div>

                                    {(hasConcludedAgreement && agreement && !agreement.sent_to_council) &&
                                        <div className="mt-3 mb-3"><FormattedHTMLMessage id="Accessible.Only.After.Sent.By.Sub" /></div>
                                    }
                                </div>
                            </div>
                        </a>
                    }

                    <div className="max-w-600px mx-auto m-0 p-0">
                        {(isStep3Complete) &&
                            <>
                                <div className={"btn bg-red srounded lowercase pl-5 pr-5 text-white lp-2 d-inline-block m-2 " +
                                    ((isCancelled || alreadySent || hasStarted) ? "disabled" : "")}
                                    onClick={(e) => {
                                        if (isCancelled || alreadySent || hasStarted) return;
                                        this.cancelAgreement(e, agreement._id);
                                    }}>
                                    <FormattedHTMLMessage id="Give.Up.Mission" />
                                </div>
                                {(agreement && agreement.doctor_initials && agreement.substitute_initials) &&
                                    <div className={"btn bg-blue srounded lowercase pl-5 pr-5 text-white lp-2 d-inline-block m-2 " +
                                        ((agreement.invitation || this.state.disabled) ? "disabled" : "")}
                                        onClick={this.downloadContract}>
                                        {agreement.invitation ?
                                            <FormattedHTMLMessage id="Contract.Not.Hydrogen" />
                                        :
                                            <FormattedHTMLMessage id="Download.Contract" />
                                        }
                                    </div>
                                }
                            </>
                        }

                        {//Doctor and Substitute should have signed agreement
                            hasConcludedAgreement && hasSignedAgreement && !alreadySent && 
                            <div className={"btn bg-green srounded lowercase pl-3 pr-3 text-white lp-2 d-inline-block m-2 " + ((alreadySent || this.state.disabled) ? "disabled" : "")}
                                onClick={(e) => {
                                    if (alreadySent || this.state.disabled) return;
                                    this.sendToCouncil(e, agreement._id, substitute)
                                }}>
                                <FormattedHTMLMessage id="Transmit.To.Council" />
                            </div>
                        }
                        
                        {viewRetrocessionCertificate === true &&
                            <div className="btn bg-green srounded lowercase pl-3 pr-3 text-white lp-2 d-inline-block m-2"
                                onClick={this.openRetrocessionCertificate} >
                                <FormattedHTMLMessage id="Create.Retrocession.Certificate" />
                            </div>
                        }

                        {listOfRetrocessionCertificate && listOfRetrocessionCertificate.length > 0 &&
                            <div className='mt-4'>
                                <h4>Liste des certificats de rétrocession</h4>
                                {listOfRetrocessionCertificate.map((certificate, key) =>
                                    <div key={key} className='mt-1' onClick={() => this.downloadCertificate(certificate.id, certificate.token)}>
                                        {certificate.date} <div className="btn bg-green srounded lowercase pl-3 pr-3 text-white lp-2 d-inline-block m-2 "><i className="fa fa-download clickable fa-lg" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                    </div>

                    <Collapse isOpen={this.state.showMap}>

                        <div className="profile-map">
                            {user.role === "doctor" &&
                                <div>
                                    { this.state.driving && this.state.driving.distance && <> <i className="fas fa-car"></i> ({this.state.driving.distance.text},{this.state.driving.duration.text}) </>}
                                    { this.state.transit && this.state.transit.distance && <> <i className="fas fa-bus"></i> ({this.state.transit.distance.text},{this.state.transit.duration.text}) </>}
                                    { this.state.cycling && this.state.cycling.distance && <> <i className="fas fa-bicycle"></i> ({this.state.cycling.distance.text},{this.state.cycling.duration.text}) </>}
                                </div>
                            }
                            <>
                                {(this.mapComponent) &&
                                    <this.mapComponent
                                        style={{ marginTop: "-10px" }}
                                        isMarkerShown={true}
                                        googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + this.GOOGLE_MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places"}
                                        loadingElement={<div style={{ height: `100%`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                                        containerElement={<div style={{ height: `400px`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                                        mapElement={<div style={{ height: `100%`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                                    />
                                }
                            </>
                        </div>
                    </Collapse>
                    
                    <div className="mt-5">
                        {(user.role === 'doctor' && availability && doctorCharacteristic) && 
                            <SubstituteModalPlanningView 
                                date={this.date}
                                days={this.state.days}
                                readingPlanning={true}
                                startDate={availability.start_date}
                                endDate={availability.end_date}
                                availability={availability}
                                days_available={days_available}
                                monthSelected={monthSelected}
                            />
                        }
                    </div>

                    {(user.role === "doctor" && availability) &&
                        <>
                            {(availability.privateReplacements && availability.privateReplacements.length > 0) &&
                                <div className="w-100 text-center mt-4 text-danger">
                                    <FormattedMessage id="You.Already.Proposed.X.Replacements" values={{ nbr: availability.privateReplacements.length }} />
                                </div>
                            }
                            
                            <LaddaButton
                                className={"btn srounded pl-5 pr-5 pt-2 pb-2 text-white bg-green mt-3"}
                                data-style={EXPAND_LEFT}
                                disabled={disabledProposeRepl}
                                onClick={() => this.props.onProposeReplacement(availability.user_id, availability._id, availability)}>
                                <FormattedHTMLMessage id="Propose.Replacement" />
                            </LaddaButton>
                        </>
                    }

                    {(replacement && viewReplacement === true) &&
                        <SubstituteReplacementProfile
                            replacement={replacement}
                            characteristics={this.props.characteristics}
                            substitute={substitute}
                            onSignContract={this.props.onSignContract}
                            closeModal={this.props.closeModal}
                            cancelAgreement={(e) => this.cancelAgreement(e, agreement._id)} 
                            openConfModal={this.props.openConfModal}
                            onDelete={this.props.onDelete}
                            openNegociationModal={this.props.openNegociationModal}
                        />
                    }
                </div>

                {(showChar) &&
                    <>
                        <Collapse isOpen={this.state.collapsed} className="modal-collapse pl-3 pr-3 pl-md-5 pr-md-5 w-100">

                            <SubstituteCharacteristicsProfile
                                substitute={substitute}
                                characteristics={characteristics}
                            />

                        </Collapse>

                        <div className="modal-toggler" onClick={this.changeCollapse}>
                            <div style={{ fontSize: 14, lineHeight: "29px" }} >{this.pointingArrow()} de détail</div>
                        </div>
                    </>
                }

                {this.state.modal}

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
        doctorCharacteristic: state.global.doctorCharacteristic,
        retrocessionCertificate: state.global.retrocessionCertificate
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),
        onGetBy: (objName, paramNames, params, cbk) => dispatch(getBy(objName, paramNames, params, cbk)),
        onGet: (objName) => dispatch(get(objName)),
        onDownloadCertificate: (certificateId, cbk) => downloadCertificate(certificateId, cbk),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubstituteProfile);