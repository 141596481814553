import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import DTransmissionsForm from './DTransmissionsForm';
import TransmissionConfModal from './TransmissionConfModal';


export default class STransmissionModal extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            modal: null
        };

        this.getDataCbk = null;
    }

    close(){
        this.setState({
            modal: <TransmissionConfModal 
                toggle={() => this.closeModal()} 
                submit={() => {
                    const data = this.getDataCbk();
                    this.props.submitCbk(data, () => this.props.toggle(false));
                }} 
                cancel={() => this.closeModal()}
                deny={() => this.props.toggle()} />
        });
    }

    closeModal() {
        this.setState({ modal : null });
    }

    render() {
        const { title, content, doctorTransmission, doctorCharacteristic } = this.props;

        return (
            <React.Fragment>

                <Modal size="lg" isOpen={true} toggle={() => this.close()} className="simple-modal simple-modal-white srounded">

                    <ModalHeader toggle={() => this.close()}></ModalHeader>

                    <ModalBody className="pl-2 pr-2 pl-md-5 pr-md-5 pt-0">

                        <h4 className="w-100 uppercase text-center pb-4"><FormattedMessage id={title} /></h4>
                        
                        <div className="text-center mb-5"><FormattedHTMLMessage id={content} /></div>

                        <DTransmissionsForm
                            getDataCbk={(cbk) => this.getDataCbk = cbk}
                            doctorCharacteristic={doctorCharacteristic}
                            defaultValues={doctorTransmission}
                            submitCbk={this.props.submitCbk}
                            submitButtoni18nId={this.props.submitButtoni18nId}
                            />

                    </ModalBody>

                </Modal>

                {this.state.modal}

            </React.Fragment>
        );

    }

}
