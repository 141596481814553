import React from "react";
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const FooterPage = () => {
  return (
    <footer className=" text-center font-small pt-4 mt-4">
      <Container fluid className="text-center">
        <Row>
          <Col md="3" className="underline">
            <Link to="" className="footer-link" >Qui Sommes nous ?</Link>
          </Col>
          <Col md="1" className="underline">
            |
          </Col>
          <Col md="3" className="underline">
            <Link to="" className="footer-link" >Mentions legales</Link>
          </Col>
          <Col md="1" className="underline" >
            |
          </Col>
          <Col md="4" style={{ marginBottom: 25 + 'px' }}>
            <Row>
              <Col md="1">
                <a href="https://www.facebook.com/Hydrogen-Remplacement-609685582873828/" target="_blank" rel="noopener noreferrer" size="lg" floating social="fb">
                  <i class="fab fa-facebook-f"></i>
                </a>
              </Col>
              <Col md="1">
                <a href="https://twitter.com/hremplacements" target="_blank" rel="noopener noreferrer" size="lg" floating social="tw">
                  <i class="fab fa-twitter"></i>
                </a>
              </Col>
              <Col md="1">
                <a href="https://www.linkedin.com/in/hydrogen-remplacements/" target="_blank" rel="noopener noreferrer" size="lg" floating social="li">
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </Col>
              <Col md="1">
                <a href="https://www.instagram.com/hydrogen_remplacement_/?hl=fr" target="_blank" rel="noopener noreferrer" size="lg" floating social="ins">
                  <i class="fab fa-instagram"></i>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="footer-copyright text-center py-3">
        <Container fluid>
          &copy; {new Date().getFullYear()} Copyright: HYDROGEN 
        </Container>
      </div>
    </footer >
  );
}

export default FooterPage;