import React from 'react';
import { Row, Col } from "reactstrap";
import { FormattedMessage } from 'react-intl';
import Maths from '../../../../util/Maths';

const AccountingMonthlyTotalRow = ({ accountingTotals, user }) => {

    const { credit_card_total_consultation, cash_total_consultation, cheque_total_consultation, secu_total_consultation, third_party_payer_consultation, retrocession_total_consultation } = accountingTotals[3];
    const { totalAmount } = accountingTotals[0];
    const { expensesTotal } = accountingTotals[1];
    const { recetteBisTotal } = accountingTotals[2];

    return (
        <div className="totalFooterColor m-0 p-0 pl-5 mt-4">
            <Row className="tableRow m-0 border-0" style={{ paddingBottom: '12px' }}>
                <Col className="font-weight-bold"><FormattedMessage id="Total.Patients" /></Col>
                <Col className="font-weight-bold">{credit_card_total_consultation ? Maths.round(credit_card_total_consultation) : '-'}</Col>
                <Col className="font-weight-bold">{cheque_total_consultation ? Maths.round(cheque_total_consultation) : '-'}</Col>
                <Col className="font-weight-bold">{cash_total_consultation ? Maths.round(cash_total_consultation) : '-'}</Col>
                <Col className="font-weight-bold">{secu_total_consultation ? Maths.round(secu_total_consultation + third_party_payer_consultation) : '-'}</Col>
                {user.role !== "doctor" && <Col className="font-weight-bold">{retrocession_total_consultation ? Maths.round(retrocession_total_consultation) : '-'}</Col>}
                <Col className="font-weight-bold"></Col>
            </Row>

            <Row className="tableRow border-0 m-0 text-left" style={{ paddingBottom: '12px' }}>
                <Col className="font-weight-bold"><FormattedMessage id="Your.Total.Gains" /></Col>
                <Col className="font-weight-bold"></Col>
                <Col className="font-weight-bold"></Col>
                <Col className="font-weight-bold"></Col>
                {user.role !== "doctor" && <Col className="font-weight-bold"></Col>}
                <Col className="font-weight-bold">{recetteBisTotal}</Col>
                <Col className="font-weight-bold"></Col>
            </Row>

            <Row className="tableRow border-0 m-0" style={{ paddingBottom: '12px' }}>
                <Col className="font-weight-bold"><FormattedMessage id="Your.Total.Expenses" /></Col>
                <Col className="font-weight-bold"></Col>
                <Col className="font-weight-bold"></Col>
                <Col className="font-weight-bold"></Col>
                {user.role !== "doctor" && <Col className="font-weight-bold"></Col>}
                <Col className="font-weight-bold">{expensesTotal}</Col>
                <Col className="font-weight-bold"></Col>
            </Row>

            <Row className="tableRow border-0 m-0" style={{ paddingBottom: '12px' }}>
                <Col className="font-weight-bold"><FormattedMessage id="Total.Amount" /></Col>
                <Col className="font-weight-bold"></Col>
                <Col className="font-weight-bold"></Col>
                <Col className="font-weight-bold"></Col>
                {user.role !== "doctor" && <Col className="font-weight-bold"></Col>}
                <Col className="font-weight-bold text-danger">{totalAmount}€</Col>
                <Col className="font-weight-bold"></Col>
            </Row>
        </div>
    );
}

export default (AccountingMonthlyTotalRow);