import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import ProfileImages from "../../../enum/ProfileImages";
import StringUtil from '../../../util/StringUtil';
import UserEnt from '../../../entities/UserEnt';
import DateUtil from '../../../util/DateUtil';
import GoogleMapUtil from "../../../util/GoogleMapUtil"
import 'ladda/dist/ladda-themeless.min.css';
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";

export class PediatricianProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            disabled: false,
            driving: null,
            transit: null,
            cycling: null,
            showMap: false,
            mapshown: false,
            modal: null
        };


        this.GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
        this.showMap = this.showMap.bind(this);

        GoogleMapUtil.loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${this.GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
            () => {
                this.buildMap(this.props.pediatrician, this.props.pediatricianCharacteristic)
                // this.forceUpdate()
            }
        );

    }

    buildMap(pediatrician, pediatricianCharacteristic) {
        this.mapComponent = withGoogleMap((props) =>
            <GoogleMap
                defaultZoom={11}
                defaultCenter={{ lat: pediatricianCharacteristic.location.coordinates[1], lng: pediatricianCharacteristic.location.coordinates[0] }}
                defaultOptions={{
                    mapTypeControl: false,
                    fullscreenControl: false,
                    gestureHandling: 'none',
                    zoomControl: false,
                    draggable: false,
                    streetViewControl: false
                }}
            >
                <Marker
                    key={Math.random()}
                    icon={{
                        url: ProfileImages.getSrc(pediatrician.image), // url
                        scaledSize: new window.google.maps.Size(50, 50), // scaled size
                        origin: new window.google.maps.Point(0, 0), // origin
                        anchor: new window.google.maps.Point(0.5, 0.5) // anchor
                    }}
                    position={{
                        lat: parseFloat(pediatricianCharacteristic.location.coordinates[1]),
                        lng: parseFloat(pediatricianCharacteristic.location.coordinates[0])
                    }} />
            </GoogleMap>
        );
    }

    buildRoundElem = (characteristics, field, i18n) => {
        if (!i18n) i18n = StringUtil.capitalize(field);

        return (
            <div className="round-elem">
                <div className={"round" + ((characteristics[field]) ? "-success" : "-failure")}>
                    {(characteristics[field]) ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}
                </div>
                <div className="title"><FormattedMessage id={i18n} /></div>
            </div>
        );
    }

    showMap() {
        this.setState({ showMap: !this.state.showMap })
    }

    render() {
        const { pediatrician, pediatricianCharacteristic } = this.props;


        let age = DateUtil.age(pediatrician.birth_date);

        return (
            <React.Fragment>

                <div className="w-100 text-center mb-4">
                    <img src={ProfileImages.getSrc(pediatrician.image)} className="d-block mx-auto mb-3"
                        width="100" alt="" style={{ borderRadius: "100px" }} />

                    <div className="">
                        <div className="d-inline-block text-center loc-icon-container-custom" onClick={this.showMap}>
                            <div className="loc-icon-custom"></div>
                        </div>

                        <div className="d-inline-block align-top">
                            <h4 className="mb-0">{UserEnt.pseudo(pediatrician)}</h4>
                            {(pediatricianCharacteristic && pediatricianCharacteristic.address_components && pediatricianCharacteristic.address_components.city) && <div>{pediatricianCharacteristic.address_components.city}</div>}
                            {age > 18 &&
                                <div>{DateUtil.age(pediatrician.birth_date)} <FormattedMessage id="Years.Old" /></div>
                            }
                        </div>

                        <div className="d-inline-block ml-3" style={{ width: "40px" }}>
                        </div>
                    </div>

                    <Collapse isOpen={this.state.showMap}>
                        <div className="profile-map">
                            {(this.mapComponent) &&
                                <this.mapComponent
                                    style={{ marginTop: "-10px" }}
                                    isMarkerShown={true}
                                    googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + this.GOOGLE_MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places"}
                                    loadingElement={<div style={{ height: `100%`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                                    containerElement={<div style={{ height: `400px`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                                    mapElement={<div style={{ height: `100%`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                                />
                            }
                        </div>
                    </Collapse>
                </div>
            </React.Fragment >
        );
    }

    collapse(category) {
        if (this.state.collapsed === category) this.setState({ collapsed: null });
        else this.setState({ collapsed: category });
    }
}

const mapStateToProps = state => {
    return {
        
    }
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PediatricianProfile));
