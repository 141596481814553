import React from 'react';
import { Col } from "reactstrap";
import DateUtil from '../../../../util/DateUtil';
import AccountingTableFooter from '../../AccountingTableFooter';
import AccountingTableHeader from '../../AccountingTableHeader';
import AccountingMonthlyTableRow from './AccountingMonthlyTableRow';
import AccountingMonthlyTotalRow from './AccountingMonthlyTotalRow';

const AccountingMonthly = ({ accountingTotals, accountingTotalsDays, monthChoosen, startDate, switchToDayFromMonth, user }) => {
    const currentMonth = startDate.getMonth();
    const currentYear = startDate.getFullYear();

    return (
        <>
            <div className="calendar-logo m-3 mx-auto"></div>

            <Col className="w-100 text-center uppercase mb-4 font-weight-bold dateHeader">
                <i className="fas fa-chevron-left mr-3 clickable" onClick={() => monthChoosen('previous')}></i>
                {DateUtil.stringifyMonth(currentMonth)} {currentYear}
                <i className="fas fa-chevron-right ml-3 clickable" onClick={() => monthChoosen(undefined, 'next')}></i>
            </Col>

            <AccountingTableHeader monthly user={user} />

            <div className="w-100">

                {accountingTotalsDays && [...Array(DateUtil.daysInMonth(currentMonth + 1, currentYear))].map((e, i) => {
                    let accountingToPass;
                    accountingTotalsDays.forEach((accounting) => {
                        const date = `${currentYear}-${currentMonth < 10 ? `0${currentMonth + 1}` : currentMonth + 1}-${i < 9 ? `0${i + 1}` : i + 1}`;
                        if (date === accounting.date) return accountingToPass = accounting;
                    });

                    return <AccountingMonthlyTableRow
                        key={i}
                        day={i}
                        month={currentMonth + 1}
                        user={user}
                        accounting={accountingToPass}
                        switchToDayFromMonth={switchToDayFromMonth}
                    />
                })}

                {accountingTotals !== undefined && accountingTotals.length > 0 && <AccountingMonthlyTotalRow accountingTotals={accountingTotals} user={user} />}
            </div>

            <AccountingTableFooter />
        </>
    );
}

export default AccountingMonthly;