import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Card } from 'reactstrap';
import { clears, getsBy } from '../../../actions/global/global';
import PediatricianProfile from "./PediatricianProfile";
import PediatricianPracticesProfile from './PediatricianPracticesProfile';
import PediatricianAboutProfile from './PediatricianAboutProfile';
import PediatricianMoreDetailsProfile from './PediatricianMoreDetailsProfile';
import OfficeTimetable from '../../doctors/office/OfficeTimetable';

class PediatricianAccountProfile extends Component {

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    render() {
        const { user, pediatricianCharacteristic, officeTimeTable } = this.props;

        if (!user || !pediatricianCharacteristic || !officeTimeTable) return this.loading();

        return (
            <React.Fragment>

                <React.Fragment>

                    <div className="w-100 text-white text-center uppercase mb-4 lp-2">
                        <h4><FormattedMessage id="My.Profile" /></h4>
                    </div>

                    <Row>

                        <Col sm="12" md="6">

                            <Card className="pt-5 rounded-0">
                                <PediatricianProfile
                                    pediatrician={user}
                                    pediatricianCharacteristic={pediatricianCharacteristic}
                                    officeTimeTable={officeTimeTable}
                                    showChar={false}
                                />
                            </Card>
                        </Col>

                        <Col sm="12" md="6">

                            <Card className="p-5 text-center rounded-0" style={{ minHeight: "639px" }}>

                                <div className="text-left mx-auto">

                                    <div className="text-center">
                                        <PediatricianPracticesProfile pediatricianCharacteristic={pediatricianCharacteristic} />
                                    </div>

                                    <div className="mt-4">
                                        <PediatricianAboutProfile pediatricianCharacteristic={pediatricianCharacteristic} />
                                    </div>

                                    <div className="mt-5 mb-4">
                                        <PediatricianMoreDetailsProfile pediatricianCharacteristic={pediatricianCharacteristic} />
                                    </div>

                                </div>

                            </Card>

                        </Col>

                        <Col sm="12" md="12">

                            <div className="max-w-800px mx-auto m-0 p-0">
                                <OfficeTimetable
									register={false}
                                />
                            </div>

                        </Col>
                    </Row>

                </React.Fragment>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
        pediatricianCharacteristic: state.global.pediatricianCharacteristic,
        officeTimeTable: state.global.officeTimeTable
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PediatricianAccountProfile);