import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import classnames from "classnames";

import DoctorCard from '../DoctorCard';
import { getAllBySecretary } from "../../../actions/secretaryManagement/secretaryManagement";

class SecretaryManagementSettings extends Component {

    constructor(props) {
        super(props);


        this.state = {
            activeTab: "1"
        }
    }

	componentDidMount() {
        this.props.getAllBySecretary()
    }

	componentDidUpdate(prevProps) {
		if(prevProps.isUpdatingSecretaryMangement !== this.props.isUpdatingSecretaryMangement && !this.props.isUpdatingSecretaryMangement) {
			this.props.getAllBySecretary()
		}
	}

	toggle = (tab) => {
        const { activeTab } = this.state;
        if (activeTab !== tab) this.setState({ activeTab: tab });
    };

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-muted mx-auto" role="status"></div></div>;

    render() {
		const secretaryManagements = this.props.secretaryManagements

        return (
			<Card className="srounded">
				
				<CardBody className="p-5">  
					<h4 className="bold m-5 text-center">Vos médecins</h4>
					<Row>
						<Col lg="2" sm="12">
							<ListGroup>
								<ListGroupItem
									className={classnames("text-color-black clickable", {
										active: this.state.activeTab === "1",
									})}
									onClick={() => { this.toggle("1"); }}
								>
									Mes médecins
								</ListGroupItem>
								<ListGroupItem
									className={classnames("text-rolor-black clickable", {
										active: this.state.activeTab === "2",
									})}
									onClick={() => { this.toggle("2"); }}
									action
								>
									Demande en attente
								</ListGroupItem>
							</ListGroup>
						</Col>
						<Col lg="10" sm="12">
							{!secretaryManagements && this.loading()}

							{/* Valid managments */}
							{this.state.activeTab === "1" && secretaryManagements && secretaryManagements.filter((secretaryManagement) => (secretaryManagement.status_active === true)).map((secretaryManagement, index) => {
								return <DoctorCard
									type="validmanagement"
									secretaryManagement={secretaryManagement}
									key={index}
								/>
							})}

							{/* ongoingrequests */}
							{this.state.activeTab === "2" && secretaryManagements && secretaryManagements.filter((secretaryManagement) => ((secretaryManagement.status_active === false && secretaryManagement.status === "0") || (secretaryManagement.status_active === false && secretaryManagement.status === "3"))).map((secretaryManagement, index) => {
								return <DoctorCard
									type="ongoingrequest"
									secretaryManagement={secretaryManagement}
									key={index}
									acceptManagement={this.acceptManagement}
									refuseManagement={this.refuseManagement}
								/>
							})}
						</Col>
					</Row>
				</CardBody>
			</Card>
        );
    };
};

const mapStateToProps = state => {
    return {
        secretaryManagements: state.secretaryManagement.secretaryManagements,
		isUpdatingSecretaryMangement: state.secretaryManagement.isUpdatingSecretaryMangement,
		global: state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllBySecretary: () => dispatch(getAllBySecretary())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SecretaryManagementSettings);