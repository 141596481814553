
import React from 'react';
import logodarktext from '../assets/images/logo-text.png';
import { Card, CardBody, Row, Col } from 'reactstrap';


class AboutUs extends React.Component {

    render() {
        return (
            <div id="registration" className='example blue-bg'>

                <div className='step-progress'>

                    <div className="topbar navbarbg">
                        <a href="#/" className="text-center bg-white w-100 d-block" style={{ lineHeight: "64px" }}>
                            <img src={logodarktext} alt="HYDROGEN" className="" height="18px" weight="38px" />
                        </a>
                    </div>

                    <Row className="step step1 mb-4 mt-5 justify-content-md-center ">
                        <Col lg="8">
                            <Card>
                                <CardBody className="p-5">


                                    <h1>Qui sommes-nous?</h1>

                                    <br/><br/>

                                    <p>« J’apporterai mon aide à mes confrères ainsi qu’à leurs familles dans
                                l’adversité. Que les hommes et mes confrères m’accordent leur estime si je suis fidèle à mes promesses ;
                                que je sois déshonoré et méprisé si j’y manque » : Hippocrate.</p>

                                    <p>Notre profession demande un dévouement auprès des patients, aussi notre temps est précieux. Pour faciliter 
                                        nos recherches de remplacements, nous souhaitons créer une communauté professionnelle, qui permette une mise 
                                        en relation directe entre les médecins installés et leurs remplaçants.Une enquête nationale auprès de plus 
                                        de 2000 médecins installés et remplaçants, nous a permis d’élaborer un site qui reflète les attentes de chacun.</p>

                                    <p>La solution s’est construite progressivement par une équipe de développeurs, graphistes et médecins remplaçants, 
                                        grâce aux précieux retours des futurs utilisateurs.</p>

                                    <p>Après des mois de travail, HYDROGEN arrive enfin sur la toile.</p>

                                    <p>Au-delà d’une simple mise en relation, HYDROGEN apporte une solution de remplacement clé en main actualisée, 
                                        géolocalisée et personnalisée. Il participe ainsi à l’amélioration de la permanence et de la continuité des 
                                        soins sur le territoire pour faciliter le quotidien denombreux de nos confrères.</p>

                                </CardBody>
                            </Card>
                        </Col>

                    </Row>

                </div>

            </div>
        );
    }
}



export default AboutUs;
