import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, UncontrolledTooltip } from "reactstrap";
import ProfileCard from '../../boths/ProfileCard';
import AvailabilityTitle from '../../boths/availabilities/AvailabilityTitle';
import SubstituteProfileModal from '../../substitutes/profile/SubstituteProfileModal';
import DReplacementContractModal from '../replacements/DReplacementContractModal';

import { getsBy } from '../../../actions/global/global';
import AddReplacementProposition from '../replacements/AddReplacementProposition';

class DAvailabilityCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: false,
            modal: null
        };
        
        this.ref = React.createRef();
        this.closeModal = this.closeModal.bind(this);
        this.proposeReplacement = this.proposeReplacement.bind(this);
    }
    
    shake() {
        this.setState({ shake: true });
        setTimeout(() => this.setState({ shake: false }), 1250)
    }

    proposeReplacement(userId, availabilityId, availability) {
        this.setState({
            modal: <AddReplacementProposition
                key={userId}
                userId={userId}
                availabilityId={availabilityId}
                availability={availability}
                toggle={this.closeModal}
                onSubmitCbk={() => this.props.onGetsBy("availability", ["id"], [availabilityId])}
                dateStartAndEnd={this.props.dateStartAndEnd}
            />
        });
    }

    openSubProfileModal(e, availability) {
        e.stopPropagation();
        this.setState({
            modal: (
                <SubstituteProfileModal
                    substitute={availability.substitute}
                    characteristics={availability}
                    availability={availability}
                    onProposeReplacement={this.proposeReplacement}
                    closeModal={this.closeModal}
                    dateStartAndEnd={this.props.dateStartAndEnd}
                />
            )
        });
    }

    openContractModal = (agreement, applicant, substituteCharacteristic) => {
        const { officeTimeTable } = this.props;

        var modal = <DReplacementContractModal
            agreement={agreement}
            applicant={applicant}
            substituteCharacteristic={substituteCharacteristic}
            officeTimeTable={officeTimeTable}
            toggle={this.closeModal}
            onSignatureCbk={(agreement) => {
                
            }}
        />;

        if (this.state.modal) {
            this.setState({ modal: null }, () => this.setState({ modal: modal }));
        } else {
            this.setState({ modal: modal });
        }
    }

    closeModal = () => {
        this.setState({ modal: null });
    }

    render() {
        const { availability } = this.props;
        var addClassName = "";
        if (this.state.shake) addClassName = " shake ";

        if (this.props.addRef) this.props.addRef(this.ref);
        if (this.props.addShakeCbk) this.props.addShakeCbk(() => this.shake());
        return (
            <React.Fragment>

                <div className={"d-availability-card pb-0 mb-0 mb-4 clickable pt-0" + addClassName}  ref={this.ref}
                    onClick={(e) => this.openSubProfileModal(e, availability)}>
                    
                    <Row className="min-height-70 ml-mr-0">

                        <Col lg="3" className="">
                            <ProfileCard user={availability.substitute} />
                        </Col>

                        {availability.warning && availability.isPerfectMatch &&
                        <Col lg="2">
                            <i className="fa fa-exclamation-triangle text-danger"></i>
                        </Col>
                        }

                        <Col>
                            <AvailabilityTitle availability={availability} />
                        </Col>

                    </Row>

                    {(availability.privateReplacements && availability.privateReplacements.length > 0) &&
                        <div id="bubble" className="card-bubble text-danger" style={{marginRight: "20px", marginTop: "-80px"}}>
                            <b>{availability.privateReplacements.length}</b>

                            <UncontrolledTooltip placement="top" target="bubble" className="white-tooltip">
                                Vous avez postulé à cette annonce
                            </UncontrolledTooltip>
                        </div>
                    }

                </div>

                {this.state.modal}

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        availabilitys: state.global.availabilitys,
        user: state.global.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(getsBy(objName, fields, values, callback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DAvailabilityCard);


