import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { adds } from "../../../actions/global/global";
import DateUtil from '../../../util/DateUtil';
import OfficeTimeTableEnt from '../../../entities/OfficeTimeTableEnt';
import { replacementOverlaps } from '../../../actions/replacements/replacements';
import AddGuardModal from '../replacements/AddGuardModal'
import SubstituteModalPlanningView from '../../substitutes/profile/SubstituteModalPlanningView'
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import { Col, Button } from "reactstrap";
import { concludedAgreementOverlaps } from '../../../actions/agreement/agreement';
import ProfileImages from "../../../enum/ProfileImages";
import { Collapse } from "reactstrap";
import Util from '../../../util/Util';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css"


class OccasionalReplacementPropositionForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            isOpen: false,
            replacementOverlapError: null,
            loadingOverlap: false,
            outputActivitePrevisionnelle: this.props.availability.retrocession_wished,
            start_date: null,
            end_date: null,
            retrocession_wished: this.props.availability.retrocession_wished,
            retrocession_guard: this.props.availability.retrocession_guard ? this.props.availability.retrocession_guard :this.props.availability.retrocession_wished ,
            activite_previsionnelle: this.props.activite_previsionnelle ? this.props.activite_previsionnelle: 80,
            hasGuard: false,
            parking: false,
            housing: false,
            guard_start_date: new Date(this.props.availability.start_date),
            guard_end_date: new Date(this.props.availability.end_date),
            office: false,
            visits: false,
            paid_by_the_fifteenth: false,
            astreinte: 20,
            guardsList: [],
            type: this.props.availability.type,
            days_available: []
        };
     
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.resetDate = this.resetDate.bind(this);
    };

    componentDidMount() {
        if (this.props.dateStartAndEnd) {
            // Date provenant de l'availability
            let startDate = new Date(this.props.availability.start_date);
            let endDate = new Date(this.props.availability.end_date);

            // Date provenant de la recherche
            let startDateProps = new Date(this.props.dateStartAndEnd.start_date);
            let endDateProps = new Date(this.props.dateStartAndEnd.end_date);

            if (this.props.availability.type === 'regular') {
                startDateProps = new Date().toISOString();
                endDateProps = new Date(new Date().getTime() + 12 * 30 * 24 * 3600 * 1000).toISOString();
            } 

            // Si la date de début est antérieur que la date de recherche, on prend la date de recherche
            // Sinon la date de début d'availabilitie
            if (moment(this.props.availability.start_date).isBefore(startDateProps)) {
                startDate = new Date(startDateProps);
            }
            if (moment(this.props.availability.end_date).isAfter(endDateProps)) {
                endDate = new Date(endDateProps);
            }

            // Si la date de début est dans le passé
            if (moment(startDate).isBefore(moment())) {
                let daysAvailableSort = this.props.availability.days_available.sort();
                for (let elem of daysAvailableSort) {
                    if (moment(elem).isSameOrAfter(moment())) {
                        startDate = elem;
                        break;
                    }
                }
            }

            let diff = moment(startDate).diff(endDate, 'week') + 1;
            diff = Math.abs(diff);
            if (diff > 12) {
                endDate = moment(startDate).add(12, 'weeks').toDate();
            }

            let days_available = DateUtil.getDaysAvailableFromInvitation(startDate, endDate, this.props.availability.days_available, this.props.officeTimeTable, this.props.availability.type);
            this.checkOverlap(startDate, endDate, this.state.type, days_available);

            let startDateMoment = moment(startDate);
            let endDateMoment = moment(endDate);
            // let nbDays = endDateMoment.diff(startDateMoment, 'days');
            // Si le nb de jours de la dispo est inférieur aux nb de jours d'exceptions
            // Ou si la date de début est supérieur à la date de fin 
            // On reset toutes les dates etc pour que l'utilisateur soit obligé de choisir
            if (startDateMoment.isAfter(endDateMoment, 'day')) {
                this.resetDate();
            } else {
                this.setState({
                    start_date: startDate,
                    end_date: endDate,
                    days_available: days_available
                })
            }
        }
    }

    // Permet de changer la date à la volée
	changeDate(date) {
		if (date.isBefore(moment().subtract(1, 'day'), 'day')) {
            this.setState({
                error: 'La date ne peut pas être inférieur à la date du jour'
			})
			return;
        } else if (date.isBefore(this.props.availability.start_date) || date.isAfter(this.props.availability.end_date)) {
            return;
        } else {
            this.setState({
                error: null,
                disabled: false
            })
        }
        let startDate = this.state.start_date;
        let endDate = this.state.end_date;

        // Si aucune date sélectionné
		if (this.state.days_available.length === 0) {
			let startDateTemp = date.toDate();
			let endDateTemp = date.hours(23).minutes(59).seconds(59).toDate();
            let days_available = DateUtil.getDaysAvailableFromInvitation(startDateTemp, endDateTemp, this.props.availability.days_available, this.props.officeTimeTable, this.props.availability.type);
            this.checkOverlap(startDateTemp, endDateTemp, this.state.type, days_available);
			this.setState({
				start_date: startDateTemp,
				end_date: endDateTemp,
				days_available: days_available
			})
			return;
		}

		if (date.isBefore(this.state.start_date, 'day')) {
            let diff = moment(date).diff(endDate, 'week') + 1;
            diff = Math.abs(diff);
            if (diff > 12) {
                this.setState({
                    error: 'Le remplacement ne peut pas excéder 12 semaines'
                })
    			return;
            }
            let days_available = DateUtil.getDaysAvailableFromInvitation(date, endDate, this.props.availability.days_available, this.props.officeTimeTable, this.props.availability.type);
            let startAndEndDays = DateUtil.startAndEndDays(days_available);
            this.checkOverlap(startDate, date, this.state.type, days_available);

            this.setState({
                start_date: startAndEndDays.start,
                end_date: startAndEndDays.end,
                days_available: days_available
            })
        } else if (date.isAfter(this.state.end_date, 'day')) {
            let diff = moment(date).diff(startDate, 'week') + 1;
            diff = Math.abs(diff);
            if (diff > 12) {
                this.setState({
                    error: 'Le remplacement ne peut pas excéder 12 semaines'
                })
    			return;
            }
            let days_available = DateUtil.getDaysAvailableFromInvitation(startDate, date, this.props.availability.days_available, this.props.officeTimeTable, this.props.availability.type);
            let startAndEndDays = DateUtil.startAndEndDays(days_available);
            this.checkOverlap(startDate, date, this.state.type, days_available);

            this.setState({
                start_date: startAndEndDays.start,
                end_date: startAndEndDays.end,
                days_available: days_available
            })
        } else {
            let days_available = Array.from(this.state.days_available);
			let days_available_filter = days_available.filter((day) => {
				return !moment(day.start).isSame(date, 'day');
			});
		    let startAndEndDays = DateUtil.startAndEndDays(this.state.days_available);

			if (days_available.length !== days_available_filter.length) {
                let startAndEndDaysFilter = DateUtil.startAndEndDays(days_available_filter);    
                this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available_filter);
				this.setState({
                    start_date: startAndEndDaysFilter.start,
                    end_date: startAndEndDaysFilter.end,
					days_available: days_available_filter
				})
			} else {
                if (this.state.type === 'occasional' && date.day() !== 0) {
                    // Si la date n'est pas dans le tableau, il faut la rajouter 
                    days_available.push({
                        start: date.toDate(),
                        end: date.hours(23).minutes(59).seconds(59).toDate()
                    });
                    this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available);
                    this.setState({
                        days_available: days_available
                    })
                } else if (this.state.type === 'regular' && date.day() !== 0) {
                    let numberDay = DateUtil.dayToNbr(this.state.days);
                    if (numberDay === date.day()) {
                        days_available.push({
                            start: date.toDate(),
                            end: date.hours(23).minutes(59).seconds(59).toDate()
                        });
                        this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available);
                        this.setState({
                            days_available: days_available
                        })
                    }
                } else if (this.state.type === 'guard') {
                    days_available.push({
                        start: date.toDate(),
                        end: date.hours(23).minutes(59).seconds(59).toDate()
                    });
                    this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available);
                    this.setState({
                        days_available: days_available
                    })
                }
			}
        }
    }

    // ================================================================
    // ========================== VALIDATION ==========================
    // ================================================================
    checkOverlap = (start, end, type, days_available) => {
        const { availability } = this.props;

		if (!start || !end) return;

        this.setState({ loadingOverlap: true });

        if(availability) {
            this.props.onConcludedAgreementOverlaps(start, end, type, undefined, days_available, (res) => {
				this.setState({ replacementOverlapError: res.overlaps, loadingOverlap: false });
			});
        } else {
            let duration = 0;
            this.props.onReplacementOverlaps(start, end, type, duration, days_available, (res) => {
                this.setState({ replacementOverlapError: res.overlaps, loadingOverlap: false });
            });
        }
    };

    handleSubmit(e) {
        e.preventDefault();

        var data = {
            type: this.state.type,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            days_available: this.state.days_available,
            retrocession: this.state.retrocession_wished,
            activite_previsionnelle: this.state.activite_previsionnelle,
            parking: this.state.parking,
            housing: this.state.housing,
            guards: this.state.guardsList,
            guard_retrocession: this.state.guard_retrocession,
            astreinte: this.state.astreinte,
            day: this.props.availability.day
        }

        var { availabilityId } = this.props;
        if (availabilityId) data.availability_id = availabilityId;

        var comments = [];
        if (data.type === "occasional") {
            // If replacement does not start on a Monday
            if (!DateUtil.isDay(data.start_date, 1)) {
                comments.push(<FormattedMessage id="Not.Start.Monday.Warning" />);
            };
            // If replacement starts on a day when doctor does not work
            if (!OfficeTimeTableEnt.worksOnDate(data.start_date, this.props.officeTimeTable)) {
                comments.push(<FormattedMessage id="Start.Date.Not.Office.TimeTable" />);
            };
            // If replacement ends on a day when doctor does not work
            if (!OfficeTimeTableEnt.worksOnDate(data.end_date, this.props.officeTimeTable)) {
                comments.push(<FormattedMessage id="End.Date.Not.Office.TimeTable" />);
            };
            // If replacement ends on a day that is not the cabinet's last working day
            if (DateUtil.day(data.end_date) !== OfficeTimeTableEnt.lastWorkingDay(this.props.officeTimeTable)) {
                comments.push(<FormattedMessage id="End.Date.Not.Office.Last.Day" />);
            };
        };

        // Cas d'une invitation
        if (availabilityId) {
            let availabilityOriginal = {
                retrocession: this.props.availability.retrocession_wished,
                start_date: this.props.availability.start_date,
                end_date: this.props.availability.end_date,
                guard_retrocession: this.props.availability.guard_retrocession_wished,
                days_available: this.props.availability.days_available
            }
            data.availability_original = availabilityOriginal;
        }

        var sendToBE = () => {
            this.props.onAdds("replacement", data, () => {
                this.props.toggle();
                if (this.props.onSubmitCbk) this.props.onSubmitCbk();

                let msg = (this.props.availability) ?
                    this.props.intl.formatMessage({ id: "Private.Replacement.Add.Success" }, { sub: this.props.availability.substitute.first_name }) :
                    this.props.intl.formatMessage({ id: "Replacement.Add.Success" });

                new Noty({
                    type: "info",
                    layout: 'topRight',
                    theme: 'bootstrap-v4',
                    text: msg,
                    timeout: 6000,
                }).show();
            });
        };
        sendToBE();
    }

    resetDate() {
        this.setState({
            start_date: null,
            end_date: null,
            replacementOverlapError: null,
            error: null,
            days_available: []
        })
    }

    render() {
        let monthSelected = moment(this.props.availability.start_date).diff(moment(), 'month');
        const { availability } = this.props;
        let days = this.props.availability.day ? this.props.availability.day : [];
        return (
            <React.Fragment>

                {availability && 
                    <div>
                        <div className="simple-modal simple-modal-white srounded">
                            <img src={ProfileImages.getSrc(availability.substitute.image)} className="d-block mx-auto mb-3" width="100" alt="user img" style={{ borderRadius: "100px" }} />
                        </div>
                        <div className="" style={{ fontSize: "large", marginTop: "4px", marginBottom: "15px" }}>
                            {availability.substitute.first_name}
                        </div>
                    </div>
                }

                <div className="col-md-12 mb-3">
                    <div><span className="cssNotifShowLegendNotSign"></span>Disponibilité du remplaçant</div>
                    <div><span className="cssNotifShowLegendNotSignGreen"></span>Date proposée</div>
                </div>

                {availability && availability.type === "regular" &&
                    <div className="col-md-12 mb-3">
                        <FormattedMessage id="Available" />{' '}
                        {availability.day.map((newDay) => 
                            <>
                                <FormattedMessage id="the.sing" />&nbsp;
                                <FormattedMessage id={Util.fstLetUpCase(newDay)} />&nbsp;
                            </>
                        )}
                    </div>
                }

                <div className="col-md-12 mb-3">
                    <FormattedMessage id="Invitation.On.Replace.Following" />
                </div>

                <SubstituteModalPlanningView 
                    date={moment()}
                    days={days}
                    readingPlanning={false}
                    startDate={this.state.start_date}
                    endDate={this.state.end_date}
                    availability={availability}
                    days_available={availability.days_available}
                    state_days_available={this.state.days_available}
                    changeDate={this.changeDate}
                    resetDate={this.resetDate}
                    monthSelected={monthSelected}
                />

                <form name="replForm" onSubmit={this.handleSubmit}>
                    <div className="container" style={{ marginTop: "10px" }}>

                        <div className="row justify-content-center " >
                            <div className="col-8">
                                <FormattedMessage id="Retrocession" ></FormattedMessage>&nbsp;
                                <input
                                    type="range"
                                    min="60"
                                    max="100"
                                    step="2.5"
                                    name="retrocession_wished"
                                    value={this.state.retrocession_wished}
                                    onChange={this.handleChange}
                                /> &nbsp; {this.state.retrocession_wished} %
                            </div>
                        </div>

                        {/* <div className="row justify-content-center " >
                            <div className="col-8">
                                <FormattedMessage id="Activite_previsionnelle" ></FormattedMessage>&nbsp;
                                <input
                                    type="range"
                                    min="000"
                                    max="200"
                                    step="10"
                                    value={this.state.outputActivitePrevisionnelle}
                                    onChange={this.updateTextInput}
                                /> &nbsp; {this.state.outputActivitePrevisionnelle} %
                            </div>
                        </div> */}

                        <div style={{ textAlign: "left", marginLeft: "38 %" }}>
                            <div className="form-check" >
                                <label className="form-check-label" htmlFor="exampleRadios1">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="parking"
                                        id="parking"
                                        checked={this.state.parking}
                                        value={this.state.parking}
                                        onChange={this.editRadio}
                                        onClick={this.editRadio}
                                    />
                                    Place de Parking
                            </label>
                            </div>
                            <div className="form-check"  >
                                <label className="form-check-label" htmlFor="exampleRadios1">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="housing"
                                        id="housing"
                                        checked={this.state.housing}
                                        value={this.state.housing}
                                        onChange={this.editRadio}
                                        onClick={this.editRadio}
                                    />
                                    Logement Disponible
                            </label>
                            </div>
                            <div className="form-check" >
                                <label className="form-check-label" htmlFor="exampleRadios1">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="guardes"
                                        id="guardes"
                                        checked={this.state.hasGuard}
                                        value={this.state.hasGuard}
                                        onChange={this.showModal}
                                        onClick={this.showModal}
                                    />
                                    Ce remplacement comprend des gardes
                                </label>
                            </div>
                        </div>
                        {/* && this.state.guardsList.length > 0 */}
                        {this.state.hasGuard  && 
                        <> 
                            <Collapse isOpen={this.state.hasGuard} className="modal-collapse pl-3 pr-3 pl-md-5 pr-md-5 w-100">

                                {this.state.guardsList.map((number) =>

                                    <GuardCard
                                        type="regular"
                                        day={this.props.availability.day}
                                        start_date={number.start_date}
                                        end_date={number.end_date}
                                        office={number.office}
                                        visits={number.visits}
                                        paid_by_the_fifteenth={number.paid_by_the_fifteenth}
                                        astreinte={number.astreinte}
                                        retrocession_guard={number.retrocession_guard}
                                    />
                                )}
                                <AddGuardModal
                                    isOpen={this.state.isOpen}
                                    toggle={this.showhideguardModal}
                                    availability= {this.props.availability}
                                    addGuard={this.addGuard}
                                    handleChangeGuard={this.handleChangeGuard}
                                    //handleDates={this.handleDates}
                                    retrocession_guard={this.state.retrocession_guard}
                                    start_date={new Date(this.state.start_date)}
                                    end_date={new Date(this.state.end_date)}
                                    guard_start_date={new Date(this.state.guard_end_date)}
                                    astreinte={this.state.astreinte}
                                    activite_previsionnelle={this.state.activite_previsionnelle}
                                />

                            </Collapse>
                            <Button type="button" onClick={this.showhideguardModal}>+ Ajouter des gardes</Button>
                        </>
                        }
                    </div>
                    {this.state.error && 
                        <div className="text-center text-danger">
                            <small>
                                {this.state.error}
                            </small>
                        </div>
                    }
                    <div>
                        <input type="submit" disabled={(this.state.replacementOverlapError || this.state.error || this.state.start_date === null || this.state.end_date === null) ? true : false} value="Envoyer l'invitation" className="srounded pl-5 pr-5 pt-2 pb-2 text-white bg-green btn" />
                    </div>
                    <div className="text-center text-danger">
                        <small>{(this.state.replacementOverlapError) && <FormattedMessage id="Overlapping.Existing.Replacement.Of.Same.Type" />}</small>
                    </div>
                </form>
            </React.Fragment>
        )
    }

    //changer checked du boutton radio
    editRadio = e => {
        var name = e.target.name
        if (name === "parking") {
            this.setState({ parking: !this.state.parking })
        }
        if (name === "housing") {
            this.setState({ housing: !this.state.housing })
        }
    }

    //mis ajour des champs formulaires ajout gardes
    getTime(date) {
        return new Date(date).getHours() + ':' + new Date(date).getMinutes()
    }

    handleChangeGuard = e => {
        var name = e.target.name
        var value = e.target.value
        this.setState({
            [name]: value
        })
    }

    //ajouter des gardes
    addGuard = (data) => {
        //Adding the guard to the list of guards
        this.setState({
            guardsList: [...this.state.guardsList, data]
        })
        //after adding the guard to the list
        this.setState({ isOpen: !this.state.isOpen })
    }

    // hide/show guard modal
    showhideguardModal = e => {
        e.preventDefault();
        this.setState({ isOpen: !this.state.isOpen })
    }

    showModal = e => {
        this.setState({ hasGuard: !this.state.hasGuard })
        //this.handleChange(e)
    }

    countCertainDays(days, d0, d1) {
        var ndays = 1 + Math.round((d1 - d0) / (24 * 3600 * 1000));
        var sum = function (a, b) {
            return a + Math.floor((ndays + (d0.getDay() + 6 - b) % 7) / 7);
        };
        return ndays.reduce(sum, 0);
    }

    updateTextInput = e => {
        e.preventDefault();
        this.setState({ outputActivitePrevisionnelle: e.target.value });
        this.handleChange(e);
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    handleChange(event) {
		event.preventDefault();
        var name = event.currentTarget.name
        var value = event.currentTarget.value
        if (name !== "guard_start_date" && name !== "guard_end_date") {
            this.setState({
                [name]: value
            })
        }
    }
}

//donnees gardes Composant AddGuardModal
function GuardCard(props) {
    return (
        <Col sm="8" className="col-centered text-center">
            <div className="profile-repl mt-5 mb-5">
                <div className={"profile-repl-title bg-guard"}>
                    <FormattedMessage id={Util.fstLetUpCase(props.type) + ".Fem"} />
                    {(props.type === "guard") && <>&nbsp;(<FormattedMessage id={Util.fstLetUpCase(props.day)} />)</>}
                </div>

                <div className="profile-repl-details mb-3">
                    <div>
                        <FormattedMessage id="Dates" />&nbsp;:&nbsp;
                        <FormattedMessage id="from" />&nbsp;{DateUtil.toDate(props.start_date)}&nbsp;
                    </div>
                    <div>
                        <FormattedMessage id="To" />&nbsp;{DateUtil.toDate(props.end_date)}
                    </div>

                    <div>
                        <FormattedMessage id="Astreinte" />&nbsp;:&nbsp;
                            {props.astreinte}&nbsp;
                    </div>

                    <div>
                        <FormattedMessage id="Guard.Retrocession" />&nbsp;:&nbsp;
                            {props.retrocession_guard}
                    </div>

                </div>

            </div>
        </Col>
    )
}

const mapStateToProps = state => {
    return {
        officeTimeTable: state.global.officeTimeTable
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAdds: (objName, obj, cbk) => dispatch(adds(objName, obj, cbk)),
        onReplacementOverlaps: (start, end, type, duration, days_available, cbk) => replacementOverlaps(start, end, type, duration, days_available, cbk),
        onConcludedAgreementOverlaps: (start, end, type, duration, days_available, cbk) => concludedAgreementOverlaps(start, end, type, duration, days_available, cbk),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OccasionalReplacementPropositionForm));