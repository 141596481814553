import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from "reactstrap";
import Pagination from "react-js-pagination";
import AccountingTableHeader from '../../AccountingTableHeader';
import AccountingTableFooter from '../../AccountingTableFooter';
import AccountingDailyTableRow from './AccountingDailyTableRow';
import AccountingDailyTotalRow from './AccountingDailyTotalRow';
import AccountingDailyExpenseTableRow from './AccountingDailyExpenseTableRow';
import DateUtil from '../../../../util/DateUtil';
import AccountingEnt from '../../../../entities/AccountingEnt';
import AccountingDailyConsultationTableRow from './AccountingDailyConsultationTableRow';

const AccountingDaily = ({
    loading,
    viewChoosen,
    skip,
    accountingsCount,
    dailyPaginationChange,
    limit,
    startDate,
    subcategory,
    switchSubcategory,
    dayChoosen,
    openModal,
    openExpensesModal,
    openconsultationsModal,
    accountings,
    accountingTotals,
    deleteAccoutingLine,
    user
}) => {
    if (accountings) accountings.sort((a, b) => new Date(b.date) - new Date(a.date));

    return (
        <>
            <Row className="mt-2">
                <Col className={`w-100 text-center uppercase mb-2 clickable ${subcategory === 'income' && 'choosenMoment'}`}
                    onClick={() => switchSubcategory('income')}>
                    <FormattedMessage id="Incomes" />
                </Col>
                <Col className={`w-100 text-center uppercase mb-2 clickable ${subcategory === 'consultation' && 'choosenMoment'}`}
                    onClick={() => switchSubcategory('consultation')}>
                    <FormattedMessage id="Consultation" />
                </Col>
                <Col className={`w-100 text-center uppercase mb-2 clickable ${subcategory === 'outcome' && 'choosenMoment'}`}
                    onClick={() => switchSubcategory('outcome')}>
                    <FormattedMessage id="Outcomes" />
                </Col>
            </Row>

            <div className="calendar-logo m-3 mt-2 mx-auto"></div>

            <Col className="w-100 text-center uppercase mb-4 mt-2 font-weight-bold dateHeader">
                <i className="fas fa-chevron-left mr-3 clickable" onClick={() => dayChoosen("yesterday")}></i>
                {DateUtil.toDate(startDate)}
                <i className="fas fa-chevron-right ml-3 clickable" onClick={() => dayChoosen(undefined, "tomorrow")}></i>
            </Col>

            <AccountingTableFooter
                daily
                loading={loading}
                openModal={openModal}
                openExpensesModal={openExpensesModal}
                openconsultationsModal={openconsultationsModal}
                subcategory={subcategory}
            />

            <AccountingTableHeader user={user} subcategory={subcategory} />

            {loading &&
                <Row>
                    <div className="spinner-border text-muted align mx-auto" role="status" style={{ marginTop: 125 }}></div>
                </Row>}

            {(accountings && subcategory === 'income') &&
                <>
                    <div className="text-muted font-italic m-2 ml-5">
                        {(accountings.filter((a) => a.categorie === "income").length === 0) && <FormattedMessage id="No.Income.Added" />}
                    </div>

                    {accountings.filter(
                        (accounting) => accounting.categorie === "income"
                    ).map(
                        (accounting) => {
                            return <AccountingDailyConsultationTableRow
                                key={accounting._id}
                                accountingAnnex={accounting}
                                openconsultationsModal={openconsultationsModal}
                                deleteAccoutingLine={deleteAccoutingLine}
                            />
                        })
                    }
                </>}

            {(accountings && subcategory === 'consultation') &&
                <>
                    <div className="text-muted font-italic m-2 ml-5">
                        {(accountings.filter((a) => a.categorie === "consultation").length === 0) && <FormattedMessage id="No.Consult.Added" />}
                    </div>

                    {accountings.filter(
                        (accounting) => accounting.categorie === "consultation"
                    ).map(
                        (accounting) => {
                            return <AccountingDailyTableRow
                                key={accounting._id}
                                openModal={openModal}
                                accounting={AccountingEnt.calculateCorrectPrices(accounting)}
                                deleteAccoutingLine={deleteAccoutingLine}
                            />
                        })
                    }
                </>
            }

            {(accountings && subcategory === 'outcome') &&
                <>
                    <div className="text-muted font-italic m-2 ml-5">
                        {(accountings.filter((a) => a.categorie === "outcome").length === 0) && <FormattedMessage id="No.Outcome.Added" />}
                    </div>

                    {accountings.filter(
                        (accounting) => accounting.categorie === "outcome"
                    ).map(
                        (accounting) => {
                            return <AccountingDailyExpenseTableRow
                                key={accounting._id}
                                accountingAnnex={accounting}
                                openExpensesModal={openExpensesModal}
                                deleteAccoutingLine={deleteAccoutingLine}
                            />
                        })
                    }
                </>}

            {(!loading && accountings.length > 0) &&
                <Row className="mt-4">
                    <Pagination
                        innerClass="w-100 pagination accounting-pagination justify-content-center"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={Math.round(skip / limit) + 1}
                        itemsCountPerPage={limit}
                        totalItemsCount={Math.max(accountingsCount === undefined ? accountingTotals.length : accountingsCount, accountings === undefined ? accountingTotals.length : accountings.length)}
                        pageRangeDisplayed={5}
                        onChange={(page) => dailyPaginationChange(page)}
                        hideDisabled={false}
                    />
                </Row>
            }

            {
                (accountingTotals !== undefined && accountingTotals.length > 0) &&
                <AccountingDailyTotalRow
                    user={user}
                    accountings={accountings}
                    accountingTotals={accountingTotals}
                />
            }


        </>
    );
}
export default AccountingDaily;