var values = [
    { label: "Yes", value: "0" },
    { label: "Only.Emergencies", value: "1" },
    { label: "No", value: "2" },
];

export default class SubstituteAcceptHomeVisits {

    static values() {
        return values;
    }

    static getLabel(nbr) {
        for (let value of values) {
            if (value.value === nbr) return value.label;
        }
    }

};