import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import { Calendar, momentLocalizer } from "react-big-calendar";
import Duration from '../../../enum/Duration';
import AppointmentUtil from '../../../util/AppointmentUtil';
import { editAppointment, deleteAppointment, addAppointmentModal, removeAppointmentModal, getAppointmentsByUser } from '../../../actions/appointment/appointment';
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";

const localizer = momentLocalizer(moment);

class SecretaryModalEditAppointment extends React.Component {

    constructor(props) {
        super(props);

        moment.locale('fr')

        this.state = {
            appointment_date: moment(this.props.appointment.appointment_date).format('YYYY-MM-DD'),
            appointment_hour: moment(this.props.appointment.appointment_date).format('HH:mm'),
            gender: this.props.appointment.patient.gender,
            name: this.props.appointment.patient.name,
            first_name: this.props.appointment.patient.first_name,
            birth_name: this.props.appointment.patient.birth_name,
            showBirthName: false,
            birth_date: this.props.appointment.patient.birth_date ? moment(this.props.appointment.patient.birth_date).format('YYYY-MM-DD') : '',
            phone_mobile: this.props.appointment.patient.phone_mobile,
            phone: this.props.appointment.patient.phone,
            email: this.props.appointment.patient.email,
            comments: this.props.appointment.comments,
            duration: this.props.appointment.duration,
            min: new Date(0, 0, 0, 7, 0, 0),
            max: new Date(0, 0, 0, 20, 0, 0),
            current_week: this.props.startWeekDate,
            appointmentsEvents: [],
            users: [],
            user: this.props.appointment.user_id,
            userSelect: '',
            error: '',
            modalValidation: false,
            showAppointment: true,
            closeModal: false,
            deleteModal: false,
            arrayOfficeHours: this.props.arrayOfficeHours
        };

        this.handleChange = this.handleChange.bind(this);
        this.onSubmitValidation = this.onSubmitValidation.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.loadUser = this.loadUser.bind(this);
        this.closeModalValidation = this.closeModalValidation.bind(this);
        this.changeView = this.changeView.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeModalSave = this.closeModalSave.bind(this);
        this.onDeleteValidation = this.onDeleteValidation.bind(this);
        this.closeModalDelete = this.closeModalDelete.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.dayEventCustom = this.dayEventCustom.bind(this);
        this.minimizeModal = this.minimizeModal.bind(this);
    };

    componentDidMount() {
        if(this.props.selectedUser) this.loadUser(this.state.appointment_date, false);
    }

    officeHours(date) {
        let arrayOfficeHours = AppointmentUtil.officeHours(date, this.props.user.role, this.props.selectedUser.officeTimeTable);

        this.setState({
            arrayOfficeHours: arrayOfficeHours
        })
    }

    loadUser(appointment_date, loadAppointments) {
        let arrayUsers = [];

        if (this.props.agreements && this.props.agreements.length > 0) {
            for (let agreement of this.props.agreements) {
                agreement.replacement.days_available.forEach((elem) => {
                    if (moment(elem.start).isSame(appointment_date, 'day')) {
                        arrayUsers.push({
                            id: agreement.substitute._id,
                            name: agreement.substitute.name,
                            first_name: agreement.substitute.first_name
                        })
                    }
                })
            }
        }

        arrayUsers.push({
            id: this.props.selectedUser.id,
            name: this.props.selectedUser.name,
            first_name: this.props.selectedUser.first_name
        });


        let userSelect = arrayUsers.find((user) => {
            return user.id === this.state.user;
        })

        if(!userSelect) {
            userSelect = this.props.selectedUser
        }

        if (loadAppointments === true) {
            let arrayUsersId = [this.props.selectedUser.id];
            if (arrayUsers.length > 0) {
                arrayUsers.forEach((user) => {
                    arrayUsersId.push(user.id);
                })
            }
            
            let data = {
                arrayUsers: arrayUsersId,
                startDate: moment(appointment_date).startOf('week').format('YYYY-MM-DD'),
                endDate: moment(appointment_date).endOf('week').subtract(1, 'day').format('YYYY-MM-DD')
            }

            this.props.getAppointmentsByUser(data);
        }

        this.setState({
            users: arrayUsers,
            userSelect: userSelect.name + ' ' + userSelect.first_name
        })
    }

    minimizeModal() {
        this.props.addAppointmentModal(this.props.appointment, this.props.arrayAgreements, this.props.agreements, this.props.selectedUser, this.state.arrayOfficeHours);
        this.props.close(this.state.appointment_date);
    }

    dayEventCustom(event) {
        let css = 'pt-1 pl-1';
        let content = moment(event.event.start).format('HH:mm') + ' ' + event.title;

        if(event.event.css === 'replacement') {
            content = event.event.title;
            css = 'pt-1 bg-occasional';
        } else if (event.event.css === 'emptyAppointment') {
            content = moment(event.event.start).format('HH:mm') + ' ' + event.event.title;
        }

        return (
            <div className={'text-color-black ' + css}>
                {content}
            </div>
        )
    }

    changeView(event) {
        let name = event.currentTarget.getAttribute('data-name');

        this.setState({
            showAppointment: name === 'patient' ? false : true
        })
    }

    handleChange(event) {
        let { name, value } = event.currentTarget;

        if (name === 'appointment_date') {
            let loadAppointments = false;

            // si la semaine change, besoin de charger les nouveaux rdv patients
            if (!moment(value).startOf('week').isSame(this.state.current_week, 'week')) {
                loadAppointments = true;
                this.officeHours(value);

                this.setState({
                    current_week: moment(value).startOf('week')
                })
            }
            this.loadUser(value, loadAppointments);
        }

        if (name === 'man' || name === 'woman') {
            this.setState({
                gender: name,
                showBirthName: name === 'woman' ? true : false
            })
        }
        
        if (name === 'user') {
            let user = this.state.users.find((user) => {
                return user.id === value;
            })

            this.setState({
                userSelect: user.name + ' ' + user.first_name
            })

        }

        this.setState({
            [name]: value
        })
    }

    closeModalValidation() {
        this.setState({
            modalValidation: false
        })
    }

    onSubmitValidation() {
        if (this.state.gender === '') {
            this.setState({
                error: 'Vous devez renseigner le genre'
            })
            return;
        }

        if (this.state.name === '' || this.state.first_name === '') {
            this.setState({
                error: 'Vous devez renseigner le nom et prénom du patient'
            })
            return;
        }

        this.setState({
            modalValidation: true
        })
    }

    closeModal() {
        this.setState({
            closeModal: true
        })
    }

    closeModalSave() {
        this.setState({
            closeModal: false
        })
    }

    closeModalDelete() {
        this.setState({
            deleteModal: false
        })
    }

    customToolBar() {
        return [];
    }

    onDeleteValidation() {
        this.setState({
            deleteModal: true
        })
    }

    onDelete() {
        this.props.deleteAppointment(this.props.appointment._id, () => {
            this.props.close(this.state.appointment_date);
            this.props.removeAppointmentModal(this.props.appointment._id);

            new Noty({
                type: "info",
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: this.props.intl.formatMessage({ id: 'Appointment.Delete' }),
                timeout: 6000,
            }).show();
        });
    }

    onSubmit() {
        let appointment_date = moment(this.state.appointment_date);
        // reconstruit la date en rajoutant l'heure
        appointment_date.set({
            hour: moment(this.state.appointment_hour, 'HH:mm').hour(),
            minute: moment(this.state.appointment_hour, 'HH:mm').minute()
        })

        let appointment = {
            _id: this.props.appointment._id,
            patient_id: this.props.appointment.patient._id,
            doctor_id: this.props.selectedUser.id,
            appointment_date: appointment_date,
            name: this.state.name,
            first_name: this.state.first_name,
            birth_name: this.state.birth_name,
            birth_date: this.state.birth_date,
            phone_mobile: this.state.phone_mobile,
            phone: this.state.phone,
            email: this.state.email,
            comments: this.state.comments,
            duration: this.state.duration,
            user_id: this.state.user,
            gender: this.state.gender
        };

        if (this.props.arrayAgreements && this.props.arrayAgreements.length > 0) {
            this.props.arrayAgreements.forEach((agreement) => {
                if (moment(agreement.start).isSame(this.state.appointment_date, 'day')) {
                    appointment.replacement_id = agreement.original._id;
                }
            })
        }

        this.props.editAppointment(appointment, this.state.user, () => {
            this.props.close(this.state.appointment_date);

            new Noty({
                type: "info",
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: this.props.intl.formatMessage({ id: 'Appointment.Edit' }),
                timeout: 6000,
            }).show();
        });
    }

    render() {
        const { arrayAgreements, appointmentsByUsers } = this.props;

        const appointmentsEvents = (appointmentsByUsers && appointmentsByUsers.length) ? AppointmentUtil.formatAppointments(appointmentsByUsers, this.state.user) : [];

        let concatEvents = arrayAgreements.concat(appointmentsEvents);

        return (
            <>
                <Modal size="lg" isOpen={true} toggle={this.closeModal} className="simple-modal simple-modal-white srounded modalAppointmentCustom">

                    <div className="header-modal-choice pt-2 row title-modal-custom">
                        <div className="col-12 d-flex justify-content-end align-items-end">
                            <button type="button" onClick={this.minimizeModal} className="title-modal-close-custom" aria-label="Close">
                                <span aria-hidden="true">-</span>
                            </button>
                            <button type="button" onClick={this.closeModal} className="title-modal-close-custom" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                    </div>

                    <ModalBody className="row">
                        <Col sm='4' id="calendarModalEditAppointment">
                            <Calendar
                                components={{ 
                                    toolbar: this.customToolBar,
                                    day: {
                                        event: this.dayEventCustom,
                                    },
                                }}
                                formats={{
                                    eventTimeRangeFormat: range =>
                                    ``
                                }}
                                renderable
                                popup={true}
                                step={15}
                                slots={4}
                                min={this.state.min}
                                max={this.state.max}
                                localizer={localizer}
                                defaultDate={new Date(this.state.appointment_date)}
                                date={new Date(this.state.appointment_date)}
                                onView={{}}
                                events={concatEvents}
                                view={'day'}
                                views={[
                                    'day'
                                ]}
                                slotPropGetter={(date) => AppointmentUtil.customSlotPropGetterDoctor(date, this.state.arrayOfficeHours, this.props.exceptionalOpen, this.props.exceptionalClose)}
                                culture={'fr'}
                                onNavigate={[]}
                                dayLayoutAlgorithm="no-overlap"
                            />
                        </Col>

                        <Col sm="8">
                            <div className='d-flex justify-content-center mb-2'>
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <span className={"nav-link " + (this.state.showAppointment === true ? 'active' : '')} data-name="rdv" onClick={this.changeView}>Rdv</span>
                                    </li>
                                    <li className="nav-item">
                                        <span className={"nav-link " + (this.state.showAppointment === true ? '' : 'active')} data-name="patient" onClick={this.changeView}>Patient</span>
                                    </li>
                                </ul>
                            </div>

                            {this.state.showAppointment === true && 
                                <>
                                    <div className='jumbotron'>
                                        <FormGroup row className="justify-content-center pt-2">
                                            <Label lg={2}>Médecin</Label>
                                            <Col lg={6}>
                                                <Input type="select" name="user" value={this.state.user} onChange={this.handleChange}>
                                                    {this.state.users && this.state.users.map((user, key) =>
                                                        <option key={key} value={user.id}>{user.name} {user.first_name}</option>
                                                    )}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row className="justify-content-center pt-2">
                                            <Label lg={2}>Date du rdv</Label>
                                            <Col lg={6}>
                                                <Input type="date" name="appointment_date" value={this.state.appointment_date} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row className="justify-content-center pt-2">
                                            <Label lg={2}>Heure</Label>
                                            <Col lg={6}>
                                                <Input type="time" name="appointment_hour" value={this.state.appointment_hour} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row className="justify-content-center pt-2">
                                            <Label lg={2}>Durée</Label>
                                            <Col lg={6}>
                                                <Input type="select" name="duration" value={this.state.duration} onChange={this.handleChange}>
                                                    {Duration.values().map((elem, key) => 
                                                        <option key={key} value={elem.value}>{elem.label}</option>
                                                    )}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                    <div className='jumbotron'>
                                        <FormGroup row className="justify-content-center pt-2">
                                            <Label className='d-flex align-items-center justify-content-end' lg={2}>Notes</Label>
                                            <Col lg={10}>
                                                <Input type="textarea" name="comments" value={this.state.comments} onChange={this.handleChange} rows="4"/>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                </>
                            }

                            {this.state.showAppointment === false && 
                                <div className='jumbotron'>
                                    <FormGroup row className="justify-content-center pt-2">
                                        <Label lg={2}>Genre</Label>
                                        <Col lg={3}>
                                            <span className='pr-4' htmlFor="woman">
                                                Femme
                                            </span>
                                            <Input type="radio" id='woman' name="woman" value={this.state.gender} onChange={this.handleChange} required checked={this.state.gender === 'woman'}/>
                                        </Col>
                                        <Col lg={3}>
                                            <span className='pr-4' htmlFor="man">
                                                Homme
                                            </span>
                                            <Input type="radio" id="man" name="man" value={this.state.gender} onChange={this.handleChange} required checked={this.state.gender === 'man'}/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="justify-content-center pt-2">
                                        <Label lg={2}>Nom</Label>
                                        <Col lg={6}>
                                            <Input type="text" name="name" value={this.state.name} onChange={this.handleChange} required />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="justify-content-center pt-2">
                                        <Label lg={2}>Prénom</Label>
                                        <Col lg={6}>
                                            <Input type="text" name="first_name" value={this.state.first_name} onChange={this.handleChange} required />
                                        </Col>
                                    </FormGroup>
                                    {this.state.showBirthName === true && 
                                        <FormGroup row className="justify-content-center pt-2">
                                            <Label lg={2}>Nom de naissance</Label>
                                            <Col lg={6}>
                                                <Input type="text" name="birth_name" value={this.state.birth_name} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                    }

                                    <FormGroup row className="justify-content-center pt-2">
                                        <Label lg={2}>Date de naissance</Label>
                                        <Col lg={6}>
                                            <Input type="date" name="birth_date" value={this.state.birth_date} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="justify-content-center pt-2">
                                        <Label lg={2}>Mobile</Label>
                                        <Col lg={6}>
                                            <Input type="tel" name="phone_mobile" value={this.state.phone_mobile} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="justify-content-center pt-2">
                                        <Label lg={2}>Fixe</Label>
                                        <Col lg={6}>
                                            <Input type="tel" name="phone" value={this.state.phone} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="justify-content-center pt-2">
                                        <Label lg={2}>Email</Label>
                                        <Col lg={6}>
                                            <Input type="email" name="email" value={this.state.email} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                </div>
                            }

                            {this.state.error && 
                                <div className='text-danger'>
                                    {this.state.error}
                                </div>
                            }
                            <Button color="secondary" onClick={this.closeModal}>Retour</Button>
                            <Button color="success" onClick={this.onSubmitValidation}>Enregistrer</Button>
                            <Button color="danger" onClick={this.onDeleteValidation}>Supprimer</Button>

                        </Col>
                    </ModalBody>
                </Modal>

                {this.state.modalValidation === true && 
                    <Modal size="lg" isOpen={true} toggle={this.closeModalValidation} className="simple-modal simple-modal-white srounded modalCustom">

                        <ModalHeader className="header-modal-choice">
                            <div className="text-left">
                                <button type="button" onClick={this.closeModalValidation} className="close d-inline-block" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div>
                                Validation
                            </div>
                        </ModalHeader>

                        <ModalBody className="row">
                            <Col sm="12">
                                Vous allez ajouter un rendez-vous patient pour <b> {this.state.userSelect} </b>
                            </Col>

                        </ModalBody>

                        <ModalFooter className="justify-content-center">
                            <Button color="secondary" onClick={this.closeModalValidation}>Retour</Button>
                            <Button color="success" onClick={this.onSubmit}>Enregistrer</Button>
                        </ModalFooter>
                    </Modal>
                }

                {this.state.closeModal === true && 
                    <Modal size="lg" isOpen={true} toggle={this.closeModalSave} className="simple-modal simple-modal-white srounded modalCustom">

                        <ModalHeader className="header-modal-choice">
                            <div className="text-left">
                                <button type="button" onClick={this.closeModalSave} className="close d-inline-block" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div>
                                Validation
                            </div>
                        </ModalHeader>

                        <ModalBody className="row">
                            <Col sm="12">
                                Les modifications effectuées n’ont pas été enregistrée. Souhaitez-vous les enregistrer ?
                            </Col>

                        </ModalBody>

                        <ModalFooter className="justify-content-center">
                            <Button color="secondary" onClick={() => this.props.close(this.state.appointment_date)}>Quitter quand même</Button>
                            <Button color="success" onClick={this.onSubmit}>Enregistrer</Button>
                        </ModalFooter>
                    </Modal>
                }

                {this.state.deleteModal === true && 
                    <Modal size="lg" isOpen={true} toggle={this.closeModalDelete} className="simple-modal simple-modal-white srounded modalCustom">

                        <ModalHeader className="header-modal-choice">
                            <div className="text-left">
                                <button type="button" onClick={this.closeModalDelete} className="close d-inline-block" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div>
                                Suppression
                            </div>
                        </ModalHeader>

                        <ModalBody className="row">
                            <Col sm="12">
                            Vous êtes sur le point de supprimer le rdv de {this.props.appointment.patient.name} {this.props.appointment.patient.first_name} du {moment(this.props.appointment.appointment_date).format('DD/MM/YYYY')} à {moment(this.props.appointment.appointment_date).format('HH:mm')} .
                            </Col>

                        </ModalBody>

                        <ModalFooter className="justify-content-center">
                            <Button color="secondary" onClick={this.closeModalDelete}>Annuler</Button>
                            <Button color="danger" onClick={this.onDelete}>Supprimer</Button>
                        </ModalFooter>
                    </Modal>
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
        exceptionalOpen: state.exceptionalOpen.exceptionalOpen,
        exceptionalClose: state.exceptionalClose.exceptionalClose,
        officeTimeTable: state.global.officeTimeTable,
        appointmentsByUsers: state.appointments.appointmentsByUsers,
    }
};

const mapDispatchToProps = dispatch => {
    return {
		editAppointment: (appointment, userId, cbk) => dispatch(editAppointment(appointment, userId, cbk)),
		deleteAppointment: (appointmentId, cbk) => dispatch(deleteAppointment(appointmentId, cbk)),
		addAppointmentModal: (appointment, arrayAgreements, agreements, selectedUser, arrayOfficeHours, cbk) => dispatch(addAppointmentModal(appointment, arrayAgreements, agreements, selectedUser, arrayOfficeHours, cbk)),
		removeAppointmentModal: (appointment) => dispatch(removeAppointmentModal(appointment)),
        getAppointmentsByUser: (data) => dispatch(getAppointmentsByUser(data)),
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SecretaryModalEditAppointment));