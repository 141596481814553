import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import 'react-datetime/css/react-datetime.css';
import { Row, Col } from 'reactstrap';
// import SubstituteRatingsProfile from './SubstituteRatingsProfile';
import SubstituteSoftwaresProfile from './SubstituteSoftwaresProfile';
import SubstitutePracticesProfile from './SubstitutePracticesProfile';
import SubstituteDUsProfile from './SubstituteDUsProfile';
import SubstituteDESCsProfile from './SubstituteDESCsProfile';
import SubInfoProfile from './SubstituteInfoProfile';

import StringUtil from '../../../util/StringUtil';

export default class SubstituteCharacteristicsProfile extends Component {

    buildRoundElem = (characteristics, field, i18n) => {
        if (!i18n) i18n = StringUtil.capitalize(field);

        return (
            <div className="round-elem">
                <div className={"round" + ((characteristics[field]) ? "-success" : "-failure")}>
                    {(characteristics[field]) ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}
                </div>
                <div className="title"><FormattedMessage id={i18n} /></div>
            </div>
        );
    }

    render() {
        const { characteristics /*, substitute */ } = this.props;

        return (
            <React.Fragment>

                <Row>

                    <Col md="6" className="">

                        <div className="d-inline-block mx-auto text-left">

                            {/* <div className="mt-3 mb-3 mx-auto text-left max-w-400px">
                                <SubstituteRatingsProfile substitute={substitute} />
                            </div> */}

                            <div className="mt-3 mb-3 mx-auto text-left max-w-400px">
                                <SubstituteSoftwaresProfile characteristics={characteristics} />
                            </div>

                            <div className="mt-3 mb-3 mx-auto text-left max-w-400px">
                                <SubstituteDUsProfile characteristics={characteristics} />
                            </div>

                            <div className="mt-3 mb-3 mx-auto text-left max-w-400px">
                                <SubstituteDESCsProfile characteristics={characteristics} />
                            </div>
                        </div>

                    </Col>

                    <Col className="d-inline-block mx-auto text-left">

                        <div className="mt-3 mb-3 mx-auto text-left max-w-400px">
                            <SubInfoProfile characteristics={characteristics} />
                        </div>

                        <div className="mt-3 mb-3 mx-auto text-left max-w-400px">
                            <SubstitutePracticesProfile characteristics={characteristics} />
                        </div>


                    </Col>

                </Row>

            </React.Fragment>
        );
    }
}


