import React from 'react'
import moment from 'moment'
import * as dates from 'react-big-calendar/lib/utils/dates'

function createCalendar(date) {
    let currentDate = moment(date)

    const first = currentDate.clone().startOf('month')
    const last = currentDate.clone().endOf('month')
    var weeksCount = Math.ceil((first.day() + last.date()) / 7)
    const calendar = Object.assign([], { currentDate, first, last })

    var firstDay = first.day() - 1;
    if(first.day() === 0) {
        weeksCount += 1;
        firstDay = 6;
    } 

    for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
        const week = []
        calendar.push(week)
        calendar.year = currentDate.year()
        calendar.month = currentDate.month()

        for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
            var pushDate = currentDate.clone().set('date', day + 1 - firstDay)
            week.push(pushDate)
        }
    }

    return calendar
}

function createCalendarProps(original_date, availabilitys, agreements, calendarEvents) {

    let currentDate = moment(original_date)

    const first = currentDate.clone().startOf('month')
    const last = currentDate.clone().endOf('month')
    var weeksCount = Math.ceil((first.day() + last.date()) / 7)
    const calendarProps = []

    var firstDay = first.day() - 1;
    if(first.day() === 0) {
        weeksCount += 1;
        firstDay = 6;
    } 

    for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
        const week = []
        calendarProps.push(week)

        for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
            const date = currentDate.clone().set('date', day + 1 - firstDay);
            let periodavailabilitys = false;
            let periodAgreement = false;
            if (availabilitys && availabilitys.length > 0) {
                availabilitys.forEach((availability) => {
                    if (availability.deleted === false && date.day() !== 0) {
                        let found = availability.days_available.find((elem) => {
                            return (moment(elem.start).isSame(date, 'day'));
                        })
                        if (found) {
                            periodavailabilitys = true;
                        }
                    }
                })
            }

            if (agreements && agreements.length > 0 && date.day() !== 0) {
                agreements.forEach((agreement) => {
                    if (!agreement.cancelled_by_substitute && !agreement.cancelled_by_doctor && !agreement.replacement.deleted) {
                        let days_available = agreement.replacement.days_available;
                        var lastElement;
                        if (agreement.replacement.applicants.length > 0) {
                            agreement.replacement.applicants.forEach((applicant) => {
                                if (applicant.substitute_id === agreement.substitute_id && applicant.substitute_status === 1 && applicant.doctor_status === 1 && applicant.negociation && applicant.negociation.length > 0) {
                                    lastElement = applicant.negociation[applicant.negociation.length - 1];
                                    days_available = lastElement.days_available;
                                }
                            })
                        }
                        let found = days_available.find((elem) => {
                            return (moment(elem.start).isSame(date, 'day'));
                        })
                        if (found) {
                            periodAgreement = true;
                        }
                    }
                })
            }

            if (calendarEvents && calendarEvents.length > 0 && date.day() !== 0) {
                calendarEvents.forEach((elem) => {
                    // Si la date est entre la date de début et de fin de l'événement
                    // Mais aussi entre la date de début et de fin de la proposition alors on le met en jours d'exceptionss
                    if (date.isBetween(elem.start_date, elem.end_date, 'day', [])) {
                        periodAgreement = true;
                    }
                })
            }
            week.push({
                periodavailabilitys,
                periodAgreement
            })
        }
    }
    return calendarProps
}

class CalendarDate extends React.Component {

    render() {
        const { dateToRender, dateOfMonth, dayBetween, periodAgreement, periodavailabilitys } = this.props

        const changeDate = () => this.props.changeDate(dateToRender)

        if (dateToRender.month() < dateOfMonth.month()) {
            return (
                <button disabled={true} className="date next-month">
                    {dateToRender.date()}
                </button>
            )
        }

        if (dateToRender.month() > dateOfMonth.month()) {
            return (
                <button disabled={true} className="date next-month">
                    {dateToRender.date()}
                </button>
            )
        }

        if (periodAgreement === true) {
            return (
                <button disabled={true} className="date in-month-disabled buttonGrey">
                    {dateToRender.date()}
                </button>
            )
        } else if (dayBetween === true) {
            return (
                <button className="date in-month cssNotifShow" onClick={changeDate}>
                    {dateToRender.date()}
                </button>
            )
        } else {
            return (
                <button className={"date in-month-disabled " + (periodavailabilitys === true ? 'cssNotifDispo' : '')} onClick={changeDate}>
                    {dateToRender.date()}
                </button>
            )
        }
    }
}

class Calendar extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            calendarProps: createCalendarProps(moment(props.date), props.availabilitys, props.agreements, props.calendarEvents),
            calendar: createCalendar(props.date)
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.date !== this.props.date) {
            let calendar = createCalendar(this.props.date)
            this.setState({
                calendar,
                calendarProps: createCalendarProps(moment(this.props.date), this.props.availabilitys, this.props.agreements, this.props.calendarEvents)
            })
        }

        if (prevProps.availabilitys !== this.props.availabilitys || prevProps.agreements !== this.props.agreements || prevProps.calendarEvents !== this.props.calendarEvents) {
            this.setState({
                calendarProps: createCalendarProps(moment(this.props.date), this.props.availabilitys, this.props.agreements, this.props.calendarEvents)
            })
        }
    }

    getDaysAvailable(date) {
        let value = false;
        this.props.days_available.forEach((elem) => {
            if (date.isSame(elem.start, 'day')) {
                value = true;
                return;
            }
        })
        return value;
    }

    render() {
        return (
            <div className="col-md-4">
                <div className="month-name">
                    {this.state.calendar.currentDate.format('MMMM').toUpperCase() + ' ' + this.state.calendar.currentDate.format('YYYY').toUpperCase()}
                </div>
                {['L', 'M', 'M', 'J', 'V', 'S', 'D'].map((day, index) => (
                    <span key={index} className="day">
                        {day}
                    </span>
                ))}
                {this.state.calendar.map((week, index) => (
                    <div key={index}>
                        {week.map((date, keyBis) => 
                            <span key={keyBis}>
                                <CalendarDate
                                    key={date.date()}
                                    dateToRender={date}
                                    dateOfMonth={this.state.calendar.currentDate}
                                    dayBetween={this.getDaysAvailable(date)}
                                    changeDate={this.props.changeDate}
                                    periodAgreement={this.state.calendarProps[index][keyBis].periodAgreement}
                                    periodavailabilitys={this.state.calendarProps[index][keyBis].periodavailabilitys}
                                />
                            </span>
                        )}
                    </div>
                ))}
            </div>
        )
    }
}

class DaysExceptions extends React.PureComponent {

    constructor(props) {
        super(props);

        let monthSelected = moment(this.props.startDate).diff(moment(), 'month') <= 0 ? 0 : moment(this.props.startDate).diff(this.props.date, 'month');
        this.state = {
            compteur: monthSelected <= 0 ? 0 : monthSelected,
            error: this.props.error,
            totalMonth: 3 + monthSelected
        }

        moment.locale('fr');
		this.onClickPrevious = this.onClickPrevious.bind(this);
        this.onClickNext = this.onClickNext.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.error !== prevProps.error) {
            this.setState({ error: this.props.error });
        }
    }
    
    onClickPrevious() {
        let compteur = this.state.compteur;
        let totalMonth = this.state.totalMonth;
        if (compteur === 0) {
            return;
        }
        compteur -= 1;
        totalMonth -= 1;
        this.setState({ compteur: compteur, totalMonth })
    }

    onClickNext() {
        let compteur = this.state.compteur;
        let totalMonth = this.state.totalMonth;
        if (compteur === 9) {
            return;
        }
        if(compteur === totalMonth - 3) {
            totalMonth += 1
        }
        compteur += 1;
        this.setState({ 
            compteur: compteur,
            totalMonth
        })
    }

    render() {
        let { date } = this.props
        let firstMonth = dates.startOf(date, 'month');
        let arrayMonth = Array.from({length: this.state.totalMonth}, (v, k) => moment(firstMonth).add(k, 'month').toString());
        if (arrayMonth.length > 3) {
            while(arrayMonth.length > 3) {
                arrayMonth.splice(0, 1);
            }
        }

        return (
            <div className="row">
                {this.state.error !== null &&
                    <div className="text-center text-danger">{this.state.error}</div>
                }
                <div className="col-md-12 buttonPreviousNext">
                    <button type="button" onClick={this.onClickPrevious}><span>‹</span></button>
                    <button type="button" onClick={this.onClickNext}><span>›</span></button>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        {arrayMonth.map((month, i) => 
                            <Calendar
                                key={i + 1}
                                date={month}
                                changeDate={this.props.changeDate}
                                agreements={this.props.agreements}
                                availabilitys={this.props.availabilitys}
                                calendarEvents={this.props.calendarEvents}
                                days_available={this.props.days_available}
                            />
                        )}
                    </div>
                </div>
                <div className="col-md-12 mb-5">
                    <button onClick={this.props.backToDateOfTheDay}>Revoir les dates proposées</button>
                </div>
            </div>
        )
    }
}

export default DaysExceptions