import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import 'react-datetime/css/react-datetime.css';
import MedicalSoftwares from '../../../enum/MedicalSoftwares';


export default class SubstituteSoftwaresProfile extends Component {

    render() {
        const { characteristics } = this.props;

        return (
            <React.Fragment>
                <h5 className="">
                    <b><FormattedMessage id="Softwares" /></b>
                </h5>
                <table className="text-left">
                    <tbody>
                        {characteristics.medical_software.map((s, i) => {
                            if (i % 2 !== 0) return null;
                            else return (
                                <tr key={"soft-" + i}>
                                    <td className="min-width-100">
                                        {s === 0 ?
                                            <div>
                                                {characteristics.other_software}
                                            </div>
                                            :
                                            <div>
                                                {(characteristics.medical_software[i]) && 
                                                    <FormattedMessage id={MedicalSoftwares.getLabel(characteristics.medical_software[i])} />
                                                }
                                            </div>
                                        }
                                    </td>
                                </tr>
                            )
                            })
                        }
                    </tbody>
                </table>

            </React.Fragment >
        );
    }
}


