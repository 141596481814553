import React from 'react';
import { Col } from "reactstrap";
import { FormattedMessage } from 'react-intl';
import DateUtil from '../../../../util/DateUtil';
import StringUtil from '../../../../util/StringUtil';
import Pagination from "react-js-pagination";

import AccountingTableFooter from '../../AccountingTableFooter';
import AccountingTableHeader from '../../AccountingTableHeader';
import AccountingMissionTableRow from './AccountingMissionTableRow';
import AccountingMissionTotalRow from './AcountingMissionTotalRow';
import ProfileImages from "../../../../enum/ProfileImages";


const AccountingMission = ({ loading, agreement, getNextMission, getPreviousMission, getMissionCertificate, user, missionsPaginationSkip, missionsPaginationLimit, accountingTotalsDays, missionPaginationChange }) => {

    const showTimeIfGuard = (date) => {
        if (agreement.replacement.type === "guard") return DateUtil.toDateWithHour(date);
        else return DateUtil.toDate(date)
    };

    agreement.accountings.forEach((accounting) => {
        accounting.retrocessionAmount = agreement.replacement.type === "guard"
            ? (agreement.replacement.guard_retrocession / 100) * accounting.total
            : (agreement.replacement.retrocession / 100) * accounting.total
    });

    return (
        <>
            {agreement !== undefined ?
                <>
                    <Col className="w-100 text-center uppercase mb-4 mt-4 font-weight-bold dateHeader">

                        <img src={ProfileImages.getSrc(agreement.doctor.image)} className="d-block mx-auto mb-3"
                            width="100" alt="" style={{ borderRadius: "100px" }} />

                        <p>{agreement.doctor.first_name}</p>
                        <i className="fas fa-chevron-left mr-3 clickable" onClick={() => getPreviousMission()}></i>

                        <FormattedMessage id={`${StringUtil.capitalize(agreement.replacement.type)}`} />
                        {' '}
                        <FormattedMessage id='from' />
                        {' '}
                        {showTimeIfGuard(agreement.replacement.start_date)}
                        {' '}
                        <FormattedMessage id='To' />
                        {' '}
                        {showTimeIfGuard(agreement.replacement.end_date)}

                        <i className="fas fa-chevron-right ml-3 clickable" onClick={() => getNextMission()}></i>

                    </Col>

                    <AccountingTableHeader mission user={user} />

                    <>
                        {agreement.accountingsPaginate.map((accounting, i) =>
                            <AccountingMissionTableRow
                                key={i}
                                user={user}
                                accounting={accounting}
                            />
                        )}
                    </>

                    <div className="mt-4">
                        <Pagination
                            innerClass="w-100 pagination accounting-pagination justify-content-center"
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={Math.round(missionsPaginationSkip / missionsPaginationLimit) + 1}
                            itemsCountPerPage={missionsPaginationLimit}
                            totalItemsCount={accountingTotalsDays.length}
                            pageRangeDisplayed={5}
                            onChange={(page) => missionPaginationChange(page)}
                            hideDisabled={false}
                        />
                    </div>

                    <AccountingMissionTotalRow
                        totalLines={agreement.accountingsPaginate}
                        user={user}
                        loading={loading}
                        accountings={agreement.accountings}
                        getMissionCertificate={getMissionCertificate}
                    />

                    <AccountingTableFooter />
                </>
                :
                <Col className="w-100 text-center uppercase mb-4 mt-4 font-weight-bold dateHeader">
                    <FormattedMessage id="No.Replacements" />
                </Col>
            }
        </>
    );
}

export default AccountingMission;