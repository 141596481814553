import React from 'react';

import { InputComposer, InputComponent } from '@gferrand/react-forms';


export default class CheckboxInput extends InputComposer {

    constructor(name, label, validators, options) {
        super(name, label, validators, options);

        // Initialize value
        this.initialize(false);
    }

    mongoType() {
        return "Boolean, default: false";
    }

    toJoi() {
        return "boolean()";
    }

    getComponent() {
        return <CheckboxInputComponent />;
    }

}

class CheckboxInputComponent extends InputComponent {

    onChange(e) {
        e.stopPropagation();

        var newValue = !this.state.value;

        super.onChange(newValue, () => this.onBlur());
    }

    render() {
        return (
            <label className="control control-checkbox">
                {super.wrap(<input
                    type="checkbox"
                    id={this.props.name}
                    checked={this.state.value}
                    invalid={!!this.state.error}
                    onChange={(e) => this.onChange(e)}
                    disabled={this.state.disabled || this.props.options.disabled}
                />)}
                <div className="control_indicator"></div>
            </label>
        );
    }

}