import React from 'react';
import { Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class AddPatientStep4 extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			validation_check: this.props.getStore().validation_check || false,
			loading: false
		}

		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.back = this.back.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.error !== this.props.error && this.props.error === 'Veuillez controler votre adresse') {
			this.props.jumpToStep(2)
		}
	}

	onChange(event) {
		const { checked } = event.currentTarget;
		this.setState({
			validation_check: checked
		})
	}

	onSubmit(event) {
		event.preventDefault();
		this.setState({ loading: true })
		this.props.submitCbk(this.state, () => this.props.jumpToStep(4));
	}

	back() {
		this.props.updateStore(this.state);
		this.props.jumpToStep(2)
	}

	isValidated() {
		return false
	}

	render() {

		return (
			<Row className="step step1 mt-2 justify-content-md-center ">
				<Col lg="8">
					<Form onSubmit={this.onSubmit}>
						<FormGroup row>
							<Input type="checkbox" name="check" id="validation_check" checked={this.state.validation_check} onChange={this.onChange} required />
        					<Label for="validation_check" check>Je certifie avoir informé mon patient (ou son représentant légal) sur le traitement de ses données. Ce dernier accepte d’être contacté pour participer à une étude clinique.</Label>
						</FormGroup>
						<FormGroup row>
        					<p>Notre médecin vérifiera prochainement les critères de sélection auprès du patient. En cas d’inclusion définitive dans le protocole, vous serez contacté par l’équipe d’Hydrogen afin de recevoir votre indemnisation.</p>
						</FormGroup>
						<FormGroup row className="justify-content-center">
							<i>Cliquez <Link to="/termspatients" target="_blank">ici</Link> pour consulter les CGV / CGU</i>
						</FormGroup>
						<Row className="mx-auto form-buttons">
							<Button disabled={this.state.loading} color="secondary" className="btn btn-ladda lowercase srounded pl-5 pr-5 pt-2 pb-2 bg-grey text-white mx-auto" onClick={this.back}>Précédent</Button>
							<Button disabled={this.state.loading} color="success" className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green mx-auto" type="submit">Suivant</Button>
						</Row>
					</Form>
				</Col>
			</Row>
		);
	};
};