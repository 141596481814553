import { combineReducers } from 'redux';

import i18n from './i18n/i18nReducer';
import global from './global/globalReducer';

import clinicalStudy from './clinicalStudy/clinicalStudyReducer';
import replacements from './replacements/replacementsReducer';
import retrocessionCertificate from './retrocessionCertificate/retrocessionCertificateReducer';
import appointments from './appointment/appointmentsReducer';
import exceptionalOpen from './exceptionalOpen/exceptionalOpenReducer';
import exceptionalClose from './exceptionalClose/exceptionalCloseReducer';
import agreements from './agreements/agreementsReducer';
import officeTimeTable from './officeTimeTable/officeTimeTableReducer';
import secretary from './secretary/secretaryReducer';
import secretaryManagement from './secretaryManagement/secretaryManagementReducer';
import userReducer from './user/userReducer';

import {LOGOUT} from "../actions/authentication/authentication";

const appReducer = combineReducers({
    i18n,
    global,
    clinicalStudy,
    replacements,
    retrocessionCertificate,
    userReducer,
    appointments,
    agreements,
    officeTimeTable,
    exceptionalOpen,
    exceptionalClose,
    secretary,
    secretaryManagement
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        // Wipe all redux state
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export default rootReducer;