import React from 'react';
import DatePicker from 'react-date-picker';
import { Input } from 'reactstrap';
import moment from 'moment';

import { InputComposer, InputComponent } from '@gferrand/react-forms';
import IsDate from './IsDate';


export default class TextDateCustomInput extends InputComposer {

    constructor(name, label, validators = [], options = {}) {
        validators.push(IsDate);

        super(name, label, validators, options);

        this.className = options.className;
        this.errorClassName = options.errorClassName;
        this.calendarClassName = options.calendarClassName;
    }

    mongoType() {
        return "Date";
    }

    toJoi() {
        return "date()";
    }

    getComponent() {
        return <TextDateCustomComponent
            className={this.className}
            calendarClassName={this.calendarClassName}
            errorClassName={this.errorClassName} />;
    }

}

class TextDateCustomComponent extends InputComponent {

    constructor(props) {
        super(props);

        this.state = {
            hours: ''
        }

        this.uuid = "cal-" + Math.random();

        this.onChangeTime = this.onChangeTime.bind(this);
    }

    componentDidMount() {
        this.lockInputs();
        if (this.props.name === 'start_date' || this.props.name === 'end_date') {
            let hours = moment(this.props.state.value).format('HH:mm');
            this.setState({
                hours
            })
        }
    }

    componentDidUpdate(prevProps) {
        this.lockInputs();

        if (prevProps.state.value !== this.props.state.value) {
            if (this.props.name === 'start_date' || this.props.name === 'end_date') {
                let hours = moment(this.props.state.value).format('HH:mm');
                this.setState({
                    hours
                })
            }
        }
    }

    lockInputs() {
        const element = document.getElementsByClassName(this.uuid);

        let input = element[0].children[0].children[0].children[0];
        let daysInput = element[0].getElementsByClassName("react-date-picker__inputGroup__day")[0];
        let monthsInput = element[0].getElementsByClassName("react-date-picker__inputGroup__month")[0];
        let yearsInput = element[0].getElementsByClassName("react-date-picker__inputGroup__year")[0];

        input.readOnly = true;
        daysInput.readOnly = true;
        monthsInput.readOnly = true;
        yearsInput.readOnly = true;
        daysInput.disabled = true;
        monthsInput.disabled = true;
        yearsInput.disabled = true;
    }

    onChangeTime(event) {
        let date = moment(this.state.value);
        let value = event.currentTarget.value;
        let hours = moment(value, 'HH:mm').hours();
        let minutes = moment(value, 'HH:mm').minutes();
        date.set({ hour: hours, minute: minutes });

        this.setState({
            hours: value
        })

        return super.onChange(date);
    }

    render() {
        const { className, calendarClassName, errorClassName } = this.props;

        return super.wrap(
            <div className="row">
                <div className="text-date-custom-pl">
                    <DatePicker
                        ref={(ref) => this.ref = ref}
                        className={this.uuid + " " + ((!!this.state.error) ? errorClassName : className) + " no-color-input"}
                        calendarClassName={calendarClassName}
                        format={"dd/MM/yyyy"}
                        clearIcon={null}
                        id={this.props.name}
                        onClick={e => { if (this.ref) this.ref.openCalendar(); e.stopPropagation() }}
                        value={(this.state.value) ? new Date(Date.parse(this.state.value)) : ""}
                        invalid={!!this.state.error}
                        autoComplete="off"
                        onChange={(value) => this.onChange(value)}
                        onFocus={(e) => { e.stopPropagation() }}
                        onBlur={() => this.onBlur(this.props.name, this.state.value)}
                        disabled={this.state.disabled || this.props.options.disabled}
                        showLeadingZeros={true}
                    />
                </div>

                <div className="text-date-custom text-date-custom-pl row">
                    {this.props.name === 'start_date' ?
                        <div className="pt-2 pr-2">
                            de
                        </div>
                        :
                        <div className="pt-2 pr-2">
                            à
                        </div>
                    }
                    <div>
                        <Input type="time" className="form-control" 
                        id={this.props.name}
                        value={this.state.hours || ""}
                        invalid={!!this.state.error}
                        autoComplete="off"
                        onChange={this.onChangeTime}
                        onBlur={() => this.onBlur(this.props.name, this.state.value)}
                        disabled={this.state.disabled || this.props.options.disabled} />
                    </div>
                </div>
            </div>
        );
    }

}