import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { adds } from "../../actions/global/global";


class Finish extends React.Component {


    render() {
        return (
            <React.Fragment>

                <Row className="step step4 mt-2 justify-content-md-center ">
                    <Col lg="8">
                        <Card className="rounded-0">
                            <CardBody className="p-5 text-center">

                                <div className="font-weight-bold">
                                    <p><b>Félicitations !</b></p>

                                    <p className="pb-0 mb-0">Votre inscription est bien enregistrée.</p>

                                    <br />

                                    <p className="pb-0 mb-0">L'équipe HYDROGEN vérifie votre statut et vous adressera </p>
                                    <p className="pb-0 mb-0">un mail de confirmation dans les plus brefs délais.</p>

                                    <br />

                                    <p>A très vite!</p>
                                    <p>L'équipe HYDROGEN</p>

                                </div>


                                <hr className="mt-5 mb-5" />

                                <p>Un doute?</p>

                                <p className="pb-0 mb-0">Toutes les informations liées à votre profil sont accessibles</p>
                                <p className="pb-0 mb-0">et modifiables à tout moment depuis votre espace personnel.</p>
                                {/* <p className="pb-0 mb-0">N'hésitez pas à modifier et enrichir votre profil public</p> */}
                                <p className="pb-0 mb-0">dès validation de votre inscription!</p>

                                <div className="mx-auto form-buttons mt-5">
                                    <Link className="btn srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green" to="/"><FormattedMessage id="GoBackToHomePage" /></Link>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        //
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAdds: (objName, obj, cbk) => dispatch(adds(objName, obj, cbk))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Finish);