import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Badge } from 'reactstrap';
import { Link } from 'react-router-dom'; 
import { getAgreementCount } from '../../../actions/agreement/agreement';
import { getReplacementCount, getReplacementInvitationCount } from '../../../actions/replacements/replacements';

import DPublicReplacements from './DPublicReplacements';
import DPrivateReplacements from './DPrivateReplacements';
import DNotDoneAgreements from '../agreements/DNotDoneAgreements';
import DConcludedAgreements from '../agreements/DConcludedAgreements';

class SReplacements extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            redirect: false,

            annonces: null,
            invitations: null,
            contrats: null,

            // Pagination
            skip: 0,
            limit: 10,

        };

        this.redirectAfterSign = this.redirectAfterSign.bind(this);
        this.updateCountAfterAccept = this.updateCountAfterAccept.bind(this);
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.props.getAgreementCount((agreementsCount) => {
            this.setState({ contrats: agreementsCount })
        });

        this.props.getReplacementCount((replacementsCount) => {
            this.setState({ annonces: replacementsCount })
        });

        this.props.getReplacementInvitationCount((replacementsInvitationCount) => {
            this.setState({ invitations: replacementsInvitationCount })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.redirect !== this.state.redirect) {
            this.props.getAgreementCount((agreementsCount) => {
                this.setState({ contrats: agreementsCount })
            });
    
            this.props.getReplacementCount((replacementsCount) => {
                this.setState({ annonces: replacementsCount })
            });
    
            this.props.getReplacementInvitationCount((replacementsInvitationCount) => {
                this.setState({ invitations: replacementsInvitationCount })
            })
        }
    }

    redirectAfterSign() {
        this.setState({
            redirect: true
        })
    }
    
    updateCountAfterAccept() {
        this.fetchData();
    }

    render() {
        var { category } = this.props.match.params;

        if (this.state.redirect === true) {
			this.props.history.push('/home/me/replacements/agreements');
            this.setState({ redirect: false });
        }
        if (!category) category = "public";
        

        let replacementId;
        let openCollapse = false;
        if (this.props.history.location && this.props.history.location.state) {
            replacementId = this.props.history.location.state.replacementId;
            openCollapse = this.props.history.location.state.openCollapse === 0 ? false : true;
        }
        return (
            <React.Fragment>
                <div className="w-100 text-white text-center uppercase lp-2">
                    <h4><FormattedMessage id="My.Replacements" /></h4>
                </div>

                <Row className="text-center mt-4 mb-4 text-white font-big">
                    <Col sm="3">
                        <Link to="/home/me/replacements/public" className={"link-hoverable text-white " + ((category === "public") ? "link-active" : "")}>
                            <FormattedMessage id="My.Posts" />
                            <Badge color="danger" pill>{this.state.annonces && this.state.annonces}</Badge>
                        </Link>
                    </Col>

                    <Col sm="3">
                        <Link to="/home/me/replacements/private" className={"link-hoverable text-white " + ((category === "private") ? "link-active" : "")}>
                            <FormattedMessage id="My.Invitations" />
                            <Badge color="danger" pill>{this.state.invitations && this.state.invitations}</Badge>
                        </Link>
                    </Col>

                    <Col sm="3">
                        <Link to="/home/me/replacements/agreements" className={"link-hoverable text-white " + ((category === "agreements") ? "link-active" : "")}>
                            <FormattedMessage id="My.Received.Agreements" />
                            <Badge color="danger" pill>{this.state.contrats && this.state.contrats}</Badge>
                        </Link>
                    </Col>

                    <Col sm="3">
                        <Link to="/home/me/replacements/history" className={"link-hoverable text-white " + ((category === "history") ? "link-active" : "")}>
                            <FormattedMessage id="My.History" />
                        </Link>
                    </Col>
                </Row>

                {(category === "public") && <DPublicReplacements updateCountAfterAccept={this.updateCountAfterAccept} redirectAfterSign={this.redirectAfterSign} replacementId={replacementId} openCollapse={openCollapse} />}
                {(category === "private") && <DPrivateReplacements updateCountAfterAccept={this.updateCountAfterAccept} redirectAfterSign={this.redirectAfterSign} replacementId={replacementId}/>}
                {(category === "agreements") && <DNotDoneAgreements />}
                {(category === "history") && <DConcludedAgreements past={true} />}

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        //
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAgreementCount: (callback) => getAgreementCount(callback),
        getReplacementCount: (callback) => getReplacementCount(callback),
        getReplacementInvitationCount: (callback) => getReplacementInvitationCount(callback),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SReplacements);