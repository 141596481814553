import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Util from '../../../util/Util';
import UserForm from '../form/UserForm';

/**
 * @description Registration step 1
 * @param {string} userRole
 * @param {function} getStore()
 */
export default class Step1 extends Component {

	constructor(props) {
		super(props);

		this.form = React.createRef();
		this.hasOfficeNumber = this.props.userRole !== 'secretary' && this.props.userRole !== 'subsitute';
	}

	isValidated() {
		let form = Object.assign({}, this.form.current.form.getRawData(), { role: this.props.userRole, image: this.form.current.state.image });
		this.props.updateStore({ user: form });
		return this.form.current.validate(); 
	}

	render() {
		const submitCbk = (data) => {
			this.props.updateStore({ user: data });
			this.props.jumpToStep(1);

			if (window.scrollTo) window.scrollTo(0, 0);
		};
		
		let defaultValues = Object.assign({}, this.props.getStore().user);
		if(Util.emptyObject(defaultValues)) defaultValues = undefined;

		return (
			<React.Fragment>

				<Row className="step step1 mt-2 justify-content-md-center ">
					<Col lg="8">

						<UserForm
							defaultValues={defaultValues}
							validate={this.props.validate}
							submitCbk={submitCbk}
							submitButtoni18nId={"Next.Step"}
							ref={this.form}
							hasOfficeNumber={this.hasOfficeNumber}
							role={this.props.userRole} />
							
					</Col>
				</Row>

			</React.Fragment>
		);
	};
};