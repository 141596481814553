import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


export default class SimpleMsgModal extends React.Component {

    render() {
        return (
            <Modal isOpen={true} toggle={this.props.onClose} size="md" className="simple-modal simple-modal-orange srounded">

                <ModalHeader toggle={this.props.onClose}>
                    {(this.props.header) && this.props.header}
                </ModalHeader>

                <ModalBody className="p-4 mb-4">

                    {this.props.content}

                </ModalBody>

            </Modal>
        );

    }

}
