import axios from "axios";

const rootUrl = process.env.REACT_APP_BE_URL || "http://localhost:7000";

export function editDuration(duration, callback) {

	return dispatch => {
		dispatch(request());

        var url = rootUrl + "/doctorCharacteristic/duration";

        let params = { duration };

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure(err))
        });
	};

    function request() { return { type: "EDIT_DURATION_REQUEST", editDuration: false, }}
	function success() { return { type: "EDIT_DURATION_SUCCESS", editDuration: true }}
	function failure(error) { return { type: "EDIT_DURATION_FAILURE", editDuration: false, error: error }}
};