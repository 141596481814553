import React from 'react';
import { Button } from 'reactstrap';
import { InputComposer, InputComponent } from '@gferrand/react-forms';

export default class WeekDaysInput extends InputComposer {

    valid = () => true;

    getComponent() {
        return <WeekDaysInputComponent />;
    }

}

class WeekDaysInputComponent extends InputComponent {

    constructor(props) {
        super(props);

        this.state = {
            value: [],
            error: null,
            negociation: props.label ? true : false
        };
    }

    __onChange(value){
        if(this.state.value.includes(value)) {
            if (this.state.value.length !== 1) {
                this.setState({ value: this.state.value.filter(v => v !== value )}, this.onChange(value));
            }
        } else {
            this.setState({ value: [...this.state.value, value ] }, this.onChange(value));
        } 
    }

    createButton(letter, value) {
        if (this.state.negociation === true) {
            let className = "radio-btn btn btn-primary ";
            if (this.state.value.includes(value) && this.props.label.includes(value)) {
                className += "active";
            } else if (!this.state.value.includes(value) && !this.props.label.includes(value)) {
                className += "negociable";
            } 
            return (
                <Button 
                    type="button"
                    disabled={(this.props.label.includes(value) ? false : true)}
                    className={className}
                    onClick={() => this.__onChange(value)}
                    >
                    <span>{letter}</span>
                </Button>
            )
        } else {
            return (
                <Button 
                    type="button"
                    className={"radio-btn btn btn-primary " + ((this.state.value.includes(value)) ? "active" : "")}
                    onClick={() => this.__onChange(value)}
                    >
                    <span>{letter}</span>
                </Button>
            )
        }
    }

    render() {
        return super.wrap(
            <div id="day" className="btn-group">

                {this.createButton("L", "monday")}
                {this.createButton("M", "tuesday")}
                {this.createButton("M", "wednesday")}
                {this.createButton("J", "thursday")}
                {this.createButton("V", "friday")}
                {this.createButton("S", "saturday")}
                
            </div>
        );
    }
}