import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Badge } from 'reactstrap';
import { Link } from 'react-router-dom'; 
import { getAgreementCount } from '../../../actions/agreement/agreement';
import { getReplacementCount, getReplacementInvitationCount, getReplacementWatchCount } from '../../../actions/replacements/replacements';

import SWatchedReplacements from './SWatchedReplacements';
import SPrivateReplacements from './SPrivateReplacements';
import SConcludedAgreements from '../agreements/SConcludedAgreements';
import SNotDoneAgreements from '../agreements/SNotDoneAgreements';

class SReplacements extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            redirect: false,

            annonces: null,
            invitations: null,
            total: null,

            // Pagination
            skip: 0,
            limit: 10
        };

        this.redirectAfterSign = this.redirectAfterSign.bind(this);
        this.updateCountAfterAccept = this.updateCountAfterAccept.bind(this);
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        let count = 0
        this.props.getAgreementCount((agreementsCount) => {
            count = agreementsCount;
            this.setState({ contrats: count })

            // this.props.getReplacementCount((replacementsCount) => {
            //     count += replacementsCount
            //     this.setState({ contrats: count })
            // });
        });

        this.props.getReplacementInvitationCount((replacementsInvitationCount) => {
            this.setState({ invitations: replacementsInvitationCount })
        })

        this.props.getReplacementWatchCount((replacementsWatchCount) => {
            this.setState({ annonces: replacementsWatchCount })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.redirect !== this.state.redirect) {
            let count = 0
            this.props.getAgreementCount((agreementsCount) => {
                count = agreementsCount;
                this.setState({ contrats: count })
                /*this.props.getReplacementCount((replacementsCount) => {
                    count += replacementsCount
                    this.setState({ contrats: count })
                });*/
            });
    
            this.props.getReplacementInvitationCount((replacementsInvitationCount) => {
                this.setState({ invitations: replacementsInvitationCount })
            })
    
            this.props.getReplacementWatchCount((replacementsWatchCount) => {
                this.setState({ annonces: replacementsWatchCount })
            })
        }
    }

    redirectAfterSign() {
        this.setState({
            redirect: true
        })
    }

    updateCountAfterAccept() {
        this.fetchData();
    }

    render() {
        var { category } = this.props.match.params;
        if (this.state.redirect === true) {
			this.props.history.push('/home/replacements/agreements');
            this.setState({ redirect: false });
        }
        if (!category) category = "private";

        return (
            <React.Fragment>

                <div className="w-100 text-white text-center uppercase lp-2">
                    <h4><FormattedMessage id="My.Replacements" /></h4>
                </div>

                <Row className="mt-4 mb-4 text-white font-big text-center mx-auto">
                    <Col sm="3">
                        <Link to="/home/replacements/private" className={"link-hoverable text-white " + ((category === "private") ? "link-active" : "")}>

                            <FormattedMessage id="Received.Posts" />
                            <Badge color="danger" pill>{this.state.invitations && this.state.invitations}</Badge>

                        </Link>
                    </Col>

                    <Col sm="3">
                        <Link to="/home/replacements/watched" className={"link-hoverable text-white " + ((category === "watched") ? "link-active" : "")}>
                            <FormattedMessage id="Followed.Posts" />
                            <Badge color="danger" pill>{this.state.annonces && this.state.annonces}</Badge>
                        </Link>
                    </Col>

                    <Col sm="3">
                        <Link to="/home/replacements/agreements" className={"link-hoverable text-white " + ((category === "agreements") ? "link-active" : "")}>
                            <FormattedMessage id="My.Agreements" />
                            <Badge color="danger" pill>{this.state.contrats && this.state.contrats}</Badge>
                        </Link>
                    </Col>

                    <Col sm="3">
                        <Link to="/home/replacements/history" className={"link-hoverable text-white " + ((category === "history") ? "link-active" : "")}>
                            <FormattedMessage id="My.History" />
                        </Link>
                    </Col>
                </Row>

                {(category === "private") && <SPrivateReplacements updateCountAfterAccept={this.updateCountAfterAccept} redirectAfterSign={this.redirectAfterSign}/>}
                {(category === "watched") && <SWatchedReplacements redirectAfterSign={this.redirectAfterSign}/>}
                {(category === "agreements") && <SNotDoneAgreements redirectAfterSign={this.redirectAfterSign}/>}
                {(category === "history") && <SConcludedAgreements past={true} />}

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        //
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAgreementCount: (callback) => getAgreementCount(callback),
        getReplacementCount: (callback) => getReplacementCount(callback),
        getReplacementInvitationCount: (callback) => getReplacementInvitationCount(callback),
        getReplacementWatchCount: (callback) => getReplacementWatchCount(callback),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SReplacements);