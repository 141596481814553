import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import {
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col
} from 'reactstrap';

import { Form, RangeInput } from '@gferrand/react-forms';
import { upds } from "../../../actions/global/global";
import Noty from 'noty';



class EditRetrocessionModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null
        };
    }

    toggle() {
        this.props.toggle();
    }

    closeModal() {
        this.setState({ modal: null });
    }

    render() {
        const { replacement } = this.props;

        const retrocessionToEdit = replacement.retrocession || replacement.guard_retrocession;

        var submitCbk = (data) => {
            var newRepl = { _id: replacement._id };

            if (replacement.retrocession) newRepl.retrocession = data.new_retrocession;
            else if (replacement.guard_retrocession) newRepl.guard_retrocession = data.new_retrocession;

            this.props.onUpds("replacement", newRepl, () => {
                this.toggle();

                new Noty({
                    type: "info",
                    layout: 'topRight',
                    theme: 'bootstrap-v4',
                    text: this.props.intl.formatMessage({ id: "Retrocession.Successfuly.Updated" }),
                    timeout: 6000,
                }).show();
            });
        };

        var data = {
            new_retrocession: retrocessionToEdit,
        };

        var form = new Form({
            name: "doc_retrocession_change_request",
            inputs:
                [new RangeInput("new_retrocession", "New.Retrocession", retrocessionToEdit, 100, 2.5, [],
                    { suffix: "%", className: (replacement.type === "guard") ? "red-range-input" : "blue-range-input" })],
            submitCbk,
            cancelCbk: () => this.props.toggle(),
            data
        });

        return (
            <React.Fragment>

                <Modal size="xl" isOpen={true} className="simple-modal simple-modal-white srounded" toggle={() => this.props.toggle()}>

                    <ModalHeader toggle={() => this.props.toggle()}>
                    </ModalHeader>

                    <ModalBody className="pt-0">

                        <h4 className="w-100 uppercase text-center">
                            <FormattedMessage id="Edit.Retrocession" />
                        </h4>

                        <Row>
                            <Col className="text-center mt-4 mb-4">

                                <Col sm="12" className="mr-4 pr-4 mt-2 text-center">
                                    {form.body()}
                                </Col>

                            </Col>
                        </Row>

                        <div className="mb-3">
                            {form.submitButton("Update", { className: "srounded pl-5 pr-5 pt-2 pb-2 text-white bg-green btn  opacity-1" })}
                        </div>

                    </ModalBody>

                </Modal>

                {this.state.modal}

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        agreements: state.global.agreements
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onUpds: (objName, obj, cbk) => dispatch(upds(objName, obj, cbk))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EditRetrocessionModal));