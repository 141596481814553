import React from 'react';
import Geocode from "react-geocode";
import { withGoogleMap, GoogleMap, Marker, Circle} from "react-google-maps";
//import SProfileForm from '../../views/registration/substitute/forms/SProfileForm';

export default class GMapWithoutScript extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

        // Geocode (for geocoding, no kidding)
        this.GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
        Geocode.setApiKey(this.GOOGLE_MAP_API_KEY);

        if (this.props.addfixMapCbk) this.props.addfixMapCbk((lat, lng, radius_preference, radius_effort) => this.fixMap(lat, lng, radius_preference, radius_effort));
        if (this.props.addLoadMapCbk) this.props.addLoadMapCbk(() => this.loadMapCbk());
       
    }

    componentDidMount() {
        this.fixMap();
    }

    // 
    loadMapCbk() {
        this.setState({ loading: true });
    }

    fixMap(lat = 48.8534, lng = 2.3488, radius_preference, radius_effort) {
        const optionsRadiusPreference = {
            fillColor: "#0357ff",
            strokeColor: "#0357ff",
            strokeOpacity: 1,
            fillOpacity: 0,
        }
        
        const optionsRadiusEffort = {
            fillColor: "#ff0303",
            strokeColor: "#ff0303",
            strokeOpacity: 1,
            fillOpacity: 0,
        }

        this.mapComponent = withGoogleMap((props) =>
            <GoogleMap
                defaultZoom={8}
                defaultCenter={{ lat, lng }}
                defaultOptions={{
                    fullscreenControl: false,
                    gestureHandling: 'none',
                    mapTypeControl: false,
                    zoomControl: true,
                    draggable: true,
                    streetViewControl: false
                }}
            >
                <Marker position={{ lat, lng }} />
                
                {radius_preference >= 0 &&
                    <Circle
                        options={optionsRadiusPreference} 
                        defaultCenter={{
                          lat: parseFloat(lat),
                          lng: parseFloat(lng)
                        }}
                        radius={(parseInt(radius_preference) + 1) * 1000}
                    />
                }

                {radius_effort > 0 &&
                    <Circle
                        options={optionsRadiusEffort} 
                        defaultCenter={{
                          lat: parseFloat(lat),
                          lng: parseFloat(lng)
                        }}
                        radius={(parseInt(radius_effort) - 1) * 1000}
                    />
                }

            </GoogleMap>
        );

        this.setState({ loading: false });
    }

    loading = () => {
        return (
            <div className="w-100 text-center mt-2 mb-2">
                <div className="spinner-border text-white mx-auto" role="status" style={{ marginTop: 125 }}></div>
            </div>
        );
    }

    render() {
        return (
            <div className="p-0 m-0" style={{ height: `${this.props.height}px` }}>
                {(this.mapComponent) &&
                    <this.mapComponent
                        style={{ marginTop: "-10px", padding: 0, margin: 0 }}
                        isMarkerShown={true}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `${this.props.height}px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />
                }

                {(this.state.loading) &&
                    <div className="position-absolute top left bg-transp-grey" style={{ width: "calc(100% - 20px)", left: "10px", height: `${this.props.height}px` }}>
                        {this.loading()}
                    </div>
                }
            </div>
        );
    }

}