import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, ModalHeader, ModalBody, Button, Col } from 'reactstrap';
import DateUtil from '../../util/DateUtil';
import Util from '../../util/Util';
import moment from 'moment';
import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";

class ModalForYear extends React.Component {

    render() {
        var result = [];
        if (this.props.eventOfDay.length > 0) {
            this.props.eventOfDay.forEach((elem, key) => {
                if (elem._type === 'Availability') {
                    elem.data.forEach((availability) => {
                        if (availability.type === 'regular') {
                            var nameDay;
                            if (availability.day === 0) {
                                nameDay = 'Sunday';
                            } else if (availability.day === 1) {
                                nameDay = 'Monday';
                            } else if (availability.day === 2) {
                                nameDay = 'Tuesday';
                            } else if (availability.day === 3) {
                                nameDay = 'Wednesday';
                            } else if (availability.day === 4) {
                                nameDay = 'Thursday';
                            } else if (availability.day === 5) {
                                nameDay = 'Friday';
                            } else if (availability.day === 6) {
                                nameDay = 'Saturday';
                            }
                        }
                        result.push(
                        <div className="profile-repl mt-5 mb-5" key={key}>
    
                            <div className={"profile-repl-title bg-" + availability.type}>
                                <FormattedMessage id={Util.fstLetUpCase(availability.type) + ".Fem"} />
                                {(availability.type === "regular") && <>&nbsp;(<FormattedMessage id={Util.fstLetUpCase(nameDay)} />)</>}
                            </div>
    
                            <div className="profile-repl-details mb-3">
                                <div>
                                    <FormattedMessage id="Dates" />&nbsp;:&nbsp;
                                        <FormattedMessage id="from" />&nbsp;{DateUtil.toDate(availability.start_date)}&nbsp;
                                    </div>
                                <div>
                                    <FormattedMessage id="To" />&nbsp;{DateUtil.toDate(availability.end_date)}
                                </div>
    
                                <div>
                                    <FormattedMessage id="Wished.Retrocession" />&nbsp;:&nbsp;
                                    {availability.retrocession_wished}&nbsp;%
                                    </div>
    
                                <div>
                                    <FormattedMessage id="Distance.Wished" />&nbsp;:&nbsp;
                                    {availability.radius_preference}&nbsp;km
                                </div>
                                
                                <div>
                                    <i className="fa icon-ICON__OEIL_GRIS"></i>&nbsp;&nbsp;
                                    {availability.views}&nbsp;
                                    <FormattedMessage id="Views" />
                                </div>
    
                                <div>
                                    <i className="fa icon-ICON__SIGNATURE_GRIS_C"></i>&nbsp;
                                    {availability.privateReplacementsCount}&nbsp;
                                    <FormattedMessage id="Replacement.Proposals" />
                                </div>
    
                            </div>
                        </div>)
                    })
                } else if (elem._type === 'Replacement') {
                    result.push(
                        <div className="profile-repl mt-5 mb-5" key={key}>
                            <div className="profile-repl-title">
                                {elem.title}
                            </div>
                            <div className="profile-repl-details mb-3">
                                <div>
                                    <FormattedMessage id="Dates" />&nbsp;:&nbsp;
                                        <FormattedMessage id="from" />&nbsp;{DateUtil.toDateWithHour(elem.start)}&nbsp;
                                    </div>
                                <div>
                                    <FormattedMessage id="To" />&nbsp;{DateUtil.toDateWithHour(elem.end)}
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    elem.data.forEach((sub_elem) => {
                        result.push(
                            <div className="profile-repl mt-5 mb-5" key={key}>
                                <div className={"profile-repl-title bg-orange"}>
                                    <FormattedMessage id="Calendar.Event" />
                                </div>
                                <div className="profile-repl-details mb-3">
                                    <div>
                                        <FormattedMessage id="Dates" />&nbsp;:&nbsp;
                                            <FormattedMessage id="from" />&nbsp;{moment(sub_elem.start_date).format('DD/MM/YYYY HH:mm')}&nbsp;
                                        </div>
                                    <div>
                                        <FormattedMessage id="To" />&nbsp;{moment(sub_elem.end_date).format('DD/MM/YYYY HH:mm')}
                                    </div>
                                    <div>
                                        <b><FormattedMessage id="Title" />&nbsp;:&nbsp;{sub_elem.title}</b>
                                    </div>

                                </div>
                            </div>
                        )
                    })
                }
            })
        } else {
            result.push('Aucun évènement pour cette journée')
        }

        return (
            <React.Fragment>

                <Modal size="md" isOpen={true} toggle={() => this.props.close()} className="simple-modal simple-modal-white srounded">
                    <ModalHeader className="border-0 m-0">
                        <div className="text-left">
                            <button type="button" className="close d-inline-block" aria-label="Close" onClick={() => this.props.close()}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                    </ModalHeader>

                    <ModalBody className="p-0">
                        <Col sm="8" className="col-centered text-center">
                            {result}
                        </Col>
                        <div className="w-100 text-center mt-4 mb-3">
                            <Button
                                className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green"
                                onClick={() => this.props.close()}>
                                <FormattedMessage id="Close" />
                            </Button>

                        </div>

                    </ModalBody>

                </Modal>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModalForYear));