import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from "reactstrap";
import DateUtil from '../../../../util/DateUtil';

const AccountingDailyExpenseTableRow = ({ accountingAnnex, openExpensesModal, deleteAccoutingLine }) => {
    const { reason, total, payment_methods, date } = accountingAnnex;

    return (
        <>
        <Row className="tableRow pt-0 m-0 pl-5">
            <Col className="text-left text-dark pl-3 h-45px lh-45">{DateUtil.toHourWithoutSeconds(date)}</Col>
            <Col className="text-left text-dark h-45px lh-45 pl-0" sm={7}>
                (<FormattedMessage id={`${payment_methods}`} />){' '}{reason}
            </Col>
            <Col className="text-left h-45px lh-45 pl-5">{total}</Col>
            <Col className="text-right h-45px lh-45">
                <i className="fas fa-pencil-alt clickable mr-5" onClick={() => openExpensesModal(accountingAnnex)}></i>
                <i className="fas fa-times clickable mr-5" onClick={(e) => deleteAccoutingLine(e, accountingAnnex)}></i>
            </Col>
        </Row>
        </>
    );
}

export default AccountingDailyExpenseTableRow;