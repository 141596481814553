import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import 'ladda/dist/ladda-themeless.min.css';
import PsyIAboutProfile from './PsyIAboutProfile';
import PsyIMoreDetailsProfile from './PsyIMoreDetailsProfile';
import PsyIPracticesProfile from './PsyIPracticesProfile';
import PsyIOfficeTimeTableProfile from './PsyIOfficeTimeTableProfile';

export default class PsyICharacteristicsProfile extends Component {

    render() {
        const { psychologistICharacteristic, officeTimeTable} = this.props;

        return (
            <React.Fragment>

                <Row>

                    <Col md="6">
                        <div className="mt-3 mb-3 mx-auto text-left max-w-400px">
                            <PsyIAboutProfile psychologistICharacteristic={psychologistICharacteristic} />
                        </div>

                        <div className="mt-3 mb-3 mx-auto text-left max-w-400px">
                            <PsyIMoreDetailsProfile psychologistICharacteristic={psychologistICharacteristic} />
                        </div>
                    </Col>
                    
                    <Col md="6">
                        <div className="mt-3 mb-3 mx-auto max-w-400px">
                            <PsyIPracticesProfile psychologistICharacteristic={psychologistICharacteristic} />
                        </div>
                    </Col>

                </Row>

                <Row className="">

                </Row>

                <div className="pb-3">
                    <div className="">
                        <PsyIOfficeTimeTableProfile officeTimeTable={officeTimeTable} />
                    </div>
                </div>

            </React.Fragment >
        );
    };

}