import React from 'react'
import moment from 'moment'
import * as dates from 'react-big-calendar/lib/utils/dates'
import { navigate } from 'react-big-calendar/lib/utils/constants'
import ModalForYear from './ModalForYear'

function createCalendar(date) {
    let currentDate = moment(date)

    const first = currentDate.clone().startOf('month')
    const last = currentDate.clone().endOf('month')
    var weeksCount = Math.ceil((first.day() + last.date()) / 7)
    const calendar = Object.assign([], { currentDate, first, last })

    var firstDay = first.day() - 1;
    if(first.day() === 0) {
        weeksCount += 1;
        firstDay = 6;
    } 

    for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
        const week = []
        calendar.push(week)
        calendar.year = currentDate.year()
        calendar.month = currentDate.month()

        for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
            var pushDate = currentDate.clone().set('date', day + 1 - firstDay)
            week.push(pushDate)
        }
    }

    return calendar
}

function createCalendarProps(original_date, events, from) {
    let currentDate = moment(original_date)

    const first = currentDate.clone().startOf('month')
    const last = currentDate.clone().endOf('month')
    var weeksCount = Math.ceil((first.day() + last.date()) / 7)
    const calendarProps = []

    var firstDay = first.day() - 1;
    if(first.day() === 0) {
        weeksCount += 1;
        firstDay = 6;
    } 

    for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
        const week = []
        calendarProps.push(week)

        for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
            const date = currentDate.clone().set('date', day + 1 - firstDay);

            let cssNotif;
            let cssNotif2;
            let cssNotif3 = ' cssNotifShowGuardTransparent ';
            let today = 'in-month';
            if (date.isSame(moment(), 'day')) {
                today = 'today';
            }
            if (events) {
                // Boucle sur les évènements pour ajouter le css 
                events.forEach((event) => {
                    let eventStart = moment(event.start);
                    // cas d'un remplacement signé
                    if (eventStart.isSame(date, 'day') && event._type === 'Replacement') {
                        if (from === 'DCalendar') {
                            if (event.sign === false) {
                                if (event.original.type === 'guard') {
                                    cssNotif = ' cssNotifDispo ';
                                } else if (event.original.type === 'occasional') {
                                    cssNotif = 'cssNotifDispo';
                                } else if (event.original.type === 'regular') {
                                    cssNotif = 'cssNotifDispo';
                                }
                            } else {
                                if (event.color === 'occasional') {
                                    cssNotif = ' cssNotifShow ';
                                } else if (event.color === 'regular') {
                                    cssNotif = 'cssNotifRegularSigner';
                                } else if (event.color === 'guard') {
                                    cssNotif = 'cssNotifShowGuard';
                                }
                            }
                        } else {
                            if (event.color === 'occasional') {
                                cssNotif = ' cssNotifShow ';
                            } else if (event.color === 'regular') {
                                cssNotif = 'cssNotifRegularSigner';
                            } else if (event.color === 'guard') {
                                cssNotif = 'cssNotifShowGuard';
                            }
                        }
                    }
                    // Cas d'une dispo posé
                    if (eventStart.isSame(date, 'day') && event._type === 'Availability') {
                        if (event.data) {
                            event.data.forEach((elem) => {
                                if (elem.type === 'guard') {
                                    cssNotif = ' cssNotifDispo ';
                                } else if (elem.type === 'occasional') {
                                    cssNotif = 'cssNotifDispo';
                                } else if (elem.type === 'regular') {
                                    cssNotif = 'cssNotifDispo';
                                }
                            })
                        }
                    }
                    // cas d'un évènement
                    if (eventStart.isSame(date, 'day') && event._type === 'CalendarEvent') {
                        cssNotif2 = 'cssNotifShowEvent ';
                    } 
                })
            }
            week.push({
                cssNotif,
                cssNotif2,
                cssNotif3, 
                today
            })
        }
    }

    return calendarProps;
}

class CalendarDate extends React.Component {
    render() {
        const { dateToRender, dateOfMonth, cssNotif, cssNotif2, cssNotif3, today } = this.props;

        if (dateToRender.month() < dateOfMonth.month()) {
            return (
                <button disabled={true} className="date next-month">
                    {dateToRender.date()}
                </button>
            )
        }

        if (dateToRender.month() > dateOfMonth.month()) {
            return (
                <button disabled={true} className="date next-month">
                    {dateToRender.date()}
                </button>
            )
        }

        return (
            <button
                className={`date ${today} ${cssNotif}`}
                onClick={() => this.props.onClick(dateToRender)}>
                {dateToRender.date()}
                <div className="orderDivNotif">
                    <div className={`${cssNotif3} notifSize`}></div>
                    <div className={`${cssNotif2} notifSize`}></div>
                </div>
            </button>
        )
    }
}

class Calendar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            calendar: createCalendar(this.props.date),
            modal: null,
            calendarProps: createCalendarProps(moment(props.date), props.events, props.from)
        }

        this.openModal = this.openModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.date !== this.props.date) {
            let calendar = createCalendar(this.props.date)
            this.setState({
                calendar
            })
        }

        if (prevProps.events !== this.props.events || prevProps.date !== this.props.date) {
            this.setState({
                calendarProps: createCalendarProps(moment(this.props.date), this.props.events, this.props.from)
            })
        }
    }

    // Ferme la modal
    closeModal() {
        this.setState({ modal: null });
    };

    // Fonction qui ouvre la modal
    openModal(day) {
        let eventOfDay = [];
        let events = Array.from(this.props.events);
        events.forEach((elem) => {
            // Permet de savoir si la date se trouve durant un évènement
            // Le dernier signe compte aussi la date de début et de fin dans la période
            if (day.isBetween(elem.start, elem.end, 'day', '[]')) {
                eventOfDay.push(elem)
            } else {
                // Si le jour correspond au jour de début ou de fin d'un évènement
                if (moment(day).isSame(elem.start, 'day') || moment(day).isSame(elem.end, 'day')) {
                    eventOfDay.push(elem)
                }
            }
        })
        this.setState({
            modal: <ModalForYear
                close={() => this.closeModal()}
                eventOfDay={eventOfDay}
            />
        });
    }

    render() {
        return (
            <div className="month col-12 col-md-3 text-center">
                <div className="month-name">
                    {this.state.calendar.currentDate.format('MMMM').toUpperCase()}
                </div>
                {['L', 'M', 'M', 'J', 'V', 'S', 'D'].map((day, index) => (
                    <span key={index} className="day">
                        {day}
                    </span>
                ))}
                {this.state.calendar.map((week, index) => (
                    <div key={index}>
                        {week.map((date, keyBis) => 
                            <CalendarDate
                                key={date.date()}
                                dateToRender={date}
                                dateOfMonth={this.state.calendar.currentDate}
                                onClick={this.openModal}
                                events={this.props.events}
                                cssNotif={this.state.calendarProps[index][keyBis].cssNotif}
                                cssNotif2={this.state.calendarProps[index][keyBis].cssNotif2}
                                cssNotif3={this.state.calendarProps[index][keyBis].cssNotif3}
                                today={this.state.calendarProps[index][keyBis].today}
                                from={this.props.from}
                            />
                        )}
                    </div>
                ))}

                {this.state.modal}
            </div>
        )
    }
}

class Year extends React.Component {

    constructor(props) {
        super(props);

        moment.locale('fr');
    }

    render() {
        const firstMonth = dates.startOf(this.props.date, 'year')
        return <div className="year row">
            {[...Array(12).keys()].map( i => 
                <Calendar
                    key={i + 1}
                    date={dates.add(firstMonth, i, 'month')}
                    events={this.props.events}
                    from={this.props.from}
                />
            )}
            </div>
    }
}

Year.range = date => {
    return [dates.startOf(date, 'year')]
}

Year.navigate = (date, action) => {
    switch (action) {
        case navigate.PREVIOUS:
            return dates.add(date, -1, 'year')

        case navigate.NEXT:
            return dates.add(date, 1, 'year')

        default:
            return date
    }
}

Year.title = (date) => {
    return moment(date).format('YYYY')
}

export default Year