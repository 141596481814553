import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { upds, getsBy, clear, getBy } from "../../../actions/global/global";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col
} from 'reactstrap';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import InfoModal from "../../../components/modal/InfoModal";
import SignatureInput from '../../../components/SignatureInput';
import EventManager from '../../../util/EventManager';



class DReplacementContractModal extends React.Component {

    constructor(props) {
        super(props);

        let content = (
            <>
            <h3 className="mb-4"><FormattedMessage id="Temporary.Replacement.Contract" /></h3>
            <p><FormattedMessage id="Doc.PreContract.Message" /></p>
            </>
        );

        this.state = {
            initials: null,
            signature: null,
            initialsError: null,
            signatureError: null,
            disabled: false,
            modal: <InfoModal content={content} toggle={() => this.setState({ modal: null })} />
        };
    }

    componentDidMount() {
        const { agreement, applicant } = this.props;

        this.props.onGetBy("agreementHTML", ["replacementId", "doctorId", "substituteId"], [agreement.replacement._id, undefined, applicant._id]);
    }

    onInitials(initials) {
        this.setState({ initials, initialsError: null });
    }

    onSignature(signature) {
        this.setState({ signature, signatureError: null });
    }

    onConfirm(agreement) {
        var initialsError = null;
        var signatureError = null;
        if (!this.state.initials) initialsError = <FormattedMessage id="Initials.Error" />;
        if (!this.state.signature) signatureError = <FormattedMessage id="Signature.Error" />;

        if (initialsError || signatureError) {
            this.setState({ initialsError, signatureError });
            return;
        }

        var data = {
            agreement_id: agreement._id,
            doctor_signature: this.state.signature,
            doctor_initials: this.state.initials,
        };
        
        // Lock the button
        this.setState({ disabled: true });
        let firstName;
        if (agreement.substitute) {
            firstName = agreement.substitute.first_name;
        } else {
            let substitute_id = this.props.agreement.substitute_id;
            this.props.replacement.applicants_users.forEach((elem) => {
                if (elem._id === substitute_id) {
                    firstName = elem.first_name;
                    return;
                }
            })
        }

        let idMessage;
        if (!agreement.substitute_initials) {
            idMessage = "Signature.Add.Success";
        } else {
            idMessage = "DSignature.Add.Success"
        }

        // Send to be
        this.props.onUpds("agreement/sign", data, (agreement) => {
            if (this.props.onSignatureCbk) this.props.onSignatureCbk(agreement);
            this.props.toggle();
            if(this.props.redirectAfterSign) {
                this.props.redirectAfterSign();
            }

            new Noty({
                type: "info",
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: this.props.intl.formatMessage({ id: idMessage}, { sub: firstName }),
                timeout: 6000,
            }).show();

            EventManager.pub("contractSigned");
        });
    }

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-secondary mx-auto" role="status"></div></div>;

    onSignClick() {
        document.getElementById('sign-drepl').scrollIntoView({
            behavior: 'smooth'
        });
    }

    render() {
        const { agreement, agreementHTML } = this.props;

        return (
            <React.Fragment>
                <Modal size="lg" isOpen={true} className="simple-modal simple-modal-white srounded" toggle={() => this.props.toggle()}>

                    <ModalHeader toggle={() => this.props.toggle()}>
                    </ModalHeader>

                    <ModalBody className="pt-0">
                        <div className="w-100 text-center uppercase mb-4">
                            <h5 className="bold lp-3"><b><FormattedMessage id="Your.Contract" /></b></h5>
                        </div>

                        {(agreementHTML) ?
                            <div className="text-left">
                                <div className="signature-btn" onClick={() => this.onSignClick()}>
                                    <i className="fa icon-ICON__SIGNATURE_GRIS_C"></i>
                                </div>

                                <div className="pdf-wrapper" dangerouslySetInnerHTML={{ __html: agreementHTML }}></div>
                            </div>
                            :
                            this.loading()
                        }

                        <Row className="mt-4">

                            <Col md="6">

                                <div id="sign-drepl" className="m-auto text-center text-uppercase" style={{ width: "350px" }}>
                                    <h5><FormattedMessage id="Paraph" /></h5>

                                    <SignatureInput
                                        containerId="initials-container"
                                        onEnd={(value) => this.onInitials(value)}
                                        error={this.state.initialsError} />

                                </div>

                            </Col>

                            <Col md="6">

                                <div className="m-auto text-center text-uppercase" style={{ width: "350px" }}>
                                    <h5><FormattedMessage id="Signature" /></h5>

                                    <SignatureInput
                                        containerId="signature-container"
                                        onEnd={(value) => this.onSignature(value)}
                                        error={this.state.signatureError} />

                                </div>

                            </Col>

                        </Row>

                        <LaddaButton
                            className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green mt-4 mb-3"
                            onClick={() => this.onConfirm(agreement)}
                            data-style={EXPAND_LEFT}
                            loading={this.state.disabled}
                            disabled={this.state.disabled}>
                            <FormattedMessage id="Confirmation" />
                        </LaddaButton>

                    </ModalBody>

                </Modal>

                {this.state.modal}

            </React.Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
        agreementHTML: state.global.agreementHTML,
        user: state.global.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onUpds: (objName, obj, cbk) => dispatch(upds(objName, obj, cbk)),
        onGetsBy: (objName, fields, values, callback) => dispatch(getsBy(objName, fields, values, callback)),
        onGetBy: (objName, params, values) => dispatch(clear(objName, getBy(objName, params, values)))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DReplacementContractModal));