import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom'; 
import { clears, getsBy } from '../../actions/global/global';
import Subscription from '../../enum/Subscription';
import ProfileImages from '../../enum/ProfileImages';
import ValidationSubscriptionModal from './ValidationSubscriptionModal';
import AddCodeModal from './AddCodeModal';
import HYDROGEN_ILLUSTRATIONS_MAP_REMPLACANTS_TRANSP from '../../assets/images/subscription/HYDROGEN_ILLUSTRATIONS-MAP-REMPLACANTS-TRANSP.png';
import HYDROGEN_ILLUSTRATIONS_KEY from '../../assets/images/subscription/HYDROGEN_ILLUSTRATIONS_KEY.png'

class HomeSubscription extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            subscriptions_replacement: null,
            subscriptions_succession: null,
            show_replacement: true,
            show_succession: false,
            code_subscription: '',
            modal: null,
            error: null
        }

        this.changeCategory = this.changeCategory.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModalCode = this.openModalCode.bind(this);
    }

    componentDidMount() {
        if (this.props.user) {
            this.fetchData();
        }

        if (this.props.history.location.state && this.props.history.location.state.openModal === true) {
            this.setState({
                modal: <ValidationSubscriptionModal 
                    close={this.closeModal}
                    choice={this.props.history.location.state.choice}
                />
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            this.fetchData();
        }
    }

    fetchData() {
        this.props.onGetsBy("subscription", 'user_id', this.props.user._id, (subscriptions) => {
            if (subscriptions && subscriptions.length > 0) {
                subscriptions.forEach((elem) => {
                    if (elem.subscribe_active === true && this.props.user.role === 'substitute' && (elem.choice === 0 || elem.choice === 1 || elem.choice === 2)) {
                        this.setState({
                            subscriptions_replacement: elem.choice
                        })
                    }
                    
                    if (elem.subscribe_active === true && this.props.user.role === 'doctor' && (elem.choice === 4 || elem.choice === 5 || elem.choice === 6 || elem.choice === 7)) {
                        this.setState({
                            subscriptions_replacement: elem.choice
                        })
                    }

                    if (elem.subscribe_active === true && (elem.choice === 3 || elem.choice === 8 || elem.choice === 9 || elem.choice === 10 || elem.choice === 11)) {
                        this.setState({
                            subscriptions_succession: elem.choice
                        })
                    }
                })
            }
        });
    }

    changeCategory(event) {
        let name = event.currentTarget.id;
        if (name === 'succession') {
            this.setState({
                show_replacement: false,
                show_succession: true
            })
        } else if (name === 'replacement') {
            this.setState({
                show_replacement: true,
                show_succession: false
            })
        }
    }

	handleChange(event) {
		event.preventDefault();
		let fieldname = event.currentTarget.name;
		this.setState({
			[fieldname]: event.currentTarget.value
		})
	}

    closeModal(fromCode, choice) {
        if (fromCode === true) {
            this.fetchData();
            if (choice === 'succession') {
                this.setState({
                    show_replacement: false,
                    show_succession: true
                })
            } else if (choice === 'replacement') {
                this.setState({
                    show_replacement: true,
                    show_succession: false
                })
            }
        }
        this.setState({ modal: null });
    }

    openModalCode() {
        this.setState({
            modal: <AddCodeModal 
                close={this.closeModal}
            />
        })
    }

	render() {
		const enterCode = this.props.intl.formatMessage({id: 'I.Have.A.Code'});

        let labelReplacement = '';
        if (this.state.subscriptions_replacement !== null) {
            labelReplacement = Subscription.getLabel(this.state.subscriptions_replacement);
            if (this.state.subscriptions_replacement === 4 || this.state.subscriptions_replacement === 5 || this.state.subscriptions_replacement === 6 || this.state.subscriptions_replacement === 7) {
                labelReplacement = 'premium';
            }
        }

        let labelSuccession = '';
        if (this.state.subscriptions_succession !== null) {
            labelSuccession = Subscription.getLabel(this.state.subscriptions_succession);
            if (this.state.subscriptions_succession === 8 || this.state.subscriptions_succession === 9 || this.state.subscriptions_succession === 10 || this.state.subscriptions_succession === 11) {
                labelSuccession = 'alliance';
            }
        }

		return (
            <div>

                <div className="w-100 text-white text-center uppercase mb-4 bolder fs-18">
                    <FormattedMessage id='Subscription.Space'/>
                </div>
                
                {this.props.user && this.props.user.role === 'substitute' &&
                <div>
                    <ul className="nav nav-tabs row">
                        <li className="nav-item col-md-6 col-sm-12 text-center">
                            <h4 onClick={this.changeCategory} id="replacement" className={"nav-link nav-custom-width " + (this.state.show_replacement === true ? 'active' : 'text-white')}><FormattedHTMLMessage id={"You.Are.Looking.For.Replacement"} /></h4>
                        </li>
                        <li className="nav-item col-md-6 col-sm-12 text-center">
                            <h4 onClick={this.changeCategory} id="succession" className={"nav-link nav-custom-width " + (this.state.show_succession === true ? 'active' : 'text-white')}><FormattedHTMLMessage id={"You.Are.Looking.For.Settlement"} /></h4>
                        </li>
                    </ul>

                    <div className="row text-white">
                        {this.state.show_replacement === true &&
                            <div>
                                <div className="row pt-3">
                                    {this.state.subscriptions_replacement === null ?
                                        <div className="col-12 pt-3 pb-3 text-center">
                                            <Link to="/home/subscription/register/replacement" className="text-white border xrounded bg-green text-white p-3 text-center">
                                                <FormattedMessage id={"I.Want.Subscription"} />
                                            </Link>
                                        </div>
                                        :
                                        <div className="col-md-4 offset-md-4 border xrounded bg-green text-white p-3 text-center">
                                            <FormattedMessage id={"Already.Subscribe"} />{labelReplacement}
                                        </div>
                                    }
                                </div>

                                <div className="col-md-12 pt-1">

                                    <div className="pt-3 text-center">
                                        <span className="apply-btn"><b>Service H+</b></span> <b>(GRATUIT)</b>
                                    </div>

                                    <div className="row">
                                        <div className="pt-3 col-md-4 col-12">
                                            <div className="row">
                                                <div className="col-md-12 col-4">
                                                    <img src={ProfileImages.getSrc(10)} className="d-block mx-auto mb-3" width="50" alt="agent_1" style={{ borderRadius: "100px" }} />
                                                    <p className="text-center">Stéphane</p>
                                                </div>

                                                <div className="col-md-12 col-4">
                                                <img src={ProfileImages.getSrc(124)} className="d-block mx-auto mb-3" width="50" alt="agent_2" style={{ borderRadius: "100px" }} />
                                                    <p className="text-center">Anna</p>
                                                </div>

                                                <div className="col-md-12 col-4">
                                                <img src={ProfileImages.getSrc(117)} className="d-block mx-auto mb-3" width="50" alt="agent_3" style={{ borderRadius: "100px" }} />
                                                    <p className="text-center">Eva</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-8 col-sm-12 align-self-center">
                                            <p>Nos agents HYDROGEN...</p>
                                            <ul className="ul-abonnement-medecin">
                                                <li>Recherchent activement de remplacements en libéral ou en salariat, sur vos périodes de disponibilités.</li>
                                                <li>Négocient la rétrocession en votre faveur.</li>
                                                <li>Editent vos contrats de remplacement et les adressent au conseil de l’ordre.</li>
                                                <li>Editent et conservent vos certificats de rétrocession.</li>
                                                <li>Vous proposent des formations médicales.</li>
                                                <li>Vous transmettent un fichier récapitulatif de vos bénéfices mensuels.</li>
                                                <li>Vous conseillent sur le plan administratif (inscription URSSAF, CFE, CHORUS, déclaration des frais...).</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="pt-5 text-center">
                                        <span className="apply-btn"><b>SERVICES GRATUITS</b></span>
                                        <p>
                                            Contactez-nous au 06 43 47 20 87 ou par mail à support@hydrogen-rempla.com
                                        </p>
                                    </div>

                                    <div className="row pt-4 icon-service-color">
                                        <i className="col-md-2 offset-md-1 fas fa-balance-scale fa-5x align-self-center"></i>
                                        <div className="col-md-8">
                                            <h5 className="title-service-color">L'offre équilibre</h5>
                                            <p>HYDROGEN vous trouve <b>un remplacement.</b></p>
                                            <p>En retour, vous réservez un remplacement à l'un de nos médecins.</p>
                                        </div>
                                    </div>

                                    <div className="row pt-4 icon-service-color">
                                        <i className="col-md-2 offset-md-1 fas fa-chart-line fa-5x align-self-center"></i>
                                        <div className="col-md-8">
                                            <h5 className="title-service-color">L'offre confort</h5>
                                            <p>Nous vous aidons à atteindre le chiffre d'affaire que vous souhaitez, en vous proposant régulièrement des remplacements, en cohérence avec vos critères.</p>
                                            <p>En retour, vous réservez des créneaux de disponibilités pour HYDROGEN.</p>
                                        </div>
                                    </div>

                                    <div className="row pt-4 pb-5 icon-service-color">
                                        <i className="col-md-2 offset-md-1 fas fa-user-md fa-5x align-self-center"></i>
                                        <div className="col-md-8">
                                            <h5 className="title-service-color">L'offre H+</h5>
                                            <p>Un agent HYDROGEN vous est dédié et gère entièrement votre planning selon vos critères.</p>
                                        </div>
                                    </div>

                                    <div className="row pt-3">
                                        {this.state.subscriptions_replacement === null ?
                                            <div className="col-12 pt-3 text-center">
                                                <Link to="/home/subscription/register/replacement" className="text-white border xrounded bg-green text-white p-3 text-center">
                                                    <FormattedMessage id={"I.Want.Subscription"} />
                                                </Link>
                                            </div>
                                            :
                                            <div className="col-md-4 offset-md-4 border xrounded bg-green text-white p-3 text-center">
                                                <FormattedMessage id={"Already.Subscribe"} />{labelReplacement}
                                            </div>
                                        }
                                    </div>
                                    
                                </div>
                            </div>
                        }

                        {this.state.show_succession === true && 
                            <div className="w-100">
                                <div className="row pt-3">
                                    {this.state.subscriptions_succession === null ?
                                        <div className="col-12 pt-3 pb-3 text-center">
                                            <Link to="/home/subscription/register/succession" className="text-white border xrounded bg-green text-white p-3 text-center">
                                                <FormattedMessage id={"I.Want.Subscription"} />
                                            </Link>
                                        </div>
                                        :
                                        <div className="col-md-4 offset-md-4 border xrounded bg-green text-white p-3 text-center">
                                            <FormattedMessage id={"Already.Subscribe.Offer"} />{labelSuccession}
                                        </div>
                                    }
                                </div>
                                
                                <div className="col-md-12 pt-1">

                                    <div className="pt-3 text-center">
                                        <span className="apply-btn"><b>Service Installation</b></span> <b>(GRATUIT)</b>
                                    </div>

                                    <div className="row">
                                        <div className="pt-3 col-md-4 col-12">
                                            <div className="row">
                                                <div className="col-md-12 col-4">
                                                    <img src={ProfileImages.getSrc(10)} className="d-block mx-auto mb-3" width="50" alt="agent_1" style={{ borderRadius: "100px" }} />
                                                    <p className="text-center">Stéphane</p>
                                                </div>

                                                <div className="col-md-12 col-4">
                                                <img src={ProfileImages.getSrc(124)} className="d-block mx-auto mb-3" width="50" alt="agent_2" style={{ borderRadius: "100px" }} />
                                                    <p className="text-center">Anna</p>
                                                </div>

                                                <div className="col-md-12 col-4">
                                                <img src={ProfileImages.getSrc(117)} className="d-block mx-auto mb-3" width="50" alt="agent_3" style={{ borderRadius: "100px" }} />
                                                    <p className="text-center">Eva</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-8 col-sm-12 align-self-center">
                                            <p>Nos agents HYDROGEN...</p>
                                            <ul className="ul-abonnement-medecin">
                                                <li>Réalisent un bilan de vos souhaits et besoins dans le cadre de votre installation.</li>
                                                <li>Recherchent activement des médecins qui souhaitent un successeur ou un collaborateur dans vos secteurs de recherche.</li>
                                                <li>Vous informent chaque mois, sur l’aspect quantitatif et qualitatif de nos recherches.</li>
                                                <li>Programment un ou plusieurs remplacements dans les cabinets ou les structures de soins de vos secteurs de recherche.</li>
                                                <li>Editent et conservent vos contrats de remplacements.</li>
                                                <li>Editent et conservent vos certificats de rétrocession signés.</li>
                                                <li>Vous adressent un retour détaillé des médecins que vous remplacez.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        }
                    </div>
                </div>
                }

                {this.props.user && this.props.user.role === 'doctor' &&
                    <div>
                        <div className='row'>
                            <div className='text-center col-12 order-4 col-md-4 order-md-1'>
                                <img src={HYDROGEN_ILLUSTRATIONS_MAP_REMPLACANTS_TRANSP} alt="bg-img" className="h-100" width="350px" height="350px" />
                            </div>
                            <div className='text-center col-12 order-2 col-md-4 order-md-4'>
                                <h4 onClick={this.changeCategory} id="replacement" className='selected-category text-black'><FormattedHTMLMessage id={"You.Are.Looking.For.Replacement"} /></h4>
                            </div>
                            <div className='text-center col-12 order-3 col-md-4 order-md-6'>
                                {this.state.subscriptions_replacement === null ?
                                    <div>
                                        <Link to="/home/subscription/register/replacement-doctor" className="ladda-button btn srounded pl-5 pr-5 pt-2 pb-2 text-white bg-blueish shine-button btn text-center mx-auto" id="submitSubscription">
                                            <FormattedMessage id={"I.Subscribe.To.Replacement"} />
                                        </Link>
                                    </div>
                                    :
                                    <div className="ladda-button btn srounded pl-5 pr-5 pt-2 pb-2 text-white bg-green btn text-center mx-auto" id="submitSubscription">
                                        <FormattedMessage id={"Already.Subscribe.Offer"} />{labelReplacement}
                                    </div>
                                }
                            </div>
                            <div className='text-center col-12 order-1 col-md-4 order-md-2 pt-2 pb-4'>
                                <input onClick={this.openModalCode} className="ladda-button btn srounded pl-5 pr-5 pt-2 pb-2 text-white bg-orange btn text-center mx-auto" id="submitSubscription" type="submit" value={enterCode} />
                            </div>
                            <div className='text-center col-12 order-8 col-md-4 order-md-3'>
                                <img src={HYDROGEN_ILLUSTRATIONS_KEY} alt="bg-img" className="h-100" width="350px" height="350px" />
                            </div>
                            <div className='text-center col-12 order-6 col-md-4 offset-md-4 order-md-5'>
                                <h4 onClick={this.changeCategory} id="succession" className='selected-category text-black'><FormattedMessage id={"You.Are.Looking.For.Succession"} /></h4>
                            </div>
                            <div className='text-center col-12 order-7 col-md-4 order-md-9 order-md-6 offset-md-4'>
                                {this.state.subscriptions_succession === null ?
                                    <div>
                                        <Link to="/home/subscription/register/succession-doctor" className="ladda-button btn srounded pl-5 pr-5 pt-2 pb-2 text-white bg-blueish shine-button btn text-center mx-auto">
                                            <FormattedMessage id={"I.Subscribe.To.Succession"} />
                                        </Link>
                                    </div>
                                    :
                                    <div className="ladda-button btn srounded pl-5 pr-5 pt-2 pb-2 text-white bg-green btn text-center mx-auto">
                                        <FormattedMessage id={"Already.Subscribe.Offer"} />{labelSuccession}
                                    </div>
                                }
                            </div>
                            <div className='text-center text-white col-12 order-5 col-md-4 order-md-10'>
                                <div className="pt-3 text-center">
                                    <span className="apply-btn"><b>Cabinet individuel : 149&euro;/an</b></span><br />
                                    <span className="apply-btn"><b>Cabinet de groupe (2 ou +) : 249&euro;/an</b></span><br />
                                    <span className="apply-btn"><b>+39&euro;/semaine de remplacement</b></span><br />
                                </div>
                            </div>
                            <div className='text-center text-white col-12 order-9 col-md-4 offset-md-4 order-md-11'>
                                <div className="pt-3 text-center">
                                    <span className="apply-btn"><b>Cabinet individuel : 249&euro;/an</b></span><br />
                                    <span className="apply-btn"><b>Cabinet de groupe (2 ou +) : 349&euro;/an</b></span><br />
                                    <span className="apply-btn"><b>+39&euro;/semaine de remplacement</b></span><br />
                                    <span className="apply-btn"><b>+349&euro; en cas de réussite **</b></span><br />
                                </div>
                            </div>
                        </div>
                    
                        {this.state.show_replacement === true &&
                            <div className='text-white fs-16 bolder'>
                                <div className="pt-3 text-center">
                                    <span className="apply-btn"><b>Découvrez notre équipe HYDROGEN</b></span>
                                </div>
                                <div className='row text-center justify-content-center mt-3'>
                                    <div className="col-sm-4 col-md col-6">
                                        <img src={ProfileImages.getSrc(10)} className="d-block mx-auto mb-3" width="200" alt="agent_1" style={{ borderRadius: "100px" }} />
                                        <p className="text-center">Stéphane</p>
                                    </div>
                                    <div className="col-sm-4 col-md col-6">
                                        <img src={ProfileImages.getSrc(124)} className="d-block mx-auto mb-3" width="200" alt="agent_2" style={{ borderRadius: "100px" }} />
                                        <p className="text-center">Camille</p>
                                    </div>
                                    <div className="col-sm-6 col-md col-6">
                                        <img src={ProfileImages.getSrc(117)} className="d-block mx-auto mb-3" width="200" alt="agent_4" style={{ borderRadius: "100px" }} />
                                        <p className="text-center">Roxane</p>
                                    </div>
                                    <div className="col-sm-4 col-md col-6">
                                        <img src={ProfileImages.getSrc(122)} className="d-block mx-auto mb-3" width="200" alt="agent_3" style={{ borderRadius: "100px" }} />
                                        <p className="text-center">Cheryl</p>
                                    </div>
                                    <div className="col-sm-6 col-md col-6">
                                        <img src={ProfileImages.getSrc(119)} className="d-block mx-auto mb-3" width="200" alt="agent_5" style={{ borderRadius: "100px" }} />
                                        <p className="text-center">Margot</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 offset-md-2 col-sm-12 mt-3 text-white">
                                        <p className='text-center'>Durant 1 an nos agents HYDROGEN...</p>
                                        <ul className="">
                                            <li>Réalisent un bilan complet de votre cabinet et de son implantation.</li>
                                            <li>Recherchent activement des médecins qui souhaitent s'installer dans votre secteur.</li>
                                            <li>Vous informent chaque mois, sur l'aspect quantitatif et qualitatif de nos recherches.</li>
                                            <li>Programment plusieurs remplacements dans votre cabinet.</li>
                                            <li>Editent vos contrats de remplacement et les adressent au conseil de l'ordre.</li>
                                            <li>Editent et conservent vos certificats de rétrocession.</li>
                                            <li>Vous adressent un retour détaillé des médecins qui ont remplacé dans votre cabinet.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }

                        {this.state.show_succession === true &&
                            <div className='text-white fs-16 bolder'>
                                <div className="pt-3 text-center">
                                    <span className="apply-btn"><b>Découvrez notre équipe HYDROGEN</b></span>
                                </div>
                                <div className='row text-center justify-content-center mt-3'>
                                    <div className="col-sm-4 col-md col-6">
                                        <img src={ProfileImages.getSrc(10)} className="d-block mx-auto mb-3" width="200" alt="agent_1" style={{ borderRadius: "100px" }} />
                                        <p className="text-center">Stéphane</p>
                                    </div>
                                    <div className="col-sm-4 col-md col-6">
                                        <img src={ProfileImages.getSrc(124)} className="d-block mx-auto mb-3" width="200" alt="agent_2" style={{ borderRadius: "100px" }} />
                                        <p className="text-center">Camille</p>
                                    </div>
                                    <div className="col-sm-6 col-md col-6">
                                        <img src={ProfileImages.getSrc(117)} className="d-block mx-auto mb-3" width="200" alt="agent_4" style={{ borderRadius: "100px" }} />
                                        <p className="text-center">Roxane</p>
                                    </div>
                                    <div className="col-sm-4 col-md col-6">
                                        <img src={ProfileImages.getSrc(122)} className="d-block mx-auto mb-3" width="200" alt="agent_3" style={{ borderRadius: "100px" }} />
                                        <p className="text-center">Cheryl</p>
                                    </div>
                                    <div className="col-sm-6 col-md col-6">
                                        <img src={ProfileImages.getSrc(119)} className="d-block mx-auto mb-3" width="200" alt="agent_5" style={{ borderRadius: "100px" }} />
                                        <p className="text-center">Margot</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 offset-md-3 col-sm-12 align-self-center mt-3 text-white">
                                        <p>Durant 1 an nos agents HYDROGEN...</p>
                                        <ul className="ul-abonnement-medecin">
                                            <li>Réalisent un bilan complet de votre cabinet et de son implantation.</li>
                                            <li>Recherchent activement des médecins qui souhaitent s'installer dans votre secteur.</li>
                                            <li>Vous informent chaque mois, sur l'aspect quantitatif et qualitatif de nos recherches.</li>
                                            <li>Programment plusieurs remplacements dans votre cabinet.</li>
                                            <li>Editent vos contrats de remplacement et les adressent au conseil de l'ordre.</li>
                                            <li>Editent et conservent vos certificats de rétrocession.</li>
                                            <li>Vous adressent un retour détaillé des médecins qui ont remplacé dans votre cabinet.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {this.state.modal}
            </div>
		);
	}
}


const mapStateToProps = state => {
	return {
        user: state.global.user,
        subscriptions: state.global.subscriptions
	}
}

const mapDispatchToProps = dispatch => {
	return {
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HomeSubscription));