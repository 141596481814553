import React, { Component } from 'react';
import PIProfileForm from "./forms/PIProfileForm";
import { Row, Col } from 'reactstrap';

export default class PIStep2 extends Component {

	constructor(props) {
		super(props);

		this.form = React.createRef();
	}

	isValidated() {
		const updateData = (data) => {
			this.props.updateStore({psychologistICharacteristic: data });
		}
		return this.form.current.validate(updateData);
	}

	back(data) {
		this.props.updateStore({ psychologistICharacteristic: data });
		this.props.jumpToStep(0);
	};

	render() {
		// Hide or show inputs based on previously entered data
		const defaultValues = this.props.getStore().psychologistICharacteristic;

		var submitCbk = (data) => {
			this.props.updateStore({ psychologistICharacteristic: data });

			this.props.jumpToStep(2);

			if (window.scrollTo) window.scrollTo(0, 0);
		};

		return (
			<React.Fragment>

				<Row className="step step1 mt-2 justify-content-md-center ">
					<Col lg="8">

						<PIProfileForm
							defaultValues={defaultValues}
							submitCbk={submitCbk}
							validate={this.props.validate}
							submitButtoni18nId={"Next.Step"}
							onBackCbk={(data)=> this.back(data)}
							ref={this.form} />

					</Col>
				</Row>

			</React.Fragment>
		)
	}
}