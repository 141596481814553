var values = [
  {label: "Zero.Percent", value: "0"},
  {label: "Five.Percent", value: "5"},
  {label: "Ten.Percent", value: "10"},
  {label: "Fifteen.Percent", value: "15"},
  {label: "Twenty.Percent", value: "20"},
  {label: "TwentyFive.Percent", value: "25"},
  {label: "Thirty.Percent", value: "30"},
  {label: "ThirtyFive.Percent", value: "35"},
  {label: "Fourty.Percent", value: "40"},
  {label: "FourtyFive.Percent", value: "45"},
  {label: "Fifty.Percent", value: "50"},
  {label: "FiftyFive.Percent", value: "55"},
  {label: "Sixty.Percent", value: "60"},
  {label: "SixtyFive.Percent", value: "65"},
  {label: "Seventy.Percent", value: "70"},
  {label: "SeventyFive.Percent", value: "75"},
  {label: "Eighty.Percent", value: "80"},
  {label: "EightyFive.Percent", value: "85"},
  {label: "Ninety.Percent", value: "90"},
  {label: "NinetyFive.Percent", value: "95"},
  {label: "Hundred.Percent", value: "100"}
  
];

  export default class Geriatrics {
  
    static values() {
      return values;
    }
  
    static getLabel(nbr) {
      for (let value of values) {
        if (value.value === nbr) return value.label;
      }
    }
  
  }