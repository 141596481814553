import React from 'react';
import { Row, Col } from "reactstrap";
import Maths from '../../../../util/Maths';
import DateUtil from '../../../../util/DateUtil';

const AccountingDailyTableRow = ({ accounting, openModal, deleteAccoutingLine, loading }) => {

    const { date, patient, payment_methods, credit_card, cheque, cash, payed_by_third_party, secu, diagnosis, retrocessionAmount } = accounting;

    const pickSecu = () => {
        if (payment_methods === "secu") return secu;
        if (payed_by_third_party) return payed_by_third_party;
        else return '-';
    }
    
    const parseDate = (date) => {
        // eslint-disable-next-line
        const regex = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/
        if (regex.test(date)) return DateUtil.dateToString(date)
        else return date;
    }

    return (
        <>
        <Row className="tableRow pt-0 m-0 pl-5">
            <Col className="text-left h-45px lh-45 pl-3">{parseDate(date)}</Col>
            <Col className="text-left h-45px lh-45">{patient} <span className="diagnosis">{diagnosis}</span></Col>
            <Col className="text-left h-45px lh-45">{credit_card ? credit_card : '-'}</Col>
            <Col className="text-left h-45px lh-45">{cheque ? cheque : '-'}</Col>
            <Col className="text-left h-45px lh-45">{cash ? cash : '-'}</Col>
            <Col className="text-left h-45px lh-45">{pickSecu()}</Col>
            <Col className="text-left h-45px lh-45">{retrocessionAmount ? Maths.round(retrocessionAmount) : '-'}</Col>
            <Col className="text-right h-45px lh-45">
                <i className="fas fa-pencil-alt clickable mr-5" onClick={() => openModal(accounting)}></i>
                <i className="fas fa-times clickable mr-5" onClick={(e) => deleteAccoutingLine(e, accounting)}></i>
            </Col>
        </Row>
        </>
    );
}

export default AccountingDailyTableRow;