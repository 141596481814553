import APIUrl from "../APIUrl";
import axios from 'axios';

export const availabilityOverlaps = function(start, end, type, days_available, callback){
    return axios.post(APIUrl.checkAvailabilityOverlap, { start, end, type, days_available })
        .then(function(response){
            if(callback) callback(response.data.message);
        })
        .catch(function(err){
            throw err;
        });
};