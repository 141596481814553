import React from 'react';
import { connect } from 'react-redux';
import { removes } from "../../actions/global/global";


class Notifications extends React.Component {

    deleteNotif(e, notif) {
        e.preventDefault();
        e.stopPropagation();

        this.props.onRemoves("notification", notif);
    }

    getLink(n) {
        if (this.props.user.role === "doctor") {
            if (n.category === "AGREEMENTS") return "#/home/me/replacements/agreements";
            else if (n.category === "CONCLUDED_AGREEMENTS") return "#/home/me/replacements/agreements";
            else if (n.category === "REPLACEMENTS") return "#/home/me/replacements";
            else if (n.category === "INVITATION") return "#/home/me/replacements/private";
            else return "#/home";
        } else {
            if (n.category === "AGREEMENTS") return "#/home/replacements/agreements";
            else if (n.category === "CONCLUDED_AGREEMENTS") return "#/home/replacements/agreements";
            else if (n.category === "REPLACEMENTS") return "#/home/replacements/agreements";
            else if (n.category === "INVITATION") return "#/home/replacements/private";
            else if (n.category === "SUBSCRIPTION") return "#/home/subscription";
            else return "#/home";
        }
    }

    render() {
        const { user, notifications } = this.props;

        if (!user) return null;

        return (
            <>
                {notifications.map(n => {
                    return (
                        <React.Fragment key={Math.random()}>
                            <a href={this.getLink(n)} onClick={this.props.toggle}>
                                <div className="text-dark min-w-400px text-left notif-item pl-4 pt-3 pb-3">
                                    <div className="d-inline-block align-middle w-90">{n.content}</div>
                                    <div className="d-inline-block align-middle ml-auto w-10"
                                        onClick={(e) => this.deleteNotif(e, n)}><i className="fa fa-close clickable" /></div>
                                </div>
                            </a>
                        </React.Fragment>
                    );
                })}
            </>
        );
    };
};

const mapStateToProps = state => {
    return {
        user: state.global.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onRemoves: (objName, obj) => dispatch(removes(objName, obj))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);