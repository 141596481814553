import React, { Component } from 'react';
import ImgsModal from './ImgsModal';
import Image from './Image';
import ProfileImages from '../../enum/ProfileImages';

class ImagePicker extends Component {

    constructor(props) {
        super(props)

        this.state = {
            modal: null
        };

        this.props.setOpenCbk(() => this.openImgsModal());
    }

    openImgsModal(e) {
        if(e){
            e.preventDefault();
            e.stopPropagation();
        } 

        const { images, defaultValue } = this.props;

        this.setState({
            modal: <ImgsModal
                images={images}
                onClose={() => this.onCloseModal()}
                onPick={(image) => this.props.onPick(image)}
                defaultValue={defaultValue} />
        });
    }

    onCloseModal() {
        this.setState({ modal: null });
    }

    render() {
        const { images, defaultValue } = this.props;

        return (
            <>
                <div className="image_picker" onClick={(e) => this.openImgsModal(e, images)}>
                    <Image
                        src={ProfileImages.getSrc(defaultValue)}
                    />
                </div>

                {this.state.modal}
            </>
        )
    }
}


export default ImagePicker;