import React from 'react';
import { connect } from 'react-redux';
import { Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import Duration from '../../../enum/Duration';
import { allBySubWithDate } from '../../../actions/agreement/agreement';
import { addAppointment, getAppointmentsByUser } from '../../../actions/appointment/appointment';
import { Calendar, momentLocalizer } from "react-big-calendar";
import AppointmentUtil from '../../../util/AppointmentUtil';
import Util from '../../../util/Util';
import ProfileImages from "../../../enum/ProfileImages";
import moment from 'moment';

const localizer = momentLocalizer(moment);

class SModalNewAppointment extends React.Component {

    constructor(props) {
        super(props);

        moment.locale('fr')

        this.state = {
            appointment_date: moment(this.props.dateNewAppointmentSelected.start).format('YYYY-MM-DD'),
            appointment_hour: moment(this.props.dateNewAppointmentSelected.start).format('HH:mm'),
            gender: '',
            name: '',
            first_name: '',
            birth_name: '',
            showBirthName: false,
            birth_date: '',
            phone_mobile: '',
            phone: '',
            email: '',
            comments: '',
            duration: props.substituteCharacteristic.duration ? props.substituteCharacteristic.duration : "15",
            min: new Date(0, 0, 0, 7, 0, 0),
            max: new Date(0, 0, 0, 20, 0, 0),
            current_week: this.props.startWeekDate,
            appointmentsEvents: this.props.appointmentsEvents,
            users: [],
            user: '',
            error: '',
            modalValidation: false,
            userSelect: '',
            arrayOfficeHours: this.props.arrayOfficeHours,
            arrayAgreements: this.props.arrayAgreements
        };

        this.handleChange = this.handleChange.bind(this);
        this.onSubmitValidation = this.onSubmitValidation.bind(this);
        this.loadUser = this.loadUser.bind(this);
        this.dayEventCustom = this.dayEventCustom.bind(this);
        this.closeModalValidation = this.closeModalValidation.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    };

    componentDidMount() {
        this.loadUser(moment(), false);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.agreements !== this.props.agreements) {
            this.officeHours(this.state.appointment_date);

            const arrayAgreements = this.formatAgreements(this.props.agreements);

            this.setState({
                arrayAgreements
            })
        }
    }

    officeHours(date) {
        let arrayOfficeHours = AppointmentUtil.officeHours(date, this.props.user.role, undefined, this.props.agreements);

        this.setState({
            arrayOfficeHours: arrayOfficeHours
        })
    }

    loadUser(date, loadAppointments) {
        let arrayUsers = [{
            id: this.props.user._id,
            name: this.props.user.name,
            first_name: this.props.user.first_name
        }];

        let dateOfTheDay = moment();

        if (this.props.agreements && this.props.agreements.length > 0) {
            for (let agreement of this.props.agreements) {
                if (dateOfTheDay.isBetween(agreement.replacement.startDate, agreement.replacement.endDate, 'day', [])) {
                    arrayUsers.push({
                        id: agreement.doctor._id,
                        name: agreement.doctor.name,
                        first_name: agreement.doctor.first_name
                    })
                    break;
                }
            }
        }

        if (loadAppointments === true) {
            let arrayUsersId = [this.props.user._id];
            if (arrayUsers.length > 0) {
                arrayUsers.forEach((user) => {
                    arrayUsersId.push(user.id);
                })
            }
            
            let data = {
                arrayUsers: arrayUsersId,
                startDate: moment(date).startOf('week').format('YYYY-MM-DD'),
                endDate: moment(date).endOf('week').subtract(1, 'day').format('YYYY-MM-DD')
            }

            this.props.getAppointmentsByUser(data);
        }
        
        this.setState({
            users: arrayUsers,
            user: arrayUsers[0].id,
            userSelect: arrayUsers[0].name + ' ' + arrayUsers[0].first_name
        })
    }

    formatAgreements(agreements) {
        var arrayAgreements = [];

        let startDate = moment(this.state.appointment_date).startOf('week').format('YYYY-MM-DD');
        let endDate = moment(this.state.appointment_date).endOf('week').subtract(1, 'day').format('YYYY-MM-DD')
        
        for (let agreement of agreements) {
            let nextRound = false;
            if (moment(agreement.replacement.end_date).isAfter(startDate) && moment(agreement.replacement.start_date).isBefore(endDate)) {
                nextRound = true;
            }
            if (nextRound === false) {
                continue;
            }
            let clonedReplacement = Util.clone(agreement);

            clonedReplacement._type = "Replacement";
            clonedReplacement.start = clonedReplacement.start_date;
            clonedReplacement.end = clonedReplacement.end_date;

            let events = [];

            agreement.replacement.days_available.forEach((elem) => {
                let event = {};
                clonedReplacement.title = (
                    <Col sm="12" className="lp-1">
                        <img src={ProfileImages.getSrc(agreement.doctor.image)}
                            className="d-block mx-auto mb-3" width="50" alt={agreement.doctor.name}>
                        </img>
                        {agreement.doctor.first_name + " " + agreement.doctor.name} <br></br>
                        {moment(elem.start).format('HH:mm')} - {moment(elem.end).format('HH:mm')}
                    </Col>
                );

                event._type = "Replacement";
                event.allDay = true
                event.title = clonedReplacement.title;
                event.color = clonedReplacement.type;
                event.start = new Date(elem.start);
                event.end = new Date(elem.end);
                event.original = clonedReplacement;
                event.css = 'replacement';

                events.push(event);
            })

            arrayAgreements = arrayAgreements.concat(events);
        }
        return arrayAgreements;
    };

    handleChange(event) {
        let { name, value } = event.currentTarget;

        if (name === 'appointment_date') {
            // Bloquer la pose de rdv dans le passé
            if (moment(value).isBefore(moment(), 'day')) {
                this.setState({
                    error: 'Vous ne pouvez pas poser de rdv dans le passé'
                })
                return;
            }

            let loadAppointments = false;
            // si la semaine change, besoin de charger les nouveaux rdv patients
            if (!moment(value).startOf('week').isSame(this.state.current_week, 'week')) {
                this.props.allBySubWithDate(moment(value).startOf('week').format('YYYY-MM-DD'), moment(value).endOf('week').subtract(1, 'day').format('YYYY-MM-DD'));
                loadAppointments = true;
                this.setState({
                    current_week: moment(value).startOf('week')
                })
            }
            this.loadUser(value, loadAppointments);
        }

        if (name === 'man' || name === 'woman') {
            this.setState({
                gender: name,
                showBirthName: name === 'woman' ? true : false
            })
        }

        if (name === 'user') {
            let user = this.state.users.find((user) => {
                return user.id === value;
            })

            this.setState({
                userSelect: user.name + ' ' + user.first_name
            })
            
        }
        
        this.setState({
            [name]: value,
            error: ''
        })
    }

    onSubmitValidation() {
        if (this.state.gender === '') {
            this.setState({
                error: 'Vous devez renseigner le genre'
            })
            return;
        }

        if (this.state.name === '' || this.state.first_name === '') {
            this.setState({
                error: 'Vous devez renseigner le nom et prénom du patient'
            })
            return;
        }

        this.setState({
            modalValidation: true
        })
    }

    onSubmit() {
        let appointment_date = moment(this.state.appointment_date);
        // reconstruit la date en rajoutant l'heure
        appointment_date.set({
            hour: moment(this.state.appointment_hour, 'HH:mm').hour(),
            minute: moment(this.state.appointment_hour, 'HH:mm').minute()
        })

        let appointment = {
            appointment_date: appointment_date,
            name: this.state.name,
            first_name: this.state.first_name,
            birth_name: this.state.birth_name,
            birth_date: this.state.birth_date,
            phone_mobile: this.state.phone_mobile,
            phone: this.state.phone,
            email: this.state.email,
            comments: this.state.comments,
            duration: this.state.duration,
            user_id: this.state.user,
            gender: this.state.gender
        }

        this.props.arrayAgreements.forEach((agreement) => {
            if (moment(agreement.start).isSame(this.state.appointment_date, 'day')) {
                appointment.replacement_id = agreement.original.replacement._id;
                appointment.doctor_id =  agreement.original.doctor_id;
            }
        })

        this.props.addAppointment(appointment, this.props.user._id, this.props.close(this.state.appointment_date));
    }

    customToolBar() {
        return [];
    }

    dayEventCustom(event) {
        let css = 'pt-1 pl-1';
        let content = moment(event.event.start).format('HH:mm') + ' ' + event.title;

        if(event.event.css === 'replacement') {
            content = event.event.title;
            css = 'pt-1 bg-occasional';
        } else if (event.event.css === 'emptyAppointment') {
            content = moment(event.event.start).format('HH:mm') + ' ' + event.event.title;
        }

        return (
            <div className={'text-color-black ' + css}>
                {content}
            </div>
        )
    }

    closeModalValidation() {

        this.setState({
            modalValidation: false
        })
    }

    render() {
        const { appointmentsByUsers } = this.props;

        const appointmentsEvents = (appointmentsByUsers && appointmentsByUsers.length) ? AppointmentUtil.formatAppointments(appointmentsByUsers, this.state.user) : [];

        let concatEvents = this.state.arrayAgreements.concat(appointmentsEvents);

        return (
            <>
                <Modal size="lg" isOpen={true} toggle={() => this.props.close(this.state.appointment_date)} className="simple-modal simple-modal-white srounded modalAppointmentCustom">

                    <ModalHeader className="header-modal-choice">
                        <div className="text-left">
                            <button type="button" onClick={() => this.props.close(this.state.appointment_date)} className="close d-inline-block" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                    </ModalHeader>

                    <ModalBody className="row">
                        <Col sm="4" id="calendarModalNewAppointment">
                            <Calendar
                                components={{ 
                                    toolbar: this.customToolBar,
                                    day: {
                                        event: this.dayEventCustom,
                                    },
                                }}
                                formats={{
                                    eventTimeRangeFormat: range =>
                                    ``
                                }}
                                renderable
                                popup={true}
                                step={15}
                                slots={4}
                                min={this.state.min}
                                max={this.state.max}
                                localizer={localizer}
                                defaultDate={new Date(this.state.appointment_date)}
                                date={new Date(this.state.appointment_date)}
                                onView={{}}
                                events={concatEvents}
                                view={'day'}
                                views={[
                                    'day'
                                ]}
                                slotPropGetter={(date) => AppointmentUtil.customSlotPropGetterSub(date, this.state.arrayOfficeHours, this.props.exceptionalOpen, this.props.agreements, this.props.exceptionalClose)}
                                culture={'fr'}
                                onNavigate={[]}
                                dayLayoutAlgorithm="no-overlap"
                            />
                        </Col>

                        <Col sm="8">
                            <div className='jumbotron'>
                                <FormGroup row className="justify-content-center pt-2">
                                    <Label lg={2}>Médecin</Label>
                                    <Col lg={6}>
                                        <Input type="select" name="user" value={this.state.user} onChange={this.handleChange}>
                                            {this.state.users && this.state.users.map((user, key) =>
                                                <option key={key} value={user.id}>{user.name} {user.first_name}</option>
                                            )}
                                        </Input>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="justify-content-center pt-2">
                                    <Label lg={2}>Date du rdv</Label>
                                    <Col lg={3}>
                                        <Input type="date" name="appointment_date" value={this.state.appointment_date} onChange={this.handleChange} />
                                    </Col>
                                    <Col lg={3}>
                                        <Input type="time" name="appointment_hour" value={this.state.appointment_hour} onChange={this.handleChange} />
                                    </Col>
                                    {this.state.error && 
                                        <div className='text-danger'>
                                            {this.state.error}
                                        </div>
                                    }
                                </FormGroup>
                                <FormGroup row className="justify-content-center pt-2">
                                    <Label lg={2}>Durée</Label>
                                    <Col lg={6}>
                                        <Input type="select" name="duration" value={this.state.duration} onChange={this.handleChange}>
                                            {Duration.values().map((elem, key) => 
                                                <option key={key} value={elem.value}>{elem.label}</option>
                                            )}
                                        </Input>
                                    </Col>
                                </FormGroup>
                            </div>

                            <div className='jumbotron'>
                                <FormGroup row className="justify-content-center pt-2 align-gender">
                                    <Label lg={2}>Genre</Label>
                                    <Col lg={3}>
                                        <span className='pr-4' htmlFor="woman">
                                            Femme
                                        </span>
                                        <Input type="radio" id='woman' name="woman" value={this.state.gender} onChange={this.handleChange} required checked={this.state.gender === 'woman'}/>
                                    </Col>
                                    <Col lg={3}>
                                        <span className='pr-4' htmlFor="man">
                                            Homme
                                        </span>
                                        <Input type="radio" id="man" name="man" value={this.state.gender} onChange={this.handleChange} required checked={this.state.gender === 'man'}/>
                                    </Col>
                                </FormGroup>
                                <div className='row'>
                                    <div className='col-6'>
                                        <FormGroup row className="justify-content-center pt-2">
                                            <Label lg={3}>Nom</Label>
                                            <Col lg={8}>
                                                <Input type="text" name="name" value={this.state.name} onChange={this.handleChange} required />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row className="justify-content-center pt-2">
                                            <Label lg={3}>Prénom</Label>
                                            <Col lg={8}>
                                                <Input type="text" name="first_name" value={this.state.first_name} onChange={this.handleChange} required />
                                            </Col>
                                        </FormGroup>
                                        {this.state.showBirthName === true && 
                                            <FormGroup row className="justify-content-center pt-2">
                                                <Label lg={3}>Nom de naissance</Label>
                                                <Col lg={8}>
                                                    <Input type="text" name="birth_name" value={this.state.birth_name} onChange={this.handleChange} />
                                                </Col>
                                            </FormGroup>
                                        }
                                        <FormGroup row className="justify-content-center pt-2">
                                            <Label lg={3}>Date de naissance</Label>
                                            <Col lg={8}>
                                                <Input type="date" name="birth_date" value={this.state.birth_date} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                    </div>

                                    <div className='col-6'>
                                        <FormGroup row className="justify-content-center pt-2">
                                            <Label lg={3}>Mobile</Label>
                                            <Col lg={8}>
                                                <Input type="tel" name="phone_mobile" value={this.state.phone_mobile} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row className="justify-content-center pt-2">
                                            <Label lg={3}>Fixe</Label>
                                            <Col lg={8}>
                                                <Input type="tel" name="phone" value={this.state.phone} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row className="justify-content-center pt-2">
                                            <Label lg={3}>Email</Label>
                                            <Col lg={8}>
                                                <Input type="email" name="email" value={this.state.email} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>

                            <div className='jumbotron'>
                                <FormGroup row className="justify-content-center pt-2">
                                    <Label className='d-flex align-items-center justify-content-end' lg={2}>Notes</Label>
                                    <Col lg={10}>
                                        <Input type="textarea" name="comments" value={this.state.comments} onChange={this.handleChange} rows="4"/>
                                    </Col>
                                </FormGroup>
                            </div>


                            <Button color="secondary" onClick={() => this.props.close(this.state.appointment_date)}>Retour</Button>
                            <Button color="success" onClick={this.onSubmitValidation}>Enregistrer</Button>
                        </Col>
                    </ModalBody>
                </Modal>

                {this.state.modalValidation === true && 
                    <Modal size="lg" isOpen={true} toggle={this.closeModalValidation} className="simple-modal simple-modal-white srounded modalCustom">

                        <ModalHeader className="header-modal-choice">
                            <div className="text-left">
                                <button type="button" onClick={this.closeModalValidation} className="close d-inline-block" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div>
                                Validation
                            </div>
                        </ModalHeader>

                        <ModalBody className="row">
                            <Col sm="12">
                                Vous allez ajouter un rendez-vous patient pour <b> {this.state.userSelect} </b>
                            </Col>

                        </ModalBody>

                        <ModalFooter className="justify-content-center">
                            <Button color="secondary" onClick={this.closeModalValidation}>Retour</Button>
                            <Button color="success" onClick={this.onSubmit}>Enregistrer</Button>
                        </ModalFooter>
                    </Modal>
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
        appointmentsByUsers: state.appointments.appointmentsByUsers,
        substituteCharacteristic: state.global.substituteCharacteristic
    }
};

const mapDispatchToProps = dispatch => {
    return {
		addAppointment: (appointment, userId) => dispatch(addAppointment(appointment, userId)),
        allBySubWithDate: (startDate, endDate) => dispatch(allBySubWithDate(startDate, endDate)),
        getAppointmentsByUser: (data) => dispatch(getAppointmentsByUser(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SModalNewAppointment);