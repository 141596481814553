import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Form } from '@gferrand/react-forms';
import { upd } from "../../../actions/global/global";
import CheckboxInput from '../../../components/form/CheckBoxInput';
import ConfModal from '../../../components/modal/ConfModal';
import { remove } from '../../../actions/global/global';
import { logout } from '../../../actions/authentication/authentication';
import VersionFooter from '../../../components/footer/VersionFooter';
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";


class GDPRSettings extends Component {

	constructor(props) {
		super(props);

		const { user } = this.props;

		this.state = {
			modal: null,
		};

		const submitCbk = (data, cbk) => {
			var prefs = {
					accept_email_notifications: (!!data.accept_email_notifications),
					accept_contacted: (!!data.accept_contacted),
					accept_contacted_clinicaltrials: (!!data.accept_contacted_clinicaltrials)
				};

			this.props.onUpd("user/prefs", prefs, () => {
				if (cbk) cbk();

				new Noty({
					type: "info",
					layout: 'topRight',
					theme: 'bootstrap-v4',
					text: this.props.intl.formatMessage({ id: "Profile.Modified.Success" }),
					timeout: 6000,
				}).show();
			});
		};

		this.form = new Form({
			name: "user",
			inputs: [
				new CheckboxInput("accept_email_notifications", "I.Want.To.Be.Notified.By.Mail", [], { lClassName: "control-checkbox" }),
				new CheckboxInput("accept_contacted", "Assert.Contact.Condition", [], { lClassName: "control-checkbox" }),
				new CheckboxInput("accept_contacted_clinicaltrials", "Assert.Contact.ClinicalTrials.Condition", [], { lClassName: "control-checkbox" })
			],
			submitCbk,
			data: (user) ? user : undefined
		});
	};

	row(form, inputName) {
		return (
			<Row className="form-group">
				<Col md="1" className="cond-form">{form.getInput(inputName)}</Col>
				<Col md="11" className="cond-form">{form.getLabel(inputName)}</Col>
			</Row>
		);
	};

	deleteAccount(e) {
		e.preventDefault();
		e.stopPropagation();

		const { user } = this.props;

		const content = (
			<>
				<h5><FormattedMessage id="Warning" /></h5>
				<FormattedMessage id="Delete.Account.Warning.Message" />
			</>
		);

		const onConfirm = () => {
			// Redirect to homepage
			const redirect = () => window.location.href = "/";

			// Redirect user to homepage
			const logout = () => {
				// Logout
				this.props.onLogout(redirect);
			}

			this.props.onRemove("user", user, logout);
		};

		this.setState({
			modal: <ConfModal
				content={content}
				onConfirm={onConfirm}
				onCancel={() => this.closeModal()}
				toggle={() => this.closeModal()} />
		});
	}

	closeModal() {
		this.setState({ modal: null });
	}

	render() {
		const { user } = this.props;

		return (
			<React.Fragment>

				<Row className="step step1 mb-4 justify-content-md-center ">
					<Col>
						<Card className="rounded-0 pb-0">
							<CardBody className="pt-5 pl-5 pr-5">

								<h5 className="text-center uppercase mb-4"><FormattedMessage id="GDPR" /></h5>

								{this.row(this.form, "accept_email_notifications")}
								{(user.role !== 'psychologist_install' && user.role !== 'pediatrician_install' && user.role !== 'psychiatrist_install')  && 
									this.row(this.form, "accept_contacted")
								}

								{this.row(this.form, "accept_contacted_clinicaltrials")}
								


								<Row className="mt-3 mb-5">

									<div className="mx-auto form-buttons">
										{this.form.submitButton("Update", { className: "srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green" })}
									</div>

								</Row>

								<hr />

								<h5 className="text-center uppercase mt-4"><FormattedMessage id="Account" /></h5>

								<div className="w-100 text-center mt-5 mb-5">

									<div className="srounded pl-5 pr-5 pt-2 pb-2 text-white bg-red btn mx-auto"
										onClick={(e) => this.deleteAccount(e)}>
										<FormattedMessage id="Delete.My.Account" />
									</div>

								</div>

								<div className="mt-5 mb-5">&nbsp;</div>

								<div className="mt-5">
									<VersionFooter />
								</div>

							</CardBody>
						</Card>
					</Col>
				</Row>

				{this.state.modal}

			</React.Fragment>
		);
	};
};

const mapStateToProps = state => {
	return {
		user: state.global.user
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onUpd: (objName, obj, cbk) => dispatch(upd(objName, obj, cbk)),
		onRemove: (objName, obj, successCbk) => dispatch(remove(objName, obj, successCbk)),
		onLogout: (cbk) => dispatch(logout(cbk))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GDPRSettings));