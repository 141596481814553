import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import 'react-datetime/css/react-datetime.css';
import { Col, Card, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Calendar, momentLocalizer } from "react-big-calendar";
import { getsBy, clears } from '../../../actions/global/global';
import DateUtil from '../../../util/DateUtil';
import SCalendarAvailabilityModal from './SCalendarAvailabilityModal';
import Util from '../../../util/Util';
import moment from 'moment';
import DoctorProfileModal from '../../doctors/profile/DoctorProfileModal';
import AddCalendarEventModal from './AddCalendarEventModal';
import SCalendarEventModal from './SCalendarEventModal';
import ChoiceModal from './ChoiceModal';
import Year from '../../calendar/Year';

const localizer = momentLocalizer(moment);

class SCalendar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            modal: null,
            view: this.props.view ? this.props.view : "month",
            date: new Date(),
            first_date_of_month: moment().startOf('month'),
            last_month_load: '',
            showFilter: false,
            showAll: true,
            hideAll: false,
            showAgreements: true,
            showEvents: true,
            showAvailability: true,
            showGuard: true
        };

        this.startDate = DateUtil.decrementDays(DateUtil.startOfMonth(new Date()), 7);
        this.endDate = moment().add(1, 'year').format('YYYY-MM-DD') 
		this.customToolBar = this.customToolBar.bind(this);
		this.closeModal = this.closeModal.bind(this);
        this.eventColors = this.eventColors.bind(this);
        this.openAvailabilityModal = this.openAvailabilityModal.bind(this);
        this.onDrillDown = this.onDrillDown.bind(this);
        this.onNavigate = this.onNavigate.bind(this);
        this.eventModal = this.eventModal.bind(this);
        this.onCancel = this.onCancel.bind(this);
    };

    componentDidMount() {
        if(window.location.href.indexOf("/add") !== -1) this.openAvailabilityModal(new Date(), new Date());
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.modal !== this.state.modal && this.state.modal === null) {
            this.props.onGetsBy("replacement", ["start_date", "end_date"], [this.startDate, this.endDate]);
            // Nécessaire si on coche la case Je suis aussi disponible pour des gardes
            this.props.onGetsBy("availability", ["start_date", "end_date"], [this.startDate, this.endDate]);
        }
    }

    closeModal() {
        this.setState({ modal: null });
    }

    onRangeChange = (data) => {
        if (data.length === 7) {
            this.setState({ view: "week" });
        } else if (data.start && data.end) {
            this.setState({ view: "month" });
        } else {
            this.setState({ view: "year" });
        }
    };

    onNavigate(date) {
        this.setState({ date, first_date_of_month: moment(date).startOf('month') });
    }

    fetchData(viewLoading) {
        let startDate = this.startDate;
        let endDate = this.endDate;

        if (moment(this.state.last_month_load).isSameOrBefore(startDate, 'day')) return;
        if (!viewLoading) {
            this.setState({ loading: true });
        }
        let availability = new Promise((resolve, reject) => {
            this.props.onGetsBy("availability", ["start_date", "end_date"], [startDate, endDate],
            () => resolve())
        })
        let replacement = new Promise((resolve, reject) => {
            this.props.onGetsBy("replacement", ["start_date", "end_date"], [startDate, endDate],
            () => resolve())
        })
        Promise.all([availability, replacement])
        .then(() => {
            this.setState({ loading: false });
        })
    }

    // Quand le user clique sur la date en chiffre du calendrier
    onDrillDown(startDate) {
        this.openAvailabilityModal({start: startDate, end: startDate});
    }

    // Ouvre une modal en cliquant sur une date dans le calendrier
    openAvailabilityModal = (period) => {
        let startDate = period && period.start;
        let endDate = period && period.end;

        let openBy = false;
        let monthSelected = moment(this.state.date).startOf('day').toDate();
        // Si aucune date est défini
        if (moment.isDate(startDate) === false) {
            openBy = true;
        }
        if(!this.props.substituteCharacteristic) return;
        if (this.state.modal) {
            this.setState({
                modal: null
            });
        } else {
            var mergeEvents = [];
            if (this.props.availabilitys && this.props.replacements) {
                let replacements = [];
                let substitute_id = this.props.substituteCharacteristic.substitute_id
                this.props.replacements.forEach((replacement) => {
                    // Ajoute que les rempla signé par les deux médecins
                    if (replacement.agreements && replacement.agreements.length > 0) {
                        replacement.agreements.forEach((elem) => {
                            if (elem.doctor_initials && elem.doctor_signature && elem.substitute_initials && elem.substitute_signature) {
                                let nego = {};
                                replacement.sign = true;
                                replacement.applicants.forEach((elem) => {
                                    if (elem.substitute_id === substitute_id && elem.negociation && elem.negociation.length > 0) {
                                        nego = elem.negociation[elem.negociation.length - 1];
                                        nego.guards = nego.guards.filter((elem) => {
                                            return elem.accepted === true;
                                        })
                                    } 
                                })
                                replacement = Object.assign({}, replacement, nego)
                                replacements.push(replacement);
                            }
                        })
                    }
                })
                mergeEvents = this.props.availabilitys.concat(replacements);
            }
            // Concat les tableaux de dispos, d'événements et les remplacements déjà signé
            this.setState({
                modal: <ChoiceModal
                    startDate={startDate}
                    endDate={endDate}
                    toggle={this.openAvailabilityModal}
                    close={this.closeModal}
                    substituteCharacteristic={this.props.substituteCharacteristic}
                    availabilitys={mergeEvents}
                    events={this.props.calendarEvents}
                    openBy={openBy}
                    monthSelected={monthSelected}
                    />
            });
        }
    };
    
    openCalendarEventModal = (startDate, endDate) => {
        if (this.state.modal) {
            this.setState({
                modal: null
            });
        } else {
            this.setState({
                modal: <AddCalendarEventModal
                    startDate={startDate}
                    endDate={endDate}
                    key={this.props.substituteCharacteristic._id}
                    toggle={this.openCalendarEventModal}
                    substituteCharacteristic={this.props.substituteCharacteristic}
                />
            });
        }
    };

    onCancel() {
        this.setState({ modal: null });
    }

    eventModal(event, onConfirm, onCancel) {

        if (event._type === "Replacement") {
            const replacement = Object.assign({}, event.original);
            delete replacement.title;
            
            this.setState({
                modal: <DoctorProfileModal
                    agreement={{ ...replacement.agreements[0], doctorTransmission: replacement.doctorTransmission}}
                    agreementReplacement={replacement}
                    doctor={replacement.doctor}
                    doctorCharacteristic={replacement.doctorCharacteristic}
                    officeTimeTable={replacement.officeTimeTable}
                    close={this.closeModal}
                    viewByCalendar={true}
                />
            });
        } else if (event._type === "Availability") {
            this.setState({
                modal: <SCalendarAvailabilityModal
                    events={event}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    onApply={this.fetchData}
                    toggle={this.onCancel} />
            });
        } else if (event._type === "CalendarEvent") {
            this.setState({
                modal: <SCalendarEventModal
                    calendarEvents={event.data}
                    onApply={this.fetchData}
                    toggle={this.onCancel} />
            });
        }
    }

    formatReplacements(replacements) {
        let substitute_id = this.props.substituteCharacteristic.substitute_id
        var arrayAgreements = [];
        var arrayGuards = [];
        let startDate = this.startDate;
        let endDate = this.endDate;
        if (this.state.view !== 'year') {
            startDate = moment(this.state.first_date_of_month).subtract(7, 'days');
            endDate = moment(this.state.first_date_of_month).add(1, 'month').add(7, 'days');
        }
        
        for (let replacement of replacements) {
            let remplaSignByTheTwo = false;
            // Le rempla doit être signé par les deux pour etre affiché
            if (replacement.agreements && replacement.agreements.length > 0) {
                replacement.agreements.forEach((elem) => {
                    if (elem.doctor_initials && elem.doctor_signature && elem.substitute_initials && elem.substitute_signature) {
                        remplaSignByTheTwo = true;
                    }
                })
            }

            if (remplaSignByTheTwo === true) {
                let nego = {};
                if (replacement.applicants && replacement.applicants.length > 0) {
                    replacement.applicants.forEach((elem) => {
                        if (elem.substitute_id === substitute_id && elem.negociation && elem.negociation.length > 0) {
                            nego = elem.negociation[elem.negociation.length - 1];
                            nego.guards = nego.guards.filter((elem) => {
                                return elem.accepted === true;
                            })
                        } 
                    })
                }
    
                replacement = Object.assign({}, replacement, nego);
                let nextRound = false;
                if (moment(replacement.end_date).isAfter(startDate) && moment(replacement.start_date).isBefore(endDate)) {
                    nextRound = true;
                }
                if (nextRound === false) {
                    continue;
                }
                let clonedReplacement = Util.clone(replacement);

                clonedReplacement._type = "Replacement";
                clonedReplacement.start = clonedReplacement.start_date;
                clonedReplacement.end = clonedReplacement.end_date;

                let bgClassName = "bg-occasional";
                if (replacement.type === "regular") bgClassName = "bg-regular";
                if (replacement.type === "guard") bgClassName = "bg-guard";

                clonedReplacement.title = (
                    <Col sm="12" className={"paddingTopCustom border-0 text-white lp-1 " + bgClassName}>
                        {replacement.doctor.first_name + " " + replacement.doctor.name}
                    </Col>
                );

                let events = [];
                replacement.days_available.forEach((elem) => {
                    let event = {};
                    event._type = "Replacement";
                    event.allDay = false
                    event.title = clonedReplacement.title;
                    event.color = clonedReplacement.type;
                    event.start = new Date(elem.start);
                    event.end = new Date(elem.end);
                    event.original = clonedReplacement;

                    events.push(event);
                })

                if (replacement.type !== 'guard') {
                    arrayAgreements = arrayAgreements.concat(events);
                } else {
                    arrayGuards = arrayGuards.concat(events);
                }
            }
        }
        return { arrayAgreements, arrayGuards };
    };
    
    // ============================================================================================
    // ==================================== AVAILABILITIES ========================================
    // ============================================================================================
    formatAvailabilitys(availabilitys, replacements) {
        var result = [];
        var arrayDates = [];  

        let startDate = this.startDate;
        let endDate = this.endDate;
        if (this.state.view !== 'year') {
            startDate = moment(this.state.first_date_of_month).subtract(7, 'days');
            endDate = moment(this.state.first_date_of_month).add(1, 'month').add(7, 'days');
        }

        let formattedAvailabilitysFilter = availabilitys.filter((elem) => {
            return moment(elem.end_date).isAfter(startDate) && moment(elem.start_date).isBefore(endDate)
        })
        for (let availability of formattedAvailabilitysFilter) {
            let arrayResult = [];
            // pour les dispo "regular"
            if (Array.isArray(availability.day)) {
                // On pousse dans le tableau les numéros des jours de la semaine qui nous intéresse
                arrayResult = availability.day.map((elem) => {
                    if (elem === 'sunday') {
                        return 0;
                    } else if (elem === 'monday') {
                        return 1;
                    } else if (elem === 'tuesday') {
                        return 2;
                    } else if (elem === 'wednesday') {
                        return 3;
                    } else if (elem === 'thursday') {
                        return 4;
                    } else if (elem === 'friday') {
                        return 5;
                    } else if (elem === 'saturday') {
                        return 6;
                    } else {
                        return 0;
                    }
                })
            }

            // Rajoute au tableau les jours entre deux dates
            availability.days_available.forEach((day) => {
                let addDate = true;
                if (replacements.length > 0) {
                    let substitute_id = this.props.substituteCharacteristic.substitute_id;
                    replacements.forEach((replacement) => {
                        let nego = {};
                        if (replacement.applicants.length > 0) {
                            replacement.applicants.forEach((elem) => {
                                if (elem.substitute_id === substitute_id && elem.negociation && elem.negociation.length > 0) {
                                    nego = elem.negociation[elem.negociation.length - 1];
                                    nego.guards = nego.guards.filter((elem) => {
                                        return elem.accepted === true;
                                    })
                                } 
                            })
                        }
                        replacement = Object.assign({}, replacement, nego);

                        replacement.days_available.forEach((elem) => {
                            // RAJOUTER GESTION JOUR REGULAR
                            if (moment(elem.start).isSame((day.start), 'day')) {
                                addDate = false;
                                return;
                            }
                        })
                    })
                }

                // Rechercher si la date a déjà été ajouté au tableau
                let found = arrayDates.find((elem) => {
                    return elem.date === moment(day.start).format('YYYY-MM-DD')
                })

                // si la date n'a jamais été rajouté
                if (!found && addDate === true) {
                    let obj = {
                        date: moment(day.start).format('YYYY-MM-DD'),
                        type: [availability.type],
                        data: [{
                            _id: availability._id,
                            type: availability.type,
                            start_date: availability.start_date,
                            end_date: availability.end_date,
                            retrocession_wished: availability.retrocession_wished,
                            radius_preference: availability.radius_preference,
                            views: availability.views,
                            privateReplacementsCount: availability.privateReplacementsCount,
                            day: moment(day.start).day()
                        }]
                    }

                    // Si ce n'est pas une régular ou si c'est une régular mais que c'est bien le bon jour
                    if (availability.type !== "regular" || arrayResult.includes(moment(day.start).day())) {
                        arrayDates.push(obj);
                    }
                } else if (addDate === true) {
                    let data = {
                        _id: availability._id,
                        type: availability.type,
                        start_date: availability.start_date,
                        end_date: availability.end_date,
                        retrocession_wished: availability.retrocession_wished,
                        radius_preference: availability.radius_preference,
                        views: availability.views,
                        privateReplacementsCount: availability.privateReplacementsCount,
                        day: moment(day.start).day()
                    }

                    // Si ce n'est pas une régular ou si c'est une régular mais que c'est bien le bon jour
                    if (availability.type !== "regular" || arrayResult.includes(moment(day.start).day())) {
                        found.type.push(availability.type)
                        found.data.push(data)
                    }
                }
            })
        }

        // Boucle sur le nouveau tableau pour rajouter les différentes couleurs dans une seule case
        for (let elem of arrayDates) {
            let className;
            if (elem.type.length === 1) {
                if (elem.type[0] === 'occasional') {
                    className = <Col sm="12" className="paddingTopCustom border-0 bg-paleblue text-dark lp-1">
                        <div className="green-light ml-0 pl-0"></div>&nbsp;
                        <FormattedMessage id="Availability.Short" />
                    </Col>
                } else if (elem.type[0] === 'regular') {
                    className = <Col sm="12" className="paddingTopCustom border-0 bg-paleblue text-dark lp-1">
                        <div className="blue-light ml-0 pl-0"></div>&nbsp;
                        <FormattedMessage id="Availability.Short" />
                    </Col>
                } else if (elem.type[0] === 'guard') {
                    className = <Col sm="12" className="paddingTopCustom border-0 bg-paleblue text-dark lp-1">
                        <div className="red-light ml-0 pl-0"></div>&nbsp;
                        <FormattedMessage id="Availability.Short" />
                    </Col>
                }
            } else if (elem.type.length === 2) {
                if (elem.type.includes('occasional') && elem.type.includes('regular')) {
                    className = <Col sm="12" className="paddingTopCustom border-0 bg-paleblue text-dark lp-1">
                        <div className="green-light ml-0 pl-0"></div>
                        <div className="blue-light ml-0 pl-0"></div>&nbsp;
                        <FormattedMessage id="Availability.Short" />
                    </Col>
                } else if (elem.type.includes('occasional') && elem.type.includes('guard')) {
                    className = <Col sm="12" className="paddingTopCustom border-0 bg-paleblue text-dark lp-1">
                        <div className="green-light ml-0 pl-0"></div>
                        <div className="red-light ml-0 pl-0"></div>&nbsp;
                        <FormattedMessage id="Availability.Short" />
                    </Col>
                } else if (elem.type.includes('regular') && elem.type.includes('guard')) {
                    className = <Col sm="12" className="paddingTopCustom border-0 bg-paleblue text-dark lp-1">
                        <div className="blue-light ml-0 pl-0"></div>
                        <div className="red-light ml-0 pl-0"></div>&nbsp;
                        <FormattedMessage id="Availability.Short" />
                    </Col>
                }
            } else if (elem.type.length === 3) {
                className = <Col sm="12" className="paddingTopCustom border-0 bg-paleblue text-dark lp-1">
                    <div className="green-light ml-0 pl-0"></div>
                    <div className="red-light ml-0 pl-0"></div>
                    <div className="blue-light ml-0 pl-0"></div>&nbsp;
                    <FormattedMessage id="Availability.Short" />
                </Col>
            }

            let eventObj = {
                start: moment(elem.date).hours(22).toDate(),
                end: moment(elem.date).hours(21).minutes(59).seconds(59).toDate(),
                allDay: true,
                color: "dark",
                title: className,
                _type: 'Availability',
                data: elem.data
            }      
            result.push(eventObj)    
        }
        return result;
    }

    eventColors(event) {
        let classNameCustom;
        if (event._type === 'CalendarEvent') {
            classNameCustom = 'bg-orange p1 lp-1 pl-1';
        } 
        
        var style = {
            whiteSpace: 'pre-wrap',
            borderRadius: 5
        };

        return {
            className: classNameCustom,
            style: style
        };
    }


    //! ============================================================================================
    //! ==================================== CALENDAR EVENTS =======================================
    //! ============================================================================================

    formatCalendarEvents(calendarEvents) {
        var arrayDates = [];
        var result = [];

        let startDate = this.startDate;
        let endDate = this.endDate;

        if (this.state.view !== 'year') {
            startDate = moment(this.state.first_date_of_month).subtract(7, 'days');
            endDate = moment(this.state.first_date_of_month).add(1, 'month').add(7, 'days');
        }
        
        let calendarEventsFilter = calendarEvents.filter((elem) => {
            return moment(elem.end_date).isAfter(startDate) && moment(elem.start_date).isBefore(endDate)
        })
        for (let calendarEvent of calendarEventsFilter) {
            let startDate = moment(calendarEvent.start_date);
            let endDate = moment(calendarEvent.end_date);
            let startDateOriginal = moment(calendarEvent.start_date);

            // Rajoute au tableau les jours entre deux dates
            while (startDate.isSameOrBefore(endDate, 'day')) {
                let obj = {
                    date: startDate.format('YYYY-MM-DD'),
                    data : [{
                        _id: calendarEvent._id,
                        start_date: moment(startDate),
                        end_date: calendarEvent.end_date,
                        prevent_availabilities: calendarEvent.prevent_availabilities,
                        substitute_id: calendarEvent.substitute_id,
                        title: calendarEvent.title,
                        desc: calendarEvent.desc,
                        allDay: false
                    }]
                }

                if (startDate.isSame(endDate, 'day')) {
                    if (endDate.isSame(moment(endDate).startOf('day'))) {
                        break;
                    }
                    if (!startDate.isSame(startDateOriginal, 'day')) {
                        obj.data[0].start_date = moment(startDate).startOf('day');
                    }
                } else {
                    obj.data[0].end_date = moment(startDate).endOf('day');
                    if (!startDate.isSame(startDateOriginal, 'day')) {
                        obj.data[0].start_date = moment(startDate).startOf('day');
                    } 
                }

                if (moment(obj.data[0].start_date).isSame(moment(obj.data[0].start_date).startOf('day')) && moment(obj.data[0].end_date).isSame(moment(obj.data[0].end_date).endOf('day'))) {
                    obj.data[0].allDay = true;
                } else if (moment(obj.data[0].start_date).isAfter(moment(obj.data[0].start_date).hour(22), 'hours') || moment(obj.data[0].end_date).isBefore(moment(obj.data[0].start_date).hour(7).minute(1), 'minutes')) {
                    obj.data[0].allDay = true;
                }

                arrayDates.push(obj);
                startDate.add(1, 'days');
            }
        }

        // Boucle sur le nouveau tableau pour rajouter les différentes couleurs dans une seule case
        for (let elem of arrayDates) {
            let className;
            for (let sub_elem of elem.data) {
                className = <Col sm="12" className="border-0 bg-orange text-dark lp-1">
                    <div className="rbc-event-content" title={sub_elem.title}>{(sub_elem.prevent_availabilities) && <><i className="fa fa-lock" ></i>&nbsp;&nbsp;</>}{sub_elem.title}</div>
                </Col>


                let eventObj = {
                    start: moment(sub_elem.start_date).toDate(),
                    end: moment(sub_elem.end_date).toDate(),
                    color: "dark",
                    title: className,
                    _type: 'CalendarEvent',
                    data: elem.data,
                    prevent_availabilities: sub_elem.prevent_availabilities,
                    allDay: sub_elem.allDay
                }            
                result.push(eventObj)  
            }
        }

        return result;
    }

    // Grise les dimanches
    customDayPropGetter(date) {
        if (date.getDay() === 0) {
            return {
                className: 'rbc-sunday',
            };
        }
    };

    // Customise la toolbar
    customToolBar(toolbar) {
        const goToWeekView = () => {
            this.props.changeView('week');
        };

        const goToMonthView = () => {
            toolbar.onView("month");
            this.setState({ view: "month", date: toolbar.date, first_date_of_month: moment(toolbar.date).startOf('month') });
        };

        const goToYearView = () => {
            toolbar.onView("year");
            this.setState({ view: "year", date: new Date() });
        };

        const goToToday = () => {
            toolbar.onView("month");
            this.setState({ view: "month", date: new Date(), first_date_of_month: moment().startOf('month') });
        }

        const goToBack = () => {
            let view = this.state.view;
            let mDate = toolbar.date;
            let newDate;
            let newStartDate;
            if (view === "month") {
                newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
                newStartDate = new Date(mDate.getFullYear(), mDate.getMonth() - 2, 0);

            } else if (view === "week") {
                newStartDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() - 14, 1);
                newDate = new Date(
                    mDate.getFullYear(),
                    mDate.getMonth(),
                    mDate.getDate() - 7,
                    1
                );
            } else if (view === 'year') {
                newStartDate = new Date(mDate.getFullYear() - 1, 0);
                newDate = new Date(
                    mDate.getFullYear() - 1,
                    1
                );
            } 
            this.startDate = newStartDate;
            this.setState({
                last_month_load: newStartDate
            })
            this.fetchData(1);
            toolbar.onNavigate("prev", newDate);
        }

        const goToNext = () => {
            let view = this.state.view;
            let mDate = toolbar.date;
            let newDate;
            if (view === "month") {
                newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
            } else if (view === "week") {
                newDate = new Date(
                    mDate.getFullYear(),
                    mDate.getMonth(),
                    mDate.getDate() + 7,
                    1
                );
            } else if (view === 'year') {
                newDate = new Date(
                    mDate.getFullYear() + 1,
                    1
                );
            } 
            toolbar.onNavigate("next", newDate);
        };
        var dateEntete = toolbar.label.toUpperCase();

        const onChangeShowFilter = () => {
            this.setState({
                showFilter: !this.state.showFilter
            })
        }

        const onChangeFilter = (event) => {
            let name = event.currentTarget.getAttribute('name');

            if (name === 'showAll') {
                this.setState({
                    showAgreements: true,
                    showEvents: true,
                    showAvailability: true,
                    showGuard: true,
                    hideAll: false,
                    showAll: true
                })
            } else if (name === 'hideAll') {
                this.setState({
                    showAgreements: false,
                    showEvents: false,
                    showAvailability: false,
                    showGuard: false,
                    showAll: false,
                    hideAll: true
                })
            } else {
                this.setState({
                    [name]: !this.state[name],
                    showAll: false,
                    hideAll: false
                })
            }
        }

        return (
            <div className='header-scalendar row'>
                <div className='col-md-3 order-md-1 col-6 order-1 d-flex justify-content-sm-center align-items-sm-center pl-3'>
                    <Dropdown isOpen={this.state.showFilter} toggle={onChangeShowFilter}>
                        <DropdownToggle caret>
                            Filtrer
                        </DropdownToggle>
                        <DropdownMenu className='dropDownMenuFilter dropdown-item-border-radius'>
                            <div className='dropdown-item-custom'>
                                <div name="showAll" id="showAll" className={"text-color-black clickable " + (this.state.showAll === true ? 'bold' : '')} onClick={onChangeFilter}>
                                    <div className="green-light pl-0 ml-0"></div>
                                    <div className="cssNotifShowLegendNotSign pl-0 merge-light"></div>
                                    <div className="red-light pl-0 merge-light"></div>
                                    <div className="cssNotifShowEventColor pl-0 merge-light"></div>
                                    &nbsp;Tout voir
                                </div>
                            </div>
                            <div className='dropdown-item-custom'>
                                <div name="showAgreements" id="showAgreements" onClick={onChangeFilter} className={"text-color-black clickable " + (this.state.showAgreements === true ? 'bold' : '')}>
                                    <div className="green-light ml-0 pl-0" ></div>&nbsp;Les remplacements
                                </div>
                            </div>
                            <div className='dropdown-item-custom'>
                                <div name="showAvailability" id="showAvailability" onClick={onChangeFilter} className={"text-color-black clickable " + (this.state.showAvailability === true ? 'bold' : '')}>
                                    <div className="cssNotifShowLegendNotSign ml-0 pl-0" ></div>&nbsp;Les disponibilités
                                </div>
                            </div>
                            <div className='dropdown-item-custom'>
                                <div name="showGuard" id="showGuard" onClick={onChangeFilter} className={"text-color-black clickable " + (this.state.showGuard === true ? 'bold' : '')}>
                                    <div className="red-light ml-0 pl-0" onClick={onChangeFilter}></div>&nbsp;Les gardes
                                </div>
                            </div>
                            <div className='dropdown-item-custom'>
                                <div name="showEvents" id="showEvents" onClick={onChangeFilter} className={"text-color-black clickable " + (this.state.showEvents === true ? 'bold' : '')}>
                                    <div className="ml-0 pl-0 cssNotifShowEventColor" onClick={onChangeFilter}></div>&nbsp;Les événements
                                </div>
                            </div>
                            <div className='dropdown-item-custom'>
                                <div name="hideAll" id="hideAll" onClick={onChangeFilter} className={"text-color-black clickable " + (this.state.hideAll === true ? 'bold' : '')}>
                                    X Tout masquer
                                </div>
                            </div>
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div className='col-12 order-3 col-md-6 order-md-2 text-center header-date-calendar'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-md-1 col-1'>
                            <button className="custom-button-today" onClick={goToToday}>
                                <i className="fa fa-calendar-check fa-2x"></i>
                            </button>
                        </div>
                        <div className='col-md-6 col-11 d-flex align-items-center justify-content-center'>
                            <div className='arrow-button-custom' type="button" onClick={goToBack}>
                                <span className="prev-icon-arrow">&#8249;</span>
                            </div>
                            <div className="dateEnteteSCalendar calendar-size-date">{dateEntete}</div>
                            <div className='arrow-button-custom' type="button" onClick={goToNext}>
                                <span className="next-icon-arrow">&#8250;</span>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-12 d-flex justify-content-around'>
                            <span className={"clickable " + (this.state.view === 'year' ? 'surround-view-calendar' : '')} onClick={goToYearView}>
                                    <div className={"buttonMonth " + (this.state.view === 'year' ? 'surround-view-calendar-text' : '')}>A</div>
                            </span>
                            <span className={"clickable " + (this.state.view === 'month' ? 'surround-view-calendar' : '')} onClick={goToMonthView}>
                                <div className={"buttonMonth " + (this.state.view === 'month' ? 'surround-view-calendar-text' : '')}>M</div>
                            </span>
                            <span className={"clickable " + (this.state.view === 'week' ? 'surround-view-calendar' : '')} onClick={goToWeekView}>
                                <div className={"buttonMonth " + (this.state.view === 'week' ? 'surround-view-calendar-text' : '')}>S</div>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-6 order-1 col-md-2 order-md-3 d-flex justify-content-end align-items-center pr-3">
                    <span onClick={this.openAvailabilityModal} className="bg-orange text-white text-center clickable circleCustom">+</span>
                </div>
            </div>
          );
    }

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    render() {
        const { availabilitys, replacements, substituteCharacteristic, calendarEvents } = this.props;
        if (!substituteCharacteristic || this.state.loading) return this.loading();
        const formattedAvailabilitys = (availabilitys.length) ? this.formatAvailabilitys(availabilitys, replacements) : [];
        const { arrayAgreements, arrayGuards } = this.formatReplacements(replacements);
        const formattedCalendarEvents = (calendarEvents.length) ? this.formatCalendarEvents(calendarEvents) : [];
        
        let events = [];
        if(this.state.showAll) {
            events.push(formattedAvailabilitys.concat(arrayAgreements).concat(formattedCalendarEvents).concat(arrayGuards));
        } else {
            if (this.state.showEvents) {
                events.push(formattedCalendarEvents);
            } 
            if (this.state.showAgreements) {
                events.push(arrayAgreements);
            } 
            if (this.state.showAvailability) {
                events.push(formattedAvailabilitys);
            }
            if (this.state.showGuard) {
                events.push(arrayGuards);
            }
        }
        if (this.state.hideAll) {
            events = [];
        }

        if (events.length > 0) {
            events = events.flat();
        }

        const messages = {
            allDay: <FormattedMessage id="Calendar.AllDay" />,
            previous: <FormattedMessage id="Calendar.Previous" />,
            next: <FormattedMessage id="Calendar.Next" />,
            today: <FormattedMessage id="Calendar.Today" />,
            month: <FormattedMessage id="Calendar.Month" />,
            week: <FormattedMessage id="Calendar.Week" />,
            day: <FormattedMessage id="Calendar.Day" />,
            agenda: <FormattedMessage id="Calendar.Agenda" />,
            date: <FormattedMessage id="Calendar.Date" />,
            time: <FormattedMessage id="Calendar.Time" />,
            event: <FormattedMessage id="Calendar.Event" />,
            year: <FormattedMessage id="Year" />,
            showMore: total => `+ ${total} autre(s)`
        };

        let newDate = new Date()

        return (
            
            <React.Fragment>
                <Card className="p-0 rounded-0" id="customSCalendar">
                    <Calendar
                        components={{ 
                            toolbar: this.customToolBar,
                        }}
                        renderable
                        selectable
                        popup={true}
                        step={30}
                        min={new Date(0, 0, 0, 7, 0, 0)}
                        max={new Date(0, 0, 0, 22, 0, 0)}
                        localizer={localizer}
                        defaultDate={newDate}
                        messages={messages}
                        events={events}
                        view={this.state.view}
                        views={{ 
                            month: true,
                            year: Year  
                        }}
                        culture={'fr'}
                        date={this.state.date}
                        eventPropGetter={this.eventColors}
                        dayPropGetter={this.customDayPropGetter}
                        onView={this.onRangeChange}
                        onNavigate={this.onNavigate}
                        onRangeChange={this.onRangeChange}
                        onSelectEvent={this.eventModal}
                        onSelectSlot={this.openAvailabilityModal}
                        onDrillDown={this.onDrillDown}
                        from={'SCalendar'}
                    />
                </Card>

                {this.state.modal}

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        availabilitys: state.global.availabilitys,
        replacements: state.global.replacements,
        substituteCharacteristic: state.global.substituteCharacteristic,
        calendarEvents: state.global.calendarEvents,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback)))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SCalendar));