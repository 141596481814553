import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import DatePicker from "react-datepicker"
import { Button } from "reactstrap";
import { connect } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';



class AddGuardModal extends React.Component {

   constructor(props) {
      super(props);

      this.state = {
         start_date: this.props.start_date,
         end_date: this.props.end_date,
         retrocession_wished: this.props.retrocession_wished,
         retrocession_guard: this.props.retrocession_guard,
         activite_previsionnelle: this.props.activite_previsionnelle,

         hasGuard: false,
         parking: false,
         housing: false,

         //donnees garde
         guard_start_date_min: new Date(this.props.start_date),
         guard_start_date: new Date(new Date(this.props.start_date).setHours(20)),
         guard_start_date_max: new Date(this.props.end_date),
         
         guard_end_date_min: new Date(new Date(this.props.start_date).setHours(5)),
         guard_end_date: new Date(new Date(this.props.start_date).setHours(new Date(this.props.start_date).getHours() + 32)),
         guard_end_date_max: new Date(new Date(this.props.start_date).setHours(44)),

         guard_end_time_min: new Date(new Date().setHours(24, 0)),
         guard_end_time_max: new Date(new Date().setHours(44, 0)),

         office: false,
         visits: false,
         paid_by_the_fifteenth: false,
         astreinte: 20,

         toggle: this.props.toggle,
         isOpen: this.props.isOpen

      };

   }

   handleChangeeDate(newDate) {
      //end date change

      this.setState({ 
         guard_end_date: newDate,
         guard_end_time_min: newDate.getDate() === this.state.guard_start_date.getDate() ? new Date(new Date(this.state.guard_start_date).setHours(new Date(this.state.guard_start_date).getHours() + 4)) : new Date(new Date(this.state.guard_start_date).setHours(24)) ,
         guard_end_time_max: newDate.getDate() === this.state.guard_start_date.getDate() ? new Date(new Date(this.state.guard_start_date).setHours(23,30)) : new Date(new Date(this.state.guard_start_date).setHours(this.state.guard_start_date.getHours() + 24)),
      })

   }

   handleChangesDate(newDate) {
      //start date change

      
      this.setState({
         guard_start_date: newDate,
         guard_end_date_min: new Date(new Date(newDate).setHours(new Date(newDate).getHours() + 4)),
         guard_end_date: new Date(new Date(newDate).setHours(new Date(newDate).getHours() + 12)),
         guard_end_date_max: new Date(new Date(newDate).setHours(new Date(newDate).getHours() + 24)),
         guard_end_time_min: newDate.getHours() >= 12 ? new Date(new Date(newDate).setHours(24)) : new Date(new Date(newDate).setHours(new Date(newDate).getHours() + 4)) ,
         guard_end_time_max: newDate.getHours() >= 12 ? new Date(new Date(newDate).setHours(newDate.getHours() + 24)) : new Date(new Date(newDate).setHours(23,30)),
      })
   }

   handleChangeGuard = e => {
      e.preventDefault()

      var name = e.target.name
      var value = e.target.value

      this.setState({
         [name]: value
      })

   }

   getdayNumber(day){
      switch(day) {
         case 'monday':
           return 1;
         case 'tuesday':
           return 2;
         case 'wednesday':
           return 3;
         case 'thursday':
           return 4;
         case 'friday':
           return 5;
         case 'sunday':
           return 7;
         case 'saturday':
           return 6;
         default:
            return 0;
      }
   }

   render() {
      const data = {
         start_date: this.state.guard_start_date,
         end_date: this.state.guard_end_date,
         office: this.state.office,
         visits: this.state.visits,
         astreinte: this.state.astreinte,
         paid_by_the_fifteenth: this.state.paid_by_the_fifteenth,
         retrocession_guard: this.state.retrocession_guard
      }
     
      var liste = []

      if(this.props.availability.type === "regular"){
         for (var d = new Date(this.props.start_date); d <= new Date(this.props.end_date); d.setDate(d.getDate() + 1)) {
            if(d.getDay() === this.getdayNumber(this.props.availability.day)){
               liste.push((new Date(d)))
            }
        }
      }
      else{
         liste = null
      }
      return (
            <Modal isOpen={this.props.isOpen} toggle={this.state.toggle} className="simple-modal simple-modal-white srounded">

               <ModalHeader >
                  <button onClick={this.props.toggle} aria-label="Close">
                     <span> Retour </span>
                  </button>
                  Ajouter une garde
               </ModalHeader>

               <ModalBody className="p-4">

                  <form name="replForm" onSubmit={this.props.addGuard}>
                     <div className="container" style={{ marginTop: "10px" }}>

                        <div className="row justify-content-center" style={{ marginTop: "10px" }}>
                           <div className="col-12">
                              <label>
                                 Du : &nbsp;
                                 <DatePicker
                                    selected={this.state.guard_start_date}
                                    value={this.state.guard_start_date}
                                    onChange={(newDate) => this.handleChangesDate(newDate)}
                                    minDate={this.state.guard_start_date_min}
                                    maxDate={this.state.guard_start_date_max}
                                    style={{ "textAlign": "center" }}
                                    showTimeSelect
                                    includeDates={liste}
                                    dateFormat="MMMM d, yyyy h:mm">
                                    <div style={{ color: "red" }}>Une garde doit durer plus de 4h </div>
                                    <div style={{ color: "red" }}>Une garde doit durer moins de 24 h</div>
                                 </DatePicker>
                              </label>

                           </div>
                           <div className="col-12">
                              <label>
                                 Au :&nbsp;
                                 <DatePicker
                                    selected={this.state.guard_end_date}
                                    value={this.state.guard_end_date}
                                    onChange={(newDate) => this.handleChangeeDate(newDate)}
                                    minDate={this.state.guard_end_date_min}
                                    maxDate={this.state.guard_end_date_max}
                                    minTime={this.state.guard_end_time_min}
                                    maxTime={this.state.guard_end_time_max}
                                    style={{ "textAlign": "center" }}
                                    showTimeSelect
                                    
                                    dateFormat="MMMM d, yyyy h:mm">
                                    <div style={{ color: "red" }}>Une garde doit durer plus de 4h </div>
                                    <div style={{ color: "red" }}>Une garde doit durer moins de 24 h</div>
                                 </DatePicker>
                              </label>
                           </div>

                        </div>
                        <div className="row justify-content-center" style={{ marginTop: "10px" }}>
                           <div className="col-8">
                              <FormattedMessage id="Astreinte" ></FormattedMessage>&nbsp;&nbsp;&nbsp;&nbsp;
                              <input
                                 type="number"
                                 name="astreinte"
                                 style={{ "textAlign": "center" }}
                                 id="astreinte"
                                 value={this.state.astreinte}
                                 onChange={this.handleChangeGuard}
                              />
                           </div>
                           <div className="row justify-content-center " style={{ marginTop: "10px" }}>
                              <div className="col-12">
                                 <FormattedMessage id="Guard.Retrocession" ></FormattedMessage>
                                 <input
                                    type="range"
                                    id="retrocession_guard"
                                    name="retrocession_guard"
                                    min="000"
                                    max="200"
                                    step="10"
                                    value={this.state.retrocession_guard}
                                    onChange={this.handleChangeGuard}
                                 /> 
                                 &nbsp; {this.state.retrocession_guard} %
                              </div>
                           </div>
                        </div>

                        {/* <div className="row justify-content-center " style={{ marginTop: "10px" }}>
                           <div className="col-8">
                              <FormattedMessage id="Activite_previsionnelle" ></FormattedMessage>&nbsp;
                              <input
                                 type="range"
                                 id="ActivitePrevisionnelle"
                                 name="ActivitePrevisionnelle"
                                 min="000"
                                 max="200"
                                 step="10"
                                 value={this.state.ActivitePrevisionnelle}
                                 onChange={this.handleChangeGuard}
                              /> &nbsp; {this.state.ActivitePrevisionnelle} %
                           </div>
                        </div> */}

                     </div>
                     <div className="row justify-content-center " style={{ marginTop: "10px" }}>
                        <Button type="button" onClick={e => this.props.addGuard(data)}>+ Ajouter des gardes</Button>
                     </div>
                  </form>
               </ModalBody>
            </Modal>
      )
   }
}
const mapStateToProps = state => {
   return {

   };
};

const mapDispatchToProps = dispatch => {
   return {

   };
};

export default connect(mapStateToProps, mapDispatchToProps)((AddGuardModal));