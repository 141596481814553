import React from 'react';
import { Row, Col } from "reactstrap";
import Maths from '../../../../util/Maths';
import DateUtil from '../../../../util/DateUtil';

const AccountingMissionTotalRow = ({ accounting, user }) => {
    const { date, credit_card_total, cash_total, cheque_total, secu_total, retrocession_total } = accounting;

    return (
        <>
        <Row className="tableRow m-0 pl-5 pt-0">
            <Col className="text-left lh-45 lp-45">{DateUtil.toDate(date)}</Col>
            <Col className="text-left lh-45 lp-45">{credit_card_total ? Maths.round(credit_card_total) : '-'}</Col>
            <Col className="text-left lh-45 lp-45">{cheque_total ? Maths.round(cheque_total) : '-'}</Col>
            <Col className="text-left lh-45 lp-45">{cash_total ? Maths.round(cash_total) : '-'}</Col>
            <Col className="text-left lh-45 lp-45">{secu_total}</Col>
            {user.role !== "doctor" && <Col className="text-left lh-45 lp-45">{retrocession_total ? Maths.round(retrocession_total) : '-'}</Col>}
            <Col></Col>
        </Row>
        </>
    );
}

export default (AccountingMissionTotalRow);