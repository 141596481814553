import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import 'react-datetime/css/react-datetime.css';
import { Row, Col } from "reactstrap";
import AddReplacementModal from './AddReplacementModal';
import DReplacementCard from './DReplacementCard';
import { clears, getsBy } from '../../../actions/global/global';

import Pagination from "react-js-pagination";

import homeImgAnnonce from '../../../assets/images/homes/HYDROGEN_ILLU_ANNONCE.png';

class DReplacements extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            loading: false,

            // Pagination
            skip: 0,
            limit: 10
        };

        this.updateReplacementAfterAccept = this.updateReplacementAfterAccept.bind(this);
    };

    componentDidMount = () => {
        this.fetchData();
        if(window.location.href.indexOf("/add") !== -1) this.toggleModal();
    }

    toggleModal = () => {
        if (this.state.modal) {
            this.setState({
                modal: null
            });
        } else {
            this.setState({
                modal: <AddReplacementModal toggle={() => this.toggleModal()} updateCountAfterAccept={this.props.updateCountAfterAccept} />
            });
        };
    };

    fetchData() {
        this.setState({ loading: true });
        this.props.onGetsBy("replacement", ["skip", "limit"], [this.state.skip, this.state.limit],
            () => {
                this.setState({ loading: false })
            });
    }

    updateReplacementAfterAccept() {
        this.fetchData();
        this.props.updateCountAfterAccept();
    }

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    render() {
        const { replacements, replacementsCount } = this.props;
        let filteredReplacements = []
        if (replacements) {
            filteredReplacements = replacements
            .sort((r0, r1) => new Date(r1.createdAt) - new Date(r0.createdAt))
            .filter((r) => !r.applicants.find(a => a.substitute_status === 1 && a.doctor_status === 1))
        }
        const filteredReplacementsCount = filteredReplacements.length
        return (
            <React.Fragment>

                <Row className="p-0 m-0">
                    <Col xs="12" className="p-0 m-0">

                        <div className="text-center mb-2 mx-auto" style={{ width: 200 }}>
                            <div className="img-btn mb-3 mx-auto clickable" onClick={() => this.toggleModal()} style={{ width: 150, height: 150 }}>
                                <img src={homeImgAnnonce} alt="" width={150} height={150} />
                            </div>

                            <div className="uppercase text-white"><FormattedHTMLMessage id="Post.New.Replacement" /></div>
                        </div>

                    </Col>
                </Row>

                {(this.state.loading) && this.loading()}

                {(!this.state.loading && replacements && filteredReplacements.length > 0) &&
                    <>
                        <div className="w-100 text-center text-white mt-3 mb-3">
                            {filteredReplacementsCount}&nbsp;
                            {(filteredReplacementsCount > 1) ? <FormattedMessage id="Results" /> : <FormattedMessage id="Result" />}
                        </div>

                        {
                            filteredReplacements
                                .sort((r0, r1) => new Date(r1.createdAt) - new Date(r0.createdAt))
                                .filter((r) => !r.applicants.find(a => a.substitute_status === 1 && a.doctor_status === 1))
                                .map(r => <DReplacementCard key={r._id} replacement={r} updateReplacementAfterAccept={this.updateReplacementAfterAccept} redirectAfterSign={this.props.redirectAfterSign} replacementId={this.props.replacementId} openCollapse={this.props.openCollapse}/>)
                        }

                        <Pagination
                            innerClass="w-100 pagination justify-content-center"
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={Math.round(this.state.skip / this.state.limit) + 1}
                            itemsCountPerPage={this.state.limit}
                            totalItemsCount={Math.max(replacementsCount, replacements.length)}
                            pageRangeDisplayed={5}
                            onChange={(page) => this.setState({ skip: (page - 1) * this.state.limit }, this.fetchData)}
                            hideDisabled={false}
                        />
                    </>
                }

                {this.state.modal}

            </React.Fragment >
        );
    }
}



const mapStateToProps = state => {
    return {
        replacements: state.global.replacements,
        replacementsCount: state.global.replacementsCount
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DReplacements));