import React from 'react';
import { Card, Row, Col, Progress } from 'reactstrap';
import moment from 'moment';
import ClinicalStudyDetail from './ClinicalStudyDetail';
import AddPatientModal from './AddPatient/AddPatientModal';

import hospital from '../../assets/images/homes/HYDROGEN_ILLU_RECHERCHE.png';
import cabinet from '../../assets/images/homes/HYDROGEN_ILLUS-ABONNEMENT.png';

export default class ClinicalStudyCard extends React.Component {

    constructor(props) {
		super(props);

		this.state = {
			addPatientModal: null,
			clinicalStudyDetailModal: null
		}

		this.openModalClinicalStudyDetail = this.openModalClinicalStudyDetail.bind(this);
		this.closeModalClinicalStudyDetail = this.closeModalClinicalStudyDetail.bind(this);
		this.openAddPatientModal = this.openAddPatientModal.bind(this);
		this.closeAddPatientModal = this.closeAddPatientModal.bind(this);
	}

	openModalClinicalStudyDetail() {
        this.setState({
            clinicalStudyDetailModal: true,
			addPatientModal: false
        });
	}

	closeModalClinicalStudyDetail() {
		this.setState({
			clinicalStudyDetailModal: false
		})
	}

	openAddPatientModal() {
        this.setState({
			clinicalStudyDetailModal: false,
			addPatientModal: true
        });
	}

	closeAddPatientModal() {
		this.setState({
			addPatientModal: false,
			clinicalStudyDetailModal: true
		})
	}

	render() {
		const { clinicalStudy } = this.props;
		const daysToGo = moment(clinicalStudy.end_date).diff(moment(), 'days');
		const includedPatientsNumber = clinicalStudy.patient.filter(p => p.included === true).length;

		return(
			<Card className="min-height-100 clickable custom-card-clinical-study">
				<Row className="justify-content-around align-middle" onClick={this.openModalClinicalStudyDetail}>
					<Col sm="1" md="1" className="justify-content-center text-center">
						<img src={clinicalStudy.type_clinical_study === 'hospital' ? hospital : cabinet} height="50" width="50" alt={clinicalStudy.type_clinical_study === 'hospital' ? 'hopital' : 'cabinet'} />
					</Col>
					<Col sm="6" md="5" className="justify-content-center text-center">{clinicalStudy.pathology}</Col>
					<Col sm="6" md="2" className="justify-content-center text-center">Indémnisation : {clinicalStudy.price_per_patient}€</Col>
					<Col sm="6" md="3">
						<div className="text-center progress-label-card px-2 pt-4">
							Patients à inclure : {clinicalStudy.number_patient_goal - includedPatientsNumber} / {clinicalStudy.number_patient_goal}
							<Progress color="success" min={0} max={clinicalStudy.number_patient_goal} value={includedPatientsNumber} />
						</div>
					</Col>
					<Col xs="2" sm="3" md="1" className={"days-to-go d-inline-block pl-3 pr-3 " + ((daysToGo >= 0 && daysToGo < 15) ? "text-danger" : "")}>
						<i className="fa icon-ICON__ALERTE_GRIS_C"></i>&nbsp;
						<b>{daysToGo > 0 ? daysToGo : 0}j</b>
					</Col>
				</Row>
				<AddPatientModal isOpen={this.state.addPatientModal} close={this.closeAddPatientModal} clinicalStudy={clinicalStudy}/>
				<ClinicalStudyDetail
					isOpen={this.state.clinicalStudyDetailModal}
					closeModal={this.closeModalClinicalStudyDetail}
					clinicalStudy={this.props.clinicalStudy}
					openAddPatientModal={this.openAddPatientModal}
					/>

			</Card>
		)
	}
}