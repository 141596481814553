import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import 'ladda/dist/ladda-themeless.min.css';
// import DoctorRatingsProfile from './DoctorRatingsProfile';
import DoctorAboutProfile from './DoctorAboutProfile';
import DoctorMoreDetailsProfile from './DoctorMoreDetailsProfile';
import DoctorPracticesProfile from './DoctorPracticesProfile';
import DoctorOfficeTimeTableProfile from './DoctorOfficeTimeTableProfile';

export default class DoctorCharacteristicsProfile extends Component {

    render() {
        const { doctorCharacteristic, officeTimeTable /*, doctor */ } = this.props;

        return (
            <React.Fragment>

                <Row>

                    <Col md="6">
                        <div className="mt-3 mb-3 mx-auto text-left max-w-400px">
                            <DoctorAboutProfile doctorCharacteristic={doctorCharacteristic} />
                        </div>

                        <div className="mt-3 mb-3 mx-auto text-left max-w-400px">
                            <DoctorMoreDetailsProfile doctorCharacteristic={doctorCharacteristic} />
                        </div>
                    </Col>

                    {/* <Col md="6">
                        <div className="mt-3 mb-3 mx-auto text-left max-w-400px">
                            <DoctorRatingsProfile doctor={doctor} />
                        </div>
                    </Col> */}
                    
                    <Col md="6">
                        <div className="mt-3 mb-3 mx-auto max-w-400px">
                            <DoctorPracticesProfile doctorCharacteristic={doctorCharacteristic} />
                        </div>
                    </Col>

                </Row>

                <Row className="">

                </Row>

                <div className="pb-3">
                    <div className="">
                        <DoctorOfficeTimeTableProfile officeTimeTable={officeTimeTable} />
                    </div>
                </div>

            </React.Fragment >
        );
    };

}