import React from 'react';
import AccountingMonthly from './tables/monthly/AccountingMonthly';
import AccountingDaily from './tables/daily/AccountingDaily';
import AccountingMission from './tables/mission/AccountingMission';

const AccountingTableWrapper = ({
  loading,
  agreements,
    startDate,
    skip,
    limit,
    viewChoosen,
    missionsPaginationLimit,
    missionsPaginationSkip,
    subcategory,
    switchSubcategory,
    dayChoosen,
    monthChoosen,
    getPreviousMission,
    getNextMission,
    openModal,
    openExpensesModal,
    openconsultationsModal,
    accountings,
    accountingsCount,
    accountingTotals,
    deleteAccoutingLine,
    switchToDayFromMonth,
    getMissionCertificate,
    dailyPaginationChange,
    accountingTotalsDays,
    missionPaginationChange,
    user
}) => {

    const showViewAccordinglyToUserChoice = () => {
        if (viewChoosen === "month") return <AccountingMonthly
            accountingTotals={accountingTotals}
            accountingTotalsDays={accountingTotalsDays}
            monthChoosen={monthChoosen}
            startDate={startDate}
            switchToDayFromMonth={switchToDayFromMonth}
            user={user}
        />;

        if (viewChoosen === "day") return <AccountingDaily
            loading={loading}
            viewChoosen={viewChoosen}
            skip={skip}
            limit={limit}
            dailyPaginationChange={dailyPaginationChange}
            subcategory={subcategory}
            switchSubcategory={switchSubcategory}
            openModal={openModal}
            openExpensesModal={openExpensesModal}
            openconsultationsModal={openconsultationsModal}
            deleteAccoutingLine={deleteAccoutingLine}
            accountingsCount={accountingsCount}
            startDate={startDate}
            dayChoosen={dayChoosen}
            accountings={accountings}
            accountingTotals={accountingTotals}
            user={user}
        />;

        if (!accountings || accountings.length === 0) return null;

        if (viewChoosen === "mission") return <AccountingMission
            accountingTotalsDays={accountingTotalsDays}
            agreement={accountings}
            agreementsCount={accountingsCount}
            getNextMission={getNextMission}
            getPreviousMission={getPreviousMission}
            getMissionCertificate={getMissionCertificate}
            missionsPaginationLimit={missionsPaginationLimit}
            missionsPaginationSkip={missionsPaginationSkip}
            missionPaginationChange={missionPaginationChange}
            loading={loading}
            user={user}
        />
    }

    return (
        <>
        <div className="bg-white pt-3" style={{ minHeight: "700px" }}>
            {showViewAccordinglyToUserChoice()}
        </div>
        </>
    );
}

export default AccountingTableWrapper;