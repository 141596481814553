import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Form, TextInput, NumericInput, RadioInput, SelectInput, NbrInput, MinLength, MaxLength, NotEmpty, Min, Max, Title, DecimalInput } from '@gferrand/react-forms';
import Geocode from "react-geocode";

import DoctorSecretariat from '../../../../enum/DoctorSecretariat';
import DoctorOfficeType from '../../../../enum/DoctorOfficeType';
import DoctorOfficeDisposal from '../../../../enum/DoctorOfficeDisposal';
import MedicalSoftwares from '../../../../enum/MedicalSoftwares';
import Teleconsultations from '../../../../enum/Teleconsultations';
import ConsultationsMode from '../../../../enum/ConsultationsMode';
import Util from "../../../../util/Util";
import GoogleMapUtil from "../../../../util/GoogleMapUtil"
import TimeSelInput from '../../../../components/form/TimeSelInput';
import ImgedSwitchInput from '../../../../components/form/ImgedSwitchInput';
import GMapWithoutScript from "../../../../components/map/GMapWithoutScript";
import SearchLocationInput from "../../../../components/form/SearchLocationInput";

import ecgImg from "../../../../assets/images/registration/HYDROGEN_ICONS_MED_ECG.png";
import nutritionImg from "../../../../assets/images/registration/HYDROGEN_ICONS_IRM.png";
import comportmentalTroublesImg from "../../../../assets/images/registration/HYDROGEN_ICONS_RADIOLOGIE.png";
import homeopathyImg from "../../../../assets/images/registration/HYDROGEN_ICONS_MED_HOMEO_01.png";
import traumatologyImg from "../../../../assets/images/registration/HYDROGEN_ICONS_SCINTIGRAPHIE.png";
import allergologyImg from "../../../../assets/images/registration/HYDROGEN_ICONS_MED_ALLERGO.png";
import emergencyImg from "../../../../assets/images/registration/HYDROGEN_ICONS_MED_URGENCE.png";


export default class PEDProfileForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lat: null,
            lng: null,

            region: "",
            department: "",
            city: "",
            country: "",
            postal_code: "",
            street: "",
            loadingGMaps: false
        };

        this.GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

        // Geocode (for geocoding, no kidding)
        Geocode.setApiKey(this.GOOGLE_MAP_API_KEY);

        // GMap component
        this.loadMapCbk = null;
        this.fixMapCbk = null;
        
        this.updateCityAndPostalCode = this.updateCityAndPostalCode.bind(this)
        
    }

    componentDidMount() {
        GoogleMapUtil.loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${this.GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
            () => {
                const { defaultValues } = this.props;
                this.buildMap(defaultValues)
                this.buildForm()

                if (this.form.getRawData().teleconsultation !== 15) {
                    this.form.hide("other_teleconsultation")
                }
                if (!defaultValues || !defaultValues.medical_software || defaultValues.medical_software !== "0") this.form.hide("other_software");
                if (!defaultValues || !defaultValues.teleconsultation || defaultValues.teleconsultation !== "15") this.form.hide("other_teleconsulation");
                if (!defaultValues || !defaultValues.visits_per_week_on_avg ||defaultValues.visits_per_week_on_avg === 0) this.form.hide("duree_Moyenne_Visite");
                if (!defaultValues || !defaultValues.sector || defaultValues.sector !== "2") {
                    this.form.hide("consultation_price");
                    this.form.hide("visit_price");
                }
                
                // Utile pour l'inscription 
                this.forceUpdate();
            }
        );
    }

    buildMap(defaultValues) {
        this.map = <GMapWithoutScript 
            height="300"
            addfixMapCbk={(cbk) => this.fixMapCbk = cbk} 
            addLoadMapCbk={(cbk) => this.loadMapCbk = cbk} />;
        // Put correct maps location right away
        if (defaultValues && (defaultValues.office_address_street || defaultValues.office_address_city || defaultValues.office_address_postcode)) {
            this.updateMap(
                defaultValues.office_address_street,
                defaultValues.office_address_city,
                defaultValues.office_address_postcode
            );
        }
    }

    buildForm() {
        const { submitCbk, defaultValues, validate } = this.props;
        
        if (defaultValues && defaultValues.office_address_street) {
            defaultValues.office_address_street = defaultValues.office_address_street + ', ' + defaultValues.office_address_postcode + ' ' + defaultValues.office_address_city + ( defaultValues.address_components ? ', ' + defaultValues.address_components.country : '' );
        }

        if (defaultValues && !defaultValues.teleconsultation) {
            defaultValues.teleconsultation = "0";
        }
        if(defaultValues && !defaultValues.duree_Moyenne_Visite) {
            if (defaultValues && defaultValues.visits_per_week_on_avg) {
                let hours = Math.floor((defaultValues.visits_per_week_on_avg * 30) / 60).toString();
                if (hours < 10) hours = '0' + hours
                else if (hours > 45) hours = '45:00'
                defaultValues.duree_Moyenne_Visite = hours + ':00';
            } else {
                defaultValues.duree_Moyenne_Visite = '01:00';
            }
        }
        var _submitCbk = (data, cbk) => {
            const fullAddress = data.office_address_street;

            Geocode.fromAddress(fullAddress).then((response) => {
                if (!response) {
                    this.form.setError('office_address_street', 'Veuillez controler votre adresse');
                    cbk();
                }
                if (response.results[0] && response.results[0].address_components) {
                    data.location = { type: "Point", coordinates: [response.results[0].geometry.location.lng, response.results[0].geometry.location.lat] };
                    data.address_components = {};
                    let streetNumber = '';
                    let streetName = '';
                    for (let comp of response.results[0].address_components) {
                        if (comp.types.includes("street_number")) {
                            data.address_components.street_number = comp.long_name;
                            streetNumber = comp.long_name;
                        } 
                        if (comp.types.includes("route")){
                            data.address_components.route = comp.long_name;
                            streetName = comp.long_name;
                        } 
                        if (comp.types.includes("administrative_area_level_1")) data.address_components.region = comp.long_name;
                        if (comp.types.includes("administrative_area_level_2")) data.address_components.department = comp.long_name;
                        if (comp.types.includes("locality")) {
                            data.address_components.city = comp.long_name;
                            data.office_address_city = comp.long_name;
                        } 
                        if (comp.types.includes("country")) data.address_components.country = comp.long_name;
                        if (comp.types.includes("postal_code")) {
                            data.address_components.postal_code = comp.long_name;
                            data.office_address_postcode = comp.long_name;
                        } 
                    }
                    data.office_address_street = streetNumber + ' ' + streetName;

                    if (data.office_address_street.length < 2) {
                        this.form.setError('office_address_street', 'Veuillez controler votre adresse');
                        cbk();
                        return;
                    }

                    if (!data.office_address_postcode || data.office_address_postcode.length < 2) {
                        this.form.setError('office_address_street', 'Veuillez controler votre adresse');
                        cbk();
                        return;
                    }

                    submitCbk(data, cbk);
                } else {
                    this.form.setError('office_address_street', 'Veuillez controler votre adresse');
                    cbk();
                }
            })
            .catch(() => {
                this.form.setError('office_address_street', 'Veuillez controler votre adresse');
                cbk();
            })
        };

        var changeCbk = (input) => {
            if (input.name === "sector") {
                if (input.value === "2") {
                    this.form.show("consultation_price");
                    this.form.show("visit_price");
                } else if (input.value === "1") {
                    this.form.hide("consultation_price");
                    this.form.hide("visit_price");
                }
            }

            if (input.name === "medical_software") {
                if (input.value === "0") this.form.show("other_software")
                else this.form.hide("other_software")
            }

            if (input.name === "teleconsultation") {
                if (input.value === "15") {
                    this.form.show("other_teleconsultation")
                } else {
                    this.form.hide("other_teleconsultation")
                }
            }

            if(input.name === "visits_per_week_on_avg"){
                if(input.value !== 0) {
                    this.form.show("duree_Moyenne_Visite")
                    this.form.inputs[14].setValue("00:00");
                } else  {
                    this.form.hide("duree_Moyenne_Visite");
                }
            }

        };

        this.form = new Form({
            name: "pediatricianCharacteristic",
            inputs: [

                new SearchLocationInput("office_address_street", "Office.Address", [MinLength(2), MaxLength(100), NotEmpty], this.updateCityAndPostalCode),
                new SelectInput("office_type", "Office.Type", DoctorOfficeType.values(), [NotEmpty]),
                new SelectInput("secretariat", "Secretariat", DoctorSecretariat.values(), [NotEmpty]),

                new SelectInput("consultation", "Consultation", ConsultationsMode.values(), [NotEmpty]),

                new SelectInput("medical_software", "Medical.Software", MedicalSoftwares.values(), [NotEmpty]),

                new TextInput("other_software", "Other.Software", undefined, [NotEmpty]),
                new SelectInput("teleconsultation", "Teleconsultation", Teleconsultations.values(), [NotEmpty]),
                
                new TextInput("other_teleconsultation", "Other.Teleconsultation", undefined, [NotEmpty]),

                new RadioInput("sector", "Sector",
                    [{ label: "1", value: "1" },
                    { label: "2", value: "2" }]
                    , [NotEmpty],
                    { groupClassName: "text-left ", className: "srounded mr-3 pl-4 pr-4 lowercase bg-blue text-white mb-1 mb-sm-0" }),
                new DecimalInput("consultation_price", "Consultation.Price", [NotEmpty, Min(25), Max(200)]),
                new DecimalInput("visit_price", "Visit.Price", [NotEmpty, Min(35), Max(200)]),

                new SelectInput("office_disposal", "Office.Disposal", DoctorOfficeDisposal.values(), [NotEmpty]),

                new NbrInput("consultations_per_week_on_avg", "Consultations.Per.Week.On.Average", [NotEmpty, Min(50), Max(400)]),
                new NbrInput("visits_per_week_on_avg", "Number.Of.Home.Visits.Per.Week.On Average", [NotEmpty, Min(0), Max(50)]),
                new TimeSelInput("duree_Moyenne_Visite", "Average.Time.Per.Visit.Per.Week", 0, 45, 15),

                new NumericInput("RPPS", "RPPS.Number", 11, [NotEmpty, MinLength(11)]),
                       
                new Title(<div className="d-block w-100 uppercase p-2 pb-4 bold"><FormattedMessage id="Medical.Practices" /></div>),

                new ImgedSwitchInput("ecg", "Ecg", [NotEmpty], { displayLabel: false, cClassName: "d-inline-block custom-practices-psy ", img: ecgImg }),
                new ImgedSwitchInput("nutrition", "Nutrition", [NotEmpty], { displayLabel: false, cClassName: "d-inline-block", img: nutritionImg }),
                new ImgedSwitchInput("comportmental_troubles", "Comportmental.Troubles", [NotEmpty], { displayLabel: false, cClassName: "d-inline-block", img: comportmentalTroublesImg }),
                new ImgedSwitchInput("homeopathy", "Homeopathy", [NotEmpty], { displayLabel: false, cClassName: "d-inline-block", img: homeopathyImg }),
                new ImgedSwitchInput("traumatology", "Traumatology", [NotEmpty], { displayLabel: false, cClassName: "d-inline-block", img: traumatologyImg }),
                new ImgedSwitchInput("allergology", "Allergology", [NotEmpty], { displayLabel: false, cClassName: "d-inline-block", img: allergologyImg }),
                new ImgedSwitchInput("emergency_medicine", "Emergency.Medicine", [NotEmpty], { displayLabel: false, cClassName: "d-inline-block custom-practices-psy ", img: emergencyImg }),
            ],
            submitCbk: _submitCbk,
            data: defaultValues,
            options: { validate: Util.null(validate) ? true : validate, validateAtStart: false },
            changeCbk,
        });
    };

    validate(cbk) {
		let isValid = true;
		let isFormValid = true;

        let fullAddress = this.form.inputs[0]._state.value;

        return Geocode.fromAddress(fullAddress)
            .then((response) => {
                if (!response) {
                    this.form.setError('office_address_street', 'Veuillez controler votre adresse');
                    return false;
                }
                if (!response.results[0] || !response.results[0].address_components) {
                    this.form.setError('office_address_street', 'Veuillez controler votre adresse');
                    return false;
                }
                this.form.inputs.forEach((elem) => {
                    if (elem.valid) {
                        isValid = elem.valid();
                    }
                    if (isValid === false) {
                        isFormValid = false;
                    }
                })

                let address_components = {};
                let streetNumber = '';
                let streetName = '';
                let adressCity = '';
                let postalCode = '';
                for (let comp of response.results[0].address_components) {
                    if (comp.types.includes("street_number")) {
                        address_components.street_number = comp.long_name;
                        streetNumber = comp.long_name;
                    } 
                    if (comp.types.includes("route")){
                        address_components.route = comp.long_name;
                        streetName = comp.long_name;
                    } 
                    if (comp.types.includes("administrative_area_level_1")) address_components.region = comp.long_name;
                    if (comp.types.includes("administrative_area_level_2")) address_components.department = comp.long_name;
                    if (comp.types.includes("locality")) {
                        address_components.city = comp.long_name;
                        adressCity = comp.long_name;
                    } 
                    if (comp.types.includes("country")) address_components.country = comp.long_name;
                    if (comp.types.includes("postal_code")) {
                        address_components.postal_code = comp.long_name;
                        postalCode = comp.long_name;
                    } 
                }

                let officeAddressStreet = streetNumber + ' ' + streetName;

                if (officeAddressStreet.length < 2) {
                    this.form.setError('office_address_street', 'Veuillez controler votre adresse');
                    return false;
                }

                if (postalCode.length < 2) {
                    this.form.setError('office_address_street', 'Veuillez controler votre adresse');
                    return false;
                }

                let data = Object.assign({}, this.form.getRawData(), { 
                    office_address_street: officeAddressStreet, 
                    office_address_city: adressCity, 
                    office_address_postcode: postalCode,
                    location: {
                        type: "Point", 
                        coordinates: [response.results[0].geometry.location.lng, response.results[0].geometry.location.lat]
                    },
                    address_components
                })  

                cbk(data);

                return isFormValid;
            }
        ).catch(() => {
            this.form.setError('office_address_street', 'Veuillez controler votre adresse');
            return false;
        })
	}

    updateMap(street = "", city = "", postcode = "") {
        const fullAddress = street + " " + city + " " + postcode;

        this.setState({ loadingGMaps: true });
        if (this.loadMapCbk) this.loadMapCbk();

        Geocode.fromAddress(fullAddress)
            .then((response) => {
                const { lat, lng } = response.results[0].geometry.location;

                this.setState({ loadingGMaps: false });

                var region, department, city, country, street_number, route, postal_code;
                if (response.results[0] && response.results[0].address_components) {
                    for (let comp of response.results[0].address_components) {
                        if (comp.types.includes("street_number")) street_number = comp.long_name;
                        if (comp.types.includes("route")) route = comp.long_name;
                        if (comp.types.includes("administrative_area_level_1")) region = comp.long_name;
                        if (comp.types.includes("administrative_area_level_2")) department = comp.long_name;
                        if (comp.types.includes("locality")) city = comp.long_name;
                        if (comp.types.includes("country")) country = comp.long_name;
                        if (comp.types.includes("postal_code")) postal_code = comp.long_name;
                    }
                }

                // Store Geoloc data
                this.setState({ lat, lng, region, department, city, country, street_number, route, postal_code, street });

                if (this.fixMapCbk) this.fixMapCbk(lat, lng);

                this.forceUpdate();
        }, (error) => {

            this.setState({ loadingGMaps: false });
        });
    };

    updateCityAndPostalCode(street, city, postal_code) {
        this.updateMap(street, city, postal_code)
    }

    validateToScrollTop() {
        window.scrollTo(0, 0)
    }

    render() {
        // Hide or show inputs based on previously entered data
        const { submitButtoni18nId } = this.props;

        return (
            <React.Fragment>

                <Row className="step step2 mb-4 justify-content-md-center ">

                    <Col>
                        {this.map}

                        <Card className="rounded-0">
                            {this.form && 
                                <CardBody className="p-5 pt-0 text-center">

                                    {this.form.body()}

                                    <Row className="mt-5">

                                        <div className="text-center w-100 ">
                                    

                                            {(this.props.onBackCbk) &&
                                                <div className="d-inline-block align-top mb-3 mb-sm-0 mx-auto">
                                                    <div
                                                        className={"btn btn-ladda lowercase srounded pl-5 pr-5 pt-2 pb-2 bg-blue text-white mx-auto"}
                                                        onClick={() => this.props.onBackCbk(this.form.getRawData())}>

                                                        <FormattedHTMLMessage id="Previous.Step" />
                                                    </div>
                                                </div>
                                            }

                                            {(this.props.onBackCbk) && <div className="d-block d-sm-inline-block m-0 p-0 h-0">&nbsp;&nbsp;&nbsp;</div>}

                                            <div className="d-inline-block align-left mx-auto">
                                                <div onClick={this.validateToScrollTop} className="mx-auto form-buttons">
                                                    {this.form.submitButton(submitButtoni18nId, {
                                                        loading: this.state.loadingGMaps,
                                                        className: "srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green"
                                                    })}
                                                </div>
                                            </div>
                                            
                                        </div>

                                    </Row>

                                </CardBody>
                            }
                        </Card>

                    </Col>
                </Row>

            </React.Fragment>
        )
    }
}