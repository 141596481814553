import React from 'react';
import { Row, Col } from "reactstrap";
import { FormattedMessage } from 'react-intl';
import DateUtil from '../../../../util/DateUtil';
import Maths from '../../../../util/Maths';

const AccountingMonthlyTableRow = ({ day, month, accounting, switchToDayFromMonth, user }) => {

    let dayNumber = new Date(`${month}/${day + 1}/${new Date().getFullYear()}`).getDay();
    let dayName = DateUtil.nbrToDay(dayNumber);

    if (accounting) {
        const { expensesTotal, recetteBisTotal, credit_card_total, cash_total, cheque_total, third_party_payer, secu_total, retrocession_total } = accounting;

        return (
            <>
            <Row className="tableRow m-0 p-0 pl-5">
                <Col className="text-left font-weight-bold h-45px lh-45"><FormattedMessage id={`${dayName.charAt(0).toUpperCase() + dayName.slice(1)}`} />{` ${day + 1}`}</Col>
                <Col className="text-left font-weight-bold h-45px lh-45">{Maths.round(credit_card_total)}</Col>
                <Col className="text-left font-weight-bold h-45px lh-45">{Maths.round(cheque_total)}</Col>
                <Col className="text-left font-weight-bold h-45px lh-45">{Maths.round(cash_total)}</Col>
                <Col className="text-left font-weight-bold h-45px lh-45">{Maths.round(secu_total + third_party_payer)}</Col>
                {user.role !== "doctor" && <Col className="text-left font-weight-bold h-45px lh-45">{Maths.round(retrocession_total)}</Col>}
                <Col className="text-left font-weight-bold h-45px lh-45">
                    <div
                        className="mb-2"
                        style={{ fontSize: "22px", lineHeight: "54px" }}>
                        <i className="fa icon-ICON__OEIL_GRIS clickable"
                            onClick={() => switchToDayFromMonth(new Date(`${month}/${day + 1}/${new Date().getFullYear()}`))}></i>
                    </div>
                </Col>
            </Row>
            {(recetteBisTotal > 0) &&
                <Row className="tableRow tableRowSubTotal topInnerShadow m-0 p-0 pl-5">
                    <Col className="text-left font-weight-bold"><FormattedMessage id="Your.Total.Gains" /></Col>
                    <Col className="text-left font-weight-bold"></Col>
                    <Col className="text-left font-weight-bold"></Col>
                    <Col className="text-left font-weight-bold"></Col>
                    {user.role !== "doctor" && <Col className="text-left font-weight-bold"></Col>}
                    <Col className="text-left font-weight-bold">{recetteBisTotal}</Col>
                    <Col className="text-left font-weight-bold"></Col>
                </Row>}
            {(expensesTotal < 0) &&
                <Row className={"tableRow tableRowSubTotal m-0 p-0 pl-5 " + ((recetteBisTotal <= 0) ? "bothInnerShadow" : "botInnerShadow")} >
                    <Col className="text-left font-weight-bold"><FormattedMessage id="Your.Total.Expenses" /></Col>
                    <Col className="text-left font-weight-bold"></Col>
                    <Col className="text-left font-weight-bold"></Col>
                    <Col className="text-left font-weight-bold"></Col>
                    {user.role !== "doctor" && <Col className="text-left font-weight-bold"></Col>}
                    <Col className="text-left font-weight-bold">{expensesTotal}</Col>
                    <Col className="text-left font-weight-bold"></Col>
                </Row>}
            </>
        );
    }

    return (
        <>
        <Row className="tableRow m-0 p-0 pl-5">
            <Col className="text-left font-weight-bold h-45px lh-45"><FormattedMessage id={`${dayName.charAt(0).toUpperCase() + dayName.slice(1)}`} />{` ${day + 1}`}</Col>
            <Col className="text-left font-weight-bold h-45px lh-45">-</Col>
            <Col className="text-left font-weight-bold h-45px lh-45">-</Col>
            <Col className="text-left font-weight-bold h-45px lh-45">-</Col>
            {user.role !== "doctor" && <Col className="text-left font-weight-bold h-45px lh-45">-</Col>}
            <Col className="text-left font-weight-bold h-45px lh-45">-</Col>
            <Col className="text-left font-weight-bold h-45px lh-45">
                <div
                    className="mb-2"
                    style={{ fontSize: "22px", lineHeight: "54px" }}>
                    <i className="fa icon-ICON__OEIL_GRIS clickable"
                        onClick={() => switchToDayFromMonth(new Date(`${month}/${day + 1}/${new Date().getFullYear()}`))}></i>
                </div>
            </Col>
        </Row>
        </>
    );
}

export default AccountingMonthlyTableRow;