
import React from 'react';


export default class CGUs extends React.Component {

    render() {

        return (
            <>

            <h1 className="w-100 text-center">CONDITIONS GENERALES D’UTILISATION</h1>
            <h5 className="w-100 text-center mb-5">Version en vigueur au 3 Mars 2021</h5>

            <p>L’utilisation du Site édité par la société HYDROGEN www.hydrogen-rempla.com et de son Contenu implique sans réserve l’acceptation des présentes conditions générales d’utilisation (ci-après les « CGU ») par toute personne physique visitant ledit site et désirant, le cas échéant, accéder aux Services qui y sont proposés, la dernière version en date faisant foi. </p>

            <h3 className="mt-5 mb-5">ARTICLE 1.	IDENTIFICATION LÉGALE</h3>

            <p>Le Site (tel que défini ci-après) est édité par la société HYDROGEN, société par actions simplifiée au capital de 7.500,00 Euros, inscrite au Registre du Commerce et des Sociétés de GRASSE sous le numéro 877 575 597, ayant son siège social sis 1047 Route des Dolines – Allée Pierre ZILLER – 06560 VALBONNE, titulaire exclusive de l’ensemble des droits de propriété intellectuelle sur le Site et son Contenu (ci-après désignée « HYDROGEN »).</p>

            <h3 className="mt-5 mb-5">ARTICLE 2.	DÉFINITIONS</h3>

            <p>Les termes suivants, commençant par une lettre capitale, qu'ils soient employés au singulier ou au pluriel dans les présentes, y compris au préambule ci-dessus, auront toujours la définition suivante :</p>

            <p>2.1.	Client : désigne tout Médecin installé ou Etablissement de santé ayant souscrit à l’une des offres payantes proposées par HYDROGEN ;</p>

            <p>2.2.	Compte : désigne le compte créé par l’Utilisateur directement à partir du Site conformément aux conditions et modalités indiquées aux présentes et sur le Site, à partir duquel l’Utilisateur peut accéder aux Services ;</p>

            <p>2.3.	Contenu : désigne l’ensemble des outils, méthodes, logiciels, algorithmes, savoir-faire, contenu éditorial (notamment textes, photographies, images, dessins, etc.), illustrations et d’une manière générale toute œuvre ou création susceptible d'appropriation intellectuelle utilisé dans le cadre de la publication du Site ;</p>

            <p>2.4.	Contrat de Remplacement : désigne le Service proposé par HYDROGEN consistant à agréger sur un modèle de contrat de remplacement standard les Données fournies par deux Utilisateurs, un Médecin installé et un Remplaçant, et relatives à ces derniers ainsi qu’au Remplacement concerné ;</p>

            <p>2.5.	Donnée : désigne toute donnée personnelle au sens du Règlement européen 2016/679 du 27 avril 2016 relatif à la protection des personnes à l'égard du traitement des données à caractère personnel et de la libre circulation des données (RGPD) collectées, traitées et hébergées par HYDROGEN ;</p>

            <p>2.6.	Établissement de santé : désigne toute personne morale ayant une activité d’établissement de santé et employant des médecins diplômés légalement autorisé à exercer ladite activité, en étant à jour du paiement de l’ensemble de ses cotisations professionnelles disposant, à ce titre, d’un identifiant RPPS délivré par l’Ordre National des Médecins français qui s’inscrit sur le Site en vue de bénéficier des Services d’HYDROGEN et qui a la possibilité de souscrire à l’une des offres payantes proposées par HYDROGEN sur son Site ;</p>

            <p>2.7.	Licence : désigne la licence de remplacement délivrée par l’Ordre National des Médecins français ;</p>

            <p>2.8.	Médecin installé : désigne tout Utilisateur ayant la qualité de médecin diplômé, exerçant à titre individuel, de manière groupée ou sous forme de société, légalement autorisé à exercer ladite activité, en étant à jour du paiement de l’ensemble de ses cotisations professionnelles disposant, à ce titre, d’un identifiant RPPS délivré par l’Ordre National des Médecins français, qui s’inscrit sur le Site en vue de bénéficier des Services d’HYDROGEN et qui a la possibilité de souscrire à l’une des offres payantes proposées par HYDROGEN sur son Site ;</p>

            <p>2.9.	Messages : désigne tout message, recherche, demande, information, commentaire, annonce, disponibilité, proposition, image, texte, vidéo, etc. postés sur la Site et/ou partagé par l’Utilisateur via celui-ci et qui l’est sous sa seule responsabilité ;</p>

            <p>2.10.	Parties : désigne tout Utilisateur et HYDROGEN, dont l'identité légale est spécifiée ci-avant ainsi que dans les mentions légales du Site ;</p>

            <p>2.11.	Remplaçant : désigne tout médecin diplômé légalement autorisé à exercer ladite activité (en ce compris les internes, qu’ils aient ou non obtenu leur thèse, dès lors qu’ils possèdent une Licence), en étant à jour du paiement de l’ensemble de ses cotisations professionnelles disposant, à ce titre, d’un identifiant RPPS délivré par l’Ordre National des Médecins français qui s’inscrit sur le Site en vue de rechercher ou de publier une offre de Remplacement, collaboration, association ou succession ;</p>

            <p>2.12.	Remplacement : désigne toute période pour laquelle :
                a.	un Médecin installé qui se trouve dans l’impossibilité d’assurer lui-même ses consultations ou l’un des médecins salarié d’un Etablissement de santé d’assurer les siennes va indiquer sur le Site être en recherche d’un Remplaçant ;
                b.	un Remplaçant va indiquer sur le Site qu’il est disponible pour réaliser les consultations d’un Médecin installé ou de l’un des médecins salariés d’un Etablissement de santé. 
                Les Remplacements sont donc indiqués à la fois par le Remplaçant sous forme de périodes de disponibilité dans son calendrier et par le Médecin installé ou l’Etablissement de santé sous forme de période de vacance ;
            </p>

            <p>2.13.	Services : désigne l’ensemble des Services fournis par HYDROGEN proposant la mise en relation entre des Médecins installés ou des Etablissement de santé, avec des Remplaçants, disponibles pour travailler dans le cadre de Remplacements, collaborations, associations ou successions. Les Services sont définis plus précisément sur le Site. Les Médecins installés et les Etablissements de santé ont également la possibilité de souscrire aux services payants d’HYDROGEN selon les conditions et modalités indiquées sur le Site et dans les conditions générales de vente d’HYDROGEN (ci-après les « CGV ») ;</p>

            <p>2.14.	Site : désigne l’ensemble des programmes informatiques et solutions logicielles ainsi que le Contenu, formant ensemble le site internet disponible à l’adresse suivante : www.hydrogen-rempla.com ;</p>

            <p>2.15.	Utilisateur : désigne tout Médecin installé, Etablissement de santé ou Remplaçant qui navigue sur le Site, en vue d’accéder aux Services proposés par HYDROGEN sur son Site.</p>


            <h3 className="mt-5 mb-5">ARTICLE 3.	ACCEPTATION DE L’UTILISATEUR</h3>

            <p>Les présentes CGU sont expressément agréés et acceptées par l’Utilisateur, qui déclare et reconnaît en avoir une parfaite connaissance. </p>

            <p>En tout état de cause, tout commencement dans l’utilisation du Site soumet l’Utilisateur aux présentes CGU. L’Utilisateur est dès lors réputé accepter l’intégralité des règles stipulées aux présentes, ainsi que celles qui seraient présentes dans tout document disponible sur le Site, intégré aux présentes CGU par référence et qui régissent sa relation avec les tiers et HYDROGEN.</p>

            <p>Les présentes CGU sont opposables pendant toute la durée d'utilisation du Site par l’Utilisateur et jusqu'à ce que de nouvelles CGU remplacent les présentes.</p>

            <p>L’Utilisateur reconnaît également avoir consulté et pris connaissance des mentions légales figurant sur le Site.</p>

            <h3 className="mt-5 mb-5">ARTICLE 4.	CONVENTION DE PREUVE</h3>

            <p>L’Utilisateur reconnaît que les enregistrements et sauvegardes (en ce compris toute donnée de connexion) réalisés sur le Site (ci-après les « Documents Electroniques ») auront pleine valeur probante entre l’Utilisateur et HYDROGEN. Ainsi, les Documents Electroniques (y compris leur date et heure) feront foi entre les Parties à tout litige.</p>

            <p>L’Utilisateur reconnaît par conséquent, dans ses relations contractuelles avec HYDROGEN, la validité et la force probante des courriers électroniques.</p>

            <p>De même, les notifications effectuées via le Site sont opposables à l’Utilisateur en tant qu’éléments de preuve.</p>

            <h3 className="mt-5 mb-5">ARTICLE 5.	EVOLUTIONS</h3>

            <p>HYDROGEN se réserve le droit de modifier les présentes CGU, selon l’évolution technique du Site ou de ses offres de Services, ou en raison de l’évolution de la législation, à sa seule discrétion.</p>

            <p>D’une manière générale, l’utilisation du Site par l’Utilisateur est toujours soumise à la version la plus récente des CGU, accessible à l’Utilisateur au moment de cette utilisation. Il appartient à l’Utilisateur de consulter aussi souvent que nécessaire les CGU, accessibles à partir du Site, y compris depuis son Compte.</p>

            <h3 className="mt-5 mb-5">ARTICLE 6.	VIOLATION</h3>

            <p>Toute violation des présentes CGU autorise HYDROGEN à refuser pour l’avenir à l’Utilisateur auteur de la violation considérée l’accès au Site, ainsi qu’à clôturer tout Compte lui permettant d’accéder aux Services, sans préjudice des indemnités qui pourraient être réclamées à l’auteur de ladite violation par HYDROGEN.</p>

            <h3 className="mt-5 mb-5">ARTICLE 7.	DOMAINE D’APPLICATION DES CONDITIONS GENERALES D’UTILISATION</h3>

            <p>Les présentes CGU sont rédigées en langue française. Elles précisent notamment les conditions d’utilisation du Site par l’Utilisateur ainsi que les différentes étapes nécessaires à la création par ce dernier d’un Compte.</p>

            <h3 className="mt-5 mb-5">ARTICLE 8.	COMPTES</h3>

            <h4 className="mt-4 mb-4">8.1.	Création du Compte</h4>

            <p>La création d’un Compte dans le but d’accéder aux Services qui y sont proposés s'effectue directement à partir du Site grâce au processus mis à la disposition de l'Utilisateur par HYDROGEN.</p>

            <h4 className="mt-4 mb-4">8.1.1.	Informations nécessaires à la création du Compte</h4>

            <p>Afin de créer son Compte et de pouvoir passer Commande des Services, l'Utilisateur doit remplir le formulaire de création de compte disponible sur le Site et compléter l’ensemble des champs pour lesquels les informations demandées sont obligatoires pour la création de son Compte.</p>

            <p>A ce titre, l’Utilisateur garantit que toutes les informations qu’il renseigne dans le formulaire de création de compte sont véridiques et sincères. L’Utilisateur s’engage également à toujours maintenir à jour les informations qui figurent sur son Compte, notamment ses périodes disponibles pour des Remplacements, et en procédant à l’abandon du Remplacement en cas d’annulation de celui-ci par l’un des deux Utilisateurs.</p>

            <p>Dans ce cadre, il s’engage également à modifier son Compte dans les plus brefs délais suite à toute modification de sa situation administrative ou professionnelle par rapport aux informations qu’il aura précédemment fournies dans le cadre de la création de son Compte (changement de locaux ; installation ; mise en place d’un secrétariat ; collaboration ; logiciel ; pratique médicale ; diplômes ; etc.).</p>

            <p>HYDROGEN se réserve le droit de refuser une demande d'inscription au Site par tout Utilisateur qui ne souhaite pas se conformer aux CGU ou qui ne fournit pas entièrement, ou de bonne foi, les informations demandées. Dans ce cadre, HYDROGEN pourra procéder à des vérifications complémentaires pour toute inscription d’un Utilisateur pour lequel les informations fournies présentent un manque de cohérence.</p>

            <p>HYDROGEN pourra alors demander à l’Utilisateur de lui fournir une copie de sa carte de professionnel de santé et/ou d’un justificatif de domicile professionnel et/ou de leur Licence pour les Remplaçants, ou de tout autre document de nature à attester de son identité et de sa qualité de médecin. Dans l’hypothèse où le contrôle réalisé par HYDROGEN fait apparaître une ou plusieurs incohérence(s), HYDROGEN pourra :</p>
            <ul>
                <li>Proposer des modifications à l’Utilisateur s’il apparaît que ces incohérences sont dues à des erreurs de l’Utilisateur lors de la création de son Compte ;</li>
                <li>Suspendre puis éventuellement supprimer le Compte de l’Utilisateur dans l’hypothèse où la vérification réalisée par HYDROGEN révèle un usage contraire aux présentes CGU par l’Utilisateur concerné..</li>
            </ul>

            <p>Les paramètres de connexion permettant l'accès à leurs Comptes ne sont pas envoyés par HYDROGEN aux Utilisateurs. Dès lors, les Utilisateurs sont responsables de la vérification de la validité de l'adresse e-mail fournie lors de leur enregistrement sur le Site, dans la mesure où cette adresse e-mail fait partie de l'identification à leur Compte. Dans le cas où l'adresse e-mail est incorrecte, l'Utilisateur peut ne pas être en mesure d'accéder à son Compte, sans que HYDROGEN ne puisse en être tenue pour responsable.</p>

            <h4 className="mt-4 mb-4">8.1.2.	Validation et mise à disposition du Compte</h4>

            <p>Un e-mail qui confirme l’inscription est envoyé à l’adresse indiquée lors de la création du Compte par l’Utilisateur contenant son identifiant ainsi que les différentes informations qu’il a indiquées.</p>

            <p>La validation du Compte se fait après vérification des informations fournies et validation du Compte par HYDROGEN, par e-mail à la suite de la fourniture par l’Utilisateur des informations suscitées. Il reçoit un second message (par mail et par SMS) quand son compte est validé et qu’il peut désormais naviguer sur le Site et accéder au Services.</p>

            <p>Une fois la création du Compte validée, l’Utilisateur peut immédiatement l’utiliser.</p>

            <h4 className="mt-4 mb-4">8.1.3.	Génération du mot de passe</h4>

            <p>Lors de la création de son Compte, l’Utilisateur choisit lui-même son mot de passe personnel. L’Utilisateur n’a pas d’obligation de modifier son mot de passe lors de sa première connexion.</p>

            <h4 className="mt-4 mb-4">8.1.4.	Contenu du Compte</h4>

            <p>Les Utilisateurs accèdent à leur Compte, sous la forme d'une interface personnalisée, après que les administrateurs d’HYDROGEN aient activé leurs paramètres de connexion (identifiant et mot de passe choisi lors de leur inscription sur le Site). </p>

            <p>A partir de ce Compte, les Utilisateurs ont accès aux Services, au suivi et à l’historique de leurs Remplacements.</p>

            <p>Pour plus d'informations sur le contenu exact et complet de ce Compte, les Utilisateurs sont invités à consulter le Site et à créer un Compte.</p>

            <h4 className="mt-4 mb-4">8.2.	Responsabilité des paramètres de connexion</h4>

            <p>Il appartient à chaque Utilisateur de modifier, dès sa première connexion, puis de manière régulière et au moins tous les trois (3) mois, le mot de passe qu’il s’est lui-même choisi au moment de son enregistrement sur le Site et de s’assurer que son code est composé de lettres et de chiffres de longueur suffisante.</p>

            <p>Chaque Utilisateur doit garder ses paramètres de connexion confidentiels. A ce titre, il est rappelé que les paramètres de connexion de l’Utilisateur sont à usage unique et strictement personnel.</p>

            <p>Ce dernier est responsable de toutes leurs utilisations, qu’il les ait ou non effectivement ou expressément autorisées. L’Utilisateur ne doit pas céder, prêter ou transférer ses paramètres de connexion à un tiers, ni permettre à un tiers de se connecter à son Compte.</p>

            <p>Les Utilisateurs s'engagent en outre à :</p>
            <ul>
                <li>Informer immédiatement HYDROGEN en cas d'utilisation abusive de leurs paramètres de connexion ou de Compte ou en cas de toute autre violation de la sécurité ;</li>
                <li>S’assurer d'être correctement déconnecté de son compte à la fin de chaque session en utilisant la fonction « Déconnexion ».</li>
            </ul>

            <p>HYDROGEN se réserve le droit de procéder à la désactivation de tout identifiant ou mot de passe, choisi par l’Utilisateur, à tout moment, si l’Utilisateur a enfreint l’une des dispositions des présentes CGU.</p>

            <h3 className="mt-5 mb-5">ARTICLE 9.	DESCRIPTION DES SERVICES</h3>

            <h4 className="mt-4 mb-4">9.1.	Généralités</h4>

            <p>HYDROGEN est une société spécialisée dans la gestion et l’optimisation de l’activité des professionnels de santé. Dans le cadre de son activité, elle a développé un Site permettant aux Utilisateurs d’accéder à ses Services.</p>

            <p>Sur le Site, tout Utilisateur peut :</p>
            <ul>
                <li>Naviguer sur le Site ;</li>
                <li>Accéder au Contenu du Site et le consulter ;</li>
                <li>Créer un Compte.</li>
            </ul>

            <p>Depuis son Compte, n'importe quel Utilisateur peut :</p>
            <ul>
                <li>Accéder aux Services ;</li>
                <li>Modifier ses informations personnelles ;</li>
                <li>Consulter son historique de Remplacements ;</li>
                <li>Suivre ses Remplacements en cours ;</li>
                <li>Effectuer des recherches parmi les Utilisateurs ;</li>
                <li>Proposer un Remplacement précis à un autre Utilisateur ;</li>
                <li>Contractualiser pour un Remplacement avec un autre Utilisateur.</li>
            </ul>

            <p>Depuis leur Compte, les Médecins installés peuvent également souscrire à l’une des offres payantes proposées par HYDROGEN sur son Site, dans les conditions et modalités qui y sont indiquées et conformément aux CGV.</p>

            <p>Pour plus d'informations sur le contenu exact et complet du Site et des Services, les Utilisateurs sont invités à consulter le Site.</p>

            <h4 className="mt-4 mb-4">9.2.	Contrat de Remplacement</h4>

            <p>Les modèles de Contrats de Remplacement utilisés par HYDROGEN sont rédigés sur la base des contrats-types fournis par le Conseil national de l'Ordre des médecins. Ces modèles définissent les droits et obligations réciproques des Utilisateurs et précisent les moyens mis en œuvre pour que soit assuré le respect des règles de déontologie médicale notamment en ce qui concerne l’indépendance professionnelle des médecins et le secret médical. </p>

            <p>Dans le cadre de la mise à disposition du Contrat de Remplacement, HYDROGEN rappelle qu’elle ne réalise qu’une agrégation de Données et informations fournies par les Utilisateurs sur des documents standardisés. HYDROGEN ne procède à aucune vérification des Données ainsi agrégées et celle-ci incombe uniquement et exclusivement aux Utilisateurs. Il appartient donc à chaque Utilisateur de vérifier scrupuleusement les informations indiquées sur le modèle de Contrat de Remplacement généré par le Site avant toute validation de ce dernier.</p>

            <p>Dès lors, HYDROGEN ne pourra en aucun cas être tenue responsable d’une erreur dans l’agrégation des Données et informations fournies par les Utilisateurs. De même, HYDROGEN étant un tiers au Contrat de Remplacement, sa responsabilité ne pourra être engagée au titre des difficultés pouvant intervenir lors de la conclusion ou de l’exécution des Contrats de Remplacement. Ainsi, tous litiges ou différends relatifs notamment à la validité, l’interprétation, l’exécution ou la résolution des Contrats de Remplacement, seront soumis à une conciliation confiée au Conseil départemental de l’Ordre des médecins, en application de l’article R.4127-56 du code de la santé publique (article 56 du code de déontologie médicale).</p>

            <p>Par ailleurs, HYDROGEN rappelle que tous les Contrats de Remplacement, doivent être communiqués au Conseil départemental de l'ordre des médecins dans le mois qui suit leur conclusion (article L 4113-9 du Code de la santé publique). HYDROGEN permet aux Utilisateurs d’adresser automatiquement un Contrat de Remplacement dématérialisé au Conseil de l’Ordre départemental des médecins (sur le lieu d’exercice du médecin installé), dès lors qu’il est signé par le Médecin installé et le Remplaçant. Dans ce cadre, les Utilisateurs acceptent et s’engagent à signaler immédiatement à HYDROGEN toute difficulté ou incident qu’ils pourraient rencontrer lors d’une quelconque des étapes de mise à disposition du Contrat de Remplacement, notamment lors de l’agrégation des Données et informations fournies par les Utilisateurs, du téléchargement ou de l’envoi du Contrat de Remplacement au Conseil de l’ordre.</p>

            <p>Toutefois, les Utilisateurs ont également la possibilité de fournir un contrat de remplacement au Conseil de l’ordre par leurs propres moyens. Dans ce cas, ils peuvent utiliser le modèle de Contrat de Remplacement disponible sur le site de l’Ordre national des Médecins, l’imprimer et se charger eux-mêmes de l’envoi.</p>

            <h4 className="mt-4 mb-4">9.3.	Gratuité des Services</h4>

            <p>L’utilisation des Services des présentes CGU par les Médecins Installés et les Remplaçants est gratuite.</p>

            <h3 className="mt-5 mb-5">ARTICLE 10.	Règles d’usage</h3>

            <p>Tout Message publié via le Site l’est sous la seule responsabilité de l’Utilisateur et doit être rédigé dans un langage correct et compréhensible par tous, ce qui exclut expressément toute utilisation du langage dit « SMS ».</p>

            <p>Pour une lisibilité optimale du contenu du Site, l’usage des minuscules est fortement recommandé. En effet, les majuscules ou l’accentuation de signes de ponctuation peuvent donner l’impression d’une hausse de ton, néfaste au bon fonctionnement du Site.</p>

            <p>Le contenu du Message posté et/ou partagé via le Site doit être le plus clair et lisible possible afin que les Utilisateurs concernés puissent le comprendre, et le cas échéant y répondre. Il doit ainsi contenir des mots clés et être suffisamment précis pour que les Utilisateurs comprennent l’information que l’Utilisateur souhaite délivrer. Aussi, il est important de rédiger ce Message de manière claire et concise mais néanmoins complète.</p>

            <p>Tous les Message doivent respecter les présentes règles d’usage, et notamment :</p>
            <ul>
                <li>Respecter la législation en vigueur,</li>
                <li>Ne pas porter atteinte à la vie privée d’autrui,</li>
                <li>Ne pas tenir des propos illégaux, notamment à caractère violent, injurieux, diffamatoires, racistes, discriminant, révisionnistes, faisant l’apologie des crimes de guerre, pédophiles, incitant au meurtre, au suicide, à la discrimination ou à la haine, etc.,</li>
                <li>Ne pas reproduire, représenter ou diffuser de contenu qui serait la reproduction sans autorisation d’une œuvre, d’une marque, d’un logo, etc. protégée par des droits de propriété intellectuelle ou industrielle,</li>
                <li>Ne pas inclure de messages incitant au prosélytisme et affichant les croyances religieuses et/ou politiques de l’Utilisateur,</li>
                <li>Ne pas dénoncer ou humilier une personne en la nommant directement via le Site,</li>
                <li>Ne pas lancer de sujets à pétition sans lien avec l’objet du Site.</li>
            </ul>

            <p>L’Utilisateur s’engage donc à adopter une attitude respectueuse en toutes circonstances.</p>

            <h4 className="mt-4 mb-4">10.1.	Droits et devoirs de l’Utilisateur</h4>

            <p>L’Utilisateur est le seul et unique responsable des Messages qu’il publie sur le Site. Dans ce cadre, il est rappelé à l’Utilisateur que ses Messages sont destinés à être lus par les autres Utilisateurs, sous sa propre responsabilité, ce que l’Utilisateur accepte expressément.</p>

            <p>Dès lors, l’Utilisateur est informé que les Messages qu’il poste ou partage via le Site doivent impérativement respecter l’ensemble des règles d’usage détaillées au présent article.</p>

            <p>Par ailleurs, la reproduction, la diffusion ou toute autre acte d’utilisation ou d’exploitation, quels qu’en soient le support ou la nature, à des fins commerciales ou non, des Messages des autres utilisateurs du Site, sont soumis à l’accord préalable exprès de ces derniers.</p>

            <h4 className="mt-4 mb-4">10.2.	Responsabilité d’HYDROGEN</h4>

            <p>HYDROGEN n’entend pas garantir l’exactitude des Messages postées ou partagés via le Site, ni cautionner les opinions pouvant apparaître, les propos tenus étant uniquement l’expression de la pensée de chaque Utilisateur.</p>

            <p>HYDROGEN ne saurait être tenu civilement responsable des Messages publiés par l’Utilisateur qui est seul responsable du contenu et de l’usage possible de ces derniers. Ainsi, l’Utilisateur s’engage à garantir HYDROGEN contre toute réclamation de tiers qui se trouveraient lésés par le contenu des Messages qu’il a postés ou partagés via le Site.</p>

            <p>En revanche, l’Utilisateur peut, à tout moment, signaler à HYDROGEN tout Message ou tout comportement d’un Utilisateur qui lui semblerait abusif en envoyant un email à l’adresse suivante support@hydrogen-rempla.com; ou bien en adressant un courrier à HYDROGEN.</p>

            <p>Dans ce cas, HYDROGEN se réserve le droit discrétionnaire de signaler l’incident à la personne concernée, et dans ce cas de l’enjoindre à prendre les mesures et correctifs appropriés vis-à-vis de la personne responsable en fonction de la gravité de l’évènement, et/ou prendre elle-même les actions qu’elle estime nécessaires au maintien de la réputation et de la probité de son Site (avertissement ; suppression du Compte de l’Utilisateur en cause ; etc.)</p>

            <h4 className="mt-4 mb-4">10.3.	Accès aux Messages</h4>

            <p>La mise en ligne d’un Message est effectuée automatiquement par HYDROGEN dans ses meilleurs délais après que l’Utilisateur ait soumis son Message via le formulaire concerné.</p>

            <p>Les Messages demeurent accessibles sur le site tant que le Service concerné est accessible sur le Site, sous réserve qu’il n’y ait aucun changement de fournisseur ou des caractéristiques principales du Service rendant le Message obsolète.</p>

            <p>En publiant un Message, l’Utilisateur comprend et accepte que celui-ci soit diffusé publiquement sur le Site et accessible à tout Utilisateur.</p>

            <h4 className="mt-4 mb-4">10.4.	Responsabilité de l’Utilisateur</h4>

            <p>L’Utilisateur est seul responsable vis-à-vis des tiers pour les Messages qu'il télécharge, fournit, transfère et partage via le Site dans le cadre de l'utilisation des Services. A ce titre, l’Utilisateur est notamment seul responsable :</p>
            <ul>
                <li>Du respect de l'ordre public et des bonnes mœurs de ses Messages ;</li>
                <li>De la conformité de ces Messages avec la législation et la déontologie médicale, et en particulier en veillant à ce qu'ils ne portent pas atteinte aux droits des tiers, notamment droits de propriété intellectuelle et droits à l’image.</li>
            </ul>

            <h4 className="mt-4 mb-4">10.5.	Evaluation du site et des utilisateurs</h4>

            <p>Dans un souci d’amélioration constante de la qualité de ses Services, HYDROGEN propose à l’utilisateur de lui faire part de tous les commentaires et informations qu’il souhaiterait porter à sa connaissance concernant la qualité des Services.</p>

            <p>HYDROGEN invite les Utilisateurs à noter les caractéristiques du Remplacement via un retour d’expérience. Suite au remplacement, HYDROGEN adressera automatiquement un questionnaire d’évaluation aux Utilisateurs concernés. Cette évaluation se fait entre pairs, via une notation analogique, sans possibilité rédactionnelle, dans un souci de bienveillance. Les notes détaillées par items et la note globale, correspondant à la moyenne des items, seront visibles par les Utilisateurs dès lors qu’HYDROGEN choisira de les afficher.</p>

            <p>HYDROGEN se donne le droit de modifier à tout instant les items et le contenu de ces évaluations. HYDROGEN n’est pas responsable de l’évaluation des Utilisateurs qui s’engagent à noter, de bonne foi et de la manière la plus objective possible, les caractéristiques du Remplacement.</p>

            <p>HYDROGEN n’intervient en aucun cas dans ces évaluations et se contente de les pondérer suivant le nombre de Remplacements réalisés via le Site. En conséquence, toute réclamation éventuelle doit être dirigée en premier lieu vers l’auteur présumé des contenus en question et HYDROGEN ne peut être tenu pour responsable de l’évaluation renseignée par les Utilisateurs.</p>

            <h3 className="mt-5 mb-5">ARTICLE 11.	DONNÉES PERSONNELLES</h3>

            <p>HYDROGEN s’engage à ce que les données à caractère personnel de l’Utilisateur soient collectées et traitées conformément à la loi n° 78-17 du 6 janvier 1978 modifiée relative à l'informatique, aux fichiers et aux libertés dite « Informatique et Libertés », et au Règlement (UE) 2016/679 du Parlement et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du Traitement des données à caractère personnel et à la libre circulation de ces données, et à toute législation prise en application de ce Règlement.</p>

            <p>Pour en savoir plus sur le traitement de ses données Personnelles, l’Utilisateur est invité à consulter la Politique de traitement des données personnelles de HYDROGEN.</p>

            <h3 className="mt-5 mb-5">ARTICLE 12.	PROPRIÉTÉ INTELLECTUELLE</h3>

            <h4 className="mt-4 mb-4">12.1.	Licence d’utilisation du Site et de son Contenu</h4>

            <p>Les articles ci-dessous présentent les règles spéciales régissant la Licence concédée à l’Utilisateur. Il est précisé que les autres stipulations plus générales des présentes CGU sont également applicables à ladite concession dont elles sont le complément nécessaire.</p>

            <p>Dans le cadre de l’utilisation du Site, l’Utilisateur devra se conformer à la procédure indiquée et aux règles d’utilisation indiquée par HYDROGEN.</p>

            <p>HYDROGEN concède à l’Utilisateur, qui l’accepte, pour la durée et le territoire prévus ci-après, une licence non-exclusive, non-transférable et non cessible d’utilisation du Site et de son Contenu, expressément limitée aux actes nécessaires à l’utilisation des Services offerts par le Site et pour permettre leur délivrance à l’Utilisateur, dans la limite des Services effectivement fournis par HYDROGEN sur son Site (ci-après la « Licence »).</p>

            <p>L’Utilisateur s’interdit expressément tout autre acte d’utilisation ou d’exploitation, et notamment :</p>
            <ul>
                <li>Créer des liens vers d’autres sites ;</li>
                <li>Toute modification, traduction, adaptation, arrangement, tests ou analyses du Site ou de son Contenu ;</li>
                <li>Toute reproduction à destination de tiers ;</li>
                <li>Préparer des œuvres dérivées à partir de, modifier, copier ou utiliser le Site ou son Contenu de toute manière, à l’exception de ce qui est expressément autorisé dans les présentes CGU ;</li>
                <li>Supprimer ou altérer les mentions d’identification ou de propriété du Site ou de son Contenu.</li>
            </ul>

            <p>Dans l’hypothèse où l’Utilisateur envisage d’utiliser le Site ou son Contenu à des fins autres que celles prévues aux présentes, celui-ci en avertira par écrit HYDROGEN qui pourra, à sa seule discrétion, refuser ou autoriser cette utilisation selon les termes et conditions qu’elle définira.</p>

            <p>La présente Licence est concédée pour l'utilisation du Site et de son Contenu à l’Utilisateur pour tous les territoires du monde entier pour une période de temps limitée à la durée d’utilisation du Site et de son Contenu par l’Utilisateur.</p>

            <h4 className="mt-4 mb-4">12.2.	Garantie de jouissance paisible</h4>

            <p>HYDROGEN déclare qu’elle détient ou dispose de tous les droits de propriété intellectuelle nécessaires à l’exécution du Contrat et que rien, en conséquence, ne s’oppose à leur concession à l’Utilisateur.</p>

            <p>HYDROGEN garantit également l’absence de nécessité pour l’Utilisateur d’obtenir d’autres licences, cessions ou concessions de droits de propriété intellectuelle d’aucune sorte en vue de l’utilisation des Contenus.</p>

            <p>HYDROGEN garantit l’Utilisateur contre toute action en contrefaçon ou toute action tendant à interdire l'utilisation du Site ou de son Contenu. Ainsi, HYDROGEN prendra à sa charge tous dommages et intérêts et frais y afférents (tels que les frais de défense de ses droits en justice) auxquels pourrait être condamné l’Utilisateur par une décision de justice devenue définitive et qui auraient pour base exclusive la démonstration d'une contrefaçon ou d’une atteinte à des droits de propriété intellectuelle qui se rattacherait à l’utilisation des Contenus. </p>

            <p>Les dispositions ci-dessus sont soumises aux conditions cumulatives suivantes :</p>
            <ul>
                <li>que l’Utilisateur ait notifié à bref délai, l'action en justice ou la déclaration ayant précédé cette action, et,</li>
                <li>qu’HYDROGEN ait été en mesure d'assurer librement la défense de ses propres intérêts et ceux de l’Utilisateur et que, pour ce faire, l’Utilisateur ait collaboré loyalement à ladite défense en fournissant, en temps utile, tous les éléments, informations et assistance nécessaires pour mener à bien une telle défense qui auraient été demandés par HYDROGEN. </li>
            </ul>

            <p>Dans le cas où l'interdiction d'utilisation de tout ou partie des éléments composant le Site ou les Contenus serait prononcée en conséquence d'une action en contrefaçon ou d’une action fondée sur une atteinte à des droits de propriété intellectuelle, ou résulterait d'une transaction signée avec le demandeur à l'action précitée, HYDROGEN s'efforcera, à son choix et à ses frais :</p>

            <ul>
                <li>soit d'obtenir le droit pour l’Utilsateur de poursuivre l'utilisation des éléments visés ;</li>
                <li>soit de remplacer les éléments de façon à éviter les manquements précités.</li>
            </ul>

            <h4 className="mt-4 mb-4">12.3.	Respect des droits de propriété intellectuelle</h4>

            <p>HYDROGEN conserve la propriété de tous les droits de propriété intellectuelle qui sont utilisés pour faire fonctionner le Site, et plus généralement tous les éléments reproduits ou utilisés sur ces derniers sont protégés au titre de la propriété intellectuelle, y compris le Contenu (ci-après ensemble les « Eléments »).</p>

            <p>Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces Eléments, sans l'accord préalable et écrit de HYDROGEN, sont strictement interdites.</p>

            <p>Le fait pour HYDROGEN de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites.</p>

            <h4 className="mt-4 mb-4">12.4.	Respect du droit d’auteur - Copyright © - Liens (appartenant à HYDROGEN ou à ses partenaires)</h4>

            <p>Le Site respecte le droit d'auteur, ainsi que les droits voisins (ci-après les « Droits d’Auteur »). Tous les droits d’auteur des œuvres protégées reproduites et communiquées sur le Site sont réservés pour le monde entier. Sauf autorisation, toute utilisation des œuvres autres que la reproduction et la consultation individuelles et privées sont interdites.</p>

            <h4 className="mt-4 mb-4">12.1.1.	 Reproduction sur support papier</h4>

            <p>La reproduction (impression) des pages du Site sur un support papier est autorisée pour un usage strictement privé et non commercial.</p>

            <h4 className="mt-4 mb-4">12.1.2.	Reproduction sur support électronique</h4>

            <p>La reproduction de tout ou partie du Site sur un support électronique est interdite.</p>

            <h4 className="mt-4 mb-4">12.5.	Liens</h4>

            <h5>12.4.1.	Création de liens vers le Site</h5>

            <p>Le Site autorise la mise en place d’un lien hypertexte pointant vers son Contenu, sous réserve de : </p>
            <ul>
                <li>Ne pas utiliser la technique du lien profond ("deep linking"), c'est-à-dire que les pages du Site ne doivent pas être imbriquées à l'intérieur des pages d'un autre site, mais accessibles par l’ouverture d’une fenêtre ;</li>
                <li>Mentionner la source qui pointera grâce à un lien hypertexte directement sur le Contenu visé ;</li>
                <li>Les informations utilisées ne doivent l'être qu'à des fins personnelles, associatives ou professionnelles ;</li>
                <li>Toute utilisation à des fins commerciales ou publicitaires est exclue, sauf accord exprès de HYDROGEN ;</li>
                <li>Ces autorisations ne s’appliquent pas aux sites internet diffusant des informations à caractère polémique, pornographique, incitant à la haine raciale ou à toute forme de discrimination ou pouvant, dans une plus large mesure, porter atteinte à la sensibilité du plus grand nombre ;</li>
                <li>Pour d'autres utilisations, veuillez consulter HYDROGEN.</li>
            </ul>

            <h4 className="mt-4 mb-4">12.6.	Limitations</h4>

            <p>Toutes les marques et autres droits de propriété intellectuelle sur tout Contenu du Site (incluant notamment les informations, textes, images, droits d’auteur, dessins et modèles) et sur la structure du Site appartiennent à HYDROGEN. Aucune utilisation de ce Contenu et des droits de propriété intellectuelle y afférant n'est autorisée sans avoir préalablement requis le consentement écrit et exprès de HYDROGEN. Tous ces droits sont expressément réservés.</p>

            <p>L’Utilisateur s'engage à respecter les droits de propriété intellectuelle de HYDROGEN sur chacun des éléments constituant le Site et à faire respecter ces droits par les tiers.</p>

            <p>L’Utilisateur s'engage à informer sans délai HYDROGEN de toute atteinte aux droits de propriété intellectuelle de cette dernière qu'il pourra éventuellement constater. En particulier, l’Utilisateur s’interdit de :</p>
            <ul>
                <li>Vendre, publier, afficher, divulguer ou mettre à la disposition de tout tiers le Contenu du Site mis à disposition par HYDROGEN ;</li>
                <li>Effectuer l'ingénierie inverse, décompiler ou désassembler le Contenu du Site, sauf si expressément autorisé par la loi ;</li>
                <li>Utiliser ou copier le Contenu du Site d'une manière qui n'est pas expressément autorisée par HYDROGEN ;</li>
                <li>Louer, prêter ou utiliser le Contenu du Site dans le but de le partager ou de le mettre à la disposition des tiers ;</li>
                <li>Publier tout test ou analyse ou évaluation de la performance relative au Contenu du Site sans autorisation écrite préalable ;</li>
                <li>Accéder aux codes sources du logiciel caractérisant le Site.</li>
            </ul>

            <p>L'Utilisateur s'engage à informer immédiatement HYDROGEN de toute atteinte à la propriété intellectuelle qu'il pourrait remarquer.</p>

            <h3 className="mt-5 mb-5">ARTICLE 13.	RESPONSABILITÉ</h3>

            <p>HYDROGEN ne pourra être tenue responsable de l’utilisation que font les Utilisateurs du Site. Ces derniers reconnaissent et acceptent qu’ils seront seuls responsables en cas d’utilisation du Site contraire à l’ordre public, à la déontologie médicale, aux bonnes mœurs ou à toute loi ou règlement.</p>

            <p>HYDROGEN s’engage à veiller au bon fonctionnement du Site, cependant sa responsabilité ne saurait être engagée pour tous les inconvénients ou dommages inhérents à l'utilisation du réseau Internet, notamment une rupture de service, une intrusion extérieure ou la présence de virus informatiques.</p>

            <h3 className="mt-5 mb-5">ARTICLE 14.	FORCE MAJEURE</h3>

            <p>Les Parties ne pourront être tenues pour responsables si la non-exécution ou le retard dans l'exécution d'une quelconque de leurs obligations, telles que décrites dans les présentes découle d'un cas de force majeure, au sens de l'article 1218 du Code civil et de la jurisprudence des juridictions françaises.</p>

            <p>La Partie constatant l'événement devra sans délai informer l'autre partie de son impossibilité à exécuter sa prestation et s'en justifier auprès de celle-ci. La suspension des obligations ne pourra en aucun cas être une cause de responsabilité pour non-exécution de l'obligation en cause, ni induire le versement de dommages et intérêts ou pénalités de retard.</p>

            <p>L'exécution de l'obligation est suspendue pendant toute la durée de la force majeure si elle est temporaire et ne dépasse pas une durée de trente (30) jours. Par conséquent, dès la disparition de la cause de la suspension de leurs obligations réciproques, les Parties feront tous leurs efforts pour reprendre le plus rapidement possible l'exécution normale de leurs obligations contractuelles. A cet effet, la Partie empêchée avertira l'autre de la reprise de son obligation par lettre recommandée avec demande d'avis de réception ou tout acte extrajudiciaire. Si l'empêchement est définitif ou dépasse une durée de trente (30) jours, les présentes seront purement et simplement résolues.</p>

            <p>Pendant cette suspension, les Parties conviennent que chacune d’elles prendra à sa charge ses propres frais engendrés par la situation.</p>

            <h3 className="mt-5 mb-5">ARTICLE 15.	NOTIFICATION</h3>

            <p>Toute notification requise aux termes des présentes conditions devra être envoyée à l'adresse donnée en tête de ce document s’agissant de HYDROGEN, et à l’adresse renseignée par l’Utilisateur lors de la création de son Compte, régulièrement mise à jour le cas échéant.</p>

            <p>Toutes notifications effectuées en application des présentes seront valablement effectuées et seront considérées comme reçues dans les conditions suivantes :</p>

            <ul>
                <li>E-mail : au jour d'envoi ;</li>
                <li>Lettre recommandée AR - le lendemain de la date portée sur l’accusé de réception.</li>
            </ul>

            <h3 className="mt-5 mb-5">ARTICLE 16.	LITIGE ET JURIDICTION </h3>

            <p>Les présentes CGU sont soumises au droit Français.</p>

            <p>En vue de trouver ensemble une solution à tout litige qui surviendrait dans l'exécution des présentes, les Parties conviennent de se concerter, en présentielle ou à distance, dans un délai de TRENTE (30) jours à compter de la réception d'une lettre recommandée avec demande d'avis de réception, notifiée par l'une des deux Parties, ou d’un courriel signé numériquement.</p>

            <p>Si au terme d'un nouveau délai de trente (30) jours, les Parties ne parvenaient pas à se mettre d'accord sur un compromis ou une solution, en cas de difficulté d’exécution des présentes et de leur suite, tous les litiges auxquels les présentes et les accords qui en découlent pourraient donner lieu, concernant tant leur validité, leur interprétation, leur exécution, leur résolution, leurs conséquences et leurs suites seront soumis au tribunal du siège social d’HYDROGEN au jour de l’assignation.</p>

            </>
        );
    }
}
