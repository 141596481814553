import React, { Component } from 'react';
import SProfileForm from './forms/SProfileForm';
import { Row, Col } from 'reactstrap';

export default class SStep2 extends Component {

    constructor(props) {
		super(props);

		this.form = React.createRef();
	}

	isValidated() {
        const updateData = (data) => {
			this.props.updateStore({substituteCharacteristic: data });
		}
		return this.form.current.validate(updateData);
	}

    back(data) {
        this.props.updateStore({ substituteCharacteristic: data });
        this.props.jumpToStep(0);
    };

    render() {
        var defaultValues = this.props.getStore().substituteCharacteristic;

        var submitCbk = (data) => {
            this.props.updateStore({ substituteCharacteristic: data });

            this.props.jumpToStep(2);

            if (window.scrollTo) window.scrollTo(0, 0);
        };

        return (
            <React.Fragment>

                <Row className="step step1 mt-2 justify-content-md-center ">
                    <Col lg="8">
                        <SProfileForm
                            defaultValues={defaultValues}
                            submitCbk={submitCbk}
                            validate={this.props.validate}
                            submitButtoni18nId={"Next.Step"}
                            onBackCbk={(data)=> this.back(data)}
                            ref={this.form}
                            />
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}