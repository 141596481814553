import React from 'react';
import { Row, Col } from "reactstrap";
import { FormattedMessage } from 'react-intl';
import Maths from '../../../../util/Maths';

const AccountingDailyTotalRow = ({ accountingTotals, user, accountings }) => {
    const { credit_card_total_consultation, cash_total_consultation, cheque_total_consultation, third_party_payer_consultation, secu_total_consultation, retrocession_total_consultation } = accountingTotals[3];
    const { totalAmount } = accountingTotals[0];
    const { expensesTotal } = accountingTotals[1];
    const { recetteBisTotal } = accountingTotals[2];

    return (
        <div className="w-100 totalFooterColor m-0 p-0 pl-5 mt-5">

            <Row className="tableRow border-0 m-0 text-left" style={{ paddingTop: '14px', paddingBottom: '12px' }} >
                <Col className="text-left font-weight-bold"><FormattedMessage id="Total.Patients" /></Col>
                <Col className="font-weight-bold"></Col>
                <Col className="text-left font-weight-bold">{credit_card_total_consultation ? Maths.round(credit_card_total_consultation) : '-'}</Col>
                <Col className="text-left font-weight-bold">{cheque_total_consultation ? Maths.round(cheque_total_consultation) : '-'}</Col>
                <Col className="text-left font-weight-bold">{cash_total_consultation ? Maths.round(cash_total_consultation) : '-'}</Col>
                <Col className="text-left font-weight-bold">{secu_total_consultation ? Maths.round(secu_total_consultation + third_party_payer_consultation) : '-'}</Col>
                {user.role !== "doctor" && <Col className="text-left font-weight-bold">{Maths.round(retrocession_total_consultation)}</Col>}
                <Col className="font-weight-bold"></Col>
            </Row>

            <Row className="tableRow border-0 m-0 text-left" style={{ paddingBottom: '12px' }}>
                <Col className="text-left font-weight-bold"><FormattedMessage id="Your.Total.Gains" /></Col>
                <Col className="font-weight-bold"></Col>
                <Col className="font-weight-bold"></Col>
                <Col className="font-weight-bold"></Col>
                <Col className="font-weight-bold"></Col>
                {user.role !== "doctor" && <Col className="font-weight-bold"></Col>}
                <Col className="text-left font-weight-bold">{recetteBisTotal}</Col>
                <Col className="font-weight-bold"></Col>
            </Row>

            <Row className="tableRow border-0 m-0" style={{ paddingBottom: '12px' }} >
                <Col className="text-left font-weight-bold"><FormattedMessage id="Your.Total.Expenses" /></Col>
                <Col className="font-weight-bold"></Col>
                <Col className="font-weight-bold"></Col>
                <Col className="font-weight-bold"></Col>
                <Col className="font-weight-bold"></Col>
                {user.role !== "doctor" && <Col className="font-weight-bold"></Col>}
                <Col className="text-left font-weight-bold">{expensesTotal}</Col>
                <Col className="font-weight-bold"></Col>
            </Row>

            <Row className="tableRow border-0 m-0" style={{ paddingBottom: '12px' }}>
                <Col className="text-left font-weight-bold"><FormattedMessage id="Total.Amount" /></Col>
                <Col className="font-weight-bold"></Col>
                <Col className="font-weight-bold"></Col>
                <Col className="font-weight-bold"></Col>
                <Col className="font-weight-bold"></Col>
                {user.role !== "doctor" && <Col className="font-weight-bold"></Col>}
                <Col className="text-left font-weight-bold text-danger">{Maths.round(totalAmount)}€</Col>
                <Col className="font-weight-bold"></Col>
            </Row>

        </div>
    );
}

export default AccountingDailyTotalRow