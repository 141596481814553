import Util from "../util/Util";

export default class AgreementEnt {

    static isConcluded = (agreement, substitute) => {
        if (!agreement) return false;

        let firstCheck = (
            !agreement.cancelled_by_doctor &&
            !agreement.cancelled_by_substitute &&
            !Util.null(agreement.doctor_signature) && 
            !Util.null(agreement.doctor_initials)
        );

        if(!substitute) return firstCheck;

        return firstCheck && substitute._id === agreement.substitute_id;
    };

    static isSigned = (agreement, substitute) => {
        if (!agreement) return false;

        let firstCheck = (
            !agreement.cancelled_by_doctor &&
            !agreement.cancelled_by_substitute &&
            !Util.null(agreement.substitute_signature) && 
            !Util.null(agreement.substitute_initials)
        );

        if(!substitute) return firstCheck;

        return firstCheck && substitute._id === agreement.substitute_id;
    }

    static alreadyRated(user, agreement) {
        return (agreement.ratings && agreement.ratings.find(r => r.rater_id === user._id));
    };

    static isCancelledBy = (agreement, user) => {
        if (user.role === "doctor") return (agreement && agreement.cancelled_by_doctor);
        else return (agreement && agreement.cancelled_by_substitute);
    };

    static isCancelled = (agreement) => {
        return (agreement && (agreement.cancelled_by_doctor || agreement.cancelled_by_substitute));
    };

    static alreadySent = (agreement) => {
        return (agreement && !!agreement.sent_to_council);
    }

    static docCancelled = (agreement) => {
        return (agreement && !!agreement.cancelled_by_doctor);
    }

    static subCancelled = (agreement) => {
        return (agreement && !!agreement.cancelled_by_substitute);
    }

}