import React from 'react'
import moment from 'moment'
import * as dates from 'react-big-calendar/lib/utils/dates'

function createCalendar(date) {
    let currentDate = moment(date)

    const first = currentDate.clone().startOf('month')
    const last = currentDate.clone().endOf('month')
    var weeksCount = Math.ceil((first.day() + last.date()) / 7)
    const calendar = Object.assign([], { currentDate, first, last })

    var firstDay = first.day() - 1;
    if(first.day() === 0) {
        weeksCount += 1;
        firstDay = 6;
    } 

    for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
        const week = []
        calendar.push(week)
        calendar.year = currentDate.year()
        calendar.month = currentDate.month()

        for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
            var pushDate = currentDate.clone().set('date', day + 1 - firstDay)
            week.push(pushDate)
        }
    }

    return calendar
}

class CalendarDate extends React.Component {

    render() {
        const { dateToRender, dateOfMonth, dayBetween } = this.props

        const changeDate = () => this.props.changeDate(dateToRender)

        if (dateToRender.month() < dateOfMonth.month()) {
            return (
                <button disabled={true} className="date next-month">
                    {dateToRender.date()}
                </button>
            )
        }

        if (dateToRender.month() > dateOfMonth.month()) {
            return (
                <button disabled={true} className="date next-month">
                    {dateToRender.date()}
                </button>
            )
        }

        if (dayBetween === true) {
            return (
                <button className="date in-month cssNotifShow" onClick={changeDate}>
                    {dateToRender.date()}
                </button>
            )
        } else {
            return (
                <button className="date in-month-disabled" onClick={changeDate}>
                    {dateToRender.date()}
                </button>
            )
        }
    }
}

class Calendar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            calendar: createCalendar(props.date)
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.date !== this.props.date) {
            let calendar = createCalendar(this.props.date)
            this.setState({
                calendar,
            })
        }
    }

    getDaysAvailable(date) {
        let value = false;
        this.props.days_available.forEach((elem) => {
            if (date.isSame(elem.start, 'day')) {
                value = true;
                return;
            }
        })
        return value;
    }

    render() {
        let compteur1 = this.props.compteur + 1;
        let compteur2 = this.props.compteur + 2;
        let hideMonth;
        if (this.props.compteurLoop !== this.props.compteur && this.props.compteurLoop !== compteur1 && this.props.compteurLoop !== compteur2) {
            hideMonth = 'hide-month'; 
        } else {
            hideMonth = '';
        }
        return (
            <div className={"col-md-4 " + hideMonth}>
                <div className="month-name">
                    {this.state.calendar.currentDate.format('MMMM').toUpperCase() + ' ' + this.state.calendar.currentDate.format('YYYY').toUpperCase()}
                </div>
                {['L', 'M', 'M', 'J', 'V', 'S', 'D'].map((day, index) => (
                    <span key={index} className="day">
                        {day}
                    </span>
                ))}
                {this.state.calendar.map((week, index) => (
                    <div key={index}>
                        {week.map((date, keyBis) => 
                            <span key={keyBis}>
                                <CalendarDate
                                    key={date.date()}
                                    dateToRender={date}
                                    dateOfMonth={this.state.calendar.currentDate}
                                    dayBetween={this.getDaysAvailable(date)}
                                    changeDate={this.props.changeDate}
                                />
                            </span>
                        )}
                    </div>
                ))}
            </div>
        )
    }
}

class CalendarRetrocessionCertificate extends React.Component {

    constructor(props) {
        super(props);

        let compteurMonth = moment(this.props.startDate).diff(moment(this.props.date), 'month') - 1;
        let monthSelected = moment(this.props.startDate).diff(moment(), 'month') <= 0 ? 0 : moment(this.props.startDate).diff(this.props.date, 'month');
        this.state = {
            compteur: compteurMonth <= 0 ? 0 : compteurMonth,
            totalMonth: 3 + monthSelected,
            date: this.props.date
        }

        moment.locale('fr');
		this.onClickPrevious = this.onClickPrevious.bind(this);
        this.onClickNext = this.onClickNext.bind(this);
    }
    
    onClickPrevious() {
        let previousDate = moment(this.state.date).subtract(1, 'month');
        let compteur = this.state.compteur;

        if (compteur > 0) {
            compteur -= 1;
        }

        this.setState({ 
            compteur: compteur,
            date: previousDate
        })
    }

    onClickNext() {
        let compteur = this.state.compteur;
        let totalMonth = this.state.totalMonth;
        if(compteur === totalMonth - 3) {
            totalMonth += 1
        }
        compteur += 1;
        this.setState({ 
            compteur: compteur,
            totalMonth
        })
    }

    render() {
        let firstMonth = dates.startOf(this.state.date, 'month');
        const arrayMonth = [...Array(this.state.totalMonth).keys()];

        return (
            <div className="row">
                <div className="col-md-12 buttonPreviousNext">
                    <button type="button" onClick={this.onClickPrevious}><span>‹</span></button>
                    <button type="button" onClick={this.onClickNext}><span>›</span></button>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        {arrayMonth.map( i => 
                            <Calendar
                                key={i + 1}
                                date={dates.add(firstMonth, i, 'month')}
                                compteur={this.state.compteur}
                                compteurLoop={i}
                                changeDate={this.props.changeDate}
                                days_available={this.props.days_available}
                            />
                        )}
                    </div>
                    <div>
                        <button onClick={this.props.resetDate}>Réinitialiser les dates</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default CalendarRetrocessionCertificate