export default function user(state = {}, action) {
	switch(action.type) {
		case "SEARCH_DOCTOR_BY_SECTOR_REQUEST":
			return Object.assign({}, state, {
				isGettingDoctors: true,
			});
		case "SEARCH_DOCTOR_BY_SECTOR_SUCCESS":
			return Object.assign({}, state, {
				doctorsBySector: action.doctorsBySector,
				isGettingDoctors: false,
			});
		case "SEARCH_DOCTOR_BY_SECTOR_FAILURE":
			return Object.assign({}, state, {
				isGettingDoctors: false,
			});
		case "SEARCH_SUBSTITUTE_BY_SECTOR_REQUEST":
			return Object.assign({}, state, {
				isGettingSub: true,
			});
		case "SEARCH_SUBSTITUTE_BY_SECTOR_SUCCESS":
			return Object.assign({}, state, {
				subBySector: action.subBySector,
				isGettingSub: false,
			});
		case "SEARCH_SUBSTITUTE_BY_SECTOR_FAILURE":
			return Object.assign({}, state, {
				isGettingSub: false,
			});
		default:
			return state;
	}
}