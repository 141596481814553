import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import 'react-datetime/css/react-datetime.css';


export default class SubstituteDESCsProfile extends Component {

    render() {
        const { characteristics } = this.props;

        if (!characteristics.descs || !characteristics.descs.length) return null;

        return (
            <React.Fragment>

                <h5 className="">
                    <b><FormattedMessage id="DESCs" /></b>
                </h5>

                <div className="text-left max-w-80">
                    {characteristics.descs.map(desc => {
                        return (
                            <div key={Math.random()}><i className="fa fa-check" />&nbsp;&nbsp;{desc.topic}</div>
                        );
                    })}
                </div>

            </React.Fragment>
        );
    }
}


