import React from "react";
import { Button, Row, Col } from "reactstrap";
import { upds, getsBy, clears, get, getBy, removes } from "../../actions/global/global";
import { connect } from "react-redux";

class ConfirmDoctor extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			user: null,
			loading: false,
			displayInfo: false,
			// Pagination
		};
	}

	componentDidMount() {
		let id = this.props.match.params.userId;
		this.setState({ loading: true });
		this.props.getSecretaryManagement('secretaryManagement/', "userId", id, () => this.setState({ loading: false }),);
	}

	acceptDoctor = (id, CollectionUser, Collection) => {
		this.setState({ loading: true });

		this.props.onUpds("user/updateuser", { userId: id, CollectionUser, Collection }, () => this.setState({ loading: false, displayInfo: true }),);
	}


	loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

	maxLengthCheck = (object) => {
		if (object.target.value.length > object.target.maxLength) {
			object.target.value = object.target.value.slice(0, object.target.maxLength)
		}
	}

	render() {

		return (
			<React.Fragment>

				<div id="registration" className='example blue-bg'>

					<div className='step-progress'>

						<div className="topbar navbarbg text-center b-fix">
							<a href="/#" className="a-unstyled">
								<div className="hydrogen-logo-sm d-inline-block align-middle"></div>
								<div className="ml-3 bold d-inline-block align-middle fs-18 lp-3">HYDROGEN</div>
							</a>
						</div>
						<h4 className="text-white uppercase w-100 text-center lp-3 mt-4 mb-3">
							{/* Bonjour Dr {user && user.name} */}
						</h4>

						<div>
							<Row>

								{this.state.displayInfo ?
									(<Row /* className="step step4 mt-2 justify-content-md-center " */>
										<Col className="fontWhite label-col1">
											<div className="fontWhite font-weight-bold"><br /><br />
												<p><b>Félicitations !</b></p>

												<p className="pb-0 mb-0">Votre action est bien enregistrée.</p>

												<br />

												<p className="pb-0 mb-0">L'équipe HYDROGEN vous adressera un lien sur votre boite mail pour finaliser votre inscription.</p>

												<br />

												<p>A très vite!</p>
												<p>L'équipe HYDROGEN</p>

											</div>
											<div className="logo"></div>
										</Col>

									</Row>)
									:
									(<Col className="fontWhite label-col">
										<div className="marBtn">
											<Button
												type="submit"
												disabled={this.state.disabled}
												size="sm"
												className="btn text-white srounded w-1 bnt-blue pl-5 pr-5 pt-3 pb-3 "
												onClick={() => this.acceptDoctor()} >
												Valider
											</Button>
										</div>


									</Col>)
								}
							</Row>

						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.global.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		/* onUpds: (objName, obj, callback) => dispatch(upds(objName, obj, callback)), */
		onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),
		onGetBy: (objName, fields, values, callback) => dispatch(getBy(objName, fields, values, callback)),
		onGet: (objName, cbk) => dispatch(get(objName, cbk)),
		onUpds: (objName, obj, callback) => dispatch(upds(objName, obj, callback)),
		onRemoves: (objName, obj, callback) =>
			dispatch(removes(objName, obj, callback)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDoctor);
