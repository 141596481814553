import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage } from 'react-intl';
import StringUtil from '../../../util/StringUtil';
import 'ladda/dist/ladda-themeless.min.css';

import ecgImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_ECG.png';
import gynecologyImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_GYNECO_02.png';
import osteopathyImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_OSTEO.png';
import homeopathyImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_HOMEO_01.png';
import sportsImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_HOMEO_SPORT.png';
import allergologyImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_ALLERGO.png';
import emergencyImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_URGENCE.png';


export default class PsyIPracticesProfile extends Component {

    buildRoundElem = (characteristics, field, i18n, img) => {
        if (!i18n) i18n = StringUtil.capitalize(field);

        return (
            <div className="round-elem d-inline-block" style={{ opacity: (characteristics[field]) ? 1 : .4 }}>
                <div className="">
                    <img src={img} alt="" width="50" height="50" />
                </div>
                <div className="title mt-2"><FormattedMessage id={i18n} /></div>
            </div>
        );
    }

    render() {
        const { psychologistICharacteristic } = this.props;

        return (
            <React.Fragment>
                <div className="d-inline-block mx-auto w-100">
                    {this.buildRoundElem(psychologistICharacteristic, "behavioral_therapy", 'Behavioral.Therapy', ecgImg)}
                    {this.buildRoundElem(psychologistICharacteristic, "psychoanalysis", "Psychoanalysis", gynecologyImg)}
                    {this.buildRoundElem(psychologistICharacteristic, "emdr", "EMDR", osteopathyImg)}
                    {this.buildRoundElem(psychologistICharacteristic, "hypnosis", "Hypnosis", homeopathyImg)}
                    {this.buildRoundElem(psychologistICharacteristic, "couple", "Couple", sportsImg)}
                    {this.buildRoundElem(psychologistICharacteristic, "breathing_methods", "Breathing.Methods", allergologyImg)}
                    {this.buildRoundElem(psychologistICharacteristic, "auriculotherapy", "Auriculotherapy", emergencyImg)}
                </div>
            </React.Fragment >
        );
    };

}