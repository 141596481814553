import React from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Geocode from "react-geocode";
import Cleave from 'cleave.js/react';

import GoogleMapUtil from "../../../util/GoogleMapUtil";

import SearchLocationInputClinical from '../../../components/form/SearchLocationInputClinical';

export default class AddPatientStep3 extends React.Component {

	constructor(props) {
		super(props);

		const { name, first_name, birth_date, gender, email, mobile_phone, phone, google_address, nir } = this.props.getStore();
		this.state = {
			name: name || "",
			first_name: first_name || "",
			birth_date: birth_date || "",
			gender: gender || "man",
			email: email || "",
			mobile_phone: mobile_phone || "",
			phone: phone || "",
			// Nécessaire pour éviter le remplissage automatique de l'adresse par le navigateur
			google_address: google_address || "",
			nir: nir || "",
			googleMapScriptLoaded: true,
			error: ""
		}

		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.back = this.back.bind(this);
		this.onChangeAddress = this.onChangeAddress.bind(this);
		this.isDateValid = this.isDateValid.bind(this);

		this.form = React.createRef();

		this.GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

        Geocode.setApiKey(this.GOOGLE_MAP_API_KEY);
		GoogleMapUtil.loadScript(
			`https://maps.googleapis.com/maps/api/js?key=${this.GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`, () =>
			this.setState({
				googleMapScriptLoaded: true
			})
		);
	}

	onChange(event) {
		const { name, value } = event.currentTarget;

		this.setState({
			[name]: value
		}, () => this.props.updateStore(this.state, 3))
	}

	onChangeAddress(value) {
		this.setState({
			google_address: value
		}, () => this.props.updateStore(this.state, 3))
	}

	onSubmit(event) {
		event.preventDefault();
		if (this.isDateValid(this.state.birth_date) === false) return; 
		this.props.updateStore(this.state, 3);
		this.props.jumpToStep(3);
	}

	back() {
		this.props.updateStore(this.state, 1);
		this.props.jumpToStep(1);
	}

	isValidated() {
		return this.form.current.reportValidity() && this.isDateValid(this.state.birth_date);
	}

	isDateValid(date) {
		if (typeof date === "string" && date.includes("/") && date.length === 10) {
			this.setState({
				error: ""
			})
			return true;
        } else {
			this.setState({
				error: "Date incorrecte"
			})
			return false;
		}
	}

	render() {

		return (
			<Row className="step step1 mt-2 justify-content-md-center ">
				<Col lg="8">
					<Form innerRef={this.form} onSubmit={this.onSubmit} >
						<FormGroup row>
							<Label md={4}><FormattedMessage id="Name" /></Label>
							<Col md={8}>
								<Input type="text" name="name" value={this.state.name} onChange={this.onChange} required />
							</Col>		
						</FormGroup>
						<FormGroup row>
							<Label md={4}><FormattedMessage id="First.Name" /></Label>
							<Col md={8}>
								<Input type="text" name="first_name" value={this.state.first_name} onChange={this.onChange} required />
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label md={4}><FormattedMessage id="DateOfBirth" /></Label>
							<Col md={8}>
								<Cleave 
									value={this.state.birth_date}
									options={{date: true, datePattern: ['d', 'm', 'Y'], delimiter: '/' }}
									onChange={this.onChange}
									name="birth_date"
									className="form-control" 
									placeholder="JJ/MM/AAAA"
									required
								/>
								<span className="text-danger">{this.state.error}</span>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label md={4}><FormattedMessage id="Gender" /></Label>
							<Button 
								color=""
								className="radio-btn srounded mr-3 pl-4 pr-4 lowercase bg-blue text-white mb-1 mb-sm-0"
								name="gender"
								value="woman"
								onClick={this.onChange}
								active={this.state.gender === "woman"}>
								<FormattedMessage id="Gender.Woman" />
							</Button>
							<Button 
								color=""
								className="radio-btn srounded mr-3 pl-4 pr-4 lowercase bg-blue text-white mb-1 mb-sm-0"
								name="gender"
								value="man"
								onClick={this.onChange}
								active={this.state.gender === "man"}>
								<FormattedMessage id="Gender.Man" />
							</Button>
						</FormGroup>
						<FormGroup row>
							<Label md={4}><FormattedMessage id="Address" /></Label>
							<Col md={8}>
								{this.state.googleMapScriptLoaded &&
									<SearchLocationInputClinical value={this.state.google_address} onChange={(value) => this.onChangeAddress(value)} name="google_address" typeMap="address" required/>
								}
								<span className="text-danger">{this.props.error}</span>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label md={4}><FormattedMessage id="Email" /></Label>
							<Col md={8}>
								<Input type="email" name="email" value={this.state.email} onChange={this.onChange} />
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label md={4}><FormattedMessage id="Phone.Mobile" /></Label>
							<Col md={8}>
								<Input type="text" name="mobile_phone" minLength="10" maxLength="10" value={this.state.mobile_phone} onChange={this.onChange} required />
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label md={4}><FormattedMessage id="Phone.Fixe" /></Label>
							<Col md={8}>
								<Input type="text" name="phone" minLength="10" maxLength="10" value={this.state.phone} onChange={this.onChange} />
							</Col>
						</FormGroup>
						<Row className="mx-auto form-buttons">
							<Button color="secondary" className="btn btn-ladda lowercase srounded pl-5 pr-5 pt-2 pb-2 bg-grey text-white mx-auto" onClick={this.back}>Annuler</Button>
							<Button color="success" className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green mx-auto" type="submit">Suivant</Button>
						</Row>
					</Form>
				</Col>
			</Row>
		);
	};
};