var values = [
    { label: "Other", value: "0" },
    { label: 'Dr Santé', value: "1" },
    { label: 'Doctolib', value: "2" },
    { label: 'LGC easy care', value: "3" },
    { label: 'Capterra', value: "4" },
    { label: 'Momentum', value: "5" },
    { label: 'Thairis', value: "6" },
    { label: 'Powerserver PACS', value: "7" },
    { label: 'Easydoct.com', value: "8" },
    { label: 'Osirix', value: "9" },
    { label: 'XMedCon', value: "10" },
    { label: 'Mango', value: "11" },
    { label: 'Horos', value: "12" },
    { label: 'RIS Archives', value: "13" },
    { label: 'Radiology Decision Support', value: "14" },
    { label: 'RadiAnt DICOM Viewer', value: "15" },
    { label: 'Planmeca', value: "16" },
    { label: 'MedicalExpo', value: "17" },
    { label: 'GetApp', value: "18" },
    { label: 'Medecom', value: "19" },
    { label: 'PostDicom', value: "20" },
    { label: 'EDL', value: "21" } ]
  

export default class RadiologistMedicalSoftwares {

    static values() {
        return values;
    }

    static getLabel(nbr) {
        for (let value of values) {
            if (value.value === nbr) return value.label;
        }
    }

};