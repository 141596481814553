import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export default class CGV extends React.Component {

	render() {
        const { user } = this.props;

		return (

            <Modal isOpen={true} toggle={this.props.close} className="simple-modal modal-lg simple-modal-white srounded modalCustom">

            <ModalHeader className="header-modal-choice">
                <div className="text-left">
                    <button type="button" onClick={this.props.close} className="close d-inline-block" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </ModalHeader>

            <ModalBody className="justify-content-center">
                {user.role === 'substitute' && 
                    <div>
                        <h1 className="w-100 text-center">CONDITIONS GENERALES D’UTILISATION</h1>
                        <h5 className="w-100 text-center mb-5">Version en vigueur au 3 Mars 2021</h5>

                        <h3 className="mt-5 mb-5">ARTICLE 1.	CHAMP D'APPLICATION</h3>

                        <p>1.1.	Les présentes conditions générales de vente (ci-après les « CGV ») ont pour objet de définir les conditions dans lesquelles  la société HYDROGEN, société au capital de 7.5000,00 Euros, inscrite au Registre du Commerce et des Sociétés de Grasse sous le numéro 877 575 597, ayant son siège social sis 1.047 Allée Pierre ZILLER – 06560 VALBONNE, (ci-après « HYDROGEN » ou la « Partie ») propose à ses clients ayant exclusivement la qualité (a) de médecins diplômés installés, exerçant à titre individuel ou de manière groupée, ou (b) d’établissement de santé employant des médecins diplômés (ci-après ensemble les « Clients » ou le « Client » ou la « Partie ») des services de recherche personnalisés (ci-après les « Services ») de médecins diplômés disponibles (ci-après les « Remplaçants ») pour les remplacer ou remplacer les médecins salariés qu’ils emploient pendant leur période d’indisponibilité (ci-après ensemble les « Remplacements ») grâce à l’utilisation de son réseau et de sa base de données (ci-après la « Base de données ») agrégeant les offres et les demandes de Remplacements. Les Services ne sont proposés que sur le site internet d’HYDROGEN (www.hydrogen-rempla.com), qui intègre la Base de données (ci-après le « Site »).</p>

                        <p>1.2.	Elles s'appliquent sans restriction ni réserve à toutes les ventes conclues par HYDROGEN auprès des Clients de même catégorie, quelles que soient les clauses pouvant figurer sur les documents du Client. Elles constituent, avec la Confirmation de Commande (ci-après définie), les seuls documents contractuels opposables aux Parties, à l'exclusion de tous autres documents, prospectus, catalogues ou description des Services qui n'ont qu'une valeur indicative.</p>

                        <p>1.3.	Ces CGV sont consultables à tout moment par le Client depuis son compte client, pour lui permettre de passer Commande (ci-après définie) auprès d’HYDROGEN. Elles peuvent également lui être communiquées sur simple demande par téléphone, courrier électronique ou courrier postal.</p>

                        <p>1.4.	Toute Commande de Services implique, de la part du Client, l'acceptation sans réserve des présentes CGV ainsi que des conditions générales d'utilisation du Site (ci-après les « CGU »). Elles sont opposables au Client qui reconnaît, en cochant une case prévue à cet effet, en avoir eu connaissance et les avoir acceptées avant de passer Commande. Le contrat ainsi formé entre le Client et HYDROGEN est composé des présentes CGV, de la Commande et de la Confirmation de Commande, y compris toute annexe ou modification de celle-ci et tous les documents qui y sont intégrés par référence (notamment les CGU et la Politique de confidentialité d’HYDROGEN), l’ensemble de ces documents devant être considérés comme un tout indivisible (ci-après le « Contrat »).</p>

                        <p>1.5.	Il appartient donc au Client de vérifier l'exactitude de sa Commande et de rectifier ou signaler immédiatement toute erreur.</p>

                        <p>1.6.	La vente des Services ne sera considérée comme définitive qu'après l'envoi au Client de la Confirmation de Commande par HYDROGEN, par courrier électronique.</p>

                        <p>1.7.	Toute Commande de Services passée sur le Site constitue la formation d'un Contrat conclu à distance entre le Client et HYDROGEN.</p>

                        <p>1.8.	La validation de la Commande par sa confirmation vaut adhésion par le Client aux CGV en vigueur au jour de la Commande, dont la conservation et la reproduction sont assurées par HYDROGEN conformément à l'article 1127-2 du Code civil.</p>

                        <p>1.9.	Les renseignements figurant sur le Site d’HYDROGEN sont donnés à titre indicatif et sont révisables à tout moment. HYDROGEN est en droit d'y apporter toutes modifications qui lui paraîtront utiles. En cas de modification des CGV, la version applicable sera celle en vigueur à la date de la Commande dont une copie datée peut être remise à sa demande au Client.</p>

                        <p>1.10.	Les présentes CGV demeureront en vigueur jusqu'à ce qu’une nouvelle version soit éditée par HYDROGEN et vienne remplacer le présent document.</p>
    
                        <h3 className="mt-5 mb-5">ARTICLE 2.	EVOLUTIONS</h3>

                        <p>HYDROGEN se réserve le droit de modifier les présentes CGV, selon l’évolution technique du Site ou de ses offres de Services, ou en raison de l’évolution de la législation, à sa seule discrétion. Dès lors que des points essentiels touchant à la vente des Services viendraient à être modifiés, il sera demandé au Client, à l’occasion de toute nouvelle Commande, de lire et d’approuver lesdites nouvelles CGV.</p>

                        <p>D’une manière générale, l’utilisation du Site, ainsi que la vente des Services au Client sont toujours soumises à la version la plus récente des CGV postées sur le Site et accessibles au Client au moment de son utilisation du Site. Il lui appartient de consulter aussi souvent que nécessaire les CGV accessibles dans la zone qui lui est réservée.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 3.	TERRITOIRE ET DURÉE</h3>

                        <p>Le Site est ouvert à tous les pays du monde.</p>

                        <p>Dans l’hypothèse où le Site, les services qu’il propose ou la manière dont ils sont proposés seraient, en tout ou partie, illégaux au regard du droit national de résidence du Client, il appartient à ce dernier de renoncer à y accéder à partir de ce territoire ou d’y accéder depuis d'autres territoires où ils sont réputés légaux. Le Client qui choisit d'accéder au Site depuis un territoire où il est réputé en tout ou partie illégal, le fait de sa propre initiative, à ses risques et périls et il lui incombe d’assumer les conséquences de l’application des règles d’ordre public de son pays de résidence, sans en imputer la responsabilité à HYDROGEN.</p>

                        <p>Sauf disposition contraire indiquée dans la Commande et la Confirmation de Commande, le Contrat est conclu pour une durée de douze (12) mois.</p>

                        <p>Il se renouvellera automatiquement par tacite reconduction pour des périodes successives de même durée, sauf dénonciation du Contrat par l’une des Parties respectant un préavis d’un (1) mois avant chaque date anniversaire du Contrat. </p>

                        <h3 className="mt-5 mb-5">ARTICLE 4.	COMMANDES</h3>

                        <p>4.1.	Passation de Commande</p>

                        <p>L’adhésion aux Services disponibles sur le Site ne peut être réalisée que de manière électronique à partir de ce dernier (ci-après la « Commande »). L'enregistrement d'une Commande sur le Site d’HYDROGEN est réalisé lorsque le Client accepte les présentes CGV en cochant la case prévue à cet effet et valide sa Commande. Cette validation implique l'acceptation de l'intégralité des présentes CGV et constitue une preuve du Contrat de vente entre les Parties.</p>

                        <p>La prise en compte de la Commande et l'acceptation de celle-ci sont confirmées par l'envoi d'un e-mail par HYDROGEN (ci-après la « Confirmation de Commande »). Les données enregistrées dans le système informatique d’HYDROGEN constituent la preuve de l'ensemble des transactions conclues avec le Client.</p>

                        <p>Dès lors, les ventes ne sont parfaites qu'après acceptation expresse et par écrit par HYDROGEN de la Commande du Client, matérialisée par l’envoi de la Confirmation de Commande.</p>

                        <p>4.2.	Modification et annulation de la Commande</p>

                        <p>Les demandes de modification de Commande par le Client ne pourront être prises en compte, dans la limite des possibilités d’HYDROGEN et à sa seule discrétion, uniquement si elles sont notifiées à cette dernière par écrit à l’adresse e-mail suivante : quarante-huit (48) heures au maximum après l’envoi par HYDROGEN de la Confirmation de Commande.</p>

                        <p>Au cours de son année d’engagement, HYDROGEN pourra de son initiative proposer une modification de Commande au Client, lorsque ce dernier aura rendu le service qu’il a commandé.  La Client a le droit de refuser cette proposition. Si le Client accepte cette modification de Commande, celle-ci sera apportée manuellement par HYDROGEN, sur le profil des médecins remplaçants.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 5.	CONDITIONS FINANCIERES</h3>

                        <p>5.1.	Gratuité des Services et nantissement applicables aux Remplaçants concernant le service de remplacement</p>

                        <p>La gratuité de l’utilisation des Services par les Remplaçants est soumise à une condition de disponibilité de leur part. En effet, en utilisant les Services, les Remplaçants acceptent et s’engagent à réserver une partie de leurs Remplacements aux Clients d’HYDROGEN dans les conditions et modalités suivantes (les données étant indiquées par année d’utilisation des Services. Le BNC prévisionnel étant le Bénéfice Non Commercial qu’obtiendrait le Remplaçant s’il acceptait les propositions d’HYDROGEN) :</p>


                        <div className="table-responsive">
                            <table className="table table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>Offres</th>
                                        <th>BNC prévisionnel</th>
                                        <th>Nombre de propositions annuelles</th>
                                        <th>Nombre de semaines de Remplacements réservé aux Clients d’HYDROGEN</th>
                                        <th>Nombre de propositions de Remplacements pouvant être refusés</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>Offre EQUILIBRE</td>
                                        <td>1 remplacement</td>
                                        <td>1</td>
                                        <td>1</td>
                                        <td>2</td>
                                    </tr>
                                    <tr>
                                        <td>Offre CONFORT</td>
                                        <td>Entre 5.000 et 19.999 €</td>
                                        <td>2 à 8</td>
                                        <td>2</td>
                                        <td>4</td>
                                    </tr>
                                    <tr>
                                        <td>Offre CONFORT</td>
                                        <td>Entre 20.000 et 39 999 €</td>
                                        <td>9 à 16</td>
                                        <td>3</td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td>Offre CONFORT</td>
                                        <td>Entre 40 000 et 59 999 €</td>
                                        <td>17 à 24</td>
                                        <td>4</td>
                                        <td>8</td>
                                    </tr>
                                    <tr>
                                        <td>Offre H+</td>
                                        <td>Supérieures à 60 000 €</td>
                                        <td>25 ou plus</td>
                                        <td>Illimité</td>
                                        <td>10</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <p>Il est entendu que les périodes de Disponibilités des Clients d’HYDROGEN seront communiquées par les remplaçants à HYDROGEN au moins (1,5) un mois et demi à l’avance, ce afin qu’HYDROGEN puisse délivrer ses Services à l’ensemble des Utilisateurs dans les meilleures conditions possibles, notamment en permettant à chacun d’eux de pouvoir anticiper et planifier leur calendrier.</p>

                        <p>5.2.	Gratuité des Services et nantissement applicables aux Remplaçants concernant le service d’installation La gratuité de l’utilisation des Services par les Remplaçants est soumise à une condition de disponibilité de leur part. En effet, en utilisant les Services, les Remplaçants acceptent et s’engagent à réserver à minima une semaine de leurs Remplacements aux Clients d’HYDROGEN dans la limite de (2) deux refus par an. Ces modalités seront reproduites autant de fois que le souhaite le remplaçant durant la période d'abonnement.</p>

                        <p>La gratuité de l’utilisation des Services par les Remplaçants est soumise à une condition de disponibilité de leur part. En effet, en utilisant les Services, les Remplaçants acceptent et s’engagent à réserver à minima une semaine de leurs Remplacements aux Clients d’HYDROGEN dans la limite de (2) deux refus par an.</p>

                        <p>5.3. Demande de caution</p>

                        <p>Afin de garantir la bonne réalisation par le Remplaçant de son obligation de disponibilité telle que détaillée ci-avant, ce dernier s’engage à nantir, auprès du prestataire homologué Swikly, désigné par HYDROGEN sur le Site et dans les conditions qui y sont indiquées, la somme suivante :</p>

                        <ul>
                            <li>Quatre-vingt-dix-neuf euros (99€) pour l’offre EQUILIBRE et l’offre CONFORT ;</li>
                            <li>Cent-quatre-vingt-dix-neuf euros (199€) pour l’offre H+ et l’offre installation ;</li>
                        </ul>

                        <p>Afin de garantir la réalisation par les Remplaçants de cette obligation de disponibilité, HYDROGEN requiert de leur part le dépôt d’une somme d’argent à titre de nantissement. Dans le cas où le Remplaçant manquerait à son obligation de disponibilité, HYDROGEN deviendra immédiatement propriétaire de la somme donnée en nantissement par le Remplaçant, et celle-ci sera directement prélevée sur le compte bancaire que le Remplaçant aura renseigné dans le cadre du nantissement indiqué ci-dessus, selon la procédure et les modalités décrites sur le Site de notre prestataire homologué Swikly. Si, en revanche, le Remplaçant a bien respecté son obligation de disponibilité, aucune somme ne sera prélevée par HYDROGEN et le nantissement réalisé par le Remplaçant deviendra sans objet et prendra automatiquement fin.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 6.	FOURNITURE DES SERVICES</h3>

                        <p>6.1.	Aide au remplacement</p>

                        <p>Les Services commandés par le Client lui seront fournis immédiatement à compter de la réception par ce dernier de la Confirmation de Commande selon les modalités indiquées sur le Site ainsi que dans le Contrat, notamment dans les CGU, en fonction des Disponibilités qu’il aura indiqué à HYDROGEN.</p>

                        <p>Ces Services pour l’aide au remplacement, consistent en la réalisation par HYDROGEN des prestations suivantes :</p>

                        <ul>
                            <li>Recherche de remplacements en libéral ou en salariat pour les périodes de Remplacement indiquées par le Client à HYDROGEN ;</li>
                            <li>Edition des contrats de remplacement et envoie au conseil de l’ordre, au Remplaçant et au médecin installé.</li>
                            <li>Edition et conservation des certificats de rétrocession et envoie au Remplaçant et au médecin installé.</li>
                            <li>Proposition de formations médicales.</li>
                            <li>Transmission d’un fichier récapitulatif des bénéfices mensuels du remplaçant.</li>
                            <li>Conseils sur le plan administratif (URSSAF, CARMF, CFE, CHORUS, déclaration des frais...).</li>
                        </ul>

                        <p>6.2.	Aide à l’installation</p>

                        <p>Ces Services pour l’aide à l’installation, consistent en la réalisation par HYDROGEN des prestations suivantes :</p>

                        <ul>
                            <li>Recherche de remplacements en libéral dans des cabinets qui recherchent un successeur ou un collaborateur ;</li>
                            <li>Recherche de remplacements en salariat dans des cabinets ou les Etablissements de santé qui recherchent un successeur ou un collaborateur</li>
                            <li>Edition des contrats de remplacement et envoie au conseil de l’ordre, au Remplaçant et au médecin installé.</li>
                            <li>Edition et conservation des certificats de rétrocession et envoie au Remplaçant et au médecin installé.</li>
                            <li>Proposition de formations médicales.</li>
                            <li>Transmission d’un fichier récapitulatif des bénéfices mensuels du remplaçant.</li>
                            <li>Conseils sur le plan administratif (URSSAF, CARMF, CFE, CHORUS, déclaration des frais...).</li>
                        </ul>

                        <p>Le Remplaçant peut, à sa discrétion, refuser tout ou partie de l’aide proposée, mais dans la mesure où il s’est abonné, il se doit de réserver ses Remplacements aux Clients d’HYDROGEN dans les conditions et modalités précisées à l’article 5.6.  HYDROGEN s'engage à faire ses meilleurs efforts pour fournir les Services souscrits par le Client, dans le cadre d'une obligation de moyen et dans les conditions ci-dessus précisées.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 7.	ASSISTANCE</h3>

                        <p>Le Client bénéficie gratuitement de l’assistance d’HYDROGEN, en cas de difficultés dans l’utilisation du Site en composant le 0643472087 (prix d’un appel local) entre 9h00 et 18h00 ou en adressant un e-mail au service support d’HYDROGEN à tout moment à l’adresse suivante : support@hydrogen-rempla.com. HYDROGEN répondra aux demandes d’assistance du Client dans ses meilleurs délais.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 8.	RESPONSABILITÉ</h3>

                        <p>La responsabilité d’HYDROGEN à l’égard du Client ne pourra être recherchée qu’en cas de manquement contractuel prouvé commis dans ou à l’occasion de l’exécution des prestations mises à sa charge au titre des présentes.</p>

                        <p>Elle ne pourra être tenue responsable des dommages indirects, des pertes financières ou des pertes d’exploitation subis par le Client ou par ses clients de ce fait. En conséquence, le Client renonce à recours à l’encontre d’HYDROGEN et contre ses assureurs au-delà de ces limites, tant en nature que sur les montants, et s’engage à faire renoncer à recours ses propres assureurs au-delà des mêmes limites.</p>

                        <p>Si toutefois la responsabilité d’HYDROGEN était retenue devant les tribunaux, le Client ne pourrait prétendre à d'autres indemnités et dommages et intérêts ou règlement quelconque, toutes causes et tous motifs confondus, qu'au montant des Services effectivement achetés et payés au cours des douze (12) derniers mois précédent le dommage.</p>

                        <p>A moins qu’il n’en soit autrement stipulé aux présentes, le Client ne pourra formuler une réclamation à l’encontre d’HYDROGEN après une période de douze (12) mois suivant la découverte de l'événement (ou des événements) ayant provoqué l'éventuelle responsabilité.</p>

                        <p>D’une manière générale, le Client reconnaît que la responsabilité d’HYDROGEN ne pourra être retenue et qu’il sera le seul responsable dans toutes les hypothèses d’utilisation des Services d’une manière non conforme aux lois et règlements en vigueur, aux règles et usages applicables à son secteur d’activité et/ou à toutes recommandations faites par HYDROGEN, notamment au sein de la documentation qu’il fournit au Client, y compris sur le Site et dans tous documents du Contrat.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 9.	NOTIFICATIONS – CONVENTION DE PREUVE</h3>

                        <p>Sauf dans les cas où une stipulation du présent Contrat en dispose autrement, il résulte d’un accord exprès entre les Parties que les échanges entre elles pourront intervenir par tous moyens, notamment par messagerie électronique aux adresses e-mail mentionnées dans leurs correspondances, factures et/ou bons de commandes, ou dans tout autre document.</p>

                        <p>Les Parties conviennent que l'impression papier d'un courriel permet de prouver valablement la teneur des échanges.</p>

                        <p>Les Parties mettent en œuvre toutes les mesures de sécurité permettant de garantir la disponibilité, l’intégrité et la confidentialité des fichiers e-mail adressés par internet.</p>

                        <p>Elles mettent en œuvre, parallèlement, toutes les mesures utiles, tels que pare feu et antivirus régulièrement mis à jour et correctement paramétrés, pour se protéger de la manière la plus efficace possible contre les intrusions, attaques et propagation des virus afin de garantir la disponibilité, l’intégrité et la confidentialité des fichiers e-mail reçus.</p>

                        <p>Les Parties sauvegarderont par la manière la plus appropriée et la plus sûre possible l’intégralité des messages transmis relatifs à l’objet du présent Contrat.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 10.	FORCE MAJEURE</h3>

                        <p>Les Parties ne pourront être tenues pour responsables si la non-exécution ou le retard dans l'exécution d'une quelconque de leurs obligations, telles que décrites dans les présentes découle d'un cas de force majeure, au sens de l'article 1218 du Code civil et de la jurisprudence des juridictions françaises.</p>

                        <p>La Partie constatant l'événement devra sans délai informer l'autre partie de son impossibilité à exécuter sa prestation et s'en justifier auprès de celle-ci. La suspension des obligations ne pourra en aucun cas être une cause de responsabilité pour non-exécution de l'obligation en cause, ni induire le versement de dommages et intérêts ou pénalités de retard.</p>

                        <p>L'exécution de l'obligation est suspendue pendant toute la durée de la force majeure si elle est temporaire et ne dépasse pas une durée de trente (30) jours. Par conséquent, dès la disparition de la cause de la suspension de leurs obligations réciproques, les Parties feront tous leurs efforts pour reprendre le plus rapidement possible l'exécution normale de leurs obligations contractuelles. A cet effet, la Partie empêchée avertira l'autre de la reprise de son obligation par lettre recommandée avec demande d'avis de réception ou tout acte extrajudiciaire. Si l'empêchement est définitif ou dépasse une durée de trente (30) jours, les présentes seront purement et simplement résolues.</p>

                        <p>Pendant cette suspension, les Parties conviennent que chacune d’elles pendra à sa charge ses propres frais engendrés par la situation.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 11.	PROPRIETE INTELLECTUELLE</h3>

                        <p>Tous les outils, méthodes, logiciels, algorithmes, savoir-faire, contenu éditorial (texte, photographie, images, vidéos, dessins, etc.), illustrations et d’une manière générale toute œuvre ou création susceptible d'appropriation intellectuelle utilisés par HYDROGEN dans le cadre de l’édition ou de l’exploitation du Site, et en particulier la Base de données, sont soit la propriété exclusive d’HYDROGEN, soit cette dernière dispose des droits nécessaires pour les utiliser et les concéder au Client dans le cadre de son utilisation du Site, ce conformément aux CGV et aux CGU.</p>

                        <p>A ce titre, le Client reconnait et accepte que, sauf disposition contraire exprimée par ailleurs aux présentes CGV et outre la licence d’utilisation du Site qui lui accordée par HYDROGEN dans le cadre des CGU, il n’acquiert aucun droit sur les actifs immatériels appartenant à HYDROGEN.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 12.	RESILIATION</h3>

                        <p>12.1.	Résiliation pour inexécution d'une obligation suffisamment grave</p>

                        <p>La Partie victime de la défaillance pourra, en cas d'inexécution suffisamment grave d'une quelconque des obligations incombant à l'autre Partie, notifier par lettre recommandée avec demande d'avis de réception à la Partie défaillante, la résolution fautive des présentes, quinze (15) jours après l'envoi d'une mise en demeure de s'exécuter restée infructueuse, et ce en application des dispositions de l'article 1224 du Code civil.</p>

                        <p>12.2.	Résiliation pour force majeure</p>

                        <p>La résolution de plein droit pour force majeure, ne pourra, nonobstant la clause résolution pour manquement d'une Partie à ses obligations figurant ci-après, avoir lieu que quinze (15) jours après la réception d'une mise en demeure notifiée par lettre recommandée avec demande d'avis de réception ou tout acte extrajudiciaire.</p>

                        <p>Toutefois, cette mise en demeure devra mentionner l'intention d'appliquer la présente clause.</p>

                        <p>12.3.	Dispositions communes aux cas de résolution</p>

                        <p>Il est expressément convenu entre les Parties que le débiteur d'une obligation de payer aux termes des présentes CGV, sera valablement mis en demeure par la seule exigibilité de l'obligation, conformément aux dispositions de l'article 1344 du Code civil.</p>

                        <p>Les prestations échangées entre les Parties depuis la conclusion du Contrat et jusqu'à sa résiliation ayant trouvé leur utilité au fur et à mesure de l'exécution réciproque de celui-ci, elles ne donneront pas lieu à restitution pour la période antérieure à la dernière prestation n'ayant pas reçu sa contrepartie.</p>

                        <p>En tout état de cause, la Partie lésée pourra demander en justice l'octroi de dommages et intérêts.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 13.	CLAUSES GENERALES</h3>

                        <p>13.1.	Tolérance</p>

                        <p>Il est formellement convenu que toute tolérance ou renonciation d'une des Parties, dans l'application de tout ou partie des engagements prévus au présent Contrat, quelles que puissent en être la fréquence et la durée, ne saurait valoir modification du présent Contrat, ni générer un droit quelconque.</p>

                        <p>13.2.	Intégralité</p>

                        <p>Les présentes dispositions, en cela sont incluses les stipulations des Confirmations de Commande, constituent l’intégralité du Contrat des Parties et remplacent en tous points les accords, lettres d’intention, courriers et propositions antérieures entre elles, quelle qu’en soit la forme ou l’appellation et portant sur le même objet, à l’exception de ceux listés aux présentes.</p>

                        <p>13.3.	Divisibilité</p>

                        <p>Si l’une quelconque des dispositions du Contrat est annulée en tout ou partie, la validité des dispositions restantes du Contrat n’en sera pas affectée. Dans ce cas, les Parties devront, si possible, remplacer cette disposition annulée par une disposition valable correspondant à l’esprit et à l’objet du Contrat.</p>

                        <p>13.4.	Indépendance</p>

                        <p>Les Parties déclarent expressément qu'elles sont et demeureront pendant toute la durée de leurs rapports des partenaires commerciaux et professionnels indépendants, assumant chacun les risques de sa propre exploitation.</p>

                        <p>13.5.	Porte-Fort</p>

                        <p>Le Client s’engage à faire respecter les présentes CGV par ses employé(s), collaborateur(s), associé(s), filiale(s) ou société(s) mère(s).</p>

                        <h3 className="mt-5 mb-5">ARTICLE 14.	DROIT APPLICABLE - LANGUE DU CONTRAT</h3>

                        <p>De convention expresse entre les Parties, les présentes CGV et les opérations d'achat et de vente qui en découlent sont régies par le droit français, à l’exclusion de tout autre.</p>

                        <p>Elles sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues, seul le texte français ferait foi en cas de litige.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 15.	LITIGES</h3>

                        <p>En vue de trouver ensemble une solution à tout litige qui surviendrait dans l'exécution du présent Contrat, les Parties conviennent de se réunir dans un délai de TRENTE (30) jours à compter de la réception d'une lettre recommandée avec demande d'avis de réception, notifiée par l'une des deux Parties, ou d’un courriel signé numériquement.</p>

                        <p>Si au terme d'un nouveau délai de trente (30) jours, les Parties ne parvenaient pas à se mettre d'accord sur un compromis ou une solution, en cas de difficulté d’exécution des présentes et de leur suite, les Parties conviennent de recourir avant toute saisine des juridictions à une convention de procédure participative telle que régie par les articles 2062 à 2068 du Code civil.</p>

                        <p>La procédure participative sera d’une durée maximum de trois (3) mois, renouvelable une fois pour la même durée. Pendant celle-ci, les Parties s’engageant à œuvrer conjointement et de bonne foi à la résolution amiable de leur éventuel différend.</p>

                        <p>Les Parties sont informées que l’absence de mise en œuvre de la procédure participative, prévue au présent paragraphe, rend irrecevable tout recours au juge pour qu’il statue sur le litige.</p>

                        <p><b>EN CAS D’ECHEC DE LA PROCEDURE PARTICIPATIVE DECRITE CI-AVANT, TOUS LES LITIGES AUXQUELS LE PRESENT CONTRAT ET LES ACCORDS QUI EN DECOULENT POURRAIENT DONNER LIEU, CONCERNANT TANT LEUR VALIDITE, LEUR INTERPRETATION, LEUR EXECUTION, LEUR RESOLUTION, LEURS CONSEQUENCES ET LEURS SUITES SERONT SOUMIS AU TRIBUNAL DU SIEGE SOCIAL D’HYDROGEN AU JOUR DE L’ASSIGNATION.</b></p>
                    </div>
                }

                {user.role === 'doctor' &&
                    <div>
                        <h1 className="w-100 text-center">CONDITIONS GENERALES DE VENTE</h1>
                        <h5 className="w-100 text-center mb-5">Version en vigueur au 3 Mars 2021</h5>

                        <h3 className="mt-5 mb-5">ARTICLE 1.	CHAMP D'APPLICATION</h3>

                        <p>1.1.	Les présentes conditions générales de vente (ci-après les « CGV ») ont pour objet de définir les conditions dans lesquelles  la société HYDROGEN, société au capital de 7.5000,00 Euros, inscrite au Registre du Commerce et des Sociétés de Grasse sous le numéro 877 575 597, ayant son siège social sis 1.047 Allée Pierre ZILLER – 06560 VALBONNE, (ci-après « HYDROGEN » ou la « Partie ») propose à ses clients ayant exclusivement la qualité (a) de médecins diplômés installés, exerçant à titre individuel ou de manière groupée, ou (b) d’établissement de santé employant des médecins diplômés (ci-après ensemble les « Clients » ou le « Client » ou la « Partie ») des services de recherche personnalisés (ci-après les « Services ») de médecins diplômés disponibles (ci-après les « Remplaçants ») pour les remplacer ou remplacer les médecins salariés qu’ils emploient pendant leur période d’indisponibilité (ci-après ensemble les « Remplacements ») grâce à l’utilisation de son réseau et de sa base de données (ci-après la « Base de données ») agrégeant les offres et les demandes de Remplacements. Les Services ne sont proposés que sur le site internet d’HYDROGEN (www.hydrogen-rempla.com), qui intègre la Base de données (ci-après le « Site »).</p>

                        <p>1.2.	Elles s'appliquent sans restriction ni réserve à toutes les ventes conclues par HYDROGEN auprès des Clients de même catégorie, quelles que soient les clauses pouvant figurer sur les documents du Client. Elles constituent, avec la Confirmation de Commande (ci-après définie), les seuls documents contractuels opposables aux Parties, à l'exclusion de tous autres documents, prospectus, catalogues ou description des Services qui n'ont qu'une valeur indicative.</p>

                        <p>1.3.	Ces CGV sont consultables à tout moment par le Client depuis son compte client, pour lui permettre de passer Commande (ci-après définie) auprès d’HYDROGEN. Elles peuvent également lui être communiquées sur simple demande par téléphone, courrier électronique ou courrier postal.</p>

                        <p>1.4.	Toute Commande de Services implique, de la part du Client, l'acceptation sans réserve des présentes CGV ainsi que des conditions générales d'utilisation du Site (ci-après les « CGU »). Elles sont opposables au Client qui reconnaît, en cochant une case prévue à cet effet, en avoir eu connaissance et les avoir acceptées avant de passer Commande. Le contrat ainsi formé entre le Client et HYDROGEN est composé des présentes CGV, de la Commande et de la Confirmation de Commande, y compris toute annexe ou modification de celle-ci et tous les documents qui y sont intégrés par référence (notamment les CGU et la Politique de confidentialité d’HYDROGEN), l’ensemble de ces documents devant être considérés comme un tout indivisible (ci-après le « Contrat »).</p>

                        <p>1.5.	Il appartient donc au Client de vérifier l'exactitude de sa Commande et de rectifier ou signaler immédiatement toute erreur.</p>

                        <p>1.6.	La vente des Services ne sera considérée comme définitive qu'après l'envoi au Client de la Confirmation de Commande par HYDROGEN, par courrier électronique.</p>

                        <p>1.7.	Toute Commande de Services passée sur le Site constitue la formation d'un Contrat conclu à distance entre le Client et HYDROGEN.</p>

                        <p>1.8.	La validation de la Commande par sa confirmation vaut adhésion par le Client aux CGV en vigueur au jour de la Commande, dont la conservation et la reproduction sont assurées par HYDROGEN conformément à l'article 1127-2 du Code civil.</p>

                        <p>1.9.	Les renseignements figurant sur le Site d’HYDROGEN sont donnés à titre indicatif et sont révisables à tout moment. HYDROGEN est en droit d'y apporter toutes modifications qui lui paraîtront utiles. En cas de modification des CGV, la version applicable sera celle en vigueur à la date de la Commande dont une copie datée peut être remise à sa demande au Client.</p>

                        <p>1.10.	Les présentes CGV demeureront en vigueur jusqu'à ce qu’une nouvelle version soit éditée par HYDROGEN et vienne remplacer le présent document.</p>


                        <h3 className="mt-5 mb-5">ARTICLE 2.	EVOLUTIONS</h3>

                        <p>HYDROGEN se réserve le droit de modifier les présentes CGV, selon l’évolution technique du Site ou de ses offres de Services, ou en raison de l’évolution de la législation, à sa seule discrétion. Dès lors que des points essentiels touchant à la vente des Services viendraient à être modifiés, il sera demandé au Client, à l’occasion de toute nouvelle Commande, de lire et d’approuver lesdites nouvelles CGV.</p>

                        <p>D’une manière générale, l’utilisation du Site, ainsi que la vente des Services au Client sont toujours soumises à la version la plus récente des CGV postées sur le Site et accessibles au Client au moment de son utilisation du Site. Il lui appartient de consulter aussi souvent que nécessaire les CGV accessibles dans la zone qui lui est réservée.</p>
                   
                        <h3 className="mt-5 mb-5">ARTICLE 3.	TERRITOIRE ET DURÉE</h3>

                        <p>Le Site est ouvert à tous les pays du monde.</p>

                        <p>Dans l’hypothèse où le Site, les services qu’il propose ou la manière dont ils sont proposés seraient, en tout ou partie, illégaux au regard du droit national de résidence du Client, il appartient à ce dernier de renoncer à y accéder à partir de ce territoire ou d’y accéder depuis d'autres territoires où ils sont réputés légaux. Le Client qui choisit d'accéder au Site depuis un territoire où il est réputé en tout ou partie illégal, le fait de sa propre initiative, à ses risques et périls et il lui incombe d’assumer les conséquences de l’application des règles d’ordre public de son pays de résidence, sans en imputer la responsabilité à HYDROGEN.</p>

                        <p>Sauf disposition contraire indiquée dans la Commande et la Confirmation de Commande, le Contrat est conclu pour une durée de douze (12) mois.</p>

                        <p>Il se renouvellera automatiquement par tacite reconduction pour des périodes successives de même durée, sauf dénonciation du Contrat par l’une des Parties respectant un préavis d’un (1) mois avant chaque date anniversaire du Contrat. </p>

                        <h3 className="mt-5 mb-5">ARTICLE 4.	COMMANDES – TARIFS</h3>

                        <p><b>4.1.	Prix</b></p>

                        <p>Les Services sont fournis aux tarifs d’HYDROGEN en vigueur au jour de la passation de la Commande, tels qu’indiqués sur le Site. Ces tarifs sont fermes et non révisables pendant leur période de validité, telle qu'indiquée par HYDROGEN. HYDROGEN se réserve le droit de modifier les prix des Services à tout moment. Le prix des Services est fixé par HYDROGEN en fonction des spécificités de chaque catégorie de Client, notamment leur type d’exercice, leur nombre, etc. Cependant, les Services seront facturés sur la base des tarifs en vigueur au moment de la Commande du Client.</p>

                        <p>Ces prix sont exprimés en euros, nets et toutes taxes comprises.</p>

                        <p>Des conditions tarifaires particulières peuvent être pratiquées en fonction des spécificités demandées par le Client concernant, notamment, le type de Services souhaités, leur quantité et/ou la régularité de leur commande par le Client, les délais et conditions de règlement etc. L’ensemble de ces conditions particulières sera alors fixé dans le récapitulatif de la Commande du Client qui pourra donc en prendre connaissance avant de confirmer sa Commande.</p>

                        <p><b>4.2.	Passation de Commande</b></p>

                        <p>L’achat des Services disponibles sur le Site ne peut être réalisé que de manière électronique à partir de ce dernier (ci-après la « Commande »). L'enregistrement d'une Commande sur le Site d’HYDROGEN est réalisé lorsque le Client accepte les présentes CGV en cochant la case prévue à cet effet et valide sa Commande. Cette validation implique l'acceptation de l'intégralité des présentes CGV et constitue une preuve du Contrat de vente entre les Parties.</p>

                        <p>La prise en compte de la Commande et l'acceptation de celle-ci sont confirmées par l'envoi d'un e-mail par HYDROGEN (ci-après la « Confirmation de Commande »). Les données enregistrées dans le système informatique d’HYDROGEN constituent la preuve de l'ensemble des transactions conclues avec le Client.</p>

                        <p>Dès lors, les ventes ne sont parfaites qu'après acceptation expresse et par écrit par HYDROGEN de la Commande du Client, matérialisée par l’envoi de la Confirmation de Commande.</p>

                        <p><b>4.3.	Modification et annulation de la Commande</b></p>

                        <p>Les demandes de modification de sa Commande demandées par le Client ne pourront être prises en compte, dans la limite des possibilités d’HYDROGEN et à sa seule discrétion, uniquement si elles sont notifiées à cette dernière par écrit à l’adresse e-mail suivante : quarante-huit (48) heures au maximum après l’envoi par HYDROGEN de la Confirmation de Commande.</p>


                        <h3 className="mt-5 mb-5">ARTICLE 5.	CONDITIONS FINANCIERES</h3>

                        <p><b>5.1.	Modalités de paiement</b></p>

                        <p>Sauf disposition contraire contenu dans la Confirmation de Commande, le prix des Services est exigible en totalité immédiatement après validation de sa Commande par le Client. En tout état de cause : </p>

                        <ul>
                            <li>Les factures émises par HYDROGEN sont payables dans un délai maximum de trente (30) jours à compter de leur date d’émission.</li>
                        </ul>

                        <p>HYDROGEN ne sera pas tenu de procéder à la fourniture des Services commandés par le Client si celui-ci ne lui en paye pas le prix dans les conditions et selon les modalités indiquées au Contrat. De même, HYDROGEN se réserve le droit de refuser toute Commande d'un Client avec lequel existerait ou aurait existé un litige quant au paiement d'une Commande antérieure.</p>

                        <p>Le paiement s'effectue, selon la fréquence choisie par le Client, par prélèvement bancaire et conformément à la procédure décrite sur le Site.</p>

                        <p>Les paiements effectués par le Client ne seront considérés comme définitifs qu'après encaissement effectif par HYDROGEN des sommes dues par ce dernier.</p>

                        <p>5.2.	Retard de paiement</p>

                        <p>En cas de retard de paiement et/ou de versement des sommes dues par le Client au-delà du délai ci-dessus fixé, et après la date de paiement figurant sur la facture adressée à celui-ci, des pénalités de retard calculées au taux appliqué par la BCE lors de son opération de financement la plus récente majorée de dix (10) points de pourcentage seront, automatiquement et de plein droit, acquises à HYDROGEN, sans formalité aucune ni mise en demeure préalable.</p>

                        <p>Le retard de paiement entraînera l'exigibilité immédiate de la totalité des sommes dues, sans préjudice de toute autre action que HYDROGEN serait en droit d'intenter, à ce titre, à l'encontre du Client.</p>

                        <p>En cas de non-respect des conditions de paiement figurant ci-dessus, HYDROGEN se réserve en outre le droit de suspendre ou d'annuler la fourniture des Commandes en cours de la part du Client, de suspendre l'exécution de ses obligations et/ou de diminuer ou d'annuler les éventuelles remises accordées à ce dernier.</p>

                        <p>Enfin, une indemnité forfaitaire pour frais de recouvrement, dont la somme est fixée par décret, sera due, de plein droit et sans notification préalable par le Client, en cas de retard de paiement. HYDROGEN se réserve le droit de demander au Client une indemnisation complémentaire si les frais de recouvrement effectivement engagés dépassaient ce montant, sur présentation des justificatifs correspondants.</p>

                        <p>5.3.	Absence d’escompte</p>

                        <p>Aucun escompte ne sera pratiqué par HYDROGEN pour paiement avant la date figurant sur la facture dans un délai inférieur à celui mentionné aux présentes CGV.</p>

                        <p>5.4.	Remises et ristournes</p>

                        <p>HYDROGEN pourra faire bénéficier le Client de remises ou ristournes figurant à ses tarifs, en fonction des quantités et/ou de la nature des Services acquis par le Client, en une seule fois et un seul lieu, ou de la régularité de ses Commandes, cela restant à l’entière discrétion d’HYDROGEN. Les éventuelles remises ou ristournes qui seraient le cas échéant accordées par HYDROGEN au Client figureront dans la Commande et dans la Confirmation de Commande.</p>

                        <p>5.5.	Pénalité</p>

                        <p>Les Clients médecins et les Clients établissement de santé s’engagent à indiquer à HYDROGEN leurs périodes de recherche de remplacements au moins un mois et demi à l’avance, ce afin qu’HYDROGEN puisse délivrer ses Services à l’ensemble des Utilisateurs dans les meilleures conditions possibles, notamment en permettant à chacun d’eux de pouvoir anticiper et planifier leur calendrier. Dans l’hypothèse où les Clients ne respecteraient ce délai, HYDROGEN pourra leur appliquer la pénalité équivalent :</p>

                        <ul>
                            <li>Pour les Clients médecins diplômés installés, exerçant à titre individuel ou de manière groupée : à deux et demi pourcents (2,5%) du chiffre d’affaires qu’ils auront réalisé grâce à l’utilisation des Services</li>
                            <li>Pour les Clients établissement de santé employant des médecins diplômés : à cinq pourcents (5%) du chiffre d’affaires qu’ils auront réalisé grâce à l’utilisation des Services</li>
                        </ul>

                        <p>Si elle est appliquée par HYDROGEN, cette pénalité sera directement prélevée sur le compte bancaire que les Clients médecins et les Clients établissement de santé auront renseigné lors de la Confirmation de Commande, selon la procédure et les modalités décrites sur le Site.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 6.	FOURNITURE DES SERVICES</h3>

                        <p>6.1.	Aide au remplacement</p>

                        <p>Les Services commandés par le Client lui seront fournis immédiatement à compter de la réception par ce dernier de la Confirmation de Commande selon les modalités indiquées sur le Site ainsi que dans le Contrat, notamment dans les CGU, et en fonction des propositions de Remplacements qu’il aura indiqué à HYDROGEN.</p>

                        <p>Ces Services pour l’aide au remplacement, consistent en la réalisation par HYDROGEN des prestations suivantes :</p>

                        <ul>
                            <li>Recherche de remplaçants, durant ses absences.</li>
                            <li>Aide à la navigation sur le site HYDROGEN : https://hydrogen-rempla.com.</li>
                            <li>Edition des contrats de remplacement et envoie au conseil de l’ordre, au Remplaçant et au Client médecin.</li>
                            <li>Edition et conservation des certificats de rétrocession et envoie au Remplaçant et au Client médecin.</li>
                            <li>Proposition de formations médicales.</li>
                            <li>Transmission d’un fichier récapitulatif des recherches mensuelles réalisées par HYDROGEN.</li>
                            <li>Conseils sur le plan administratif (URSSAF, CARMF, CFE, CHORUS, déclaration des frais...).</li>
                        </ul>

                        <p>6.2.	Aide à la succession et à la collaboration</p>

                        <p>Ces Services pour l’aide à l’installation, consistent en la réalisation par HYDROGEN des prestations suivantes :</p>

                        <ul>
                            <li>Recherche de médecins qui souhaitent s’installer dans le secteur du Client.</li>
                            <li>Programmation de remplacements pour tester les conditions de travail.</li>
                            <li>Edition des contrats de remplacement et et envoie au conseil de l’ordre, au Remplaçant et au Client médecin.</li>
                            <li>Edition et conservation des certificats de rétrocession et envoie au Remplaçant et au Client médecin.</li>
                            <li>Proposition de formations médicales.</li>
                            <li>Transmission d’un fichier récapitulatif des recherches mensuelles réalisées par HYDROGEN.</li>
                            <li>Conseils sur le plan administratif (URSSAF, CARMF, CFE, CHORUS, déclaration des frais...).</li>
                            <li>Adresse un retour détaillé des médecins qui ont remplacé le Client médecin.</li>
                        </ul>

                        <h3 className="mt-5 mb-5">ARTICLE 7.	ASSISTANCE</h3>

                        <p>Le Client bénéficie gratuitement de l’assistance d’HYDROGEN, en cas de difficultés dans l’utilisation du Site en composant le 0643472087 (prix d’un appel local) entre 9h00 et 18h00 ou en adressant un e-mail au service support d’HYDROGEN à tout moment à l’adresse suivante : support@hydrogen-rempla.com. HYDROGEN répondra aux demandes d’assistance du Client dans ses meilleurs délais.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 8.	RESPONSABILITÉ</h3>

                        <p>La responsabilité d’HYDROGEN à l’égard du Client ne pourra être recherchée qu’en cas de manquement contractuel prouvé commis dans ou à l’occasion de l’exécution des prestations mises à sa charge au titre des présentes.</p>

                        <p>Elle ne pourra être tenue responsable des dommages indirects, des pertes financières ou des pertes d’exploitation subis par le Client ou par ses clients de ce fait. En conséquence, le Client renonce à recours à l’encontre d’HYDROGEN et contre ses assureurs au-delà de ces limites, tant en nature que sur les montants, et s’engage à faire renoncer à recours ses propres assureurs au-delà des mêmes limites.</p>

                        <p>Si toutefois la responsabilité d’HYDROGEN était retenue devant les tribunaux, le Client ne pourrait prétendre à d'autres indemnités et dommages et intérêts ou règlement quelconque, toutes causes et tous motifs confondus, qu'au montant des Services effectivement achetés et payés au cours des douze (12) derniers mois précédent le dommage.</p>

                        <p>A moins qu’il n’en soit autrement stipulé aux présentes, le Client ne pourra formuler une réclamation à l’encontre d’HYDROGEN après une période de douze (12) mois suivant la découverte de l'événement (ou des événements) ayant provoqué l'éventuelle responsabilité.</p>

                        <p>D’une manière générale, le Client reconnaît que la responsabilité d’HYDROGEN ne pourra être retenue et qu’il sera le seul responsable dans toutes les hypothèses d’utilisation des Services d’une manière non conforme aux lois et règlements en vigueur, aux règles et usages applicables à son secteur d’activité et/ou à toutes recommandations faites par HYDROGEN, notamment au sein de la documentation qu’il fournit au Client, y compris sur le Site et dans tous documents du Contrat.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 9.	NOTIFICATIONS – CONVENTION DE PREUVE</h3>

                        <p>Sauf dans les cas où une stipulation du présent Contrat en dispose autrement, il résulte d’un accord exprès entre les Parties que les échanges entre elles pourront intervenir par tous moyens, notamment par messagerie électronique aux adresses e-mail mentionnées dans leurs correspondances, factures et/ou bons de commandes, ou dans tout autre document.</p>

                        <p>Les Parties conviennent que l'impression papier d'un courriel permet de prouver valablement la teneur des échanges.</p>

                        <p>Les Parties mettent en œuvre toutes les mesures de sécurité permettant de garantir la disponibilité, l’intégrité et la confidentialité des fichiers e-mail adressés par internet.</p>

                        <p>Elles mettent en œuvre, parallèlement, toutes les mesures utiles, tels que pare feu et antivirus régulièrement mis à jour et correctement paramétrés, pour se protéger de la manière la plus efficace possible contre les intrusions, attaques et propagation des virus afin de garantir la disponibilité, l’intégrité et la confidentialité des fichiers e-mail reçus.</p>

                        <p>Les Parties sauvegarderont par la manière la plus appropriée et la plus sûre possible l’intégralité des messages transmis relatifs à l’objet du présent Contrat.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 10.	FORCE MAJEURE</h3>

                        <p>Les Parties ne pourront être tenues pour responsables si la non-exécution ou le retard dans l'exécution d'une quelconque de leurs obligations, telles que décrites dans les présentes découle d'un cas de force majeure, au sens de l'article 1218 du Code civil et de la jurisprudence des juridictions françaises.</p>

                        <p>La Partie constatant l'événement devra sans délai informer l'autre partie de son impossibilité à exécuter sa prestation et s'en justifier auprès de celle-ci. La suspension des obligations ne pourra en aucun cas être une cause de responsabilité pour non-exécution de l'obligation en cause, ni induire le versement de dommages et intérêts ou pénalités de retard.</p>

                        <p>L'exécution de l'obligation est suspendue pendant toute la durée de la force majeure si elle est temporaire et ne dépasse pas une durée de trente (30) jours. Par conséquent, dès la disparition de la cause de la suspension de leurs obligations réciproques, les Parties feront tous leurs efforts pour reprendre le plus rapidement possible l'exécution normale de leurs obligations contractuelles. A cet effet, la Partie empêchée avertira l'autre de la reprise de son obligation par lettre recommandée avec demande d'avis de réception ou tout acte extrajudiciaire. Si l'empêchement est définitif ou dépasse une durée de trente (30) jours, les présentes seront purement et simplement résolues.</p>

                        <p>Pendant cette suspension, les Parties conviennent que chacune d’elles pendra à sa charge ses propres frais engendrés par la situation.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 11.	PROPRIETE INTELLECTUELLE</h3>

                        <p>Tous les outils, méthodes, logiciels, algorithmes, savoir-faire, contenu éditorial (texte, photographie, images, vidéos, dessins, etc.), illustrations et d’une manière générale toute œuvre ou création susceptible d'appropriation intellectuelle utilisés par HYDROGEN dans le cadre de l’édition ou de l’exploitation du Site, et en particulier la Base de données, sont soit la propriété exclusive d’HYDROGEN, soit cette dernière dispose des droits nécessaires pour les utiliser et les concéder au Client dans le cadre de son utilisation du Site, ce conformément aux CGV et aux CGU.</p>

                        <p>A ce titre, le Client reconnait et accepte que, sauf disposition contraire exprimée par ailleurs aux présentes CGV et outre la licence d’utilisation du Site qui lui accordée par HYDROGEN dans le cadre des CGU, il n’acquiert aucun droit sur les actifs immatériels appartenant à HYDROGEN.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 12.	RESILIATION</h3>

                        <p>12.1.	Résiliation pour inexécution d'une obligation suffisamment grave</p>

                        <p>La Partie victime de la défaillance pourra, en cas d'inexécution suffisamment grave d'une quelconque des obligations incombant à l'autre Partie, notifier par lettre recommandée avec demande d'avis de réception à la Partie défaillante, la résolution fautive des présentes, quinze (15) jours après l'envoi d'une mise en demeure de s'exécuter restée infructueuse, et ce en application des dispositions de l'article 1224 du Code civil.</p>

                        <p>12.2.	Résiliation pour force majeure</p>

                        <p>La résolution de plein droit pour force majeure, ne pourra, nonobstant la clause résolution pour manquement d'une Partie à ses obligations figurant ci-après, avoir lieu que quinze (15) jours après la réception d'une mise en demeure notifiée par lettre recommandée avec demande d'avis de réception ou tout acte extrajudiciaire.</p>

                        <p>Toutefois, cette mise en demeure devra mentionner l'intention d'appliquer la présente clause.</p>

                        <p>12.3.	Dispositions communes aux cas de résolution</p>

                        <p>Il est expressément convenu entre les Parties que le débiteur d'une obligation de payer aux termes des présentes CGV, sera valablement mis en demeure par la seule exigibilité de l'obligation, conformément aux dispositions de l'article 1344 du Code civil.</p>

                        <p>Les prestations échangées entre les Parties depuis la conclusion du Contrat et jusqu'à sa résiliation ayant trouvé leur utilité au fur et à mesure de l'exécution réciproque de celui-ci, elles ne donneront pas lieu à restitution pour la période antérieure à la dernière prestation n'ayant pas reçu sa contrepartie.</p>

                        <p>En tout état de cause, la Partie lésée pourra demander en justice l'octroi de dommages et intérêts.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 13.	CLAUSES GENERALES</h3>

                        <p><b>13.1.	Tolérance</b></p>

                        <p>Il est formellement convenu que toute tolérance ou renonciation d'une des Parties, dans l'application de tout ou partie des engagements prévus au présent Contrat, quelles que puissent en être la fréquence et la durée, ne saurait valoir modification du présent Contrat, ni générer un droit quelconque.</p>

                        <p><b>13.2.	Intégralité</b></p>

                        <p>Les présentes dispositions, en cela sont incluses les stipulations des Confirmations de Commande, constituent l’intégralité du Contrat des Parties et remplacent en tous points les accords, lettres d’intention, courriers et propositions antérieures entre elles, quelle qu’en soit la forme ou l’appellation et portant sur le même objet, à l’exception de ceux listés aux présentes.</p>

                        <p><b>13.3.	Divisibilité</b></p>

                        <p>Si l’une quelconque des dispositions du Contrat est annulée en tout ou partie, la validité des dispositions restantes du Contrat n’en sera pas affectée. Dans ce cas, les Parties devront, si possible, remplacer cette disposition annulée par une disposition valable correspondant à l’esprit et à l’objet du Contrat.</p>

                        <p><b>13.4.	Indépendance</b></p>

                        <p>Les Parties déclarent expressément qu'elles sont et demeureront pendant toute la durée de leurs rapports des partenaires commerciaux et professionnels indépendants, assumant chacun les risques de sa propre exploitation.</p>

                        <p><b>13.5.	Porte-Fort</b></p>

                        <p>Le Client s’engage à faire respecter les présentes CGV par ses employé(s), collaborateur(s), associé(s), filiale(s) ou société(s) mère(s).</p>

                        <h3 className="mt-5 mb-5">ARTICLE 14.	DROIT APPLICABLE - LANGUE DU CONTRAT</h3>

                        <p>De convention expresse entre les Parties, les présentes CGV et les opérations d'achat et de vente qui en découlent sont régies par le droit français, à l’exclusion de tout autre.</p>

                        <p>Elles sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues, seul le texte français ferait foi en cas de litige.</p>

                        <h3 className="mt-5 mb-5">ARTICLE 15.	LITIGES</h3>

                        <p>En vue de trouver ensemble une solution à tout litige qui surviendrait dans l'exécution du présent Contrat, les Parties conviennent de se réunir dans un délai de TRENTE (30) jours à compter de la réception d'une lettre recommandée avec demande d'avis de réception, notifiée par l'une des deux Parties, ou d’un courriel signé numériquement.</p>

                        <p>Si au terme d'un nouveau délai de trente (30) jours, les Parties ne parvenaient pas à se mettre d'accord sur un compromis ou une solution, en cas de difficulté d’exécution des présentes et de leur suite, les Parties conviennent de recourir avant toute saisine des juridictions à une convention de procédure participative telle que régie par les articles 2062 à 2068 du Code civil.</p>

                        <p>La procédure participative sera d’une durée maximum de trois (3) mois, renouvelable une fois pour la même durée. Pendant celle-ci, les Parties s’engageant à œuvrer conjointement et de bonne foi à la résolution amiable de leur éventuel différend.</p>

                        <p>Les Parties sont informées que l’absence de mise en œuvre de la procédure participative, prévue au présent paragraphe, rend irrecevable tout recours au juge pour qu’il statue sur le litige.</p>

                        <p><b>EN CAS D’ECHEC DE LA PROCEDURE PARTICIPATIVE DECRITE CI-AVANT, TOUS LES LITIGES AUXQUELS LE PRESENT CONTRAT ET LES ACCORDS QUI EN DECOULENT POURRAIENT DONNER LIEU, CONCERNANT TANT LEUR VALIDITE, LEUR INTERPRETATION, LEUR EXECUTION, LEUR RESOLUTION, LEURS CONSEQUENCES ET LEURS SUITES SERONT SOUMIS AU TRIBUNAL DU SIEGE SOCIAL D’HYDROGEN AU JOUR DE L’ASSIGNATION.</b></p>

                    </div>
                }
            </ModalBody>
        </Modal>
		);
	}
}
