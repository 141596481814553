import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import StepZilla from 'react-stepzilla';
import Step1 from '../form/Step1';
import DStep2 from './DStep2';
import DStep3 from './DStep3';
import DStep4 from './DStep4';
import Finish from '../Finish';
import GDPRModal from "../../../components/gdpr/GDPRModal";
import { getBy, add } from "../../../actions/global/global";
import { connect } from "react-redux";
import moment from 'moment';
import UserUtil from '../../../util/UserUtil';

class DSteps extends Component {

	constructor(props) {
		super(props);

		this.state = {};
		this.store = {};

		//if (process.env.REACT_APP_IS_PRODUCTION === "false") this.initStore();
	};

	componentDidMount() {
		if (this.props.match.params.id) {
			this.props.onGetBy('user/admin', "id", this.props.match.params.id, (res) => this.updateStoreFromUser(res));
		}
	};

	updateStoreFromUser = (res) => {
		if (res) {
			var user = Object.assign({}, res);
			var doctorCharacteristics = {}
			var officeTimeTable = Object.assign({}, res.officeTimeTable);
			
			if (user.birth_date) {
				user.birth_date = moment(user.birth_date).format("MM/DD/YYYY")
			}

			if(user.characteristics) {
				doctorCharacteristics = user.characteristics.validated_doctorCharacteristics.reduce((previousValue, item) => ({
					...previousValue,
					[item]: user.characteristics[item]
				}), {});

				UserUtil.setCharacs(doctorCharacteristics, user.characteristics);

				delete user.characteristics;
			}

			this.updateStore({
				user: user,
				doctorCharacteristic: doctorCharacteristics,
				officeTimeTable: officeTimeTable,
				_id: res._id
			});
			this.forceUpdate();
		}
	};

	initStore = () => {
		this.store = {
			user: {
				birth_date: "08/08/1990",
				role: "doctor",
				email: "pole_emploi@dot.net",
				first_name: "Isi",
				gender: "man",
				name: "Sani",
				password: "karateka87",
				confirmation_mot_de_passe: "karateka87",
				phone_mobile: "0674870987",
				phone_pro: "0945678934"
			},
			doctorCharacteristic: {
				consultation: "2",
				consultation_price: "25",
				consultations_per_week_on_avg: 125,
				geriatrics: "15",
				medical_software: "0",
				teleconsultation: "15",
				visits_per_week_on_avg: 13,
				RPPS: "11122233344",
				office_disposal: "1",
				// office_address_street: "Boulevard Gambetta",
				office_type: "3",
				other_software: "TestMedic",
				pediatrics: "55",
				secretariat: "2",
				duree_Moyenne_Visite: "01:00",
				sector: "2",
				visit_price: "35",
				sports_medicine: false,
				other_teleconsultation: "Autre logiciel",
				ecg: false,
				homeopathy: false,
				emergency_medicine: false,
				gynecology: false,
				allergology: false,
				osteopathy: false,
			},
			officeTimeTable: {
				fridayAfternoonEnd: "18:00",
				fridayAfternoonStart: "14:00",
				fridayMorningEnd: "12:00",
				fridayMorningStart: "08:00",
				mondayAfternoonEnd: "18:00",
				mondayAfternoonStart: "14:00",
				mondayMorningEnd: "12:00",
				mondayMorningStart: "08:00",
				saturdayMorning: true,
				thursdayAfternoonEnd: "18:00",
				thursdayAfternoonStart: "14:00",
				thursdayMorningEnd: "12:00",
				thursdayMorningStart: "08:00",
				tuesdayAfternoonEnd: "18:00",
				tuesdayAfternoonStart: "14:00",
				tuesdayMorningEnd: "12:00",
				tuesdayMorningStart: "08:00",
				wednesdayMorning: true,
				wednesdayMorningEnd: "12:00",
				wednesdayMorningStart: "08:00",
			}
		};
	};

	getStore() {
		return this.store;
	};

	updateStore(update) {
		this.store = {
			...this.store,
			...update,
		};
	};

	render() {

		var nameDStep1 = <FormattedMessage id="My.Info"/>;
		var nameDStep2 = <FormattedMessage id="My.Profile"/>;
		var nameDStep3 = <FormattedMessage id="My.Schedule"/>;
		var nameDStep4 = <FormattedMessage id="Conditions"/>;
		var nameStep5 = <FormattedMessage id="Confirmation.2"/>;

		var validate = true;

		const steps = [
			{ name: nameDStep1, component: <Step1 userRole={"doctor"} validate={validate} getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
			{ name: nameDStep2, component: <DStep2 validate={validate} getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
			{ name: nameDStep3, component: <DStep3 validate={validate} getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
			{ name: nameDStep4, component: <DStep4 onAdd={this.props.onAdd} validate={validate} getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
			{ name: nameStep5, component: <Finish userRole={"doctor"} getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
		];
		
		return (
			<div id="registration" className='example blue-bg'>

				<div className='step-progress'>

					<div className="topbar navbarbg text-center b-fix">
						<a href="/#" className="a-unstyled">
							<div className="hydrogen-logo-sm d-inline-block align-middle"></div>
							<div className="ml-3 bold d-inline-block align-middle fs-18 lp-3">HYDROGEN</div>
						</a>
					</div>

					<h4 className="text-white uppercase w-100 text-center lp-3 mt-4 mb-3"><FormattedMessage id="I.Register" /></h4>

					<StepZilla
						steps={steps}
						stepsNavigation={true}
						preventEnterSubmission={true}
						showNavigation={false}
					/>

					<GDPRModal />

				</div>

			</div>
		);
	};
};

function mapStateToProps(state) {
	return {
		user: state.global.user
	};
};

function mapDispatchToProps(dispatch) {
	return {
		onGetBy: (objName, fields, values, callback) => dispatch(getBy(objName, fields, values, callback)),
        onAdd: (objName, obj, cbk) => dispatch(add(objName, obj, cbk))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DSteps));