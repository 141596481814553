import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage } from 'react-intl';
import DoctorSecretariat from '../../../enum/DoctorSecretariat';
import DoctorOfficeType from '../../../enum/DoctorOfficeType';
import ConsultationsMode from '../../../enum/ConsultationsMode';
import RadiologistMedicalSoftwares from '../../../enum/RadiologistMedicalSoftwares';
import Teleconsulation from '../../../enum/Teleconsultations';
import 'ladda/dist/ladda-themeless.min.css';

export default class RadiologistMoreDetailsProfile extends Component {

    render() {
        const { radiologistCharacteristic } = this.props;

        return (
            <React.Fragment>

                <h5 className="">
                    <b><FormattedMessage id="More.Details" /></b>
                </h5>

                <p className="mt-0 mb-0">
                    <FormattedMessage id="Secretariat" />:&nbsp; <b>
                    <FormattedMessage id={DoctorSecretariat.getLabel(radiologistCharacteristic.secretariat)} /></b>
                </p>

                <p className="mt-0 mb-0">
                    <FormattedMessage id="Office.Type" />:&nbsp; <b>
                    <FormattedMessage id={DoctorOfficeType.getLabel(radiologistCharacteristic.office_type)} /></b>
                </p>

                <p className="mt-0 mb-0"><FormattedMessage id="Interpretation" />:&nbsp;
                    <b><FormattedMessage id={ConsultationsMode.getLabel(radiologistCharacteristic.interpretation)} /></b>
                </p>

                <p className="mt-0 mb-0"><FormattedMessage id="Medical.Software" />:&nbsp;
                        {(radiologistCharacteristic.radiologist_medical_softwares === "0") ?
                        <b>{radiologistCharacteristic.other_software}</b>
                        :
                        <b><FormattedMessage id={RadiologistMedicalSoftwares.getLabel(radiologistCharacteristic.radiologist_medical_softwares)} /></b>
                    }
                </p>
                {radiologistCharacteristic.teleconsultation && 
                    <p className="mt-0 mb-0"><FormattedMessage id="Teleconsultation" />:&nbsp;
                        <b><FormattedMessage id={Teleconsulation.getLabel(radiologistCharacteristic.teleconsultation)} /></b>
                    </p>
                }

                </React.Fragment >
        );
    };

}