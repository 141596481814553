var values = [
    { label: "Other", value: "0" },
    { label: "AlmaPro", value: "1" },
    { label: "Altyse", value: "2" },
    { label: "Axisanté", value: "3" },
    { label: "CLM", value: "4" },
    { label: "Crossway", value: "5" },
    { label: "Dr.Santé", value: "6" },
    { label: "Éo.Médecin", value: "7" },
    { label: "Fisimed", value: "8" },
    { label: "Hellodoc", value: "9" },
    { label: "Hypermed", value: "10" },
    { label: "Magic.Med", value: "11" },
    { label: "Maidis", value: "12" },
    { label: "Méd’oc", value: "13" },
    { label: "Médicab", value: "14" },
    { label: "Médicawin", value: "15" },
    { label: "Médiclick", value: "16" },
    { label: "Médimust", value: "17" },
    { label: "Médipratik", value: "18" },
    { label: "Médistory", value: "19" },
    { label: "MLM", value: "20" },
    { label: "Weda", value: "21" },
    { label: "Shaman", value: "22" },
    { label: "None", value: "23"},
];

export default class MedicalSoftware {

    static values() {
        return values;
    }

    static getLabel(nbr) {
        for (let value of values) {
            if (value.value === nbr) return value.label;
        }
    }

};