
import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import CGUs from './CGUs';
import Confidentiality from './Confidentiality';



class Terms extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            category: "CGUs"
        };
    }

    render() {
        const { category } = this.state;

        return (
            <div id="registration" className='example blue-bg'>

                <div className='step-progress'>

                <div className="topbar navbarbg text-center b-fix">
						<a href="/#" className="a-unstyled">
							<div className="hydrogen-logo-sm d-inline-block align-middle"></div>
							<div className="ml-3 bold d-inline-block align-middle fs-18 lp-3">HYDROGEN</div>
						</a>
					</div>

                    <Row className="step step1 mt-5 justify-content-md-center ">

                        <Col lg="8">

                            <Card>

                                <CardBody className="pl-5 pr-5 pb-5 pt-4">

                                    <Row className="text-center mb-4 mt-0 p-0 text-black font-big">
                                        <Col sm="6" className="mt-0">
                                            <div
                                                onClick={() => this.setState({ category: "CGUs" })}
                                                className={"link-hoverable text-black " + ((category === "CGUs") ? "link-active" : "")}>
                                                <FormattedMessage id="CGUs" />
                                            </div>
                                        </Col>

                                        <Col sm="6">
                                            <div
                                                onClick={() => this.setState({ category: "Confidentiality" })}
                                                className={"link-hoverable text-black " + ((category === "Confidentiality") ? "link-active" : "")}>
                                                <FormattedMessage id="Confidentiality" />
                                            </div>
                                        </Col>
                                    </Row>

                                    <hr/>
                                    <br/><br/>

                                    {(category === "CGUs") && <CGUs />}
                                    {(category === "Confidentiality") && <Confidentiality />}

                                </CardBody>

                            </Card>

                        </Col>

                    </Row>

                </div>

            </div>
        );
    }
}



export default Terms;
