import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Noty from 'noty';
import { add } from "../../actions/global/global";
import Subscription from '../../enum/Subscription';

class AddCodeModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            code_subscription: null,
            error: null
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
		event.preventDefault();
		let fieldname = event.currentTarget.name;
		this.setState({
			[fieldname]: event.currentTarget.value
		})
	}

    handleSubmit(e) {
		e.preventDefault();

        if (this.state.code_subscription === '') return;
        this.setState({ error: null });

        let subscription = { 
			user_id: this.props.user._id,
			role: this.props.user.role,
            code_subscription: this.state.code_subscription
		};

		this.props.onAdd("subscription/code", subscription, (result) => {
            if (result.code === false) {
                this.setState({ 
                    error: 'Le code que vous avez renseigné est incorrect.'
                })
            } else {
                let choice = "replacement"
                switch (result.choice) {
                    case 5:
                    case 6:
                    case 7:
                        choice = "replacement";
                        break;
                    case 9:
                    case 10:
                    case 11:
                        choice = "succession"
                        break;
                    default:
                        choice = "replacement"
                }
                this.props.close(true, choice);
                new Noty({
                    type: "info",
                    layout: 'topRight',
                    theme: 'bootstrap-v4',
                    text: this.props.intl.formatMessage({ id: "Subscribe.Validation" }, { choice_text: Subscription.getLabel(this.state.choice) }),
                    timeout: 6000,
                }).show();
            }
		});
	}

	render() {

		return (
            <Modal isOpen={true} toggle={this.props.close} className="simple-modal modal-lg simple-modal-white srounded modalCustom">

                <ModalHeader className="header-modal-choice">
                    <div className="text-left">
                        <button type="button" onClick={this.props.close} className="close d-inline-block" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                </ModalHeader>

                <ModalBody className="justify-content-center">
                    <div>
                        <h5 className="w-100 text-center mb-5">Entrez votre code</h5>


                        <div className="form-group row">
                            <label className="col-4 col-form-label" htmlFor="code_subscription"><FormattedMessage id={"I.Have.A.Code"} /></label>
                            <div className="col-8">
                                <input className="form-control" placeholder="Entrez votre code" onChange={this.handleChange} type="text" name="code_subscription" value={this.state.code_subscription} />
                            </div>
                        </div>

                        {this.state.error && 
                            <div className="bg-white text-center text-danger col-md-12">
                                {this.state.error}
                            </div>
                        } 

                        <div className="row justify-content-center">
                            <input className="ladda-button btn srounded pl-5 pr-5 pt-2 pb-2 text-white bg-green btn text-center mx-auto" id="submitSubscription" onClick={this.handleSubmit} type="submit" value="Envoyez" />
                        </div>

                    </div>

                </ModalBody>
            </Modal>
		);
	}
}

const mapStateToProps = state => {
	return {
        user: state.global.user,
        subscriptions: state.global.subscriptions
	}
}

const mapDispatchToProps = dispatch => {
	return {
        onAdd: (objName, obj, cbk) => dispatch(add(objName, obj, cbk))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddCodeModal));