var values = [
    { label: "Consultation.Free", value: "1" },
    { label: "Consultation.On.Appoitment", value: "2" },
    { label: "Consultation.Free.And.On.Appointment", value: "3" }
];

export default class ConsultationsMode {

    static values() {
        return values;
    }

    static getLabel(nbr) {
        for (let value of values) {
            if (value.value === nbr) return value.label;
        }
    }

};