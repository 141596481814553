import React from 'react';
import { Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap';

export default class AddPatientStep2 extends React.Component {

	constructor(props) {
		super(props);
		const { criteria_non_inclusion } = this.props.getStore()
		this.state = {
			criteria_non_inclusion: criteria_non_inclusion || Array.from(this.props.criteria, (criteria) => {
				criteria.checked = null
				return criteria
			})
		}

		this.onSubmit = this.onSubmit.bind(this);
		this.back = this.back.bind(this);

		this.form = React.createRef();
	}

	componentDidMount() {
		let criteria = this.props.criteria.filter((criteria) => {
			return criteria.isVisible
		})
		if(!this.props.criteria || criteria.length === 0) {
			this.props.updateStore(this.state, 2);
			this.props.jumpToStep(2);
		}
	}

	onChange(event, key) {
		const { checked } = event.currentTarget;
		let criteria_non_inclusion = Array.from(this.state.criteria_non_inclusion)
		criteria_non_inclusion[key].checked = checked
		this.setState({
			criteria_non_inclusion
		}, () => this.props.updateStore(this.state, 2))
	}

	onSubmit(event) {
		event.preventDefault()
		this.props.updateStore(this.state, 2);
		this.props.jumpToStep(2);
	}

	back() {
		this.props.updateStore(this.state, 0);
		this.props.jumpToStep(0)
	}

	isValidated() {
		return this.form.current.reportValidity();
	}

	render() {

		return (
			<Row className="step step1 mt-2 justify-content-md-center ">
				 <p className="color-red bold mb-5">** L'ensemble des critères doit être absent chez le patient **</p>
				<Col lg="10">
					<Form innerRef={this.form} onSubmit={this.onSubmit}>
						{this.state.criteria_non_inclusion && this.state.criteria_non_inclusion.map((criteria, key) => {
							if(criteria.isVisible) return(
								<FormGroup row key={"form-group-" + key}>
									<Label md="10">{criteria.text}</Label>
									<Col md="2">
										<Label className="custom-switch custom-switch-success">
											<Input type="checkbox" checked={criteria.checked} onChange={(event) => this.onChange(event, key)} required />
											<span className="custom-switch-slider rounded-circle" data-label-on="OUI" data-label-off="NON"></span>
										</Label>
									</Col>
								</FormGroup>
							)
							return null
						})}
						<Row className="mx-auto form-buttons">
							<Button color="secondary" className="btn btn-ladda lowercase srounded pl-5 pr-5 pt-2 pb-2 bg-grey text-white mx-auto" onClick={this.back}>Précédent</Button>
							<Button color="success" className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green mx-auto" type="submit">Suivant</Button>
						</Row>
					</Form>
				</Col>
			</Row>
		);
	};
};