import React from 'react';
import { connect } from 'react-redux';
import { Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import AppointmentUtil from '../../../util/AppointmentUtil';
import { addExceptionalOpen, editExceptionalOpen, deleteExceptionalOpen } from '../../../actions/exceptionalOpen/exceptionalOpen';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from 'moment';

const localizer = momentLocalizer(moment);

class SModalExceptionalOpen extends React.Component {

    constructor(props) {
        super(props);

        moment.locale('fr');

        let date = moment(this.props.dateNewAppointmentSelected.start).format('YYYY-MM-DD');
        let start_date = moment(this.props.dateNewAppointmentSelected.start).format('HH:mm');
        let end_date = moment(this.props.dateNewAppointmentSelected.end).format('HH:mm');

        if (this.props.dateNewAppointmentSelected.original) {
            start_date = moment(date).set({
                hour: moment(this.props.dateNewAppointmentSelected.original.start_date, 'HH:mm').hour(),
                minute: moment(this.props.dateNewAppointmentSelected.original.start_date, 'HH:mm').minute(),
            }).format('HH:mm');

            end_date = moment(date).set({
                hour: moment(this.props.dateNewAppointmentSelected.original.end_date, 'HH:mm').hour(),
                minute: moment(this.props.dateNewAppointmentSelected.original.end_date, 'HH:mm').minute(),
            }).format('HH:mm');
        }

        this.state = {
            date: date,
            start_date: start_date,
            end_date: end_date,
            error: '',
            closeModal: false,
            deleteModal: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.dayEventCustom = this.dayEventCustom.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this);
        this.closeModalDelete = this.closeModalDelete.bind(this);
        this.closeModalSave = this.closeModalSave.bind(this);
    };

    customToolBar() {
        return [];
    }

    dayEventCustom(event) {
        let css = 'pt-1 pl-1';
        let content = moment(event.event.start).format('HH:mm') + ' ' + event.title;

        if(event.event.css === 'replacement') {
            content = event.event.title;
            css = 'pt-1 bg-occasional';
        } else if (event.event.css === 'emptyAppointment') {
            content = moment(event.event.start).format('HH:mm') + ' ' + event.event.title;
        }

        return (
            <div className={'text-color-black ' + css}>
                {content}
            </div>
        )
    }

    closeModal() {
        this.setState({
            closeModal: true
        })
    }

    closeModalSave() {
        this.setState({
            closeModal: false
        })
    }

    closeModalDelete() {
        this.setState({
            deleteModal : false
        })
    }

    handleChange(event) {
        let { name, value } = event.currentTarget;

        let error = false;
        if (name === 'start_date') {
            let startDate = moment(this.state.date).set({
                hour: moment(value, 'HH:mm').hour(),
                minute: moment(value, 'HH:mm').minute(),
            });

            let endDate = moment(this.state.date).set({
                hour: moment(this.state.end_date, 'HH:mm').hour(),
                minute: moment(this.state.end_date, 'HH:mm').minute(),
            });

            if (startDate.isSameOrAfter(endDate, 'minutes')) {
                error = true;
            }
        } 

        if (name === 'end_date') {
            let startDate = moment(this.state.date).set({
                hour: moment(this.state.start_date, 'HH:mm').hour(),
                minute: moment(this.state.start_date, 'HH:mm').minute(),
            });

            let endDate = moment(this.state.date).set({
                hour: moment(value, 'HH:mm').hour(),
                minute: moment(value, 'HH:mm').minute(),
            });

            if (startDate.isSameOrAfter(endDate, 'minutes')) {
                error = true;
            }
        }

        if (error === true) {
            this.setState({
                error: 'Vous ne pouvez pas avoir un horaire de fin avant l\'horaire de début'
            })
            return;
        }

        this.setState({
            [name]: value,
            error: ''
        })
    }

    onDelete() {
        this.setState({
            deleteModal: true
        })
    }

    onDeleteConfirmation() {
        this.props.deleteExceptionalOpen(this.props.dateNewAppointmentSelected.original._id, this.props.close());
    }

    onSubmit() {
        let exceptionalOpen = {
            date: this.state.date,
            user_id: this.props.user._id,
            start_date: this.state.start_date,
            end_date: this.state.end_date
        }

        this.props.arrayAgreements.forEach((agreement) => {
            if (moment(agreement.start).isSame(this.state.date, 'day')) {
                exceptionalOpen.doctor_id =  agreement.original.doctor_id;
            }
        })

        // cas d'une édition
        if (this.props.dateNewAppointmentSelected.original) {
            exceptionalOpen._id = this.props.dateNewAppointmentSelected.original._id;
            this.props.editExceptionalOpen(exceptionalOpen, this.props.close());
        } else {
            this.props.addExceptionalOpen(exceptionalOpen, this.props.close());
        }

        this.props.closeShowViewExceptionalOpen();
    }

    render() {
        const { arrayAgreements, appointmentsByUsers } = this.props;
        const appointmentsEvents = (appointmentsByUsers && appointmentsByUsers.length) ? AppointmentUtil.formatAppointments(appointmentsByUsers, this.props.user._id) : [];

        let concatEvents = arrayAgreements.concat(appointmentsEvents);

        return (
            <>
                <Modal size="lg" isOpen={true} toggle={this.props.dateNewAppointmentSelected.original ? this.closeModal : this.props.close} className="simple-modal simple-modal-white srounded modalAppointmentCustom">

                    <ModalHeader className="header-modal-choice">
                        <div className="text-left">
                            <button type="button" onClick={this.props.dateNewAppointmentSelected.original ? this.closeModal : this.props.close} className="close d-inline-block" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div>
                            Ajouter une ouverture exceptionnelle du cabinet
                        </div>
                    </ModalHeader>

                    <ModalBody className="row">
                        <Col sm="4" id="calendarModalNewAppointment">
                            <Calendar
                                components={{ 
                                    toolbar: this.customToolBar,
                                    day: {
                                        event: this.dayEventCustom,
                                    },
                                }}
                                formats={{
                                    eventTimeRangeFormat: range =>
                                    ``
                                }}
                                renderable
                                popup={true}
                                step={15}
                                slots={4}
                                min={new Date(0, 0, 0, 7, 0, 0)}
                                max={new Date(0, 0, 0, 20, 0, 0)}
                                localizer={localizer}
                                defaultDate={new Date(this.state.date)}
                                date={new Date(this.state.date)}
                                onView={{}}
                                events={concatEvents}
                                view={'day'}
                                views={[
                                    'day'
                                ]}
                                slotPropGetter={(date) => AppointmentUtil.customSlotPropGetterDoctor(date, this.props.arrayOfficeHours, this.props.exceptionalOpen, this.props.exceptionalClose)}
                                culture={'fr'}
                                onNavigate={[]}
                                dayLayoutAlgorithm="no-overlap"
                            />
                        </Col>

                        <Col sm="8">
                            <div className='jumbotron'>
                                <FormGroup row className="justify-content-center pt-2">
                                    <Label lg={2}>Date du créneau</Label>
                                    <Col lg={6}>
                                        <Input type="date" name="date" value={this.state.date} onChange={this.handleChange} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="justify-content-center pt-2">
                                    <Label lg={2}>Heure de début</Label>
                                    <Col lg={6}>
                                        <Input type="time" name="start_date" value={this.state.start_date} onChange={this.handleChange} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="justify-content-center pt-2">
                                    <Label lg={2}>Heure de fin</Label>
                                    <Col lg={6}>
                                        <Input type="time" name="end_date" value={this.state.end_date} onChange={this.handleChange} />
                                    </Col>
                                </FormGroup>
                            </div>

                            {this.state.error && 
                                <div className='text-danger'>
                                    {this.state.error}
                                </div>
                            }

                            <Button color="secondary" onClick={this.props.close}>Annuler</Button>
                            <Button color="success" onClick={this.onSubmit}>
                                {this.props.dateNewAppointmentSelected.original ?
                                    'Enregistrer'
                                    : 
                                    'Ajouter'    
                                }
                            </Button>
                            {this.props.dateNewAppointmentSelected.original &&
                                <Button color="danger" onClick={this.onDelete}>Supprimer</Button>
                            }
                        </Col>

                    </ModalBody>
                </Modal>

                {this.state.closeModal === true && 
                    <Modal size="lg" isOpen={true} toggle={this.closeModalSave} className="simple-modal simple-modal-white srounded modalCustom">

                        <ModalHeader className="header-modal-choice">
                            <div className="text-left">
                                <button type="button" onClick={this.closeModalSave} className="close d-inline-block" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div>
                                Validation
                            </div>
                        </ModalHeader>

                        <ModalBody className="row">
                            <Col sm="12">
                                Les modifications effectuées n’ont pas été enregistrée. Souhaitez-vous les enregistrer ?
                            </Col>

                        </ModalBody>

                        <ModalFooter className="justify-content-center">
                            <Button color="secondary" onClick={this.props.close}>Quitter</Button>
                            <Button color="success" onClick={this.onSubmit}>Enregistrer</Button>
                        </ModalFooter>
                    </Modal>
                }

                {this.state.deleteModal === true && 
                    <Modal size="lg" isOpen={true} toggle={this.closeModalDelete} className="simple-modal simple-modal-white srounded modalCustom">

                        <ModalHeader className="header-modal-choice">
                            <div className="text-left">
                                <button type="button" onClick={this.closeModalDelete} className="close d-inline-block" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div>
                                Suppression
                            </div>
                        </ModalHeader>

                        <ModalBody className="row">
                            <Col sm="12">
                                Souhaitez-vous supprimer l'ouverture exceptionnelle du {moment(this.state.date).format('DD/MM/YYYY')} de {this.state.start_date} à {this.state.end_date} ?
                            </Col>

                        </ModalBody>

                        <ModalFooter className="justify-content-center">
                            <Button color="secondary" onClick={this.closeModalDelete}>Quitter</Button>
                            <Button color="success" onClick={this.onDeleteConfirmation}>Supprimer</Button>
                        </ModalFooter>
                    </Modal>
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
        appointmentsByUsers: state.appointments.appointmentsByUsers
    }
};

const mapDispatchToProps = dispatch => {
    return {
		addExceptionalOpen: (exceptionalOpen, cbk) => dispatch(addExceptionalOpen(exceptionalOpen, cbk)),
		editExceptionalOpen: (exceptionalOpen, cbk) => dispatch(editExceptionalOpen(exceptionalOpen, cbk)),
		deleteExceptionalOpen: (exceptionalOpenId, cbk) => dispatch(deleteExceptionalOpen(exceptionalOpenId, cbk)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SModalExceptionalOpen);