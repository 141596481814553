import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import StepZilla from 'react-stepzilla';
import Step1 from '../form/Step1';
import SecStep2 from './SecStep2';
import Finish from '../Finish';
import GDPRModal from "../../../components/gdpr/GDPRModal";
import { getBy } from "../../../actions/global/global";
import { connect } from "react-redux";
import { createSecretary } from "../../../actions/secretary/secretary";
import { secretaryAcceptManagement, getRegistrationInfo  } from "../../../actions/secretaryManagement/secretaryManagement";

/**
 * @description Looks like it both handle 
 */
class SecSteps extends Component {

    constructor(props) {
        super(props);

        this.state = {};
        this.store = {role: 'secretary'};

        this.submitSecretary = this.submitSecretary.bind(this);
        this.secretaryAcceptManagement = this.secretaryAcceptManagement.bind(this);
        this.getStore = this.getStore.bind(this);
        this.secretaryAcceptManagement = this.secretaryAcceptManagement.bind(this);

        if (process.env.REACT_APP_IS_PRODUCTION === "false") this.initStore();
    };

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.getRegistrationInfo(this.props.match.params.id, (res) => this.updateStoreFromSecretary(res));
        }
    }

    initStore () {
        const pwd = 'abc-12345678';
        this.store = {
            user: {
                birth_date: "08/08/1990",
                email: "secretary001@yopmail.com",
                first_name: "Dorian",
                gender: "woman",
                name: "Lombard",
                password: pwd,
                confirmation_mot_de_passe: pwd,
                phone_mobile: "0674870987",
                phone_pro: "0945678934"
            }
        };
    };

    updateStoreFromSecretary = (res) => {
        if (res && res.secretaryManagement[0]) {
            const secretary = Object.assign({}, res.secretaryManagement[0].secretary, {birth_date: ""});
            this.updateStore(secretary);

            this.forceUpdate();
        }
    }

    getStore() {
        return this.store;
    };

    updateStore(update) {
        this.store = {
            ...this.store,
            ...update,
        };
    };

    secretaryAcceptManagement(callback) {
        let data = this.store;
        this.props.secretaryAcceptManagement(data, callback);
    }

    submitSecretary(callback) {
        let data = this.store;

        data.user.accept_contacted = true;
        data.user.accept_contacted_clinicaltrials = true;
        data.user.name = data.user.name[0].toUpperCase() + data.user.name.slice(1);
        data.user.first_name = data.user.first_name[0].toUpperCase() + data.user.first_name.slice(1);

        delete data.user.confirmation_mot_de_passe;

        this.props.createSecretary(data.user, callback);
    }

    render() {
        const nameSecStep1 = <FormattedMessage id="My.Info" />;
        const nameSecStep2 = <FormattedMessage id="Conditions" />;
        const nameSecStep3 = <FormattedMessage id="Confirmation.2" />;

        const validate = true;

        const steps = [
            { name: nameSecStep1, component: <Step1 userRole={"secretary"} validate={validate} getStore={this.getStore} updateStore={(u) => { this.updateStore(u) }} secretaryAcceptManagement={(cbk) => this.secretaryAcceptManagement(cbk)} /> },
            { name: nameSecStep2, component: <SecStep2 validate={validate} getStore={this.getStore} updateStore={(u) => { this.updateStore(u) }} submitSecretary={(cbk) => this.submitSecretary(cbk)} /> },
            { name: nameSecStep3, component: <Finish getStore={this.getStore} /> },
        ];


        return (
            <div id="registration" className='example blue-bg'>

                <div className='step-progress'>

                    <div className="topbar navbarbg text-center b-fix">
                        <a href="/#" className="a-unstyled">
                            <div className="hydrogen-logo-sm d-inline-block align-middle"></div>
                            <div className="ml-3 bold d-inline-block align-middle fs-18 lp-3">HYDROGEN</div>
                        </a>
                    </div>

                    <h4 className="text-white uppercase w-100 text-center lp-3 mt-4 mb-3"><FormattedMessage id="I.Register" /></h4>

                    <StepZilla
                        steps={steps}
                        stepsNavigation={true}
                        preventEnterSubmission={true}
                        showNavigation={false}
                    />

                    <GDPRModal />

                </div>

            </div>
        );
    };
};

function mapStateToProps(state) {
    return {
        user: state.global.user
    };
};

function mapDispatchToProps(dispatch) {
    return {
        onGetBy: (objName, fields, values, callback) => dispatch(getBy(objName, fields, values, callback)),
        createSecretary: (data, cbk) => dispatch(createSecretary(data, cbk)),
        getRegistrationInfo: (data, cbk) => dispatch(getRegistrationInfo(data, cbk)),
        secretaryAcceptManagement: (data, cbk) => dispatch(secretaryAcceptManagement(data, cbk)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SecSteps));