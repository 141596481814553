import React from 'react';
import 'react-datetime/css/react-datetime.css';
import ProfileImages from "../../enum/ProfileImages";
import Stars from '../../components/misc/Stars';
import RatingEnt from '../../entities/RatingEnt';
import UserEnt from '../../entities/UserEnt';
import ReplacementEnt from '../../entities/ReplacementEnt';
import AgreementEnt from '../../entities/AgreementEnt';

export default class ProfileCard extends React.Component {

    render() {
        const { user, replacement, agreement } = this.props;

        if (!user) {
            return (
                <div className="clickable height-50 profile" style={{ marginTop: "8px" }}>
                    <img src={ProfileImages.getSrc()} alt="user img" className="d-inline" style={{ borderRadius: "50px" }} width="35" height="35" />
                </div>
            );
        }

        var hasConcludedAgreement = ReplacementEnt.concludedAgreement(replacement, user) || AgreementEnt.isConcluded(agreement);
        return (
            <>

                <div className="clickable height-50 profile" style={{ marginTop: "8px" }}>

                    <img src={ProfileImages.getSrc(user.image)} alt="user img" className="d-inline" style={{ borderRadius: "50px" }} width="50" height="50" />

                    <div className="d-inline-block ml-2 height-50 align-middle">
                        <div className="d-inline-block pl-2 pr-2 height-50 break-word ellipsis">
                            <b>{(hasConcludedAgreement) ? UserEnt.fullName(user) : UserEnt.pseudo(user)}</b>
                        </div>
                        <div className="d-inline-block pl-2 pr-2 height-50 align-top"><Stars nbr={RatingEnt.averageAllStars(user.rating)} /></div>
                    </div>

                </div>


            </>
        );
    };
}