
class EventManager {

    constructor() {
        this.cbks = {};
    }

    sub(source, event, cbk) {
        if (!this.cbks[event]) this.cbks[event] = {};
        this.cbks[event][source] = cbk;
    }

    unsub(source, event) {
        if (this.cbks[event]) delete this.cbks[event][source];
    }

    pub(event, param) {
        if (this.cbks[event]) {
            for (let cbk of Object.values(this.cbks[event])) cbk(param);
        }
    }

}

export default new EventManager();