import React from 'react';
import { Input } from 'reactstrap';
import LocalisationEnt from '../../entities/LocalisationEnt';

export default class SearchLocationInputClinical extends React.Component {

	constructor(props) {
        super(props);

		this.onChange = this.onChange.bind(this);
		this.handlePlaceSelect = this.handlePlaceSelect.bind(this);

        this.autoCompleteRef = React.createRef()

        this.autoComplete = null;

    }

    componentDidMount() {
        this.autoComplete = new window.google.maps.places.Autocomplete(
            this.autoCompleteRef.current,
            { types: [this.props.typeMap], componentRestrictions: {"country" : LocalisationEnt.getRegions()} ,fields: ["name", "address_component", "place_id", 'formatted_address'] }
        );
        this.autoComplete.setFields(["address_components", "formatted_address"]);
        this.autoComplete.addListener("place_changed", () => 
            this.handlePlaceSelect()
		);
    }

    handlePlaceSelect() {
        const addressObject = this.autoComplete.getPlace();
        const { formatted_address } = addressObject
        let city
        if (addressObject && addressObject.address_components) {
            addressObject.address_components.forEach((elem) => {
                if(elem.types.includes("locality")) city = elem.long_name
            })
            if (this.props.typeMap === 'address') {
                this.props.onChange(formatted_address);
            } else {
                this.props.onChange(city);
            }
        }
    }

    onChange(event) {
		const value = event.currentTarget.value;
        this.props.onChange(value);
    }

    render() {
        return (
            <Input
                innerRef={this.autoCompleteRef}
                type="text"
                id={this.props.name}
				name={this.props.name}
                value={this.props.value}
                onChange={this.onChange} 
                placeholder=''
                required={this.props.required} />
        );
    }
}