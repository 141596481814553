import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedHTMLMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, CardImg, Card, CardBody } from 'reactstrap';
import CalendarRetrocessionCertificate from './CalendarRetrocessionCertificate';
import RetrocessionCertificateSignature from './RetrocessionCertificateSignature';
import moment from 'moment';
import DateUtil from '../../util/DateUtil';
import ProfilesImages from "../../enum/ProfileImages";
import { getBy, clear } from "../../actions/global/global";

class RetrocessionCertificateHome extends Component {

    constructor(props) {
        super(props);

        let startDate = this.props.replacement.start_date;
        let endDate = this.props.replacement.end_date;
        let days_available = this.props.replacement.days_available;

        let days = this.props.replacement.type === 'regular' ? this.props.replacement.day[0] : [];

        if (this.props.replacement.applicants) {
            this.props.replacement.applicants.forEach((applicant) => {
                if (applicant.substitute_id === this.props.substitute_id) {
                    if (applicant.negociation && applicant.negociation.length > 0) {
                        let lastElement = applicant.negociation[applicant.negociation.length - 1];
                        startDate = lastElement.start_date;
                        endDate = lastElement.end_date;
                        days_available = lastElement.days_available;
                    }
                }
            })
        }

        let doctor, substitute;

        if (this.props.fromDoctor === true) {
            doctor = {
                name: this.props.user.name,
                first_name: this.props.user.first_name,
                address1: this.props.doctorCharacteristic.office_address_street,
                address2: this.props.doctorCharacteristic.office_address_postcode + ', ' + this.props.doctorCharacteristic.office_address_city,
                rpps: this.props.doctorCharacteristic.RPPS,
                image: this.props.user.image,
                doctorCharacteristic: this.props.doctorCharacteristic,
                gender: this.props.user.gender,
                doctor_id: this.props.user._id
            }
            substitute = {
                name: this.props.substitute.name,
                first_name: this.props.substitute.first_name,
                address1: this.props.substitute.address_street,
                address2: this.props.substitute.address_postcode + ', ' + this.props.substitute.address_city,
                rpps: this.props.substitute.RPPS,
                siret: this.props.substitute.siret,
                image: this.props.substitute.image,
                substituteCharacteristic: this.props.substitute,
                gender: this.props.substitute.gender,
                substitute_id: this.props.substitute.substitute_id
            }
        } else {
            doctor = {
                name: this.props.doctor.name,
                first_name: this.props.doctor.first_name,
                address1: this.props.doctor.office_address_street,
                address2: this.props.doctor.office_address_postcode + ', ' + this.props.doctor.office_address_city,
                rpps: this.props.doctor.RPPS,
                image: this.props.doctor.image,
                doctorCharacteristic: this.props.doctor,
                gender: this.props.doctor.gender,
                doctor_id: this.props.doctor.doctor_id
            }
            substitute = {
                name: this.props.user.name,
                first_name: this.props.user.first_name,
                address1: this.props.substituteCharacteristic.address_street,
                address2: this.props.substituteCharacteristic.address_postcode + ', ' + this.props.substituteCharacteristic.address_city,
                rpps: this.props.substituteCharacteristic.RPPS,
                siret: this.props.substituteCharacteristic.siret,
                image: this.props.user.image,
                substituteCharacteristic: this.props.substituteCharacteristic,
                gender: this.props.user.gender,
                substitute_id: this.props.user._id
            }
        }

        let daysAvailableFilter = days_available.filter((day) => {
            return moment(day.start).isSameOrBefore(moment(), 'day');
        })

        this.state = {
            start_date: startDate,
            end_date: endDate,
            days_available: daysAvailableFilter,
            days: days,
            modal: null,
            amount: '',
            error: '',
            type: this.props.replacement.type,
            doctor, 
            substitute
        }

        this.daysAvailableOriginal = daysAvailableFilter;
        this.changeDate = this.changeDate.bind(this);
        this.sign = this.sign.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.resetDate = this.resetDate.bind(this);
    }

    componentDidMount() {
        this.props.onGetBy("retrocessionCertificate", ["replacement_id"], [this.props.replacement._id])
    }

    componentDidUpdate(prevProps) {
        if (prevProps.retrocessionCertificate !== this.props.retrocessionCertificate) {
            if (this.props.retrocessionCertificate && this.props.retrocessionCertificate.length > 0) {
                this.props.retrocessionCertificate.forEach((certificate) => {
                    if (((certificate.substitute_signature && !certificate.doctor_signature) || (!certificate.substitute_signature && certificate.doctor_signature)) && !certificate.cancelled_by_doctor && !certificate.cancelled_by_substitute) {
                        this.setState({
                            amount: certificate.amount,
                            days_available: certificate.days_available
                        })
                    }
                })
            }
        }
    }

    closeModal() {
        this.setState({
            modal: null
        })
    }

    handleChange(event) {
        const {name, value} = event.currentTarget;
        if (name === 'amount' && value !== '') {
            this.setState({
                error: ''
            })
        } 

        if (name === 'amount' && value < 0) {
            return;
        }

		this.setState({
			[name]: value
		})
    }


    // Permet de changer la date à la volée
	changeDate(date) {
        if (date.isAfter(moment(), 'day')) {
            this.setState({
                error: 'Vous ne pouvez créer de certificat pour des dates ultérieures à la date du jour'
            })
            return;
        }

        if (this.state.days_available.length === 0) {
			let startDateTemp = date.toDate();
			let endDateTemp = date.hours(23).minutes(59).seconds(59).toDate();
			let days_available = DateUtil.getDaysAvailable(startDateTemp, endDateTemp, this.state.type, this.state.days, [], this.props.officeTimeTable);
			this.setState({
				start_date: startDateTemp,
				end_date: endDateTemp,
				days_available: days_available
			})
			return;
		}
        let startAndEndDays = DateUtil.startAndEndDays(this.state.days_available);
        // change la date de début
        if (date.isBefore(startAndEndDays.start, 'day')) {
            let diff = moment(date).diff(startAndEndDays.end, 'week') + 1;
            diff = Math.abs(diff);
            if (diff > 12) {
                this.setState({
                    error: 'Le remplacement ne peut pas excéder 12 semaines'
                })
    			return;
            }

            let days_available = DateUtil.getDaysAvailable(date, startAndEndDays.end, this.state.type, this.state.days);
			this.setState({
				start_date: date,
				end_date: startAndEndDays.end,
				days_available: days_available
			}, this.checkProposition)
        // change la date de fin
        }  else if (date.isAfter(startAndEndDays.end, 'day')) {
            let diff = moment(date).diff(startAndEndDays.start, 'week') + 1;
            diff = Math.abs(diff);
            if (diff > 12) {
                this.setState({
                    error: 'Le remplacement ne peut pas excéder 12 semaines'
                })
    			return;
            }
            let days_available = DateUtil.getDaysAvailable(startAndEndDays.start, date, this.state.type, this.state.days);

			this.setState({
				start_date: startAndEndDays.start,
				end_date: date,
				days_available: days_available
			}, this.checkProposition)
        } else {
            let days_available = Array.from(this.state.days_available);
			let days_available_filter = days_available.filter((day) => {
				return !moment(day.start).isSame(date, 'day')
			});

            // Il ne peut pas y avoir 0 jours de cochés
            if (days_available_filter.length === 0) return;
			if (days_available.length !== days_available_filter.length) {
                let startAndEndDaysFilter = DateUtil.startAndEndDays(days_available_filter);
				this.setState({
                    start_date: startAndEndDaysFilter.start,
                    end_date: startAndEndDaysFilter.end,
					days_available: days_available_filter
				})
			} else {
                // Si ce n'est pas une garde, on ne peut pas rajouter les dimanches
                if (this.state.type === 'occasional' && date.day() !== 0) {
                    // Si la date n'est pas dans le tableau, il faut la rajouter 
                    days_available.push({
                        start: date.toDate(),
                        end: date.hours(23).minutes(59).seconds(59).toDate()
                    });
                    this.setState({
                        days_available: days_available
                    })
                } else if (this.state.type === 'regular' && date.day() !== 0) {
                    let numberDay = DateUtil.dayToNbr(this.state.days);
                    if (numberDay === date.day()) {
                        days_available.push({
                            start: date.toDate(),
                            end: date.hours(23).minutes(59).seconds(59).toDate()
                        });
                        this.setState({
                            days_available: days_available
                        })
                    }
                } else if (this.state.type === 'guard') {
                    days_available.push({
                        start: date.toDate(),
                        end: date.hours(23).minutes(59).seconds(59).toDate()
                    });
                    this.setState({
                        days_available: days_available
                    })
                }
			}
        }
        this.setState({ disabled: false });
    }

    sign(disableSign) {
        if (disableSign === true) return;

        if (this.state.amount === '') {
            this.setState({
                error: 'Vous devez renseigner un montant'
            })
        } else if (this.state.days_available.length === 0) {
            this.setState({
                error: 'Vous devez sélectionner des jours'
            })
        } else {
            this.setState({
                modal: <RetrocessionCertificateSignature 
                    agreement={this.props.agreement}
                    fromDoctor={this.props.fromDoctor}
                    amount={this.state.amount}
                    days_available={this.state.days_available}
                    toggle={this.closeModal}
                    closeModalHome={this.props.toggle}
                    doctor={this.state.doctor}
                    substitute={this.state.substitute}
                    replacement={this.props.replacement}
                />,
                error: ''
            })
        }
    }

    resetDate() {
        this.setState({
            days_available: this.daysAvailableOriginal,
            error: ''
        })
    }

    render() {
        let { retrocessionCertificate } = this.props;

        let disableButtonSign = false;
        let waitForSign = false;
        let name;
        if (retrocessionCertificate && retrocessionCertificate.length > 0) {
            let lastRetrocessionCertificate = retrocessionCertificate[retrocessionCertificate.length - 1];
            if (this.props.fromDoctor === false) {
                if (lastRetrocessionCertificate.substitute_initials && lastRetrocessionCertificate.substitute_signature && !lastRetrocessionCertificate.cancelled_by_substitute && !lastRetrocessionCertificate.doctor_initials && !lastRetrocessionCertificate.doctor_signature) {
                    disableButtonSign = true;
                } else if (lastRetrocessionCertificate.doctor_initials && lastRetrocessionCertificate.doctor_signature && !lastRetrocessionCertificate.cancelled_by_doctor && !lastRetrocessionCertificate.substitute_initials && !lastRetrocessionCertificate.substitute_signature) {
                    waitForSign = true;
                    name = this.props.doctor.first_name + ' ' + this.props.doctor.name;
                }
            } else {
                if (lastRetrocessionCertificate.doctor_initials && lastRetrocessionCertificate.doctor_signature && !lastRetrocessionCertificate.cancelled_by_doctor && !lastRetrocessionCertificate.substitute_initials && !lastRetrocessionCertificate.substitute_signature) {
                    disableButtonSign = true;
                } else if (lastRetrocessionCertificate.substitute_initials && lastRetrocessionCertificate.substitute_signature && !lastRetrocessionCertificate.cancelled_by_substitute && !lastRetrocessionCertificate.doctor_initials && !lastRetrocessionCertificate.doctor_signature) {
                    waitForSign = true;
                    name = this.props.substitute.first_name + ' ' + this.props.substitute.name;
                }
            }
        }
        let colorButton = 'bg-green';
        if (disableButtonSign === true) {
            colorButton = 'bg-grey';
        }
        if (waitForSign === true) {
            colorButton = 'bg-blue'
        }

        return (
            <Modal isOpen={true} toggle={this.props.toggle} size="md" className="simple-modal simple-modal-white srounded">

                <ModalHeader toggle={this.props.onClose}>
                    <button type="button" className="close d-inline-block b-green" aria-label="Close" onClick={this.props.toggle} ><span aria-hidden="true">×</span></button>
                </ModalHeader>

                <ModalBody className="p-4">
                    <div className='row d-flex justify-content-around'>

                        <Card className="col-md-5 col-sm-12 jumbotron">
                            <div>
                                <h5><p className='bold'>Medecin installé</p></h5>
                                <CardImg className="custom-width-card"
                                    src={ProfilesImages.getSrc(this.state.doctor.image)}
                                    alt={this.state.doctor.name}
                                    title={this.state.doctor.name}
                                />
                            </div>
                            <CardBody>
                                <p className='bold'>{this.state.doctor.first_name} {this.state.doctor.name}</p>
                                <p>{this.state.doctor.address1}<br></br>{this.state.doctor.address2}</p>
                                <p>RPPS: {this.state.doctor.rpps}</p>
                            </CardBody>
                        </Card>

                        <Card className="col-md-5 col-sm-12 jumbotron">
                            <div>
                                <h5><p className='bold'>Medecin remplaçant</p></h5>
                                <CardImg className="custom-width-card"
                                    src={ProfilesImages.getSrc(this.state.substitute.image)}
                                    alt={this.state.substitute.name}
                                    title={this.state.substitute.name}
                                />
                            </div>
                            <CardBody>
                                <p className='bold'>{this.state.substitute.first_name} {this.state.substitute.name}</p>
                                <p>{this.state.substitute.address1}<br></br>{this.state.substitute.address2}</p>
                                <p>RPPS: {this.state.substitute.rpps}</p>
                                <p>SIRET: {this.state.substitute.siret}</p>
                            </CardBody>
                        </Card>

                        <div className='col-12 mt-3'>
                            <div className='d-flex ml-2'>
                                <div className='green-light justify-content-start'></div>
                                <div className='ml-1 justify-content-start custom-select-days'>Jours à rétrocéder par le Dr {this.state.doctor.name}</div>
                            </div>
                            {waitForSign === true && 
                                <div className='mb-4'>
                                    Dates renseignées par {name}
                                </div>
                            }
                            <CalendarRetrocessionCertificate
                                date={moment(this.state.start_date).startOf('month')}
                                startDate={this.state.start_date}
                                endDate={this.state.end_date}
                                days={this.state.days}
                                changeDate={this.changeDate}
                                days_available={this.state.days_available}
                                resetDate={this.resetDate}
                            />
                        </div>

                        <div className='col-6 mt-4 mb-5 w-50'>
                            <div className="input-group mb-3">
                                {waitForSign === true && 
                                    <div className='col-12'>
                                        Montant renseigné par {name}
                                    </div>
                                }
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">Montant à rétrocéder</span>
                                </div>
                                <input type="number" id="input-number-amount" name='amount' onChange={this.handleChange} className="form-control" placeholder="€" aria-label="Username" value={this.state.amount} aria-describedby="basic-addon1" required/>
                                {this.state.error &&
                                    <div className='error'>
                                        {this.state.error}
                                    </div> 
                                }
                            </div>
                        </div>
                    </div>
                    <div className="btn bg-red srounded lowercase pl-3 pr-3 text-white lp-2 d-inline-block m-2" onClick={this.props.toggle}>
                        <FormattedHTMLMessage id="Cancel" />
                    </div>
                    <div className={"btn srounded lowercase pl-3 pr-3 text-white lp-2 d-inline-block m-2 " + (colorButton)}
                        onClick={() => this.sign(disableButtonSign)} disabled={disableButtonSign === true ? true : false} >
                        {disableButtonSign === true ?
                            <FormattedHTMLMessage id="Already.Sign.Certificate" />
                            : 
                            <>
                                {waitForSign === true ?
                                    <FormattedHTMLMessage id="Wait.Sign.Retrocession.Certificate" />
                                    :
                                    <FormattedHTMLMessage id="Sign" />
                                }

                            </>
                        }
                    </div>
                    {this.state.modal}
                </ModalBody>

            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
        doctorCharacteristic: state.global.doctorCharacteristic,
        substituteCharacteristic: state.global.substituteCharacteristic,
        retrocessionCertificate: state.global.retrocessionCertificate
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetBy: (objName, params, values) => dispatch(clear(objName, getBy(objName, params, values)))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RetrocessionCertificateHome);