import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Card } from 'reactstrap';
import { clears, getsBy } from '../../../actions/global/global';
import PsychiatristProfile from "./PsychiatristProfile";
import PsychiatristTreatedPathologiesProfile from './PsychiatristTreatedPathologiesProfile';
import PsychiatristAboutProfile from './PsychiatristAboutProfile';
import PsychiatristMoreDetailsProfile from './PsychiatristMoreDetailsProfile';
import OfficeTimetable from '../../doctors/office/OfficeTimetable';

class PsychiatristAccountProfile extends Component {

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    render() {
        const { user, psychiatristCharacteristic, officeTimeTable } = this.props;

        if (!user || !psychiatristCharacteristic || !officeTimeTable) return this.loading();

        return (
            <React.Fragment>

                <React.Fragment>

                    <div className="w-100 text-white text-center uppercase mb-4 lp-2">
                        <h4><FormattedMessage id="My.Profile" /></h4>
                    </div>

                    <Row>

                        <Col sm="12" md="6">

                            <Card className="pt-5 rounded-0">
                                <PsychiatristProfile
                                    psychiatrist={user}
                                    psychiatristCharacteristic={psychiatristCharacteristic}
                                    officeTimeTable={officeTimeTable}
                                    showChar={false}
                                />
                            </Card>
                        </Col>

                        <Col sm="12" md="6">

                            <Card className="p-5 text-center rounded-0" style={{ minHeight: "639px" }}>

                                <div className="text-left mx-auto">

                                    <div className="text-center">
                                        <PsychiatristTreatedPathologiesProfile psychiatristCharacteristic={psychiatristCharacteristic} />
                                    </div>

                                    <div className="mt-4">
                                        <PsychiatristAboutProfile psychiatristCharacteristic={psychiatristCharacteristic} />
                                    </div>

                                    <div className="mt-5 mb-4">
                                        <PsychiatristMoreDetailsProfile psychiatristCharacteristic={psychiatristCharacteristic} />
                                    </div>

                                </div>

                            </Card>

                        </Col>

                        <Col sm="12" md="12">

                            <div className="max-w-800px mx-auto m-0 p-0">
                                <OfficeTimetable
									register={false}
                                />
                            </div>

                        </Col>
                    </Row>

                </React.Fragment>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
        psychiatristCharacteristic: state.global.psychiatristCharacteristic,
        officeTimeTable: state.global.officeTimeTable
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PsychiatristAccountProfile);