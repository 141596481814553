import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Col, Row, Collapse } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { adds } from "../../../actions/global/global";
import { Form, RangeInput, NotEmpty, RadioInput } from '@gferrand/react-forms';
import DateUtil from '../../../util/DateUtil';
import ReplacementEnt from '../../../entities/ReplacementEnt';
import AvailabilityEnt from '../../../entities/AvailabilityEnt';
import { availabilityOverlaps } from '../../../actions/availabilities/availabilities';
import { calendarEventOverlaps } from '../../../actions/calendarEvent/calendarEvent';
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import { concludedAgreementOverlaps } from '../../../actions/agreement/agreement';
import ModalChoiceDays from '../../calendar/ModalChoiceDays';
import moment from 'moment';

class AddAvailabilityModal extends React.Component {

	constructor(props) {
		super(props);

		let startDate = this.props.startDate;
		let endDate = this.props.endDate;

		let days_available = [];
		if (this.props.startDate !== undefined && this.props.endDate !== undefined) {
			days_available = DateUtil.getDaysAvailable(startDate, endDate, "occasional", [])
		} 

		this.state = {
			collapse: false,
			modal: null,
			availabilityOverlapError: null,
			calendarEventOverlapError: null,
			replacementOverlapError: null,
			loadingOverlap: true,
			startDate: startDate,
			endDate: endDate,
			days: [],
			days_available: days_available,
			type: "occasional",
			sameForGuard: false,
			error: null,
			errorSelectDayRegular: '',
            errorRegularDateAndDay: '',
		};

		this.date = moment().startOf('month');

		this.buildAvailabilityForm();
		this.changeDate = this.changeDate.bind(this);
		this.backToDateOfTheDay = this.backToDateOfTheDay.bind(this);
		this.cancelSelectedDays = this.cancelSelectedDays.bind(this);
		this.handleChange = this.handleChange.bind(this);
	};

	componentDidMount() {
		var period = {
			start: undefined,
			end: undefined
		}
		// Quand le user a selectionné une date ou une période
		if (this.props.endDate !== undefined) {
			period = ReplacementEnt.occasionalPeriod(this.props.startDate, this.props.endDate);
		} 
		// For availabilities other than guard, cannot end on a sunday
		if (DateUtil.isDay(period.start, 0)) period.start = DateUtil.decrementDays(period.start, 1);
		var data = {
			type: "occasional",
			radius_preference: this.props.substituteCharacteristic.radius_preference,
			radius_effort: this.props.substituteCharacteristic.radius_effort,
			retrocession_wished: this.props.substituteCharacteristic.retrocession_wished,
			start_date: this.props.startDate,
			end_date: this.props.endDate,
			day: DateUtil.nbrToDay((new Date(period.start) || new Date()).getDay()),
		};

		if (data.radius_preference > data.radius_effort) {
			this.setState({
				error: 'Votre zone d\'effort ne peut pas être plus petite que votre zone de confort'
			})
		}

		// Si y'a des dates, on check les overlaps
		if (data.start_date !== undefined || data.end_date !== undefined) {
			this.checkOverlap(data.start_date, data.end_date, data.type, this.state.days_available);
		}

		if (data.start_date !== undefined && moment(data.start_date).isBefore(moment().subtract(1, 'day'), 'day')) {
            this.setState({
                error: 'La date ne peut pas être inférieur à la date du jour'
			})
		}

		let days_available = [];
		if (this.props.startDate !== undefined && this.props.endDate !== undefined) {
			days_available = DateUtil.getDaysAvailable(this.props.startDate, this.props.endDate, this.state.type, this.state.days);
		} 

		this.setState({
			days_available: days_available
		})
	}

	buildAvailabilityForm() {
		var submitCbk = (data) => {
			// Si la case pour mettre les mêmes dispo sur garde est cochée
			if (this.state.sameForGuard === true && this.state.type !== 'guard') {
				data.sameForGuard = true;
			}
			data.start_date = this.state.startDate;
			data.end_date = this.state.endDate;
			if(data.day) {
				data.day = [data.day];
			}

			data.days_available = this.state.days_available;

			this.props.onAdds("availability", data, () => {
				this.props.toggle();

				new Noty({
					type: "info",
					layout: 'topRight',
					theme: 'bootstrap-v4',
					text: this.props.intl.formatMessage({ id: "Availability.Add.Success" }),
					timeout: 6000,
				}).show();
			});
		};

		var period = ReplacementEnt.occasionalPeriod(this.props.startDate, this.props.endDate);

		// For availabilities other than guard, cannot end on a sunday
		if (DateUtil.isDay(period.start, 0)) period.start = DateUtil.decrementDays(period.start, 1);
		
		var data = {
			type: "occasional",
			radius_preference: this.props.substituteCharacteristic.radius_preference,
			radius_effort: this.props.substituteCharacteristic.radius_effort,
			retrocession_wished: this.props.substituteCharacteristic.retrocession_wished,
			start_date: period.start,
			end_date: period.end,
			day: [],
		};

		this.availabilityForm = new Form({
			name: "availability",
			inputs: [

				new RadioInput("type", "Availability.Type",
					[
						{ label: "Occasional.Fem", value: "occasional" },
						{ label: "Regular.Fem", value: "regular" },
						{ label: "Guard.Fem", value: "guard" },
					], [NotEmpty]),
					
				new RadioInput("day", "Availability.Days",
				[{ label: "Monday.FirstLetter", value: "monday" },
				{ label: "Tuesday.FirstLetter", value: "tuesday" },
				{ label: "Wednesday.FirstLetter", value: "wednesday" },
				{ label: "Thursday.FirstLetter", value: "thursday" },
				{ label: "Friday.FirstLetter", value: "friday" },
				{ label: "Saturday.FirstLetter", value: "saturday" }],
				[NotEmpty]),
				new RangeInput("radius_preference", "Radius.Confort", 0, 100, 10, [NotEmpty], { suffix: "km", className: "blue-range-input" }),
				new RangeInput("retrocession_wished", "Retrocession.Wished.Confort", 60, 100, 2.5, [NotEmpty], { suffix: "%", className: "blue-range-input" }),
				new RangeInput("radius_effort", "Radius.Effort", 10, 100, 10, [NotEmpty], { suffix: "km", className: "blue-range-input" }),
				new RangeInput("retrocession_wished_effort", "Retrocession.Wished.Effort", 60, 100, 2.5, [NotEmpty], { suffix: "%", className: "blue-range-input" }),
			],
			submitCbk,
			changeCbk: (input) => this.validateAvailabilityForm(input),
			data
		});

		this.availabilityForm.hide("day");
	};

	validateAvailabilityForm(input) {
		const rawData = this.availabilityForm.getRawData();
		var { name, value } = input;
		if (moment(this.state.startDate).day() === 0 && moment(this.state.endDate).day() === 0 && moment(this.state.startDate).isSame(this.state.endDate) && input.value !== "guard") {
			this.setState({
				error: 'Vous ne pouvez pas poser une disponibilité occasionnelle le dimanche'
			})
		} else if (moment(this.state.startDate).day() === 0 && moment(this.state.endDate).day() === 0 && moment(this.state.startDate).isSame(this.state.endDate) && input.value === "guard") {
			this.setState({ 
				error: null
			})
		}
		if (input.name === "type" && input.value === "guard") {
			this.availabilityForm.setValue("retrocession_wished", this.props.substituteCharacteristic.guard_retrocession_wished);
			this.setState({ type: 'guard'});
		}

		if (name === 'radius_preference') {
			if (value > rawData.radius_effort) {
				this.setState({
					error: 'Votre zone d\'effort ne peut pas être plus petite que votre zone de confort'
				})
			} else {
				this.setState({
					error: null
				})
			}
		}

		if (name === 'radius_effort') {
			if (rawData.radius_preference > value) {
				this.setState({
					error: 'Votre zone d\'effort ne peut pas être plus petite que votre zone de confort'
				})
			} else {
				this.setState({
					error: null
				})
			}
		}

		if (name === "day") {
			this.setState({ 
				days: value,
				errorSelectDayRegular: '' 
			})
			this.checkOverlap(this.state.startDate, this.state.endDate, rawData.type, this.state.days_available);
			return;
		};

		// User switches replacement type, show / hide relevant fields
		if (name === "type") {
			if (value === "regular") {
				this.availabilityForm.show("day");

				this.setState({
					startDate: undefined,
					endDate: undefined,
					days: [],
					type: "regular",
					days_available: [],
					availabilityOverlapError: null,
					calendarEventOverlapError: null,
					replacementOverlapError: null,
					errorSelectDayRegular: ''
				})
			} else {
				this.availabilityForm.hide("day");
				this.setState({
					startDate: undefined,
					endDate: undefined,
					days: [],
					type: value,
					days_available: [],
					availabilityOverlapError: null,
					calendarEventOverlapError: null,
					replacementOverlapError: null,
					errorSelectDayRegular: ''
				})
			}

			//! Bug: hide/show doesn't happen without forcing the upd. Workaround. :(
			return this.forceUpdate();
		};

		// ===================================================
		// =================== Validation ====================
		// ===================================================

		AvailabilityEnt.validate(this.availabilityForm);
	};

	row(inputName) {
		return (
			<Row className="form-group">
				<Col md="5">{this.availabilityForm.getLabel(inputName)}</Col>
				<Col md="7">{this.availabilityForm.getInput(inputName)}</Col>
			</Row>
		);
	};

	pointingArrow = () => {
		if (!this.state.collapse) return <i className="fa fa-angle-down" />;
		return <i className="fa fa-angle-up" />
	}

	toggle = (e) => {
		e.preventDefault();
		this.setState({ 'collapse': !this.state.collapse });
	}

    // Permet de changer la date à la volée
	changeDate(date) {
		if (date.isBefore(moment().subtract(1, 'day'), 'day')) {
            this.setState({
                error: 'La date ne peut pas être inférieur à la date du jour'
			})
			return;
        } else {
            this.setState({
                error: null
            })
		}

		let daysNotAvailable = [];
		if (this.props.availabilitys && this.props.availabilitys.length > 0) {
			this.props.availabilitys.forEach((availability) => {
				if (availability.type === this.state.type) {
					daysNotAvailable = daysNotAvailable.concat(availability.days_available);
				}
			})
		}

		// Si aucune date sélectionné
		if (this.state.days_available.length === 0) {
			let startDateTemp = date.toDate();
			let endDateTemp = date.hours(23).minutes(59).seconds(59).toDate();
			let days_available = [];
			
            if (this.state.type === 'regular') {
                let dayInString = DateUtil.nbrToDay(moment(startDateTemp).day());
                if (this.state.days.length === 0) {
                    this.setState({
                        errorSelectDayRegular: 'Vous devez sélectionner un jour de la semaine avant de choisir vos dates'
                    })
                    return;
                } else if (dayInString !== this.state.days) {
                    this.setState({
                        errorRegularDateAndDay: 'Vous devez sélectionner une date correspondant au jour de la semaine que vous avez sélectionné'
                    })
                    return;
                } else {
                    endDateTemp = moment(startDateTemp).add(this.state.duration - 1, 'weeks');
                    days_available = DateUtil.getDaysAvailable(startDateTemp, endDateTemp, "regular", this.state.days, daysNotAvailable);
                    this.setState({
                        errorRegularDateAndDay: ''
                    })
                }
			} else {
				days_available = DateUtil.getDaysAvailable(startDateTemp, endDateTemp, this.state.type, this.state.days, daysNotAvailable);
			}

            this.checkOverlap(startDateTemp, endDateTemp, this.state.type, days_available);
			this.setState({
				startDate: startDateTemp,
				endDate: endDateTemp,
				days_available: days_available
			})
			return;
		} 

		// change la date de début
		if (date.isBefore(this.state.startDate)) {
			let days_available = DateUtil.getDaysAvailable(date, this.state.endDate, this.state.type, this.state.days, daysNotAvailable);
		    let startAndEndDays = DateUtil.startAndEndDays(days_available);
			this.checkOverlap(date, startAndEndDays.end, this.state.type, days_available);
			this.setState({
				startDate: startAndEndDays.start,
				endDate: startAndEndDays.end,
				days_available: days_available
			})
		// change la date de fin
		} else if (date.isAfter(this.state.endDate)) {
			let days_available = DateUtil.getDaysAvailable(this.state.startDate, date, this.state.type, this.state.days, daysNotAvailable);
		    let startAndEndDays = DateUtil.startAndEndDays(days_available);
			this.checkOverlap(startAndEndDays.start, date, this.state.type, days_available);
			this.setState({
				startDate: startAndEndDays.start,
				endDate: startAndEndDays.end,
				days_available: days_available
			})
		} else {
			let days_available = Array.from(this.state.days_available);
			let days_available_filter = days_available.filter((day) => {
				return !moment(day.start).isSame(date, 'day');
			});
			let startAndEndDays = DateUtil.startAndEndDays(this.state.days_available);

			// Ici on retire un jour
			if (days_available.length !== days_available_filter.length) {
				let startAndEndDaysFilter = DateUtil.startAndEndDays(days_available_filter);
				this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available_filter);
				this.setState({
					startDate: startAndEndDaysFilter.start,
                    endDate: startAndEndDaysFilter.end,
					days_available: days_available_filter
				})
			} else {
				let push = true;
				if (daysNotAvailable && daysNotAvailable.length > 0) {
					daysNotAvailable.forEach((elem) => {
						if (date.isSame(elem.start, 'day')) {
							push = false;
							return;
						}
					})
				}

				if (push === true) {
					// Si ce n'est pas une garde, on ne peut pas rajouter les dimanches
					if (this.state.type === 'occasional' && date.day() !== 0) {
						// Si la date n'est pas dans le tableau, il faut la rajouter 
						days_available.push({
							start: date.toDate(),
							end: date.hours(23).minutes(59).seconds(59).toDate()
						});
						this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available);
						this.setState({
							days_available: days_available
						})
					} else if (this.state.type === 'regular' && date.day() !== 0) {
						let numberDay = DateUtil.dayToNbr(this.state.days);
						if (numberDay === date.day()) {
							days_available.push({
								start: date.toDate(),
								end: date.hours(23).minutes(59).seconds(59).toDate()
							});
							this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available);
							this.setState({
								days_available: days_available
							})
						}
					} else if (this.state.type === 'guard') {
						days_available.push({
							start: date.toDate(),
							end: date.hours(23).minutes(59).seconds(59).toDate()
						});
						this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available);
						this.setState({
							days_available: days_available
						})
					}
				}
			}
		}
	}

	// Bouton qui annule la sélection de jours
	cancelSelectedDays() {
		this.setState({
			startDate: undefined,
			endDate: undefined,
			days_available: [],
			availabilityOverlapError: '', 
			calendarEventOverlapError: '',
			replacementOverlapError: '',
			error: ''
		})
	}

	// Bouton qui permet de revenir à la date du jour
	backToDateOfTheDay() {
		let startDateTemp = moment().startOf('day').toDate();
		let endDateTemp = moment().hours(23).minutes(59).seconds(59).toDate()
		
		let days_available = DateUtil.getDaysAvailable(startDateTemp, endDateTemp, this.state.type, this.state.days);
		this.checkOverlap(startDateTemp, endDateTemp, this.state.type, days_available);
		this.setState({
			startDate: startDateTemp,
			endDate: endDateTemp,
			days_available: days_available
		})
	}

	handleChange(event) {
		const value = event.currentTarget.name === 'sameForGuard' ? event.currentTarget.checked : event.currentTarget.value;
		const name = event.currentTarget.name;

		const rawData = this.availabilityForm.getRawData();
		// Si la case pour poser les mêmes date de garde est cochée
		if (value === true) {
			this.checkOverlap(rawData.start_date, rawData.end_date, 'guard', this.state.days_available);
		} else {
			this.checkOverlap(rawData.start_date, rawData.end_date, rawData.type, this.state.days_available);
		}

		this.setState({
		  [name]: value
		});
	}

	// ================================================================
	// ========================== VALIDATION ==========================
	// ================================================================
	checkOverlap = (start, end, type, days_available) => {
		if (!start || !end) return;

		this.setState({ loadingOverlap: true });
		
		let availabilityOverlapError = false;
		let calendarEventOverlapError = false;
		this.props.onAvailabilityOverlaps(start, end, type, days_available, (res) => {
			availabilityOverlapError = res.overlaps;
			this.props.onCalendarEventOverlaps(start, end, days_available, type, (res) => {
				calendarEventOverlapError = res.overlaps;
				this.setState({ availabilityOverlapError, calendarEventOverlapError, loadingOverlap: false });
				this.props.onConcludedAgreementOverlaps(start, end, type, undefined, days_available, (res) => {
					this.setState({ replacementOverlapError: res.overlaps, availabilityOverlapError, calendarEventOverlapError, loadingOverlap: false });
				});
			});
		});
	};

	render() {
		return (
			<React.Fragment>

				<Modal size="lg" isOpen={true} toggle={this.props.toggle} className="simple-modal simple-modal-white srounded">

					<ModalHeader toggle={this.props.toggle}>
					</ModalHeader>

					<ModalBody className="pl-2 pr-2 pl-md-5 pr-md-5 pt-0">
						<h4 className="w-100 uppercase text-center pb-3"><FormattedMessage id="Add.Availability" /></h4>
						<div className="three-btns-inputs pt-3">
							{this.availabilityForm.getInput("type")}
						</div>

						<div className="text-center mb-5">
							{this.state.type === 'regular' &&
								<span>Je suis disponible tous les :</span>
							}
							{this.availabilityForm.getInput("day")}

							<div className="text-center text-danger">
								{this.state.errorSelectDayRegular}
								{this.state.errorRegularDateAndDay}
							</div>
						</div>

						<div className="container">
							<ModalChoiceDays 
								date={this.date}
								startDate={this.state.startDate}
								endDate={this.state.endDate}
								type={this.state.type}
								availabilitys={this.props.availabilitys}
								changeDate={this.changeDate}
								backToDateOfTheDay={this.backToDateOfTheDay}
								cancelSelectedDays={this.cancelSelectedDays}
								openBy={this.props.openBy}
								monthSelected={this.props.monthSelected}
								days_available={this.state.days_available}
								events={this.props.events}
							/>
						</div>

						<div className="w-100 text-center align-top">

							<div className="text-center text-danger">
								<small>{(this.state.availabilityOverlapError) && <FormattedMessage id="Overlapping.Existing.Availability.Of.Same.Type" />}</small>
							</div>
							<div className="text-center text-danger">
								<small>{(this.state.calendarEventOverlapError) && <FormattedMessage id="Overlapping.Existing.CalendarEvent" />}</small>
							</div>
							<div className="text-center text-danger">
								<small>{(this.state.replacementOverlapError) && <FormattedMessage id="Overlapping.Existing.Replacement" />}</small>
							</div>
							
						</div>

						{this.state.error && 
							<div className="error">
								{this.state.error}
							</div>
						}

						<Col sm="12" className="text-center mt-3">
							<a href="/" className="clickable font-14 text-center text-dark"
								onClick={this.toggle}>
								<b>
									<i className={"fa fa-" + ((this.state.collapse) ? "minus" : "plus")}></i>&nbsp;&nbsp;
										<span className="underline lp-1"><FormattedMessage id="Advanced.Options" /></span>
								</b>
							</a>
						</Col>

						<Collapse isOpen={this.state.collapse} className="">
							<div className="collapsed-form">
								{this.row("radius_preference")}
								{this.row("retrocession_wished")}
								{this.row("radius_effort")}
								{this.row("retrocession_wished_effort")}
							</div>
						</Collapse>

						{this.state.type !== 'guard' &&
							<label className="containerCheckBoxCustom">Je suis aussi disponible pour des gardes
								<input className="classForCheckbox" onChange={this.handleChange} checked={this.state.sameForGuard} type="checkbox" id="sameForGuard" name="sameForGuard" />
								<span className="checkmark"></span>
							</label>
						}

						<div className="text-center form-buttons w-100 mt-3 mb-4">
							{this.availabilityForm.submitButton("Publish", {
								disabled: (this.state.calendarEventOverlapError ||
									this.state.availabilityOverlapError ||
									this.state.replacementOverlapError ||
									this.state.loadingOverlap || 
									this.state.startDate === undefined || 
									this.state.endDate === undefined || this.state.error !== null),
								className: "srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green"
							})}
						</div>

					</ModalBody>
				</Modal>
			</React.Fragment>
		);
	};
};

const mapStateToProps = state => {
	return {
		//
	}
};

const mapDispatchToProps = dispatch => {
	return {
		onAdds: (objName, obj, cbk) => dispatch(adds(objName, obj, cbk)),
		onCalendarEventOverlaps: (start, end, days_available, type, cbk) => calendarEventOverlaps(start, end, days_available, type, cbk),
		onAvailabilityOverlaps: (start, end, type, days_available, cbk) => availabilityOverlaps(start, end, type, days_available, cbk),
		onConcludedAgreementOverlaps: (start, end, type, duration, days_available, cbk) => concludedAgreementOverlaps(start, end, type, duration, days_available, cbk),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddAvailabilityModal));