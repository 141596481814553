import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { upds, upd } from "../../../actions/global/global";
import { watchReplacement, } from "../../../actions/replacements/replacements";
import ReplacementEnt from '../../../entities/ReplacementEnt';
import Noty from 'noty';
import DateUtil from "../../../util/DateUtil";
import DoctorProfile from '../../doctors/profile/DoctorProfile'
import ConfModal from "../../../components/modal/ConfModal";
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";

class ModalIntermediate extends React.Component {

    constructor(props) {
        super(props);

        const hasLiked = ReplacementEnt.hasLiked(props.replacement, props.user)

        this.state = {
            disabled: false,
            modal: null,
            hasLiked
        };

        this.acceptRempl = this.acceptRempl.bind(this);
        this.refuseRempl = this.refuseRempl.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.thinkChoice = this.thinkChoice.bind(this);
    }

    // Fonction pour accepter un remplacement
    acceptRempl() {
        var data = {
            replacement_id: this.props.replacementId,
            substitute_id: this.props.substitute._id,
            substitute_status: 1,
            doctor_status: 0,
            doctor_id: this.props.doctor._id,
            fromDoctor: false
        };

        // Cas de figure attendu, un remplaçant qui accepte une invitation d'un medecin installé
        if (this.props.applicants) {
            this.props.applicants.forEach((applicant) => {
                // Si le remplaçant correspond et qu'il n'a pas validé
                if (applicant.substitute_id === this.props.substitute._id && (applicant.substitute_status === 0 || applicant.substitute_status === 4)) {
                    data = {
                        doctor_status: applicant.doctor_status === 3 ? 1 : applicant.doctor_status,
                        substitute_id: applicant.substitute_id,
                        replacement_id: this.props.replacementId,
                        substitute_status: 1,
                        applicant_id: applicant._id,
                        doctor_id: this.props.doctor._id,
                        fromDoctor: false
                    }
                }
            })
        }

        this.props.onUpd("replacement/accept", data, () => {
            this.props.close(true);
            if (data.doctor_status === 1) {
                this.props.onApply(this.props.replacement, this.props.doctor, this.props.doctorCharacteristic, this.props.officeTimeTable);
            } else {
                new Noty({
                    type: "info",
                    layout: 'topRight',
                    theme: 'bootstrap-v4',
                    text: 'Vous venez de postuler à ce remplacement',
                    timeout: 6000,
                }).show();
            }
        });
    }

    // Fonction pour refuser un remplacement
    refuseRempl() {
        var data = {
            replacement_id: this.props.replacementId,
            substitute_id: this.props.substitute._id,
            substitute_status: 2,
            doctor_status: 0,
            doctor_id: this.props.replacement.doctor_id
        };

        const onConfirm = (response) => {
            data.response_choice = response;

            this.props.onUpds("replacement/refuse", data, () => {
                this.props.close();

                new Noty({
                    type: "info",
                    layout: 'topRight',
                    theme: 'bootstrap-v4',
                    text: 'Vous venez de refuser ce remplacement',
                    timeout: 6000,
                }).show();
            });
        }

        const onCancel = () => {
            this.setState({ disabled: false });
            this.closeModal();
        }
        const content = <FormattedHTMLMessage id="Refuse.Invitation" />

        this.setState({
            modal: <ConfModal
                fromDoctor={false}
                content={content}
                onConfirm={onConfirm}
                onCancel={onCancel}
                toggle={onCancel} />
        });
    }

    closeModal() {
        this.setState({ modal: null });
    }

    // Si le user choisit de réfléchir
    thinkChoice() {
        let data = {
            substitute_status: 4,
            replacement_id: this.props.replacementId,
            substitute_id: this.props.substitute._id,
            role: this.props.substitute.role,
            doctor_id: this.props.doctor._id
        }
        this.props.onUpd("replacement/think", data, () => {
            this.props.close();

            new Noty({
                type: "info",
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: 'Vous avez choisi de réfléchir à cette proposition',
                timeout: 6000,
            }).show();
        });
    }

    onWatch = (e, replacement) => {
        e.stopPropagation();

        this.setState({ disabled: true });

        const { user } = this.props;

        this.props.onWatchReplacement(replacement, (replacement) => {
            this.setState({ disabled: false, hasLiked: !this.state.hasLiked });

            if (user && replacement.watchers) {
                if (replacement.watchers.find(id => id === user._id)) {
                    new Noty({
                        type: "info",
                        layout: 'topRight',
                        theme: 'bootstrap-v4',
                        text: this.props.intl.formatMessage({ id: "Replacement.Watch.Add.Success" }),
                        timeout: 6000,
                    }).show();
                } else {
                    new Noty({
                        type: "info",
                        layout: 'topRight',
                        theme: 'bootstrap-v4',
                        text: this.props.intl.formatMessage({ id: "Replacement.Watch.Remove.Success" }),
                        timeout: 6000,
                    }).show();
                }
            }
        });
    };

    render() {
        const { user, doctor, doctorCharacteristic, officeTimeTable, replacementId, agreement, agreementReplacement, replacement } = this.props;
        let viewHeart = true;
        if (replacement) {
            if (replacement.overlapping && replacement.overlapping === true) {
                viewHeart = false;
            }
            if (replacement.applicants && replacement.applicants.length > 0) {
                replacement.applicants.forEach((elem) => {
                    if (elem.substitute_id === user._id && (elem.substitute_status === 3 || elem.substitute_status === 1)) {
                        viewHeart = false;
                    }
                })
            }
        }
        return (
            <React.Fragment>

                <Modal size="md" isOpen={true} toggle={() => this.props.close()} className="simple-modal simple-modal-white srounded">

                    <ModalHeader className="border-0 m-0">
                        <div className="text-left repl-views">
                            <FormattedMessage id="Posted.On" />&nbsp;{DateUtil.toDate(replacement.createdAt)}
                        </div>

                        <div className="close-container">
                            {(!agreementReplacement && viewHeart === true && replacement.watchers) &&
                                <div className="repl-heart">
                                    <i
                                        className={"fa icon-ICON__COEUR_ROSE clickable " +
                                            ((this.state.hasLiked) ? " pink " : " ") +
                                            ((ReplacementEnt.isConcluded(replacement) ||
                                                ReplacementEnt.ongoingAgreement(replacement, user) ||
                                                this.state.disabled) ? " disabled " : "")
                                            }
                                        onClick={(e) => {
                                            if (!ReplacementEnt.isConcluded(replacement) &&
                                                !ReplacementEnt.ongoingAgreement(replacement, user) &&
                                                !this.state.disabled) this.onWatch(e, replacement)
                                        }}>
                                    </i>
                                </div>
                            }

                            <button type="button" className="close d-inline-block" aria-label="Close"
                                onClick={() => this.props.close()} ><span aria-hidden="true">×</span></button>
                        </div>

                    </ModalHeader>

                    <ModalBody className="p-0">

                        <div className="w-100 text-center mt-4 mb-3">
                            <DoctorProfile
                                agreement={agreement}
                                agreementReplacement={agreementReplacement}
                                replacementId={replacementId}
                                doctor={doctor}
                                doctorCharacteristic={doctorCharacteristic}
                                officeTimeTable={officeTimeTable}
                                onApply={this.props.onApply}
                                downloadContract={(agreementId, successCbk) => this.props.downloadContract(agreementId, successCbk)}
                                sendToCouncil={(agreementId, successCbk) => this.props.sendToCouncil(agreementId, successCbk)}
                                cancelAgreement={(agreementId, successCbk) => this.props.cancelAgreement(agreementId, successCbk)}
                                acceptRemplacement={this.props.acceptRemplacement}
                                refuseRempl={this.refuseRempl}
                                acceptRempl={this.acceptRempl}
                                openNegociationModal={this.props.openNegociationModal}
                                thinkChoice={this.thinkChoice}
                                replacement={replacement}
                            />
                        </div>

                    </ModalBody>

                </Modal>
                {this.state.modal}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpds: (objName, obj, cbk) => dispatch(upds(objName, obj, cbk)),
        onUpd: (objName, obj, cbk) => dispatch(upd(objName, obj, cbk)),
        onWatchReplacement: (replacement, callback) => dispatch(watchReplacement(replacement, callback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModalIntermediate));