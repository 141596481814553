import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Form, PasswordInput, MinLength, NotEmpty } from '@gferrand/react-forms';
import Util from '../../util/Util';
import { setNewPwd } from "../../actions/authentication/authentication";
import blueBG from '../../assets/images/login/RIDEAU_BLEU.svg';
import yellowBG from '../../assets/images/login/RIDEAU_JAUNE.svg';
import SimpleMsgModal from '../../components/modal/SimpleMsgModal';


class ResetPwd extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: false,
            modal: null,
        };

        const submitCbk = (data, cbk) => {
            var beData = {
                token: this.props.match.params.token,
                password: data.password
            };

            this.props.onSetNewPwd(beData, (err) => {
                cbk();

                if (err) this.setState({ error: err });
                else this.openSuccessModal();
            });
        };

        const changeCbk = () => {
            var formData = this.form.getRawData();

            if (!Util.emptyString(formData.password) && !Util.emptyString(formData.conf_password) && formData.password !== formData.conf_password) {
                this.form.setError("conf_password", "Passwords.Not.Identical");
            }
        };

        this.form = new Form({
            name: "user",
            inputs: [
                new PasswordInput("password", "New.Password", undefined, [MinLength(8), NotEmpty], { placeholder: "new.Password", cClassName: "text-center" }),
                new PasswordInput("conf_password", "Conf.New.Password", undefined, [MinLength(8), NotEmpty], { placeholder: "confirm.New.Password", cClassName: "text-center" }),
            ],
            submitCbk,
            changeCbk
        });

    }

    openSuccessModal(email) {
        const content = (
            <div>
                <div><FormattedMessage id="Your.Password.Was.Reset" /></div>
                <div><FormattedMessage id="You.Can.Now.Login.With.New.Credentials" /></div>
            </div>
        );

        this.setState({ modal: <SimpleMsgModal content={content} onClose={() => this.closeModal()} /> });
    }

    closeModal() {
        this.setState({ modal: null }, () => window.location.href = "#/");
    }

    render() {
        return (
            <>
                <div className="container-fluid bg-blue m-0 p-0 min-h-100 w-100 b-fix">

                    <div className="w-100 h-100 position-absolute m-0 p-0 d-none d-xl-block" style={{ top: -2 }}>
                        <img src={blueBG} alt="bg-img" className="h-100" style={{ marginLeft: -220 }} />
                        <img src={yellowBG} alt="bg-img" className="h-100 position-absolute" style={{ right: -220 }} />
                    </div>

                    <div className="position-relative max-w-350px mx-auto text-center" style={{ marginTop: "calc(50vh - 152px)" }}>

                        <div className="border xrounded bg-white text-center p-4 mx-auto mx-md-0">

                            <div className="mt-2 mb-2 text-center">
                                <h5 className="text-center pb-4"><FormattedMessage id="Reset.My.Password" /></h5>

                                <div className="mb-3">{this.form.getInput("password")}</div>

                                <div className="mb-5">{this.form.getInput("conf_password")}
                                    {(this.state.error) && <div className="mt-3 text-danger"><small><FormattedMessage id="Modify.Pwd.Error.Happened" /></small></div>}
                                </div>

                                <div>
                                    {this.form.submitButton("Reset", { className: "btn bg-blue text-white w-100 srounded pl-5 pr-5 pt-3 pb-3" })}
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                {this.state.modal}
            </>
        );
    }
}



const mapStateToProps = state => {
    return {
        //
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetNewPwd: (data, callback) => setNewPwd(data, callback)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPwd);
