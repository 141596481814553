import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Form, FormGroup, Label, Input, Button, Collapse } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { emailExists } from '../../actions/user/user';
import { doctorCreateSecretary } from '../../actions/secretary/secretary';
import Noty from "noty";
class AddSecretaryModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            first_name: "",
            email: "",
            phone_mobile: "",
            error: "",

            emailError: false,
            emailBelongToUser: false,
            emailBelongToSecretary: false,
            phoneMobileError: false,

            nameEmpty: false,
            firstNameEmpty: false,

            fieldError: false
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        // this.isValidated = this.isValidated.bind(this);
    }

    onChange(event) {
        const { name, value } = event.currentTarget;

        if (name === "name") {
            this.setState({ nameEmpty: false });
        }

        if (name === "first_name") {
            this.setState({ firstNameEmpty: false });
        }

        //Check if email already exists
        if (name === "email") {
            let regexMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let callback = (msg) => {
                console.log(msg)

                if (msg.emailExists) {
                    if(value === this.props.user.email) {
                        this.setState({ emailBelongToUser: true })
                    } else if(msg.emailExists !== 'secretary') {
                        this.setState({ emailNotBelongToSecretary: true })
                    } else {
                        this.setState({ emailBelongToUser: false, emailNotBelongToSecretary: false })
                    }
                }
                else this.setState({ emailBelongToUser: false, emailNotBelongToSecretary: false })
            };
            
            if (value && value.length > 1 && !regexMail.test(value)) {
                this.setState({ emailError: true })
            } else {
                this.setState({ emailError: false, emailBelongToUser: false, emailNotBelongToSecretary: false });
                this.props.onEmailExists(value, callback);
            }
            
            
        }

        if (name === 'phone_mobile') {
            
            let regexPhone = new RegExp('^((06)|(07))');
            if ((value && value.length > 1 && value.length < 10) || isNaN(value) || !regexPhone.test(value)) {
                this.setState({ phoneMobileError: true })
            } 
                
            else this.setState({ phoneMobileError: false })
        }
        this.setState({
            [name]: value
        })
    }

    onSubmit(event) {
        event.preventDefault();
        var callback = (cbk) => {
            new Noty({
                type: "success",
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: this.props.intl.formatMessage({ id: 'Request.Sent' }),
                timeout: 3000,
            }).show();
            cbk()
        }

        if (!this.state.name || !this.state.first_name ||
            this.state.emailError || this.state.emailBelongToUser || this.state.emailNotBelongToSecretary ||
            this.state.first_name.length < 2 || this.state.name.length < 2) {

            if (!this.state.name || this.state.name.length < 2) {
                this.setState({ nameEmpty: true });
            }
            if (!this.state.first_name || this.state.first_name.length < 2) {
                this.setState({ firstNameEmpty: true });
            }
            this.setState({ fieldError: true })
        }
        else {
            let secretary = {
                name: this.state.name,
                first_name: this.state.first_name,
                email: this.state.email,
                phone_mobile: this.state.phone_mobile,
                role: "temporary_sec",
                image: 1,
            }
            this.props.doctorCreateSecretary(secretary, callback(this.props.close));
        }

    }

    // isValidated() {
    //     return (this.state.name && this.state.first_name && this.state.phone_mobile && this.state.gender &&
    //         !this.state.phoneMobileError && !this.state.phoneMobileExists && !this.state.emailError && !this.state.emailExists &&
    //         this.state.first_name.length > 2 && this.state.name.length > 2)
    // }

    render() {
        const isValidated = this.state.name && this.state.first_name && this.state.email.length > 1 && 
            !this.state.emailError && !this.state.emailBelongToUser && !this.state.emailNotBelongToSecretary && 
            this.state.first_name.length > 2 && this.state.name.length > 2 && !this.state.phoneMobileError;
        return (
            <React.Fragment>
                <Modal size="lg" isOpen={true} toggle={this.props.close} className="simple-modal simple-modal-white srounded">

                    <ModalHeader className="justify-content-center" toggle={this.props.close}>
                        <FormattedMessage id="Add.Secretary" />
                        <p>Veuillez renseigner les informations de votre secrétaire ci-dessous. Après validation, votre secrétaire recevra un mail et devra valider votre demande.</p>
                    </ModalHeader>

                    <ModalBody>
                        <Row className="step step1 mt-2 justify-content-md-center ">
                            <Col lg="8">
                                <Form onSubmit={this.onSubmit}>
                                    <FormGroup row className="justify-content-center py-2">
                                        <Col md="4" className="row">
                                            <Label className="m-auto">Nom : *</Label>
                                        </Col>
                                        <Col className="col-md-6 align-self-center">
                                            <Input className="secretaryInputModal" type="text" name="name" value={this.state.name} onChange={this.onChange} required invalid={this.state.nameEmpty ? true : false} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="justify-content-center py-2">
                                        <Col md="4" className="row">
                                            <Label className="m-auto">Prénom : *</Label>
                                        </Col>
                                        <Col className="col-md-6 align-self-center">
                                            <Input className="secretaryInputModal" type="text" name="first_name" value={this.state.first_name} onChange={this.onChange} required invalid={this.state.firstNameEmpty ? true : false} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="justify-content-center py-2">
                                        <Col md="4" className="row">
                                            <Label className="m-auto" for="exampleInputEmail1">E-mail : *</Label>
                                        </Col>
                                        <Col className="col-md-6 align-self-center">
                                            <Input className="secretaryInputModal" id="exampleInputEmail1" placeholder="" type="email" name="email" value={this.state.email} onChange={this.onChange} required={false} invalid={this.state.emailError ||  this.state.emailBelongToUser || this.state.emailNotBelongToSecretary ? true : false} />
                                            <Collapse isOpen={this.state.emailError ||  this.state.emailBelongToUser || this.state.emailNotBelongToSecretary}>
                                                <p className="ml-1 alert-input">
                                                    {this.state.emailError && <FormattedMessage id={"Email.Error"} />} {" "}
                                                    {this.state.emailBelongToUser && <FormattedMessage id={"Secretary.Email.Same.As.User"} />}
                                                    {this.state.emailNotBelongToSecretary && <FormattedMessage id={"Email.Not.Belong.To.Secretary"} />}
                                                </p>
                                            </Collapse>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="justify-content-center py-2">
                                        <Col md="4" className="row">
                                            <Label className="m-auto">Téléphone : *</Label>
                                        </Col>
                                        <Col className="col-md-6 align-self-center">
                                            <Input className="secretaryInputModal" name="phone_mobile" value={this.state.phone_mobile} onChange={this.onChange} required maxLength="10" invalid={this.state.phoneMobileError ? true : false} />
                                            <Collapse isOpen={this.state.phoneMobileError === true}>
                                                <p className="ml-1 alert-input"> Numéro invalide </p>
                                            </Collapse>
                                        </Col>
                                    </FormGroup>
                                    <Row className=" mt-3">
                                        {this.state.fieldError === true &&
                                            <Row className="position-absolute w-100">
                                                <p className="ml-1 alert-input w-100 center">Veuillez remplir les champs obligatoires</p>
                                            </Row>
                                        }
                                    </Row>


                                    <Row className="mx-auto form-buttons">
                                        <Button color="success" className={`btn srounded px-4 py-2 lowercase text-white mx-auto ${isValidated ? "bg-green" : "bg-secondary"}`} type="submit" disabled={!isValidated}>Ajouter</Button>
                                    </Row>

                                </Form>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    };
};

const mapStateToProps = state => {
    return {
        user: state.global.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEmailExists: (email, cbk) => emailExists(email, cbk),
        doctorCreateSecretary: (secretary, cbk) => dispatch(doctorCreateSecretary(secretary, cbk))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddSecretaryModal));