
export default class UserEnt {

    static fullName(user){
        return user.first_name + " " + user.name;
    }

    static pseudo(user){
        return user.first_name;
    }

}