import React from 'react';

class Stars extends React.Component {

    render() {
        // No more than 5 stars
        var nbr = Math.min(this.props.nbr, 5);
        nbr = Math.max(nbr, 0);

        var starsNode = [];

        for (let i = 0; i < nbr; i++) {
            starsNode.push(<span key={Math.random()} className="fa icon-ICON_STHETO_GRIS_C gold"></span>);
        }
        for (let i = 0; i <= 4 - nbr; i++) {
            starsNode.push(<span key={Math.random()} className="fa icon-ICON_STHETO_GRIS_C"></span>);
        }

        return (
            <React.Fragment>
                {starsNode}
            </React.Fragment>
        );
    }
}

export default Stars;