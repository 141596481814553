import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { Modal, ModalHeader, ModalBody, Row, Col, } from 'reactstrap';
import { Form, Empty, TextareaInput } from '@gferrand/react-forms';
import { adds } from "../../actions/global/global";
import ProfileImages from "../../enum/ProfileImages";
import DateUtil from '../../util/DateUtil';
import StarsInput from "../../components/form/StarsInput";
import UserEnt from '../../entities/UserEnt';
import Noty from 'noty';
import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";

class RatingModal extends React.Component {

    constructor(props) {
        super(props)

        this.buildForm();
    }

    buildForm() {
        const { user, agreement } = this.props

        var data = {
            criteria_1: 0,
            criteria_2: 0,
            criteria_3: 0,
            criteria_4: 0,
            criteria_5: 0
        };

        var i18n;
        if (user.role === "substitute") {
            i18n = {
                criteria_1: "Professionalism",
                criteria_2: "Relationnal",
                criteria_3: "Punctuality",
                criteria_4: "IT",
                criteria_5: "Accounting",
            };
        } else {
            i18n = {
                criteria_1: "Kindness",
                criteria_2: "Medical.Equipment",
                criteria_3: "IT.Equipment",
                criteria_4: "Geographic.Location",
                criteria_5: "Cabinet",
            };
        }

        var submitCbk = (data, endCbk) => {
            data.rated_id = user._id;
            data.agreement_id = agreement._id;
            let error = false;
            if (data.criteria_1 === 0) {
                this.form.setError("criteria_1", "Vous devez mettre une note entre 1 et 5");
                error = true;
            } 
            if (data.criteria_2 === 0) {
                this.form.setError("criteria_2", "Vous devez mettre une note entre 1 et 5");
                error = true;
            } 
            if (data.criteria_3 === 0) {
                this.form.setError("criteria_3", "Vous devez mettre une note entre 1 et 5");
                error = true;
            } 
            if (data.criteria_4 === 0) {
                this.form.setError("criteria_4", "Vous devez mettre une note entre 1 et 5");
                error = true;
            } 
            if (data.criteria_5 === 0) {
                this.form.setError("criteria_5", "Vous devez mettre une note entre 1 et 5");
                error = true;
            }

            if (error === false) {
                this.props.onAdds("rating", data, () => {
                    this.props.onRate();
    
                    new Noty({
                        type: "info",
                        layout: 'topRight',
                        theme: 'bootstrap-v4',
                        text: (user.role === "substitute") ? this.props.intl.formatMessage({ id: "SRating.Add.Success" }) : this.props.intl.formatMessage({ id: "DRating.Add.Success" }),
                        timeout: 6000,
                    }).show();
                });
            } else {
                return endCbk();
            }
        };

        this.form = new Form({
            name: "rating",
            inputs: [
                new StarsInput("criteria_1", i18n["criteria_1"], [Empty]),
                new StarsInput("criteria_2", i18n["criteria_2"], [Empty]),
                new StarsInput("criteria_3", i18n["criteria_3"], [Empty]),
                new StarsInput("criteria_4", i18n["criteria_4"], [Empty]),
                new StarsInput("criteria_5", i18n["criteria_5"], [Empty]),
				new TextareaInput("comment", "Comment.Detail", undefined),
            ],
            submitCbk,
            data
        });

    }

    render() {
        const { user } = this.props

        return (

            <React.Fragment>

                <Modal size="md" isOpen={true} toggle={() => this.props.close()} className="simple-modal simple-modal-white srounded">

                    <ModalHeader toggle={() => this.props.close()}>
                    </ModalHeader>

                    <ModalBody>
                        <h4 className="w-100 uppercase text-center mb-4 lp-2">
                            <FormattedHTMLMessage id="Rate.repl" />
                        </h4>

                        <Col sm="12" className="mb-1 mt-3">

                            <Row>
                                <Col className="text-center">
                                    <img src={ProfileImages.getSrc(user.image)} className="d-block mx-auto mb-3"
                                        width="100" alt="" style={{ borderRadius: "100px" }} />

                                    <h4 className="mb-0">{UserEnt.fullName(user)}</h4>
                                    <div>{DateUtil.age(user.birth_date)} <FormattedMessage id="Years.Old" /></div>
                                </Col>
                            </Row>

                            <br />

                            <Row className="d-flex justify-content-center">

                                <Col sm="12">
                                    <Row className="form-inline form-group">
                                        <Col md="7">
                                            {this.form.getLabel("criteria_1")}
                                        </Col>
                                        <Col md="5">
                                            <div style={{ textAlign: "left", height: 35, paddingTop: 3 }}>
                                                {this.form.getInput("criteria_1")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="form-inline form-group">
                                        <Col md="7">
                                            {this.form.getLabel("criteria_2")}
                                        </Col>
                                        <Col md="5">
                                            <div style={{ textAlign: "left", height: 35, paddingTop: 3 }}>
                                                {this.form.getInput("criteria_2")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="form-inline form-group">
                                        <Col md="7">
                                            {this.form.getLabel("criteria_3")}
                                        </Col>
                                        <Col md="5">
                                            <div style={{ textAlign: "left", height: 35, paddingTop: 3 }}>
                                                {this.form.getInput("criteria_3")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="form-inline form-group">
                                        <Col md="7">
                                            {this.form.getLabel("criteria_4")}
                                        </Col>
                                        <Col md="5">
                                            <div style={{ textAlign: "left", height: 35, paddingTop: 3 }}>
                                                {this.form.getInput("criteria_4")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="form-inline form-group">
                                        <Col md="7">
                                            {this.form.getLabel("criteria_5")}
                                        </Col>
                                        <Col md="5">
                                            <div style={{ textAlign: "left", height: 35, paddingTop: 3 }}>
                                                {this.form.getInput("criteria_5")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="form-inline form-group mt-3 mb-0">
                                        <Col className="text-center">
                                            {this.form.getLabel("comment")}
                                        </Col>
                                        <Col md="12">
                                                {this.form.getInput("comment")}
                                        </Col>
                                    </Row>
                                  
                                </Col>

                            </Row>

                        </Col>

                        <div className="mt-5 mb-3">
                            {this.form.submitButton("Validate", { className: "srounded pl-5 pr-5 pt-2 pb-2 text-white bg-green mr-0" })}
                        </div>

                    </ModalBody>

                </Modal>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        //
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAdds: (objName, obj, cbk) => dispatch(adds(objName, obj, cbk))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RatingModal));