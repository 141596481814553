import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

export default class ConfModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            disabled: false,
            response: null,
            enumChoice: []
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        // Cas d'un remplaçant refusant une invitation
        if (this.props.fromDoctor === false) {
            let enumChoice = [
                { value: "AVAILABILITY.NOT.JUSTIFIED", id: "AVAILABILITY_NOT_JUSTIFIED" },
                { value: "CALENDAR.NOT.UP.TO.DATE", id: "CALENDAR_NOT_UP_TO_DATE" },
                { value: "AVAILABILTY.ALREADY.CONCLUDED", id: "AVAILABILTY_ALREADY_CONCLUDED" },
                { value: "REPLACEMENT.NOT.FIT", id: "REPLACEMENT_NOT_FIT" },
                { value: "REPLACEMENT.TOO.FAR", id: "REPLACEMENT_TOO_FAR" }
            ];
            this.setState({
                enumChoice,
                response: 'AVAILABILITY_NOT_JUSTIFIED'
            })
        } 

        if (this.props.fromDoctor === true) {
            let enumChoice = [
                { value: "AVAILABILITY.NOT.JUSTIFIED", id: "AVAILABILITY_NOT_JUSTIFIED" },
                { value: "REPLACEMENT.NOT.UP.TO.DATE", id: "REPLACEMENT_NOT_UP_TO_DATE" },
                { value: "REPLACEMENT.ALREADY.CONCLUDED", id: "REPLACEMENT_ALREADY_CONCLUDED" },
                { value: "REPLACEMENT.USER.NOT.FIT", id: "REPLACEMENT_USER_NOT_FIT" }
            ];
            this.setState({
                enumChoice,
                response: 'AVAILABILITY_NOT_JUSTIFIED'
            })
        }
    }

    confirm() {
        this.props.onConfirm(this.state.response);
        this.setState({ disabled: true });
    }

    cancel() {
        if (this.props.onCancel) this.props.onCancel();
        this.props.toggle();
    }

    handleChange(event) {  
        const value = event.currentTarget.value;
        this.setState({
            response: value
        })
    }

    render() {
        return (
            <Modal isOpen={true} toggle={this.props.toggle} size="md" className="simple-modal simple-modal-green srounded">

                <ModalHeader toggle={this.props.onClose}>
                    <button type="button" className="close d-inline-block b-green" aria-label="Close" onClick={this.props.toggle} ><span aria-hidden="true">×</span></button>
                </ModalHeader>

                <ModalBody className="p-4">

                    {this.props.content}

                    {(this.props.fromDoctor === false || this.props.fromDoctor === true) &&
                        <div>
                            {this.state.enumChoice.map((elem, key) => 
                                <div className="row" key={key}>
                                    <input className="col-1 col-md-2 offset-md-1" type="radio" defaultChecked={key === 0} id={elem.id} name="response" onChange={this.handleChange} value={elem.id} />
                                    <label className="col-11 col-md-7 text-right" htmlFor={"response" + key}><FormattedMessage id={elem.value} /></label>
                                </div>
                            )}
                        </div>
                    }

                    <div className="mt-4 mb-3">
                        <Button 
                            className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-grey btn opacity-1"
                            onClick={() => this.cancel()}>
                            <FormattedMessage id="Cancel" />
                        </Button>

                        <Button 
                            className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-warning btn opacity-1 mr-0"
                            disabled={this.state.disabled}
                            onClick={() => this.confirm()}>
                            <FormattedMessage id="Confirm" />
                        </Button>
                    </div>

                </ModalBody>

            </Modal>
        );

    }

}
