import React from 'react';
import 'react-datetime/css/react-datetime.css';
import ProfileImages from "../../enum/ProfileImages";
import { FormattedMessage } from 'react-intl';
import RatingModal from "../boths/RatingModal";
import UserEnt from '../../entities/UserEnt';


export default class ProfileRateCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null
        };
    }

    rate(e, user, agreement) {
        e.stopPropagation();

        this.setState({
            modal: <RatingModal
                user={user}
                agreement={agreement}
                onRate={() => { if (this.props.onRate) this.props.onRate() }}
                close={() => this.closeModal()}
            />,
        });
    }

    closeModal() {
        this.setState({ modal: null });
    }

    render() {
        const { user, agreement } = this.props;

        if (!user) {
            return (
                <div className="clickable height-50 profile hover-underline" style={{ marginTop: "5px" }}>
                    <img src={ProfileImages.getSrc()} alt="user img" className="d-inline" style={{ borderRadius: "50px" }} width="35" height="35" />
                </div>
            );
        }

        return (
            <>

                <div className="clickable height-50 profile hover-underline" style={{ marginTop: "5px" }}
                    onClick={(e) => this.rate(e, user, agreement)}>

                    <img src={ProfileImages.getSrc(user.image)} alt="user img" className="d-inline" style={{ borderRadius: "50px" }} width="35" height="35" />

                    <div className="d-inline-block ml-2 height-50">
                        <div className="d-inline-block pl-2 pr-2 height-50 text-danger">
                            <FormattedMessage id="Rate.rempl" /> <b>{UserEnt.fullName(user)}</b>!
                        </div>
                    </div>

                </div>

                {this.state.modal}

            </>
        );
    };
}