import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Collapse, Card, CardBody, Badge } from "reactstrap";
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Form, DateInput, RangeInput, SelectInput, RadioInput, NotEmpty } from '@gferrand/react-forms';
import { clears, getsBy } from "../../../actions/global/global";
import { searchDoctorsBySector } from '../../../actions/user/user';
import SReplacementCard from './SReplacementCard';
import SReplacementCardBySector from './SReplacementCardBySector';
import DoctorSecretariat from "../../../enum/DoctorSecretariat";
import {  withGoogleMap, GoogleMap, Marker, /** Circle */ } from "react-google-maps";
import ProfileImages from '../../../enum/ProfileImages';
import Geocode from "react-geocode";
import Util from "../../../util/Util";
import GoogleMapUtil from "../../../util/GoogleMapUtil"
import WeekDaysInput from "../../../components/form/WeekDaysInput";
import SearchLocationInput from "../../../components/form/SearchLocationInput";

import Pagination from "react-js-pagination";

import lampImg from '../../../assets/images/search/HYDROGEN_ILLU_LAMPE.png';


class SearchReplacement extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            searchedOnce: false,
            displayPerfectMatch: true,
            distance: 0,

            // Pagination
            skip: 0,
            limit: 10,

            targetedReplId: null,
            loadingCityCoords: false,
            coords: null,
            displaySearch: 'replacement',
            sendSearch: false
        };

        this.GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

        this.searchFilter = {
            start_date: new Date().toISOString(),
            end_date: new Date(new Date().getTime() + 4 * 30 * 24 * 3600 * 1000).toISOString()
        };

        // Refs to repl cards
        this.replRefs = {};
        this.shakeCbks = {};

        // =================================
        // ============= GMAPS =============
        // =================================

        /*this.mapComponent = <GReplacementMap
            substituteCharacteristic={this.props.substituteCharacteristic}
            onMarkerClick={(replacement, index) => this.onMarkerClick(replacement, index)}
            addReplsMapCbk={(cbk) => this.addReplsMapCbk = cbk}
            addLoadMapCbk={(cbk) => this.loadMapCbk = cbk} />;*/

        // Default coords
        this.mapComponent = null;
        this.clearCity = this.clearCity.bind(this); 
        this.changeResultSearch = this.changeResultSearch.bind(this);

        Geocode.setRegion('fr');
        Geocode.setLanguage('fr');
    };

    componentDidMount(){
        this.props.onClears("replacement");
        if(this.props.substituteCharacteristic) {
            GoogleMapUtil.loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${this.GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
                () => {
                    this.buildForm()
                    this.buildMap(this.props.substituteCharacteristic);

                    this.forceUpdate()
                }
            );
            // this.buildForm();
            // this.buildMap(this.props.substituteCharacteristic);
        }
        // this.fetchData()
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.replacement !== this.props.replacement) this.fetchData()
        if(!prevProps.substituteCharacteristic && this.props.substituteCharacteristic) {
            // this.buildForm()
            // this.buildMap(this.props.substituteCharacteristic);
            GoogleMapUtil.loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${this.GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
                () => {
                    this.buildForm()
                    this.buildMap(this.props.substituteCharacteristic);

                    this.forceUpdate()
                }
            );
        }

        if (prevState.skip !== this.state.skip) {
            let object = {
                displaySearch: this.state.displaySearch,
                skip: this.state.skip
            }
            this.addReplsMapCbk(this.props.replacements, null, this.props.doctorsBySector, object);
        }
    }

    buildForm() {
        const { substituteCharacteristic } = this.props;

        if (this.form || !substituteCharacteristic) return;


        var submitCbk = (data, endCbk) => {
            if (data.type === "regular" && (!data.days || data.days.length === 0)) return endCbk();

            const { substituteCharacteristic } = this.props;

            if (!substituteCharacteristic) return endCbk();

            this.searchFilter = Object.assign({}, data);

            if (!this.state.collapse) {
                delete data.distance;
                delete data.nbrOfPatientsPerWeek;
                delete data.nbrOfStars;
                delete data.retrocession;
                delete data.replacementDuration;
                delete data.office_disposal;
                delete data.secretariat;
                delete data.city;
            }

            this.setState({
                skip: 0,
                searchedOnce: true,
                distance: (data.distance) ? data.distance * 1000 : substituteCharacteristic.radius_preference * 1000
            });

            this.fetchData(endCbk);

            let filtersForSector = {
                distance: this.searchFilter.distance,
                "lat" : (this.state.coords) ? this.state.coords[0] : undefined,
                "lng" : (this.state.coords) ? this.state.coords[1] : undefined,
            }

            this.props.searchDoctorsBySector(filtersForSector);
        };

        var changeCbk = (input) => {
            const { name, value } = input;

            var formData = this.form.getRawData();

            this.setState({ searchedOnce: false });

            if (this.addReplsMapCbk) this.addReplsMapCbk([]);

            if (name === "start_date" || name === "end_date") {
                var today = new Date();
                today.setHours(0, 0, 0, 0);

                // Cannot start in the past
                if (new Date(formData.start_date) < today) this.form.setError("start_date", "Start.Past.Err");

                if (formData.start_date && formData.end_date) {
                    // End date before start date
                    if(formData.replacementType === "guard" && new Date(formData.end_date).setHours(0, 0, 0, 0) < new Date(formData.start_date).setHours(0, 0, 0, 0)) {
                        this.form.setError("end_date", "EndDate.Before.StartDate.Err");
                    } else if (formData.replacementType  !== "guard" && new Date(formData.end_date) <= new Date(formData.start_date).setHours(0, 0, 0, 0)) {
                        this.form.setError("end_date", "EndDate.Before.StartDate.Err");
                    }
                }
            }

            // User switches replacement type, show / hide relevant fields
            if (name === "replacementType") {
                if (value === "regular") {
                    this.form.show("days");
                } else {
                    this.form.hide("days");
                }
                if (formData.start_date && formData.end_date) {
                    // End date before start date
                    if(value === "guard" && new Date(formData.end_date).setHours(0, 0, 0, 0) < new Date(formData.start_date).setHours(0, 0, 0, 0)) {
                        this.form.setError("end_date", "EndDate.Before.StartDate.Err");
                    } else if (value  !== "guard" && new Date(formData.end_date) <= new Date(formData.start_date).setHours(0, 0, 0, 0)) {
                        this.form.setError("end_date", "EndDate.Before.StartDate.Err");
                    } else {
                        this.form.setError("end_date", null)
                    }
                }
            };

            // If user enter a   ity, we need to get city coordinates 
            if (name === "city") {
                if (Util.emptyString(value)) {
                    this.setState({ coords: null });
                } else {
                    this.setState({ loadingCityCoords: true });
                    Geocode.fromAddress(value, '', 'fr', 'fr').then((response) => {
                        const { lat, lng } = response.results[0].geometry.location;

                        this.setState({ coords: [lat, lng], loadingCityCoords: false });

                        this.forceUpdate();
                    }, (error) => {
                        this.form.setError("city", "Error.City.Not.Recognized")

                        this.setState({ loadingCityCoords: false });
                    });
                }
            }

            if (name === 'days') {
                this.setState({ skip: 0 });
            }

            this.props.onClears("replacement");
        };

        this.form = new Form({
            inputs: [
                new DateInput("start_date", "Start.Date", [NotEmpty], { errorClassName: "react-date-picker-invalid", className: "custom-date-input-color" }),
                new DateInput("end_date", "End.Date", [NotEmpty], { errorClassName: "react-date-picker-invalid", className: "custom-date-input-color" }),

                new RadioInput("replacementType", "Replacement",
                    [{ label: "Occasional", value: "occasional" },
                    { label: "Regular", value: "regular" },
                    { label: "Guard", value: "guard" }]
                    , [NotEmpty]),

                new WeekDaysInput("days"),

                new SelectInput("replacementDuration", "Replacement.Duration",
                    [{ label: "Up.To.1.Week", value: "1" },
                    { label: "1.Week.To.2.Weeks", value: "2" },
                    { label: "2.Weeks.To.1.Month", value: "3" },
                    { label: "More.Than.1.Month", value: "4" }]
                    , []),

                new SelectInput("secretariat", "Secretariat", DoctorSecretariat.values(), []),

                new SelectInput("office_disposal", "Office.Disposal",
                    [{ label: "Office.Disposal.Short-Term", value: "1" },
                    { label: "Office.Disposal.Long-Term", value: "2" },
                    { label: "No", value: "3" }]
                    , []),

                new SearchLocationInput("city", "City", [], null, { className: 'custom-input-city', typeMap: '(cities)'}),
                new RangeInput("distance", "Distance", 10, 100, 10, [], { suffix: " km", className: "blue-range-input" }),
                new RangeInput("nbrOfPatientsPerWeek", "Nbr.Of.Patients", 50, 300, 50, [], { suffix: " par semaine", className: "blue-range-input" }),
                new RangeInput("nbrOfStars", "Stars", 0, 5, 1, [], { suffix: " ★", className: "blue-range-input" }),
                new RangeInput("retrocession", "Retrocession", 60, 100, 2.5, [], { suffix: " %", className: "blue-range-input" }),
            ],

            submitCbk,
            changeCbk: (input) => changeCbk(input),
            data: {
                replacementType: "occasional",
                replacementDuration: null,
                secretariat: null,
                distance: (substituteCharacteristic) ? substituteCharacteristic.radius_preference : 10,
                retrocession: 60,
                // retrocession: (substituteCharacteristic) ? substituteCharacteristic.retrocession_wished : 60,
                office_disposal: null,
                start_date: new Date(),
                end_date: new Date(new Date().getTime() + 3 * 30 * 24 * 3600 * 1000),
                city: this.props.substituteCharacteristic.address_city
            }
        });
        this.form.hide("days");
    }

    buildMap(substituteCharacteristic) {
        this.mapComponent = <GReplacementMap
            substituteCharacteristic={substituteCharacteristic}
            onMarkerClick={(replacement, index) => this.onMarkerClick(replacement, index)}
            addReplsMapCbk={(cbk) => this.addReplsMapCbk = cbk}
            addLoadMapCbk={(cbk) => this.loadMapCbk = cbk} />;
    }

    onMarkerClick(replacement, index) {
        let skip = Math.trunc(index / 10) * 10;
        let displayPerfectMatch = !replacement.warning;
        const ref = this.replRefs[replacement._id];
        this.setState({
            displayPerfectMatch,
            skip
        }, () => {

            if (ref) window.scrollTo(0, ref.current.offsetTop);
    
            const shakeCbk = this.shakeCbks[replacement._id];
            if (shakeCbk) shakeCbk();
        })
        
    }

    fetchData(endCbk) {
        this.setState({ loading: true });
        if (this.loadMapCbk) this.loadMapCbk();

        this.props.onGetsBy("replacement",
            [
                "start_date",
                "end_date",
                "replacementType",
                "distance",
                "nbrOfPatientsPerWeek",
                "nbrOfStars",
                "office_disposal",
                "replacementDuration",
                "retrocession",
                "secretariat",
                "days",
                "skip",
                "limit",
                "lat",
                "lng"
            ],
            [
                this.searchFilter.start_date,
                this.searchFilter.end_date,
                this.searchFilter.replacementType,
                this.searchFilter.distance,
                this.searchFilter.nbrOfPatientsPerWeek,
                this.searchFilter.nbrOfStars,
                this.searchFilter.office_disposal,
                this.searchFilter.replacementDuration,
                this.searchFilter.retrocession,
                this.searchFilter.secretariat,
                this.searchFilter.days,
                this.state.skip,
                this.state.limit,
                (this.state.coords) ? this.state.coords[0] : undefined,
                (this.state.coords) ? this.state.coords[1] : undefined,
            ], (replacements) => {
                let object = {
                    displaySearch: this.state.displaySearch,
                    skip: this.state.skip
                }
                if (this.addReplsMapCbk) this.addReplsMapCbk(replacements, null, this.props.doctorsBySector, object);
                if (replacements.length === 0) {
                    let coords = { coords: this.state.coords }
                    this.addReplsMapCbk([], coords, this.props.doctorsBySector, object);
                } 

                this.setState({ 
                    loading: false, 
                    sendSearch: true
                });
                if (endCbk) endCbk();
            });
    }

    row(inputName) {
        return (
            <Row className="form-group">
                <Col md="5">{this.form.getLabel(inputName)}</Col>
                <Col md="7">{this.form.getInput(inputName)}</Col>
            </Row>
        );
    };

    rowDistanceCustom(inputName) {
        return (
            <div className="d-flex justify-content-center pt-4">
                <div className="w-distance"><div className="p-distance"><FormattedMessage id="Distance" /></div>{this.form.getInput(inputName)}</div>
            </div>
        );
    };

    rowCityCustom(inputName) {
        return (
            <div className="d-flex justify-content-center">
                <div className="w-distance">{this.form.getInput(inputName)}
                    <i className="fa fa-pencil-square-o custom-input-city-fa custom-input-city" onClick={this.clearCity} aria-hidden="true"></i>
                </div>
            </div>
        );
    };

    clearCity() {
        this.form.inputs[7].setValue("");
        document.getElementById("city").focus();
    }

    toggle = () => {
        this.setState({ 'collapse': !this.state.collapse });
    };

    changeResultSearch(event) {
        const id = event.currentTarget.id;

        if (this.state.displaySearch === id) return;
        
        let object = {
            displaySearch: id,
            skip: this.state.skip
        }
        this.addReplsMapCbk(this.props.replacements, null, this.props.doctorsBySector, object);
        this.setState({
            displaySearch: id
        })
    }

    loading = () => {
        return (
            <div className="w-100 text-center mt-2 mb-2 h-300px">
                <div className="spinner-border text-white mx-auto" role="status" style={{ marginTop: 125 }}></div>
            </div>
        );
    }

    render() {
        const { replacements, doctorsBySector } = this.props;
        if(!this.form) return this.loading();

        const perfectReplacements = replacements ? replacements.filter((replacement) => {
            return !replacement.warning;
        }) : [];

        const warningReplacements = replacements ? replacements.filter((replacement) => {
            return replacement.warning;
        }) : [];

        if (replacements && doctorsBySector && doctorsBySector.doctors) {
            doctorsBySector.doctors.map(function(doctor, index, object){ 
                var result = replacements.filter(a1 => a1.doctor_id === doctor._id);
                if(result.length > 0) { 
                    object.splice(index, 1);
                }
                return doctor; 
            });
        }

        // Empty refs
        this.replRefs = {};
        this.shakeCbks = {};

        let totalReplacements = perfectReplacements.length + warningReplacements.length;
        let totalReplacementsBySector = doctorsBySector ? doctorsBySector.doctors.length : 0;

        return (
            <React.Fragment>

                <div className="w-100 text-white text-center uppercase mb-4 lp-2">
                    <h4><FormattedMessage id="Find.Replacement" /></h4>
                </div>

                <Card className="p-0 rounded-0">

                    <CardBody className="mt-0 p-0">

                        <div className="p-0">

                            <Row className="p-0 m-0">

                                <Col className="p-0 col-12 col-lg-5 col-xl-6" >
                                    {this.mapComponent}
                                </Col>

                                <Col className="p-0 col-12 col-lg-7 col-xl-6">
                                    <div className="mb-3 mt-3 pb-4">
            
                                        <div className="three-btns-inputs-custom pt-3 text-center">
                                            {this.form.getInput("replacementType")}
                                        </div>

                                        <div className="text-center mb-4 text-dark">
                                            {this.form.getInput("days")}
                                        </div>

                                        <div className="w-100 text-center align-top pl-1">
                                            <div className="d-inline-block align-top mt-2">
                                                <b><FormattedMessage id="From" />&nbsp;&nbsp;</b>
                                                <span className="date-input-input">{this.form.getInput("start_date")}</span>
                                            </div>

                                            <div className="d-inline-block align-top mt-2">
                                                <b>&nbsp;&nbsp;<FormattedMessage id="to" /></b>
                                                &nbsp;&nbsp;<span className="date-input-input">{this.form.getInput("end_date")}</span>
                                            </div>
                                        </div>

                                        <div className="text-center align-top">
                                            {this.rowCityCustom("city")}
                                            {this.rowDistanceCustom("distance")}
                                        </div>

                                        <div className="w-100 text-center form-buttons">
                                            {this.form.submitButton("Find", {
                                                loading: this.state.loadingCityCoords,
                                                className: "srounded pl-5 pr-5 pt-2 pb-2 text-white bg-green btn text-center mx-auto"
                                            })}
                                        </div>

                                        <div className="w-100 text-center pt-3">
                                            <a href="/" className="clickable font-14 p-0 m-0 text-center text-dark"
                                                onClick={(e) => { e.preventDefault(); this.toggle() }}>
                                                <b>
                                                    <i className={"fa fa-" + ((this.state.collapse) ? "minus" : "plus")}></i>&nbsp;&nbsp;
                                                        <span className="lp-1"><FormattedMessage id="Advanced.Search" /></span>
                                                </b>
                                            </a>
                                        </div>

                                        <Collapse isOpen={this.state.collapse} className="p-2">
                                            <div className="collapsed-form pr-5">
                                                {this.row("retrocession")}
                                                {this.row("secretariat")}
                                                {this.row("nbrOfPatientsPerWeek")}
                                            </div>
                                        </Collapse>
                                        
                                        {!this.state.loading && this.state.sendSearch &&
                                            <div className="row justify-content-around w-100 text-white mb-3 mt-3">
                                                <div className='col-4'>
                                                    <button className={"ladda-button btn srounded text-white btn text-center mx-auto " + (this.state.displaySearch === 'replacement' ? " bg-green" : " bg-secondary")} onClick={this.changeResultSearch} id="replacement">
                                                        <FormattedMessage id="Available.Replacements" />
                                                        <Badge className="ml-1" color="danger" pill>{totalReplacements}&nbsp;</Badge>
                                                    </button>
                                                </div>

                                                <div className='col-4'>
                                                    <button className={"ladda-button btn srounded text-white btn text-center mx-auto" + (this.state.displaySearch === 'replacement' ? " bg-secondary" : " bg-green")} onClick={this.changeResultSearch} id="sector">
                                                        <FormattedHTMLMessage id="Doctor.By.Sector" />
                                                        <Badge className="ml-1" color="danger" pill>{totalReplacementsBySector}&nbsp;</Badge>
                                                    </button>
                                                </div>
                                            </div>
                                        }

                                    </div>

                                </Col>
                            </Row>
                        </div>

                    </CardBody>

                </Card>

                {(!this.state.loading && this.state.searchedOnce && (!replacements || !replacements.length)) &&
                    <div className="w-100 mx-auto text-center mb-5">
                        <div className="d-inline-block mb-3" style={{ width: 150, height: 150 }}>
                            <img src={lampImg} alt="" width={150} height={150} />
                        </div>
                        <div className="uppercase text-white"><FormattedHTMLMessage id="No.Repl.Matches" /></div>
                    </div>
                }

                {(!this.state.loading) &&
                    <React.Fragment>
                        {this.state.displaySearch === 'replacement' && 
                            <React.Fragment>
                                {(replacements) && 
                                    perfectReplacements.slice(0 + this.state.skip, this.state.limit  + this.state.skip).map(r => <SReplacementCard
                                    addShakeCbk={(cbk) => this.shakeCbks[r._id] = cbk}
                                    addRef={(ref) => this.replRefs[r._id] = ref}
                                    key={r._id}
                                    replacement={r} />)
                                }
                                {perfectReplacements.length === 0 &&  warningReplacements.length === 0 && this.state.sendSearch &&
                                    <div className="w-100 mx-auto text-center mb-2">
                                        <div className="d-inline-block mb-3" style={{ width: 150, height: 150 }}>
                                            <img src={lampImg} alt="" width={150} height={150} />
                                        </div>
                                        <div className="uppercase text-white"><FormattedHTMLMessage id="No.Availability.Matches" /></div>
                                    </div>
                                }
                                {perfectReplacements.length > 0 && warningReplacements.length > 0 &&
                                    <div className='search-separator mb-4'></div>
                                }
                                {(replacements) && 
                                    warningReplacements.slice(0 + this.state.skip, this.state.limit  + this.state.skip).map(r => <SReplacementCard
                                    addShakeCbk={(cbk) => this.shakeCbks[r._id] = cbk}
                                    addRef={(ref) => this.replRefs[r._id] = ref}
                                    key={r._id}
                                    replacement={r} />)
                                }
                            </React.Fragment>
                        }

                        {this.state.displaySearch === 'sector' &&
                           <React.Fragment>
                                {(replacements) && 
                                    perfectReplacements.slice(0 + this.state.skip, this.state.limit  + this.state.skip).map(r => <SReplacementCard
                                    addShakeCbk={(cbk) => this.shakeCbks[r._id] = cbk}
                                    addRef={(ref) => this.replRefs[r._id] = ref}
                                    key={r._id}
                                    replacement={r} />)
                                }
                                {(replacements) && 
                                    warningReplacements.slice(0 + this.state.skip, this.state.limit  + this.state.skip).map(r => <SReplacementCard
                                    addShakeCbk={(cbk) => this.shakeCbks[r._id] = cbk}
                                    addRef={(ref) => this.replRefs[r._id] = ref}
                                    key={r._id}
                                    replacement={r} />)
                                }

                                {(perfectReplacements.length > 0 || warningReplacements.length > 0) &&
                                    <div className='search-separator mb-4'></div>
                                }

                                {(doctorsBySector) && 
                                    doctorsBySector.doctors.slice(0 + this.state.skip, this.state.limit  + this.state.skip).map((r) => <SReplacementCardBySector
                                        addShakeCbk={(cbk) => this.shakeCbks[r._id] = cbk}
                                        addRef={(ref) => this.replRefs[r._id] = ref}
                                        key={r._id}
                                        doctor={r} />)
                                }
                            </React.Fragment>
                        }

                        <Pagination
                            innerClass="w-100 pagination justify-content-center"
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={Math.round(this.state.skip / this.state.limit) + 1}
                            itemsCountPerPage={this.state.limit}
                            totalItemsCount={(this.state.displaySearch === 'replacement' ? totalReplacements : totalReplacementsBySector)}
                            pageRangeDisplayed={5}
                            onChange={(page) => this.setState({ skip: (page - 1) * this.state.limit })}
                            hideDisabled={false}
                        />
                    </React.Fragment>
                }
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
        substituteCharacteristic: state.global.substituteCharacteristic,
        replacement: state.global.replacement,
        replacements: state.global.replacements,
        replacementsCount: state.global.replacementsCount,
        doctorsBySector: state.userReducer.doctorsBySector
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),
        onClears: (objName) => dispatch(clears(objName)),
        searchDoctorsBySector: (filters) => dispatch(searchDoctorsBySector(filters)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchReplacement);



class GReplacementMap extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            previousRepls: []
        };

        // Geocode (for geocoding, no kidding)
        this.GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
        Geocode.setApiKey(this.GOOGLE_MAP_API_KEY);

        this.mapComponent = null;
        this.buildMap();

        if (this.props.addLoadMapCbk) this.props.addLoadMapCbk(this.loadMapCbk());
        if (this.props.addReplsMapCbk) this.props.addReplsMapCbk((replacements, city, doctorsBySector, obj) => this.buildMap(replacements, city, doctorsBySector, obj));
    }

    loadMapCbk() {
        this.setState({ loading: true });
    }

    buildMap(replacements, city, doctorsBySector, obj) {
        // Don't update the map for nothing
        if ((!replacements || replacements.length === 0) && (!this.state.previousRepls || this.state.previousRepls.length === 0) && this.mapComponent) {
            this.setState({ loading: false });
        }

        const { substituteCharacteristic } = this.props;

        var defaultLat = (substituteCharacteristic) ? substituteCharacteristic.location.coordinates[1] : 48.8534;
        var defaultLong = (substituteCharacteristic) ? substituteCharacteristic.location.coordinates[0] : 2.3488;

        if (city && city.coords) {
            defaultLat = city.coords[0];
            defaultLong = city.coords[1];
        }
        const getBounds = () => {
            const bounds = new window.google.maps.LatLngBounds();

            const testBoundAgainstCoords = (lat, lng) => {
                const latLng = new window.google.maps.LatLng(parseFloat(lat), parseFloat(lng));
                bounds.extend(latLng);
            };

            // Add every replacement zone
            if (replacements) replacements.map(r => testBoundAgainstCoords(r.doctorCharacteristic.location.coordinates[1], r.doctorCharacteristic.location.coordinates[0]));
            // Add sub location
            if (!this.state.coords) testBoundAgainstCoords(defaultLat, defaultLong);

            var offset = 0.002;
            var center = bounds.getCenter();
            bounds.extend(new window.google.maps.LatLng(center.lat() + offset, center.lng() + offset));
            bounds.extend(new window.google.maps.LatLng(center.lat() - offset, center.lng() - offset));

            return bounds;
        }

        const perfectReplacements = replacements ? replacements.filter((replacement) => {
            return !replacement.warning;
        }) : [];

        const warningReplacements = replacements ? replacements.filter((replacement) => {
            return replacement.warning;
        }) : [];


        let doctor = [];
        if (obj && obj.displaySearch === 'sector') {
            doctor = doctorsBySector.doctors.slice(obj.skip, obj.skip + 10);
        }

        this.mapComponent = withGoogleMap((props) =>
            <GoogleMap
                ref={map => map && map.fitBounds(getBounds())}
                defaultZoom={11}
                defaultCenter={{ lat: defaultLat, lng: defaultLong }}
                defaultOptions={{
                    mapTypeControl: false,
                    fullscreenControl: false,
                    zoomControl: true,
                    streetViewControl: false
                }}
            >
                {(!this.state.coords) &&
                    <>
                        {(props.isMarkerShown) &&
                            <Marker position={{ lat: defaultLat, lng: defaultLong }} />
                        }
                        {/* <Circle
                            defaultCenter={{
                                lat: parseFloat(defaultLat),
                                lng: parseFloat(defaultLong)
                            }}
                            radius={this.state.distance}
                            options={{
                                strokeColor: '#00CEE0',
                                strokeOpacity: 1,
                                strokeWeight: 1,
                                fillOpacity: 0,
                            }}
                        /> */}
                    </>
                }

                {(perfectReplacements) && perfectReplacements.map((r, key) => {
                    return (
                        <Marker
                            key={Math.random()}
                            onClick={() => this.props.onMarkerClick(r, key)}
                            icon={{
                                url: ProfileImages.getSrc(r.doctor.image), // url
                                scaledSize: new window.google.maps.Size(50, 50), // scaled size
                                origin: new window.google.maps.Point(0, 0), // origin
                                anchor: new window.google.maps.Point(0.5, 0.5) // anchor
                            }}
                            position={{
                                lat: parseFloat(r.doctorCharacteristic.location.coordinates[1]),
                                lng: parseFloat(r.doctorCharacteristic.location.coordinates[0])
                            }} />
                    );
                })}
                {(warningReplacements) && warningReplacements.map((r, key) => {
                    return (
                        <Marker
                            key={Math.random()}
                            onClick={() => this.props.onMarkerClick(r, key)}
                            icon={{
                                url: ProfileImages.getSrc(r.doctor.image), // url
                                scaledSize: new window.google.maps.Size(50, 50), // scaled size
                                origin: new window.google.maps.Point(0, 0), // origin
                                anchor: new window.google.maps.Point(0.5, 0.5) // anchor
                            }}
                            position={{
                                lat: parseFloat(r.doctorCharacteristic.location.coordinates[1]),
                                lng: parseFloat(r.doctorCharacteristic.location.coordinates[0])
                            }} />
                    );
                })}

                {obj && obj.displaySearch === 'sector' && 
                    <>
                        {(doctor) && doctor.map((r, key) => {
                            return (
                                <Marker
                                    key={Math.random()}
                                    onClick={() => this.props.onMarkerClick(r, key)}
                                    icon={{
                                        url: ProfileImages.getSrc(r.image), // url
                                        scaledSize: new window.google.maps.Size(50, 50), // scaled size
                                        origin: new window.google.maps.Point(0, 0), // origin
                                        anchor: new window.google.maps.Point(0.5, 0.5) // anchor
                                    }}
                                    position={{
                                        lat: parseFloat(r.doctorCharacteristic.location.coordinates[1]),
                                        lng: parseFloat(r.doctorCharacteristic.location.coordinates[0])
                                    }} />
                            );
                        })}
                    </>
                }
            </GoogleMap>
        );

        this.setState({ loading: false, previousRepls: replacements });
    }

    loading = () => {
        return (
            <div className="w-100 text-center mt-2 mb-2 h-420px">
                <div className="spinner-border text-white mx-auto" role="status" style={{ marginTop: 125 }}></div>
            </div>
        );
    }

    render() {
        return (
            <div className="p-0 m-0" style={{ height: `463px` }}>
                <this.mapComponent
                    style={{ marginTop: "-10px" }}
                    isMarkerShown={true}
                    // googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + this.GOOGLE_MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places"}
                    loadingElement={<div style={{ height: `100%`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                    containerElement={<div style={{ height: `463px`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                    mapElement={<div style={{ height: `100%`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                />

                {(this.state.loading) &&
                    <div className="position-absolute top left h-420px bg-transp-grey w-100">
                        {this.loading()}
                    </div>
                }
            </div>
        )
    }

}
