import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import 'react-datetime/css/react-datetime.css';
import { Col, Card, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Calendar, momentLocalizer } from "react-big-calendar";
import { allByDoctor } from '../../../actions/replacements/replacements';
import { downloadContract } from "../../../actions/agreement/agreement";
import DChoiceModal from './DChoiceModal';
import { upds } from "../../../actions/global/global";
import DateUtil from '../../../util/DateUtil';
import Util from '../../../util/Util';
import moment from 'moment';
import Year from '../../calendar/Year'
import DAddCalendarEventModal from './DAddCalendarEventModal';
import DCalendarEventModal from './DCalendarEventModal'
import SubstituteProfileModal from '../../substitutes/profile/SubstituteProfileModal'
import DCalendarReplacementModal from './DCalendarReplacementModal';
import DReplacementContractModal from '../replacements/DReplacementContractModal';

const localizer = momentLocalizer(moment);

class DCalendar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            view: this.props.view ? this.props.view : "month",
            date: new Date(),
            first_date_of_month: moment().startOf('month'),
            last_month_load: '',
            showFilter: false,
            showAll: true,
            hideAll: false,
            showAgreements: true,
            showReplacements: true,
            showGuard: true,
            showEvents: true,
        };

        this.startDate = DateUtil.decrementDays(DateUtil.startOfMonth(new Date()), 7);
        this.endDate = moment().add(1, 'year').format('YYYY-MM-DD');
		this.customToolBar = this.customToolBar.bind(this);
		this.closeModal = this.closeModal.bind(this);
        this.eventColors = this.eventColors.bind(this);
        this.openReplacementModal = this.openReplacementModal.bind(this);
        this.onDrillDown = this.onDrillDown.bind(this);
        this.onNavigate = this.onNavigate.bind(this);
        this.eventModal = this.eventModal.bind(this);
        this.onCancel = this.onCancel.bind(this);
    };

    componentDidUpdate(prevProps, prevState) {
        if(prevState.modal !== this.state.modal && this.state.modal === null) {
            this.props.allByDoctor();
        }
    }

    closeModal() {
        this.setState({ modal: null });
    }

    onRangeChange = (data) => {
        if (data.length === 7) {
            this.setState({ view: "week" });
        } else if (data.start && data.end) {
            this.setState({ view: "month" });
        } else {
            this.setState({ view: "year" });
        }
    };

    onNavigate(date) {
        this.setState({ date, first_date_of_month: moment(date).startOf('month') });
    }

    // Quand le user clique sur la date en chiffre du calendrier
    onDrillDown(startDate) {
        this.openReplacementModal({start: startDate, end: startDate});
    }

    // Ouvre une modal en cliquant sur une date dans le calendrier
    openReplacementModal = (period) => {
        let startDate = period && period.start;
        let endDate = period && period.end;

        let openBy = false;
        let monthSelected = moment(this.state.date).startOf('day').toDate();
        // Si aucune date est défini
        if (moment.isDate(startDate) === false) {
            openBy = true;
        }
        if(!this.props.doctorCharacteristic) return;
        if (this.state.modal) {
            this.setState({
                modal: null
            });
        } else {
            // Concat les tableaux de dispos, d'événements et les remplacements déjà signé
            this.setState({
                modal: <DChoiceModal
                    startDate={startDate}
                    endDate={endDate}
                    toggle={this.openReplacementModal}
                    close={this.closeModal}
                    doctorCharacteristic={this.props.doctorCharacteristic}
                    replacements={this.props.replacements}
                    events={this.props.calendarEvents}
                    openBy={openBy}
                    monthSelected={monthSelected}
                    />
            });
        }
    };
    
    openCalendarEventModal = (startDate, endDate) => {
        if (this.state.modal) {
            this.setState({
                modal: null
            });
        } else {
            this.setState({
                modal: <DAddCalendarEventModal
                    startDate={startDate}
                    endDate={endDate}
                    toggle={this.openCalendarEventModal}
                />
            });
        }
    };

    onCancel() {
        this.setState({ modal: null });
    }

    downloadContract(agreementId, successCbk) {
        this.props.onDownloadContract(agreementId, successCbk);
    }

    cancelAgreement(agreementId, successCbk) {
        this.props.onUpds("agreement/cancel/" + agreementId, {}, () => {
            successCbk();
            this.closeModal();
        });
    }

    sendToCouncil(agreementId, successCbk) {
        this.props.onUpds("agreement/send/" + agreementId, {}, () => {
            successCbk();
            this.closeModal();
        });
    }

    openContractModal = (agreement, applicant, substituteCharacteristic, replacement) => {
        const { officeTimeTable } = this.props;

        let replacementObj = Object.assign({}, replacement, { applicants_users: replacement.applicants});

        var modal = <DReplacementContractModal
            agreement={agreement}
            applicant={applicant}
            substituteCharacteristic={substituteCharacteristic}
            replacement={replacementObj}
            officeTimeTable={officeTimeTable}
            toggle={this.closeModal}
            onSignatureCbk={(agreement) => {
                
            }}
        />;

        if (this.state.modal) {
            this.setState({ modal: null }, () => this.setState({ modal: modal }));
        } else {
            this.setState({ modal: modal });
        }
    }

    eventModal(event, onConfirm, onCancel) {
        if (event._type === "Replacement") {
            const replacement = Object.assign({}, event.original);
            delete replacement.title;
            if (replacement.concludedAgreements) {
                let agreementReplacement = Object.assign({}, replacement.concludedAgreements, { 
                    replacement: replacement
                });

                let agreementGlobal = Object.assign({}, replacement.concludedAgreements, {
                    substitute: replacement.substitute
                }, {
                    substituteCharacteristic: replacement.substituteCharacteristics
                }, {
                    replacement: replacement
                })

                this.setState({
                    modal: <SubstituteProfileModal
                        substitute={replacement.substitute}
                        characteristics={replacement.substituteCharacteristics}
                        agreement={agreementGlobal}
                        closeModal={this.onCancel}
                        replacement={replacement}
                        downloadContract={(agreementId, successCbk) => this.downloadContract(agreementId, successCbk)}
                        onSignContract={() => this.openContractModal(agreementReplacement, replacement.substitute, replacement.substituteCharacteristics, replacement)}
                        cancelAgreement={(agreementId, successCbk) => this.cancelAgreement(agreementId, successCbk)}
                        sendToCouncil={(agreementId, successCbk) => this.sendToCouncil(agreementId, successCbk)}
                    />
                });
            } else {
                this.setState({
                    modal: <DCalendarReplacementModal
                        history={this.props.history}
                        event={event}
                        onConfirm={onConfirm}
                        onCancel={onCancel}
                        toggle={this.onCancel} />
                })
            }
        } else if (event._type === "CalendarEvent") {
            this.setState({
                modal: <DCalendarEventModal
                    calendarEvents={event.data}
                    onApply={this.fetchData}
                    toggle={this.onCancel} />
            });
        }
    }

    formatReplacements(replacements) {
        var arrayReplacements = [];
        var arrayAgreements = [];
        var arrayGuards = [];
        let startDate = this.startDate;
        let endDate = this.endDate;
        if (this.state.view !== 'year') {
            startDate = moment(this.state.first_date_of_month).subtract(7, 'days');
            endDate = moment(this.state.first_date_of_month).add(1, 'month').add(7, 'days');
        }
        
        for (let replacement of replacements) {
            let nextRound = false;
            if (moment(replacement.end_date).isAfter(startDate) && moment(replacement.start_date).isBefore(endDate)) {
                nextRound = true;
            }
            if (nextRound === false) {
                continue;
            }
            let clonedReplacement = Util.clone(replacement);
            clonedReplacement._type = "Replacement";
            clonedReplacement.start = clonedReplacement.start_date;
            clonedReplacement.end = clonedReplacement.end_date;

            let remplaSignByTheTwo = false;
            if (replacement.concludedAgreements) {
                if (replacement.concludedAgreements.doctor_initials && replacement.concludedAgreements.doctor_signature && replacement.concludedAgreements.substitute_initials && replacement.concludedAgreements.substitute_signature) {
                    remplaSignByTheTwo = true;
                }
            };
            
            if (remplaSignByTheTwo) {
                let bgClassName = "bg-occasional";
                if (replacement.type === "regular") bgClassName = "bg-regular";
                if (replacement.type === "guard") bgClassName = "bg-guard";
                clonedReplacement.title = (
                    <Col sm="12" className={"paddingTopCustom border-0 text-white lp-1 " + bgClassName}>
                        {replacement.substitute.first_name + " " + replacement.substitute.name}
                    </Col>
                );
            } else {
                if (replacement.invitation_by_doctor === true) {
                    let statusSub = 0;
                    if (replacement.applicants) {
                        statusSub = replacement.applicants[0].substitute_status;
                    }
                    if (statusSub === 2) continue;
                }
                // Afficher ceux ou doctor status est à 0 et sub status par egal à 2 
                let nbApplicants = 0;
                if (replacement.applicants) {
                    replacement.applicants.forEach((applicant) => {
                        if (applicant.doctor_status === 0 && applicant.substitute_status !== 2) {
                            nbApplicants += 1;
                        }
                    })
                }
                let bgClassName = "green-light ";
                if (replacement.type === "regular") {
                    bgClassName = "blue-light";
                } 
                if (replacement.type === "guard") {
                    bgClassName = "red-light";
                } 

                clonedReplacement.title = (
                    <Col sm="12" className="paddingTopCustom border-0 bg-paleblue text-dark lp-1 d-flex">
                        <div className={"ml-0 pl-0 light-margin-calendar " + bgClassName}></div>&nbsp;
                        <FormattedMessage id="Announcement"/>
                        <span className='ml-auto'>{nbApplicants}</span>
                    </Col>
                );
            }

            let events = [];
            replacement.days_available.forEach((elem) => {
                let event = {};
                event._type = "Replacement";
                event.allDay = false
                event.title = clonedReplacement.title;
                event.color = clonedReplacement.type;
                event.start = new Date(elem.start);
                event.end = new Date(elem.end);
                event.sign = remplaSignByTheTwo;
                event.original = clonedReplacement;

                events.push(event);
            })

            if (remplaSignByTheTwo) {
                if (replacement.type !== 'guard') {
                    arrayAgreements = arrayAgreements.concat(events);
                } else {
                    arrayGuards = arrayGuards.concat(events);
                }
            } else {
                arrayReplacements = arrayReplacements.concat(events);
            }
        }
        return { arrayReplacements, arrayAgreements, arrayGuards };
    };
    
    eventColors(event) {
        let classNameCustom;
        if (event._type === 'CalendarEvent') {
            classNameCustom = 'bg-orange p1 lp-1 pl-1';
        } 
        
        var style = {
            whiteSpace: 'pre-wrap',
            borderRadius: 5
        };

        return {
            className: classNameCustom,
            style: style
        };
    }

    //! ============================================================================================
    //! ==================================== CALENDAR EVENTS =======================================
    //! ============================================================================================
    formatCalendarEvents(calendarEvents) {
        var arrayDates = [];
        var result = [];

        let startDate = this.startDate;
        let endDate = this.endDate;

        if (this.state.view !== 'year') {
            startDate = moment(this.state.first_date_of_month).subtract(7, 'days');
            endDate = moment(this.state.first_date_of_month).add(1, 'month').add(7, 'days');
        }
        
        let calendarEventsFilter = calendarEvents.filter((elem) => {
            return moment(elem.end_date).isAfter(startDate) && moment(elem.start_date).isBefore(endDate)
        }).sort((a, b) => {
            return moment(a.start_date).isBefore(moment(b.start_date)) ? -1 : 1;
        })
        
        for (let calendarEvent of calendarEventsFilter) {
            let startDate = moment(calendarEvent.start_date);
            let endDate = moment(calendarEvent.end_date);
            let startDateOriginal = moment(calendarEvent.start_date);

            // Rajoute au tableau les jours entre deux dates
            while (startDate.isSameOrBefore(endDate, 'day')) {

                let found = arrayDates.find((elem) => {
                    return elem.date === startDate.format('YYYY-MM-DD');
                });

                if (found) {
                    found.data.push({
                        _id: calendarEvent._id,
                        start_date: moment(startDate),
                        end_date: calendarEvent.end_date,
                        prevent_availabilities: calendarEvent.prevent_availabilities,
                        doctor_id: calendarEvent.doctor_id,
                        title: calendarEvent.title,
                        desc: calendarEvent.desc,
                        allDay: false
                    })
                    
                    if (startDate.isSame(endDate, 'day')) {
                        if (endDate.isSame(moment(endDate).startOf('day'))) {
                            break;
                        }
                        if (!startDate.isSame(startDateOriginal, 'day')) {
                            found.data[found.data.length - 1].start_date = moment(startDate).startOf('day');
                        }
                    } else {
                        found.data[found.data.length - 1].end_date = moment(startDate).endOf('day');
                        if (!startDate.isSame(startDateOriginal, 'day')) {
                            found.data[found.data.length - 1].start_date = moment(startDate).startOf('day');
                        } 
                    }
    
                    if (moment(found.data[found.data.length - 1].start_date).isSame(moment(found.data[found.data.length - 1].start_date).startOf('day')) && moment(found.data[found.data.length - 1].end_date).isSame(moment(found.data[found.data.length - 1].end_date).endOf('day'))) {
                        found.data[found.data.length - 1].allDay = true;
                    } else if (moment(found.data[found.data.length - 1].start_date).isAfter(moment(found.data[found.data.length - 1].start_date).hour(22), 'hours') || moment(found.data[found.data.length - 1].end_date).isBefore(moment(found.data[found.data.length - 1].start_date).hour(7).minute(1), 'minutes')) {
                        found.data[found.data.length - 1].allDay = true;
                    }
                } else {
                    let obj = {
                        date: startDate.format('YYYY-MM-DD'),
                        data : [{
                            _id: calendarEvent._id,
                            start_date: moment(startDate),
                            end_date: calendarEvent.end_date,
                            prevent_availabilities: calendarEvent.prevent_availabilities,
                            doctor_id: calendarEvent.doctor_id,
                            title: calendarEvent.title,
                            desc: calendarEvent.desc,
                            allDay: false
                        }]
                    }

                    if (startDate.isSame(endDate, 'day')) {
                        if (endDate.isSame(moment(endDate).startOf('day'))) {
                            break;
                        }
                        if (!startDate.isSame(startDateOriginal, 'day')) {
                            obj.data[0].start_date = moment(startDate).startOf('day');
                        }
                    } else {
                        obj.data[0].end_date = moment(startDate).endOf('day');
                        if (!startDate.isSame(startDateOriginal, 'day')) {
                            obj.data[0].start_date = moment(startDate).startOf('day');
                        } 
                    }
    
                    if (moment(obj.data[0].start_date).isSame(moment(obj.data[0].start_date).startOf('day')) && moment(obj.data[0].end_date).isSame(moment(obj.data[0].end_date).endOf('day'))) {
                        obj.data[0].allDay = true;
                    } else if (moment(obj.data[0].start_date).isAfter(moment(obj.data[0].start_date).hour(22), 'hours') || moment(obj.data[0].end_date).isBefore(moment(obj.data[0].start_date).hour(7).minute(1), 'minutes')) {
                        obj.data[0].allDay = true;
                    }
                    
                    arrayDates.push(obj);
                }
                startDate.add(1, 'days');
            }
        }

        // Boucle sur le nouveau tableau pour rajouter les différentes couleurs dans une seule case
        for (let elem of arrayDates) {
            let className;
            for (let sub_elem of elem.data) {
                className = <Col sm="12" className="border-0 bg-orange text-dark lp-1 ">
                    <div className="rbc-event-content" title={sub_elem.title}>{(sub_elem.prevent_availabilities) && <><i className="fa fa-lock" ></i>&nbsp;&nbsp;</>}{sub_elem.title}</div>
                </Col>

                let eventObj = {
                    start: moment(sub_elem.start_date).toDate(),
                    end: moment(sub_elem.end_date).toDate(),
                    color: "dark",
                    title: className,
                    _type: 'CalendarEvent',
                    data: elem.data,
                    prevent_availabilities: sub_elem.prevent_availabilities,
                    allDay: sub_elem.allDay
                }            
                result.push(eventObj)  
            }
        }
        return result;
    }

    // Grise les dimanches
    customDayPropGetter(date) {
        if (date.getDay() === 0) {
            return {
                className: 'rbc-sunday',
            };
        }
    };

    // Customise la toolbar
    customToolBar(toolbar) {
        const goToWeekView = () => {
            this.props.changeView('week');
        };

        const goToMonthView = () => {
            toolbar.onView("month");
            this.setState({ view: "month", date: toolbar.date, first_date_of_month: moment(toolbar.date).startOf('month') });
        };

        const goToYearView = () => {
            toolbar.onView("year");
            this.setState({ view: "year", date: new Date() });
        };

        const goToToday = () => {
            toolbar.onView("month");
            this.setState({ view: "month", date: new Date(), first_date_of_month: moment().startOf('month') });
        }

        const goToBack = () => {
            let view = this.state.view;
            let mDate = toolbar.date;
            let newDate;
            let newStartDate;
            if (view === "month") {
                newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
                newStartDate = new Date(mDate.getFullYear(), mDate.getMonth() - 2, 0);

            } else if (view === "week") {
                newStartDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() - 14, 1);
                newDate = new Date(
                    mDate.getFullYear(),
                    mDate.getMonth(),
                    mDate.getDate() - 7,
                    1
                );
            } else if (view === 'year') {
                newStartDate = new Date(mDate.getFullYear() - 1, 0);
                newDate = new Date(
                    mDate.getFullYear() - 1,
                    1
                );
            } 
            this.startDate = newStartDate;
            this.setState({
                last_month_load: newStartDate
            })
            // this.fetchData(1);
            toolbar.onNavigate("prev", newDate);
        }

        const goToNext = () => {
            let view = this.state.view;
            let mDate = toolbar.date;
            let newDate;
            if (view === "month") {
                newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
            } else if (view === "week") {
                newDate = new Date(
                    mDate.getFullYear(),
                    mDate.getMonth(),
                    mDate.getDate() + 7,
                    1
                );
            } else if (view === 'year') {
                newDate = new Date(
                    mDate.getFullYear() + 1,
                    1
                );
            } 
            toolbar.onNavigate("next", newDate);
        };
        var dateEntete = toolbar.label.toUpperCase();

        const onChangeShowFilter = () => {
            this.setState({
                showFilter: !this.state.showFilter
            })
        }

        const onChangeFilter = (event) => {
            let name = event.currentTarget.getAttribute('name');

            if (name === 'showAll') {
                this.setState({
                    showAgreements: true,
                    showEvents: true,
                    showReplacements: true,
                    showGuard: true,
                    hideAll: false,
                    showAll: true
                })
            } else if (name === 'hideAll') {
                this.setState({
                    showAgreements: false,
                    showEvents: false,
                    showReplacements: false,
                    showGuard: false,
                    showAll: false,
                    hideAll: true
                })
            } else {
                this.setState({
                    [name]: !this.state[name],
                    showAll: false,
                    hideAll: false
                })
            }
        }

        return (
            <div className='header-scalendar row'>
                <div className='col-md-3 order-md-1 col-6 order-1 d-flex justify-content-sm-center align-items-sm-center pl-3'>
                    <Dropdown isOpen={this.state.showFilter} toggle={onChangeShowFilter}>
                        <DropdownToggle caret>
                            Filtrer
                        </DropdownToggle>
                        <DropdownMenu className='dropDownMenuFilter dropdown-item-border-radius'>
                            <div className='dropdown-item-custom'>
                                <div id="showAll" name="showAll" onClick={onChangeFilter}  className={"text-color-black clickable " + (this.state.showAll === true ? 'bold' : '')}>
                                    <div className="green-light pl-0 ml-0"></div>
                                    <div className="cssNotifShowLegendNotSign pl-0 merge-light"></div>
                                    <div className="red-light pl-0 merge-light"></div>
                                    <div className="cssNotifShowEventColor pl-0 merge-light"></div>
                                    &nbsp;Tout voir
                                </div>
                            </div>
                            <div className='dropdown-item-custom'>
                                <div name="showAgreements" id="showAgreements" onClick={onChangeFilter} className={"text-color-black clickable " + (this.state.showAgreements === true ? 'bold' : '')}>
                                    <div className="green-light ml-0 pl-0" ></div>&nbsp;Les remplacements
                                </div>
                            </div>
                            <div className='dropdown-item-custom'>
                                <div name="showReplacements" id="showReplacements" onClick={onChangeFilter} className={"text-color-black clickable " + (this.state.showReplacements === true ? 'bold' : '')}>
                                    <div className="cssNotifShowLegendNotSign ml-0 pl-0" ></div>&nbsp;Les annonces
                                </div>
                            </div>
                            <div className='dropdown-item-custom'>
                                <div name="showGuard" id="showGuard" onClick={onChangeFilter} className={"text-color-black clickable " + (this.state.showGuard === true ? 'bold' : '')}>
                                    <div className="red-light ml-0 pl-0" onClick={onChangeFilter}></div>&nbsp;Les gardes
                                </div>
                            </div>
                            <div className='dropdown-item-custom'>
                                <div name="showEvents" id="showEvents" onClick={onChangeFilter} className={"text-color-black clickable " + (this.state.showEvents === true ? 'bold' : '')}>
                                    <div className="ml-0 pl-0 cssNotifShowEventColor" onClick={onChangeFilter}></div>&nbsp;Les événements
                                </div>
                            </div>
                            <div className='dropdown-item-custom'>
                                <div name="hideAll" id="hideAll" onClick={onChangeFilter} className={"text-color-black clickable " + (this.state.hideAll === true ? 'bold' : '')}>
                                    X Tout masquer
                                </div>
                            </div>
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div className='col-12 order-3 col-md-6 order-md-2 text-center header-date-calendar'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-md-1 col-1'>
                            <button className="custom-button-today" onClick={goToToday}>
                                <i className="fa fa-calendar-check fa-2x"></i>
                            </button>
                        </div>
                        <div className='col-md-6 col-11 d-flex align-items-center justify-content-center'>
                            <div className='arrow-button-custom' type="button" onClick={goToBack}>
                                <span className="prev-icon-arrow">&#8249;</span>
                            </div>
                            <div className="dateEnteteSCalendar calendar-size-date">{dateEntete}</div>
                            <div className='arrow-button-custom' type="button" onClick={goToNext}>
                                <span className="next-icon-arrow">&#8250;</span>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-12 d-flex justify-content-around'>
                            <span className={"clickable " + (this.state.view === 'year' ? 'surround-view-calendar' : '')} onClick={goToYearView}>
                                    <div className={"buttonMonth " + (this.state.view === 'year' ? 'surround-view-calendar-text' : '')}>A</div>
                            </span>
                            <span className={"clickable " + (this.state.view === 'month' ? 'surround-view-calendar' : '')} onClick={goToMonthView}>
                                <div className={"buttonMonth " + (this.state.view === 'month' ? 'surround-view-calendar-text' : '')}>M</div>
                            </span>
                            <span className={"clickable " + (this.state.view === 'week' ? 'surround-view-calendar' : '')} onClick={goToWeekView}>
                                <div className={"buttonMonth " + (this.state.view === 'week' ? 'surround-view-calendar-text' : '')}>S</div>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-6 order-1 col-md-2 order-md-3 d-flex justify-content-end align-items-center pr-3">
                    <span onClick={this.openReplacementModal} className="bg-orange text-white text-center clickable circleCustom">+</span>
                </div>
            </div>
          );
    }

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    render() {
        const { isGetReplacements, replacements, calendarEvents } = this.props;
        if (!isGetReplacements || isGetReplacements === false) return this.loading();
        const { arrayReplacements, arrayAgreements, arrayGuards } = this.formatReplacements(replacements);
        const formattedCalendarEvents = (calendarEvents.length) ? this.formatCalendarEvents(calendarEvents) : [];

        let events = [];
        if(this.state.showAll) {
            events.push(arrayAgreements.concat(formattedCalendarEvents).concat(arrayReplacements).concat(arrayGuards));
        } else {
            if (this.state.showAgreements) {
                events.push(arrayAgreements);
            } 
            if (this.state.showReplacements) {
                events.push(arrayReplacements);
            }
            if (this.state.showGuard) {
                events.push(arrayGuards);
            }
            if (this.state.showEvents) {
                events.push(formattedCalendarEvents);
            } 
        }
        if (this.state.hideAll) {
            events = [];
        }

        if (events.length > 0) {
            events = events.flat();
        }

        const messages = {
            allDay: <FormattedMessage id="Calendar.AllDay" />,
            previous: <FormattedMessage id="Calendar.Previous" />,
            next: <FormattedMessage id="Calendar.Next" />,
            today: <FormattedMessage id="Calendar.Today" />,
            month: <FormattedMessage id="Calendar.Month" />,
            week: <FormattedMessage id="Calendar.Week" />,
            day: <FormattedMessage id="Calendar.Day" />,
            agenda: <FormattedMessage id="Calendar.Agenda" />,
            date: <FormattedMessage id="Calendar.Date" />,
            time: <FormattedMessage id="Calendar.Time" />,
            event: <FormattedMessage id="Calendar.Event" />,
            year: <FormattedMessage id="Year" />,
            showMore: total => `+ ${total} autre(s)`
        };

        let newDate = new Date()

        return (
            
            <React.Fragment>
                <Card className="p-0 rounded-0" id="customDCalendar">
                    <Calendar
                        components={{ 
                            toolbar: this.customToolBar,
                        }}
                        renderable
                        selectable
                        popup={true}
                        step={30}
                        min={new Date(0, 0, 0, 7, 0, 0)}
                        max={new Date(0, 0, 0, 22, 0, 0)}
                        localizer={localizer}
                        defaultDate={newDate}
                        messages={messages}
                        events={events}
                        view={this.state.view}
                        views={{ 
                            month: true,
                            year: Year  
                        }}
                        culture={'fr'}
                        date={this.state.date}
                        eventPropGetter={this.eventColors}
                        dayPropGetter={this.customDayPropGetter}
                        onView={this.onRangeChange}
                        onNavigate={this.onNavigate}
                        onRangeChange={this.onRangeChange}
                        onSelectEvent={this.eventModal}
                        onSelectSlot={this.openReplacementModal}
                        onDrillDown={this.onDrillDown}
                        from={'DCalendar'}
                    />
                </Card>
                {this.state.modal}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        replacements: state.replacements.replacements,
        isGetReplacements: state.replacements.isGetReplacements,
        calendarEvents: state.global.calendarEvents,
        doctorCharacteristic: state.global.doctorCharacteristic,
        officeTimeTable: state.global.officeTimeTable
    }
};

const mapDispatchToProps = dispatch => {
    return {
        allByDoctor: () => dispatch(allByDoctor()),
        onDownloadContract: (agreementId, cbk) => downloadContract(agreementId, cbk),
        onUpds: (objName, obj, cbk) => dispatch(upds(objName, obj, cbk))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DCalendar));