export default function appointments(state = {
	arrayAppointments: []
}, action) {
	switch(action.type) {
		case "GETS_APPOINTMENT_REQUEST":
			return Object.assign({}, state, {
				getsAppointmentsByUsers: false
			});
		case "GETS_APPOINTMENT_SUCCESS":
			return Object.assign({}, state, {
				getsAppointmentsByUsers: true,
				appointmentsByUsers: action.users.users,
			});
		case "GETS_APPOINTMENT_FAILURE":
			return Object.assign({}, state, {
				getsAppointmentsByUsers: false
			});
		case "ADD_APPOINTMENT_REQUEST":
			return Object.assign({}, state, {
				addAppointment: false
			});
		case "ADD_APPOINTMENT_SUCCESS":
			let appointmentsByUsers = Array.from(state.appointmentsByUsers);
			appointmentsByUsers.forEach((user) => {
				if (user._id === action.appointment.appointment.user_id) {
					user.appointments = user.appointments.filter((appointment) => {
						return !!appointment._id;
					})
					user.appointments.push(action.appointment.appointment);
				}
			})
			return Object.assign({}, state, {
				addAppointment: true,
				appointmentsByUsers: appointmentsByUsers
			});
		case "ADD_APPOINTMENT_FAILURE":
			return Object.assign({}, state, {
				addAppointment: false
			});
		case "EDIT_APPOINTMENT_REQUEST":
			return Object.assign({}, state, {
				editAppointment: false
			});
		case "EDIT_APPOINTMENT_SUCCESS":
			let editAppointmentsByUsers = Array.from(state.appointmentsByUsers);

			editAppointmentsByUsers.forEach((user) => {
				if (user._id === action.userId) {
					user.appointments = user.appointments.filter((appointment) => {
						return appointment._id !== action.appointment.appointment._id;
					})
					user.appointments.push(action.appointment.appointment);
				}
			});
			// Pour mettre à jour les vignettes de rdv
			let arrayAppointmentsFilter = state.arrayAppointments.filter((appointment) => {
				return appointment._id !== action.appointment.appointment._id;
			})

			return Object.assign({}, state, {
				editAppointment: true,
				appointmentsByUsers: editAppointmentsByUsers,
				arrayAppointments: arrayAppointmentsFilter
			});
		case "EDIT_APPOINTMENT_FAILURE":
			return Object.assign({}, state, {
				editAppointment: false
			});
		case "DELETE_APPOINTMENT_REQUEST":
			return Object.assign({}, state, {
				isDeleteAppointment: false
			});
		case "DELETE_APPOINTMENT_SUCCESS":
			let appointmentNotDeleted = Array.from(state.appointmentsByUsers);

			appointmentNotDeleted.forEach((user) => {
				if (user._id === action.appointment.appointment.user_id) {
					user.appointments =  user.appointments.filter((appointment) => {
						return appointment._id !== action.appointment.appointment._id;
					})
				}
			});

			return Object.assign({}, state, {
				isDeleteAppointment: true,
				appointmentsByUsers: appointmentNotDeleted
			});
		case "DELETE_APPOINTMENT_FAILURE":
			return Object.assign({}, state, {
				isDeleteAppointment: false
			});
		case "ADD_APPOINTMENT_MODAL_SUCESS":
			let arrayAppointments = Array.from(state.arrayAppointments);

			if (state.arrayAppointments.length > 0) {
				let findAppointment = state.arrayAppointments.find((appointment) => {
					return appointment._id === action.newAppointment._id;
				});
				if (!findAppointment) {
					arrayAppointments.push(action.newAppointment);
				}
			} else {
				arrayAppointments.push(action.newAppointment);
			}

			return Object.assign({}, state, {
				isAddAppointmentModal: true,
				arrayAppointments: arrayAppointments,
				arrayAgreements: action.arrayAgreements,
				agreements: action.agreements,
				selectedUser: action.selectedUser,
				arrayOfficeHours: action.arrayOfficeHours
			});
		case "REMOVE_APPOINTMENT_MODAL_SUCESS":
			let appointmentFilter = state.arrayAppointments.filter((appointment) => {
				return appointment._id !== action.newAppointment;
			});

			return Object.assign({}, state, {
				arrayAppointments: appointmentFilter
			});
		default:
			return state;
	}
}