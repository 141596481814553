import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage } from 'react-intl';
import 'ladda/dist/ladda-themeless.min.css';

export default class DoctorAboutProfile extends Component {

    render() {
        const { doctorCharacteristic } = this.props;

        return (
            <React.Fragment>

                <div className="">

                <h5 className="">
                    <b><FormattedMessage id="About" /></b>
                </h5>

                <div className="mx-auto  text-left">
                    <p className="mt-0 mb-0">
                        <FormattedMessage id="Sector" />:&nbsp; <b>{doctorCharacteristic.sector}</b>
                    </p>

                    <p className="mt-0 mb-0">
                        <FormattedMessage id="Consultations.Per.Week.On.Average.Short" />:&nbsp; <b>{doctorCharacteristic.consultations_per_week_on_avg}</b>
                    </p>

                    <p className="mt-0 mb-0">
                        <FormattedMessage id="Visits.Per.Week.On.Average.Short" />:&nbsp; <b>{doctorCharacteristic.visits_per_week_on_avg}</b>
                    </p>
                    

                    {(doctorCharacteristic.sector === "2") &&
                        <>
                            <p className="mt-0 mb-0">
                                <FormattedMessage id="Consultation.Price" />:&nbsp; <b>{doctorCharacteristic.consultation_price}€</b>
                            </p>

                            <p className="mt-0 mb-0">
                                <FormattedMessage id="Visit.Price" />:&nbsp; <b>{doctorCharacteristic.visit_price}€</b>
                            </p>
                            
                            {/* <p>
                                <FormattedMessage id="Office.Disposal" />:&nbsp; <b>{doctorCharacteristic.office_disposal}</b>
                            </p> */}
                        </>
                    }

                    

                  
                </div>

                </div>

            </React.Fragment >
        );
    };

}