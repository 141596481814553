import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { upd } from "../../../actions/global/global";
import PIProfileForm from '../../registration/psychologist/forms/PIProfileForm';
import GDPRSettings from '../../boths/settings/GDPRSettings';
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import UserForm from '../../registration/form/UserForm';

class PsyISettings extends Component {

    constructor(props) {
        super(props);

        const { category } = this.props.match.params;

        this.state = {
            tab: category || "info"
        };
    }

    toggle(tab) {
        this.setState({ tab });
    }

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    render() {
        const { user, psychologistICharacteristic } = this.props;

        if (!user || !psychologistICharacteristic) return this.loading();

        return (
            <React.Fragment>

                <div className="w-100 text-white text-center uppercase mb-4 lp-2">
                    <h4><FormattedMessage id="Account.Parameters" /></h4>
                </div>

                <Row className="text-center mt-4 mb-4 text-white font-big">
                    <Col sm="4">
                        <div className={"link-hoverable " + ((this.state.tab === "info") ? "link-active" : "")} onClick={() => this.toggle("info")}>
                            <FormattedMessage id="My.Info" />
                        </div>
                    </Col>

                    <Col sm="4">
                        <div className={"link-hoverable " + ((this.state.tab === "profile") ? "link-active" : "")} onClick={() => this.toggle("profile")}>
                            <FormattedMessage id="My.Profile" />
                        </div>
                    </Col>

                    <Col sm="4">
                        <div className={"link-hoverable " + ((this.state.tab === "notifications") ? "link-active" : "")} onClick={() => this.toggle("notifications")}>
                            <FormattedMessage id="GDPR" />
                        </div>
                    </Col>
                </Row>

                <Row className="step step1 mb-4 justify-content-md-center ">
                    <Col lg="10">

                        {(this.state.tab === "info") &&
                            <>
                                <UserForm
                                    defaultValues={{ ...user, password: "********" }}
                                    submitCbk={(user, cbk) => {
                                        delete user.confirmation_mot_de_passe;
                                        this.props.onUpd("user", { user }, () => {
                                            if (cbk) cbk();

                                            new Noty({
                                                type: "info",
                                                layout: 'topRight',
                                                theme: 'bootstrap-v4',
                                                text: this.props.intl.formatMessage({ id: "Profile.Modified.Success" }),
                                                timeout: 6000,
                                            }).show();
                                        })
                                    }}
                                    submitButtoni18nId={"Update"}
                                    disableEmail={true}
                                    role = {user.role}
                                    hasOfficeNumber />
                            </>
                        }

                        {(this.state.tab === "profile") &&
                            <>
                                <PIProfileForm
                                    defaultValues={{ ...psychologistICharacteristic }}
                                    submitCbk={(psychologistICharacteristic, cbk) => {
                                        this.props.onUpd("psychologistICharacteristic", { psychologistICharacteristic }, () => {
                                            if (cbk) cbk();

                                            new Noty({
                                                type: "info",
                                                layout: 'topRight',
                                                theme: 'bootstrap-v4',
                                                text: this.props.intl.formatMessage({ id: "Profile.Modified.Success" }),
                                                timeout: 6000,
                                            }).show();
                                        })
                                    }}
                                    submitButtoni18nId={"Update"}
                                />
                            </>
                        }

                        {(this.state.tab === "notifications") &&
                            <>
                                <GDPRSettings />
                            </>
                        }

                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
        psychologistICharacteristic: state.global.psychologistICharacteristic
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onUpd: (objName, obj, cbk) => dispatch(upd(objName, obj, cbk))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PsyISettings));


