import React from 'react'
import moment from 'moment'
import * as dates from 'react-big-calendar/lib/utils/dates'

function createCalendar(date) {
    let currentDate = moment(date)

    const first = currentDate.clone().startOf('month')
    const last = currentDate.clone().endOf('month')
    var weeksCount = Math.ceil((first.day() + last.date()) / 7)
    const calendar = Object.assign([], { currentDate, first, last })

    var firstDay = first.day() - 1;
    if(first.day() === 0) {
        weeksCount += 1;
        firstDay = 6;
    } 

    for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
        const week = []
        calendar.push(week)
        calendar.year = currentDate.year()
        calendar.month = currentDate.month()

        for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
            var pushDate = currentDate.clone().set('date', day + 1 - firstDay)
            week.push(pushDate)
        }
    }

    return calendar
}

function createCalendarProps(original_date, replacements, availabilitys, events) {
    let currentDate = moment(original_date)

    const first = currentDate.clone().startOf('month')
    const last = currentDate.clone().endOf('month')
    var weeksCount = Math.ceil((first.day() + last.date()) / 7)
    const calendarProps = []

    var firstDay = first.day() - 1;
    if(first.day() === 0) {
        weeksCount += 1;
        firstDay = 6;
    } 

    for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
        const week = []
        calendarProps.push(week)

        for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
            const date = currentDate.clone().set('date', day + 1 - firstDay);
            
            let availabilityCss = false;
            let agreementSign = false;
            
            if(replacements && replacements.length > 0) {
                replacements.forEach((elem) => {
                    if (elem.type) {
                        let found = elem.days_available.find((elem) => {
                            return (moment(elem.start).isSame(date, 'day'));
                        })
                        if (found) {
                            if (elem.sign === true) {
                                agreementSign = true;
                            } else {
                                availabilityCss = true;
                            }
                        }
                    }
                })
            }

            if (events && events.length > 0) {
                events.forEach((elem) => {
                    if (date.isSameOrAfter(elem.start_date, 'day') && date.isSameOrBefore(elem.end_date, 'day')) {
                        if (!date.isSame(elem.end_date)) {
                            // Bloquant
                            if (elem.prevent_availabilities === true) {
                                agreementSign = true;
                            // Non bloquant
                            } else {
                                availabilityCss = true;
                            }
                        } 
                    }
                })
            }

            if (availabilitys) {
                availabilitys.forEach((elem) => {
                    // cas d'une availability
                    if (elem.type) {
                        let found = elem.days_available.find((elem) => {
                            return (moment(elem.start).isSame(date, 'day'));
                        })
                        if (found) {
                            if (elem.sign === true) {
                                agreementSign = true;
                            } else {
                                availabilityCss = true;
                            }
                        }
                    } 
                })
            }
            week.push({
                availabilityCss,
                agreementSign,
            })
        }
    }
    return calendarProps
}

class CalendarDate extends React.Component {
    render() {
        const { dateToRender, dateOfMonth, dayBetween, type, availabilityCss, agreementSign } = this.props
        const changeDate = () => this.props.changeDate(dateToRender)

        if (dateToRender.month() < dateOfMonth.month()) {
            return (
                <button disabled={true} className="date next-month">
                    {dateToRender.date()}
                </button>
            )
        }

        if (dateToRender.month() > dateOfMonth.month()) {
            return (
                <button disabled={true} className="date next-month">
                    {dateToRender.date()}
                </button>
            )
        }

        let colorDate = ' ';
        if (agreementSign === true && (dateToRender.day() !== 0 || type === 'guard')) {
            colorDate = " buttonGrey ";
        } else if (availabilityCss === true && (dateToRender.day() !== 0 || type === 'guard')) {
            colorDate = " cssNotifDispo ";
        }

        let colorButtonByType = ' cssNotifShow ';
        if (type === 'regular') {
            colorButtonByType = ' cssNotifShowRegular '
        } else if (type === 'guard') {
            colorButtonByType = ' cssNotifShowGuardSCalendar '
        }

        if (dayBetween === true) {
            return (
                <button className={ "date in-month " + colorButtonByType} onClick={changeDate}>
                    {dateToRender.date()}
                </button>
            )
        } else {
            return (
                <button className={ "date in-month-disabled " + colorDate } onClick={changeDate}>
                    {dateToRender.date()}
                </button>
            )
        }
    }
}
class Calendar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            calendarProps: createCalendarProps(moment(props.date), props.replacements, props.availabilitys, props.events),
            calendar: createCalendar(props.date)
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.date !== this.props.date) {
            let calendar = createCalendar(this.props.date)
            this.setState({
                calendar
            })
        }
        if(prevProps.type !== this.props.type || prevProps.date !== this.props.date) {
            this.setState({
                calendarProps: createCalendarProps(moment(this.props.date), this.props.replacements, this.props.availabilitys, this.props.events)
            })
        }
    }

    getDaysAvailable(date) {
        let value = false;
        this.props.days_available.forEach((elem) => {
            if (date.isSame(elem.start, 'day')) {
                value = true;
                return;
            }
        })
        return value;
    }

    render() {
        return(
            <div className={"col-md-4 "}>
                <div className="month-name">
                    {this.state.calendar.currentDate.format('MMMM').toUpperCase() + ' ' + this.state.calendar.currentDate.format('YYYY').toUpperCase()}
                </div>
                {['L', 'M', 'M', 'J', 'V', 'S', 'D'].map((day, index) => (
                    <span key={index} className="day">
                        {day}
                    </span>
                ))}

                {this.state.calendar.map((week, index) => (
                    <div key={index}>
                        {week.map((date, keyBis) => 
                            <span key={keyBis}>
                                <CalendarDate
                                    key={"CalendarDate " + keyBis}
                                    dateToRender={date}
                                    dateOfMonth={this.state.calendar.currentDate}
                                    dayBetween={this.getDaysAvailable(date)}
                                    type={this.props.type}
                                    changeDate={this.props.changeDate}
                                    availabilityCss={this.state.calendarProps[index][keyBis].availabilityCss}
                                    agreementSign={this.state.calendarProps[index][keyBis].agreementSign}
                                />
                            </span>
                        )}
                    </div>
                ))}
            </div>
        )
    }
}

class ModalChoiceDays extends React.Component {
    constructor(props) {
        super(props);

        let compteurMonth = moment(this.props.monthSelected).diff(moment(), 'month') <= 0 ? 0 : moment(this.props.monthSelected).diff(moment(), 'month');

        this.state = {
            compteur: compteurMonth <= 0 ? 0 : compteurMonth,
            totalMonth: 3 + compteurMonth,
            firstMonth: this.props.date.startOf('month').add(compteurMonth, 'months')
        }

        moment.locale('fr');
        this.onClickPrevious = this.onClickPrevious.bind(this);
		this.onClickNext = this.onClickNext.bind(this);
        this.backToDateOfTheDay = this.backToDateOfTheDay.bind(this);
    }

    onClickPrevious() {
        let compteur = this.state.compteur;
        let totalMonth = this.state.totalMonth;

        let oldStartDate = this.state.firstMonth;
        let newStartDate = moment(oldStartDate).subtract(1, 'months');

        if (compteur === 0 && !this.props.replacementOutOfHydrogen) {
            return;
        }

        compteur -= 1;
        totalMonth -= 1;

        this.setState({ 
            compteur: compteur,
            totalMonth,
            firstMonth: newStartDate
        })
    }

    onClickNext() {
        let compteur = this.state.compteur;
        let totalMonth = this.state.totalMonth;

        if (compteur === 9) {
            return;
        }

        let oldStartDate = this.state.firstMonth;
        let newStartDate = moment(oldStartDate).add(1, 'months');

        if(compteur === totalMonth - 3) {
            totalMonth += 1
        }
        compteur += 1;

        this.setState({ 
            compteur: compteur,
            totalMonth,
            firstMonth: newStartDate
        })
    }

    // Remet le curseur sur la date du jour
    backToDateOfTheDay() {
        this.props.backToDateOfTheDay();
		this.setState({
            compteur: 0,
            totalMonth: 3,
            firstMonth: this.props.date.startOf('month').toDate()
        })
    }

    render() {
        let arrayForMap = [0, 1, 2];
        
        return (
            <div className="row">
                <div className="col-md-12 buttonPreviousNext">
                    <button type="button" onClick={this.onClickPrevious}><span>‹</span></button>
                    <button onClick={this.backToDateOfTheDay}>Aujourd'hui</button>
                    <button type="button" onClick={this.onClickNext}><span>›</span></button>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        {arrayForMap.map(i => 
                            <Calendar 
                                key={i + 1}
                                date={dates.add(this.state.firstMonth, i, 'month')}
                                type={this.props.type}
                                changeDate={this.props.changeDate}
                                replacements={this.props.replacements}
                                events={this.props.events}
                                availabilitys={this.props.availabilitys}
                                days_available={this.props.days_available}
                            />
                        )}
                    </div>
                </div>
                <div className="col-md-12">
                    <button onClick={this.props.cancelSelectedDays}>Annuler</button>
                </div>
            </div>
        )
    }

}

export default ModalChoiceDays