import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import 'react-datetime/css/react-datetime.css';


export default class SubstituteDUsProfile extends Component {

    render() {
        const { characteristics } = this.props;

        if (!characteristics.du_dius || !characteristics.du_dius.length) return null;

        return (
            <React.Fragment>

                <h5 className="">
                    <b><FormattedMessage id="DU.DIUs" /></b>
                </h5>

                <div className="text-left max-w-80">
                    {characteristics.du_dius.map(du => {
                        return (
                            <div key={Math.random()}><i className="fa fa-check" />&nbsp;&nbsp;{du.topic}</div>
                        );
                    })}
                </div>

            </React.Fragment>
        );
    }
}


