var values = [
  { label: "Office.Disposal.Less.2.Years", value: "1" },
  { label: "Office.Disposal.3.To.5.Years", value: "2" },
  { label: "Office.Disposal.Don't.Know", value: "3" }
];

export default class DoctorOfficeDisposal {

  static values() {
    return values;
  }

  static getLabel(nbr) {
    for (let value of values) {
      if (value.value === nbr) return value.label;
    }
  }

};