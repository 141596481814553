import React from "react";
import { Input, Button, Row, Col } from "reactstrap";
import { upds, getsBy, clears, get, getBy, removes } from "../../actions/global/global";
import { connect } from "react-redux";

class DoctorCorrectWrongnIfo extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
			user: null,
			loading: false,
			displayInfo: false,
			// Pagination
		};
	}

	componentDidMount() {
		let id = this.props.match.params.userId;
		this.setState({ loading: true });
		this.props.onGetBy('user/correctionFields', "userId", id, () => this.setState({ loading: false }),);
	}

	getInfoUserUpdatedFromInput = () => {
		const input1 = document.getElementById("name");
		const input2 = document.getElementById("fname");
		const input3 = document.getElementById("email");
		const input4 = document.getElementById("mobile");

		let { name, first_name, email, phone_mobile } = this.props.user;

		if (input1 != null) {
			name = input1.value;
		}
		if (input2 != null) {
			first_name = input2.value;
		}
		if (input3 != null) {
			email = input3.value;
		}
		if (input4 != null) {
			phone_mobile = input4.value;
		}
		let role = this.props.user.role;
		return {
			name,
			first_name,
			email,
			phone_mobile,
			role,
		}
	}

	getInfoSubstituteUpdatedFromInput = () => {
		const input1 = document.getElementById("address");
		const input2 = document.getElementById("city");
		const input3 = document.getElementById("poste");
		const input4 = document.getElementById("rpps");
		let { address_street, address_city, address_postcode, RPPS } = this.props.user.substituteCharacteristic;
		if (input1 !== null) {

			address_street = input1.value;
		}

		if (input2 !== null) {
			address_city = input2.value;
		}

		if (input3 !== null) {
			address_postcode = input3.value;
		}

		if (input4 !== null) {

			RPPS = input4.value;
		}

		return {
			address_street,
			address_city,
			address_postcode,
			RPPS,
		}
	}

	getInfoDoctorUpdatedFromInput = () => {
		const input1 = document.getElementById("address");
		const input2 = document.getElementById("city");
		const input3 = document.getElementById("poste");
		const input4 = document.getElementById("rpps");
		let { office_address_street, office_address_city, office_address_postcode, RPPS } = this.props.user.doctorCharacteristic;

		if (input1 !== null) {
			office_address_street = input1.value;
		}

		if (input2 != null) {

			office_address_city = input2.value;
		}

		if (input3 != null) {
			office_address_postcode = input3.value;
		}
		if (input4 != null) {

			RPPS = input4.value;
		}

		return {
			office_address_street,
			office_address_city,
			office_address_postcode,
			RPPS,
		}
	}

	getInfoPsyInstalledUpdatedFromInput = () => {
		const input1 = document.getElementById("address");
		const input2 = document.getElementById("city");
		const input3 = document.getElementById("poste");
		const input4 = document.getElementById("rpps");
		let { office_address_street, office_address_city, office_address_postcode, RPPS } = this.props.user.psychologistICharacteristic;

		if (input1 !== null) {
			office_address_street = input1.value;
		}

		if (input2 != null) {

			office_address_city = input2.value;
		}

		if (input3 != null) {
			office_address_postcode = input3.value;
		}
		if (input4 != null) {

			RPPS = input4.value;
		}

		return {
			office_address_street,
			office_address_city,
			office_address_postcode,
			RPPS,
		}
	}

	saveInfoToCollectionUser = (id, CollectionUser, Collection) => {
		this.setState({ loading: true });

		this.props.onUpds("user/updateuser", { userId: id, CollectionUser, Collection }, () => this.setState({ loading: false, displayInfo: true }),);
	}

	saveInfoUser = () => {
		let id = this.props.match.params.userId;
		if (this.props.user && this.props.user.role === "substitute") {

			const CollectionUser = this.getInfoUserUpdatedFromInput();
			const CollectionSubstitute = this.getInfoSubstituteUpdatedFromInput();
			this.saveInfoToCollectionUser(id, CollectionUser, CollectionSubstitute);
		} else if (this.props.user && this.props.user.role === 'doctor') {
			const CollectionUser = this.getInfoUserUpdatedFromInput();
			const CollectionDoctor = this.getInfoDoctorUpdatedFromInput();
			this.saveInfoToCollectionUser(id, CollectionUser, CollectionDoctor);
		} else if (this.props.user && this.props.user.role === 'psychologist_install') {
			const CollectionUser = this.getInfoUserUpdatedFromInput();
			const CollectionPsyInstalled = this.getInfoPsyInstalledUpdatedFromInput();
			this.saveInfoToCollectionUser(id, CollectionUser, CollectionPsyInstalled);
		}
	}

	loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

	maxLengthCheck = (object) => {
		if (object.target.value.length > object.target.maxLength) {
			object.target.value = object.target.value.slice(0, object.target.maxLength)
		}
	}

	render() {
		const { user } = this.props;

		return (
			<React.Fragment>

				<div id="registration" className='example blue-bg'>

					<div className='step-progress'>

						<div className="topbar navbarbg text-center b-fix">
							<a href="/#" className="a-unstyled">
								<div className="hydrogen-logo-sm d-inline-block align-middle"></div>
								<div className="ml-3 bold d-inline-block align-middle fs-18 lp-3">HYDROGEN</div>
							</a>
						</div>
						<h4 className="text-white uppercase w-100 text-center lp-3 mt-4 mb-3">
							{/* Bonjour Dr {user && user.name} */}
						</h4>

						<div>
							<Row>

								{this.state.displayInfo ?
									(<Row /* className="step step4 mt-2 justify-content-md-center " */>
										<Col className="fontWhite label-col1">
											<div className="fontWhite font-weight-bold"><br /><br />
												<p><b>Félicitations !</b></p>

												<p className="pb-0 mb-0">Votre inscription est bien enregistrée.</p>

												<br />

												<p className="pb-0 mb-0">L'équipe HYDROGEN vérifie votre statut et vous adressera </p>
												<p className="pb-0 mb-0">un mail de confirmation dans les plus brefs délais.</p>

												<br />

												<p>A très vite!</p>
												<p>L'équipe HYDROGEN</p>

											</div>
											<div className="logo"></div>
										</Col>

									</Row>)
									:
									(<Col className="fontWhite label-col">

										{user &&

											<div className="alignCenter"><br />
												<div className="mg-T">
													<h5 > Les informations suivantes semblent
														incorrectes. <br />
														Afin de finaliser votre inscription, veuillez les
														corriger:</h5>
												</div>
												<br />
												<div>
													{(user.correctionFields.name === true) &&
														<div className="label">Nom : <Input className="input-correct" type="text"
															name="name" id="name" defaultValue={user.name} placeholder="Saisissez le nom" />
														</div>
													}
												</div>

												<div>
													{(user.correctionFields.first_name === true) &&
														<div className="label">Prénom : <Input className="input-correct" type="text"
															name="prenom" id="fname" defaultValue={user.first_name} placeholder="Saisissez le prénom" />
														</div>
													}
												</div>
												<div>
													{(user.correctionFields.address === true) &&
														<div className="label"> Adresse: <Input className="input-correct" type="text"
															name="address" id="address" defaultValue={(user.role === "substitute") ?
																user.substituteCharacteristic.address_street : user.doctorCharacteristic.address_street}
															placeholder="Saisissez le nom et le numéro de la rue" />
														</div>
													}
												</div>

												<div>
													{(user.correctionFields.city === true) &&
														<div className="label">Ville : <Input className="input-correct" type="text"
															name="ville" id="city" defaultValue={(user.role === "substitute") ?
																user.substituteCharacteristic.address_city : user.doctorCharacteristic.address_city}
															placeholder="Saisissez la Ville" />
														</div>
													}
												</div>
												<div>
													{(user.correctionFields.postcode === true) &&
														<div className="label"> Code Postal: <Input className="input-correct" type="text"
															name="postcode" id="poste" defaultValue={(user.role === "substitute") ?
																user.substituteCharacteristic.address_postcode : user.doctorCharacteristic.address_postcode}
															placeholder="Saisissez le code postal" />
														</div>
													}
												</div>
												<div>
													{(user.correctionFields.email === true) &&
														<div className="label">Email : <Input className="input-correct" type="text"
															name="email" id="email" defaultValue={user.email}
															placeholder="Saisissez l'email" />
														</div>
													}
												</div>

												<div>
													{(user.correctionFields.phone_mobile === true) &&
														<div className="label"> Téléphone Portable : <Input className="input-correct" type="number"
															name="telephone" id="mobile" maxLength="10" onInput={this.maxLengthCheck}
															defaultValue={user.phone_mobile}
															placeholder="Saisissez le numéro de téléphone" /></div>
													}
												</div>
												<div>
													{(user.correctionFields.RPPS === true) &&
														<div className="label">RPPS : <Input className="input-correct" type="number" maxLength="11"
															onInput={this.maxLengthCheck} name="RPPS" id="rpps" defaultValue={(user.role === "substitute") ?
																user.substituteCharacteristic.RPPS : user.doctorCharacteristic.RPPS}
															placeholder="Saisissez le numéro RPPS" />
														</div>
													}
												</div>
											</div>
										}
										<div className="marBtn">
											<Button
												type="submit"
												disabled={this.state.disabled}
												size="sm"
												className="btn text-white srounded w-1 bnt-blue pl-5 pr-5 pt-3 pb-3 "
												onClick={() => this.saveInfoUser()} >
												Valider
											</Button>
										</div>


									</Col>)
								}
							</Row>

						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.global.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		/* onUpds: (objName, obj, callback) => dispatch(upds(objName, obj, callback)), */
		onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),
		onGetBy: (objName, fields, values, callback) => dispatch(getBy(objName, fields, values, callback)),
		onGet: (objName, cbk) => dispatch(get(objName, cbk)),
		onUpds: (objName, obj, callback) => dispatch(upds(objName, obj, callback)),
		onRemoves: (objName, obj, callback) =>
			dispatch(removes(objName, obj, callback)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorCorrectWrongnIfo);
