
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';


class Home extends React.Component {

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;
    
	render() {
        const { user } = this.props;

        if(!user) return this.loading();

		return (
            <>
                {(user.role === "substitute") && <Redirect to='/home/shome'/>}
                {(user.role === "doctor") && <Redirect to='/home/dhome'/>}
                {(user.role === "psychologist_install") && <Redirect to='/home/pihome'/>}
				{(user.role === "radiologist_install") && <Redirect to='/home/rhome'/>}
				{(user.role === "pediatrician_install") && <Redirect to='/home/pehome'/>}
				{(user.role === "psychiatrist_install") && <Redirect to='/home/pshome'/>}
				{(user.role === "secretary") && <Redirect to='/home/sechome'/>}
			</>
		);
	}
}


const mapStateToProps = state => {
	return {
		user: state.global.user
	}
}

const mapDispatchToProps = dispatch => {
	return {
		//
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
