import DateUtil from "../util/DateUtil";
import AgreementEnt from "./AgreementEnt";
import OfficeTimeTableEnt from './OfficeTimeTableEnt';
import moment from 'moment';
import { split } from 'moment-range-split';

export default class ReplacementEnt {

    static getRegularReplEnd = (daysAsString, duration, start) => {
        let startDay = 6;
        daysAsString.forEach((day) => {
            if(startDay > DateUtil.dayToNbr(day)) startDay = DateUtil.dayToNbr(day)
        })

        if(!start) start = DateUtil.nextDay(startDay, new Date());

        var end = new Date(start);
        end.setDate(new Date(start).getDate() + 7 * (duration - 1));
        while (!DateUtil.isDay(end, startDay)) end.setDate(new Date(end).getDate() - 1);

        return end;
    }

    static isSeen = (replacement, substitute_id) => {
        return replacement.applicants.find((applicant) => {
            return (applicant.substitute_id === substitute_id && applicant.seen_by_substitute)
        })
    }

    static isDeclinedBySubstitute = (replacement, substitute_id) => {
        return replacement.applicants.find((applicant) => {
            return (applicant.substitute_id === substitute_id && applicant.substitute_status === 2)
        })
    }

    static isConcluded = (replacement, substitute) => {
        if (!replacement || !replacement.agreements) return false;

        for (let agreement of replacement.agreements) {
            if (AgreementEnt.isConcluded(agreement, substitute)) return true;
        }

        return false;
    };

    static isDoctorInterested = (replacement, substitute) => {
        return replacement.applicants.find((applicant) => {
            return (applicant.substitute_id === substitute.substitute_id && applicant.doctor_status === 1)
        })
    }

    static isSubstituteInterested = (replacement, substitute) => {
        return replacement.applicants.find((applicant) => {
            return (applicant.substitute_id === substitute.substitute_id && applicant.substitute_status === 1)
        })
    }

    // Permet de savoir si le remplaçant négocie
    static isNegociate = (replacement, substitute) => {
        let isNegociate = false;
        replacement.applicants.forEach((elem) => {
            // si y'a une négo
            if (elem.negociation && elem.negociation.length > 0) {
                // Récupère la dernière négo de la liste des négos
                var last_element = elem.negociation[elem.negociation.length - 1];
                if (last_element.negociator === 'substitute' && elem.substitute_id === substitute) {
                    isNegociate = true;
                }
            }
        })
        return isNegociate;
    }

    static waitForResponseNegociation = (replacement, substitute) => {
        let isNegociate = false;
        replacement.applicants.forEach((elem) => {
            // si y'a une négo
            if (elem.negociation && elem.negociation.length > 0) {
                // Récupère la dernière négo de la liste des négos
                var last_element = elem.negociation[elem.negociation.length - 1];
                if (last_element.negociator === 'doctor' && elem.substitute_id === substitute) {
                    isNegociate = true;
                }
            }
        })
        return isNegociate;
    }

    // Permet de savoir si on réfléchit
    static isThinking = (replacement, substitute) => {
        let isThinking = false;
        replacement.applicants.forEach((elem) => {
            if ((elem.doctor_status === 4 || elem.substitute_status === 4) && elem.substitute_id === substitute) {
                isThinking = {
                    date_think: elem.date_think,
                    doctor_status: elem.doctor_status,
                    substitute_status: elem.substitute_status
                }
            }
        })
        if (isThinking !== false) {
            // Ajouter 7 jours à la date et compter le nombre de jours qui nous sépare
            let sevenDaysLater = moment(isThinking.date_think).add(7, 'days');
            let diff = sevenDaysLater.diff(isThinking.date_think, 'days');
            isThinking.diff = diff
        }
        return isThinking;
    }

    static isPrivate = (replacement) => {
        return (!!replacement.availability_id);
    }

    static privateApplied = (replacement) => {
        let apply = false;
        if(replacement && replacement.applicants && replacement.applicants.length > 0) {
            apply = replacement.applicants.find((applicant) => {
                return applicant.substitute_id === replacement.availabilityOwner.substitute_id && applicant.substitute_status === 1
            })
        };
        return apply
    }

    static privateWatches = (replacement) => {
        return replacement && replacement.watchers && replacement.watchers.length > 0;
    }

    static occasionalPeriod(startDate = new Date(), wishedEndDate) {
        // Start date cannot be in the past
        var start = DateUtil.greatest(startDate, new Date());
        while (DateUtil.isDay(start, 0)) start.setDate(new Date(start).getDate() - 1);

        // We set endDate to the day after startDate
        var dayAfter = new Date(start);
        dayAfter.setDate(new Date(start).getDate() + 1);
        if (!wishedEndDate) wishedEndDate = new Date(dayAfter);

        var end = DateUtil.greatest(new Date(wishedEndDate), new Date(dayAfter));
        end = DateUtil.greatest(new Date(end), new Date(start));

        // If such day is a Sunday, remove 1 day 
        if (DateUtil.isDay(end, 0)) end.setDate(new Date(end).getDate() - 1);

        return { start, end };
    }

    static regularPeriod(day, startDate, wishedEndDate) {
        let startDay = 6;

        if(startDay > DateUtil.dayToNbr(day)) startDay = DateUtil.dayToNbr(day)
        // Get next day 
        var start = DateUtil.nextDay(startDay, startDate);

        // In theory, end date is 13 weeks
        var thirteenWeeksLater = new Date(start);

        thirteenWeeksLater.setDate(new Date(start).getDate() + 7 * 12);

        if (!wishedEndDate) wishedEndDate = new Date(thirteenWeeksLater);
        var end = DateUtil.lowest(new Date(wishedEndDate), new Date(thirteenWeeksLater));
        end = DateUtil.greatest(new Date(end), new Date(start));
        // Find correct day
        while (!DateUtil.isDay(end, startDay)) {
            end.setDate(new Date(end).getDate() - 1);
        }

        return { start, end };
    }

    static regularPeriodAddThreeMonth(day, startDate, wishedEndDate) {
        day = DateUtil.dayToNbr(day);

        // Get next day 
        var start = DateUtil.nextDay(day, startDate);
        var thirteenWeeksLater = moment(start).add(3, 'months');
        if (!wishedEndDate) wishedEndDate = new Date(thirteenWeeksLater);
        var end = DateUtil.lowest(new Date(wishedEndDate), new Date(thirteenWeeksLater));
        end = DateUtil.greatest(new Date(end), new Date(start));

        // Find correct day
        while (!DateUtil.isDay(end, day)) {
            end.setDate(new Date(end).getDate() - 1);
        }

        return { start, end };
    }

    static guardPeriod(startDate = new Date(), wishedEndDate) {
        // Start date cannot be in the past
        var start = DateUtil.greatest(startDate, new Date());
        start.setHours(18);
        start.setMinutes(0);

        // Guards end the next day
        var nextDay = new Date(start);
        nextDay.setDate(new Date(start).getDate() + 1);
        if (!wishedEndDate) wishedEndDate = new Date(nextDay);

        var end = DateUtil.lowest(new Date(wishedEndDate), new Date(nextDay));

        end.setHours(8);
        end.setMinutes(0);

        end = DateUtil.greatest(new Date(end), new Date(start));

        return { start, end };
    };

    static ongoingAgreement = (replacement, user) => {
        if (!replacement.agreements) return false;

        const agreement = replacement.agreements.find(a => a.substitute_id === user._id);

        return (!!agreement);
    };

    static concludedAgreement = (replacement, user) => {
        if (!replacement || !replacement.agreements) return false;
        const agreement = replacement.agreements.find(a => a.substitute_id === user._id);

        return (agreement && AgreementEnt.isConcluded(agreement));
    };

    static concludedAgreementCancelByDoctor = (replacement, user) => {
        if (!replacement || !replacement.agreements) return false;
        const agreement = replacement.agreements.find(a => a.substitute_id === user._id && a.cancelled_by_doctor === true);
        return agreement;
    }

    static concludedAgreementCancelBySub = (replacement, user) => {
        if (!replacement || !replacement.agreements) return false;
        const agreement = replacement.agreements.find(a => a.substitute_id === user._id && a.cancelled_by_substitute
            === true);
        return agreement;
    }

    // Quand les deux médecins ont accepté le remplacement mais pas encore signé le contrat
    static hasAccordAgreement = (replacement, user) => {
        const agreement = replacement.applicants.find(a => a.substitute_id === user._id && a.substitute_status === 1 && a.doctor_status === 1);
        return agreement;
    }

    // Si le médecin a refusé le remplaçant 
    static refuseByDoc = (replacement, user) => {
        const agreement = replacement.applicants.find(a => a.substitute_id === user._id && a.substitute_status === 1 && a.doctor_status === 2);
        
        return agreement;
    }

    // Si le médecin a refusé le remplaçant 
    static refuseBySub = (replacement, user) => {
        const agreement = replacement.applicants.find(a => a.substitute_id === user._id && a.substitute_status === 2 && a.doctor_status === 1);

        return agreement;
    }

    static isCancelled = (replacement, user) => {
        if (!replacement.agreements) return false;

        const agreement = replacement.agreements.find(a => a.substitute_id === user._id);

        return (agreement && AgreementEnt.isCancelled(agreement));
    };

    static subCancelled = (replacement, user) => {
        if (!replacement.agreements) return false;

        const agreement = replacement.agreements.find(a => a.substitute_id === user._id);

        return (agreement && AgreementEnt.subCancelled(agreement));
    };

    static docCancelled = (replacement, user) => {
        if (!replacement.agreements) return false;

        const agreement = replacement.agreements.find(a => a.substitute_id === user._id);

        return (agreement && AgreementEnt.docCancelled(agreement));
    };

    static docSigned = (replacement, user) => {
        if (!replacement.agreements) return false;

        const agreement = replacement.agreements.find(a => a.substitute_id === user._id);

        return (agreement && agreement.doctor_signature);
    };

    static hasApplied = (replacement, user) => {
        if (!replacement.applicants || !replacement.applicants.length) return false;
        let found = false;

        if (replacement.applicants) {
            replacement.applicants.forEach((applicant) => {
                if (applicant.substitute_id === user._id) {
                    found = true;
                    return;
                }
            })
        }
        if (found === true) {
            return true;
        } 

        const applicant = replacement.applicants.find(a => a._id === user._id);
        if (applicant) return true;

        return false;
    };

    static hasLiked = (replacement, user) => {
        return (replacement.watchers && replacement.watchers.includes(user._id));
    };

    static isRatable = (replacement) => {
        var nowMinus31Days = new Date();
        nowMinus31Days.setDate(nowMinus31Days.getDate() - 31);

        return new Date(replacement.end_date).getTime() < new Date(nowMinus31Days).getTime();
    }

    static hasAgreements = (replacement) => {
        return (replacement.agreements && replacement.agreements.length > 0);
    }

    static alreadyAccepted = (replacement) => {
        return replacement.applicants.find(a => a.substitute_status === 1 && a.doctor_status === 1);
    }

    static acceptedByAnother = (replacement, user_id) => {
        let acceptedByAnother = false;
        let subApplicant = replacement.applicants.find(a => a.substitute_status === 1 && a.doctor_status === 1);

        if (subApplicant && subApplicant.substitute_id && subApplicant.substitute_id !== user_id) acceptedByAnother = true;
        return acceptedByAnother;
    }

    // ======================================================================================
    // ==================================== Validation ======================================
    // ======================================================================================

    static validateOccasionalOrRegular = (form) => {
        var formData = form.getRawData();

        // Start date input
        if (formData.start_date) {
            form.setError("start_date");

            // Cannot be in the past
            if (DateUtil.inThePast(formData.start_date)) {
                form.setError("start_date", "Start.Past.Err");
            }

            // Cannot start on a Sunday
            if (DateUtil.isDay(formData.start_date, 0)) form.setError("start_date", "Sunday.Err");

            if (!Array.isArray(formData.day)) {
                formData.day = [formData.day];
            }
            if (formData.type === "regular" && formData.day && !DateUtil.sameDay(formData.start_date, formData.day)) {
                form.setError("start_date", "Date.Not.Regular.Repl.Day");
            };

        };

        // End date input
        if (formData.end_date) {
            form.setError("end_date");

            // Cannot be in the past
            if (DateUtil.inThePast(formData.end_date)) form.setError("end_date", "End.Past.Err");
            // Don't start on a Sunday
            if (DateUtil.isDay(formData.end_date, 0)) form.setError("end_date", "Sunday.Err");
            // Cannot be before start date
            if (formData.start_date && DateUtil.before(formData.end_date, formData.start_date)) form.setError("end_date", "EndDate.Before.StartDate.Err");
            // Max 12 weeks
            if (formData.start_date && DateUtil.days(formData.start_date, formData.end_date) > (7 * 12)) form.setError("end_date", "Max.12.Weeks.Err");
        };

        // If regular replacement, must match chosen day
        if (formData.type === "regular" && formData.expiration_date) {
            // Exp date not in the past
            if (DateUtil.inThePast(formData.expiration_date)) form.setError("expiration_date", "Exp.Past.Err");

            // Cannot be before start date
            if (formData.start_date && DateUtil.before(formData.expiration_date, formData.start_date)) form.setError("expiration_date", "ExpDate.Before.StartDate.Err");
        };

        // Loop through all guard forms
        const subForms = form.getSubForms();
        for (let subForm of subForms) {
            var subFormData = subForm.getRawData();
            // Guard start date input
            if (subFormData.start_date) {
                // If guard starts on Saturday, it cannot start before 12
                if (DateUtil.isDay(subFormData.start_date, 6)) {
                    if (DateUtil.hour(subFormData.start_date) < 12) subForm.setError("start_date", "Saturday.Guard.Start.12");
                }
                // If guard starts on Sunday, it cannot start before 8
                else if (DateUtil.isDay(subFormData.start_date, 0)) {
                    if (DateUtil.hour(subFormData.start_date) < 8) subForm.setError("start_date", "Sunday.Guard.Start.8");
                }
                // If guard is during the week, it cannot start before 18
                else {
                    if (DateUtil.hour(subFormData.start_date) < 18) subForm.setError("start_date", "Week.Guard.Start.18");
                };

                // Must be within replacement scope
                if (formData.start_date && DateUtil.before(subFormData.start_date, formData.start_date)) {
                    subForm.setError("start_date", "Date.Not.Within.Guard.Range");
                };
                if (formData.end_date && DateUtil.after(subFormData.start_date, DateUtil.toMidnight(formData.end_date))) {
                    subForm.setError("start_date", "Date.Not.Within.Guard.Range");
                };
                if (formData.type === "regular" && !DateUtil.sameDay(subFormData.start_date, formData.day)) {
                    subForm.setError("start_date", "Guard.Start.Reg.Repl.day");
                };
            }

            // Guard end date input
            if (subFormData.end_date) {
                // Cannot be after 8h
                if (subFormData.start_date &&
                    DateUtil.isNextDay(subFormData.start_date, subFormData.end_date) &&
                    DateUtil.hour(subFormData.end_date) > 8) {
                    subForm.setError("end_date", "Guard.MaxHour.8");
                };
                // Guard ends at max the next day
                if (subFormData.start_date && !DateUtil.isSameOrNextDay(subFormData.start_date, subFormData.end_date)) {
                    subForm.setError("end_date", "Guard.EndDate.Day.After.StartDate");
                };

                let startDateMoment = moment(subFormData.start_date);
                let endDateMoment = moment(subFormData.end_date)
    
                // Retourne le nombre d'heure qui sépare les deux dates
                let duration = moment.duration(endDateMoment.diff(startDateMoment)).asHours();
    
                // Guard must not last less than 4 hours
                if (duration < 4) {
                    subForm.setError("end_date", "No.Guard.Under.4h");
                };

                // Must be within replacement scope
                if (formData.end_date) {
                    const nextDayAt8 = new Date(formData.end_date);
                    nextDayAt8.setDate(nextDayAt8.getDate() + 1)
                    nextDayAt8.setHours(8);
                    nextDayAt8.setMinutes(0);
                    nextDayAt8.setMilliseconds(0);

                    if (DateUtil.after(subFormData.end_date, nextDayAt8)) {
                        subForm.setError("end_date", "Date.Not.Within.Guard.Range");
                    };
                    if (DateUtil.before(subFormData.end_date, formData.start_date)) {
                        subForm.setError("end_date", "Date.Not.Within.Guard.Range");
                    };
                }
            };
        };

        return form;
    };

    static validateGuard(form, availability) {
        const formData = form.getRawData();
        form.setError("start_date", null);
        form.setError("end_date", null);

        // Guard start date input
        if (formData.start_date) {
            // Cannot be in the past
            if (DateUtil.inThePast(formData.start_date)) form.setError("start_date", "Start.Past.Err");
        }

        // Guard end date input
        if (formData.end_date) {
            // Cannot be in the past
            if (DateUtil.inThePast(formData.end_date)) form.setError("end_date", "Start.Past.Err");

            // If the start date is earlier than substitute availability preference
            if (availability && new Date(formData.start_date) < new Date(availability.start_date)) {
                form.setError("start_date", "Repl.Before.Avail.Error")
            };

            // If the end date is after substitute availability's preference
            if (availability && new Date(formData.end_date) > new Date(new Date(availability.end_date).getTime() + 24 * 60 * 60 * 1000)) {
                form.setError("end_date", "Repl.After.Avail.Error")
            };
        };

        if (formData.start_date && formData.end_date) {
            // Guard must not last less than 4 hours
            let startDateMoment = moment(formData.start_date);
            let endDateMoment = moment(formData.end_date)

            // Retourne le nombre d'heure qui sépare les deux dates
            let duration = moment.duration(endDateMoment.diff(startDateMoment)).asHours();

            if (duration < 4) {
                form.setError("end_date", "No.Guard.Under.4h");
            }

            // Guard must not last less than 4 hours
            if (duration > 24) {
                form.setError("end_date", "No.Guard.Over.24h");
            }
        }

        return form;
    };

    // ===========================================================================================
    // ======================================= RANGES ============================================
    // ===========================================================================================

    static splitForWeek(replType, ranges, officeTimeTable) {
        let events = []
        for (const range of ranges) {
            let rangeDay = new Date(range.start).getDay();
            let officeDaySchedule = OfficeTimeTableEnt.getScheduleForDay(replType, officeTimeTable, rangeDay);

            if (!officeDaySchedule || !officeDaySchedule.length) continue;

            if (officeDaySchedule[0].start && officeDaySchedule[0].end) {
                let event = {};
                event.start = DateUtil.dateAndTimeToDate(DateUtil.toyyyyMMdd(range.start), officeDaySchedule[0].start);
                event.end = DateUtil.dateAndTimeToDate(DateUtil.toyyyyMMdd(range.end), officeDaySchedule[0].end);
                events.push(event);
            }

            if (officeDaySchedule[1] && officeDaySchedule[1].start && officeDaySchedule[1].end) {
                let event = {};
                event.start = DateUtil.dateAndTimeToDate(DateUtil.toyyyyMMdd(range.start), officeDaySchedule[1].start);
                event.end = DateUtil.dateAndTimeToDate(DateUtil.toyyyyMMdd(range.end), officeDaySchedule[1].end);
                events.push(event);
            }

            if (officeDaySchedule[1] && !officeDaySchedule[0].end && !officeDaySchedule[1].start) {
                if (!officeDaySchedule[0].start || !officeDaySchedule[1].end) continue;

                let event = {};
                if (!officeDaySchedule[0].start || !officeDaySchedule[1].end) continue
                event.start = DateUtil.dateAndTimeToDate(DateUtil.toyyyyMMdd(range.start), officeDaySchedule[0].start);
                event.end = DateUtil.dateAndTimeToDate(DateUtil.toyyyyMMdd(range.end), officeDaySchedule[1].end);
                events.push(event);
            }
        }

        return events;
    }

    static splitForMonth(replType, ranges, officeTimeTable) {
        var events = [];

        for (const range of ranges) {
            let rangeDay = new Date(range.start).getDay();
            let officeDaySchedule = OfficeTimeTableEnt.getScheduleForDay(replType, officeTimeTable, rangeDay);

            if (!officeDaySchedule || !officeDaySchedule.length) continue;

            var startTime = () => {
                let startTime;

                if (officeDaySchedule[0].start && !officeDaySchedule[1].start) startTime = officeDaySchedule[0].start;
                else if (officeDaySchedule[0].start && officeDaySchedule[1].start) startTime = officeDaySchedule[0].start;
                else if (officeDaySchedule[1].start && !officeDaySchedule[0].start) startTime = officeDaySchedule[1].start;
                else startTime = "00:00:01";

                return startTime;
            };

            var endTime = () => {
                let endTime;

                if (officeDaySchedule[0].start && officeDaySchedule[1].end) endTime = officeDaySchedule[1].end;
                else if (officeDaySchedule[0].start && officeDaySchedule[0].end) endTime = officeDaySchedule[0].end;
                else if (officeDaySchedule[1].start && officeDaySchedule[1].end) endTime = officeDaySchedule[1].end;
                else endTime = "00:00:01";

                return endTime;
            };

            let o = officeDaySchedule;

            if (!o[0].start && !o[0].end && !o[1].start && !o[1].end) continue; //! No hours at all
            if (o[0].start && !o[0].end && !o[1].start && !o[1].end) continue; //! Only morning start hour
            if (!o[0].start && o[0].end && !o[1].start && !o[1].end) continue; //! only morning end hour
            if (!o[0].start && !o[0].end && o[1].start && !o[1].end) continue; //! only afternoon start hour
            if (!o[0].start && !o[0].end && !o[1].start && o[1].end) continue; //! only afternoon end hour
            if (!o[0].start && o[0].end && o[1].start && !o[1].end) continue; //! only morning end hour and afternoon start hour

            let event = {};

            event.start = DateUtil.dateAndTimeToDate(DateUtil.toyyyyMMdd(range.start), startTime());
            event.end = DateUtil.dateAndTimeToDate(DateUtil.toyyyyMMdd(range.end), endTime());
            events.push(event);
        }

        return events;
    }

    //! GUARD (they don't use officeTimeTables)
    static split = (startDate, endDate, splitIntervals) => {
        var firstDateStartHour = moment(startDate).format("HH:mm:ss");
        var lastDayEndHour = moment(endDate).format("HH:mm:ss");

        var range = moment.range(startDate, endDate);
        var ranges = split(range, splitIntervals);

        // Fix ranges so that they start & end on the same day
        for (let range of ranges) range.end = range.end.subtract({ seconds: 1 });

        if (firstDateStartHour) ranges[0].start = moment(DateUtil.dateAndTimeToDate(ranges[0].start, firstDateStartHour));
        if (lastDayEndHour) ranges[ranges.length - 1].end = moment(DateUtil.dateAndTimeToDate(ranges[ranges.length - 1].end, lastDayEndHour));

        // Convert to regular dates
        var results = [];
        for (let range of ranges) results.push({ start: range.start.toDate(), end: range.end.toDate() });

        return results;
    }

    //* OCCASIONAL
    //? REGULAR
    static splitWithOfficeTimeTable = (replType, startDate, endDate, officeTimeTable, view) => {
        var ranges = ReplacementEnt.split(startDate, endDate, "daily");

        // 3eme params laissé pour avoir les horaires de cabinet
        // Mais si cabinet fermé alors que c'est le jour de début ou de fin du rempla, il n'apparaitra pas
        if (view === "week") return ReplacementEnt.splitForWeek(replType, ranges, officeTimeTable);
        // 3eme param = tableau vide pour ceux qui ont négocié un contrat commençant ou terminant
        // un jour de la semaine ou le cabinet du méd installé n'est pas ouvert
        else if (view === "month") return ReplacementEnt.splitForMonth(replType, ranges, []);
        else if (view === "year") return ReplacementEnt.splitForMonth(replType, ranges, []);
    }

}

