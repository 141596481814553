import React from 'react';
import { Link } from 'react-router-dom';


class VersionFooter extends React.Component {

    render() {
        return (
            <>

                <div className="version w-100 text-center mb-0">
                    version {process.env.REACT_APP_VERSION}
                    &nbsp;-&nbsp;
					<Link className="text-dark" to="/terms" target="_blank">Conditions d'utilisation</Link>
                    &nbsp;-&nbsp;
                    <Link className="text-dark" to="/aboutus" target="_blank">Qui sommes nous ?</Link>
                    &nbsp;-&nbsp;
                    <a className="text-dark" href="mailto:support@hydrogen-rempla.com">Contactez nous</a>
                </div>

            </>
        );
    }
}

export default VersionFooter;
