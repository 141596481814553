import React from "react";
import { Button } from "reactstrap";
import { acceptPatientCGU, getClinicalStudyByPatientId } from "../../actions/clinicalStudy/clinicalStudy";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import moment from 'moment';

class AcceptCGU extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      loading: false,
      displayInfo: false,
      checked: false,
      disabled: true,
    };
  }

  componentDidMount() {
    let patient_id = this.props.match.params.patient_id;
    this.props.getClinicalStudyByPatientId(patient_id);
  }

  loading = () =>
    <div className="w-100 text-center mt-2 mb-2">
      <div className="spinner-border text-white mx-auto" role="status">
      </div>
    </div>;

  handleChange = () => {
    this.setState({ checked: !this.state.checked, disabled: !this.state.disabled });
  }

  accept = () => {
    let patient_id = this.props.match.params.patient_id;
    this.props.acceptPatientCGU(patient_id);
  }

  render() {
    const { clinicalStudy, acceptedPatientCGU, isGettingClinicalStudy } = this.props;
    const patient = clinicalStudy && clinicalStudy.patient[0];

    return (
      <React.Fragment>

        <div id="registration" className='example blue-bg'>

          <div className='step-progress'>

            <div className="topbar navbarbg text-center b-fix">
              <div className="a-unstyled">
                <div className="hydrogen-logo-sm d-inline-block align-middle"></div>
                <div className="ml-3 bold d-inline-block align-middle fs-18 lp-3">HYDROGEN</div>
              </div>
            </div>

            {isGettingClinicalStudy ? this.loading() :
              clinicalStudy &&
              <React.Fragment>
                {(acceptedPatientCGU || clinicalStudy.patient[0].accepted_cgu) &&
                  <React.Fragment>

                    <div className="mt-2 row mx-auto">
                      <div className="fontWhite">
                        <div className="fontWhite font-weight-bold"><br /><br />
                          <p><b>Félicitations !</b></p>

                          <p className="pb-0 mb-0">Votre choix est bien enregistré.</p>

                          <br />

                          <p className="pb-0 mb-0">L'équipe HYDROGEN vérifira vos informations et vous adressera </p>
                          <p className="pb-0 mb-0">dans les plus brefs délais.</p>

                          <br />

                          <p>À très vite!</p>
                          <p>L'équipe HYDROGEN</p>

                        </div>
                        <div className="hydrogen-logo ml-mr-auto mb-5 mt-5"></div>
                      </div>
                    </div>
                  </React.Fragment>
                }
                {(!acceptedPatientCGU && !clinicalStudy.patient[0].accepted_cgu) &&
                  <React.Fragment>
                    <h4 className="text-white uppercase w-100 text-center lp-3 mt-4 mb-3">
                      Bonjour {clinicalStudy && patient.name}
                    </h4>
                    <div className="col fontWhite py-3">
                      <div className="">

                        <div className="row mt-2 justify-content-around">
                          <div className="col-md-2 col-sm-6">Nom : {patient.name}</div>
                        </div>

                        <div className="row mt-2 justify-content-around">
                          <div className="col-md-2 col-sm-6">Prenom : {patient.first_name}</div>
                        </div>

                        <div className="row mt-2 justify-content-around">
                          <div className="col-md-2 col-sm-6">Date de naissance : {moment(patient.birth_date).format("YYYY-MM-DD")}</div>
                        </div>

                        <div className="row mt-2 justify-content-around">
                          <div className="col-md-2 col-sm-6">Adresse : {patient.address}</div>
                        </div>

                        {patient.nir && <div className="row mt-2 justify-content-around">
                          <div className="col-md-2 col-sm-6">Numéro de Sécurité Sociale : {patient.nir}</div>
                        </div>}

                        {patient.email && <div className="row mt-2 justify-content-around">
                          <div className="col-md-2 col-sm-6">E-mail : {patient.email}</div>
                        </div>}

                      </div>

                      <div className="row mt-5 justify-content-center">
                        <input
                          className="checkbox_patient"
                          type="checkbox"
                          id={"name"}
                          checked={this.state.checked}
                          onChange={(e) => this.handleChange(e)}
                        />
                        <div className="bold col-8">
                          Autoriser Hydrogen à vous recontacter
                        </div>
                      </div>

                      <div className="row mt-5 justify-content-center">
                        <Button
                          type="submit"
                          onClick={this.accept}
                          disabled={this.state.disabled}
                          className="btn bg-green w-50 srounded pt-3 pb-3"
                          size="lg">
                          Valider
                        </Button>
                      </div>

                      <div className="mt-5"><i>Cliquez <Link to="/termspatients" target="_blank">ici</Link> pour consulter les CGV / CGU</i></div>

                    </div>
                  </React.Fragment>
                }
              </React.Fragment>
            }
          </div>
        </div>
      </React.Fragment>

    );
  }
}


const mapStateToProps = state => {
  return {
    clinicalStudy: state.clinicalStudy.clinicalStudy,
    acceptedPatientCGU: state.clinicalStudy.acceptedPatientCGU,
    isGettingClinicalStudy: state.clinicalStudy.isGettingClinicalStudy
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClinicalStudyByPatientId: (patient_id, cbk) => dispatch(getClinicalStudyByPatientId(patient_id, cbk)),
    acceptPatientCGU: (patient_id, cbk) => dispatch(acceptPatientCGU(patient_id, cbk))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptCGU);
