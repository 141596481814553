var values = [
    { label: "Offre équilibre", value: 0 },
    { label: "Offre confort", value: 1 },
    { label: "Offre H+", value: 2 },
    { label: "Installation", value: 3 },
    { label: "1 médecin", value: 4 },
    { label: "2 médecins", value: 5 },
    { label: "3 médecins", value: 6 },
    { label: "4 médecins et +", value: 7 },
    { label: "1 médecin", value: 8 },
    { label: "2 médecins", value: 9 },
    { label: "3 médecins", value: 10 },
    { label: "4 médecins et +", value: 11 }
];

export default class Subscription {

    static values() {
        return values;
    }

    static getLabel(nbr) {
        for (let value of values) {
            if (value.value === nbr) return value.label;
        }
    }

};