export default class GoogleMapUtil {
    static loadScript(url, callback) {
        if (window.google && window.google.maps) {
            callback();
            return;
        }
        let script = document.createElement("script");
        script.type = "text/javascript";
    
        if (script.readyState) {
            script.onreadystatechange = function() {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            script.onload = () => callback();
        }
    
        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    }
}