import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Collapse, Col, Row } from 'reactstrap';
import Stars from '../../../components/misc/Stars';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { get, upd, getBy } from "../../../actions/global/global";
import ProfileImages from "../../../enum/ProfileImages";
import RatingEnt from '../../../entities/RatingEnt';
import StringUtil from '../../../util/StringUtil';
import DoctorReplacementProfile from './DoctorReplacementProfile';
import UserEnt from '../../../entities/UserEnt';
import AgreementEnt from '../../../entities/AgreementEnt';
import ConfModal from "../../../components/modal/ConfModal";
import STransmissionModal from '../transmissions/STransmissionModal';
import Util from '../../../util/Util';
import DateUtil from '../../../util/DateUtil';
import GoogleMapUtil from "../../../util/GoogleMapUtil"
import 'ladda/dist/ladda-themeless.min.css';
import DoctorCharacteristicsProfile from "./DoctorCharacteristicsProfile";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import DoctorTransmission from './DoctorTransmission';
import Noty from 'noty';
import RetrocessionCertificateHome from '../../retrocessionCertificate/RetrocessionCertificateHome';
import moment from 'moment';
import { downloadCertificate } from "../../../actions/retrocessionCertificate/retrocessionCertificate";

export class DoctorProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            collapseGuards: false,
            disabled: false,
            driving: null,
            transit: null,
            cycling: null,
            showMap: false,
            mapshown: false,
            modal: null
        };

        const { doctor, doctorCharacteristic } = this.props;

        this.GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
        this.sendGMAPSRequests = this.sendGMAPSRequests.bind(this);

        GoogleMapUtil.loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${this.GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
            () => {
                this.buildMap(doctor, doctorCharacteristic)
                // this.forceUpdate()
            }
        );

        this.sendToCouncil = this.sendToCouncil.bind(this);
        this.downloadContract = this.downloadContract.bind(this);
        this.openRetrocessionCertificate = this.openRetrocessionCertificate.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.downloadCertificate = this.downloadCertificate.bind(this);
    }

    componentDidMount() {
        if (this.props.user.role === "substitute") this.props.onGet("substituteCharacteristic");
        if(this.props.replacementId){
            this.props.onGetBy("retrocessionCertificate", ["replacement_id"], [this.props.replacementId])
        }
    }

    buildMap(doctor, doctorCharacteristic) {
        this.mapComponent = withGoogleMap((props) =>
            <GoogleMap
                defaultZoom={11}
                defaultCenter={{ lat: doctorCharacteristic.location.coordinates[1], lng: doctorCharacteristic.location.coordinates[0] }}
                defaultOptions={{
                    mapTypeControl: false,
                    fullscreenControl: false,
                    gestureHandling: 'none',
                    zoomControl: false,
                    draggable: false,
                    streetViewControl: false
                }}
            >
                <Marker
                    key={Math.random()}
                    icon={{
                        url: ProfileImages.getSrc(doctor.image), // url
                        scaledSize: new window.google.maps.Size(50, 50), // scaled size
                        origin: new window.google.maps.Point(0, 0), // origin
                        anchor: new window.google.maps.Point(0.5, 0.5) // anchor
                    }}
                    position={{
                        lat: parseFloat(doctorCharacteristic.location.coordinates[1]),
                        lng: parseFloat(doctorCharacteristic.location.coordinates[0])
                    }} />
            </GoogleMap>
        );
    }

    pointingArrow = () => {
        if (this.state.collapsed === "details") return "-";
        return "+";
    }

    buildRoundElem = (characteristics, field, i18n) => {
        if (!i18n) i18n = StringUtil.capitalize(field);

        return (
            <div className="round-elem">
                <div className={"round" + ((characteristics[field]) ? "-success" : "-failure")}>
                    {(characteristics[field]) ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}
                </div>
                <div className="title"><FormattedMessage id={i18n} /></div>
            </div>
        );
    }

    sendGMAPSRequests() {

        const DistanceMatrixService = new window.google.maps.DistanceMatrixService();

        const geocoder = new window.google.maps.Geocoder();

        const changestate = (name, value) => {
            this.setState({ [name]: value })
        }
        //"72 rue de Barbatre, REIMS, 51100
        //
        const address1 = this.props.doctorCharacteristic.office_address_street + "," + this.props.doctorCharacteristic.office_address_city + "," + this.props.doctorCharacteristic.office_address_postcode
        var address1lng;
        var address1lat;
        const address2lng = this.props.substituteCharacteristic ? this.props.substituteCharacteristic.location.coordinates[0] : null
        const address2lat = this.props.substituteCharacteristic ? this.props.substituteCharacteristic.location.coordinates[1] : null

        const that = this;

        geocoder.geocode({ 'address': address1 }, function (results, status) {

            if (status === 'OK') {
                address1lat = results[0].geometry.location.lat()
                address1lng = results[0].geometry.location.lng()

                if (that.state.mapshown === false && that.props.user.role === "substitute") {
                   
                    DistanceMatrixService.getDistanceMatrix({
                         destinations: [{ lat: address1lat, lng: address1lng }],
                         origins: [{ lng: address2lng, lat: address2lat }],
                        travelMode: "DRIVING",
                    }, (result) => {
                        if (result.rows[0].elements[0]) {
                            changestate("driving", result.rows[0].elements[0])
                        }
                    });

                    DistanceMatrixService.getDistanceMatrix({
                         destinations: [{ lat: address1lat, lng: address1lng }],
                         origins: [{ lng: address2lng, lat: address2lat }],
                        travelMode: "BICYCLING",
                    }, (result) => {
                        if (result.rows[0].elements[0]) {
                            changestate("cycling", result.rows[0].elements[0])
                        }
                    });

                    DistanceMatrixService.getDistanceMatrix({
                        destinations: [{ lat: address1lat, lng: address1lng }],
                        origins: [{ lng: address2lng, lat: address2lat }],
                        travelMode: "TRANSIT",
                    }, (result) => {
                        if (result.rows[0].elements[0]) {
                            changestate("transit", result.rows[0].elements[0])
                        }
                    });
                }
                that.setState({ mapshown: true })
            }
        });
        this.setState({ showMap: !this.state.showMap })
    }

    downloadContract(e, agreementId) {
        e.preventDefault();
        e.stopPropagation();

        if (!this.state.disabled) {
            this.setState({ disabled: true });

            this.props.downloadContract(agreementId, () => this.setState({ disabled: false }));
        }
    }

    sendToCouncil(e, agreementId, doctor) {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.disabled) return;

        this.setState({ disabled: true });

        const onConfirm = () => {
            this.props.sendToCouncil(agreementId, () => this.setState({ disabled: false, modal: null }));
            this.closeModal();
        };

        const onCancel = () => {
            this.setState({ disabled: false });
            this.closeModal();
        }

        const content = <FormattedHTMLMessage id="Confirm.Send.To.Council" values={{ name: doctor.first_name }} />

        this.setState({
            modal: <ConfModal
                content={content}
                onConfirm={onConfirm}
                onCancel={() => onCancel()}
                toggle={() => onCancel()} />
        });
    }

    cancelAgreement(e, agreementId) {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.disabled) return;

        this.setState({ disabled: true });

        const onConfirm = () => {
            this.props.cancelAgreement(agreementId, () => {
                this.setState({ disabled: false });
                if (this.props.onCancelCbk) this.props.onCancelCbk();
            });
        };

        const onCancel = () => {
            this.setState({ disabled: false });
            this.closeModal();
        };

        const content = <FormattedHTMLMessage id="Confirm.Cancel.Agreement" />

        this.setState({
            modal: <ConfModal
                content={content}
                onConfirm={onConfirm}
                onCancel={() => onCancel()}
                toggle={() => onCancel()} />
        });
    }

    closeModal() {
        this.setState({ modal: null });
    }

    openTransmissionFormModal(doctorTransmission, substituteTransmission, doctorCharacteristic) {
        this.setState({
            modal:
                <STransmissionModal
                    title={"Transmission.Modifications.Proposal"}
                    content={"Sub.Transmissions.Expl"}
                    doctorTransmission={{ ...doctorTransmission, ...substituteTransmission }}
                    doctorCharacteristic={doctorCharacteristic}
                    toggle={() => this.closeModal()}
                    submitCbk={(substituteTransmission, cbk) => {
                        substituteTransmission.doctor_id = doctorCharacteristic.doctor_id;

                        this.props.onUpd("substituteTransmission", { substituteTransmission }, () => {
                            if (cbk) cbk();

                            new Noty({
                                type: "info",
                                layout: 'topRight',
                                theme: 'bootstrap-v4',
                                text: this.props.intl.formatMessage({ id: "Transmission.Modified.Success" }),
                                timeout: 6000,
                            }).show();

                            this.closeModal();
                        })
                    }} />
        });
    }

    componentWillMount() {
        const { doctor, agreement } = this.props;

        // If there's an agreement, go fetch the substituteTransmission
        if (agreement && agreement.doctor_signature) this.props.onGetBy("substituteTransmission", ["doctorId"], [doctor._id]);
    }

    openRetrocessionCertificate(event) {
        event.preventDefault();
        event.stopPropagation();
        
        let doctor = Object.assign({}, this.props.doctor, this.props.doctorCharacteristic);
        let replacement = Object.assign({}, this.props.agreementReplacement);

        this.setState({
            modal: <RetrocessionCertificateHome
                    toggle={this.closeModal}
                    doctor={doctor}
                    agreement={this.props.agreement}
                    replacement={replacement}
                    fromDoctor={false}
                />
        })
    }

    downloadCertificate(certificateId, token) {
        this.props.onDownloadCertificate(certificateId, token);
    }

    render() {
        const { user, doctor, doctorCharacteristic, officeTimeTable, replacementId, agreement, agreementReplacement, replacement, substituteTransmission } = this.props;
        const { rating } = doctor;

        // <Transmissions>

        let doctorTransmission;

        // If the replacement is contracted & ongoing 
        if (agreement &&
            agreement.doctor_signature &&
            new Date(DateUtil.nowPlusDays(15)) > new Date(agreementReplacement.start_date) &&
            new Date(agreementReplacement.end_date) > new Date()) {
            doctorTransmission = agreement.doctorTransmission || {};
        }

        // </Transmissions>

        var showChar = Util.null(this.props.showChar) ? true : this.props.showChar;
        var hasConcludedAgreement = AgreementEnt.isConcluded(agreement);
        let viewPhone = false;
        if (replacement && replacement.availability_id) {
            viewPhone = true;
        } else {
            if (agreementReplacement && agreement) {
                // Si les deux médecins ont acceptés le remplacement mais pas forcément encore signé, ni l'un ou l'autre
                agreementReplacement.applicants.forEach((elem) => {
                    if (elem.substitute_id === agreement.substitute_id && elem.substitute_status === 1 && elem.doctor_status === 1) {
                        viewPhone = true;
                    }
                })
            }
        }

        let viewRetrocessionCertificate = false;
        if (agreementReplacement && this.props.agreement) {
            let startDate = agreementReplacement.start_date
            if (agreementReplacement.applicants) {
                agreementReplacement.applicants.forEach((applicant) => {
                    if (applicant.substitute_id === this.props.agreement.substitute_id) {
                        if (applicant.negociation && applicant.negociation.length > 0) {
                            let lastElement = applicant.negociation[applicant.negociation.length - 1];
                            startDate = lastElement.start_date;
                        }
                    }
                })
            }
            if (moment(startDate).isSameOrBefore(moment(), 'day')) {
                viewRetrocessionCertificate = true;
            }
        }

        let age = DateUtil.age(doctor.birth_date);

        let listOfRetrocessionCertificate = [];
        if (this.props.retrocessionCertificate && this.props.retrocessionCertificate.length > 0) {
            this.props.retrocessionCertificate.forEach((certificate) => {
                if (certificate.doctor_signature && certificate.substitute_signature && !certificate.cancelled_by_doctor && !certificate.cancelled_by_substitute) {
                    listOfRetrocessionCertificate.push({
                        id: certificate._id,
                        name: certificate.name,
                        token: certificate.token,
                        date: 'Certificat du ' + moment(certificate.createdAt).format('DD/MM/YYYY')
                    })
                }
            })
        }
        return (
            <React.Fragment>

                <div className="w-100 text-center mb-4">
                    <img src={ProfileImages.getSrc(doctor.image)} className="d-block mx-auto mb-3"
                        width="100" alt="" style={{ borderRadius: "100px" }} />

                    <div className="">
                        {(doctor.role) !== 'temporary' ?
                            <div className="d-inline-block text-center loc-icon-container-custom" onClick={this.sendGMAPSRequests}>
                                <div className="loc-icon-custom"></div>
                            </div>
                            :
                            <div className="d-inline-block  text-center loc-icon-container">
                                <div className="loc-icon-custom"></div>
                            </div>
                        }
                        <div className="d-inline-block align-top">
                            <h4 className="mb-0">{(hasConcludedAgreement) ? UserEnt.fullName(doctor) : UserEnt.pseudo(doctor)}</h4>
                            {(doctorCharacteristic && doctorCharacteristic.address_components && doctorCharacteristic.address_components.city) && <div>{doctorCharacteristic.address_components.city}</div>}
                            {age > 18 &&
                                <div>{DateUtil.age(doctor.birth_date)} <FormattedMessage id="Years.Old" /></div>
                            }
                        </div>
                        <div className="d-inline-block ml-3" style={{ width: "40px" }}>
                        </div>
                    </div>

                    <div className="mt-2" style={{ fontSize: "22px" }}><Stars nbr={RatingEnt.averageAllStars(rating)} /></div>

                    {(viewPhone === true) &&
                        <a href={"tel:" + Util.formatPhoneNumber(doctor.phone_mobile)}>
                            <div className="w-100 text-center mt-3 ">
                                <div className="phone-icon text-blue">
                                    <i className="fa fa-phone"></i>
                                </div>

                                <div className="text-blue text-center mt-2">
                                    <div className="bold lp-1"><FormattedHTMLMessage id="You.Can.Now.Contact" />&nbsp;{doctor.first_name}&nbsp;!</div>
                                    <div className="mt-1 font-size-18" >{Util.formatPhoneNumber(doctor.phone_mobile)}</div>


                                    {(agreement && !agreement.sent_to_council) &&
                                        <div className="mt-3 mb-3"><FormattedHTMLMessage id="Accessible.Only.After.Sent" /></div>
                                    }
                                </div>
                            </div>
                        </a>
                    }

                    <Collapse isOpen={this.state.showMap}>
                        <div className="profile-map">
                            {user.role === "substitute" &&
                                <div>
                                    { this.state.driving && this.state.driving.distance && <> <i className="fas fa-car"></i> ({this.state.driving.distance.text},{this.state.driving.duration.text}) </>}
                                    { this.state.transit && this.state.transit.distance && <> <i className="fas fa-bus"></i> ({this.state.transit.distance.text},{this.state.transit.duration.text}) </>}
                                    { this.state.cycling && this.state.cycling.distance && <> <i className="fas fa-bicycle"></i> ({this.state.cycling.distance.text},{this.state.cycling.duration.text}) </>}
                                </div>
                            }
                            <>
                                {(this.mapComponent) &&
                                    <this.mapComponent
                                        style={{ marginTop: "-10px" }}
                                        isMarkerShown={true}
                                        googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + this.GOOGLE_MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places"}
                                        loadingElement={<div style={{ height: `100%`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                                        containerElement={<div style={{ height: `400px`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                                        mapElement={<div style={{ height: `100%`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                                    />
                                }
                            </>
                        </div>
                    </Collapse>

                    {(agreementReplacement || replacementId) &&
                        <DoctorReplacementProfile
                            doctor={doctor}
                            agreement={agreement}
                            agreementReplacement={agreementReplacement}
                            replacementId={replacementId}
                            onApply={this.props.onApply}
                            acceptRemplacement={this.props.acceptRemplacement}
                            acceptRempl={this.props.acceptRempl}
                            refuseRempl={this.props.refuseRempl}
                            sendToCouncil={this.sendToCouncil}
                            cancelAgreement={(e) => { this.cancelAgreement(e, agreement._id) }}
                            downloadContract={this.downloadContract}
                            openNegociationModal={this.props.openNegociationModal}
                            thinkChoice={this.props.thinkChoice}
                            viewByCalendar={this.props.viewByCalendar}
                        />
                    }

                    {viewRetrocessionCertificate === true &&
                        <div className="btn bg-green srounded lowercase pl-3 pr-3 text-white lp-2 d-inline-block m-2"
                            onClick={this.openRetrocessionCertificate} >
                            <FormattedHTMLMessage id="Create.Retrocession.Certificate" />
                        </div>
                    }
                    
                    {listOfRetrocessionCertificate && listOfRetrocessionCertificate.length > 0 &&
                        <div className='mt-4'>
                            <h4>Liste des certificats de rétrocession</h4>
                            {listOfRetrocessionCertificate.map((certificate, key) =>
                                <div key={key} className='mt-1' onClick={() => this.downloadCertificate(certificate.id, certificate.token)}>
                                    {certificate.date} <div className="btn bg-green srounded lowercase pl-3 pr-3 text-white lp-2 d-inline-block m-2 "><i className="fa fa-download clickable fa-lg" aria-hidden="true"></i>
                                        </div>
                                </div>
                            )}
                        </div>
                    }
                </div>

                {(showChar && doctor.role !== "temporary") &&
                    <>
                        <Collapse isOpen={(this.state.collapsed === "details")} className="modal-collapse pl-3 pr-3 pl-md-5 pr-md-5 w-100" >

                            <DoctorCharacteristicsProfile
                                doctor={doctor}
                                doctorCharacteristic={doctorCharacteristic}
                                officeTimeTable={officeTimeTable}
                            />

                        </Collapse>

                        <Collapse isOpen={(this.state.collapsed === "transmissions")} className="modal-collapse pl-3 pr-3 pl-md-5 pr-md-5 w-100" >

                            {(doctorTransmission) &&
                                <DoctorTransmission
                                    openTransmissionFormModal={() => this.openTransmissionFormModal(doctorTransmission, substituteTransmission, doctorCharacteristic)}
                                    substituteTransmission={substituteTransmission}
                                    doctorCharacteristic={doctorCharacteristic}
                                    doctorTransmission={doctorTransmission} />
                            }

                        </Collapse>


                        {(doctorTransmission) ?
                            <div className="modal-toggler-light">
                                <Row className="m-0">
                                    <Col xs="6" className="modal-toggler-hoverable-left" style={{ borderRight: "1px solid grey" }} onClick={() => this.collapse("details")}>
                                        <div style={{ fontSize: 14, lineHeight: "29px" }} >{this.pointingArrow()} <FormattedMessage id="Of.Details" /></div>
                                    </Col>

                                    <Col xs="6" className="modal-toggler-hoverable-right" onClick={() => this.collapse("transmissions")}>
                                        <div style={{ fontSize: 14, lineHeight: "29px", textTransform: "lowercase" }} ><FormattedMessage id="Transmissions" /></div>
                                    </Col>
                                </Row>
                            </div>
                            :
                            <div className="modal-toggler" onClick={() => this.collapse("details")}>
                                <div style={{ fontSize: 14, lineHeight: "29px" }} >{this.pointingArrow()} <FormattedMessage id="Of.Details" /></div>
                            </div>
                        }
                    </>
                }

                {this.state.modal}

            </React.Fragment >
        );
    }

    collapse(category) {
        if (this.state.collapsed === category) this.setState({ collapsed: null });
        else this.setState({ collapsed: category });
    }

}


const mapStateToProps = state => {
    return {
        user: state.global.user,
        substituteTransmission: state.global.substituteTransmission,
        substituteCharacteristic: state.global.substituteCharacteristic,
        availabilitysUser: state.global.availabilitysUser,
        availabilitys: state.global.availabilitys,
        retrocessionCertificate: state.global.retrocessionCertificate
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGet: (objName) => dispatch(get(objName)),
        onUpd: (objName, obj, cbk) => dispatch(upd(objName, obj, cbk)),
        onGetBy: (objName, paramNames, params, cbk) => dispatch(getBy(objName, paramNames, params, cbk)),
        onDownloadCertificate: (certificateId, cbk) => downloadCertificate(certificateId, cbk),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DoctorProfile));
