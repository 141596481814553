import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import 'react-datetime/css/react-datetime.css';
import { Card, Col, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import moment from 'moment';
import { getDoctorAgreementsBySecretaryWithDate } from '../../../actions/agreement/agreement';
import { editAppointment, removeAppointmentModal, getAppointmentsByUser } from "../../../actions/appointment/appointment";
import { allBySecretary } from '../../../actions/replacements/replacements';
import { getsExceptionalOpen } from "../../../actions/exceptionalOpen/exceptionalOpen";
import { getsExceptionalClose } from "../../../actions/exceptionalClose/exceptionalClose";
import SecretaryModalNewAppointment from './SecretaryModalNewAppointment';
import SecretaryModalEditAppointment from './SecretaryModalEditAppointment';
import SecretaryModalTakeAppointment from './SecretaryModalTakeAppointment.js';
import SecModalExceptionalOpen from './SecModalExceptionalOpen';
import SecModalExceptionalClose from './SecModalExceptionalClose';
import AppointmentUtil from '../../../util/AppointmentUtil';
import Util from '../../../util/Util';
import ProfileImages from '../../../enum/ProfileImages';
import WorkWeek from '../../calendar/WorkWeek';
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import DModalWarningExceptionalClose from '../../doctors/appointments/DModalWarningExceptionalClose';

import invitationImg from '../../../assets/images/homes/HYDROGEN_ILLU_PLANNING.png';


const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar)

class SecretaryCalendarWeeks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentDate: new Date(),
            startDate: moment().startOf('week'),
            endDate: moment().endOf('week').subtract(1, 'day'),
            arrayOfficeHours: [],
            min: new Date(0, 0, 0, 7, 0, 0),
            max: new Date(0, 0, 0, 20, 0, 0),
            collapseHours: true,
            modalNewAppointment: false,
            modalEditAppointment: false,
            dateNewAppointmentSelected: '',
            modalTakeAppointment: false,
            modalWarningExceptionalClose: false,
            showViewAppointment: false,
            showViewExceptionalOpen: false,
            showViewExceptionalClose: false,
            modalExceptionalOpen: false,
            modalExceptionalClose: false,
            showViewAbsence: false,
            showFilter: false,
            arraySub: [],
            userSelect: {
                id: '',
                name: '',
                first_name: '',
                image: ''
            },
            appointment: '',
            modalWarning: false,
            warning: '',
            event: {},
            events: [],
            arrayAgreements: [],
            appointments: [],
            calendarEvents: [],
            formatReplacements: [],
            substituteIds: []
        };

        this.officeHours = this.officeHours.bind(this);
        this.onNavigate = this.onNavigate.bind(this);
        this.collapseHours = this.collapseHours.bind(this);
        this.openNewAppointment = this.openNewAppointment.bind(this);
        this.closeModalNewAppointment = this.closeModalNewAppointment.bind(this);
        this.customToolBar = this.customToolBar.bind(this);
        this.openModalTakeAppointment = this.openModalTakeAppointment.bind(this);
        this.closeModalTakeAppointment = this.closeModalTakeAppointment.bind(this);
        this.showViewAppointment = this.showViewAppointment.bind(this);
        this.editModal = this.editModal.bind(this);
        this.dayEventCustom = this.dayEventCustom.bind(this);
        this.loadSub = this.loadSub.bind(this);
        this.closeModalEditAppointment = this.closeModalEditAppointment.bind(this);
        this.handleDragEvent = this.handleDragEvent.bind(this);
        this.closeModalWarning = this.closeModalWarning.bind(this);
        this.showViewExceptionalOpen = this.showViewExceptionalOpen.bind(this);
        this.closeModalExceptionalOpen = this.closeModalExceptionalOpen.bind(this);
        this.showViewExceptionalClose = this.showViewExceptionalClose.bind(this);
        this.closeModalWarningExceptionalClose = this.closeModalWarningExceptionalClose.bind(this);
        this.timeSlotWrapper = this.timeSlotWrapper.bind(this);
    };

    componentDidMount() {
        if (this.props.selectedUser) {
            this.setState({
                userSelect: {
                    id: this.props.selectedUser._id,
                    name: this.props.selectedUser.name,
                    first_name: this.props.selectedUser.first_name,
                    image: this.props.selectedUser.image
                }
            })
            this.props.getDoctorAgreementsBySecretaryWithDate(this.props.selectedUser.id, moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).format('YYYY-MM-DD'));
                    
        }

        if (this.props.secretaryManagements && this.props.secretaryManagements.length > 0) {
            let data = {
                arrayUsers: this.props.secretaryManagements.map((elem) => elem.doctor_id),
                startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
                endDate: moment(this.state.endDate).format('YYYY-MM-DD')
            }

            this.props.allBySecretary(this.props.secretaryManagements[0].doctor_id);
            this.props.getDoctorAgreementsBySecretaryWithDate(this.props.secretaryManagements[0].doctor_id, moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).format('YYYY-MM-DD'));

            this.props.getAppointmentsByUser(data);

            let exceptionalOpen = {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                user_id: this.props.secretaryManagements[0].doctor_id
            }

            this.props.getsExceptionalOpen(exceptionalOpen);
            this.props.getsExceptionalClose(exceptionalOpen);

            this.setState({
                userSelect: {
                    ...this.props.secretaryManagements[0].doctor,
                    id: this.props.secretaryManagements[0].doctor._id,
                }
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.secretaryManagements !== this.props.secretaryManagements && this.props.secretaryManagements && this.props.secretaryManagements.length > 0) {

            this.setState({
                userSelect: {
                    ...this.props.secretaryManagements[0].doctor,
                    id: this.props.secretaryManagements[0].doctor._id,
                }
            })
        }

        if(prevState.userSelect.id !== this.state.userSelect.id) {

            this.props.allBySecretary(this.state.userSelect.id);
            this.props.getDoctorAgreementsBySecretaryWithDate(this.state.userSelect.id, moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).format('YYYY-MM-DD'));

            let exceptionalOpen = {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                user_id: this.state.userSelect.id
            }

            this.props.getsExceptionalOpen(exceptionalOpen);
            this.props.getsExceptionalClose(exceptionalOpen);
            this.officeHours(this.state.startDate)
            this.setState({
                formatAppointments: [],
                substituteIds: []
            })
        }

        if (prevProps.agreements !== this.props.agreements) {
            const arrayAgreements = (this.props.agreements && this.props.agreements.length > 0) ? this.formatAgreements(this.props.agreements, this.state.startDate, this.state.endDate) : [];
            const substituteIds = (this.props.agreements && this.props.agreements.length > 0) ? this.props.agreements.map((agreement) => agreement.substitute._id) : []
            this.setState({
                substituteIds,
                arrayAgreements: arrayAgreements
            })
        }

        if (prevProps.replacements !== this.props.replacements) {
            const formatReplacements = (this.props.replacements && this.props.replacements.length > 0) ? AppointmentUtil.formatReplacements(this.props.replacements, this.state.startDate, this.state.endDate) : [];

            this.setState({
                formatReplacements: formatReplacements,
            })
        }

        if (prevProps.appointmentsByUsers !== this.props.appointmentsByUsers) {
            let userIds = this.state.substituteIds.concat(this.state.userSelect.id)

            const appointmentsEvents = (this.props.appointmentsByUsers && this.props.appointmentsByUsers.length > 0) ? AppointmentUtil.formatAppointmentsForSecretary(this.props.appointmentsByUsers, userIds) : [];

            this.setState({
                appointments: appointmentsEvents,
            })
        }

        if(prevState.substituteIds !== this.state.substituteIds && this.state.substituteIds) {
            let data = {
                arrayUsers: [this.state.userSelect.id].concat(this.state.substituteIds),
                startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
                endDate: moment(this.state.endDate).format('YYYY-MM-DD')
            }
            this.props.getAppointmentsByUser(data);
        }
    }

    loadSub(agreements) {
        let arraySub = [];
        let arrayIdSub = [];
        if (agreements && agreements.length > 0) {
            agreements.forEach((agreement) => {
                let found = arraySub.find((elem) => {
                    return elem.substitute_id === agreement.substitute._id
                })
                if (!found) {
                    arraySub.push({
                        name: agreement.substitute.name,
                        first_name: agreement.substitute.first_name,
                        substitute_id: agreement.substitute._id,
                        image: agreement.substitute.image
                    })
                    arrayIdSub.push(agreement.substitute._id);
                }
            })
        }

        let found = arraySub.find((elem) => {
            return elem.substitute_id === this.state.userSelect.id
        })

        if (!found) {
            let subSelected = this.state.arraySub.find((elem) => {
                return elem.substitute_id === this.state.userSelect.id;
            })
            if (subSelected) arraySub.push(subSelected);
        }

        this.setState({
            arraySub
        })
        return arrayIdSub;
    }

    officeHours(date) {
        if(!this.props.user) return
        let arrayOfficeHours = AppointmentUtil.officeHours(date, this.props.user.role, this.state.userSelect.officeTimeTable);
        this.setState({
            arrayOfficeHours: arrayOfficeHours
        })
    }

    onNavigate(date) {
        this.officeHours(date);

        this.props.getDoctorAgreementsBySecretaryWithDate(this.state.userSelect.id, moment(date).startOf('week').format('YYYY-MM-DD'), moment(date).endOf('week').subtract(1, 'day').format('YYYY-MM-DD'));

        let exceptionalOpen = {
            startDate: moment(date).startOf('week'),
            endDate: moment(date).endOf('week').subtract(1, 'day'),
            user_id: this.state.userSelect.id
        }

        this.props.getsExceptionalOpen(exceptionalOpen);
        this.props.getsExceptionalClose(exceptionalOpen);

        this.setState({
            currentDate: date,
            startDate: moment(date).startOf('week'),
            endDate: moment(date).endOf('week').subtract(1, 'day')
        })
    }

    loadAppointmentsByUser() {
        if(!this.props.user) return
        let arrayUsers = this.loadSub(this.props.agreements);

        let data = {
            startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
            endDate: moment(this.state.endDate).format('YYYY-MM-DD')
        }

        let arrayReplacementId = [];

        if (this.props.agreements && this.props.agreements.length > 0) {
            this.props.agreements.forEach((agreement) => {
                arrayReplacementId.push(agreement.replacement._id);
            })
        }

        data.arrayReplacementId = arrayReplacementId
        arrayUsers.push(this.props.user._id);
        data.arrayUsers = arrayUsers;

        this.props.getAppointmentsByUser(data);
    }

    collapseHours() {
        if (this.state.collapseHours === true) {
            this.setState({
                min: new Date(0, 0, 0, 0, 0, 0),
                max: new Date(0, 0, 0, 23, 59, 59),
                collapseHours: false
            })
        } else {
            this.setState({
                min: new Date(0, 0, 0, 7, 0, 0),
                max: new Date(0, 0, 0, 20, 0, 0),
                collapseHours: true
            })
        }
    }

    closeModalNewAppointment(date) {
        if (!moment(date).isBetween(this.state.startDate, this.state.endDate, undefined, '[]')) {
            this.loadAppointmentsByUser();
        }

        this.setState({
            modalNewAppointment: false,
            dateNewAppointmentSelected: ''
        })
    }

    closeModalWarning() {
        this.setState({
            modalWarning: false
        })
    }

    closeModalTakeAppointment() {
        this.setState({
            modalTakeAppointment: false
        })
    }

    closeModalEditAppointment(date) {
        if (!moment(date).isBetween(this.state.startDate, this.state.endDate, undefined, '[]')) {
            this.loadAppointmentsByUser();
        }

        this.setState({
            modalEditAppointment: false,
        })
    }

    closeModalExceptionalOpen() {
        this.setState({
            modalExceptionalOpen: false,
            modalExceptionalClose: false,
            showViewExceptionalOpen: false,
            showViewExceptionalClose: false,
            modalTakeAppointment: false,
            events: []
        })
    }

    openNewAppointment(event, validation) {
        if (this.state.showViewExceptionalOpen === true) {
            this.setState({
                modalExceptionalOpen: true,
                dateNewAppointmentSelected: event
            })
        } else if (this.state.showViewExceptionalClose === true) {
            let start_hour = '';
            let end_hour = '';

            let overlap = AppointmentUtil.checkOverlapWithAppointment(event.start, event.end, start_hour, end_hour, this.props.appointmentsByUsers, this.state.userSelect.id);

            if (overlap && !validation) {
                this.setState({
                    modalWarningExceptionalClose: true,
                    warning: overlap,
                    dateNewAppointmentSelected: event
                })
                return;
            }

            this.setState({
                modalExceptionalClose: true,
                dateNewAppointmentSelected: event
            })
        } else {
            this.setState({
                modalNewAppointment: true,
                dateNewAppointmentSelected: event
            })
        }
    }

    closeModalWarningExceptionalClose() {
        this.setState({
            modalWarningExceptionalClose: false
        })
    }

    openModalTakeAppointment() {
        if (this.state.showViewAppointment === false) {
            this.setState({
                modalTakeAppointment: true
            })
        } else {
            let concatEvents = this.state.arrayAgreements.concat(this.state.appointments);
            this.setState({
                showViewAppointment: false,
                events: concatEvents
            })
        }
    }

    showViewAppointment() {
        const emptyAppointments = AppointmentUtil.emptyAppointments(this.props.appointmentsByUsers, this.state.arrayOfficeHours, this.props.exceptionalOpen, this.props.exceptionalClose, this.state.userSelect.id);
        let concatEvents = this.state.events.concat(emptyAppointments);

        this.setState({
            showViewAppointment: true,
            modalTakeAppointment: false,
            events: concatEvents
        })
    }

    showViewExceptionalOpen() {
        const exceptionalOpens = (this.props.exceptionalOpen && this.props.exceptionalOpen.length) ? AppointmentUtil.exceptionalOpen(this.props.exceptionalOpen) : [];
        let concatEvents = this.state.events.concat(exceptionalOpens);

        this.setState({
            showViewExceptionalOpen: true,
            modalTakeAppointment: false,
            events: concatEvents
        })
    }

    showViewExceptionalClose() {
        const exceptionalCloses = (this.props.exceptionalClose && this.props.exceptionalClose.length) ? AppointmentUtil.exceptionalClose(this.props.exceptionalClose) : [];
        let concatEvents = this.state.events.concat(exceptionalCloses);

        this.setState({
            showViewExceptionalClose: true,
            modalTakeAppointment: false,
            events: concatEvents
        })
    }

    editModal(event) {
        if (event.css === 'exceptionalOpen') {
            this.setState({
                modalExceptionalOpen: true,
                dateNewAppointmentSelected: event
            })
        } else if (event.css === 'exceptionalClose') {
            this.setState({
                modalExceptionalClose: true,
                dateNewAppointmentSelected: event
            })
        } else if (event.css === 'appointment') {
            this.props.removeAppointmentModal(event.original._id);
            this.setState({
                modalEditAppointment: true,
                appointment: event.original
            })
        } else if (event.css === 'emptyAppointment') {
            this.openNewAppointment(event);
        }
    }

    formatAgreements(agreements, startDate, endDate) {
        var arrayAgreements = [];

        for (let agreement of agreements) {
            let nextRound = false;
            if (moment(agreement.replacement.end_date).isAfter(startDate) && moment(agreement.replacement.start_date).isBefore(endDate)) {
                nextRound = true;
            }
            if (nextRound === false) {
                continue;
            }
            let clonedReplacement = Util.clone(agreement.replacement);
            clonedReplacement._type = "Replacement";
            clonedReplacement.start = clonedReplacement.start_date;
            clonedReplacement.end = clonedReplacement.end_date;

            let events = [];

            agreement.replacement.days_available.forEach((elem) => {
                let event = {};

                clonedReplacement.title = (
                    <Col sm="12" className="lp-1">
                        <img src={ProfileImages.getSrc(agreement.substitute.image)}
                            className="d-block mx-auto mb-3" width="50" alt={agreement.substitute.name}>
                        </img>
                        {agreement.substitute.first_name + " " + agreement.substitute.name} <br></br>
                        {moment(elem.start).format('HH:mm')} - {moment(elem.end).format('HH:mm')}
                    </Col>
                );

                event._type = "Replacement";
                event.allDay = true
                event.title = clonedReplacement.title;
                event.color = clonedReplacement.type;
                event.start = new Date(elem.start);
                event.end = new Date(elem.end);
                event.sign = true;
                event.original = clonedReplacement;
                event.css = 'replacement';

                events.push(event);
            })

            if (agreement.replacement.type !== 'guard') {
                arrayAgreements = arrayAgreements.concat(events);
            }
        }

        return arrayAgreements;
    }

    customToolBar(toolbar) {

        const goToToday = () => {
            this.officeHours(new Date());

            this.props.getDoctorAgreementsBySecretaryWithDate(this.state.userSelect.id, moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).format('YYYY-MM-DD'));
            

            let exceptionalOpen = {
                startDate: moment().startOf('week'),
                endDate: moment().endOf('week').subtract(1, 'day'),
                user_id: this.state.userSelect.id
            }

            this.props.getsExceptionalOpen(exceptionalOpen);
            this.props.getsExceptionalClose(exceptionalOpen);

            this.setState({
                currentDate: new Date(),
                startDate: moment().startOf('week'),
                endDate: moment().endOf('week').subtract(1, 'day'),
            })
        }

        const goToBack = () => {

            let mDate = toolbar.date;
            let newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() - 7,
                1
            );

            toolbar.onNavigate("prev", newDate);
        }

        const goToNext = () => {

            let mDate = toolbar.date;
            let newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() + 7,
                1
            );

            toolbar.onNavigate("next", newDate);
        };

        const onChangeShowFilter = () => {
            this.setState({
                showFilter: !this.state.showFilter
            })
        }

        const onChangeFilter = (event) => {
            let id = event.currentTarget.getAttribute('data-user-id');

            if (id === this.state.userSelect.id) {
                return;
            }

            let user = this.props.secretaryManagements.find((secretaryManagement) => {
                return secretaryManagement.doctor_id === id;
            });

            if(!user) return;

            const appointmentsEvents = (this.props.appointmentsByUsers && this.props.appointmentsByUsers.length) ? AppointmentUtil.formatAppointments(this.props.appointmentsByUsers, id) : [];

            
            this.setState({
                userSelect: {
                    ...user.doctor,
                    id: id,
                },
                appointments: appointmentsEvents,
            })
        }

        // const goToWeekView = () => {
        //     return;
        // }

        // const goToMonthView = () => {
        //     this.props.changeView('month');
        // };

        // const goToYearView = () => {
        //     this.props.changeView('year');
        // };

        var dateEntete = toolbar.label.toUpperCase();

        return (
            <div className='row pt-5 pb-5'>
                {/* <div className='col-md-12 col-sm-12 d-flex justify-content-center'>
                    <div className='col-3 d-flex justify-content-around '>
                        <span className="clickable " onClick={goToYearView}>
                            <div className="buttonMonth">A</div>
                        </span>
                        <span className="clickable" onClick={goToMonthView}>
                            <div className="buttonMonth">M</div>
                        </span>
                        <span className="clickable surround-view-calendar" onClick={goToWeekView}>
                            <div className="buttonMonth surround-view-calendar-text text-center">S</div>
                        </span>
                    </div>
                </div> */}
                <div className='col-12 col-md-9 text-center header-date-calendar'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-md-2 col-1'>
                            <Dropdown isOpen={this.state.showFilter} toggle={onChangeShowFilter}>
                                <DropdownToggle caret>
                                    Filtrer
                                </DropdownToggle>
                                <DropdownMenu className='dropDownMenuFilter dropdown-item-border-radius'>
                                    {this.props.secretaryManagements.length > 0 &&
                                        this.props.secretaryManagements.map((secretaryManagement, key) =>
                                            <div key={key} className='dropdown-item-custom'>
                                                <div name="showUser" data-user-id={secretaryManagement.doctor_id} onClick={onChangeFilter} className={"text-color-black clickable " + (this.state.userSelect.id === secretaryManagement.doctor_id ? 'bold' : '')}>
                                                    {secretaryManagement.doctor.name}&nbsp;{secretaryManagement.doctor.first_name}
                                                </div>
                                            </div>
                                        )
                                    }
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div className='col-md-2 col-1'>
                            <button className="custom-button-today" onClick={goToToday}>
                                <i className="fa fa-calendar-check fa-2x"></i>
                            </button>
                        </div>
                        <div className='col-md-8 col-10 d-flex align-items-center justify-content-center'>
                            <div className='arrow-button-custom' type="button" onClick={goToBack}>
                                <span className="prev-icon-arrow">&#8249;</span>
                            </div>
                            <div className="dateEnteteSCalendar calendar-week-size-date">{dateEntete}</div>
                            <div className='arrow-button-custom' type="button" onClick={goToNext}>
                                <span className="next-icon-arrow">&#8250;</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-2 d-flex justify-content-end align-items-center pr-3">
                    <span onClick={this.openModalTakeAppointment} className="bg-orange text-white text-center clickable circleCustom">
                        {this.state.showViewAppointment === false ?
                            '+' : '-'
                        }
                    </span>
                </div>
                <div className='col-12 d-flex align-items-center justify-content-center bold pt-3'>
                    <img src={ProfileImages.getSrc(this.state.userSelect.image)}
                        className="" width="50" alt={this.state.userSelect.name}>
                    </img>
                </div>
                <div className='col-12 d-flex align-items-center justify-content-center bold'>
                    {this.state.userSelect.name}
                </div>
            </div>
        )
    }

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    dayEventCustom(event) {
        let css = 'pt-1 pl-1';
        let content = moment(event.event.start).format('HH:mm') + ' ' + event.title;

        if (event.event.css === 'replacement') {
            content = event.event.title;
            css = 'pt-1';
        } else if (event.event.css === 'emptyAppointment') {
            content = moment(event.event.start).format('HH:mm') + ' ' + event.event.title;
        } else if (event.event.css === 'exceptionalOpen') {
            content = event.event.title;
        } else if (event.event.css === 'announcement') {
            let bgClassName = "green-light ";
            if (event.event.original.type === "regular") {
                bgClassName = "blue-light";
            }
            if (event.event.original.type === "guard") {
                bgClassName = "red-light";
            }
            let title = (
                <Col sm="12" className="paddingTopCustom border-0 bg-paleblue text-dark lp-1 d-flex">
                    <div className={"ml-0 pl-0 light-margin-calendar " + bgClassName}></div>&nbsp;
                    <FormattedMessage id="Announcement" />
                </Col>
            );

            content = title;
        }

        return (
            <div className={'text-color-black ' + css}>
                {content}
            </div>
        )
    }

    handleDragEvent(event) {
        if (event.event.css === 'replacement' || event.event.css === 'exceptionalOpen') {
            return;
        }

        let appointment_date = moment(event.start);
        let check = AppointmentUtil.checkAppointment(event.start, event.event.original.duration, event.event.original._id, this.state.arrayOfficeHours, this.props.appointmentsByUsers, this.props.exceptionalOpen, this.props.exceptionalClose, this.props.user._id);

        if (check.appointments === true || check.officeHours === true) {
            this.setState({
                modalWarning: true,
                warning: check.appointments === true ? 'Vous allez déplacer ce patient sur un autre rdv patient. Continuer ?' : 'Vous allez déplacer ce patient en dehors des horaires d\'ouverture du cabinet. Continuer ?',
                event: event
            })
            return;
        }

        // reconstruit la date en rajoutant l'heure
        appointment_date.set({
            hour: moment(event.start, 'HH:mm').hour(),
            minute: moment(event.start, 'HH:mm').minute()
        })

        let appointment = {
            _id: event.event.original._id,
            patient_id: event.event.original.patient._id,
            doctor_id: event.event.original.doctor_id,
            appointment_date: appointment_date,
            name: event.event.original.patient.name,
            first_name: event.event.original.patient.first_name,
            birth_name: event.event.original.patient.birth_name,
            birth_date: event.event.original.patient.birth_date,
            phone_mobile: event.event.original.patient.phone_mobile,
            phone: event.event.original.patient.phone,
            email: event.event.original.patient.email,
            comments: event.event.original.comments,
            duration: event.event.original.duration,
            user_id: event.event.original.user_id,
            gender: event.event.original.patient.gender,
            replacement_id: event.event.original.replacement_id
        };

        let appointmentsUpdate = Array.from(this.state.appointments);
        for (let appointment of appointmentsUpdate) {
            if (appointment.original._id === event.event.original._id) {
                appointment.start = event.start;
                appointment.end = event.end;
                break;
            }
        }

        this.setState({
            appointments: appointmentsUpdate
        })

        this.props.editAppointment(appointment, event.event.original.user_id, () => {

            new Noty({
                type: "info",
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: this.props.intl.formatMessage({ id: 'Appointment.Edit' }),
                timeout: 6000,
            }).show();
        });
    }

    timeSlotWrapper(props) {
        let addStyle = {};
        let timeIndicator = {};

        return React.cloneElement(
            props.children,
            {
                style: { ...addStyle },
                'data-time': moment(props.value).format('HH:mm'),
                ...timeIndicator,
                onClick: this.collapseHours
            })
    }


    render() {
        const { agreements, user, exceptionalOpen, exceptionalClose, secretaryManagements } = this.props;

        if (!user || !secretaryManagements) {
            return this.loading();
        }

        if(secretaryManagements && secretaryManagements.length === 0) {
            return (
                <div className="text-center mt-5 mb-2 mx-auto" style={{ width: 350 }}>
                    <div className="mb-3 mx-auto" style={{ width: 150, height: 150 }}>
                        <img src={invitationImg} alt="" width={150} height={150} />
                    </div>

                    <div className="uppercase text-white"><FormattedHTMLMessage id="You.Have.No.Secretary.Managment.Yet" /></div>

                    <a href="#/home/secsettings" alt="" className="btn srounded mt-4 pl-5 pr-5 pt-2 pb-2 text-white bg-white text-blue lp-1 mx-auto">
                        <b><FormattedHTMLMessage id="Check.Secretary.Managment" /></b>
                    </a>
                </div>
            );
        }
        let events = this.state.arrayAgreements.concat(this.state.events).concat(this.state.appointments).concat(this.state.formatReplacements);
        return (
            <React.Fragment>

                <Card className="p-0 rounded-0" id="customDCalendarWeek">
                    <DnDCalendar
                        components={{
                            timeSlotWrapper: this.timeSlotWrapper,
                            toolbar: this.customToolBar,
                            week: {
                                event: this.dayEventCustom,
                            },
                        }}
                        renderable
                        selectable
                        formats={{
                            eventTimeRangeFormat: range =>
                                ``
                        }}
                        popup={true}
                        step={5}
                        timeslots={6}
                        min={this.state.min}
                        max={this.state.max}
                        localizer={localizer}
                        defaultDate={this.state.currentDate}
                        date={this.state.currentDate}
                        onView={{}}
                        events={events}
                        view={'week'}
                        slotPropGetter={(date) => AppointmentUtil.customSlotPropGetterDoctor(date, this.state.arrayOfficeHours, exceptionalOpen, exceptionalClose)}
                        views={{
                            week: WorkWeek
                        }}
                        culture={'fr'}
                        onNavigate={this.onNavigate}
                        onSelectSlot={this.openNewAppointment}
                        onSelectEvent={this.editModal}
                        eventPropGetter={(event) => AppointmentUtil.titleAccessor(event, this.state.showViewAppointment, this.state.showViewExceptionalOpen, this.state.showViewExceptionalClose)}
                        onEventDrop={this.handleDragEvent}
                        dayLayoutAlgorithm="no-overlap"
                        draggableAccessor={AppointmentUtil.draggableAccessor}
                    />
                </Card>

                {this.state.modalNewAppointment === true &&
                    <SecretaryModalNewAppointment
                        close={this.closeModalNewAppointment}
                        dateNewAppointmentSelected={this.state.dateNewAppointmentSelected}
                        arrayAgreements={this.state.arrayAgreements}
                        startWeekDate={this.state.startDate}
                        agreements={agreements}
                        arrayOfficeHours={this.state.arrayOfficeHours}
                        exceptionalOpen={exceptionalOpen}
                        exceptionalClose={exceptionalClose}
                        appointmentsByUsers={this.props.appointmentsByUsers}
                        selectedUser={this.state.userSelect}
                    />
                }

                {this.state.modalTakeAppointment === true &&
                    <SecretaryModalTakeAppointment
                        close={this.closeModalTakeAppointment}
                        showViewAppointment={this.showViewAppointment}
                        showViewExceptionalOpen={this.showViewExceptionalOpen}
                        showViewExceptionalClose={this.showViewExceptionalClose}
                    />
                }

                {this.state.modalEditAppointment === true &&
                    <SecretaryModalEditAppointment
                        close={this.closeModalEditAppointment}
                        appointment={this.state.appointment}
                        agreements={agreements}
                        arrayAgreements={this.state.arrayAgreements}
                        arrayOfficeHours={this.state.arrayOfficeHours}
                        exceptionalOpen={exceptionalOpen}
                        exceptionalClose={exceptionalClose}
                        appointmentsByUsers={this.props.appointmentsByUsers}
                        selectedUser={this.state.userSelect}
                    />
                }

                {/* {this.state.modalWarning === true &&
                    <DModalWarningAppointment
                        close={this.closeModalWarning}
                        message={this.state.warning}
                        event={this.state.event}
                    />
                } */}

                {this.state.modalExceptionalOpen === true &&
                    <SecModalExceptionalOpen
                        selectedUser={this.state.userSelect}
                        close={this.closeModalExceptionalOpen}
                        dateNewAppointmentSelected={this.state.dateNewAppointmentSelected}
                        arrayAgreements={this.state.arrayAgreements}
                        arrayOfficeHours={this.state.arrayOfficeHours}
                        exceptionalOpen={exceptionalOpen}
                        exceptionalClose={exceptionalClose}
                        appointmentsByUsers={this.props.appointmentsByUsers}
                    />
                }

                {this.state.modalExceptionalClose === true &&
                    <SecModalExceptionalClose
                        selectedUser={this.state.userSelect}
                        close={this.closeModalExceptionalOpen}
                        dateNewAppointmentSelected={this.state.dateNewAppointmentSelected}
                        arrayAgreements={this.state.arrayAgreements}
                        arrayOfficeHours={this.state.arrayOfficeHours}
                        exceptionalOpen={exceptionalOpen}
                        exceptionalClose={exceptionalClose}
                        appointmentsByUsers={this.props.appointmentsByUsers}
                    />
                }

                {this.state.modalWarningExceptionalClose === true &&
                    <DModalWarningExceptionalClose
                        close={this.closeModalWarningExceptionalClose}
                        message={this.state.warning}
                        openNewAppointment={this.openNewAppointment}
                        event={this.state.dateNewAppointmentSelected}
                    />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        officeTimeTable: state.global.officeTimeTable,
        agreements: state.agreements.agreements,
        isGetReplacementsWithDate: state.agreements.isGetReplacementsWithDate,
        user: state.global.user,
        exceptionalOpen: state.exceptionalOpen.exceptionalOpen,
        exceptionalClose: state.exceptionalClose.exceptionalClose,
        replacements: state.replacements.replacements,
        appointmentsByUsers: state.appointments.appointmentsByUsers,
        secretaryManagements: state.global.secretaryManagements
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getDoctorAgreementsBySecretaryWithDate: (doctorId, startDate, endDate) => dispatch(getDoctorAgreementsBySecretaryWithDate(doctorId, startDate, endDate)),
        getAppointmentsByUser: (data) => dispatch(getAppointmentsByUser(data)),
        editAppointment: (appointment, userId, cbk) => dispatch(editAppointment(appointment, userId, cbk)),
        removeAppointmentModal: (appointment) => dispatch(removeAppointmentModal(appointment)),
        getsExceptionalOpen: (appointment, cbk) => dispatch(getsExceptionalOpen(appointment, cbk)),
        getsExceptionalClose: (appointment, cbk) => dispatch(getsExceptionalClose(appointment, cbk)),
        allBySecretary: (doctorId) => dispatch(allBySecretary(doctorId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SecretaryCalendarWeeks));