import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, RangeInput } from '@gferrand/react-forms';
import WeekDaysInput from "../../components/form/WeekDaysInput";
import DaysException from './DaysExceptions';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap';
import { upd, getsBy, clears } from "../../actions/global/global";
import Noty from 'noty';
import DateUtil from '../../util/DateUtil';
import { calendarEventOverlaps, getCalendarEventPreventAvailabilities } from '../../actions/calendarEvent/calendarEvent';
import { concludedAgreementOverlaps, getAgreementConcluded } from '../../actions/agreement/agreement';
import { getOfficeTimeTableByUser } from '../../actions/officeTimeTable/officeTimeTable';

import "../../../node_modules/noty/lib/noty.css";
import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";

class ModalNegociation extends React.Component {

    constructor(props) {
        super(props);

        let guards = Array.from(this.props.replacement.guards, g => Object.assign({ accepted: true }, g))

        let replacement = Object.assign({}, this.props.replacement);
        let days_available = replacement.days_available;
        let start_date = replacement.start_date;
        let end_date = replacement.end_date;
        let days = replacement.type === 'regular' ? replacement.day[0] : [];
        if (replacement.applicants) {
            replacement.applicants.forEach((elem) => {
                if (elem.negociation && elem.negociation.length > 0 && this.props.substitute === elem.substitute_id) {
                    let last_element = elem.negociation[elem.negociation.length - 1];
                    start_date = last_element.start_date;
                    end_date = last_element.end_date;
                    if (last_element.guards && last_element.guards.length > 0) {
                        guards = Array.from(last_element.guards)
                    }
                    days_available = Array.from(last_element.days_available);
                }
            })
        }
        this.checkOverlap(start_date, end_date, this.props.replacement.type, days_available);

        this.state = {
            disabled: true,
            guards: guards,
            start_date: start_date,
            end_date: end_date,
            days: days,
            error: null,
			calendarEventOverlapError: null,
            replacementOverlapError: null,
            samePropositionError: true,
            agreementsConcluded: [],
            calendarEvents: [],
            loading: false,
            loadingOverlap: false,
            days_available: days_available,
            type: this.props.replacement.type,
        };

        this.buildNegociationForm();
        this.rejectGuard = this.rejectGuard.bind(this);
        this.deselectOverlappingDays = this.deselectOverlappingDays.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.backToDateOfTheDay = this.backToDateOfTheDay.bind(this);
        this.resetRetrocession = this.resetRetrocession.bind(this);
        this.checkOverlap = this.checkOverlap.bind(this);
        this.checkProposition = this.checkProposition.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true });

        this.props.getOfficeTimeTableByUser(this.props.replacement.doctor_id);

        let getAvailability = new Promise((resolve, reject) => {
            this.props.onGetsBy("availability", "substitute", this.props.substitute,
            (availability) => resolve(availability))
        })
        let getAgreementsConcluded = new Promise((resolve, reject) => {
            this.props.getAgreementConcluded(this.props.substitute,
            (agreementsConcluded) => resolve(agreementsConcluded))
        })
        let getCalendarEvents = new Promise((resolve, reject) => {
            this.props.getCalendarEventPreventAvailabilities(this.props.substitute,
            (calendarEvents) => resolve(calendarEvents))
        })
        Promise.all([getAvailability, getAgreementsConcluded, getCalendarEvents])
            .then((result) => {
                let agreementsConcluded = result[1];
                let calendarEvents = result[2];
                this.deselectOverlappingDays(calendarEvents, agreementsConcluded);
                this.checkProposition()

                this.setState({
                    calendarEvents,
                    agreementsConcluded, 
                })
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.start_date !== this.state.start_date || prevState.end_date !== this.state.end_date) {
            this.deselectOverlappingDays(this.state.calendarEvents, this.state.agreementsConcluded);
            this.checkProposition();
        }
    }

    deselectOverlappingDays(calendarEvents, agreementsConcluded) {
        this.setState({ loading: true });
        let days_available = Array.from(this.state.days_available);

        if (calendarEvents.length > 0) {
            days_available = days_available.filter((day) => {
                return calendarEvents.find((elem) => {
                    return (moment(elem.end_date).isBefore(day.start)) || (moment(elem.start_date).isAfter(day.end));
                })
            })
        }

        if (agreementsConcluded.length > 0) {
            agreementsConcluded.forEach((elem) => {
                if (!elem.cancelled_by_substitute && !elem.cancelled_by_doctor) {
                    var lastElement;
                    let daysAvailableReplacement = elem.replacement.days_available;
                    if (elem.replacement.applicants.length > 0) {
                        elem.replacement.applicants.forEach((applicant) => {
                            if (applicant.substitute_id === elem.substitute_id && applicant.substitute_status === 1 && applicant.doctor_status === 1 && applicant.negociation && applicant.negociation.length > 0) {
                                lastElement = applicant.negociation[applicant.negociation.length - 1];
                                daysAvailableReplacement = lastElement.days_available;
                            }
                        })
                    }
                    days_available = days_available.filter((elem) => {
                        return !daysAvailableReplacement.find((daysAvailableReplacement) => {
                            return moment(elem.start).isSame(daysAvailableReplacement.start, 'day');
                        })
                    })
                }
            })
        }
        
        let startAndEndDays = DateUtil.startAndEndDays(days_available);

        this.setState({ 
            loading: false, 
            days_available: days_available,
            calendarEventOverlapError: null,
            replacementOverlapError: null,
            start_date: startAndEndDays.start,
            end_date: startAndEndDays.end
        })
    }

    buildNegociationForm() {
		var submitCbk = (data) => {
            let negociation = data;
            negociation.start_date = this.state.start_date;
            negociation.end_date = this.state.end_date;
            negociation.guards = this.state.guards
            negociation.negociator = this.props.doctor === true ? 'doctor' : 'substitute';
            negociation.date = moment().toDate();
            negociation.type = this.props.replacement.type;
            negociation.days_available = this.state.days_available;

            // Ajoute l'id du médecin remplaçant
            negociation.substitute = this.props.substitute;
			this.props.onUpd("replacement/negociate", { negociation, replacement_id: this.props.replacement._id }, () => {
				this.props.close();

				new Noty({
					type: "info",
					layout: 'topRight',
					theme: 'bootstrap-v4',
					text: this.props.intl.formatMessage({ id: "Send.New.Nego" }),
					timeout: 6000,
				}).show();
			});
		};

        let retrocession = this.props.replacement.retrocession;
        let guardRetrocession = this.props.replacement.guard_retrocession;
        let day = this.props.replacement.day;
        if (this.props.replacement.applicants && this.props.replacement.applicants.length > 0) {
            this.props.replacement.applicants.forEach((elem) => {
                if (elem.negociation && elem.negociation.length > 0 && this.props.substitute === elem.substitute_id) {
                    let lastElement = elem.negociation[elem.negociation.length - 1];
                    retrocession = lastElement.retrocession
                    guardRetrocession = lastElement.guard_retrocession
                    day = lastElement.day
                }
            })
        }

		var data = {
            start_date: this.props.replacement.start_date,
            end_date: this.props.replacement.end_date,
            retrocession: retrocession,
            guard_retrocession: guardRetrocession,
            day: day
        };

        this.negociationForm = new Form({
			name: "negociation",
			inputs: [
                new RangeInput("retrocession", "Replacement", 60, 100, 2.5, [], { suffix: "%", className: "blue-range-input backgroundColorRangeInput " }),
                new RangeInput("guard_retrocession", "Guards", 60, 100, 2.5, [], { suffix: "%", className: "red-range-input backgroundColorRangeInput " }),
				new WeekDaysInput("day", this.props.replacement.day)
			],
            submitCbk,
			changeCbk: (input) => this.changeCbk(input),
			data
        });
        
        if (this.props.replacement.type !== 'regular') {
		    this.negociationForm.hide("day");
        } 
    };

    changeCbk(input) {
        var { name, value } = input;
        if (name === 'day') {
			this.setState({ days: value })
        }

        if (name === 'guard_retrocession' && this.state.type === 'guard') {
            this.setState({
                disabled: false
            })
        }

        if (name === 'retrocession') {
            this.setState({
                disabled: false
            })
        }

        this.checkProposition();
    }
    
    row(form, inputName) {
		return (
			<Row className="form-group">
				<Col md="5">{form.getLabel(inputName)}</Col>
				<Col md="7">{form.getInput(inputName)}</Col>
			</Row>
		);
	};

    // Permet de gérer l'acception ou non des gardes
    rejectGuard(event) {
        let guardId = event.currentTarget.getAttribute('data-guard-id');
        let guards = Array.from(this.state.guards);
        // Recherche si la garde a déjà été refusée
        let found = guards.find((guard) => {
            return guard._id === guardId && guard.accepted === false;
        });

        guards.forEach((elem) => {
            if (elem._id === guardId && found) {
                elem.accepted = true;
            } else if (elem._id === guardId && !found) {
                elem.accepted = false;
            }
        })

        this.setState({
            guards: guards
        }, this.checkProposition)
    }

    // Permet de changer la date à la volée
	changeDate(date) {
		if (date.isBefore(moment().subtract(1, 'day'), 'day')) {
            this.setState({
                error: 'La date ne peut pas être inférieur à la date du jour'
			})
			return;
        } else {
            this.setState({
                error: null
            })
        }

        let daysNotAvailable = [];
        if (this.state.agreementsConcluded && this.state.agreementsConcluded.length > 0){
            this.state.agreementsConcluded.forEach((elem) => {
                daysNotAvailable = daysNotAvailable.concat(elem.replacement.days_available);
            })
        }

        if (this.state.calendarEvents && this.state.calendarEvents.length > 0){
            this.state.calendarEvents.forEach((elem) => {
                daysNotAvailable = daysNotAvailable.concat(DateUtil.getDaysAvailableForEvent(elem.start_date, elem.end_date));
            })
        }

        if (this.state.days_available.length === 0) {
			let startDateTemp = date.toDate();
			let endDateTemp = date.hours(23).minutes(59).seconds(59).toDate();
			let days_available = DateUtil.getDaysAvailable(startDateTemp, endDateTemp, this.state.type, this.state.days, [], this.props.officeTimeTable);
            this.checkOverlap(startDateTemp, endDateTemp, this.state.type, days_available);
			this.setState({
				start_date: startDateTemp,
				end_date: endDateTemp,
				days_available: days_available
			})
			return;
		}

        let startAndEndDays = DateUtil.startAndEndDays(this.state.days_available);
        
        // change la date de début
        if (date.isBefore(startAndEndDays.start, 'day')) {
            let diff = moment(date).diff(startAndEndDays.end, 'week') + 1;
            diff = Math.abs(diff);
            if (diff > 12) {
                this.setState({
                    error: 'Le remplacement ne peut pas excéder 12 semaines'
                })
    			return;
            }

            let days_available = DateUtil.getDaysAvailable(date, startAndEndDays.end, this.state.type, this.state.days, daysNotAvailable, this.props.officeTimeTable);
            this.checkOverlap(date, startAndEndDays.end, this.state.type, days_available);
			this.setState({
				start_date: date,
				end_date: startAndEndDays.end,
				days_available: days_available
			}, this.checkProposition)
        // change la date de fin
        }  else if (date.isAfter(startAndEndDays.end, 'day')) {
            let diff = moment(date).diff(startAndEndDays.start, 'week') + 1;
            diff = Math.abs(diff);
            if (diff > 12) {
                this.setState({
                    error: 'Le remplacement ne peut pas excéder 12 semaines'
                })
    			return;
            }
            let days_available = DateUtil.getDaysAvailable(startAndEndDays.start, date, this.state.type, this.state.days, daysNotAvailable, this.props.officeTimeTable);
            this.checkOverlap(startAndEndDays.start, date, this.state.type, days_available);

			this.setState({
				start_date: startAndEndDays.start,
				end_date: date,
				days_available: days_available
			}, this.checkProposition)
        } else {
            let days_available = Array.from(this.state.days_available);
			let days_available_filter = days_available.filter((day) => {
				return !moment(day.start).isSame(date, 'day')
			});
            // Il ne peut pas y avoir 0 jours de cochés
            if (days_available_filter.length === 0) return;
			if (days_available.length !== days_available_filter.length) {
                let startAndEndDaysFilter = DateUtil.startAndEndDays(days_available_filter);
                this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available_filter);
				this.setState({
                    start_date: startAndEndDaysFilter.start,
                    end_date: startAndEndDaysFilter.end,
					days_available: days_available_filter
				}, this.checkProposition)
			} else {
				let push = true;
				if (daysNotAvailable && daysNotAvailable.length > 0) {
					daysNotAvailable.forEach((elem) => {
						if (date.isSame(elem.start, 'day')) {
							push = false;
							return;
						}
					})
				}

				if (push === true) {
					// Si ce n'est pas une garde, on ne peut pas rajouter les dimanches
					if (this.state.type === 'occasional' && date.day() !== 0) {
						// Si la date n'est pas dans le tableau, il faut la rajouter 
						days_available.push({
							start: date.toDate(),
							end: date.hours(23).minutes(59).seconds(59).toDate()
						});
                        this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available);
						this.setState({
							days_available: days_available
						}, this.checkProposition)
					} else if (this.state.type === 'regular' && date.day() !== 0) {
                        let numberDay = DateUtil.dayToNbr(this.state.days);
						if (numberDay === date.day()) {
							days_available.push({
								start: date.toDate(),
								end: date.hours(23).minutes(59).seconds(59).toDate()
							});
							this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available);
							this.setState({
								days_available: days_available
							}, this.checkProposition)
						}
                    } else if (this.state.type === 'guard') {
						days_available.push({
							start: date.toDate(),
							end: date.hours(23).minutes(59).seconds(59).toDate()
						});
                        this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available);
						this.setState({
							days_available: days_available
						}, this.checkProposition)
					}
				}
			}
        }
        this.setState({ disabled: false });
    }
    
    // Bouton qui permet de revenir à la date du jour
	backToDateOfTheDay() {
        let replacement = this.props.replacement;
        let start_date = this.props.replacement.start_date;
        let end_date = this.props.replacement.end_date;
        let days_available = replacement.days_available;
        if (replacement.applicants) {
            replacement.applicants.forEach((elem) => {
                if (elem.negociation && elem.negociation.length > 0 && this.props.substitute === elem.substitute_id) {
                    let last_element = elem.negociation[elem.negociation.length - 1];
                    start_date = last_element.start_date;
                    end_date = last_element.end_date;
                    days_available = Array.from(last_element.days_available);
                }
            })
        }
        
        this.checkOverlap(start_date, end_date, this.props.replacement.type, this.state.days_available);
		this.setState({
			start_date: start_date,
            end_date: end_date,
            days_available: days_available
		}, this.checkProposition)
    }
    
    // Remet la rétrocession à sa valeur d'origine
    resetRetrocession() {
        let originalRetrocession = this.props.replacement.retrocession;
        this.negociationForm.setValue("retrocession", originalRetrocession);
    }

    checkOverlap(start, end, type, days_available) {
		if (!start || !end) return;
        // Check caldendarEvents overlaps
        if (this.props.doctor === false) {
            this.props.onCalendarEventOverlaps(start, end, days_available, type, (res) => {
                this.setState({ calendarEventOverlapError: res.overlaps });
            });
        }
        // Check if a replacement with the same type overlaps 
        this.props.onConcludedAgreementOverlaps(start, end, type, undefined, days_available, (res) => {
            this.setState({ replacementOverlapError: res.overlaps, loadingOverlap: false });
        });
    };

    checkProposition() {
		const negociation = this.negociationForm.getRawData();
        const { replacement } = this.props;
        let startAndEndDays = DateUtil.startAndEndDays(this.state.days_available);

        const sameGuards = this.state.guards.length === replacement.guards.length && replacement.guards.every((value, index) => {
            let accepted = value.accepted === this.state.guards[index].accepted || (!value.accepted && this.state.guards[index].accepted)
            return accepted
        })
        
        let sameDays = this.state.days_available.length === replacement.days_available.length && replacement.days_available.every((value) => {
            return this.state.days_available.some((day_available) => {
                return moment(value.start).isSame(day_available.start, 'day');
            })
        });

        if(negociation.retrocession !== replacement.retrocession) return this.setState({ samePropositionError: null })
        if(replacement.guard_retrocession && negociation.guard_retrocession !== replacement.guard_retrocession) return this.setState({ samePropositionError: null })
        if (startAndEndDays) {
            if(!moment(startAndEndDays.start).isSame(moment(replacement.start_date), 'day')) return this.setState({ samePropositionError: null })
            if(!moment(startAndEndDays.end).isSame(moment(replacement.end_date), 'day')) return this.setState({ samePropositionError: null })
        }
        if(!sameGuards) return this.setState({ samePropositionError: null })
        if(!sameDays) return this.setState({ samePropositionError: null })
        this.setState({ samePropositionError: true })
    };
    
    render() {
        const { replacement } = this.props;
        let guardRetrocession = replacement.guard_retrocession;
        if (this.state.guards && this.state.guards.length > 0) {
            if (replacement.applicants && replacement.applicants.length > 0) {
                replacement.applicants.forEach((elem) => {
                    if (elem.negociation && elem.negociation.length > 0 && this.props.substitute === elem.substitute_id) {
                        let lastElement = elem.negociation[elem.negociation.length - 1];
                        guardRetrocession = lastElement.guard_retrocession
                    }
                })
            }
        }

        let classSubmitButton = ' disabled';
        if (this.state.disabled === false) {
            classSubmitButton = '';
        } 

        let firstName;
        if (this.props.doctor === true) {
            if (replacement.applicants_users && replacement.applicants_users.length > 0) {
                replacement.applicants_users.forEach((elem) => {
                    if (elem._id === this.props.substitute) {
                        firstName = elem.first_name;
                        return;
                    }
                })
            }
        }
        return (
            <React.Fragment>

                <Modal size="md" isOpen={true} toggle={this.props.close} className="simple-modal simple-modal-white srounded">

                    <ModalHeader className="border-0 m-0">

                        <div className="close-container">
                            <button type="button" className="close d-inline-block" aria-label="Close"
                                onClick={this.props.close} ><span aria-hidden="true">×</span></button>
                        </div>
                    </ModalHeader>

                    <ModalBody className="p-0">    
                        <div className="row justify-content-center">
                            <span className="bordureTitleRetro">Proposer une nouvelle période</span>
                        </div>                    
                        {replacement.type !== 'guard' &&
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.negociationForm.getInput("day")}
                                    </div>
                                    <div className="col-md-4">
                                        <div><span className="cssNotifShowLegendNotSign"></span>Disponibilité du remplaçant</div>
                                    </div>
                                    <div className="col-md-4">
                                        <div><span className="cssNotifShowLegendNotSignGrey"></span>Indisponible</div>

                                    </div>
                                    <div className="col-md-4">
                                        <div><span className="cssNotifShowLegendNotSignGreen"></span>Date proposée</div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        <div className="w-100 text-center mt-4 mb-3">
                            <div className="container">
                                {this.props.doctor === true && firstName && 
                                    <div className="w-100 mt-4 mb-3">
                                        Planning de : { firstName }
                                    </div>
                                }
                                {replacement.type !== 'guard' &&
                                    <DaysException
                                        date={moment().startOf('month')}
                                        startDate={this.state.start_date}
                                        endDate={this.state.end_date}
                                        days={this.state.days}
                                        changeDate={this.changeDate}
                                        backToDateOfTheDay={this.backToDateOfTheDay}
                                        agreements={this.state.agreementsConcluded}
                                        availabilitys={this.props.availabilitys}
                                        calendarEvents={this.state.calendarEvents}
                                        days_available={this.state.days_available}
                                    />
                                }
                            </div>

                            <div className="container">
                                <div className="jumbotron">
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <span className="bordureTitleRetro">Négocier la retrocession</span>
                                        </div>
                                        {replacement.type !== 'guard' &&
                                            <div className="col-md-12">
                                                {this.row(this.negociationForm, "retrocession")}
                                            </div>
                                        }
                                        {((this.state.guards && this.state.guards.length > 0) || replacement.type === 'guard') &&
                                            <div className="col-md-12">
                                                {this.row(this.negociationForm, "guard_retrocession")}
                                            </div>
                                        }
                                        <div className="col-md-12">
                                            <button onClick={this.resetRetrocession}>Revoir la proposition</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(this.state.guards && this.state.guards.length > 0) &&
                                <div className="container">
                                    <div className="jumbotron">
                                        <Row className="m-0 text-center">
                                        <div className="col-md-12 mb-3">
                                            <span className="bordureTitleRetro">Refuser une garde</span>
                                        </div>
                                            {this.state.guards.map((guard, i) => {
                                                return (
                                                    <Col sm="4" className={"col-centered " + (guard.accepted === false ? 'oldPropalLine oldPropal' : '')} key={i}>
                                                        <div data-guard-id={guard._id} onClick={this.rejectGuard} className="profile-repl">
                                                            <div className={"profile-repl-title bg-guard"}>
                                                                <FormattedMessage id={"Replacement.Guard"} />
                                                            </div>

                                                            <div className="profile-repl-details mb-3">
                                                                <div>
                                                                    <FormattedMessage id="Dates" />&nbsp;:&nbsp;
                                                                    <FormattedMessage id="from" />&nbsp;{DateUtil.toDateWithHour(guard.start_date)}&nbsp;
                                                                </div>
                                                                <div>
                                                                    <FormattedMessage id="To" />&nbsp;{DateUtil.toDateWithHour(guard.end_date)}
                                                                </div>
                                                                <div>
                                                                    <FormattedMessage id="Proposed.Retrocession" />&nbsp;:&nbsp;
                                                                    {guardRetrocession}&nbsp;%
                                                                </div>
                                                                <div>
                                                                    <FormattedMessage id="Guard.In.Office" />&nbsp;:&nbsp;
                                                                    {(guard.office) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                                                                </div>

                                                                <div>
                                                                    <FormattedMessage id="Guard.Visits" />&nbsp;:&nbsp;
                                                                    {(guard.visits) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                                                                </div>

                                                                <div>
                                                                    <FormattedMessage id="Paid.By.Fifteenth" />&nbsp;:&nbsp;
                                                                    {(guard.paid_by_the_fifteenth) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </div>
                                </div>
                            }

                            {(this.state.replacementOverlapError === true) &&
                                <div className="error">
                                    Vous avez déjà un remplacement à cette date
                                </div>
                            }

                            {this.state.error && 
                                <div className="error">
                                    {this.state.error}
                                </div>
                            }

                            {(this.state.calendarEventOverlapError === true) &&
                                <div className="error">
                                    Vous avez déjà un évènement à cette date
                                </div>
                            }

                            {(this.state.samePropositionError === true) &&
                                <div className="error">
                                    Vous devez modifier au moins un champ pour proposer une négociation
                                </div>
                            }
                            
                            <div className="mt-5 w-100 mx-auto text-center form-buttons mb-3">
                                {this.negociationForm.submitButton("Send.Back", {
                                    disabled: (this.state.calendarEventOverlapError ||
                                        this.state.replacementOverlapError || this.state.loadingOverlap || this.state.samePropositionError || this.state.error || this.state.disabled),
                                    className: "srounded pl-5 pr-5 pt-2 pb-2 text-white bg-blue btn opacity-1" + classSubmitButton
                                })}
                            </div>
                        </div>

                    </ModalBody>

                </Modal>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {

    return {
        availabilitys: state.global.availabilitys,
        calendarEvents: state.global.calendarEvents,
        officeTimeTable: state.officeTimeTable.officeTimeTable
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpd: (objName, obj, cbk) => dispatch(upd(objName, obj, cbk)),
        onCalendarEventOverlaps: (start, end, days_available, type, cbk) => calendarEventOverlaps(start, end, days_available, type, cbk),
        onConcludedAgreementOverlaps: (start, end, type, duration, days_available, cbk) => concludedAgreementOverlaps(start, end, type, duration, days_available, cbk),
        getAgreementConcluded: (substitute_id, cbk) => getAgreementConcluded(substitute_id, cbk),        
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),
        getCalendarEventPreventAvailabilities: (substitute_id, cbk) => getCalendarEventPreventAvailabilities(substitute_id, cbk),
        getOfficeTimeTableByUser: (userId) => dispatch(getOfficeTimeTableByUser(userId)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModalNegociation));