export default function retrocessionCertificate(state = {}, action) {
	switch(action.type) {
		case "GENERATE_RETROCESSION_CERTIFICATE_REQUEST":
			return Object.assign({}, state, {
				isGenerateCertificate: true
			});
		case "GENERATE_RETROCESSION_CERTIFICATE_SUCCESS":
			return Object.assign({}, state, {
				retrocessionCertificateHTML: action.retrocessionCertificateHTML.retrocessionCertificateHTML,
				isGenerateCertificate: false
			});
		case "GENERATE_RETROCESSION_CERTIFICATE_FAILURE":
			return Object.assign({}, state, {
				isGenerateCertificate: false
			});
		default:
			return state;
	}
}