import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';


export default class TransmissionConfModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            disabled: false
        };
    }

    render() {
        return (
            <Modal isOpen={true} toggle={this.props.toggle} size="md" className="simple-modal simple-modal-green srounded">

                <ModalHeader toggle={this.props.onClose}>
                    {/* <FormattedMessage id="Confirmation" /> */}
                    <button type="button" className="close d-inline-block b-green" aria-label="Close" onClick={this.props.toggle} ><span aria-hidden="true">×</span></button>
                </ModalHeader>

                <ModalBody className="p-4">

                    <FormattedMessage id="Adopt.Modifications" />

                    <div className="mt-4 mb-3">
                        <Button 
                            className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-warning btn opacity-1"
                            disabled={this.state.disabled}
                            onClick={() => this.props.submit()}>
                            <FormattedMessage id="Yes" />
                        </Button>

                        <Button 
                            className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-grey btn opacity-1"
                            onClick={() => this.props.deny()}>
                            <FormattedMessage id="No" />
                        </Button>

                        <Button 
                            className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-grey btn opacity-1"
                            onClick={() => this.props.cancel()}>
                            <FormattedMessage id="See.Again" />
                        </Button>
                    </div>

                </ModalBody>

            </Modal>
        );

    }

}
