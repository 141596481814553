import axios from "axios";
import APIUrl from "../APIUrl";
import FileUtil from "../../util/FileUtil";

const rootUrl = process.env.REACT_APP_BE_URL || "http://localhost:7000";

export function generateRetrocessionCertificate(data, cbk) {

	return dispatch => {
		dispatch(request());

        var url = rootUrl + "/retrocessionCertificateHTML/generate-retrocession-certificate";

        return axios.post(url, data)
            .then(function (response) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (cbk) cbk();
            })
            .catch(function (err) {
                dispatch(failure())
                throw err;
            });
	};

    function request() { return { type: "GENERATE_RETROCESSION_CERTIFICATE_REQUEST" }}
	function success(retrocessionCertificateHTML) { return { type: "GENERATE_RETROCESSION_CERTIFICATE_SUCCESS", retrocessionCertificateHTML }}
	function failure(error) { return { type: "GENERATE_RETROCESSION_CERTIFICATE_FAILURE", error }}
};

export const downloadCertificate = (certificateId, token) => {
    return axios.get(APIUrl.downloadCertificate.replace("{{certificateId}}", certificateId), { responseType: "arraybuffer" })
        .then(function (response) {

            FileUtil.downloadPDF(token, response.data);
        })
        .catch(function (err) {
            throw err;
        });
};