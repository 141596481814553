import React from 'react';
import Noty from 'noty';
import { connect } from "react-redux";
import { doctorAcceptManagement, doctorRefuseManagement, doctorDeleteManagement } from "../../actions/secretaryManagement/secretaryManagement"
import ProfileImages from "../../enum/ProfileImages";
import { Card, CardBody } from "reactstrap";
import moment from "moment";
import { FormattedMessage, injectIntl } from 'react-intl';
import ConfModal from "../../components/modal/ConfModal";
import StringUtil from "../../util/StringUtil";
class SecretaryCard extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.secretaryManagement) return;

        this.state = {
            acceptalModal: false,
            refusalModal: false,
            deleteModal: false
        }

        this.doctorAcceptManagement = this.doctorAcceptManagement.bind(this);
        this.doctorRefuseManagement = this.doctorRefuseManagement.bind(this);
        this.doctorDeleteManagement = this.doctorDeleteManagement.bind(this);
        this.closeAcceptModal = this.closeAcceptModal.bind(this);
        this.closeRefuseModal = this.closeRefuseModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.accept = this.accept.bind(this);
        this.refuse = this.refuse.bind(this);
        this.delete = this.delete.bind(this);
    }

    doctorAcceptManagement() {
        this.setState({ acceptalModal: true })
    }

    doctorRefuseManagement() {
        this.setState({ refusalModal: true })
    }

    doctorDeleteManagement() {
        this.setState({ deleteModal: true })

    }

    accept() {
        this.props.doctorAcceptManagement(this.props.secretaryManagement._id, () => {
            new Noty({
                type: "info",
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: this.props.intl.formatMessage({ id: "Profile.Modified.Success" }),
                timeout: 6000,
            }).show();

            this.closeAcceptModal();
        })
    }

    refuse() {
        this.props.doctorRefuseManagement(this.props.secretaryManagement._id, () => {
            new Noty({
                type: "info",
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: this.props.intl.formatMessage({ id: "Profile.Modified.Success" }),
                timeout: 6000,
            }).show();

            this.closeAcceptModal();
        })
    }

    delete() {
        this.props.doctorDeleteManagement(this.props.secretaryManagement._id, () => {
            new Noty({
                type: "info",
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: this.props.intl.formatMessage({ id: "Profile.Modified.Success" }),
                timeout: 6000,
            }).show();

            this.closeDeleteModal();
        })
    }

    closeAcceptModal() {
        this.setState({ acceptalModal: false })
    }

    closeRefuseModal() {
        this.setState({ refusalModal: false })
    }

    closeDeleteModal() {
        this.setState({ deleteModal: false })
    }


    render() {
        if (!this.props.secretaryManagement) return null;

        let secretaryManagement = this.props.secretaryManagement;
        let secretary = this.props.secretaryManagement.secretary;

        return (
            <React.Fragment>
                <Card key={this.props.index} className="d-inline-block align-top">
                    <CardBody>
                        <div className="row pb-3 w-100 secretaryCard align-items-center">
                            <div className="picture col-sm-3 col-md-3">
                                <img src={ProfileImages.getSrc(secretary.image)} className="d-block mx-auto mb-3" width="100" alt="" style={{ borderRadius: "100px" }} />
                                <div className="col-12 text-center bold mb-3">
									<div>
										{secretaryManagement.secretary.name.length > 15 ? StringUtil.capitalize(secretaryManagement.secretary.name.slice(0, 15)) : StringUtil.capitalize(secretaryManagement.secretary.name)} {' '}
										{secretaryManagement.secretary.first_name.length > 15 ? StringUtil.capitalize(secretaryManagement.secretary.first_name.slice(0, 15)) : StringUtil.capitalize(secretaryManagement.secretary.first_name)}
									</div>
								</div>
                            </div>

                            <div className="floatCarImg">
                                <div className={"bold circle " + this.acronymBackColor} style={{ color: this.acronymTextColor }}>{this.acronym}</div>
                            </div >

                            <div className="text-left bold col-12 mb-3 col-sm-7 col-md-7">
                                <div className="alignL bold">
                                    Tel : {secretaryManagement.secretary.phone_mobile}
                                </div>
                                <div className="alignL bold">
                                    Email : {secretaryManagement.secretary.email}
                                </div>
                                {this.props.type === "validmanagement" &&
                                    <div className="alignL bold">
                                        <FormattedMessage id="Sercretary.Management.Start" /> : {moment(secretaryManagement.start_date).format("DD/MM/YYYY")}
                                    </div>
                                }
                            </div>
                            <div className="row col-sm-2 col-md-2">
                                {this.props.type === "validmanagement" &&
									<div className="col-12 mt-2 w-100">
										<button className='srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-red' onClick={this.doctorDeleteManagement}>X</button>
									</div>
								}
                            </div>

                            {this.props.type === "ongoingrequest" && this.props.secretaryManagement.status === "3" &&
                                <div className="mt-2 w-100">
                                    <div className="info bolder text-white bg-blue lh-30">
                                        <FormattedMessage id='Pending.Secretary' />
                                    </div>
                                </div>
                            }

                            {this.props.type === "refusal" &&
                                <div className="col alignL bolder">
                                    <h5 className='color-red'>Refusé</h5>
                                </div>
                            }

                        </div>

                        {this.state.acceptalModal && <ConfModal
                            content={<FormattedMessage id="SecretaryManagement.Warning" />}
                            onConfirm={this.accept}
                            onCancel={this.closeAcceptModal}
                            toggle={this.closeAcceptModal} />
                        }

                        {this.state.refusalModal && <ConfModal
                            content={<FormattedMessage id="SecretaryManagement.Warning" />}
                            onConfirm={this.refuse}
                            onCancel={this.closeRefuseModal}
                            toggle={this.closeRefuseModal} />
                        }
                        {this.state.deleteModal && <ConfModal
                            content={<FormattedMessage id="SecretaryManagement.Warning" />}
                            onConfirm={this.delete}
                            onCancel={this.closeDeleteModal}
                            toggle={this.closeDeleteModal} />
                        }
                    </CardBody>
                </Card >
            </React.Fragment>
        );
    }

}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        doctorAcceptManagement: (secretaryManagementId, cbk) => dispatch(doctorAcceptManagement(secretaryManagementId, cbk)),
        doctorRefuseManagement: (secretaryManagementId, cbk) => dispatch(doctorRefuseManagement(secretaryManagementId, cbk)),
        doctorDeleteManagement: (secretaryManagementId, cbk) => dispatch(doctorDeleteManagement(secretaryManagementId, cbk))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SecretaryCard));