import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export default class SecretaryModalTakeAppointment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };

    };


    render() {

        return (
            <Modal size="lg" isOpen={true} toggle={this.props.close} className="simple-modal simple-modal-white srounded modalCustom">

                <ModalHeader className="header-modal-choice">
                    <div className="text-left">
                        <button type="button" onClick={this.props.close} className="close d-inline-block" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                </ModalHeader>

                <ModalBody className="row d-flex justify-content-center">

                    <div className="w-100 text-center mb-3">
                        <button type="button" name="newDispo" onClick={this.props.showViewAppointment} className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white btn btn-secondary background-calendar-weeks-work">
                            <span>Prendre un RDV</span>
                        </button>
                    </div>
                    
                    <div className="w-100 text-center mb-3">
                        <button type="button" name="newDispo" onClick={this.props.showViewExceptionalClose} className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white btn btn-secondary button-calendar-weeks-exceptional">
                            <span>Gestion des absences</span>
                        </button>
                    </div>

                    <div className="w-100 text-center mb-3">
                        <button type="button" name="newDispo" onClick={this.props.showViewExceptionalOpen} className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white btn btn-secondary background-calendar-weeks-exceptional-open">
                            <span>Gestion des ouvertures exceptionnelles</span>
                        </button>
                    </div>

                </ModalBody>
            </Modal>
        );
    }
}