import DateUtil from "../util/DateUtil";
import Util from "../util/Util";
import moment from 'moment';

export default class OfficeTimeTableEnt {

    static lastWorkingDay(officeTimeTable) {
        if (OfficeTimeTableEnt.worksOnDay(0, officeTimeTable)) return 0;
        else if (OfficeTimeTableEnt.worksOnDay(6, officeTimeTable)) return 6;
        else if (OfficeTimeTableEnt.worksOnDay(5, officeTimeTable)) return 5;
        else if (OfficeTimeTableEnt.worksOnDay(4, officeTimeTable)) return 4;
        else if (OfficeTimeTableEnt.worksOnDay(3, officeTimeTable)) return 3;
        else if (OfficeTimeTableEnt.worksOnDay(2, officeTimeTable)) return 2;
        else if (OfficeTimeTableEnt.worksOnDay(1, officeTimeTable)) return 1;
    }

    static worksOnDay(day, officeTimeTable) {
        var txtDay = DateUtil.nbrToDay(day);

        return (
            officeTimeTable[txtDay + "Morning"] ||
            officeTimeTable[txtDay + "Noon"] ||
            officeTimeTable[txtDay + "Afternoon"] ||
            officeTimeTable[txtDay + "Evening"] ||
            officeTimeTable[txtDay + "MorningStart"] ||
            officeTimeTable[txtDay + "MorningEnd"] ||
            officeTimeTable[txtDay + "AfternoonStart"] ||
            officeTimeTable[txtDay + "AfternoonEnd"]
        );
    }

    static worksOnDate(date, officeTimeTable) {
        return OfficeTimeTableEnt.worksOnDay(new Date(date).getDay(), officeTimeTable);
    }

    static getScheduleForDay(replType, officeTimeTable, day) {
        if (OfficeTimeTableEnt.isEmpty(replType, officeTimeTable, DateUtil.nbrToDay(day))) {
            return [
                { start: "00:00:01", end: "12:00:00" },
                { start: "12:00:00", end: "23:59:59" }
            ];
        }

        if (day === 1) {
            return [
                { start: officeTimeTable.mondayMorningStart, end: officeTimeTable.mondayMorningEnd },
                { start: officeTimeTable.mondayAfternoonStart, end: officeTimeTable.mondayAfternoonEnd }];
        } else if (day === 2) {
            return [
                { start: officeTimeTable.tuesdayMorningStart, end: officeTimeTable.tuesdayMorningEnd },
                { start: officeTimeTable.tuesdayAfternoonStart, end: officeTimeTable.tuesdayAfternoonEnd }];
        } else if (day === 3) {
            return [
                { start: officeTimeTable.wednesdayMorningStart, end: officeTimeTable.wednesdayMorningEnd },
                { start: officeTimeTable.wednesdayAfternoonStart, end: officeTimeTable.wednesdayAfternoonEnd }];
        } else if (day === 4) {
            return [
                { start: officeTimeTable.thursdayMorningStart, end: officeTimeTable.thursdayMorningEnd },
                { start: officeTimeTable.thursdayAfternoonStart, end: officeTimeTable.thursdayAfternoonEnd }];
        } else if (day === 5) {
            return [
                { start: officeTimeTable.fridayMorningStart, end: officeTimeTable.fridayMorningEnd },
                { start: officeTimeTable.fridayAfternoonStart, end: officeTimeTable.fridayAfternoonEnd }];
        } else if (day === 6) {
            return [
                { start: officeTimeTable.saturdayMorningStart, end: officeTimeTable.saturdayMorningEnd },
                { start: officeTimeTable.saturdayAfternoonStart, end: officeTimeTable.saturdayAfternoonEnd }];
        } else if (day === 7) {
            return [
                { start: officeTimeTable.sundayMorningStart, end: officeTimeTable.sundayMorningEnd },
                { start: officeTimeTable.sundayAfternoonStart, end: officeTimeTable.sundayAfternoonEnd }];
        }

        return [];
    }

    static splitOfficeTimeTable(hours) {
        let splitHours = hours.slice(0, 2);
        let splitMinutes = hours.slice(3, 5);

        return {
            splitHours,
            splitMinutes
        }
    }

    static setCalendarWithHoursOfficeTimeTable(startSplit, endSplit, date) {
        let start = moment(date).set('hour', startSplit.splitHours).set('minute', startSplit.splitMinutes);
        let end = moment(date).set('hour', endSplit.splitHours).set('minute', endSplit.splitMinutes);

        return {
            start,
            end
        }
    }

    static getScheduleForDayForWeekCalendar(officeTimeTable, date) {
        var morning;
        var afternoon;
        if (date.day() === 1) {
            if (officeTimeTable.mondayMorningStart) {
                let start = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.mondayMorningStart);
                let end = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.mondayMorningEnd);

                morning = OfficeTimeTableEnt.setCalendarWithHoursOfficeTimeTable(start, end, date);
            }

            if (officeTimeTable.mondayAfternoonStart) {
                let start = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.mondayAfternoonStart);
                let end = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.mondayAfternoonEnd);

                afternoon = OfficeTimeTableEnt.setCalendarWithHoursOfficeTimeTable(start, end, date);
            }
        } else if (date.day() === 2) {
            if (officeTimeTable.tuesdayMorningStart) {
                let start = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.tuesdayMorningStart);
                let end = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.tuesdayMorningEnd);

                morning = OfficeTimeTableEnt.setCalendarWithHoursOfficeTimeTable(start, end, date);
            }

            if (officeTimeTable.tuesdayAfternoonStart) {
                let start = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.tuesdayAfternoonStart);
                let end = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.tuesdayAfternoonEnd);

                afternoon = OfficeTimeTableEnt.setCalendarWithHoursOfficeTimeTable(start, end, date);
            }
        } else if (date.day() === 3) {
            if (officeTimeTable.wednesdayMorningStart) {
                let start = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.wednesdayMorningStart);
                let end = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.wednesdayMorningEnd);

                morning = OfficeTimeTableEnt.setCalendarWithHoursOfficeTimeTable(start, end, date);
            }

            if (officeTimeTable.wednesdayAfternoonStart) {
                let start = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.wednesdayAfternoonStart);
                let end = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.wednesdayAfternoonEnd);

                afternoon = OfficeTimeTableEnt.setCalendarWithHoursOfficeTimeTable(start, end, date);
            }
        } else if (date.day() === 4) {
            if (officeTimeTable.thursdayMorningStart) {
                let start = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.thursdayMorningStart);
                let end = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.thursdayMorningEnd);

                morning = OfficeTimeTableEnt.setCalendarWithHoursOfficeTimeTable(start, end, date);
            }

            if (officeTimeTable.thursdayAfternoonStart) {
                let start = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.thursdayAfternoonStart);
                let end = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.thursdayAfternoonEnd);

                afternoon = OfficeTimeTableEnt.setCalendarWithHoursOfficeTimeTable(start, end, date);
            }
        } else if (date.day() === 5) {
            if (officeTimeTable.fridayMorningStart) {
                let start = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.fridayMorningStart);
                let end = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.fridayMorningEnd);

                morning = OfficeTimeTableEnt.setCalendarWithHoursOfficeTimeTable(start, end, date);
            }

            if (officeTimeTable.fridayAfternoonStart) {
                let start = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.fridayAfternoonStart);
                let end = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.fridayAfternoonEnd);

                afternoon = OfficeTimeTableEnt.setCalendarWithHoursOfficeTimeTable(start, end, date);
            }
        } else if (date.day() === 6) {
            if (officeTimeTable.saturdayMorningStart) {
                let start = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.saturdayMorningStart);
                let end = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.saturdayMorningEnd);

                morning = OfficeTimeTableEnt.setCalendarWithHoursOfficeTimeTable(start, end, date);
            }

            if (officeTimeTable.saturdayAfternoonStart) {
                let start = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.saturdayAfternoonStart);
                let end = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.saturdayAfternoonEnd);

                afternoon = OfficeTimeTableEnt.setCalendarWithHoursOfficeTimeTable(start, end, date);
            }
        } else if (date.day() === 7) {
            if (officeTimeTable.sundayMorningStart) {
                let start = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.sundayMorningStart);
                let end = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.sundayMorningEnd);

                morning = OfficeTimeTableEnt.setCalendarWithHoursOfficeTimeTable(start, end, date);
            }

            if (officeTimeTable.sundayAfternoonStart) {
                let start = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.sundayAfternoonStart);
                let end = OfficeTimeTableEnt.splitOfficeTimeTable(officeTimeTable.sundayAfternoonEnd);

                afternoon = OfficeTimeTableEnt.setCalendarWithHoursOfficeTimeTable(start, end, date);
            }
        } 

        return [
            morning,
            afternoon
        ]
    }

    static isEmpty = (replType, officeTimeTable, day) => {
        if (!officeTimeTable || Util.emptyObject(officeTimeTable)) return true;

        // For regular, check specific day
        if (replType === "regular") {
            if ((officeTimeTable[day + "MorningStart"] && officeTimeTable[day + "MorningEnd"]) ||
                (officeTimeTable[day + "AfternoonStart"] && officeTimeTable[day + "AfternoonEnd"]) ||
                (officeTimeTable[day + "MorningStart"] && officeTimeTable[day + "AfternoonEnd"])) return false;
        }
        // For occasional, check full week. If any day if defined, it's ok
        else {
            const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

            for (let d of days) {
                if ((officeTimeTable[d + "MorningStart"] && officeTimeTable[d + "MorningEnd"]) ||
                    (officeTimeTable[d + "AfternoonStart"] && officeTimeTable[d + "AfternoonEnd"]) ||
                    (officeTimeTable[d + "MorningStart"] && officeTimeTable[d + "AfternoonEnd"])) return false;
            }
        }

        return true;
    }

}