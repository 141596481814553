const rootUrl = process.env.REACT_APP_BE_URL || "http://localhost:5001";

const APIUrl = {
    // Authentication
    getBearerToken:      rootUrl + "/oauth/token",                                           // GET
    login:               rootUrl + "/auth/login",                                            // POST
    resetPwd:            rootUrl + "/auth/reset",                                            // POST
    setNewPwd:           rootUrl + "/auth/setnew",                                           // POST

    // Global SINGLES
    add:                 rootUrl + "/{{objName}}",                                           // POST
    get:                 rootUrl + "/{{objName}}",                                           // GET
    upd:                 rootUrl + "/{{objName}}",                                           // PUT
    remove:              rootUrl + "/{{objName}}",                                           // DELETE

    // Global MANY
    gets:                rootUrl + "/{{objName}}s",                                          // GET

    // User
    checkEmail:          rootUrl + "/user/email",                                            // POST
    checkPhone:          rootUrl + "/user/phone",                                            // POST

    // Replacements
    watchReplacement:    rootUrl + "/replacement/watch",
    applyToReplacement:  rootUrl + "/replacement/apply",                                     // PUT
    getWatchedReplacements: rootUrl + "/replacement/watched",                                // GET
    getCertificate: rootUrl + "/replacementHTMLs/replacementId/{{replacementId}}/doctorId/{{doctorId}}/substituteId/{{substituteId}}/total/{{total}}",  //GET
    checkReplacementOverlap: rootUrl + "/replacement/overlap",                               // POST
    declinePrivateRepl: rootUrl + "/replacement/decline/",                                    // GET
    getReplacementCount: rootUrl + "/replacements/count",                                    // GET
    getReplacementInvitationCount: rootUrl + "/replacements/invitation/count",                                    // GET
    getReplacementWatchCount: rootUrl + "/replacements/watch/count",                                    // GET

    // Agreements
    payAgreement:        rootUrl + "/agreement/pay",                                         // POST
    cancelAgreement:     rootUrl + "/agreement/cancel",                                      // POST
    downloadContract:    rootUrl + "/agreement/contract/{{agreementId}}",                    // GET
    concludedAgreementOverlaps: rootUrl + "/agreement/overlap",                              // POST
    getAgreementConcluded: rootUrl + "/agreements/concluded/custom/{{substitute_id}}",       // GET
    getAgreementCount: rootUrl + "/agreements/count",                                        // GET

    // Availabilities
    checkAvailabilityOverlap: rootUrl + "/availability/overlap",                             // POST

    // RetrocessionCertificate 
    downloadCertificate:    rootUrl + "/retrocessionCertificate/download/{{certificateId}}",                    // GET

    // CalendarEvents
    checkCalendarEventOverlap: rootUrl + "/calendarEvent/overlap",                           // POST
    getCalendarEventPreventAvailabilities: rootUrl + "/calendarEvents/custom/{{substitute_id}}",                           // GET
};

export default APIUrl;


// "http://192.168.0.11:5001";
