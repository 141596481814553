import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, ModalHeader, ModalBody, Button, Col } from 'reactstrap';
import moment from 'moment';
import OfficeTime from "../../../enum/OfficeTime";
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";

class DOfficeModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            hoursStart: moment(this.props.event.start).format("HH"),
            minutesStart: moment(this.props.event.start).format("mm"),
            hoursEnd: moment(this.props.event.end).format("HH"),
            minutesEnd: moment(this.props.event.end).format("mm"),
            error: false
        }

        this.allDays = this.allDays.bind(this);
        this.allDaysEmpty = this.allDaysEmpty.bind(this);
        this.selectHoursMinutes = this.selectHoursMinutes.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveEvent = this.saveEvent.bind(this);
    }

    handleChange(event) {
        this.setState({
            hoursStart: event.currentTarget.name === "selectHoursStart" ? event.currentTarget.value : this.state.hoursStart,
            minutesStart: event.currentTarget.name === "selectMinutesStart" ? event.currentTarget.value :  this.state.minutesStart,
            hoursEnd: event.currentTarget.name === "selectHoursEnd" ? event.currentTarget.value : this.state.hoursEnd,
            minutesEnd: event.currentTarget.name === "selectMinutesEnd" ? event.currentTarget.value :  this.state.minutesEnd,
        })
    }

    onDelete(event){
        // Si l'event n'est pas encore enregistré en bdd
        if (event.temp === true) {
            this.props.deleteEventTemp(event);
            this.props.close();
            return;
        }
        this.props.deleteEventBdd(event);
        this.props.close();
    }

    // Fonction pour rajouter les dispos sur tous les jours, meme s'il y'a dejà des dispos
    allDays() {
        let start = moment(this.props.event.start)
        start.set({ hour: this.state.hoursStart, minute: this.state.minutesStart })
        let end = moment(this.props.event.end)
        end.set({ hour: this.state.hoursEnd, minute: this.state.minutesEnd })
        this.props.allDays(moment(start).format("HH:mm"), moment(end).format("HH:mm"), moment(start).day())
    }

    // Fonction pour rajouter les dispos sur tous les jours ou il n'y a pas encore de dispos
    // Plus utiliser pour le moment
    allDaysEmpty() {
        this.props.allDaysEmpty(moment(this.props.event.start).format("HH:mm"), moment(this.props.event.end).format("HH:mm"));
    }

    selectHoursMinutes() {
        var hours = OfficeTime.valuesHours();
        var minutes = OfficeTime.valuesMinutes();

        // Permet d'éviter que l'heure de fin soit inférieur à celle de début et l'heure de début ne peut pas être 22h00
        var hoursStart = hours.filter(element => {
           return element.value <= this.state.hoursEnd && element.value < 22
        }); 
  
        var hoursEnd = hours;

        return (
            <div>
                <div>
                    <FormattedMessage id="Start.Hour" />&nbsp;:&nbsp;
                    <select onChange={this.handleChange} value={this.state.hoursStart} name="selectHoursStart" id="select-hours-start">
                        {hoursStart.map((elem, key) => 
                            <option key={key} value={elem.value}>{elem.label}</option>
                        )}
                    </select>
                    <select onChange={this.handleChange} value={this.state.minutesStart} name="selectMinutesStart" id="select-minutes-start">
                        {minutes.map((elem, key) => 
                            <option key={key} value={elem.value}>{elem.label}</option>
                        )}
                    </select>
                </div>
                <div>
                    <FormattedMessage id="End.Hour" />&nbsp;:&nbsp;
                    <select onChange={this.handleChange} value={this.state.hoursEnd} name="selectHoursEnd" id="select-hours-end">
                        {hoursEnd.map((elem, key) => 
                            <option key={key} value={elem.value}>{elem.label}</option>
                        )}
                    </select>
                    <select onChange={this.handleChange} value={this.state.minutesEnd} name="selectMinutesEnd" id="select-minutes-end">
                        {minutes.map((elem, key) => 
                            <option key={key} value={elem.value}>{elem.label}</option>
                        )}
                    </select>
                </div>
            </div>
        )
    }

    // Enregistre la mise à jour d'une dispo existante
    saveEvent() {
        // Formate les heures
        let start = moment(this.props.event.start).set({
            hour: this.state.hoursStart,
            minute: this.state.minutesStart
        });
        let end = moment(this.props.event.end).set({
            hour: this.state.hoursEnd,
            minute: this.state.minutesEnd
        });

        // Récup les anciennes valeurs pour les comparer
        let oldStart = this.props.event.start;
        let oldEnd = this.props.event.end;

        let updateAvailability = this.props.updateAvailability(start, end, oldStart, oldEnd, this.props.event.temp)
        // La mise à jour du rdv est ok
        if (updateAvailability === true) {
            this.setState({ error: false })
            this.props.close();
        } else {
            this.setState({ error: true })
        }
    }

    render() {
        let start = moment(this.props.event.start).format("HH:mm");
        let end = moment(this.props.event.end).format("HH:mm");
        // Le format doit être la 1ere lettre en majuscule et la suite en minuscule pour la traduction
        let day = this.props.event.day[0].toUpperCase() + this.props.event.day.slice(1); 

        const selectHoursMinutes = this.selectHoursMinutes()

        return (
            <React.Fragment>

                <Modal size="md" isOpen={true} toggle={() => this.props.close()} className="simple-modal simple-modal-white srounded">
                    <ModalHeader className="border-0 m-0">
                        <div className="text-left">
                            <button type="button" className="close d-inline-block" aria-label="Close" onClick={() => this.props.close()}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                    </ModalHeader>

                    <ModalBody className="p-0">
                        <Col sm="8" className="col-centered text-center">
                            <div className="profile-repl mt-5 mb-5">

                                <div className="profile-repl-details mb-3">
                                    <div>
                                        <FormattedMessage id="Day" />&nbsp;:&nbsp;
                                        <FormattedMessage id={day} />
                                    </div>
                                    <div>
                                        <FormattedMessage id="Hours" />&nbsp;:&nbsp;
                                        {start} - {end}
                                    </div>
                                </div>

                                <div className="profile-repl-details mb-3">
                                    {selectHoursMinutes}
                                </div>

                                {this.props.register === true && 
                                    <div className="profile-repl-details mb-3">
                                        <button onClick={this.allDays}>Appliquer le créneau aux jours suivants</button>
                                    </div>
                                }

                                {/* 
                                <div className="profile-repl-details mb-3">
                                    <button onClick={this.allDaysEmpty}>Appliquer aux jours non renseignées</button>
                                </div> */}

                                {this.state.error === true && 
                                    <div className="profile-repl-details mb-3 bg-red customError">
                                        <FormattedMessage id="Overlapping.OfficeTime" />
                                    </div>
                                }

                            </div>
                        </Col>
                        <div className="w-100 text-center mt-4 mb-3">
                            <Button
                                className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-red"
                                onClick={() => this.onDelete(this.props.event)}>
                                <FormattedMessage id="Delete" />
                            </Button>

                            <Button
                                className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green"
                                onClick={this.saveEvent}>
                                <FormattedMessage id="Save" />
                            </Button>
                        </div>

                    </ModalBody>

                </Modal>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DOfficeModal));