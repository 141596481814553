import React from 'react';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage } from 'react-intl';
import DateUtil from '../../../util/DateUtil';
import { Row } from "reactstrap";
import Util from '../../../util/Util';
import ReplacementEnt from "../../../entities/ReplacementEnt";


export default class DReplacementTitle extends React.Component {

    render() {
        const { replacement } = this.props;

        if (!replacement) return null;

        let isPrivate = ReplacementEnt.isPrivate(replacement);
        if (replacement.invitation_by_doctor === true) isPrivate = true;

        let endDate;
        if(replacement.type === "guard") {
            endDate = DateUtil.toDateWithHour(replacement.end_date);
        } else if(replacement.type === "regular") {
            if (!replacement.end_date) {
                endDate = DateUtil.toDate(ReplacementEnt.getRegularReplEnd(replacement.day, replacement.duration, replacement.start_date));
            } else {
                endDate = DateUtil.toDate(replacement.end_date);
            }
        } else {
            endDate = DateUtil.toDate(replacement.end_date);
        } 

        let retrocession = replacement.retrocession;
        let guardRetrocession = replacement.guard_retrocession;
        // Dans le cas d'une invitation, on affiche la rétrocession négocié
        if (replacement.availability_id) {
            if (replacement.applicants && replacement.applicants.length > 0) {
                replacement.applicants.forEach((applicant) => {
                    if (applicant.negociation && applicant.negociation.length > 0) {
                        let lastElement = applicant.negociation[applicant.negociation.length - 1];
                        retrocession = lastElement.retrocession;
                        guardRetrocession = lastElement.guard_retrocession;

                    }
                })
            }
        }
        return (
            <React.Fragment>

                <Row className="min-height-70">

                    <div className="d-inline-block pl-3 pr-3">
                        {(replacement.type === "regular") && replacement.day.map((newDay, key) => 
                            <span key={key}>
                                <FormattedMessage id="the.sing" />&nbsp;
                                    <b><FormattedMessage id={Util.fstLetUpCase(newDay)} /></b>,&nbsp;
                            </span>
                        )}
                        <FormattedMessage id="from" />{' '}
                        <b>{(replacement.type === "guard") ? DateUtil.toDateWithHour(replacement.start_date) : DateUtil.toDate(replacement.start_date)}</b>
                        {' '}<FormattedMessage id="To" />{' '}
                        <b>{endDate}</b>
                    </div>

                    {(!isPrivate) &&
                        <>
                        <div className="d-inline-block align-top text-center" style={{ minWidth: "40px" }}>
                            <div className="icon" style={{ height: "50px", lineHeight: "70px", fontSize: "22px" }}>
                                <i className="fa icon-ICON__OEIL_GRIS"></i>
                            </div>
                            <div className="number" style={{ height: "20px", lineHeight: "0px", fontSize: "12px", marginTop: "-4px" }}>
                                {replacement.views || "0"}
                            </div>
                        </div>

                        <div className={"d-inline-block align-top text-center " +
                            ((replacement.watchers && replacement.watchers.length > 0) ? "clickable text-danger" : "")}
                            style={{ minWidth: "40px" }}
                            onClick={() => { if (replacement.watchers && replacement.watchers.length > 0) this.props.showWatchers() }}>
                            <div className="icon" style={{ height: "50px", lineHeight: "70px", fontSize: "16px" }}>
                                <i className="fa icon-ICON__COEUR_ROSE"></i>
                            </div>

                            <div className="number" style={{ height: "20px", lineHeight: "0px", fontSize: "12px", marginTop: "-4px" }}>
                                {replacement.watchers.length}
                            </div>
                        </div>

                        <div className={"d-inline-block align-top text-center " + ((replacement.applicantsCharacteristics && replacement.applicantsCharacteristics.length > 0) ? "clickable text-danger" : "")}
                            onClick={() => { if (replacement.applicantsCharacteristics && replacement.applicantsCharacteristics.length > 0) this.props.showApplicants() }}
                            style={{ minWidth: "40px" }}>
                            <div className="icon p-3 pl-0" style={{ height: "70px", lineHeight: "76px", fontSize: "16px" }}>
                                {(replacement.applicantsCharacteristics && replacement.applicantsCharacteristics.length > 0) ?
                                    <div className="border srounded border-muted pl-2 pr-2 border-success text-success" style={{ height: "35px", lineHeight: "35px", fontSize: "16px" }}>
                                        {replacement.applicantsCharacteristics.length} <FormattedMessage id="Proposals" />
                                    </div>
                                    :
                                    <div className="border srounded border-muted pl-2 pr-2 text-muted" style={{ height: "35px", lineHeight: "35px", fontSize: "16px" }}>
                                        0 <FormattedMessage id="Proposals" />
                                    </div>
                                }
                            </div>
                        </div>
                        <FormattedMessage id="Retrocession" /> : &nbsp;
                        {(replacement.type !== "guard") ?
                            <b>{retrocession} % </b>
                            :
                            <b>{guardRetrocession} % </b>
                        }
                        </>
                    }

                </Row>

            </React.Fragment >
        );
    };
}