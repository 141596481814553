import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Form, NotEmpty } from '@gferrand/react-forms';
import { EXPAND_LEFT } from 'react-ladda';
import { Link } from 'react-router-dom';

import CheckboxInput from '../../../components/form/CheckBoxInput';
import SubmitButton from '../../../components/SubmitButton';

export default class PEStep4 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            disabled: true,
            loading: false
        };

        const submitCbk = (formData) => {
            var callback = () => this.props.jumpToStep(4);

            this.setState({ loading: true });

            var data = this.props.getStore();
            data.user.accept_contacted_clinicaltrials = true;
            data.user.name = data.user.name[0].toUpperCase() + data.user.name.slice(1); 
            data.user.first_name = data.user.first_name[0].toUpperCase() + data.user.first_name.slice(1);
            delete data.user.confirmation_mot_de_passe;

            if (data.officeTimeTable.totalHours) {
    			delete data.officeTimeTable.totalHours;
            }

            this.props.onAdd("user", data, callback);
        };

        const changeCbk = () => {
            const formData = this.form.getRawData();

            this.setState({ disabled: (!formData.assert_pediatrician) });
        };

        this.form = new Form({
            name: "conditions",
            inputs: [
                new CheckboxInput("assert_pediatrician", "Assert.Pediatrician.Condition", [NotEmpty, MustBeTrue]),
                // new CheckboxInput("accepted_protection", "Assert.Protection.Condition", []),
                // new CheckboxInput("accepted_real_estate", "Assert.RealEstate.Condition", []),
            ],
            submitCbk,
            changeCbk,
            options: { validate: this.props.validate }
        });
    };

    back() {
        this.props.jumpToStep(2);
    };

    row(form, input) {
        return (
            <Row className="form-group">
                <Col xs="1" className="cond-form">{form.getInput(input)}</Col>
                <Col xs="11" className="cond-form pl-4 pl-sm-0">{form.getLabel(input)}</Col>
            </Row>
        );
    }

    validate() {
		return false;
	}

    isValidated() {
		return this.validate(); 
	}

    render() {

        return (
            <React.Fragment>

                <Row className="step step3 mt-2 justify-content-md-center ">

                    <Col lg="8">

                        <Card className="rounded-0">

                            <CardBody className="p-5">

                                <div className="w-100 text-center mb-3">
                                    <h5><FormattedMessage id="Validation" /></h5>
                                </div>

                                {this.row(this.form, "assert_pediatrician")}
                                {/* {this.row(this.form, "accepted_protection")}
                                {this.row(this.form, "accepted_real_estate")} */}


                                <div><i>Cliquez <Link to="/terms" target="_blank">ici</Link> pour consulter les CGV / CGU</i></div>

                                <Row className="mt-5">

                                    <div className="text-center w-100">

                                        <div className="d-inline-block align-top mb-3 mb-sm-0 mx-auto">
                                            <div
                                                className={"btn btn-ladda lowercase srounded pl-5 pr-5 pt-2 pb-2 bg-blue text-white mx-auto"}
                                                onClick={() => this.back()}
                                                data-style={EXPAND_LEFT}>

                                                <FormattedHTMLMessage id="Previous.Step" />
                                            </div>
                                        </div>

                                        <div className="d-block d-sm-inline-block m-0 p-0 h-0">&nbsp;&nbsp;&nbsp;</div>

                                        <div className="d-inline-block align-top mx-auto">
                                            <SubmitButton loading={this.state.loading} disabled={this.state.disabled} form={this.form}/>
                                        </div>

                                    </div>

                                </Row>

                            </CardBody>

                        </Card>

                    </Col>

                </Row>

            </React.Fragment >
        );
    }
}

class MustBeTrue {

    static name() {
        return "MustBeTrue";
    }

    static valid(value) {
        return value === true;
    }

    static error() {
        return null;
    }

    static toJoi() {
        return ".boolean().invalid(false)";
    }

}