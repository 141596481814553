import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { adds } from "../../../actions/global/global";
import OccasionalReplacementPropositionForm from './OccasionalReplacementPropositionForm';
import GuardForm from './GuardForm';

class AddReplacementProposition extends React.Component {

	constructor(props) {
		super(props);

		const { availability } = this.props;

		this.state = {
			replType: (availability.type !== 'undefined') ? availability.type : "occasional",
			modal: null,
			isModalOpen: false
		};

		this.onChangeTypeCbks = [];


		this.occasionalReplacementForm = <OccasionalReplacementPropositionForm
			{...this.props}
			onAddChangeTypeCbk={(cbk) => this.onChangeTypeCbks.push(cbk)}
			onChangeReplType={(replType) => this.toggle(replType)} />;

		this.regularReplacementForm = <OccasionalReplacementPropositionForm
			{...this.props}
			onAddChangeTypeCbk={(cbk) => this.onChangeTypeCbks.push(cbk)}
			onChangeReplType={(replType) => this.toggle(replType)} />;

		this.guardForm = <GuardForm
			{...this.props}
			onAddChangeTypeCbk={(cbk) => this.onChangeTypeCbks.push(cbk)}
			onChangeReplType={(replType) => this.toggle(replType)} />;
	};

	toggle(replType) {
		const { availability } = this.props;

		if (availability && replType !== availability.type) return;

		this.setState({ replType: replType });

		for (let cbk of this.onChangeTypeCbks) cbk(replType);
	};


	render() {
		return (
			<Modal size="lg" isOpen={true} toggle={this.props.toggle} className="simple-modal simple-modal-white srounded">

				<ModalHeader toggle={this.props.toggle}>
				</ModalHeader>

				<ModalBody className="pl-2 pr-2 pl-md-5 pr-md-5 pt-0">

					<div className="mx-auto text-center" style={{ maxWidth: "700px" }}>

						{(this.state.replType === "occasional") &&
							<div>
								{this.occasionalReplacementForm}
							</div>
						}

						{(this.state.replType === "regular") &&
							<div>
								{this.regularReplacementForm}
							</div>
						}

						{(this.state.replType === "guard") &&
							<div>
								{this.guardForm}
							</div>
						}

					</div>

				</ModalBody>

			</Modal>
		);
	}

};

const mapStateToProps = state => {
	return {
		officeTimeTable: state.global.officeTimeTable
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onAdds: (objName, obj, cbk) => dispatch(adds(objName, obj, cbk))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddReplacementProposition);