import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

class AddDoctor extends React.Component {

	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
        let {name, value} = event.currentTarget;
		this.props.handleChange(this.props.index, name, value);
	}

	render() {

		return (
            <div className="col-md-12 jumbotron">
                <div className="text-center pb-3">
                    Chaque médecin recevra un mail avec un code unique d'abonnement
                </div>
                <div className="form-group row">
                    <label className="col-4 col-form-label" htmlFor="name"><FormattedMessage id={"Name"} /></label>
                    <div className="col-8">
                        <input onChange={this.handleChange} required className="form-control" type="text" name="name" value={this.props.doctor.name} />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-4 col-form-label" htmlFor="first_name"><FormattedMessage id={"First.Name"} /></label>
                    <div className="col-8">
                        <input onChange={this.handleChange} required className="form-control" type="text" name="first_name" value={this.props.doctor.first_name} />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-4 col-form-label" htmlFor="mail"><FormattedMessage id={"Email"} /></label>
                    <div className="col-8">
                        <input onChange={this.handleChange} required className="form-control" type="text" name="mail" value={this.props.doctor.mail} />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-4 col-form-label" htmlFor="phone"><FormattedMessage id={"Phone.Mobile"} /></label>
                    <div className="col-8">
                        <input onChange={this.handleChange} className="form-control" type="text" name="phone" value={this.props.doctor.phone} />
                    </div>
                </div>
            </div>
		);
	}
}


const mapStateToProps = state => {
	return {
        user: state.global.user,
	}
}

const mapDispatchToProps = dispatch => {
	return {
        //
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDoctor);
