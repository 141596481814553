import React from 'react';
import { connect } from 'react-redux';
import { Col } from "reactstrap";
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { getsBy, clears } from '../../actions/global/global';
import CalendarController from '../calendar/CalendarController';

class SecHome extends React.Component {


	componentDidMount() {
		this.props.onGetsBy("secretaryManagement/active-by-secretary", [], [], async (secretaryManagements) => {
			let options = await secretaryManagements.map((secretaryManagement) => {
				return { value: secretaryManagement, label: `${secretaryManagement.doctor.name} ${secretaryManagement.doctor.first_name}` }
			})
			this.setState({ options, user: options[0] })
		});
	}
	
	// getSecretaryManagements() {
	// 	this.props.onGetsBy("secretaryManagement/all-by-secretary", [], [], (secretaryManagements) => {
	// 		secretaryManagements.map((secretaryManagement) => {
	// 			let options = []
	// 			options.push({ value: secretaryManagement, label: `${secretaryManagement.doctor.name} ${secretaryManagement.doctor.first_name}` })
	// 			this.setState({ options, user: options[0] })
	// 		})
	// 	});
	// }

	// handleOptionChange = (event) => {
	// 	this.setState({ user: event.target.value });
	// };

	// changeDoctor = () => {
	// 	if (this.state.options.length > 0) this.setState( { user: this.state.options[0]} )
	// }

	loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

	button = (i18n, link = "", img) => {
		return (
			<Col xs="6" sm="3" className="p-0 m-0">
				<div className="d-inline-block text-center mb-2">
					<Link to={link} className="d-inline-block mb-3 img-btn" style={{ width: 150, height: 150 }}>
						<img src={img} alt="" width={150} height={150} />
					</Link>
					<div className="uppercase text-white"><FormattedHTMLMessage id={i18n} /></div>
				</div>
			</Col>
		);
	}

	inactiveLink = (i18n, img) => {
		return (
			<Col xs="6" sm="3" className="p-0 m-0">
				<div className="d-inline-block text-center mb-2">
					<div className="d-inline-block mb-3" style={{ width: 150, height: 150 }}>
						<img src={img} alt="" width={150} height={150} />
					</div>
					<div className="uppercase text-white"><FormattedHTMLMessage id={i18n} /></div>
				</div>
			</Col>
		);
	}

	render() {
		// const { options, selectedUser } = this.state
		return (
			<>
				{/* <Form className='row srounded bg-white text-center mb-3 mx-5'>
					<Col className="col-lg-4 my-auto"> <h4 className="bold m-3 align-middle"> <FormattedMessage id="List.Doctors" /> </h4></Col>
					<Col className="col-lg-4 my-auto mx-auto">
						<Input className="srounded" type="select" name="selectedUser" value={selectedUser} onChange={this.handleOptionChange}>
							{options && options.map((option, key) =>
								<option key={key} value={option.value}> {Util.truncate(option.label, 20)} </option>
							)}
						</Input>
					</Col>
					<Col className="col-lg-4 my-auto">
						<Button className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green" onClick={this.changeDoctor}><FormattedMessage id='Submit' /></Button>
					</Col>
				</Form> */}
				<CalendarController history={this.props.history} role='secretary' />
				{/* {this.state.user && this.state.user.value && <CalendarController history={this.props.history} role='secretary' data={this.state.user.value} />} */}
			</>

		);
	}
}


const mapStateToProps = state => {
	return {
		secretaryManagements: state.global.secretaryManagements
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback)))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SecHome));
