import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { EXPAND_LEFT } from 'react-ladda';
import OfficeTimetable from '../../doctors/office/OfficeTimetable';

export default class PSStep3 extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			disabled: false,
			error: false
		};

		this.timeTableCbk = null;
	};

	isValidated() {
		let valid = true;
		var timeTableData = this.timeTableCbk();
		if (timeTableData.totalHours < 5 || timeTableData.totalHours > 80) {
			valid = false;
		} else {
			this.props.updateStore({ officeTimeTable: timeTableData });
		}
		return valid; 
	}

	addTimeTableCbk(cbk) {
		if (!this.timeTableCbk) this.timeTableCbk = cbk;
	};

	finish() {
		var timeTableData = this.timeTableCbk();

		// si le nombre d'heure est inférieur à 5h00 ou supérieur à 80h00 sur la semaine
		if (timeTableData.totalHours < 5 || timeTableData.totalHours > 80) {
			// Voir pour utiliser noty
			this.setState({ 
				error: true
			})
			return;
		} else {
			this.setState({ 
				error: false
			})
			// Supprime la clé, plus nécessaire
			delete timeTableData.totalHours
		}

		this.props.updateStore({ officeTimeTable: timeTableData });

		this.props.jumpToStep(3);

		if (window.scrollTo) window.scrollTo(0, 0);
	};

	back() {
		var timeTableData = this.timeTableCbk();
		this.props.updateStore({ officeTimeTable: timeTableData });
		this.props.jumpToStep(1);
	};

	render() {
		
		return (
			<React.Fragment>


				<Row className="step step3 mt-2 justify-content-md-center ">

					<Col lg="8">

						<Card className="rounded-0">

							<CardBody className="p-3 p-md-4 pt-5 pb-5">

								<p className=" text-center mb-4 mt- font-14">
									<b><FormattedMessage id="Weekly.Schedule" /></b>
									<br />
									<FormattedMessage id="Select.Office.Opening.And.Closing.Hours" />
									&nbsp;:
								</p>

								<OfficeTimetable
									addCbk={(cbk) => this.addTimeTableCbk(cbk)}
									data={this.props.getStore().officeTimeTable}
									register={true}
								/>

								<Row className="mt-5 mb-4">

									<div className="text-center w-100 ">
									{this.state.error === true &&
										<div className="profile-repl-details mb-3 bg-red customError">
											<FormattedMessage id="Calendar.Week.Work" />
										</div>
									}

										<div className="d-inline-block align-top mb-3 mb-sm-0 mx-auto">
											<div
												className={"btn btn-ladda lowercase srounded pl-5 pr-5 pt-2 pb-2 bg-blue text-white mx-auto"}
												onClick={() => this.back()}
												data-style={EXPAND_LEFT}>

												<FormattedHTMLMessage id="Previous.Step" />
											</div>
										</div>
										
                                        <div className="d-block d-sm-inline-block m-0 p-0 h-0">&nbsp;&nbsp;&nbsp;</div>

										<div className="d-inline-block align-top mx-auto">
											<div
												className={"btn srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green"}
												disabled={this.state.disabled}
												onClick={() => this.finish()}
												data-style={EXPAND_LEFT}>

												<FormattedHTMLMessage id="Next.Step" />
											</div>
										</div>

									</div>


								</Row>

							</CardBody>
						</Card>
					</Col>
				</Row>

			</React.Fragment>
		);
	}
}

