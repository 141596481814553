import DReplacements from '../views/doctors/replacements/DReplacements';
import SearchAvailability from '../views/doctors/availabilities/SearchAvailability';
import DReplacementProposals from '../views/doctors/availabilities/DReplacementProposals';
import Availabilities from '../views/substitutes/availabilities/Availabilities';
import SReplacements from '../views/substitutes/replacements/SReplacements';
import SearchReplacement from '../views/substitutes/replacements/SearchReplacement';
import SCalendar from '../views/substitutes/calendar/SCalendar';
import Home from '../views/Home';
import SubstituteAccountProfile from '../views/substitutes/profile/SubstituteAccountProfile';
import DoctorAccountProfile from '../views/doctors/profile/DoctorAccountProfile';
import SSettings from '../views/substitutes/settings/SSettings';
import DSettings from '../views/doctors/settings/DSettings';
import AccountingDashboard from '../views/accounting/AccountingDashboard';
import SHome from '../views/substitutes/SHome';
import DHome from '../views/doctors/DHome';
import PIHome from '../views/psychologist/PIHome';
import HomeSubscription from '../views/subscription/HomeSubscription';
import RegisterSubReplacement from '../views/subscription/RegisterSubReplacement';
import ClinicalStudy from '../views/clinicalStudy/ClinicalStudy';
import PsyIAccountProfile from '../views/psychologist/profile/PsyIAccountProfile';
import PsyISettings from '../views/psychologist/settings/PsyISettings';
import RHome from '../views/radiologist/RHome';
import RadiologistAccountProfile from '../views/radiologist/profile/RadiologistAccountProfile';
import RadiologistSettings from '../views/radiologist/settings/RadiologistSettings';
import PEHome from '../views/pediatricians/PEHome';
import PediatricianAccountProfile from '../views/pediatricians/profile/PediatricianAccountProfile';
import PediatricianSettings from '../views/pediatricians/settings/PediatricianSettings.js';
import PSHome from '../views/psychiatrists/PSHome';
import PsychiatristAccountProfile from '../views/psychiatrists/profile/PsychiatristAccountProfile';
import PsychiatristSettings from '../views/psychiatrists/settings/PsychiatristSettings.js';
import SecHome from '../views/secretaries/SecHome';
// import SecretaryAccountProfile from '../views/secretaries/profile/SecretaryAccountProfile';
import SecretarySettings from '../views/secretaries/settings/SecretarySettings.js';

var ConnectedRoutes = [
	{
		collapse: true,
		name: "Doctors",
		child: [
			{
				path: "/home/dhome",
				component: DHome
			},
			{
				path: "/home/dsettings/:category?",
				component: DSettings
			},
			{
				path: "/home/dprofile",
				component: DoctorAccountProfile
			},
			{
				path: "/home/accounting",
				component: AccountingDashboard
			},
			{
				path: "/home/me/replacements/:category?",
				component: DReplacements
			},
			{
				path: "/home/me/subscription",
				component: HomeSubscription,
				exact: true
			},
		]
	},
	{
		collapse: true,
		name: "Home",
		child: [
			{
				path: "/home",
				exact: true,
				component: Home
			}, {
				path: "/home/clinicalStudy",
				exact: true,
				component: ClinicalStudy
			}
		]
	},
	{
		collapse: true,
		name: "Substitutes",
		child: [
			{
				path: "/home/shome",
				component: SHome
			},
			{
				path: "/home/ssettings",
				component: SSettings
			},
			{
				path: "/home/sprofile",
				component: SubstituteAccountProfile
			},
			{
				path: "/home/availability/find",
				component: SearchAvailability
			},
			{
				path: "/home/availability/props",
				component: DReplacementProposals
			},
			{
				path: "/home/availability",
				component: Availabilities
			},
			{
				path: "/home/accounting",
				component: AccountingDashboard
			},
			{
				path: "/home/replacement/find",
				component: SearchReplacement
			},
			{
				path: "/home/replacements/:category?",
				component: SReplacements
			},
			{
				path: "/home/replacement/applied",
				component: SReplacements
			},
			{
				path: "/home/scalendar",
				component: SCalendar
			},
			{
				path: "/home/subscription",
				component: HomeSubscription,
				exact: true
			},
			{
				path: "/home/subscription/register/:category?",
				component: RegisterSubReplacement
			},
			// {
			// 	path: "/home/send-mail-rempla",
			// 	component: SendMails
			// }
		]
	},
	{
		collapse: true,
		name: "Secretaries",
		child: [
			{
				path: "/home/sechome",
				component: SecHome
			},
			{
				path: "/home/secsettings/:category?",
				component: SecretarySettings
			},
			{
				path: "/home/secprofile",
				component: SecretarySettings
			}
		]
	},
	{
		collapse: true,
		name: "PsychologistInstall",
		child: [
			{
				path: "/home/pihome",
				component: PIHome
			},
			{
				path: "/home/piprofile",
				component: PsyIAccountProfile
			},
			{
				path: "/home/pisettings",
				component: PsyISettings
			},
		]
	},
	{
		collapse: true,
		name: "RadiologistInstall",
		child: [
			{
				path: "/home/rhome",
				component: RHome
			},
			{
				path: "/home/rprofile",
				component: RadiologistAccountProfile
			},
			{
				path: "/home/rsettings",
				component: RadiologistSettings
			},
		]
	},
	{
		collapse: true,
		name: "PediatricianInstall",
		child: [
			{
				path: "/home/pehome",
				component: PEHome
			},
			{
				path: "/home/peprofile",
				component: PediatricianAccountProfile
			},
			{
				path: "/home/pesettings",
				component: PediatricianSettings
			},
		]
	},
	{
		collapse: true,
		name: "PsychiatristInstall",
		child: [
			{
				path: "/home/pshome",
				component: PSHome
			},
			{
				path: "/home/psprofile",
				component: PsychiatristAccountProfile
			},
			{
				path: "/home/pssettings",
				component: PsychiatristSettings
			},
		]
	},
];

export default ConnectedRoutes;
