


import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';


export default class UnexpectedErrorModal extends React.Component {

    getErrorMsg = (error) => {
        if (!error || !error.data || !error.data.message) return null;

        if (error.data.message.includes("[ERR001]")) return <FormattedMessage id="ERR001" />
        if (error.data.message.includes("[ERR002]")) return <FormattedMessage id="ERR002" />
        if (error.data.message.includes("[ERR003]")) return <FormattedMessage id="ERR003" />
        if (error.data.message.includes("[ERR004]")) return <FormattedMessage id="ERR004" />

        return null;
    }

    render() {
        const { error } = this.props;

        const errorMsg = this.getErrorMsg(error);

        return (
            <Modal isOpen={true} toggle={this.props.close} size="md" className="border border-danger rounded-ex">

                <ModalHeader toggle={this.props.close}><FormattedMessage id="Error" /></ModalHeader>

                <ModalBody>

                    {(errorMsg) ?
                        <>{errorMsg}</>
                        :
                        <>
                        <div className="w-100 text-center mb-4">
                            <i className="fa fa-exclamation-circle text-danger mx-auto" style={{ fontSize: "160px" }}></i>
                        </div>

                        <div className="w-100 text-center">
                            <FormattedMessage id="Unexpected.Error.Happened" />
                        </div>
                        </>
                    }

                </ModalBody>

                <ModalFooter>

                    <Button color="danger"
                        onClick={() => this.props.close()}>
                        <FormattedMessage id="Ok" />
                    </Button>

                </ModalFooter>

            </Modal>
        );

    }

}
