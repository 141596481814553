import APIUrl from "../APIUrl";
import axios from "axios";
import {
    updsAction,
    removesAction
} from "../global/global";
import FileUtil from '../../util/FileUtil'

const rootUrl = process.env.REACT_APP_BE_URL || "http://localhost:7000";

export const watchReplacement = (replacement, callback) => {
    return function (dispatch) {
        let replacementId = replacement._id;
        return axios.put(APIUrl.watchReplacement, { replacementId })
            .then(function (response) {
                if (response && response.data) {
                    let replacementMerge = Object.assign({}, replacement, response.data.message.replacement );
                    dispatch(updsAction("replacement", replacementMerge));
                    if (callback) callback(response.data.message.replacement);
                } 
            })
            .catch(function (err) {
                throw err;
            });
    };
};

export const applyToReplacement = (replacementId, callback) => {
    return function (dispatch) {
        return axios.put(APIUrl.applyToReplacement, replacementId)
            .then(function (response) {
                if (response && response.data) dispatch(updsAction("replacement", response.data.message.replacement));
                if (callback) callback();
            })
            .catch(function (err) {
                throw err;
            });
    };
};

export const downloadCertificate = (replacementId, doctorId, substituteId, total, cbk) => {

    return axios.get(APIUrl.getCertificate.replace("{{replacementId}}", replacementId)
            .replace("{{doctorId}}", doctorId)
            .replace("{{substituteId}}", substituteId)
            .replace("{{total}}", total), {
                responseType: "arraybuffer"
            })
        .then(function (response) {
            if (cbk) cbk();

            FileUtil.downloadPDF("certificate-" + replacementId, response.data);
        })
        .catch(function (err) {
            throw err;
        });
};

export const replacementOverlaps = function (start, end, type, duration, days_available, callback) {
        return axios.post(APIUrl.checkReplacementOverlap, {
            start,
            end,
            type,
            duration,
            days_available
        })
        .then(function (response) {
            if (callback) callback(response.data.message);
        })
        .catch(function (err) {
            throw err;
        });
};

export const declinePrivateRepl = function (replId, callback) {
    return function (dispatch) {
        return axios.get(APIUrl.declinePrivateRepl + replId)
            .then(function (response) {
                if (response && response.data) dispatch(removesAction("replacement", replId));
                if (callback) callback();
            })
            .catch(function (err) {
                throw err;
            });
    };
};

export const getReplacementCount = (cbk) => {
    return axios.get(APIUrl.getReplacementCount)
        .then(function (response) {
            if (cbk) cbk(response.data.message.replacementsCount);
        })
        .catch(function (err) {
            throw err;
        });
};

export const getReplacementInvitationCount = (cbk) => {
    return axios.get(APIUrl.getReplacementInvitationCount)
        .then(function (response) {
            if (cbk) cbk(response.data.message.replacementsInvitationCount);
        })
        .catch(function (err) {
            throw err;
        });
};

export const getReplacementWatchCount = (cbk) => {
    return axios.get(APIUrl.getReplacementWatchCount)
        .then(function (response) {
            if (cbk) cbk(response.data.message.replacementsWatchCount);
        })
        .catch(function (err) {
            throw err;
        });
};


export function allByDoctor() {
    return dispatch => {
		dispatch(request());
        var url = rootUrl + "/replacements/all-by-doctor";

        return axios.get(url)
            .then(function (response) {
                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "FIND_REPLACEMENTS_REQUEST", isGettingReplacements: true, isGetReplacements: false}}
	function success(result) { return { type: "FIND_REPLACEMENTS_SUCCESS", replacements: result.replacements, isGettingReplacements: false, isGetReplacements: true}}
	function failure() { return { type: "FIND_REPLACEMENTS_FAILURE", isGettingReplacements: false, isGetReplacements: false}}
};

export function allBySecretary(doctorId) {
    return dispatch => {
		dispatch(request());
        var url = rootUrl + "/replacements/all-by-secretary/" + doctorId;

        return axios.get(url)
            .then(function (response) {
                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "FIND_REPLACEMENTS_REQUEST", isGettingReplacements: true, isGetReplacements: false}}
	function success(result) { return { type: "FIND_REPLACEMENTS_SUCCESS", replacements: result.replacements, isGettingReplacements: false, isGetReplacements: true}}
	function failure() { return { type: "FIND_REPLACEMENTS_FAILURE", isGettingReplacements: false, isGetReplacements: false}}
};

export function deleteReplacement(replacementId) {
    return dispatch => {
		dispatch(request());
        var url = rootUrl + "/replacement/" + replacementId;

        return axios.delete(url)
            .then(function (response) {
                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "DELETE_REPLACEMENTS_REQUEST", isDeleteReplacement: false}}
	function success(result) { return { type: "DELETE_REPLACEMENTS_SUCCESS", isDeleteReplacement: true}}
	function failure() { return { type: "DELETE_REPLACEMENTS_FAILURE", isDeleteReplacement: false }}
};