export default function appointments(state = {}, action) {
	switch(action.type) {
		case "ADD_EXCEPTIONAL_CLOSE_REQUEST":
			return Object.assign({}, state, {
				addExceptionalClose: false
			});
		case "ADD_EXCEPTIONAL_CLOSE_SUCCESS":
			let exceptionalClose = Array.from(state.exceptionalClose);
			exceptionalClose.push(action.exceptionalClose.exceptionalClose);
			return Object.assign({}, state, {
				addExceptionalClose: true,
				exceptionalClose: exceptionalClose
			});
		case "ADD_EXCEPTIONAL_CLOSE_FAILURE":
			return Object.assign({}, state, {
				addExceptionalClose: false
			});
		case "GETS_EXCEPTIONAL_CLOSE_REQUEST":
			return Object.assign({}, state, {
				getExceptionalClose: false
			});
		case "GETS_EXCEPTIONAL_CLOSE_SUCCESS":
			return Object.assign({}, state, {
				getExceptionalClose: true,
				exceptionalClose: action.exceptionalClose.exceptionalCloses
			});
		case "GETS_EXCEPTIONAL_CLOSE_FAILURE":
			return Object.assign({}, state, {
				getExceptionalClose: false
			});
		case "EDIT_EXCEPTIONAL_CLOSE_REQUEST":
			return Object.assign({}, state, {
				editExceptionalClose: false
			});
		case "EDIT_EXCEPTIONAL_CLOSE_SUCCESS":
			let arrayExceptionalClose = Array.from(state.exceptionalClose);
			arrayExceptionalClose = arrayExceptionalClose.filter((exceptionalClose) => {
				return exceptionalClose._id !== action.exceptionalClose.exceptionalClose._id;
			})
			arrayExceptionalClose.push(action.exceptionalClose.exceptionalClose);

			return Object.assign({}, state, {
				editExceptionalClose: true,
				exceptionalClose: arrayExceptionalClose
			});
		case "EDIT_EXCEPTIONAL_CLOSE_FAILURE":
			return Object.assign({}, state, {
				editExceptionalClose: false
			});
		case "DELETE_EXCEPTIONAL_CLOSE_REQUEST":
			return Object.assign({}, state, {
				editExceptionalClose: false
			});
		case "DELETE_EXCEPTIONAL_CLOSE_SUCCESS":
			let arrayExceptionalCloseFilter = state.exceptionalClose.filter((exceptionalClose) => {
				return exceptionalClose._id !== action.exceptionalClose.exceptionalClose._id;
			})
			return Object.assign({}, state, {
				editExceptionalClose: true,
				exceptionalClose: arrayExceptionalCloseFilter
			});
		case "DELETE_EXCEPTIONAL_CLOSE_FAILURE":
			return Object.assign({}, state, {
				editExceptionalClose: false
			});
		case "GETS_EXCEPTIONAL_CLOSE_BY_DOCTORS_REQUEST":
			return Object.assign({}, state, {
				getExceptionalCloseByDoctors: false
			});
		case "GETS_EXCEPTIONAL_CLOSE_BY_DOCTORS_SUCCESS":
			return Object.assign({}, state, {
				getExceptionalCloseByDoctors: true,
				exceptionalClose: action.exceptionalClose.exceptionalCloses
			});
		case "GETS_EXCEPTIONAL_CLOSE_BY_DOCTORS_FAILURE":
			return Object.assign({}, state, {
				getExceptionalCloseByDoctors: false
			});
		default:
			return state;
	}
}