import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Form, NotEmpty } from '@gferrand/react-forms';
import CheckboxInput from '../../../components/form/CheckBoxInput';
import { EXPAND_LEFT } from 'react-ladda';
import { Link } from 'react-router-dom';
import SubmitButton from '../../../components/SubmitButton';


export default class SStep3 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            disabled: true,
            loading: false
        };

        const submitCbk = (formData) => {
            var callback = () => this.props.jumpToStep(3);

            this.setState({ loading: true });

            var data = this.props.getStore();
            data.user.accept_contacted = true;
            data.user.accept_contacted_clinicaltrials = true;
            // user.name = req.body.name[0].toUpperCase() + req.body.name.slice(1); 
            // user.first_name = req.body.first_name[0].toUpperCase() + req.body.first_name.slice(1); 

            data.user.name = data.user.name[0].toUpperCase() + data.user.name.slice(1); 
            data.user.first_name = data.user.first_name[0].toUpperCase() + data.user.first_name.slice(1); 
            
            delete data.user.confirmation_mot_de_passe;
            this.props.onAdd("user", data, callback);

            if (window.scrollTo) window.scrollTo(0, 0);
        };

        var studiesLevel = this.props.getStore().substituteCharacteristic.studies_level;

        const changeCbk = () => {
            const formData = this.form.getRawData();

            if (studiesLevel !== "2") this.setState({ disabled: !formData.assert_doctor });
            else this.setState({ disabled: !formData.assert_doctor });
        };

        var inputs = [
            new CheckboxInput("assert_doctor", "Assert.Doctor.Condition", [NotEmpty, MustBeTrue], { lClassName: "control-checkbox"}),
            //new CheckboxInput("assert_data", "Assert.Agree.Data.Condition", [NotEmpty, MustBeTrue], { lClassName: "control-checkbox"}),
        ];
        if (studiesLevel !== "2") {
            inputs = [
                new CheckboxInput("assert_doctor", "Assert.Doctor.Condition", [NotEmpty, MustBeTrue], { lClassName: "control-checkbox"}),
               // new CheckboxInput("assert_data", "Assert.Agree.Data.Condition", [NotEmpty, MustBeTrue], { lClassName: "control-checkbox"}),
                //new CheckboxInput("assert_license", "Assert.License.Condition", [NotEmpty, MustBeTrue], { lClassName: "control-checkbox"}),
            ];
        }

        this.form = new Form({
            name: "conditions",
            inputs,
            submitCbk,
            changeCbk,
            options: { validate: this.props.validate }
        });
    };

    back() {
        this.props.jumpToStep(1);
    };

    row(form, input) {
        if (!form.getInput(input)) return null;

        return (
            <Row className="form-group">
                <Col xs="1" className="cond-form">{form.getInput(input)}</Col>
                <Col xs="11" className="cond-form pl-4 pl-sm-0">{form.getLabel(input)}</Col>
            </Row>
        );
    }

    validate() {
        return false;
    }

    isValidated() {
		return this.validate(); 
	}

    render() {

        return (
            <React.Fragment>

                <Row className="step step3 mt-2 justify-content-md-center ">

                    <Col lg="8">

                        <Card className="rounded-0">

                            <CardBody className="p-5">

                                <div className="w-100 text-center mb-3">
                                    <h5><FormattedMessage id="Conditions.Use" /></h5>
                                </div>

                                {this.row(this.form, "assert_doctor")}
                                {/* {this.row(this.form, "assert_data")} */}
                                {/* {this.row(this.form, "assert_license")} */}
                                {/* {this.row(this.form, "accept_contacted")} */}
                                
                                <div><i>Cliquez <Link to="/terms" target="_blank">ici</Link> pour consulter les CGV / CGU</i></div>

                                <Row className="mt-5">

                                    <div className="text-center w-100 ">

                                        <div className="d-inline-block align-top mb-3 mb-sm-0">
                                            <div
                                                className={"btn btn-ladda lowercase srounded pl-5 pr-5 pt-2 pb-2 bg-blue text-white mx-auto"}
                                                onClick={() => this.back()}
                                                data-style={EXPAND_LEFT}>

                                                <FormattedHTMLMessage id="Previous.Step" />
                                            </div>
                                        </div>
                                        
                                        <div className="d-block d-sm-inline-block m-0 p-0 h-0">&nbsp;&nbsp;&nbsp;</div>

                                        <div className="d-inline-block align-top">
                                            <SubmitButton loading={this.state.loading} disabled={this.state.disabled} form={this.form}/>
                                        </div>

                                    </div>

                                </Row>

                            </CardBody>

                        </Card>

                    </Col>

                </Row>

            </React.Fragment>
        );
    }
}

class MustBeTrue {

    static name() {
        return "MustBeTrue";
    }

    static valid(value) {
        return value === true;
    }

    static error() {
        return null;
    }

    static toJoi() {
        return ".boolean().invalid(false)";
    }

}