import React from 'react';
import { FormattedMessage } from 'react-intl';

import { InputComposer, InputComponent } from '@gferrand/react-forms';

export default class ImgedSwitchInput extends InputComposer {

    constructor(name, label, validators = [], options = {}) {
        super(name, label, validators, options);

        this.initialize(false);
    }

    mongoType() {
        var options = [];
        for (let choice of this.choices) options.push("'" + choice.value + "'");
        return { enum: options };
    }

    toJoi() {
        var options = '';
        for (let choice of this.choices) options += '"' + choice.value + '", ';
        if (options[options.length - 1] === " ") options = options.substring(0, options.length - 2);
        return "string().valid(" + options + ")";
    }

    getComponent() {
        return <ImgedSwitchInputComponent choices={this.choices} label={this.label} />;
    }

}

class ImgedSwitchInputComponent extends InputComponent {

    render() {
        const { options, name, label } = this.props;

        return super.wrap(
            <div className="round-elem d-inline-block clickable" style={{ opacity: (this.state.value) ? 1 : .4 }}>
                <div onClick={() => {
                        if (this.state.disabled || options.disabled) return;
                        this.onChange(!this.state.value);
                        this.onBlur(name, !this.state.value)
                    }}>
                    <img src={options.img} alt="" width="50" height="50" />
                </div>
                <div className="title mt-2"><FormattedMessage id={label} /></div>
            </div>
        );
    }

}