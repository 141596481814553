var values = [
  { label: "Settlement.Less.2.Years", value: "0" },
  { label: "Settlement.3.To.5.Years", value: "1" },
  { label: "Settlement.No",           value: "2" },
  { label: "Settlement.Don't.Know",   value: "3" },
];

export default class SubstituteSettlementPreference {

  static values() {
    return values;
  }

  static getLabel(nbr) {
    for (let value of values) {
      if (value.value === nbr) return value.label;
    }
  }

};