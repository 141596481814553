import React from 'react';
import { injectIntl } from 'react-intl';
import 'react-tagsinput/react-tagsinput.css';
import { connect } from 'react-redux';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css'
import { Card, CardBody, FormGroup, Label, Col, Input } from "reactstrap";
import { FormattedMessage } from 'react-intl';
import { upd } from "../../../actions/global/global";
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import Noty from 'noty';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from 'moment';
import DOfficeModal from '../../doctors/office/DOfficeModal';
import DateUtil from "../../../util/DateUtil";
import Duration from '../../../enum/Duration';
import { editDuration } from '../../../actions/doctorCharacteristic/doctorCharacteristic';

const localizer = momentLocalizer(moment);

class OfficeTimetable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            disabled: false,
            modal: null,
            events: [],
            newEvents: [],
            compteurOpenBy: 0,
            duration: '15'
        };

        this.timeTableCbk = null;
        this.deleteEventTemp = this.deleteEventTemp.bind(this);
        this.deleteEventBdd = this.deleteEventBdd.bind(this);
        this.allDays = this.allDays.bind(this);
        this.allDaysEmpty = this.allDaysEmpty.bind(this);
        this.loadEvents = this.loadEvents.bind(this);
        this.updateAvailability = this.updateAvailability.bind(this);
        this.formatData = this.formatData.bind(this);
        this.customToolBar = this.customToolBar.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        // Dans le cas d'une édition
        if (this.props.officeTimeTable) {
            this.loadEvents(false);
        }
        // Dans le cas d'une inscription
        if (this.props.data) {
            this.loadEvents(true);
        }

        if (this.props.doctorCharacteristic) {
            if (this.props.doctorCharacteristic.duration) {
                this.setState({
                    duration: this.props.doctorCharacteristic.duration
                })
            }
        }
    }

    componentDidUpdate(prevProps) {
        // Dans le cas d'une edition
        if (prevProps.officeTimeTable !== this.props.officeTimeTable) {
            this.loadEvents(false);
        }
        if (prevProps.data !== this.props.data) {
            this.loadEvents(true);
        }

        if (prevProps.doctorCharacteristic !== this.props.doctorCharacteristic) {
            if (this.props.doctorCharacteristic.duration) {
                this.setState({
                    duration: this.props.doctorCharacteristic.duration
                })
            }
        }
    }

    handleChange(event) {
        let { name, value } = event.currentTarget;

        this.setState({
            [name]: value
        })
    }

    addTimeTableCbk(cbk) {
        if (!this.timeTableCbk) this.timeTableCbk = cbk;
    }

    // Charge les horaires du cabinet
    loadEvents(temp) {
        let events = [];
        let nameDay = [
            "monday", 
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday"
        ];
        let day = 6;
        let compteurJour = 0;
        let numDay = 0;
        let nameStart;
        let nameEnd;
        // Boucle sur les 6 jours de la semaine matin et aprem
        for (let pas = 0; pas < 12; pas++) {
            var start;
            var end;
            // Dans le 2eme rdv d'une journée
            if (compteurJour > 0) {
                nameStart = nameDay[numDay]+ 'AfternoonStart';
                nameEnd = nameDay[numDay] + 'AfternoonEnd';

                if (temp === false) {
                    start = this.props.officeTimeTable[nameStart];
                    end = this.props.officeTimeTable[nameEnd];
                } else {
                    start = this.props.data[nameStart];
                    end = this.props.data[nameEnd];
                }

                events.push({
                    start: moment("2020-01-" + day + "T" + start + ":00.000Z").subtract(1, 'hours').toDate(),
                    end: moment("2020-01-" + day + "T" + end + ":00.000Z").subtract(1, 'hours').toDate(),
                    allDay: false,
                    day: nameDay[numDay],
                    period: 'afternoon',
                    temp: temp
                })
                day++;
                numDay++;
                compteurJour = 0;
            // 1er rdv d'une journée
            } else {
                if (day < 10) {
                    day = '0' + day;
                } 
                nameStart = nameDay[numDay] + 'MorningStart';
                nameEnd = nameDay[numDay] + 'MorningEnd';

                if (temp === false) {
                    start = this.props.officeTimeTable[nameStart];
                    end = this.props.officeTimeTable[nameEnd];
                } else {
                    start = this.props.data[nameStart];
                    end = this.props.data[nameEnd];
                }

                events.push({
                    start: moment("2020-01-" + day + "T" + start + ":00.000Z").subtract(1, 'hours').toDate(),
                    end: moment("2020-01-" + day + "T" + end + ":00.000Z").subtract(1, 'hours').toDate(),
                    allDay: false,
                    day: nameDay[numDay],
                    period: 'morning',
                    temp
                })
                compteurJour++;
            }
        }

        if (temp === false) {
            this.setState({ events, newEvents: [] })
        } else {
            this.setState({ events: [], newEvents: events })
        }
    }

    // Fonction pour enregistrer les modifications sur le calendrier
    update() {
        this.setState({ disabled: true });

        // Affiche le message de succès
        var callback = () => {
            this.setState({ disabled: false });

            new Noty({
                type: "info",
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: this.props.intl.formatMessage({ id: "OfficeTimeTable.Successfuly.Updated" }),
                timeout: 6000,
            }).show();
        }

        var events = Array.from(this.state.events);
        var newEvents = Array.from(this.state.newEvents);
        var timeTableData = events.concat(newEvents);

        var data = {};

        let compteur = {
            "monday": 0, 
            "tuesday": 0,
            "wednesday": 0,
            "thursday": 0,
            "friday": 0,
            "saturday": 0
        }

        let nameStart;
        let nameEnd;
        // Boucle sur les events de l'agenda
        timeTableData.forEach((elem) => {
            // Si les dates sont valides et les jours correspondent
            if ((moment(elem.start).isValid() === true || moment(elem.end).isValid() === true) && Object.keys(compteur).includes(elem.day)) {
                if (compteur[elem.day] === 0) {
                    compteur[elem.day]++;
                    nameStart = elem.day + 'MorningStart';
                    nameEnd = elem.day + 'MorningEnd';
                    data[nameStart] = moment(elem.start).format('HH:mm')
                    data[nameEnd] = moment(elem.end).format('HH:mm')
                } else {
                    compteur[elem.day]++;
                    nameStart = elem.day + 'AfternoonStart';
                    nameEnd = elem.day + 'AfternoonEnd';
                    data[nameStart] = moment(elem.start).format('HH:mm')
                    data[nameEnd] = moment(elem.end).format('HH:mm')
                }
            } 
        });

        // Ajout de l'id du medecin
        data._id = this.props.officeTimeTable._id;

        this.props.onUpd("officeTimeTable", data, callback);

        let duration = {
            duration: this.state.duration,
            userId: this.props.officeTimeTable.doctor_id
        }
        this.props.editDuration(duration);
    }

    // Customise la toolbar
    customToolBar() {
        // Si c'est une édition
        if (this.props.register === false) {
            return (
                <div>
                    <div className="rbc-toolbar">
                        <span className="rbc-btn-group dateSCalendar">
                            <span className="dateEnteteSCalendar"><FormattedMessage id="My.Std.Schedule" /></span>
                        </span>
                    </div>
                </div>
            );
        // Dans une nouvelle inscription
        } else {
            return (
                <div></div>
            )
        }
    }

    // Customise l'affichage des jours
    customViewDay(arg) {
        let day;
        if (arg.label === "06 lun.") {
            day = <FormattedMessage id="Monday" />
        } else if (arg.label === "07 mar.") {
            day = <FormattedMessage id="Tuesday" />
        } else if (arg.label === "08 mer.") {
            day = <FormattedMessage id="Wednesday" />
        } else if (arg.label === "09 jeu.") {
            day = <FormattedMessage id="Thursday" />
        } else if (arg.label === "10 ven.") {
            day = <FormattedMessage id="Friday" />
        } else if (arg.label === "11 sam.") {
            day = <FormattedMessage id="Saturday" />
        } else if (arg.label === "12 dim.") {
            day = <FormattedMessage id="Sunday" />
        } 

        return (
            <div>
                { day }
            </div>
        );
    }

    // mets à jour une dispo depuis la modal
    updateAvailability(start, end, oldStart, oldEnd, temp) {
        var events = Array.from(this.state.events);
        var newEventsState = Array.from(this.state.newEvents);
        var timeTableData = events.concat(newEventsState);

        // Permet de retrouver l'index dans le tableau du rdv à mettre à jour
        let indexNumber = timeTableData.findIndex((elem) => {
			return elem.start === oldStart && elem.end === oldEnd;
        });

        let chevauchement = false;

        timeTableData.forEach((event, key) => {
            // Compare la date du jour et exclu avec l'index le rdv déjà existant
            if (moment(event.start).day() === moment(start).day() && key !== indexNumber) {
                // Vérifie que deux période ne puisse pas se chevaucher
                if (moment(event.start) < moment(end) && moment(event.end) > moment(start)) {
                    chevauchement = true;
                    return;
                }
            }
        });

        if (chevauchement === true) {
            return false;
        }

        if (temp === true) {
            let indexNumber = newEventsState.findIndex((elem) => {
                return elem.start === oldStart && elem.end === oldEnd;
            });
            newEventsState[indexNumber].start = moment(start).toDate();
            newEventsState[indexNumber].end = moment(end).toDate();

            this.setState({
                newEvents: newEventsState
            })
        } else {
            let indexNumber = events.findIndex((elem) => {
                return elem.start === oldStart && elem.end === oldEnd;
            });

            events[indexNumber].start = moment(start).toDate();
            events[indexNumber].end = moment(end).toDate();
            this.setState({
                events: events
            })
        }

        return true;
    }

    // Rajoute les évènements dans le state
    selectAvailability(start, end) {
        let day;
        if (moment(start).day() === 1) {
            day = 'monday'
        } else if (moment(start).day() === 2) {
            day = 'tuesday'
        } else if (moment(start).day() === 3) {
            day = 'wednesday'
        } else if (moment(start).day() === 4) {
            day = 'thursday'
        } else if (moment(start).day() === 5) {
            day = 'friday'
        } else if (moment(start).day() === 6) {
            day = 'saturday'
        // Impossible de poser une ouverture de cabinet le dimanche
        } else if (moment(start).day() === 0) {
            return;
        }

        // Vérifie que l'on ne puisse pas avoir plus de 2 rdv par jours
        let compteur = 0;
        let chevauchement = false;
        var timeTableData = this.state.events.concat(this.state.newEvents);
        timeTableData.forEach((event) => {
            if (moment(event.start).day() === moment(start).day()) {
                compteur++;
                // Vérifie que deux période ne puisse pas se chevaucher
                if (moment(event.start) < end && moment(event.end) > start) {
                    chevauchement = true;
                }
            }
        });

        if (compteur >= 2 || chevauchement === true) {
            return;
        }

        let newEvents = this.state.newEvents;
        var period;
        if (compteur > 0) {
            period = 'afternoon'
        } else {
            period = 'morning'
        }

        const duration = moment(end).diff(moment(start), 'minutes')
        // Si c'est le 1er ou 2eme rdv qu'on pose et que le state events est vide -> donc inscription
        if ((this.state.compteurOpenBy === 0 || this.state.compteurOpenBy === 1 || duration <= 30) && this.props.register === true) {
            let event = {
                start: start,
                end: end,
                allDay: false,
                day: day,
                period: period,
                temp: true,
            }
            this.eventModal(event);
            //To avoid close on open for mobile view 
            this.setState({
                disableClose: true
            })
            setTimeout(() => {
                this.setState({
                    disableClose: false
                })
            }, 300)
        }

        newEvents.push({
            start: start,
            end: end,
            allDay: false,
            day: day,
            period: period,
            temp: true,
            openBy: true
        })

        this.setState({ newEvents })
        // Dans le cas d'une inscription
        if (this.props.register === true) {
            // Permet de savoir combien le user a posé lui-même des dispos
            let compteurOpenBy = this.state.compteurOpenBy + 1;
            this.setState({ compteurOpenBy })
        }
    }

    // Supprime un event dans le state qui est temporaire
    deleteEventTemp(event) {
        // Filtre la liste en éliminant l'évènement correspondant puis maj le state
        let newList = Array.from(this.state.newEvents.filter((newEvents) => {
			return newEvents.start !== event.start && newEvents.end !== event.end;
        }));
        this.setState({
            newEvents: newList
        })
    }

    // Supprime un event dans le state qui provient de la BDD
    deleteEventBdd(eventDelete) {
        // Filtre la liste en éliminant l'évènement correspondant puis maj le state
        let newList = Array.from(this.state.events.filter((event) => {
			return event.start !== eventDelete.start && event.end !== eventDelete.end;
        }));
        
        this.setState({
            events: newList
        })
    }

    closeModal() {
        if(!this.state.disableClose) this.setState({ modal: null });
    };

    // Compte les dispos par jour
    compteurDays(timeTableData) {
        let compteur = {
            "monday": 0, 
            "tuesday": 0,
            "wednesday": 0,
            "thursday": 0,
            "friday": 0,
        }
        // Boucle sur les dispos existantes
        timeTableData.forEach((event) => {
            if (moment(event.start).isValid() === true) {
                // Comptez le nombre de rdv par jours
                if (event.day === 'monday') {
                    compteur['monday']++;
                } else if (event.day === 'tuesday') {
                    compteur['tuesday']++;
                } else if (event.day === 'wednesday') {
                    compteur['wednesday']++;
                } else if (event.day === 'thursday') {
                    compteur['thursday']++;
                } else if (event.day === 'friday') {
                    compteur['friday']++;
                } 
            }
        });

        return compteur;
    }

    // Fonction pour rajouter les dispos uniquement si y'en a pas
    // Plus utiliser pour le moment
    allDaysEmpty(start, end) {
        let newEvents = [];
        // Récupère tous les jours de la semaines
        var events = Array.from(this.state.events);
        var newEventsState = Array.from(this.state.newEvents);
        var timeTableData = events.concat(newEventsState);

        // Appel la fonction qui compte les dispos par jours
        const compteur = this.compteurDays(timeTableData);

        let nameDay = {
            "monday": '06', 
            "tuesday": '07',
            "wednesday": '08',
            "thursday": '09',
            "friday": '10',
            "saturday" : '11'
        };

        for (let [key, value] of Object.entries(nameDay)) {
            // Si pas de rdv sur la journée, on peut rajouter directement
            if (compteur[key] === 0) {
                newEvents.push({
                    start: moment("2020-01-" + value + "T" + start + ":00.000Z").subtract(1, 'hours').toDate(),
                    end: moment("2020-01-" + value + "T" + end + ":00.000Z").subtract(1, 'hours').toDate(),
                    allDay: false,
                    day: key,
                    temp: true
                });
            // S'il y'a deja 1 ou 2 rdv, on compare la date existante avec la nouvelle
            } else if (compteur[key] === 1) {
                // Convertir le début et la fin à la date du jour concerné
                let startConvert = moment("2020-01-" + value + "T" + start + ":00.000Z").subtract(1, 'hours').toDate()
                let endConvert = moment("2020-01-" + value + "T" + end + ":00.000Z").subtract(1, 'hours').toDate()
                timeTableData.forEach((elem) => {
                    // Retourne les journées concerné avec une date de début valide
                    if (elem.day === key && moment(elem.start).isValid() === true) {
                        // Si les dispos NE se chevauchent PAS, on ajoute la nouvelle dispo
                        if (moment(elem.start) > endConvert || moment(elem.end) < startConvert) {
                            newEvents.push({
                                start: moment("2020-01-" + value + "T" + start + ":00.000Z").subtract(1, 'hours').toDate(),
                                end: moment("2020-01-" + value + "T" + end + ":00.000Z").subtract(1, 'hours').toDate(),
                                allDay: false,
                                day: key,
                                temp: true
                            });
                        }
                    }
                })
            }
        }
        // Pour ajouter les nouveaux rdv aux existants;
        let eventsConcat = timeTableData.concat(newEvents);
        this.setState({ newEvents: eventsConcat, events: [] })
        this.closeModal();
    }

    // Fonction pour dupliquer un rdv à tous les jours de la semaine
    allDays(start, end, numberDay) {
        let newEvents = [];

        var events = Array.from(this.state.events);
        var newEventsState = Array.from(this.state.newEvents);
        var timeTableData = events.concat(newEventsState);

        // Appel la fonction qui compte les dispos par jours
        const compteur = this.compteurDays(timeTableData);

        let nameDay = {};

        // Permet de savoir à partir de quel jours de la semaine on commence
        if (numberDay === 1) {
            nameDay = {
                "monday": '06', 
                "tuesday": '07',
                "wednesday": '08',
                "thursday": '09',
                "friday": '10',
            };
        } else if (numberDay === 2) {
            nameDay = {
                "tuesday": '07',
                "wednesday": '08',
                "thursday": '09',
                "friday": '10',
            };
        } else if (numberDay === 3) {
            nameDay = {
                "wednesday": '08',
                "thursday": '09',
                "friday": '10',
            };
        } else if (numberDay === 4) {
            nameDay = {
                "thursday": '09',
                "friday": '10',
            };
        } 

        for (let [key, value] of Object.entries(nameDay)) {
            // Si pas de rdv sur la journée, on peut rajouter directement
            if (compteur[key] === 0) {
                newEvents.push({
                    start: moment("2020-01-" + value + "T" + start + ":00.000Z").subtract(1, 'hours').toDate(),
                    end: moment("2020-01-" + value + "T" + end + ":00.000Z").subtract(1, 'hours').toDate(),
                    allDay: false,
                    day: key,
                    temp: true
                });
            // S'il y'a deja 1 rdv, on compare la date existante avec la nouvelle
            } else if (compteur[key] === 1) {
                // Convertir le début et la fin à la date du jour concerné
                let startConvert = moment("2020-01-" + value + "T" + start + ":00.000Z").subtract(1, 'hours').toDate()
                let endConvert = moment("2020-01-" + value + "T" + end + ":00.000Z").subtract(1, 'hours').toDate()
                timeTableData.forEach((elem, keyTimeTableData) => {
                    // Retourne les journées concerné avec une date de début valide
                    if (elem.day === key && moment(elem.start).isValid() === true) {
                        // Si les dispos se chevauchent on remplace par la nouvelle dispo
                        if (moment(elem.start) < endConvert && moment(elem.end) > startConvert) {
                            // Supprime l'ancienne dispo
                            timeTableData.splice(keyTimeTableData, 1);
                            newEvents.push({
                                start: moment("2020-01-" + value + "T" + start + ":00.000Z").subtract(1, 'hours').toDate(),
                                end: moment("2020-01-" + value + "T" + end + ":00.000Z").subtract(1, 'hours').toDate(),
                                allDay: false,
                                day: key,
                                temp: true
                            });
                        } else {
                            // Si elles ne se chevauchent pas, on ajoute la nouvelle dispo
                            newEvents.push({
                                start: moment("2020-01-" + value + "T" + start + ":00.000Z").subtract(1, 'hours').toDate(),
                                end: moment("2020-01-" + value + "T" + end + ":00.000Z").subtract(1, 'hours').toDate(),
                                allDay: false,
                                day: key,
                                temp: true
                            });
                        }
                    }
                })
            // Si 2 rdv deja sur la journée
            } else if (compteur[key] === 2) {
                // Convertir le début et la fin à la date du jour concerné
                let startConvert = moment("2020-01-" + value + "T" + start + ":00.000Z").subtract(1, 'hours').toDate()
                let endConvert = moment("2020-01-" + value + "T" + end + ":00.000Z").subtract(1, 'hours').toDate()

                // Retourne les 2 dispos d'une meme journée
                let newList = Array.from(timeTableData.filter((dayFilter) => {
                    return dayFilter.day === key && moment(dayFilter.start).isValid() === true
                }))

                let superpose = 0;
                newList.forEach((elem) => {
                    if (moment(elem.start) < endConvert && moment(elem.end) > startConvert) {
                        superpose++;
                    }
                })

                // Si aucune dispo se superpose
                if (superpose === 0) {
                    let valueObj = {
                        valueDebut1: null,
                        valueFin1: null,
                        valueDebut2: null,
                        valueFin2: null,
                    }
                    // Boucle sur les dispo de la journée
                    newList.forEach((elem, keyNewList) => {
                        // Si la fin de mon rdv à ajouter est avant le début du 1er, on supprime le 1er
                        let deleteFirstDispo = DateUtil.beforeNoStrict(endConvert, elem.start);
                        // Si le début de mon rdv à ajouter est après la fin du 2eme, on supprime le 2eme
                        let deleteLastDispo = DateUtil.afterNoStrict(startConvert, elem.end);

                        if (keyNewList === 0) {
                            valueObj.valueDebut1 = deleteFirstDispo
                            valueObj.valueFin1 = deleteLastDispo
                        } else {
                            valueObj.valueDebut2 = deleteFirstDispo
                            valueObj.valueFin2 = deleteLastDispo
                        }
                    });

                    timeTableData.forEach((elem, keyTimeTableData) => { 
                        if (elem.day === key && moment(elem.start).isValid() === true) {
                            // Entre les deux dispos
                            if (valueObj.valueDebut1 === false && valueObj.valueDebut2 === true && valueObj.valueFin1 === true && valueObj.valueFin2 === false) {

                                // Converti les heures pour quelle soit exploitable par moment
                                let startConvertHour = moment(start, 'HH:mm').toDate();
                                let endConvertHour = moment(end, 'HH:mm').toDate();

                                let thirteenHours = moment('13:00', 'HH:mm').toDate();
                                
                                // S'il débute avant 13h00 et finit apres 13h00, on supprime les 2 dispos par la nouvelle
                                if (moment(startConvertHour).isBefore(thirteenHours) && moment(endConvertHour).isAfter(thirteenHours)) {
                                    // Supprime les 2 dispos de la journée et rajoute la nouvelle dispo
                                    timeTableData.splice(keyTimeTableData, 2);
                                    newEvents.push({
                                        start: moment("2020-01-" + value + "T" + start + ":00.000Z").subtract(1, 'hours').toDate(),
                                        end: moment("2020-01-" + value + "T" + end + ":00.000Z").subtract(1, 'hours').toDate(),
                                        allDay: false,
                                        day: key,
                                        temp: true
                                    });
                                // Si débute avant 13h00 -> on remplace la dispo du matin
                                } else if (moment(startConvertHour).isBefore(thirteenHours)) {
                                    // Supprime la 1 dispoe de la journée et rajoute la nouvelle dispo
                                    timeTableData.splice(keyTimeTableData, 1);
                                    newEvents.push({
                                        start: moment("2020-01-" + value + "T" + start + ":00.000Z").subtract(1, 'hours').toDate(),
                                        end: moment("2020-01-" + value + "T" + end + ":00.000Z").subtract(1, 'hours').toDate(),
                                        allDay: false,
                                        day: key,
                                        temp: true
                                    });
                                // Si débute après 13h00 -> on remplace la dispo de l'aprem
                                } else if (moment(startConvertHour).isSameOrAfter(thirteenHours)) {
                                    // Supprime la 2eme dispos de la journée et rajoute la nouvelle dispo
                                    timeTableData.splice(keyTimeTableData + 1, 1);
                                    newEvents.push({
                                        start: moment("2020-01-" + value + "T" + start + ":00.000Z").subtract(1, 'hours').toDate(),
                                        end: moment("2020-01-" + value + "T" + end + ":00.000Z").subtract(1, 'hours').toDate(),
                                        allDay: false,
                                        day: key,
                                        temp: true
                                    });
                                }
                            // On supprime la 1ere dispo
                            } else if (valueObj.valueDebut1 === true && valueObj.valueDebut2 === true && valueObj.valueFin1 === false && valueObj.valueFin2 === false) {
                                timeTableData.splice(keyTimeTableData, 1);
                                newEvents.push({
                                    start: moment("2020-01-" + value + "T" + start + ":00.000Z").subtract(1, 'hours').toDate(),
                                    end: moment("2020-01-" + value + "T" + end + ":00.000Z").subtract(1, 'hours').toDate(),
                                    allDay: false,
                                    day: key,
                                    temp: true
                                });
                            // On supprime la 2eme dispo
                            } else if (valueObj.valueDebut1 === false && valueObj.valueDebut2 === false && valueObj.valueFin1 === true && valueObj.valueFin2 === true) {
                                timeTableData.splice(keyTimeTableData + 1, 1);
                                newEvents.push({
                                    start: moment("2020-01-" + value + "T" + start + ":00.000Z").subtract(1, 'hours').toDate(),
                                    end: moment("2020-01-" + value + "T" + end + ":00.000Z").subtract(1, 'hours').toDate(),
                                    allDay: false,
                                    day: key,
                                    temp: true
                                });
                            }
                        } 
                    });
                // Si 1 dispo se superpose, on la remplace par la nouvelle
                } else if (superpose === 1) {
                    // Remplacer l'ancienne dispo par la nouvelle
                    timeTableData.forEach((elem, keyTimeTableData) => {
                        // Retourne les journées concerné avec une date de début valide
                        if (elem.day === key && moment(elem.start).isValid() === true) {
                            if (moment(elem.start) < endConvert && moment(elem.end) > startConvert) {
                                // Supprime l'ancienne dispo
                                timeTableData.splice(keyTimeTableData, 1);
                                newEvents.push({
                                    start: moment("2020-01-" + value + "T" + start + ":00.000Z").subtract(1, 'hours').toDate(),
                                    end: moment("2020-01-" + value + "T" + end + ":00.000Z").subtract(1, 'hours').toDate(),
                                    allDay: false,
                                    day: key,
                                    temp: true
                                });
                            }
                        }
                    });
                // Si 2 dispo se superpose, on remplace les 2 par la nouvelle
                } else if (superpose === 2) {
                    timeTableData.forEach((elem, keyTimeTableData) => { 
                        if (elem.day === key && moment(elem.start).isValid() === true) {
                            // Supprime les 2 dispos de la journée et rajoute la nouvelle dispo
                            timeTableData.splice(keyTimeTableData, 2);
                            newEvents.push({
                                start: moment("2020-01-" + value + "T" + start + ":00.000Z").subtract(1, 'hours').toDate(),
                                end: moment("2020-01-" + value + "T" + end + ":00.000Z").subtract(1, 'hours').toDate(),
                                allDay: false,
                                day: key,
                                temp: true
                            });
                        }
                    });
                }
            }
        }

        // toutes les dispos deviennent des nouveautés
        timeTableData.forEach((elem) => {
            elem.temp = true;
        })
        // Pour ajouter les nouveaux rdv aux existants;
        let eventsConcat = timeTableData.concat(newEvents);

        this.setState({ newEvents: eventsConcat, events: [] })
        this.closeModal();
    }

    // Ouvre la modal
    eventModal(event) {
        this.setState({
            modal: <DOfficeModal
                event={event}
                deleteEventTemp={this.deleteEventTemp}
                deleteEventBdd={this.deleteEventBdd}
                close={() => this.closeModal()}
                allDays={this.allDays}
                allDaysEmpty={this.allDaysEmpty}
                updateAvailability={this.updateAvailability}
                register={this.props.register}
            />
        });
    }

    // Grise le dimanches
    customDayPropGetter(date) {
        if (date.getDay() === 0) {
            return {
                className: 'rbc-sunday',
            };
        }
    };

    // Fonction pour formater les données dans le cas d'une nouvelle inscription
    formatData() {
        // Comme c'est une nouvelle inscription, on retrouve les dispos uniquement dans ce tableau
        var newEvents = Array.from(this.state.newEvents);

        // Récupère le nombre total d'heures sur la semaine
        let totalHours = this.totalHours();
        var data = {
            totalHours: totalHours,
            duration: this.state.duration
        };

        let compteur = {
            "monday": 0, 
            "tuesday": 0,
            "wednesday": 0,
            "thursday": 0,
            "friday": 0,
            "saturday": 0
        }

        let nameStart;
        let nameEnd;
        // Boucle sur les events de l'agenda
        newEvents.forEach((elem) => {
            // Si les dates sont valides et les jours correspondent
            if ((moment(elem.start).isValid() === true || moment(elem.end).isValid() === true) && Object.keys(compteur).includes(elem.day)) {
                if (compteur[elem.day] === 0) {
                    compteur[elem.day]++;
                    nameStart = elem.day + 'MorningStart';
                    nameEnd = elem.day + 'MorningEnd';
                    data[nameStart] = moment(elem.start).format('HH:mm')
                    data[nameEnd] = moment(elem.end).format('HH:mm')
                } else {
                    compteur[elem.day]++;
                    let end = data[elem.day + 'MorningEnd']
                    if(moment(elem.start, "HH:mm").isSameOrAfter(end, "minute")){
                        nameStart = elem.day + 'AfternoonStart';
                        nameEnd = elem.day + 'AfternoonEnd';
                        data[nameStart] = moment(elem.start).format('HH:mm')
                        data[nameEnd] = moment(elem.end).format('HH:mm')
                    } else {
                        nameStart = elem.day + 'AfternoonStart';
                        nameEnd = elem.day + 'AfternoonEnd';
                        data[nameStart] = data[elem.day + 'MorningStart']
                        data[nameEnd] = data[elem.day + 'MorningEnd']
                        data[elem.day + 'MorningStart'] = moment(elem.start).format('HH:mm')
                        data[elem.day + 'MorningEnd'] = moment(elem.end).format('HH:mm')
                    }
                    
                }
            } 
        });

        Object.keys(compteur).forEach((day) => {
            if(data[day + 'AfternoonStart']) return
            if(moment(data[day + 'MorningStart'], "HH:mm").isSameOrBefore(moment("12:00", "HH:mm"))) return
            data[day + 'AfternoonStart'] = data[day + 'MorningStart']
            data[day + 'AfternoonEnd'] = data[day + 'MorningEnd']
            delete data[day + 'MorningStart']
            delete data[day + 'MorningEnd']
        })
        return data;
    }

    // Calcul le nombre total d'heures
    totalHours() {
        var events = Array.from(this.state.events);
        var newEventsState = Array.from(this.state.newEvents);
        var timeTableData = events.concat(newEventsState);
        let totalHours = 0;

        timeTableData.forEach((elem) => {
            if (moment(elem.start).isValid() === true && moment(elem.end).isValid() === true) {
                let start = moment(elem.start);
                let end = moment(elem.end)
                var duration = moment.duration(end.diff(start));
                var hours = duration.asHours();
                totalHours += hours;
            }
        })
        return totalHours;
    }

    render() {
        // Pour une nouvelle inscription
        if (this.props.addCbk) this.props.addCbk(() => this.formatData());

        const events = this.state.events.concat(this.state.newEvents);
        const totalHours = this.totalHours();

        return (
            <React.Fragment>
                <Card className="rounded-0">
                    <div className="row">
                        <div className="pt-3 m-3 col-sm-12 d-block d-xl-none">
                            Si vous êtes sur téléphone, veuillez garder le doigt appuyer pour ajouter un créneau.
                        </div>

                        <div className="pb-5 col-sm-2 offset-sm-10 col-md-2 offset-md-10">
                            <span>{totalHours} </span><FormattedMessage id="Hours" />
                        </div>
                    </div>

                    <CardBody className="pl-4 pr-4">

                        <Calendar
                            components={{ 
                                toolbar: this.customToolBar, 
                                header: this.customViewDay
                            }}
                            renderable
                            selectable
                            showMultiDayTimes={true}
                            step={30}
                            localizer={localizer}
                            defaultView="week"
                            events={events}
                            min= {new Date(2020, 1, 1, 7)}
                            max= {new Date(2020, 1, 1, 23)}
                            culture={'fr'}
                            defaultDate={new Date(2020, 0, 6)}
                            dayPropGetter={this.customDayPropGetter}
                            // Empêche l'ouverture du détail de la journée
                            onDrillDown={() => { }}
                            onSelectSlot={({ start, end }) => this.selectAvailability(start, end)}
                            onSelectEvent={event => this.eventModal(event)}
                        />


                        <FormGroup row className="justify-content-center pt-5">
                            <Label lg={2}>Durée d'une consultation</Label>
                            <Col lg={6}>
                                <Input type="select" name="duration" value={this.state.duration} onChange={this.handleChange}>
                                    {Duration.values().map((elem, key) => 
                                        <option key={key} value={elem.value}>{elem.label}</option>
                                    )}
                                </Input>
                            </Col>
                        </FormGroup>

                        {this.props.register === false &&
                            <div className="mt-5 mb-3 w-100 text-center">
                                <LaddaButton
                                    className={"srounded pl-5 pr-5 pt-2 pb-2 text-white bg-green btn"}
                                    loading={this.state.disabled}
                                    onClick={() => this.update()}
                                    data-style={EXPAND_LEFT}>

                                    <FormattedMessage id="Update" />
                                </LaddaButton>
                            </div>
                        }

                    </CardBody>
                </Card>

                {this.state.modal}

            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        officeTimeTable: state.global.officeTimeTable,
        doctorCharacteristic: state.global.doctorCharacteristic,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onUpd: (objName, obj, cbk) => dispatch(upd(objName, obj, cbk)),
        editDuration: (duration, cbk) => dispatch(editDuration(duration, cbk)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OfficeTimetable));