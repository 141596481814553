
// ========================================
// ================== MEN =================
// ========================================

// import men_src0 from '../assets/images/users/men/HYDROGEN_AVATARS-0.png';
import men_src1 from '../assets/images/users/men/HYDROGEN_AVATARS-02.png';
import men_src2 from '../assets/images/users/men/HYDROGEN_AVATARS-05.png';
import men_src3 from '../assets/images/users/men/HYDROGEN_AVATARS-07.png';
import men_src4 from '../assets/images/users/men/HYDROGEN_AVATARS-09.png';
import men_src5 from '../assets/images/users/men/HYDROGEN_AVATARS-10.png';
import men_src6 from '../assets/images/users/men/HYDROGEN_AVATARS-12.png';
import men_src7 from '../assets/images/users/men/HYDROGEN_AVATARS-16.png';
import men_src8 from '../assets/images/users/men/HYDROGEN_AVATARS-17.png';
import men_src9 from '../assets/images/users/men/HYDROGEN_AVATARS-18.png';
import men_src10 from '../assets/images/users/men/HYDROGEN_AVATARS-21.png';
import men_src11 from '../assets/images/users/men/HYDROGEN_AVATARS-22.png';
import men_src12 from '../assets/images/users/men/HYDROGEN_AVATARS-23.png';
import men_src13 from '../assets/images/users/men/HYDROGEN_AVATARS-24.png';
import men_src14 from '../assets/images/users/men/HYDROGEN_AVATARS-25.png';
import men_src15 from '../assets/images/users/men/HYDROGEN_AVATARS-26.png';
import men_src16 from '../assets/images/users/men/HYDROGEN_AVATARS-27.png';
import men_src17 from '../assets/images/users/men/HYDROGEN_AVATARS-28.png';
import men_src18 from '../assets/images/users/men/HYDROGEN_AVATARS-29.png';
import men_src19 from '../assets/images/users/men/HYDROGEN_AVATARS-31.png';
import men_src20 from '../assets/images/users/men/HYDROGEN_AVATARS-32.png';
import men_src21 from '../assets/images/users/men/HYDROGEN_AVATARS-33.png';
import men_src22 from '../assets/images/users/men/HYDROGEN_AVATARS-34.png';
import men_src23 from '../assets/images/users/men/HYDROGEN_AVATARS-35.png';
import men_src24 from '../assets/images/users/men/HYDROGEN_AVATARS-36.png';
import men_src25 from '../assets/images/users/men/HYDROGEN_AVATARS-38.png';
import men_src26 from '../assets/images/users/men/HYDROGEN_AVATARS-39.png';
import men_src27 from '../assets/images/users/men/HYDROGEN_AVATARS-40.png';
import men_src28 from '../assets/images/users/men/HYDROGEN_AVATARS-41.png';

// ========================================
// ================= WOMEN ================
// ========================================

// import women_src0 from '../assets/images/users/women/HYDROGEN_AVATARS-01.png';
import women_src1 from '../assets/images/users/women/HYDROGEN_AVATARS_Plan de travail 1.png';
import women_src2 from '../assets/images/users/women/HYDROGEN_AVATARS-03.png';
import women_src3 from '../assets/images/users/women/HYDROGEN_AVATARS-04.png';
import women_src4 from '../assets/images/users/women/HYDROGEN_AVATARS-06.png';
import women_src5 from '../assets/images/users/women/HYDROGEN_AVATARS-08.png';
import women_src6 from '../assets/images/users/women/HYDROGEN_AVATARS-11.png';
import women_src7 from '../assets/images/users/women/HYDROGEN_AVATARS-13.png';
import women_src8 from '../assets/images/users/women/HYDROGEN_AVATARS-14.png';
import women_src9 from '../assets/images/users/women/HYDROGEN_AVATARS-15.png';
import women_src10 from '../assets/images/users/women/HYDROGEN_AVATARS-19.png';
import women_src11 from '../assets/images/users/women/HYDROGEN_AVATARS-20.png';
import women_src12 from '../assets/images/users/women/HYDROGEN_AVATARS-21.png';
import women_src13 from '../assets/images/users/women/HYDROGEN_AVATARS-22.png';
import women_src14 from '../assets/images/users/women/HYDROGEN_AVATARS-23.png';
import women_src15 from '../assets/images/users/women/HYDROGEN_AVATARS-24.png';
import women_src16 from '../assets/images/users/women/HYDROGEN_AVATARS-25.png';
import women_src17 from '../assets/images/users/women/HYDROGEN_AVATARS-26.png';
import women_src18 from '../assets/images/users/women/HYDROGEN_AVATARS-27.png';
import women_src19 from '../assets/images/users/women/HYDROGEN_AVATARS-28.png';
import women_src20 from '../assets/images/users/women/HYDROGEN_AVATARS-02.png';
import women_src21 from '../assets/images/users/women/HYDROGEN_AVATARS-05.png';
import women_src22 from '../assets/images/users/women/HYDROGEN_AVATARS-07.png';
import women_src23 from '../assets/images/users/women/HYDROGEN_AVATARS-09.png';
import women_src24 from '../assets/images/users/women/HYDROGEN_AVATARS-10.png';
import women_src25 from '../assets/images/users/women/HYDROGEN_AVATARS-12.png';
import women_src26 from '../assets/images/users/women/HYDROGEN_AVATARS-16.png';
import women_src27 from '../assets/images/users/women/HYDROGEN_AVATARS-17.png';
import women_src28 from '../assets/images/users/women/HYDROGEN_AVATARS-18.png';


const men = [
    // MEN
    // { value: 0, src: men_src0 },
    { value: 1, src: men_src1 },
    { value: 2, src: men_src2 },
    { value: 3, src: men_src3 },
    { value: 4, src: men_src4 },
    { value: 5, src: men_src5 },
    { value: 6, src: men_src6 },
    { value: 7, src: men_src7 },
    { value: 8, src: men_src8 },
    { value: 9, src: men_src9 },
    { value: 10, src: men_src10 },
    { value: 11, src: men_src11 },
    { value: 12, src: men_src12 },
    { value: 13, src: men_src13 },
    { value: 14, src: men_src14 },
    { value: 15, src: men_src15 },
    { value: 16, src: men_src16 },
    { value: 17, src: men_src17 },
    { value: 18, src: men_src18 },
    { value: 19, src: men_src19 },
    { value: 20, src: men_src20 },
    { value: 21, src: men_src21 },
    { value: 22, src: men_src22 },
    { value: 23, src: men_src23 },
    { value: 24, src: men_src24 },
    { value: 25, src: men_src25 },
    { value: 26, src: men_src26 },
    { value: 27, src: men_src27 },
    { value: 28, src: men_src28 },
];

const women = [
    // WOMEN
    // { value: 100, src: women_src0 },
    { value: 101, src: women_src1 },
    { value: 102, src: women_src2 },
    { value: 103, src: women_src3 },
    { value: 104, src: women_src4 },
    { value: 105, src: women_src5 },
    { value: 106, src: women_src6 },
    { value: 107, src: women_src7 },
    { value: 108, src: women_src8 },
    { value: 109, src: women_src9 },
    { value: 110, src: women_src10 },
    { value: 111, src: women_src11 },
    { value: 112, src: women_src12 },
    { value: 113, src: women_src13 },
    { value: 114, src: women_src14 },
    { value: 115, src: women_src15 },
    { value: 116, src: women_src16 },
    { value: 117, src: women_src17 },
    { value: 118, src: women_src18 },
    { value: 119, src: women_src19 },
    { value: 120, src: women_src20 },
    { value: 121, src: women_src21 },
    { value: 122, src: women_src22 },
    { value: 123, src: women_src23 },
    { value: 124, src: women_src24 },
    { value: 125, src: women_src25 },
    { value: 126, src: women_src26 },
    { value: 127, src: women_src27 },
    { value: 128, src: women_src28 },
];

const defaultImage = [{
    value: 0, src: ''
}];
const images = [...men, ...women];

export default class ProfileImages {

    static getMenImgs = () => men;
    static getWomenImgs = () => women;
    static getDefaultImage = () => defaultImage;

    static getImgs = () => {
        return images;
    }

    static getSrc = (value) => {
        const img = images.find(i => i.value === value);

        if (img) return img.src;
        return "";
    }

    static isCorrectGender = (image, gender) => {
        if (gender === "man") {
            for(let img of ProfileImages.getMenImgs()){
                if(img.value === image) return true;
            }
        } else {
            for(let img of ProfileImages.getWomenImgs()){
                if(img.value === image) return true;
            }
        }

        return false;
    };

    static defaultForGender = (gender) => {
        if (gender === "man") return ProfileImages.getMenImgs()[0].value;
        else return ProfileImages.getWomenImgs()[0].value;
    };

};