import React from 'react';
import { connect } from 'react-redux';
import 'react-datetime/css/react-datetime.css';
import { injectIntl } from 'react-intl';
import ProfileImages from "../../../enum/ProfileImages";
import { Row } from "reactstrap";
import Stars from '../../../components/misc/Stars';
import RatingEnt from '../../../entities/RatingEnt';
import { upds } from "../../../actions/global/global";

class SReplacementCardBySector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            shake: false
        };

        this.ref = React.createRef();
    };

    shake() {
        this.setState({ shake: true });
        setTimeout(() => this.setState({ shake: false }), 1250);
    }

    render() {
        const { user, doctor } = this.props;
        if (!user || !doctor) return null;

        var addClassName = "";
        if (this.state.shake) addClassName = " shake ";

        if (this.props.addRef) this.props.addRef(this.ref);
        if (this.props.addShakeCbk) this.props.addShakeCbk(() => this.shake());

        return (
            <React.Fragment>

                <div className={"d-replacement-card-dim pb-0 mb-0 mb-4 pt-0 mt-0 " + addClassName} style={{ position: "relative" }} ref={this.ref}>

                    <div className="d-replacement-card pb-0 mb-0 mb-4 clickable ">
                        <div>
                            <Row className="min-height-70 ml-mr-0">
                                <div className='col-lg-3'>

                                    <div className="clickable height-50 profile" style={{ marginTop: "8px" }}>

                                        <img src={ProfileImages.getSrc(doctor.image)} alt="user img" className="d-inline" style={{ borderRadius: "50px" }} width="50" height="50" />

                                        <div className="d-inline-block ml-2 height-50 align-middle">
                                            <div className="d-inline-block pl-2 pr-2 height-50 break-word ellipsis">
                                                <b>{doctor.first_name}</b>
                                            </div>
                                            <div className="d-inline-block pl-2 pr-2 height-50 align-top"><Stars nbr={RatingEnt.averageAllStars(doctor.rating)} /></div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-lg-2 offset-lg-7">
                                    <a className='text-white border xrounded bg-green text-white p-3 text-center' href="/#/home/subscription">
                                        Entrer en contact
                                    </a>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    };
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpds: (objName, obj, cbk) => dispatch(upds(objName, obj, cbk))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SReplacementCardBySector));