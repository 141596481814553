import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Collapse, Card, CardBody, Badge } from "reactstrap";
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import DAvailabilityCard from './DAvailabilityCard';
import DAvailabilityCardBySector from './DAvailabilityCardBySector';
import { Form, DateInput, RangeInput, RadioInput } from '@gferrand/react-forms';
import { clears, getsBy } from "../../../actions/global/global";
import { searchSubstitutesBySector } from '../../../actions/user/user';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import ProfileImages from '../../../enum/ProfileImages';
import Geocode from "react-geocode";
import AllWeekDaysInput from "../../../components/form/AllWeekDaysInput";
import Pagination from "react-js-pagination";
import StarsInput from "../../../components/form/StarsInput";

import "bootstrap/dist/css/bootstrap.min.css";


import lampImg from '../../../assets/images/search/HYDROGEN_ILLU_LAMPE.png';


class SearchAvailability extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            displayPerfectMatch: true,
            loading: false,
            searchedOnce: false,

            //Sort items
            sortItem: "retro",
            sorted: 1,
            sortedWithRetro: "fas fa-angle-up",
            sortedWithnbjours: "fas fa-sort",
            sortedWithRempproche: "fas fa-sort",

            //Types Array for replacement types
            typeArray: [],

            //Avoid rerendering the page
            PreviousAvailabilitys: null,

            // Pagination
            skip: 0,
            limit: 10,

            displaySearch: 'replacement'
        };


        this.searchFilter = {
            start_date: new Date().toISOString(),
            end_date: new Date(new Date().getTime() + 1 * 30 * 24 * 3600 * 1000).toISOString()
        };

        this.buildForm()

        // Default coords : Paris
        this.GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
        this.mapComponent = null;

        // Refs to availability cards
        this.availabilityRefs = {};
        this.shakeCbks = {};

        // =================================
        // ============= GMAPS =============
        // =================================

        // Default coords
        if (this.props.doctorCharacteristic) this.buildMap(this.props.doctorCharacteristic);
        this.changeResultSearch = this.changeResultSearch.bind(this);
    }

    componentDidMount() {
        if (this.props.doctorCharacteristic) {
            this.fetchData((availabilitys) => { 
                let object = {
                    displaySearch: this.state.displaySearch,
                    skip: this.state.skip
                }
                this.addAvailabilitysMapCbk(availabilitys, this.props.availabilityBySector, object); 
                this.setState({ searchedOnce: true });
            })

            this.props.searchSubstitutesBySector();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Now that we have the doctorCharacteristic we need to update the map
        if (prevProps.doctorCharacteristic !== this.props.doctorCharacteristic) {
            this.buildMap(this.props.doctorCharacteristic);
            this.fetchData((availabilitys) => { 
                let object = {
                    displaySearch: this.state.displaySearch,
                    skip: this.state.skip
                }
                this.addAvailabilitysMapCbk(availabilitys, this.props.availabilityBySector, object); 
                this.setState({ searchedOnce: true });
            })

            this.props.searchSubstitutesBySector();
            this.forceUpdate();
        }
        if (this.addAvailabilitysMapCbk && this.props.substituteCharacteristic) {
            if ((prevProps.availabilitys && prevProps.availabilitys.length !== this.props.substituteCharacteristic.length) || !prevProps.availabilitys) {
                this.addAvailabilitysMapCbk(this.props.substituteCharacteristic);
            }
        }
    }

    buildForm() {
        var submitCbk = (data, endCbk) => {
            this.form.setValue('type', null)
            this.form.setValue('start_date', new Date().toISOString())
            this.form.setValue('end_date', new Date(new Date().getTime() + 1 * 30 * 24 * 3600 * 1000).toISOString())
            this.form.setValue('nbr_of_stars', 0)
            this.form.setValue('retrocession', 100)
            this.form.hide("days");
            //Clear inputs tries everything
            this.searchFilter = {
                start_date: new Date().toISOString(),
                end_date: new Date(new Date().getTime() + 1 * 30 * 24 * 3600 * 1000).toISOString()
            };
            this.fetchData(() => endCbk())
        };

        var changeCbk = (input) => {
            const {
                name,
                value
            } = input;

            var formData = this.form.getRawData();

            if (name === "type") {
                // User switches availability type, show / hide relevant fields
                if (value === "regular") {
                    // Radio input pour bloquer les jours de sa librairie
                    this.form.show("days");
                    this.form.setValue("days", ['allDays']);
                    this.form.hide("start_date");
                    this.form.hide("end_date");
                } else {
                    this.form.hide("days");
                    this.form.show("start_date");
                    this.form.show("end_date");
                }
                if (formData.start_date && formData.end_date) {
                    // End date before start date
                    if(value === "guard" && new Date(formData.end_date).setHours(0, 0, 0, 0) < new Date(formData.start_date).setHours(0, 0, 0, 0)) {
                        this.form.setError("end_date", "EndDate.Before.StartDate.Err");
                    } else if (value  !== "guard" && new Date(formData.end_date) <= new Date(formData.start_date).setHours(0, 0, 0, 0)) {
                        this.form.setError("end_date", "EndDate.Before.StartDate.Err");
                    } else {
                        this.form.setError("end_date", null)
                    }
                }
                this.setState({ skip: 0 });
            }

            if (name === "start_date" || name === "end_date") {
                var today = new Date();
                today.setHours(0, 0, 0, 0);

                // Cannot start in the past
                if (new Date(formData.start_date) < today) this.form.setError("start_date", "Start.Past.Err");

                if (formData.start_date && formData.end_date) {
                    // End date before start date
                    if(formData.type === "guard" && new Date(formData.end_date).setHours(0, 0, 0, 0) < new Date(formData.start_date).setHours(0, 0, 0, 0)) {
                        this.form.setError("end_date", "EndDate.Before.StartDate.Err");
                    } else if (formData.type  !== "guard" && new Date(formData.end_date) <= new Date(formData.start_date).setHours(0, 0, 0, 0)) {
                        this.form.setError("end_date", "EndDate.Before.StartDate.Err");
                    }    
                }
            }

            //User switches retrocession ou nbr etoiles
            if (name === "retrocession") {
                formData.retrocession = value
            }

            if (name === "nbr_of_stars") {
                formData.nbr_of_stars = value
            }

            if (name !== null) {
                this.searchFilter = Object.assign({}, formData)
                if (name === 'type') {
                    if (value === "regular") {
                        this.searchFilter.days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
                    } else {
                        delete this.searchFilter.days;
                    }
                } 
                if (this.searchFilter.days && this.searchFilter.days.includes("allDays")) {
                    this.searchFilter.days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
                } 
                if (!this.state.collapse) {
                    delete this.searchFilter.nbr_of_stars;
                    delete this.searchFilter.retrocession;
                }
                if (name === "type") this.fetchData((availabilitys) => this.addAvailabilitysMapCbk(availabilitys))
                else this.fetchData()

                if (name === 'days') {
                    this.setState({ skip: 0 });
                }
            }
        };

        this.form = new Form({
            inputs: [
                new DateInput("start_date", "Start.Date", [], { errorClassName: "react-date-picker-invalid", className: "custom-date-input-color" }),
                new DateInput("end_date", "End.Date", [], { errorClassName: "react-date-picker-invalid", className: "custom-date-input-color" }),

                new RadioInput("type", "Replacement",
                    [{ label: "Occasional", value: "occasional" },
                    { label: "Regular", value: "regular" },
                    { label: "Guard", value: "guard" }]
                    , [], [], {className: "srounded pl-5 pr-5 pt-2 pb-2 text-white bg-green btn"}),

                new AllWeekDaysInput("days"),
                
                new StarsInput("nbr_of_stars", "Stars", [], 1, 5),
                new RangeInput("retrocession", "Retrocession", 60, 100, 2.5, [], { suffix: " %", className: "blue-range-input" }),
            ],
            submitCbk,
            changeCbk,
            data: {
                start_date: new Date(),
                end_date: new Date(new Date().getTime() + 1 * 30 * 24 * 3600 * 1000),
                retrocession: 100,
                type: 'occasional'
            }
        });

        this.form.hide("days");

        // Default coords : Paris
        this.GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
        this.mapComponent = null;

        // Refs to availability cards
        this.availabilityRefs = {};
        this.shakeCbks = {};

        // =================================
        // ============= GMAPS =============
        // =================================

        this.mapComponent = <GAvailabilityMap
            doctorCharacteristic={this.props.doctorCharacteristic}
            onMarkerClick={(availability) => this.onMarkerClick(availability)}
            addAvailabilitysMapCbk={(cbk) => this.addAvailabilitysMapCbk = cbk}
            addLoadMapCbk={(cbk) => this.loadMapCbk = cbk} />;

        // Default coords
        this.mapComponent = null;
        if (this.props.doctorCharacteristic) this.buildMap(this.props.doctorCharacteristic);
    }

    buildMap(doctorCharacteristic) {
        this.mapComponent = <GAvailabilityMap
            doctorCharacteristic={doctorCharacteristic}
            onMarkerClick={(availability, index) => this.onMarkerClick(availability, index)}
            addAvailabilitysMapCbk={(cbk) => this.addAvailabilitysMapCbk = cbk}
            addLoadMapCbk={(cbk) => this.loadMapCbk = cbk} />;
    }

    onMarkerClick(availability, index) {
        const ref = this.availabilityRefs[availability._id];
        let skip = Math.trunc(index / 10) * 10;
        let displayPerfectMatch = this.state.displayPerfectMatch;
        if(this.searchFilter.type !== "regular") {
            displayPerfectMatch =  availability.isPerfectMatch
        }
        
        this.setState({
            displayPerfectMatch,
            skip
        }, () => {
            if (ref && ref.current) window.scrollTo(0, ref.current.offsetTop);

            const shakeCbk = this.shakeCbks[availability._id];
            if (shakeCbk) shakeCbk();
        })
    }

    fetchData(cbk) {
        this.setState({ loading: true });
        if (this.loadMapCbk) this.loadMapCbk();
        let type;
        if (this.form) {
            type = this.form.inputs[2]._state.value;
        }
        if (type === 'regular') {
            let startDate = new Date().toISOString();
            let endDate = new Date(new Date().getTime() + 12 * 30 * 24 * 3600 * 1000).toISOString();
            this.searchFilter.start_date = startDate;
            this.searchFilter.end_date = endDate;
        }
        this.props.onGetsBy("substituteCharacteristic",
            [
                "start_date",
                "end_date",
                "type",
                "nbr_of_stars",
                "retrocession",
                "days",
                "skip",
                "limit"
            ],
            [
                this.searchFilter.start_date,
                this.searchFilter.end_date,
                this.searchFilter.type,
                this.searchFilter.nbr_of_stars,
                this.searchFilter.retrocession,
                this.searchFilter.days,
                this.state.skip,
                this.state.limit
            ], (availabilitys) => {
                let object = {
                    displaySearch: this.state.displaySearch,
                    skip: this.state.skip
                }
                this.addAvailabilitysMapCbk(availabilitys, this.props.availabilityBySector, object)
                if (cbk) cbk(availabilitys);
                this.setState({ loading: false });
            });
    }

    pointingArrow = () => {
        if (!this.state.collapse) return <i className="fa fa-angle-down" />;
        return <i className="fa fa-angle-up" />
    }

    row(inputName) {
        return (
            <Row className="form-group">
                <Col md="5">{this.form.getLabel(inputName)}</Col>
                <Col md="7">{this.form.getInput(inputName)}</Col>
            </Row>
        );
    }

    toggle = () => {
        this.setState({ collapse: !this.state.collapse });
    }

    handleClick = e => {
        e.preventDefault();
        if (e.target.id !== this.state.sortItem) {
            switch (e.target.id) {
                case 'retro':
                    this.setState({
                        sortItem: e.target.id,
                        sorted: 1,
                        sortedWithRetro: "fas fa-angle-down",
                        sortedWithRempproche: "fas fa-sort",
                        sortedWithnbjours: "fas fa-sort"
                    })
                    break;

                case 'rempproche':
                    this.setState({
                        sortItem: e.target.id,
                        sorted: 1,
                        sortedWithRetro: "fas fa-sort",
                        sortedWithRempproche: "fas fa-angle-down",
                        sortedWithnbjours: "fas fa-sort",
                    })
                    break;
                case 'nbjours':
                    this.setState({
                        sortItem: e.target.id,
                        sorted: 1,
                        sortedWithRetro: "fas fa-sort",
                        sortedWithRempproche: "fas fa-sort",
                        sortedWithnbjours: "fas fa-angle-down"
                    })
                    break;
                default:
                    this.setState({
                        sortItem: e.target.id,
                        sorted: 1,
                        sortedWithRetro: "fas fa-angle-down",
                        sortedWithRempproche: "fas fa-sort",
                        sortedWithnbjours: "fas fa-sort"
                    })
                    break;
            }
        }else if (this.state.sorted === 1) {
            switch (e.target.id) {
                case 'retro':
                    this.setState({
                        sortItem: e.target.id,
                        sorted: -1,
                        sortedWithRetro: this.state.sortedWithRetro === "fas fa-angle-down" ? "fas fa-angle-up" : "fas fa-angle-down",
                        sortedWithRempproche: "fas fa-sort",
                        sortedWithnbjours: "fas fa-sort"
                    })
                    break;
                case 'rempproche':
                    this.setState({
                        sortItem: e.target.id,
                        sorted: -1,
                        sortedWithRetro: "fas fa-sort",
                        sortedWithRempproche: this.state.sortedWithRempproche === "fas fa-angle-down" ? "fas fa-angle-up" : "fas fa-angle-down",
                        sortedWithnbjours: "fas fa-sort",
                    })
                    break;
                case 'nbjours':
                    this.setState({
                        sortItem: e.target.id,
                        sorted: -1,
                        sortedWithRetro: "fas fa-sort",
                        sortedWithRempproche: "fas fa-sort",
                        sortedWithnbjours: this.state.sortedWithnbjours === "fas fa-angle-down" ? "fas fa-angle-up" : "fas fa-angle-down",
                    })
                    break;
                default:
                    this.setState({
                        sortItem: e.target.id,
                        sorted: -1,
                        sortedWithRetro: this.state.sortedWithRetro === "fas fa-angle-down" ? "fas fa-angle-up" : "fas fa-angle-down",
                        sortedWithRempproche: "fas fa-sort",
                        sortedWithnbjours: "fas fa-sort"
                    })
                    break;
            }
        } else {
            switch (e.target.id) {
                case 'retro':
                    this.setState({
                        sortItem: e.target.id,
                        sorted: 1,
                        sortedWithRetro: this.state.sortedWithRetro === "fas fa-angle-down" ? "fas fa-angle-up" : "fas fa-angle-down",
                        sortedWithRempproche: "fas fa-sort",
                        sortedWithnbjours: "fas fa-sort"
                    })
                    break;
                case "rempproche":
                    this.setState({
                        sortItem: e.target.id,
                        sorted: 1,
                        sortedWithRetro: "fas fa-sort",
                        sortedWithRempproche: this.state.sortedWithRempproche === "fas fa-angle-down" ? "fas fa-angle-up" : "fas fa-angle-down",
                        sortedWithnbjours: "fas fa-sort"
                    })
                    break;
                case "nbjours":
                    this.setState({
                        sortItem: e.target.id,
                        sorted: 1,
                        sortedWithRetro: "fas fa-sort",
                        sortedWithRempproche: "fas fa-sort",
                        sortedWithnbjours: this.state.sortedWithnbjours === "fas fa-angle-down" ? "fas fa-angle-up" : "fas fa-angle-down",
                    })
                    break;
                default:
                    this.setState({
                        sortItem: e.target.id,
                        sorted: -1,
                        sortedWithRetro: this.state.sortedWithRetro === "fas fa-angle-down" ? "fas fa-angle-up" : "fas fa-angle-down",
                        sortedWithRempproche: "fas fa-sort",
                        sortedWithnbjours: "fas fa-sort"
                    })
                    break;
            }
        }
    };

    getBusinessDatesCount(startDate, endDate) {
        var count = 0;
        var curDate = startDate;
        while (curDate <= endDate) {
            var dayOfWeek = curDate.getDay();
            if (!((dayOfWeek === 6) || (dayOfWeek === 0)))
                count++;
            curDate.setDate(curDate.getDate() + 1);
        }
        return count;
    }

    changeResultSearch(event) {
        const id = event.currentTarget.id;

        if (this.state.displaySearch === id) return;
        
        let object = {
            displaySearch: id,
            skip: this.state.skip
        }
        this.addAvailabilitysMapCbk(this.props.substituteCharacteristics, this.props.availabilityBySector, object); 
        this.setState({
            displaySearch: id
        })
    }

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    render() {
        const { substituteCharacteristics, availabilityBySector } = this.props;
        const matchingAvailabilities = substituteCharacteristics ? substituteCharacteristics.filter((availability) => {
            return availability.isPerfectMatch;
        }) : []

        const interestingAvailabilities = substituteCharacteristics ? substituteCharacteristics.filter((availability) => {
            return !availability.isPerfectMatch;
        }) : []

        if (substituteCharacteristics && availabilityBySector) {
            availabilityBySector.map(function(availability, index, object){ 
                var result = substituteCharacteristics.filter(a1 => a1.substitute_id === availability.substitute_id);
                if(result.length > 0) { 
                    object.splice(index, 1);
                }
                return availability; 
            });
        }

        // Empty refs
        this.availabilityRefs = {};
        this.shakeCbks = {};

        let type;
        if (this.form) {
            type = this.form.inputs[2]._state.value;
        }


        let searchStartDate = this.form.getRawData().start_date;
        let searchEndDate = this.form.getRawData().end_date;

        let totalAvailabilites = matchingAvailabilities.length + interestingAvailabilities.length;
        let totalAvailabilitesBySector = availabilityBySector ? availabilityBySector.length : 0;
        return (
            <React.Fragment>
                <div className="w-100 text-white text-center uppercase mb-4">
                    <h4><FormattedMessage id="Find.Substitute" /></h4>
                </div>
                <Card className="p-0 rounded-0">
                    <CardBody className="mt-0 p-0">
                        <div className="p-0">
                            <Row className="p-0 m-0">
                                <Col className="p-0 col-12 col-lg-5 col-xl-6">
                                    {this.mapComponent}
                                </Col>
                                <Col className="p-0 col-12 col-lg-7 col-xl-6">
                                    <div className="mb-3 mt-3 pb-4" >
                                        <div className="three-btns-inputs-custom pt-3 text-center">
                                            {this.form.getInput("type")}
                                        </div>
                                        <div className="three-btns-inputs pt-3">
                                            {this.form.getInput("all_days")}
                                        </div>
                                        <div className="text-center mb-4">
                                            {this.form.getInput("days")}
                                        </div>
                                        <Collapse isOpen={true}>
                                            <div className="w-100 text-center align-top">

                                                <div className="d-inline-block align-top">
                                                    {type !== 'regular' &&
                                                        <div className="d-inline-block align-top mt-2">
                                                            <b><FormattedMessage id="From" />&nbsp;&nbsp;</b>
                                                        </div>
                                                    }
                                                    <span className="date-input-input">{this.form.getInput("start_date")}</span>
                                                </div>
                                                <div className="d-inline-block align-top">
                                                    {type !== 'regular' && 
                                                        <div className="d-inline-block align-top mt-2">
                                                            <b>&nbsp;&nbsp;<FormattedMessage id="to" /></b>
                                                        </div>  
                                                    }
                                                    &nbsp;&nbsp;<span className="date-input-input">{this.form.getInput("end_date")}</span>
                                                </div>
                                            </div>
                                        </Collapse>

                                        {(!this.state.loading) &&
                                            <div className="row justify-content-around w-100 text-white mb-3 ">
                                                <button className={"ladda-button btn srounded btn text-center mx-auto " + (this.state.displaySearch === 'replacement' ? " bg-green " : " bg-secondary ")} onClick={this.changeResultSearch} id="replacement">
                                                    <FormattedMessage id="Available.Replacement.Sub" />
                                                    <Badge className="ml-1" color="danger" pill>{totalAvailabilites}&nbsp;</Badge>
                                                </button>
                                                <button className={"ladda-button btn srounded btn text-center mx-auto " + (this.state.displaySearch === 'replacement' ? " bg-secondary " : " bg-green ")} onClick={this.changeResultSearch} id="sector">
                                                    <FormattedHTMLMessage id="Available.Sector.Sub" className="text-dark"/>
                                                    <Badge className="ml-1" color="danger" pill>{totalAvailabilitesBySector}&nbsp;</Badge>
                                                </button>
                                            </div>
                                        }
                                        
                                        <Collapse isOpen={this.state.collapse} className="p-2">
                                            <div className="collapsed-form">
                                                {this.row("nbr_of_stars")}
                                                {this.row("retrocession")}
                                            </div>
                                        </Collapse>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

                {this.state.loading === true &&
                    this.loading()
                }
                
                {(!this.state.loading) &&
                    <React.Fragment>
                        {this.searchFilter.type === "regular" ? (
                            <React.Fragment>
                                {this.state.displaySearch === 'replacement' && 
                                    <React.Fragment>
                                        <div className="w-100 text-white text-center mb-3 ">
                                            {(substituteCharacteristics.length > 1) ? <FormattedMessage id="Results.Matching" /> : <FormattedMessage id="Result.Matching" />}
                                        </div>
                                        {substituteCharacteristics.slice(0 + this.state.skip, this.state.limit  + this.state.skip).map((r, key) => <DAvailabilityCard
                                            dateStartAndEnd={{start_date: new Date(), end_date: new Date(new Date().setMonth(3)) }}
                                            addShakeCbk={(cbk) => this.shakeCbks[r._id] = cbk}
                                            addRef={(ref) => this.availabilityRefs[r._id] = ref}
                                            key={"regular" + r._id + key}
                                            availability={r} />)
                                        }
                                        {matchingAvailabilities.length > 0 && interestingAvailabilities.length > 0 &&
                                            <div className='search-separator mb-4'></div>
                                        }
                                        <Pagination
                                            innerClass="w-100 pagination justify-content-center"
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            activePage={Math.round(this.state.skip / this.state.limit) + 1}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={substituteCharacteristics.length}
                                            pageRangeDisplayed={5}
                                            onChange={(page) => this.setState({ skip: (page - 1) * this.state.limit })}
                                            hideDisabled={false}
                                        />
                                    </React.Fragment>
                                }

                                {this.state.displaySearch === 'sector' &&
                                    <React.Fragment>
                                        <React.Fragment>
                                            <div className="w-100 text-white text-center mb-3 ">
                                                {(substituteCharacteristics.length > 1) ? <FormattedMessage id="Results.Matching" /> : <FormattedMessage id="Result.Matching" />}
                                            </div>
                                            {substituteCharacteristics.slice(0 + this.state.skip, this.state.limit  + this.state.skip).map((r, key) => <DAvailabilityCard
                                                dateStartAndEnd={{start_date: new Date(), end_date: new Date(new Date().setMonth(3)) }}
                                                addShakeCbk={(cbk) => this.shakeCbks[r._id] = cbk}
                                                addRef={(ref) => this.availabilityRefs[r._id] = ref}
                                                key={"regular" + r._id + key}
                                                availability={r} />)
                                            }
                                            {matchingAvailabilities.length > 0 && interestingAvailabilities.length > 0 &&
                                                <div className='search-separator mb-4'></div>
                                            }
                                            <Pagination
                                                innerClass="w-100 pagination justify-content-center"
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={Math.round(this.state.skip / this.state.limit) + 1}
                                                itemsCountPerPage={this.state.limit}
                                                totalItemsCount={substituteCharacteristics.length}
                                                pageRangeDisplayed={5}
                                                onChange={(page) => this.setState({ skip: (page - 1) * this.state.limit })}
                                                hideDisabled={false}
                                            />
                                        </React.Fragment>

                                        {(matchingAvailabilities.length > 0 || interestingAvailabilities.length > 0) &&
                                            <div className='search-separator mb-4'></div>
                                        }
                                        {(availabilityBySector) && 
                                            availabilityBySector.slice(0 + this.state.skip, this.state.limit  + this.state.skip).map((r, key) => <DAvailabilityCardBySector
                                                dateStartAndEnd={{start_date: searchStartDate, end_date: searchEndDate }}
                                                addShakeCbk={(cbk) => this.shakeCbks[r._id] = cbk}
                                                addRef={(ref) => this.availabilityRefs[r._id] = ref}
                                                key={"perfect" + r._id + key}
                                                availability={r} />)
                                        }
      
                                    </React.Fragment>
                                }

                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {this.state.displaySearch === 'replacement' && 
                                    <React.Fragment>
                                        {(substituteCharacteristics) && 
                                            matchingAvailabilities.slice(0 + this.state.skip, this.state.limit  + this.state.skip).map((r, key) => <DAvailabilityCard
                                                dateStartAndEnd={{start_date: searchStartDate, end_date: searchEndDate }}
                                                addShakeCbk={(cbk) => this.shakeCbks[r._id] = cbk}
                                                addRef={(ref) => this.availabilityRefs[r._id] = ref}
                                                key={"perfect" + r._id + key}
                                                availability={r} />)
                                        }
                                        {matchingAvailabilities.length === 0 && interestingAvailabilities.length === 0 &&
                                            <div className="w-100 mx-auto text-center mb-2">
                                                <div className="d-inline-block mb-3" style={{ width: 150, height: 150 }}>
                                                    <img src={lampImg} alt="" width={150} height={150} />
                                                </div>
                                                <div className="uppercase text-white"><FormattedHTMLMessage id="No.Availability.Matches" /></div>
                                            </div>
                                        }

                                        {matchingAvailabilities.length > 0 && interestingAvailabilities.length > 0 &&
                                            <div className='search-separator mb-4'></div>
                                        }

                                        {(substituteCharacteristics) && 
                                            interestingAvailabilities.slice(0 + this.state.skip, this.state.limit  + this.state.skip).map((r, key) => <DAvailabilityCard
                                                dateStartAndEnd={{start_date: searchStartDate, end_date: searchEndDate }}
                                                addShakeCbk={(cbk) => this.shakeCbks[r._id] = cbk}
                                                addRef={(ref) => this.availabilityRefs[r._id] = ref}
                                                key={"interesting" + r._id + key}
                                                availability={r} />)
                                        }
                                    </React.Fragment>
                                }

                                {this.state.displaySearch === 'sector' &&
                                    <React.Fragment>
                                        {(substituteCharacteristics) && 
                                            matchingAvailabilities.slice(0 + this.state.skip, this.state.limit  + this.state.skip).map((r, key) => <DAvailabilityCard
                                                dateStartAndEnd={{start_date: searchStartDate, end_date: searchEndDate }}
                                                addShakeCbk={(cbk) => this.shakeCbks[r._id] = cbk}
                                                addRef={(ref) => this.availabilityRefs[r._id] = ref}
                                                key={"perfect" + r._id + key}
                                                availability={r} />)
                                        }
                                        {(substituteCharacteristics) && 
                                            interestingAvailabilities.slice(0 + this.state.skip, this.state.limit  + this.state.skip).map((r, key) => <DAvailabilityCard
                                                dateStartAndEnd={{start_date: searchStartDate, end_date: searchEndDate }}
                                                addShakeCbk={(cbk) => this.shakeCbks[r._id] = cbk}
                                                addRef={(ref) => this.availabilityRefs[r._id] = ref}
                                                key={"interesting" + r._id + key}
                                                availability={r} />)
                                        }

                                        {(matchingAvailabilities.length > 0 || interestingAvailabilities.length > 0) &&
                                            <div className='search-separator mb-4'></div>
                                        }
                                        {(availabilityBySector) && 
                                            availabilityBySector.slice(0 + this.state.skip, this.state.limit  + this.state.skip).map((r, key) => <DAvailabilityCardBySector
                                                dateStartAndEnd={{start_date: searchStartDate, end_date: searchEndDate }}
                                                addShakeCbk={(cbk) => this.shakeCbks[r._id] = cbk}
                                                addRef={(ref) => this.availabilityRefs[r._id] = ref}
                                                key={"perfect" + r._id + key}
                                                availability={r} />)
                                        }
      
                                    </React.Fragment>
                                }

                                <Pagination
                                    innerClass="w-100 pagination justify-content-center"
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={Math.round(this.state.skip / this.state.limit) + 1}
                                    itemsCountPerPage={this.state.limit}
                                    totalItemsCount={(this.state.displaySearch === 'replacement' ? totalAvailabilites : totalAvailabilitesBySector)}
                                    pageRangeDisplayed={5}
                                    onChange={(page) => this.setState({ skip: (page - 1) * this.state.limit })}
                                    hideDisabled={false}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        substituteCharacteristics: state.global.substituteCharacteristics,
        substituteCharacteristicsCount: state.global.substituteCharacteristicsCount,
        doctorCharacteristic: state.global.doctorCharacteristic,
        availabilityBySector: state.userReducer.subBySector
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onClears: (objName) => dispatch(clears(objName)),
        onGetsBy: (objName, fields, values, callback) => dispatch(getsBy(objName, fields, values, callback)),
        searchSubstitutesBySector: () => dispatch(searchSubstitutesBySector()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchAvailability);



class GAvailabilityMap extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            previousAvailabilitys: []
        };

        // Geocode (for geocoding, no kidding)
        this.GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
        Geocode.setApiKey(this.GOOGLE_MAP_API_KEY);

        this.mapComponent = null;
        this.buildMap();

        if (this.props.addLoadMapCbk) this.props.addLoadMapCbk(() => this.loadMapCbk());
        if (this.props.addAvailabilitysMapCbk) this.props.addAvailabilitysMapCbk((availabilitys, availabilityBySector, object) => this.buildMap(availabilitys, availabilityBySector, object));
    }

    loadMapCbk() {
        this.setState({ loading: true });
    }

    buildMap(availabilitys, availabilityBySector, object) {
        // Don't update the map for nothing
        if ((!availabilitys || availabilitys.length === 0) && (!this.state.previousAvailabilitys || this.state.previousAvailabilitys.length === 0) && this.mapComponent) {
            this.setState({ loading: false });
        }
        const { doctorCharacteristic } = this.props;

        var defaultLat = (doctorCharacteristic) ? doctorCharacteristic.location.coordinates[1] : 48.8534;
        var defaultLong = (doctorCharacteristic) ? doctorCharacteristic.location.coordinates[0] : 2.3488;

        const getBounds = () => {
            const bounds = new window.google.maps.LatLngBounds();

            const testBoundAgainstCoords = (lat, lng) => {
                const latLng = new window.google.maps.LatLng(parseFloat(lat), parseFloat(lng));
                bounds.extend(latLng);
            };

            // Add every availability zone
            if (availabilitys) availabilitys.map(a => testBoundAgainstCoords(a.location.coordinates[1], a.location.coordinates[0]));
            // Add sub location
            testBoundAgainstCoords(defaultLat, defaultLong);

            var offset = 0.002;
            var center = bounds.getCenter();
            bounds.extend(new window.google.maps.LatLng(center.lat() + offset, center.lng() + offset));
            bounds.extend(new window.google.maps.LatLng(center.lat() - offset, center.lng() - offset));

            return bounds;
        };

        const matchingAvailabilities = availabilitys ? availabilitys.filter((availability) => {
            return availability.isPerfectMatch;
        }) : []

        const interestingAvailabilities = availabilitys ? availabilitys.filter((availability) => {
            return !availability.isPerfectMatch;
        }) : []

        
        let sub = [];
        if (object && object.displaySearch === 'sector') {
            sub = availabilityBySector.slice(object.skip, object.skip + 10);
        }

        this.mapComponent = withScriptjs(withGoogleMap((props) =>
            <GoogleMap
                ref={map => map && map.fitBounds(getBounds())}
                defaultZoom={11}
                defaultCenter={{ lat: defaultLat, lng: defaultLong }}
                defaultOptions={{
                    mapTypeControl: false,
                    fullscreenControl: false,
                    gestureHandling: 'none',
                    zoomControl: false,
                    draggable: false,
                    streetViewControl: false
                }}
            >
                {(props.isMarkerShown) &&
                    <Marker position={{ lat: defaultLat, lng: defaultLong }} />
                }


                {(matchingAvailabilities) && matchingAvailabilities.map((a, key) => {
                    return (
                        <Marker
                            key={Math.random()}
                            onClick={() => this.props.onMarkerClick(a, key)}
                            icon={{
                                url: ProfileImages.getSrc(a.substitute.image), // url
                                scaledSize: new window.google.maps.Size(50, 50), // scaled size
                                origin: new window.google.maps.Point(0, 0), // origin
                                anchor: new window.google.maps.Point(0.5, 0.5) // anchor
                            }}
                            position={{
                                lat: parseFloat(a.location.coordinates[1]),
                                lng: parseFloat(a.location.coordinates[0])
                            }} />
                    );
                })}
                {(interestingAvailabilities) && interestingAvailabilities.map((a, key) => {
                    return (
                        <Marker
                            key={Math.random()}
                            onClick={() => this.props.onMarkerClick(a, key)}
                            icon={{
                                url: ProfileImages.getSrc(a.substitute.image), // url
                                scaledSize: new window.google.maps.Size(50, 50), // scaled size
                                origin: new window.google.maps.Point(0, 0), // origin
                                anchor: new window.google.maps.Point(0.5, 0.5) // anchor
                            }}
                            position={{
                                lat: parseFloat(a.location.coordinates[1]),
                                lng: parseFloat(a.location.coordinates[0])
                            }} />
                    );
                })}
                    
                {object && object.displaySearch === 'sector' &&
                    <>
                        {(sub) && sub.map((a, key) => {
                            return (
                                <Marker
                                    key={Math.random()}
                                    onClick={() => this.props.onMarkerClick(a, key)}
                                    icon={{
                                        url: ProfileImages.getSrc(a.substitute.image), // url
                                        scaledSize: new window.google.maps.Size(50, 50), // scaled size
                                        origin: new window.google.maps.Point(0, 0), // origin
                                        anchor: new window.google.maps.Point(0.5, 0.5) // anchor
                                    }}
                                    position={{
                                        lat: parseFloat(a.location.coordinates[1]),
                                        lng: parseFloat(a.location.coordinates[0])
                                    }} />
                            );
                        })}
                    </>
                }

            </GoogleMap>
        ));

        this.setState({ loading: false, previousAvailabilitys: availabilitys });
    }

    loading = () => {
        return (
            <div className="w-100 text-center mt-2 mb-2 h-300px">
                <div className="spinner-border text-white mx-auto" role="status" style={{ marginTop: 125 }}></div>
            </div>
        );
    }

    render() {
        return (
            <div className="p-0 m-0" style={{ height: `420px` }}>
                <this.mapComponent
                    style={{ marginTop: "-10px" }}
                    isMarkerShown={true}
                    googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + this.GOOGLE_MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places"}
                    loadingElement={<div style={{ height: `100%`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                    containerElement={<div style={{ height: `420px`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                    mapElement={<div style={{ height: `100%`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                />
            </div>
        )
    }

}
