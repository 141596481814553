import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { login, logout } from "../../actions/authentication/authentication";
import { Input, Button, Form } from 'reactstrap';
import blueBG from '../../assets/images/login/RIDEAU_BLEU.svg';
import yellowBG from '../../assets/images/login/RIDEAU_JAUNE.svg';


class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            error: null,
            disabled: false
        };

    }

    onLogin(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ disabled: true });

        this.props.onLogin(
            { email: this.state.username, password: this.state.password },
            (err) => {
                this.setState({ disabled: false });

                if (err) {
                    if (err.indexOf("ERRGRANT") !== -1) this.setState({ error: <FormattedMessage id="Access.Not.Yet.Granted" /> });
                    
                    else this.setState({ error: <FormattedMessage id="Auth.Failed" /> });
                } else {
                    window.location.href = "#/home/";
                }
            }
        );

        return false;
    }

    render() {
        return (
            <>
            <div className="container-fluid bg-blue m-0 p-0 min-h-100 w-100 b-fix">

                <div className="w-100 h-100 position-absolute m-0 p-0 d-none d-xl-block" style={{ top: -2 }}>
                    <img src={blueBG} alt="bg-img" className="h-100" style={{ marginLeft: -220 }} />
                    <img src={yellowBG} alt="bg-img" className="h-100 position-absolute" style={{ right: -220 }} />
                </div>

                <div className="row">
                    <div className="col-md-2">
                        <a href="#/" className="btn border xrounded text-white p-3 w-100 mb-2"><span className="arrowLeft">&#8592;</span><FormattedMessage id="Back" /></a>
                    </div>
                </div>

                <div className="position-relative max-w-350px mx-auto text-center" style={{ marginTop: "calc(50vh - 154.8px)" }}>

                    <div className="border xrounded bg-white text-center p-4 mx-auto mx-md-0">

                        <div className="mt-2 mb-2 text-center">
                            <Form onSubmit={(e) => this.onLogin(e)}>
                                <h5 className="bold mb-4"><FormattedMessage id="Login" /></h5>

                                <Input
                                    type="email"
                                    className="lowercase mb-3"
                                    placeholder={this.props.intl.formatMessage({ id: "Email" })}
                                    required
                                    onChange={(e) => this.setState({ "username": e.target.value, error: null })}
                                />

                                <div className="mb-3">
                                    <Input
                                        disabled={this.state.disabled}
                                        type="password"
                                        minLength="8"
                                        maxLength="40"
                                        placeholder={this.props.intl.formatMessage({ id: "password" })}
                                        required onChange={(e) => this.setState({ "password": e.target.value, error: null })}
                                    />
                                    <div className="text-danger mt-2"><small>{this.state.error}</small></div>
                                </div>

                                <div className="w-100 mb-4">
                                    <a href="#/recoverPwd" className="text-dark underline lowercase"><FormattedMessage id="Forgotten.Password" /></a>
                                </div>

                                <Button
                                    type="submit"
                                    disabled={this.state.disabled}
                                    className="btn bg-blue text-white w-100 srounded pl-5 pr-5 pt-3 pb-3"
                                    size="lg"
                                    block>
                                    <FormattedMessage id="Identify" />
                                </Button>
                            </Form>

                            <div className="w-100 mt-3">
                                <a href="#/aboutus" target="_blank" className="underline lowercase text-secondary"><FormattedMessage id="Who.Are.We" /></a>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        global: state.global
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogin: (data, callback) => dispatch(logout(login(data, callback)))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Login));