import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Col, Row } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { adds } from "../../../actions/global/global";
import { Form, RangeInput, NotEmpty, RadioInput, TextInput, DateTimeInput } from '@gferrand/react-forms';
import DateUtil from '../../../util/DateUtil';
import ReplacementEnt from '../../../entities/ReplacementEnt';
import AvailabilityEnt from '../../../entities/AvailabilityEnt';
import { calendarEventOverlaps } from '../../../actions/calendarEvent/calendarEvent';
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import { concludedAgreementOverlaps } from '../../../actions/agreement/agreement';
import { emailExists, phoneExists } from '../../../actions/user/user';
import ModalChoiceDays from '../../calendar/ModalChoiceDays';
import moment from 'moment';

class DAddCalendarReplacementModal extends React.Component {

	constructor(props) {
		super(props);

		this.date = moment();

		let startDate = this.props.startDate;
		let endDate = this.props.endDate;
		if (this.props.startDate === undefined && this.props.endDate === undefined) {
			startDate = moment().hours(0).minutes(0).seconds(0).toDate();
			endDate = moment().hours(0).minutes(0).seconds(0).toDate();
		} 

		this.state = {
			collapse: false,
			modal: null,
			replacementOverlapError: null,
			loadingOverlap: false,
			startDate: startDate,
			endDate: endDate,
			days: [],
			days_available: DateUtil.getDaysAvailable(startDate, endDate, "occasional", []),
			type: "occasional",
			error: null,
			retrocession: 60,
			nom_medecin: null,
			prenom_medecin: null,
			mail_medecin: null,
			warningMail: false,
			warningTel: false,
			errorSelectDayRegular: '',
			errorRegularDateAndDay: ''
		};

		this.buildAvailabilityForm();
		this.changeDate = this.changeDate.bind(this);
		this.backToDateOfTheDay = this.backToDateOfTheDay.bind(this);
		this.cancelSelectedDays = this.cancelSelectedDays.bind(this);
	};

	componentDidMount() {
		var period = {
			start: undefined,
			end: undefined
		}
		// Quand le user a selectionné une date ou une période
		if (this.props.endDate !== undefined) {
			period = ReplacementEnt.occasionalPeriod(this.props.startDate, this.props.endDate);
		}
		// For availabilities other than guard, cannot end on a sunday
		if (DateUtil.isDay(period.start, 0)) period.start = DateUtil.decrementDays(period.start, 1);
		var data = {
			type: "occasional",
			radius_preference: '',
			retrocession: 60,
			start_date: period.start,
			end_date: period.end,
			day: [],
		};

		let days_available = DateUtil.getDaysAvailable(this.props.startDate, this.props.endDate, this.state.type, this.state.days);

		// Si y'a des dates, on check les overlaps
		if (data.start_date !== undefined || data.end_date !== undefined) {
			days_available = DateUtil.getDaysAvailable(data.start_date, data.end_date, this.state.type, this.state.days);
			this.checkOverlap(data.start_date, data.end_date, data.type, days_available);
		}

		this.setState({
			days_available: days_available
		})
	}

	row(inputName) {
		return (
			<Row className="form-group">
				<Col md="5">{this.replacementForm.getLabel(inputName)}</Col>
				<Col md="7">{this.replacementForm.getInput(inputName)}</Col>
			</Row>
		);
	};

	pointingArrow = () => {
		if (!this.state.collapse) return <i className="fa fa-angle-down" />;
		return <i className="fa fa-angle-up" />
	}

	toggle = () => {
		this.setState({ 'collapse': !this.state.collapse });
	}


	// Permet de changer la date à la volée
	changeDate(date) {
		this.setState({
			error: null
		})

		// Si aucune date sélectionnéggtt             egttt ttt b!k ;
		if (this.state.days_available.length === 0) {
			let startDateTemp = date.toDate();
			let endDateTemp = date.hours(23).minutes(59).seconds(59).toDate();
			let days_available = [];

            if (this.state.type === 'regular') {
                let dayInString = DateUtil.nbrToDay(moment(startDateTemp).day());
                if (this.state.days.length === 0) {
                    this.setState({ 
                        errorSelectDayRegular: 'Vous devez sélectionner un jour de la semaine avant de choisir vos dates'
                    })
                    return;
                } else if (dayInString !== this.state.days) {
                    this.setState({
                        errorRegularDateAndDay: 'Vous devez sélectionner une date correspondant au jour de la semaine que vous avez sélectionné'
                    })
                    return;
                } else {
                    endDateTemp = moment(startDateTemp).add(this.state.duration - 1, 'weeks');
                    days_available = DateUtil.getDaysAvailable(startDateTemp, endDateTemp, "regular", this.state.days);
                    this.setState({
                        errorRegularDateAndDay: ''
                    })
                }
			} else {
				days_available = DateUtil.getDaysAvailable(startDateTemp, endDateTemp, this.state.type, this.state.days);
			}

            this.checkOverlap(startDateTemp, endDateTemp, this.state.type, days_available);
			this.setState({
				startDate: startDateTemp,
				endDate: endDateTemp,
				days_available: days_available
			})
			return;
		} 

		// change la date de début
		if (date.isBefore(this.state.startDate)) {
			let days_available = DateUtil.getDaysAvailable(date, this.state.endDate, this.state.type, this.state.days);
			let startAndEndDays = DateUtil.startAndEndDays(days_available);
			this.checkOverlap(date, startAndEndDays.end, this.state.type, days_available);
			this.setState({
				startDate: startAndEndDays.start,
				endDate: startAndEndDays.end,
				days_available: days_available
			})
		// change la date de fin
		} else if (date.isAfter(this.state.endDate)) {
			let days_available = DateUtil.getDaysAvailable(this.state.startDate, date, this.state.type, this.state.days);
			let startAndEndDays = DateUtil.startAndEndDays(days_available);
			this.checkOverlap(startAndEndDays.start, date, this.state.type, days_available);
			this.setState({
				startDate: startAndEndDays.start,
				endDate: startAndEndDays.end,
				days_available: days_available
			})
		} else {
			let days_available = Array.from(this.state.days_available);
			let days_available_filter = days_available.filter((day) => {
				return !moment(day.start).isSame(date, 'day');
			});
			let startAndEndDays = DateUtil.startAndEndDays(this.state.days_available);

			// Ici on retire un jour
			if (days_available.length !== days_available_filter.length) {
				let startAndEndDaysFilter = DateUtil.startAndEndDays(days_available_filter);
				this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available_filter);
				this.setState({
					startDate: startAndEndDaysFilter.start,
					endDate: startAndEndDaysFilter.end,
					days_available: days_available_filter
				})
			} else {
				let days_available = Array.from(this.state.days_available);
				let days_available_filter = days_available.filter((day) => {
					return !moment(day.start).isSame(date, 'day');
				});
				let startAndEndDays = DateUtil.startAndEndDays(this.state.days_available);
	
				// Ici on retire un jour
				if (days_available.length !== days_available_filter.length) {
					let startAndEndDaysFilter = DateUtil.startAndEndDays(days_available_filter);
					this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available_filter);
					this.setState({
						startDate: startAndEndDaysFilter.start,
						endDate: startAndEndDaysFilter.end,
						days_available: days_available_filter
					})
				} else {
					// Si ce n'est pas une garde, on ne peut pas rajouter les dimanches
					if (this.state.type === 'occasional' && date.day() !== 0) {
						// Si la date n'est pas dans le tableau, il faut la rajouter 
						days_available.push({
							start: date.toDate(),
							end: date.hours(23).minutes(59).seconds(59).toDate()
						});
						this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available);
						this.setState({
							days_available: days_available
						})
					} else if (this.state.type === 'regular' && date.day() !== 0) {
						let numberDay = DateUtil.dayToNbr(this.state.days);
						if (numberDay === date.day()) {
							days_available.push({
								start: date.toDate(),
								end: date.hours(23).minutes(59).seconds(59).toDate()
							});
							this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available);
							this.setState({
								days_available: days_available
							})
						}
					} else if (this.state.type === 'guard') {
						days_available.push({
							start: date.toDate(),
							end: date.hours(23).minutes(59).seconds(59).toDate()
						});
						this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, days_available);
						this.setState({
							days_available: days_available
						})
					}
				}
			}
		}
	}

	// Bouton qui annule la sélection de jours
	cancelSelectedDays() {
		this.setState({
			startDate: undefined,
			endDate: undefined,
			days_available: [],
			replacementOverlapError: null,
		})
	}

	// Bouton qui permet de revenir à la date du jour
	backToDateOfTheDay() {
		let startDateTemp = moment().startOf('day').toDate();
		let endDateTemp = moment().hours(23).minutes(59).seconds(59).toDate()
		
		let days_available = DateUtil.getDaysAvailable(startDateTemp, endDateTemp, this.state.type, this.state.days);
		this.checkOverlap(startDateTemp, endDateTemp, this.state.type, days_available);
		this.setState({
			startDate: startDateTemp,
			endDate: endDateTemp,
			days_available: days_available
		})
	}

	buildAvailabilityForm() {
		var submitCbk = (data, cbk) => {
			data = this.replacementForm.getRawData()
			data.days_available = this.state.days_available;

			if (data.type !== 'guard') {
				data.start_date = this.state.startDate;
				data.end_date = this.state.endDate;
			}
			if (data.type === 'guard') {
				data.guard_retrocession = data.retrocession;
			}
			if (data.type !== 'regular') {
				data.day = [];
			}

			if (!data.mail_medecin) {
				this.replacementForm.setError("mail_medecin", "Missing.Phone.And.Mail");
				cbk();
				return;
			} 

			if (!data.tel_medecin) {
				this.replacementForm.setError("tel_medecin", "Missing.Phone.And.Mail");
				cbk();
				return;
			}
			
			let notif = "ReplacementPrivate.Add.Succes.Out"
			if (this.state.warningMail !== false || this.state.warningTel !== false) {
				notif = "ReplacementPrivate.Add.Success";
			}

			this.props.onAdds("replacement/doc/private", data, () => {
				this.props.toggle();

				new Noty({
					type: "info",
					layout: 'topRight',
					theme: 'bootstrap-v4',
					text: this.props.intl.formatMessage({ id: notif }),
					timeout: 6000,
				}).show();
				
			}, () => {
				this.forceUpdate()
			});
			AvailabilityEnt.validate(this.replacementForm);

		};

		var period = ReplacementEnt.occasionalPeriod(this.props.startDate, this.props.endDate);
		let endDate = moment(this.props.endDate).add(1, 'day');
		var periodForGuard = ReplacementEnt.guardPeriod(this.props.startDate, endDate);
		// For availabilities other than guard, cannot end on a sunday
		if (DateUtil.isDay(period.start, 0)) period.start = DateUtil.decrementDays(period.start, 1);

		var data = {
			type: "occasional",
			radius_preference: '',
			retrocession: 60,
			start_date: periodForGuard.start,
			end_date: periodForGuard.end,
			day: [],
		};
		
        var startDateHint;
		this.replacementForm = new Form({
			name: "availability",
			inputs: [

				new RadioInput("type", "Availability.Type",
					[
						{ label: "Occasional.Fem", value: "occasional" },
						{ label: "Regular.Fem", value: "regular" },
						{ label: "Guard.Fem", value: "guard" },
					], [NotEmpty]),

				new RadioInput("day", "Availability.Days",
				[{ label: "Monday.FirstLetter", value: "monday" },
				{ label: "Tuesday.FirstLetter", value: "tuesday" },
				{ label: "Wednesday.FirstLetter", value: "wednesday" },
				{ label: "Thursday.FirstLetter", value: "thursday" },
				{ label: "Friday.FirstLetter", value: "friday" },
				{ label: "Saturday.FirstLetter", value: "saturday" }],
				[NotEmpty]),
				new DateTimeInput("start_date", "Start.DateTime", "fr", [], { hint: startDateHint, timeConstraints: { minutes: { step: 60 } } }),
                new DateTimeInput("end_date", "End.DateTime", "fr", [], { timeConstraints: { minutes: { step: 60 } } }),

				new RangeInput("retrocession", "Retrocession.Wished", 60, 100, 2.5, [NotEmpty], { suffix: "%", className: "blue-range-input" }),
				new TextInput("nom_medecin", "Nom Medecin", undefined, [NotEmpty]),
				new TextInput("prenom_medecin", "Prenom Medecin", undefined, [NotEmpty]),
				new TextInput("mail_medecin", "Mail Medecin", undefined,),
				new TextInput("tel_medecin", "Telephone Medecin", undefined,),
			],
			submitCbk,
			changeCbk: (input) => this.validateReplacementForm(input),
			data
		});

		this.replacementForm.hide("day");
	};

	// ================================================================
	// ========================== VALIDATION ==========================
	// ================================================================

	checkOverlap = (start, end, type, days_available) => {
		if (!start || !end) return;
		this.setState({ loadingOverlap: true });

		this.props.onConcludedAgreementOverlaps(start, end, type, undefined, days_available, (res) => {
			this.setState({ replacementOverlapError: res.overlaps });
		});

		this.setState({ loadingOverlap: false });
	};

	validateReplacementForm(input) {
		const rawData = this.replacementForm.getRawData();
		var { name, value } = input;

		if (input.name === "retrocession" ) {
			this.replacementForm.setValue("retrocession", value);
		}
		if (input.name === "nom_medecin" || input.name === "prenom_medecin" || input.name === "mail_medecin" || input.name === "tel_medecin") {
			this.replacementForm.setValue(name, input.value);
		}

		if (input.name === "type" && input.value === "guard") {
			this.setState({ type: 'guard'});
			this.replacementForm.setValue("type", "guard");
		}

		if (rawData.type === 'guard') {
			var availability = this.props.availability;
			ReplacementEnt.validateGuard(this.replacementForm, availability);
		}

		if (name === "mail_medecin") {
			var callback = (msg) => {
				if (msg.emailExists && msg.emailExists !== 'temporary_sub') {
					if (msg.emailExists === 'substitute') {
						this.setState({warningMail: "Email.Already.Exists.Warning"})
					} else if (msg.emailExists === 'doctor' || msg.emailExists === 'temporary') {
						this.setState({warningMail: "Email.Already.Exists"})
					}
				} else {
					this.setState({warningMail: false});
				}
			};

			this.props.onEmailExists(input.value, callback);
		}

		if (name === "tel_medecin") {
			var callbackTel = (msg) => {
				if (msg.phoneExists && msg.phoneExists !== "temporary_sub") {
					if (msg.phoneExists === 'substitute') {
						this.setState({warningTel: "Phone.Already.Exists.Warning"})
					} else if (msg.phoneExists === 'doctor' || msg.phoneExists === 'temporary') {
						this.setState({warningTel: "Phone.Already.Exists"})
					}
				} else {
					this.setState({warningTel: false})
				}
			};

			this.props.onPhoneExists(input.value, callbackTel);
		}

		if (name === "day") {
			this.setState({ 
				days: value,
				errorSelectDayRegular: '' 
			})

			this.checkOverlap(this.state.startDate, this.state.endDate, rawData.type, this.state.days_available);
			return;
		};

		// User switches replacement type, show / hide relevant fields
		if (name === "type") {
			if (value === "regular") {
				this.replacementForm.show("day");

				this.setState({
					startDate: undefined,
					endDate: undefined,
					days: [],
					type: "regular",
					days_available: [],
					replacementOverlapError: null,
					errorSelectDayRegular: '',
					errorRegularDateAndDay: ''
				})
			} else {
				this.replacementForm.hide("day");
				this.setState({
					startDate: undefined,
					endDate: undefined,
					days: [],
					type: value,
					days_available: [],
					replacementOverlapError: null,
					errorSelectDayRegular: '',
					errorRegularDateAndDay: ''
				})
			}
		};
	};

	render() {
		return (
			<React.Fragment>

				<Modal size="lg" isOpen={true} toggle={this.props.toggle} className="simple-modal simple-modal-white srounded">

					<ModalHeader toggle={this.props.toggle}>
					</ModalHeader>

					<ModalBody className="pl-2 pr-2 pl-md-5 pr-md-5 pt-0">
						<h4 className="w-100 uppercase text-center pb-3"><FormattedMessage id="Add.Replacement" /></h4>
						<div className="three-btns-inputs pt-3">
							{this.replacementForm.getInput("type")}
						</div>

						<div className="text-center mb-5">
							{this.replacementForm.getInput("day")}

							<div className="text-center text-danger">
								{this.state.errorSelectDayRegular}
								{this.state.errorRegularDateAndDay}
							</div>
						</div>

						{this.state.type !== 'guard' && 
							<div className="container">
								<ModalChoiceDays
									date={this.date}
									startDate={this.state.startDate}
									endDate={this.state.endDate}
									type={this.state.type}
									replacements={this.props.replacements}
									events={this.props.events}
									changeDate={this.changeDate}
									backToDateOfTheDay={this.backToDateOfTheDay}
									cancelSelectedDays={this.cancelSelectedDays}
									openBy={this.props.openBy}
									monthSelected={this.props.monthSelected}
									days_available={this.state.days_available}
									replacementOutOfHydrogen={true}
								/>
							</div>
						}

						<div className="w-100 text-center align-top">
							<div className="text-center text-danger">
								<small>{(this.state.replacementOverlapError) && <FormattedMessage id="Overlapping.Existing.Replacement" />}</small>
							</div>
						</div>

						<div className="collapsed-form">
							{this.state.type === 'guard' && 
								<div>
									{this.row("start_date")}
									{this.row("end_date")}
								</div>
							}
							{this.row("nom_medecin")}
							{this.row("prenom_medecin")}
							{this.row("mail_medecin")}
							{this.state.warningMail &&
								<div className="text-center">
									<small><FormattedMessage id={this.state.warningMail} /></small>
								</div>
							}
							{this.row("tel_medecin")}
							{this.state.warningTel &&
								<div className="text-center">
									<small><FormattedMessage id={this.state.warningTel} /></small>
								</div>
							}
							{this.row("retrocession")}
						</div>

						<div className="text-center form-buttons w-100 mt-5 mb-4">
							{this.replacementForm.submitButton("Publish", {
								disabled: (this.state.replacementOverlapError ||
									this.state.loadingOverlap ||
									this.state.startDate === undefined ||
									this.state.endDate === undefined),
								className: "srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green"
							})}
						</div>

					</ModalBody>
				</Modal>
			</React.Fragment>
		);
	};
};

const mapStateToProps = state => {
	return {
		users: state.global.users
	}
};

const mapDispatchToProps = dispatch => {
	return {
		onAdds: (objName, obj, cbk) => dispatch(adds(objName, obj, cbk)),
		onCalendarEventOverlaps: (start, end, days_available, type, cbk) => calendarEventOverlaps(start, end, days_available, type, cbk),
		onConcludedAgreementOverlaps: (start, end, type, duration, days_available, cbk) => concludedAgreementOverlaps(start, end, type, duration, days_available, cbk),
		onEmailExists: (email, cbk) => emailExists(email, cbk),
		onPhoneExists: (email, cbk) => phoneExists(email, cbk),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DAddCalendarReplacementModal));
