import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ModalChoiceDays from '../../calendar/ModalChoiceDays';
import { adds, gets } from "../../../actions/global/global";
import ConfModal from "../../../components/modal/ConfModal";
import DateUtil from '../../../util/DateUtil';
import OfficeTimeTableEnt from '../../../entities/OfficeTimeTableEnt';
import { replacementOverlaps } from '../../../actions/replacements/replacements';
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import { concludedAgreementOverlaps } from '../../../actions/agreement/agreement';
import { getsBy, clears } from '../../../actions/global/global';
import { calendarEventOverlaps } from '../../../actions/calendarEvent/calendarEvent';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import Datetime from "react-datetime";
import { allByDoctor } from '../../../actions/replacements/replacements';


class OccasionalOrRegularForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            replacementOverlapError: null,
            agreementOverlapError: null,
            loadingOverlap: false,
            startDate: undefined,
            endDate: undefined,
            days: [],
            type: "occasional",
            days_available: [],
            retrocession: 60,
            parking: false,
            housing: false,
            show_add_guard: false,
            expiration_date: '',
            duration: 4,
            guard_retrocession: 60,
            error: '',
            errorSelectDayRegular: '',
            errorRegularDateAndDay: '',
            errorRegularDaysMini: '',
            guards: [{
                start_date: '',
                start_date_error: '',
                end_date: '',
                end_date_error: '',
                office: false,
                visits: false,
                paid_by_the_fifteenth: false,
            }]
        };

        this.date = moment().startOf('month');

        this.props.onAddChangeTypeCbk((replType) => {
            this.setState({
                type: replType
            })
        });

		this.changeDate = this.changeDate.bind(this);
		this.cancelSelectedDays = this.cancelSelectedDays.bind(this);
		this.backToDateOfTheDay = this.backToDateOfTheDay.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeDateStartDateGuard = this.handleChangeDateStartDateGuard.bind(this);
        this.handleChangeDateEndDateGuard = this.handleChangeDateEndDateGuard.bind(this);
        this.handleChangeGuards = this.handleChangeGuards.bind(this);
        this.addGuard = this.addGuard.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createReplacement = this.createReplacement.bind(this);
    };

    componentDidMount() {
        if (!this.props.fromCalendar) {
            this.props.allByDoctor();
        }

        if (!this.props.calendarEvents) {
            this.props.onGetsBy("calendarEvent", ["start_date", "end_date"], [DateUtil.decrementDays(DateUtil.startOfMonth(new Date()), 7), moment().add(1, 'year').format('YYYY-MM-DD') ]);
        }

        if (this.props.startDate && this.props.endDate) {
            let daysAlreadyAvailable = [];
            if (this.props.replacements) {
                this.props.replacements.forEach((replacement) => {
                    daysAlreadyAvailable.push(replacement.days_available);
                })
            }
            let daysAlreadyAvailableFlat = daysAlreadyAvailable.flat();

            const days_available = DateUtil.getDaysAvailable(this.props.startDate, this.props.endDate, "occasional", this.state.days, daysAlreadyAvailableFlat, this.props.officeTimeTable);

            this.checkOverlap(this.props.startDate, this.props.endDate, "occasional", this.state.duration, days_available);
            this.setState({
                startDate: this.props.startDate,
                endDate: this.props.endDate,
                days_available: days_available
            })
        }
    }

    handleChange(event) {
		let { name, value } = event.currentTarget;

        if (name === "type" && value === 'guard') {
            this.props.onChangeReplType(value);
        } else if (name === "type" && value === 'occasional') {
            this.setState({
                days: [],
                startDate: undefined,
                endDate: undefined,
                days_available: [],
                replacementOverlapError: false,
                expiration_date: '',
                errorRegularDateAndDay: ''
            })
        } else if (name === "type" && value === "regular") {
            this.setState({
                startDate: undefined,
                endDate: undefined,
                days_available: [],
                replacementOverlapError: false,
                days: []
            })
        } else if (name === 'parking') {
            value = !this.state.parking;
        } else if (name === 'housing') {
            value = !this.state.housing;
        } else if (name === 'paid_by_the_fifteenth') {
            value = !this.state.paid_by_the_fifteenth;
        } else if (name === 'visits') {
            value = !this.state.visits;
        } else if (name === 'office') {
            value = !this.state.office;
        } else if (this.state.type === 'regular' && name === 'day') {
            this.setState({
                days: value,
                startDate: undefined,
                endDate: undefined,
                days_available: [],
                errorSelectDayRegular: ''
            })
            return;
        } else if (this.state.type === 'regular' && name === 'duration') {
            let endDate = moment(this.state.startDate).add(value, 'weeks');
            let days_available = DateUtil.getDaysAvailable(this.state.startDate, endDate, "regular", this.state.days, [], this.props.officeTimeTable);
		    let startAndEndDays = DateUtil.startAndEndDays(days_available);
            this.setState({
                days_available,
                startDate: startAndEndDays.start,
                endDate: startAndEndDays.end
            })            
        }

		this.setState({
			[name]: value
		})
    }

    handleChangeGuards(event, key) {
        let { name, value } = event.currentTarget;
		let guards = Array.from(this.state.guards);
        if (name === 'visits' || name === 'office' || name === 'paid_by_the_fifteenth') {
            value = !guards[key][name];
        }
		guards[key][name] = value;
        this.setState({
			guards
		});
    }

    handleChangeDate(date) {
        let error = '';
        if (moment(date).isBefore(moment(), 'day')) {
            error = "La date d'expiration ne peut être dans le passé";
        } 
        if (moment(date).isBefore(this.state.startDate, 'day')) {
            error = "La date d'expiration ne peut être avant la date de début";
        } 
        this.setState({
            expiration_date: moment(date),
            error
        })
    }

    handleChangeDateStartDateGuard(date, key) {
		let guards = Array.from(this.state.guards);
		guards[key]['start_date'] = date;
        let error = false;
        if (DateUtil.isDay(date, 6)) {
            if (DateUtil.hour(date) < 12) {
                error = true;
		        guards[key]['start_date_error'] = "Le Samedi les gardes commencent à 12h au plus tôt";
            } 
        } else if (DateUtil.isDay(date, 0)) {
            if (DateUtil.hour(date) < 8) {
                error = true;
		        guards[key]['start_date_error'] = "Le Dimanche les gardes commencent à 8h au plus tôt";
            } 
        } else {
            if (DateUtil.hour(date) < 18) {
                error = true;
		        guards[key]['start_date_error'] = "En semaine les gardes commencent à 18h au plus tôt";
            } 
        };
        // Must be within replacement scope
        if (moment(date).isBefore(this.state.startDate, 'day')) {
            error = true;
            guards[key]['start_date_error'] = "Cette date n'est pas comprise dans la période de remplacement";
        };
        if (moment(date).isAfter(this.state.endDate, 'day')) {
            error = true;
            guards[key]['start_date_error'] = "Cette date n'est pas comprise dans la période de remplacement";
        };
        if (this.state.type === "regular" && !DateUtil.sameDay(date, this.state.days)) {
            error = true;
            guards[key]['start_date_error'] = "La garde doit commencer sur le jour choisi pour le remplacement régulier";
        };
        if (error === false) {
            delete guards[key]['start_date_error'];
        }

        this.setState({
            guards
        })
    }

    handleChangeDateEndDateGuard(date, key) {
		let guards = Array.from(this.state.guards);
		guards[key]['end_date'] = date;

        let error = false;

        if (guards[key]['start_date'] && DateUtil.isNextDay(guards[key]['start_date'], date) && DateUtil.hour(date) > 8) {
            error = true;
            guards[key]['end_date_error'] = "Une garde ne peut pas finir après 8h";
        };
        if (guards[key]['start_date'] && !DateUtil.isSameOrNextDay(guards[key]['start_date'], date)) {
            error = true;
            guards[key]['end_date_error'] = "La garde doit terminer au plus tard le lendemain du jour où elle commence";
        };

        let startDateMoment = moment(guards[key]['start_date']);
        let endDateMoment = moment(date);
        let durationGuard = moment.duration(endDateMoment.diff(startDateMoment)).asHours();
        if (durationGuard < 4) {
            error = true;
            guards[key]['end_date_error'] = "Une garde ne peut pas durer moins de 4h";
        };

        if (this.state.endDate) {
            const nextDayAt8 = new Date(this.state.endDate);
            nextDayAt8.setDate(nextDayAt8.getDate() + 1)
            nextDayAt8.setHours(8);
            nextDayAt8.setMinutes(0);
            nextDayAt8.setMilliseconds(0);

            if (DateUtil.after(date, nextDayAt8)) {
                error = true;
                guards[key]['end_date_error'] = "Cette date n'est pas comprise dans la période de remplacement";
            };
            if (DateUtil.before(date, this.state.startDate)) {
                error = true;
                guards[key]['end_date_error'] = "Cette date n'est pas comprise dans la période de remplacement";
            };
        }

        if (error === false) {
            delete guards[key]['end_date_error'];
        }

        this.setState({
            guards
        })
    }

    createReplacement(data) {
        this.props.onAdds("replacement", data, () => {
            if (!this.props.fromCalendar) {
                this.props.updateCountAfterAccept();
            }
            this.props.toggle();

            new Noty({
                type: "info",
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: this.props.intl.formatMessage({ id: "Replacement.Add.Success" }),
                timeout: 6000,
            }).show();
        });
    }

    closeModal() {
        this.setState({ modal: null });
    };

    openConfModal(content, onConfirm, onCancel) {
        this.setState({
            modal: <ConfModal
                content={content}
                onConfirm={onConfirm}
                onCancel={onCancel}
                toggle={() => onCancel()} />
        });
    };

    checkOverlap = (start, end, type, duration, days_available) => {
        if(!start || (!end && !duration)) return;

        this.setState({ loadingOverlap: true });

        this.props.onConcludedAgreementOverlaps(start, end, type, duration, days_available, (res) => {
            this.setState({ agreementOverlapError: res.overlaps });
        });

        this.props.onReplacementOverlaps(start, end, type, duration, days_available, (res) => {
            this.setState({ replacementOverlapError: res.overlaps, loadingOverlap: false });
        });

    };

    changeDate(date) {
		if (date.isBefore(moment().subtract(1, 'day'), 'day')) {
            this.setState({
                error: 'La date ne peut pas être inférieur à la date du jour'
			})
			return;
        } else {
            this.setState({
                error: null
            })
		}
		// Si aucune date sélectionné
		if (this.state.days_available.length === 0) {
			let startDateTemp = date.toDate();
			let endDateTemp = date.hours(23).minutes(59).seconds(59).toDate();
            let days_available = [];
            if (this.state.type === 'regular') {
                let dayInString = DateUtil.nbrToDay(moment(startDateTemp).day());
                if (this.state.days.length === 0) {
                    this.setState({
                        errorSelectDayRegular: 'Vous devez sélectionner un jour de la semaine avant de choisir vos dates'
                    })
                    return;
                } else if (dayInString !== this.state.days) {
                    this.setState({
                        errorRegularDateAndDay: 'Vous devez sélectionner une date correspondant au jour de la semaine que vous avez sélectionné'
                    })
                    return;
                } else {
                    endDateTemp = moment(startDateTemp).add(this.state.duration - 1, 'weeks');
                    days_available = DateUtil.getDaysAvailable(startDateTemp, endDateTemp, "regular", this.state.days, [], this.props.officeTimeTable);
                    this.setState({
                        errorRegularDateAndDay: ''
                    })
                }
            } else {
                days_available = DateUtil.getDaysAvailable(startDateTemp, endDateTemp, this.state.type, this.state.days, [], this.props.officeTimeTable);
            }

            this.checkOverlap(startDateTemp, endDateTemp, this.state.type, this.state.duration, days_available);
			this.setState({
				startDate: startDateTemp,
				endDate: endDateTemp,
				days_available: days_available
			})
			return;
		}

		// change la date de début
		if (date.isBefore(this.state.startDate, 'day')) {
			let days_available = DateUtil.getDaysAvailable(date, this.state.endDate, this.state.type, this.state.days, [], this.props.officeTimeTable);
		    let startAndEndDays = DateUtil.startAndEndDays(days_available);

            this.checkOverlap(date, startAndEndDays.end, this.state.type, this.state.duration, days_available);
            if (days_available.length > 3) {
                this.setState({
                    errorRegularDaysMini: ''
                })
            }

			this.setState({
				startDate: startAndEndDays.start,
				endDate: startAndEndDays.end,
				days_available: days_available
			})
		// change la date de fin
		} else if (date.isAfter(this.state.endDate, 'day')) {
			let days_available = DateUtil.getDaysAvailable(this.state.startDate, date, this.state.type, this.state.days, [], this.props.officeTimeTable);
		    let startAndEndDays = DateUtil.startAndEndDays(days_available);
            this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, this.state.duration, days_available);
            if (days_available.length > 3) {
                this.setState({
                    errorRegularDaysMini: ''
                })
            }
			this.setState({
				startDate: startAndEndDays.start,
				endDate: startAndEndDays.end,
				days_available: days_available
			})
		} else {
			let days_available = Array.from(this.state.days_available);
			let days_available_filter = days_available.filter((day) => {
				return !moment(day.start).isSame(date, 'day')
			});
		    let startAndEndDays = DateUtil.startAndEndDays(this.state.days_available);

			if (days_available.length !== days_available_filter.length) {
                let startAndEndDaysFilter = DateUtil.startAndEndDays(days_available_filter);
                this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, this.state.duration, days_available_filter);
				this.setState({
                    startDate: startAndEndDaysFilter.start,
                    endDate: startAndEndDaysFilter.end,
					days_available: days_available_filter
				})
			} else {
                if (this.state.type === 'occasional' && date.day() !== 0) {
                    // Si la date n'est pas dans le tableau, il faut la rajouter 
                    days_available.push({
                        start: date.toDate(),
                        end: date.hours(23).minutes(59).seconds(59).toDate()
                    });
                    this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, this.state.duration, days_available);
                    this.setState({
                        days_available: days_available
                    })
                } else if (this.state.type === 'regular' && date.day() !== 0) {
                    let numberDay = DateUtil.dayToNbr(this.state.days);
                    if (numberDay === date.day()) {
                        days_available.push({
                            start: date.toDate(),
                            end: date.hours(23).minutes(59).seconds(59).toDate()
                        });
                        this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, this.state.duration, days_available);
                        if (days_available.length > 3) {
                            this.setState({
                                errorRegularDaysMini: ''
                            })
                        }
                        this.setState({
                            days_available: days_available
                        })
                    }
                } else if (this.state.type === 'guard') {
                    days_available.push({
                        start: date.toDate(),
                        end: date.hours(23).minutes(59).seconds(59).toDate()
                    });
                    this.checkOverlap(startAndEndDays.start, startAndEndDays.end, this.state.type, this.state.duration, days_available);
                    this.setState({
                        days_available: days_available
                    })
                }
			}
		}
	}

    cancelSelectedDays() {
		this.setState({
			startDate: undefined,
			endDate: undefined,
			days_available: [],
            replacementOverlapError: false,
            days: []
		})
	}

    // Bouton qui permet de revenir à la date du jour
	backToDateOfTheDay() {
		let startDateTemp = moment().startOf('day').toDate();
		let endDateTemp = moment().hours(23).minutes(59).seconds(59).toDate()
		
		let days_available = DateUtil.getDaysAvailable(startDateTemp, endDateTemp, this.state.type, [], [], this.props.officeTimeTable);
        this.checkOverlap(startDateTemp, endDateTemp, this.state.type, this.state.duration, this.state.days_available);
		this.setState({
			startDate: startDateTemp,
			endDate: endDateTemp,
			days_available: days_available
		})
	}

    addGuard() {
        let guards = Array.from(this.state.guards);
        if (this.state.show_add_guard === false) {
            this.setState({
                show_add_guard: true
            })
        } else {
            guards.push(
                {
                    start_date: '',
                    end_date: '',
                    office: false,
                    visits: false,
                    paid_by_the_fifteenth: false
                }
            )
            this.setState({
                guards
            })
        }
    }

    removeGuard(key) {
		let guards = Array.from(this.state.guards);
		guards.splice(key, 1);
		this.setState({
			guards
		});
    }

    handleSubmit() {
        let data = {
            housing: this.state.housing,
            parking: this.state.parking,
            retrocession: this.state.retrocession,
            days_available: this.state.days_available,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            type: this.state.type
        };

        if (this.state.type === 'regular') {
            if (data.days_available.length < 4) {
                this.setState({
                    errorRegularDaysMini: 'En remplacement régulier, vous devez avoir un minimum de 4 dates sélectionnées'
                })
                return;
            }

            if (this.state.expiration_date === '') {
                this.setState({
                    error: 'Vous devez renseigner une date d\'expiration'
                })
                return;
            }

            data.expiration_date = this.state.expiration_date;
            data.duration = this.state.duration;
            if (!Array.isArray(this.state.days)) {
                data.day = [this.state.days];
            } else {
                data.day = this.state.days;
            }
        }

        if (this.state.show_add_guard === true) {
            data.guards = this.state.guards;
            data.guard_retrocession = this.state.guard_retrocession;
        }

        var comments = [];
        if (this.state.type === "occasional") {
            // If replacement does not start on a Monday
            if (!DateUtil.isDay(this.state.startDate, 1)) {
                comments.push(<FormattedMessage id="Not.Start.Monday.Warning" />);
            };
            // If replacement starts on a day when doctor does not work
            if (!OfficeTimeTableEnt.worksOnDate(this.state.startDate, this.props.officeTimeTable)) {
                comments.push(<FormattedMessage id="Start.Date.Not.Office.TimeTable" />);
            };
            // If replacement ends on a day when doctor does not work
            if (!OfficeTimeTableEnt.worksOnDate(this.state.endDate, this.props.officeTimeTable)) {
                comments.push(<FormattedMessage id="End.Date.Not.Office.TimeTable" />);
            };
            // If replacement ends on a day that is not the cabinet's last working day
            if (DateUtil.day(this.state.endDate) !== OfficeTimeTableEnt.lastWorkingDay(this.props.officeTimeTable)) {
                comments.push(<FormattedMessage id="End.Date.Not.Office.Last.Day" />);
            };
        };

        if (comments.length > 0) {
            var warning = (
                <div className="mb-5">
                    <h5><FormattedMessage id="Note.Concerning.This.Repl" /></h5>
                    <div className="text-left mt-4 mb-4">
                        <ul>{comments.map(c => <li>{c}</li>)}</ul>
                    </div>
                    <FormattedMessage id="Confirm.Continue" />
                </div>
            );

            this.openConfModal(warning, () => this.createReplacement(data), () => {
                this.closeModal();
            });
        } else {
            this.createReplacement(data)
        };
    }

    render() {
        let weeks = [
            {
                name: 'monday',
                value: 'L'
            }, {
                name: 'tuesday',
                value: 'M'
            }, {
                name: 'wednesday',
                value: 'M'
            }, {
                name: 'thursday',
                value: 'J'
            }, {
                name: 'friday',
                value: 'V'
            }, {
                name: 'saturday',
                value: 'S'
            }
        ];
        return (
            <React.Fragment>
                <div className="form-group three-btns-inputs row">
                    <div className="col-md-7">
                        <div className="w-100 collapse show">
                            <div className="btn-group" id="type">
                                <button name="type" value="occasional" type="button" onClick={this.handleChange} className={"radio-btn btn btn-primary" + (this.state.type === 'occasional' ? ' active' : '')}>
                                    Remplacement<br></br>occasionnel
                                </button>
                                <button name="type" value="regular" type="button" onClick={this.handleChange} className={"radio-btn btn btn-primary" + (this.state.type === 'regular' ? ' active' : '')}>
                                    Remplacement<br></br>régulier
                                </button>
                                <button name="type" value="guard" type="button" onClick={this.handleChange} className={"radio-btn btn btn-primary" + (this.state.type === 'guard' ? ' active' : '')}>
                                    garde
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.type === 'regular' &&
                    <div className="form-group row text-center">
                        <div className='text-center col-12'>
                            <FormattedMessage id="Indicate.Days.Replacement.Regular"/>
                        </div>
                        <div className="col-md-12">
                            <div className="w-100 collapse show">
                                <div id="day" className="btn-group">
                                    {weeks.map((week, key) => 
                                        <button key={key} type="button" value={week.name} name="day" onClick={this.handleChange} className={"radio-btn btn btn-primary " + (this.state.days === week.name ? 'active' : '')}>
                                            {week.value}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="text-center text-danger">
                                {this.state.errorSelectDayRegular}
                            </div>
                        </div>
                    </div>
                }

                <div className="container">
                    <div className='pb-4'>
                        {this.state.type === 'occasional' &&
                            <FormattedMessage id="Indicate.Days.Replacement"/>
                        }
                    </div>
                    <ModalChoiceDays
                        date={this.date}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        days={this.state.days}
                        type={this.state.type}
                        replacements={this.props.replacements}
                        events={this.props.calendarEvents}
                        changeDate={this.changeDate}
                        backToDateOfTheDay={this.backToDateOfTheDay}
                        cancelSelectedDays={this.cancelSelectedDays}
                        openBy={this.props.openBy}
                        monthSelected={this.props.monthSelected}
                        days_available={this.state.days_available}
                    />
                    <div className="text-center text-danger">
                        {this.state.errorRegularDateAndDay}
                    </div>
                </div>

                {this.state.type === 'regular' &&
                    <>
                        <div className="form-group row mt-5">
                            <div className="col-md-5">
                                <label className='col-form-label control-checkbox' htmlFor="expiration_date"><FormattedMessage id="Expiration.Date.2"/></label>
                            </div>
                            <div className="col-md-7">
                                <div className="w-100 collapse show">
                                    <DatePicker
                                        className="no-color-input"
                                        format={"dd/MM/yyyy"}
                                        clearIcon={null}
                                        id="expiration_date"
                                        value={(this.state.expiration_date) ? new Date(Date.parse(this.state.expiration_date)) : ""}
                                        invalid={!!this.state.error}
                                        autoComplete="off"
                                        onChange={this.handleChangeDate}
                                        onFocus={(e) => { e.stopPropagation() }}
                                        disabled={false}
                                        showLeadingZeros={true}
                                    />
                                </div>
                                <div className="text-center text-danger">
                                    {this.state.error}
                                </div>
                            </div>
                            <div className='small'>
                                <FormattedMessage id="Repl.Renewal.Hint"/>
                            </div>
                        </div>
                    </>
                }

                <div className="form-group row mt-5">
                    <div className="col-md-5">
                        <label className='col-form-label control-checkbox' htmlFor="retrocession"><FormattedMessage id="Retrocession"/></label>
                    </div>
                    <div className="col-md-7">
                        <div className="w-100 collapse show">
                            <div className="text-center">
                                <input id="retrocession" min="60" max="100" step="2.5" type="range" className="form-control blue-range-input form-control" name="retrocession" value={this.state.retrocession} onChange={this.handleChange} />
                                {this.state.retrocession} %
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-md-5">
                        <label className='col-form-label control-checkbox' htmlFor="parking"><FormattedMessage id="Parking"/></label>
                    </div>
                    <div className="col-md-7">
                        <div className="w-100 collapse show">
                            <label className="control control-checkbox">
                                <input type="checkbox" id="parking" name="parking" value={this.state.parking} onChange={this.handleChange} />
                                <div className="control_indicator"></div>
                            </label>
                        </div>
                    </div>
                </div>
                
                <div className="form-group row">
                    <div className="col-md-5">
                        <label className='col-form-label control-checkbox' htmlFor="housing"><FormattedMessage id="Accommodation"/></label>
                    </div>
                    <div className="col-md-7">
                        <div className="w-100 collapse show">
                            <label className="control control-checkbox">
                                <input type="checkbox" id="housing" name="housing" value={this.state.housing} onChange={this.handleChange} />
                                <div className="control_indicator"></div>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-12 col-md-5">
                        <label className='col-form-label control-checkbox' htmlFor="contain_guard"><FormattedMessage id="Repl.Contains.Guard"/></label>
                    </div>
                    <div className="col-12 col-md-7">
                        <div className="row">
                            <i className="clickable fa fa-plus-square customAddButton" onClick={this.addGuard}></i>
                        </div>
                    </div>
                </div>

                {this.state.show_add_guard === true &&
                    <>
                        {this.state.guards.map((guard, key) =>
                        
                            <div key={key} className="add-guard-remplacement">
                                <div className="collapse show">
                                    <div className="collapse show">
                                        <div className="form-group row">
                                            <div className="col-12 col-md-5">
                                                <label className='col-form-label control-checkbox' htmlFor="start_date_guard"><FormattedMessage id="Start.DateTime"/></label>
                                            </div>
                                            <div className="col-12 col-md-7">
                                                <Datetime
                                                    locale="FR"
                                                    value={(guard.start_date) ? new Date(guard.start_date) : ""}
                                                    inputProps={{ 
                                                        readOnly: true,
                                                        placeholder: "--/--/---- --:--", 
                                                        disabled: false, 
                                                        id: "start_date_guard",
                                                        className: "form-control " + ((!!this.state.error) ? "is-invalid" : "")
                                                    }}
                                                    onChange={(event) => this.handleChangeDateStartDateGuard(event, key)}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-center text-danger">
                                            {guard.start_date_error}
                                        </div>
                                    </div>
                                </div>
                                <div className="collapse show">
                                    <div className="collapse show">
                                        <div className="form-group row">
                                            <div className="col-12 col-md-5">
                                                <label className='col-form-label control-checkbox' htmlFor="end_date_guard"><FormattedMessage id="End.DateTime"/></label>
                                            </div>
                                            <div className="col-12 col-md-7">
                                            <Datetime
                                                    locale="FR"
                                                    value={(guard.end_date) ? new Date(guard.end_date) : ""}
                                                    inputProps={{ 
                                                        readOnly: true,
                                                        placeholder: "--/--/---- --:--", 
                                                        disabled: false, 
                                                        id: "end_date_guard",
                                                        className: "form-control " + ((!!this.state.error) ? "is-invalid" : "")
                                                    }}
                                                    onChange={(event) => this.handleChangeDateEndDateGuard(event, key)}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-center text-danger">
                                            {guard.end_date_error}
                                        </div>
                                    </div>
                                </div>
                                <div className="collapse show">
                                    <div className="form-group row">
                                        <div className="col-12 col-md-5">
                                            <label className='col-form-label control-checkbox'  htmlFor="office"><FormattedMessage id="Guard.In.Office"/></label>
                                        </div>
                                        <div className="col-12 col-md-7">
                                            <label className="control control-checkbox">
                                                <input type="checkbox" id="office" name="office" className="form-control" checked={guard.office} onChange={(event) => this.handleChangeGuards(event, key)} />
                                                <div className="control_indicator"></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="collapse show">
                                    <div className="form-group row">
                                        <div className="col-12 col-md-5">
                                            <label className='col-form-label control-checkbox' htmlFor="visits"><FormattedMessage id="Guard.Visits"/></label>
                                        </div>
                                        <div className="col-12 col-md-7">
                                            <label className="control control-checkbox">
                                                <input type="checkbox" id="visits" name="visits" className="form-control" checked={guard.visits} onChange={(event) => this.handleChangeGuards(event, key)} />
                                                <div className="control_indicator"></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="collapse show">
                                    <div className="form-group row">
                                        <div className="col-12 col-md-5">
                                            <label className='col-form-label control-checkbox'  htmlFor="paid_by_the_fifteenth"><FormattedMessage id="Paid.By.Fifteenth"/></label>
                                        </div>
                                        <div className="col-12 col-md-7">
                                            <label className="control control-checkbox">
                                                <input type="checkbox" id="paid_by_the_fifteenth" name="paid_by_the_fifteenth" className="form-control" checked={guard.paid_by_the_fifteenth} onChange={(event) => this.handleChangeGuards(event, key)} />
                                                <div className="control_indicator"></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="clickable close-btn" onClick={() => this.removeGuard(key)}>x</div>
                            </div>
                        )}

                        <div className="collapse show">
                            <div className="form-group row">
                                <div className="col-12 col-md-5">
                                    <label className='col-form-label control-checkbox'  htmlFor="paid_by_the_fifteenth"><FormattedMessage id="Guard.Retrocession"/></label>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="text-center">
                                        <input id="guard_retrocession" min="60" max="100" step="2.5" type="range" className="form-control red-range-input form-control" name="guard_retrocession" value={this.state.guard_retrocession} onChange={this.handleChange} />
                                    {this.state.guard_retrocession} %
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

                <div className="text-center text-danger">
                    <small>{(this.state.replacementOverlapError) && <FormattedMessage id="Overlapping.Existing.Replacement.Of.Same.Type" />}</small>
                </div>

                <div className="text-center text-danger">
                    <small>{(this.state.agreementOverlapError) && <FormattedMessage id="Overlapping.Replacement" />}</small>
                </div>

                <div className="mt-5 w-100 mx-auto text-center form-buttons mb-3">
                    <div className="text-center text-danger">
                        {this.state.errorRegularDaysMini}
                    </div>
                    <button type="button" disabled={this.state.days_available.length === 0 || this.state.replacementOverlapError || this.state.loadingOverlap ? true : false} onClick={this.handleSubmit} className="srounded pl-5 pr-5 pt-2 pb-2 text-white bg-green btn  opacity-1">
                        <FormattedMessage id="Publish" />
                    </button>
   
                </div>
                {this.state.modal}

            </React.Fragment>
        );
    }

};

const mapStateToProps = state => {
    return {
        officeTimeTable: state.global.officeTimeTable,
        replacements: state.replacements.replacements,
        calendarEvents: state.global.calendarEvents
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAdds: (objName, obj, cbk) => dispatch(adds(objName, obj, cbk)),
        onReplacementOverlaps: (start, end, type, duration, days_available, cbk) => replacementOverlaps(start, end, type, duration, days_available, cbk),
		onConcludedAgreementOverlaps: (start, end, type, duration, days_available, cbk) => concludedAgreementOverlaps(start, end, type, duration, days_available, cbk),
		onCalendarEventOverlaps: (start, end, days_available, type, cbk) => calendarEventOverlaps(start, end, days_available, type, cbk),
        onGets: (objName) => dispatch(gets(objName)),
        allByDoctor: () => dispatch(allByDoctor()),
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OccasionalOrRegularForm));