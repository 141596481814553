import axios from "axios";

const rootUrl = process.env.REACT_APP_BE_URL || "http://localhost:7000";

export function addExceptionalOpen(exceptionalOpen, callback) {

	return dispatch => {
		dispatch(request());

        var url = rootUrl + "/exceptionalOpen";

        let params = { exceptionalOpen };

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure())
        });
	};

    function request() { return { type: "ADD_EXCEPTIONAL_OPEN_REQUEST", addExceptionalOpen: false, }}
	function success(result) { return { type: "ADD_EXCEPTIONAL_OPEN_SUCCESS", addExceptionalOpen: true, exceptionalOpen: result }}
	function failure(error) { return { type: "ADD_EXCEPTIONAL_OPEN_FAILURE", addExceptionalOpen: false, error: error }}
};

export function getsExceptionalOpen(params, callback) {

	return dispatch => {
		dispatch(request());

        var url = rootUrl + "/exceptionalOpens";

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure())
        });
	};

    function request() { return { type: "GETS_EXCEPTIONAL_OPEN_REQUEST", getExceptionalOpen: false, }}
	function success(result) { return { type: "GETS_EXCEPTIONAL_OPEN_SUCCESS", getExceptionalOpen: true, exceptionalOpen: result }}
	function failure(error) { return { type: "GETS_EXCEPTIONAL_OPEN_FAILURE", getExceptionalOpen: false }}
};

export function editExceptionalOpen(exceptionalOpen, callback) {

	return dispatch => {
		dispatch(request());

        var url = rootUrl + "/exceptionalOpen/" + exceptionalOpen._id;

        let params = { exceptionalOpen };

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure())
        });
	};

    function request() { return { type: "EDIT_EXCEPTIONAL_OPEN_REQUEST", editExceptionalOpen: false, }}
	function success(result) { return { type: "EDIT_EXCEPTIONAL_OPEN_SUCCESS", editExceptionalOpen: true, exceptionalOpen: result }}
	function failure(error) { return { type: "EDIT_EXCEPTIONAL_OPEN_FAILURE", editExceptionalOpen: false, error: error }}
};

export function deleteExceptionalOpen(exceptionalOpenId, callback) {
    return dispatch => {
		dispatch(request());
        var url = rootUrl + "/exceptionalOpen/" + exceptionalOpenId;

        return axios.delete(url)
            .then(function (response) {
                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                    if (callback) callback(obj);
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "DELETE_EXCEPTIONAL_OPEN_REQUEST", isDeleteExceptionalOpen: false}}
	function success(result) { return { type: "DELETE_EXCEPTIONAL_OPEN_SUCCESS", isDeleteExceptionalOpen: true, exceptionalOpen: result }}
	function failure() { return { type: "DELETE_EXCEPTIONAL_OPEN_FAILURE", isDeleteExceptionalOpen: false }}
};

export function getsExceptionalOpenByDoctors(params, callback) {

	return dispatch => {
		dispatch(request());

        var url = rootUrl + "/exceptionalOpens/by-doctors";

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure())
        });
	};

    function request() { return { type: "GETS_EXCEPTIONAL_OPEN_BY_DOCTORS_REQUEST", getExceptionalOpenByDoctors: false, }}
	function success(result) { return { type: "GETS_EXCEPTIONAL_OPEN_BY_DOCTORS_SUCCESS", getExceptionalOpenByDoctors: true, exceptionalOpen: result }}
	function failure(error) { return { type: "GETS_EXCEPTIONAL_OPEN_BY_DOCTORS_FAILURE", getExceptionalOpenByDoctors: false }}
};