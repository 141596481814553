import React from 'react';
import { Row, Col, Button } from 'reactstrap';

export default class AddPatientStep4 extends React.Component {

	constructor(props) {
		super(props);

		this.reset = this.reset.bind(this);
	}

	reset() {
		this.props.resetStore();
		this.props.jumpToStep(0);
	}

	render() {

		return (
			<Row className="step step1 mt-2 justify-content-md-center ">
				<Col lg="8">
					<h5>Validation</h5>
					<p>Vous pouvez indiquer à votre patient qu’il sera recontacté prochainement.</p>
					<p>Merci pour votre aide !</p>
					<Row className="mx-auto form-buttons">
						<Button color="secondary" className="btn btn-ladda lowercase srounded pl-5 pr-5 pt-2 pb-2 bg-grey text-white mx-auto" onClick={this.props.cancelCbk}>Retourner à l'étude</Button>
						<Button color="success" className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green mx-auto" onClick={this.reset}>Ajouter un nouveau patient</Button>
					</Row>
				</Col>
			</Row>
		);
	};
};