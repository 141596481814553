import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Card } from 'reactstrap';
import DoctorProfile from "./DoctorProfile";
import CGV from '../../subscription/CGV';
import { clears, getsBy } from '../../../actions/global/global';

import DoctorPracticesProfile from './DoctorPracticesProfile';
import DoctorAboutProfile from './DoctorAboutProfile';
import DoctorMoreDetailsProfile from './DoctorMoreDetailsProfile';
import OfficeTimetable from '../../doctors/office/OfficeTimetable';
import Subscription from '../../../enum/Subscription';


class DoctorAccountProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            subscriptions_replacement: null,
            subscriptions_succession: null,
            modal: null,
            list_subscriptions_replacement: [],
            list_subscriptions_succession: [],
        }

        this.openCGV = this.openCGV.bind(this);
		this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        if (this.props.user) {
            this.fetchData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            this.fetchData();
        }
    }

    fetchData() {
        this.props.onGetsBy("subscription", 'user_id', this.props.user._id, (subscriptions) => {
            if (subscriptions && subscriptions.length > 0) {
                subscriptions.forEach((elem) => {
                    if (elem.subscribe_active === true && this.props.user.role === 'doctor' && (elem.choice === 4 || elem.choice === 5 || elem.choice === 6 || elem.choice === 7)) {
                        this.setState({
                            subscriptions_replacement: elem.choice,
                            list_subscriptions_replacement: elem.list_subscriptions
                        })
                    }

                    if (elem.subscribe_active === true && (elem.choice === 8 || elem.choice === 9 || elem.choice === 10 || elem.choice === 11) ) {
                        this.setState({
                            subscriptions_succession: elem.choice,
                            list_subscriptions_succession: elem.list_subscriptions
                        })
                    }
                })
            }
        })
    }

    closeModal() {
        this.setState({ modal: null });
    }

	openCGV() {
		this.setState({
			modal: <CGV 
				close={this.closeModal}
				user={this.props.user}
			/>
		})
	}


    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    render() {
        const { user, doctorCharacteristic, officeTimeTable } = this.props;

        if (!user || !doctorCharacteristic) return this.loading();

        let labelReplacement = '';
        if (this.state.subscriptions_replacement !== null) {
            labelReplacement = Subscription.getLabel(this.state.subscriptions_replacement);
            if (this.state.subscriptions_replacement === 4 || this.state.subscriptions_replacement === 5 || this.state.subscriptions_replacement === 6 || this.state.subscriptions_replacement === 7) {
                labelReplacement = 'premium';
            }
        }

        let labelSuccession = '';
        if (this.state.subscriptions_succession !== null) {
            labelSuccession = Subscription.getLabel(this.state.subscriptions_succession);
            if (this.state.subscriptions_succession === 8 || this.state.subscriptions_succession === 9 || this.state.subscriptions_succession === 10 || this.state.subscriptions_succession === 11) {
                labelSuccession = 'alliance';
            }
        }

        return (
            <React.Fragment>

                <React.Fragment>

                    <div className="w-100 text-white text-center uppercase mb-4 lp-2">
                        <h4><FormattedMessage id="My.Profile" /></h4>
                    </div>

                    <Row>

                        <Col sm="12" md="6">

                            <Card className="pt-5 rounded-0">
                                <DoctorProfile
                                    doctor={user}
                                    doctorCharacteristic={doctorCharacteristic}
                                    officeTimeTable={officeTimeTable}
                                    showChar={false}
                                />
                            </Card>

                            {user.role === 'doctor' && (this.state.subscriptions_replacement !== null || this.state.subscriptions_succession !== null) &&
                            <Card className="pt-5 rounded-0">
                                <div className="text-center">
                                    <h5><b><FormattedMessage id="My.Subscription" /></b></h5>
                                </div>
                                <div className="pt-1 pb-5 text-center">
                                    {this.state.subscriptions_replacement !== null &&
                                        <div>
                                            <b>
                                                <FormattedMessage id={"Info.Subscribe.Offer"} />{labelReplacement}
                                            </b>

                                            {this.state.list_subscriptions_replacement && this.state.list_subscriptions_replacement.length > 0 &&
                                                <div>
                                                    Vous partagez cet abonnement avec : &nbsp;
                                                    {this.state.list_subscriptions_replacement.map((elem) => 
                                                        <div>
                                                            {elem.first_name} {elem.name}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                    }

                                    {this.state.subscriptions_succession !== null &&
                                        <div>
                                            <b>
                                                <FormattedMessage id={"Info.Subscribe.Offer"} />{labelSuccession}
                                            </b>
                                            {this.state.list_subscriptions_succession && this.state.list_subscriptions_succession.length > 0 &&
                                                <div>
                                                    Vous partagez cet abonnement avec : &nbsp;

                                                    {this.state.list_subscriptions_succession.map((elem) => 
                                                        <div>
                                                            {elem.first_name} {elem.name}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                    }

                                    <div onClick={this.openCGV} className="clickable collapse-text">
                                        <i>Ouvrir les CGV</i>
                                    </div>
                                </div>
                            </Card>
                        }

                            {/* ne pas décommenter */}
                            {/* <Card className="p-5 text-center rounded-0">
                                <div className="text-left">
                                    <DoctorRatingsProfile doctor={user} />
                                </div>
                            </Card> */}

                        </Col>

                        <Col sm="12" md="6">

                            <Card className="p-5 text-center rounded-0" style={{ minHeight: "713px" }}>

                                <div className="text-left mx-auto">

                                    <div className="text-center">
                                        <DoctorPracticesProfile doctorCharacteristic={doctorCharacteristic} />
                                    </div>

                                    <div className="mt-4">
                                        <DoctorAboutProfile doctorCharacteristic={doctorCharacteristic} />
                                    </div>

                                    <div className="mt-5 mb-4">
                                        <DoctorMoreDetailsProfile doctorCharacteristic={doctorCharacteristic} />
                                    </div>

                                </div>

                            </Card>

                        </Col>

                        <Col sm="12" md="12">

                            <div className="max-w-800px mx-auto m-0 p-0">
                                <OfficeTimetable
									register={false}
                                />
                            </div>

                        </Col>
                        {this.state.modal}
                    </Row>

                </React.Fragment>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
        doctorCharacteristic: state.global.doctorCharacteristic,
        officeTimeTable: state.global.officeTimeTable
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorAccountProfile);


