import React from 'react';
import { InputComposer, InputComponent } from '@gferrand/react-forms';


export default class StarsInput extends InputComposer {

    valid = () => true;

    getComponent() {
        return <StarsInputComponent starsNbr={this.starsNbr} />;
    }

}

class StarsInputComponent extends InputComponent {

    buildInput(index) {
        return (
            <span key={index}
                onClick={() => this.onChange(index)}
                className={"fa icon-ICON_STHETO_GRIS_C font-size-25 clickable " + ((this.state.value >= index) ? "gold" : "") }>
            </span>
        )
    }

    render() {
        return super.wrap(
            <div>
                {this.buildInput(1)}
                {this.buildInput(2)}
                {this.buildInput(3)}
                {this.buildInput(4)}
                {this.buildInput(5)}
            </div>
        );
    }

};