import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import GDPRModal from "../components/gdpr/GDPRModal";

import blueBG from '../assets/images/login/RIDEAU_BLEU.svg';
import yellowBG from '../assets/images/login/RIDEAU_JAUNE.svg';
import homeImg1 from '../assets/images/login/HOME_ILLU_01.png';
import homeImg2 from '../assets/images/login/HOME_ILLU_02.png';
import homeImg3 from '../assets/images/login/HOME_ILLU_03.png';
import homeImg4 from '../assets/images/login/HOME_ILLU_04.png';
import homeImg5 from '../assets/images/login/HOME_ILLU_05.png';
import homeImg6 from '../assets/images/login/HOME_ILLU_06.png';

import Footer from './Footer';
const bgImgs = [
	{ img: homeImg1, top: -2, left: 30 },
	{ img: homeImg2, top: -2, left: 35 },
	{ img: homeImg3, top: -2, left: 30 },
	{ img: homeImg4, top: -2, left: 35 },
	{ img: homeImg5, top: -2, left: 30 },
	{ img: homeImg6, top: -2, left: 30 },
];

class Login extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			username: "",
			password: "",
			collapse: false,
			error: null,
			modal: null
		};

		this.CATCHPHRASES = [
			`Simple et gratuite <br />
			Hydrogen est la première <br />
			interface médicale géolocalisée <br />
			qui facilite l'interaction entre <br />
			les médecins et les remplaçants.`,

			`Géolocalisez <br />
			<small>- Les médecins qui cherchent un remplacement </small><br />
			<small>- Les remplaçants disponibles dans votre secteur </small><br />`,

			`Paramétrez vos critères <br />
			de recherche pour trouver <br />
			un remplacement sur mesure.`,

			`Gagnez du temps :  <br />
			les annonces sont mises <br />
			à jour et triées en temps réel. <br />`,

			`Vos disponibilités sont visibles par les médecins. <br />
			La gestion de votre planning est optimale.`,

			`Libérez vous des contraintes <br />
			administratives : optez pour la signature  <br />
			électronique du contrat.
			`,
		]
	}

	toggle() {
		this.setState({ 'collapse': !this.state.collapse });
	}

	closeModal() {
		this.setState({ modal: null });
	}
	
	componentDidMount() {
		const divImg = document.getElementById('img-container');
		const divTxt = document.getElementById('txt-container');
		const timeout = 5000;
		let i = 0;
		const transition = () => {
			if (i > 5) i = 0;
			
			if (divImg.children.length !== 0){
			 	divImg.removeChild(divImg.children[0]);
			}
			if (divTxt.children.length !== 0){
				divTxt.removeChild(divTxt.children[0]);
			}

			const newImage = document.createElement('img');
			newImage.src = bgImgs[i].img ;
			newImage.className = 'h-100 position-absolute animation-img';
			newImage.id = 'login-bg-img';
			newImage.style.top =  bgImgs[i].top;
			newImage.style.right = '30px';
			newImage.style.minHeight = '600px';
			divImg.appendChild(newImage);
			
			const newTxt = document.createElement('h4');
			newTxt.className = 'lh-30 lp-3 min-h-280px animation-txt';
			newTxt.id='login-page-catchprhrases';
			newTxt.innerHTML = this.CATCHPHRASES[i];
			divTxt.appendChild(newTxt);
			i++;
		};

		this.interval = setInterval(transition, timeout);
		transition();
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	render() {
		return (
			<>

				<div id="login-page-container" className="container-fluid m-0 p-0 bg-blue b-fix min-h-100">

					<div className="w-100 h-100 position-absolute m-0 p-0 d-none d-xl-block" style={{ top: -2 }}>
						<img src={blueBG} alt="bg-img" id="blueBg" className=" h-100" style={{ marginLeft: -220, minHeight: 700 }} />
						<span id = "img-container" ></span>
						<img src={yellowBG} alt="bg-img" id="yellowBg" className="h-100 position-absolute" style={{ right: -520, minHeight: 700 }} />
					</div>


					<div className="position-relative ml-md-5 mt-2 mt-md-4">

						<div className="max-w-350px border xrounded bg-white text-center p-4 mx-auto mx-md-0">

							<div className="mt-4">
								<div className="hydrogen-logo mx-auto mb-2"></div>
								<div className="bold">HYDROGEN</div>
							</div>

							<div className="mt-4 mb-2">
								<a href="#/registration" className="btn border xrounded bg-blue text-white p-3 w-100 mb-3"><FormattedMessage id="I.Register" /></a>
								<a href="#/login" className="btn border xrounded bg-blueish text-white p-3 w-100"><FormattedMessage id="I.Login" /></a>
							</div>

						</div>

						<div id = "txt-container" className="uppercase text-white mt-4 max-w-600px pl-3 p-sm-0 pr-3 pr-sm-0 mx-auto mx-md-0 text-left text-sm-center text-md-left">
							
						</div>

					</div>

				</div>

				<GDPRModal />
				<Footer />
			</>
		);
	}
}

export default connect(null, null)(injectIntl(Login));
