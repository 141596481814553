import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Form, NotEmpty } from '@gferrand/react-forms';
import { emailExists } from '../../../../actions/user/user';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { Link } from 'react-router-dom';

import { EXPAND_LEFT } from 'react-ladda';

import CheckboxInput from '../../../../components/form/CheckBoxInput';
import SubmitButton from '../../../../components/SubmitButton';

class MustBeTrue {

    static name() {
        return "MustBeTrue";
    }

    static valid(value) {
        return value === true;
    }

    static error() {
        return null;
    }

    static toJoi() {
        return ".boolean().invalid(false)";
    }

}

class DoctorForm extends Component {

    constructor(props) {
        super(props);

        const { submitCbk } = this.props;

        this.state = {
            disabled: true,
            loading: false
        };

        // Listen to the onChange event to check email unicity
        const changeCbk = () => {
            const formData = this.form.getRawData();

            this.setState({ disabled: (!formData.assert_secretary) });
        };

        this.form = new Form({
            name: "conditions",
            inputs: [
                new CheckboxInput("assert_secretary", "Assert.Secretary.Condition", [NotEmpty, MustBeTrue]),
                // new CheckboxInput("assert_data", "Assert.Agree.Data.Condition", [NotEmpty, MustBeTrue]),
                // new CheckboxInput("assert_license", "Assert.License.Condition", [NotEmpty]),
                // new CheckboxInput("accept_contacted", "Assert.Contact.Condition", [NotEmpty, MustBeTrue]),
                // new CheckboxInput("accept_contacted_clinicaltrials", "Assert.Contact.ClinicalTrials.Condition", [NotEmpty, MustBeTrue]),
            ],
            submitCbk,
            changeCbk,
            options: { validate: this.props.validate }
        });

        this.openAvatarPicker = null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.defaultValues !== this.props.defaultValues) {
            this.form.inputs.forEach((elem) => {
                this.form.setValue(elem.name, this.props.defaultValues[elem.name])
            })
        }
    }

    validate() {
        let isValid = true;
        let isFormValid = true;
        this.form.inputs.forEach((elem) => {
            isValid = elem.valid();
            if (isValid === false) {
                isFormValid = false;
            }
        })
        return isFormValid;
    }

    row(form, input) {
        return (
            <Row className="form-group">
                <Col xs="1" className="cond-form">{form.getInput(input)}</Col>
                <Col xs="11" className="cond-form pl-4 pl-sm-0">{form.getLabel(input)}</Col>
            </Row>
        );
    }

    isValidated() {
		return this.validate(); 
	}

    validateToScrollTop() {
        window.scrollTo(0, 0)
    }

    render() {
        var { defaultValues } = this.props;

        if (!defaultValues) defaultValues = {};

        return (
            <React.Fragment>

                <Row className="step step1 mb-4 justify-content-md-center ">
                    <Col lg="8">
                        <Card className="rounded-0">

                            <CardBody className="p-5">

                                <div className="w-100 text-center mb-3">
                                    <h5><FormattedMessage id="Validation" /></h5>
                                </div>

                                {this.row(this.form, "assert_secretary")}
                                {/* {this.row(this.form, "assert_data")} */}
                                {/* {this.row(this.form, "assert_license")} */}
                                {/* {this.row(this.form, "accept_contacted")}
                                {this.row(this.form, "accept_contacted_clinicaltrials")} */}

                                <div><i>Cliquez <Link to="/terms" target="_blank">ici</Link> pour consulter les CGV / CGU</i></div>


                                <Row className="mt-5">

                                <div className="text-center w-100">

                                    <div className="d-inline-block align-top mb-3 mb-sm-0 mx-auto">
                                        <div
                                            className={"btn btn-ladda lowercase srounded pl-5 pr-5 pt-2 pb-2 bg-blue text-white mx-auto"}
                                            onClick={() => this.back()}
                                            data-style={EXPAND_LEFT}>

                                            <FormattedHTMLMessage id="Previous.Step" />
                                        </div>
                                    </div>

                                    <div className="d-block d-sm-inline-block m-0 p-0 h-0">&nbsp;&nbsp;&nbsp;</div>

                                    <div className="d-inline-block align-top mx-auto">
                                        <SubmitButton loading={this.state.loading} disabled={this.state.disabled} form={this.form}/>
                                    </div>

                                </div>

                                </Row>

                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };
};

const mapStateToProps = state => {
    return {
        //
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEmailExists: (email, cbk) => emailExists(email, cbk)
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DoctorForm);