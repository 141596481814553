export default class Util{

    static contains(array, obj){
        if(!array || array.length === 0) return false;

        for(let a of array) if(a === obj) return true;
        return false;
    }

    static null(val){
        return (val === undefined || val === null);
    }

    static emptyString(val){
        return (Util.null(val) || ("" + val).replace(" ", "") === "");
    }

    static emptyObject(val){
        return Util.null(val) || Object.keys(val).length <= 0;
    }

    static isEmail(email){
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static isPhone(phone){
        var re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
        return re.test(String(phone).toLowerCase());
    }

    static truncate(txt, size){
        if(Util.emptyString(txt)) return txt;
        
        if(txt.length > size) return txt.substring(0, size).trim() + "...";
        else return txt;
    }

    static fstLetUpCase(txt){
        return txt.charAt(0).toUpperCase() + txt.slice(1);
    }

    static clone(obj){
        return Object.assign({}, obj);
    }

    static stringify(obj) {
        var result;

        try {
            result = JSON.stringify(obj);
        } catch (e) {
            result = {};
        }

        return result;
    }

    static parse(txt) {
        return JSON.parse(txt);
    }

    static formatPhoneNumber(nbr){
        return nbr.toString().replace(/\B(?=(\d{2})+(?!\d))/g, " ");
    }
}