import React from 'react';
import { Row, Col } from "reactstrap";
import AccountingEnt from '../../../../entities/AccountingEnt';
import Maths from '../../../../util/Maths';

const AccountingMissionTotalRow = ({ loading, accountings, getMissionCertificate, user, totalLines }) => {

    const total = {
        credit_card_total: 0,
        cash_total: 0,
        cheque_total: 0,
        secu_total: 0,
        third_party_payer: 0,
        retrocession_total: 0,
        totalAmount: 0
    };

    accountings.forEach((accounting) => {
        accounting = AccountingEnt.calculateCorrectPrices(accounting);

        total.credit_card_total += accounting.credit_card ? Maths.round(accounting.credit_card) : 0
        total.cash_total += accounting.cash ? accounting.cash : 0
        total.cheque_total += accounting.cheque ? accounting.cheque : 0
        total.secu_total += accounting.secu ? accounting.secu : 0
        total.third_party_payer += accounting.payed_by_third_party ? accounting.payed_by_third_party : 0
        total.retrocession_total += accounting.retrocessionAmount ? accounting.retrocessionAmount : 0
        total.totalAmount += accounting.total
    })

    const { credit_card_total, cash_total, cheque_total, third_party_payer, secu_total, retrocession_total } = total;

    return (
        <div className="mt-4">
            <Row className="totalFooterColor m-0 pb-3 pt-3 mb-2 pl-5">
                <Col className="text-left font-weight-bold">{totalLines.length} Jour(s)</Col>
                <Col className="text-left font-weight-bold">{credit_card_total ? credit_card_total : '-'}</Col>
                <Col className="text-left font-weight-bold">{cheque_total ? cheque_total : '-'}</Col>
                <Col className="text-left font-weight-bold">{cash_total ? cash_total : '-'}</Col>
                <Col className="text-left font-weight-bold">{secu_total ? secu_total + third_party_payer : '-'}</Col>
                {user.role !== "doctor" && <Col className="text-left font-weight-bold">{retrocession_total ? Maths.round(retrocession_total) : '-'}</Col>}
                <Col>{
                    loading ?
                        <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-muted mx-auto" role="status"></div></div>
                        :
                        <i onClick={!loading ? () => getMissionCertificate(retrocession_total) : null} className={"fa icon-ICON__RECU_GRIS_C clickable"}></i>

                }</Col>

            </Row>
        </div>
    );
}

export default AccountingMissionTotalRow;