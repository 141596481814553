import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import 'react-datetime/css/react-datetime.css';
import DReplacementCard from './DReplacementCard';
import { clears, getsBy } from '../../../actions/global/global';

import Pagination from "react-js-pagination";

import invitationImg from '../../../assets/images/search/HYDROGEN_ILLU_INVITATION.png';

require('moment/locale/fr');
require('moment/locale/en-gb');

class DPrivateReplacements extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            loading: this.props.replacementId ? true : false,

            // Pagination
            skip: 0,
            limit: 10
        };

        this.updateReplacementAfterAccept = this.updateReplacementAfterAccept.bind(this);
    };

    componentDidMount() {
        this.fetchData();
    };

    fetchData() {
        this.setState({ loading: true });
        this.props.onGetsBy("replacement/private", ["skip", "limit"], [this.state.skip, this.state.limit],
            () => this.setState({ loading: false }));
    }

    updateReplacementAfterAccept() {
        this.fetchData();
        this.props.updateCountAfterAccept();
    }

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    render() {
        const { replacements, replacementsCount } = this.props;
        
        return (
            <React.Fragment>

                {(this.state.loading) && this.loading()}
                
                {(!this.state.loading && (!replacements || !replacements.length)) &&
                    <div className="text-center mt-5 mb-2 mx-auto" style={{ width: 250 }}>
                        <div className="mb-3 mx-auto" style={{ width: 150, height: 150 }}>
                            <img src={invitationImg} alt="" width={150} height={150} />
                        </div>

                        <div className="uppercase text-white"><FormattedHTMLMessage id="You.Have.Invited.No.Substitute.Yet" /></div>

                        <a href="#/home/availability/find" alt="" className="btn srounded mt-4 pl-5 pr-5 pt-2 pb-2 text-white bg-white text-blue lp-1 mx-auto">
                            <b><FormattedHTMLMessage id="Start.Research" /></b>
                        </a>
                    </div>
                }

                {(!this.state.loading && replacements && replacements.length > 0) &&
                    <>
                        <div className="w-100 text-center text-white mb-3">
                            {replacementsCount}&nbsp;
                            {(replacementsCount > 1) ? <FormattedMessage id="Results" /> : <FormattedMessage id="Result" />}
                        </div>

                        {
                            replacements
                                .sort((r0, r1) => new Date(r1.createdAt) - new Date(r0.createdAt))
                                .map(r => <DReplacementCard key={r._id} replacement={r} updateReplacementAfterAccept={this.updateReplacementAfterAccept} redirectAfterSign={this.props.redirectAfterSign}/>)
                        }

                        <Pagination
                            innerClass="w-100 pagination justify-content-center"
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={Math.round(this.state.skip / this.state.limit) + 1}
                            itemsCountPerPage={this.state.limit}
                            totalItemsCount={Math.max(replacementsCount, replacements.length)}
                            pageRangeDisplayed={5}
                            onChange={(page) => this.setState({ skip: (page - 1) * this.state.limit }, this.fetchData)}
                            hideDisabled={false}
                        />
                    </>
                }

                {this.state.modal}

            </React.Fragment >
        );
    }
}



const mapStateToProps = state => {
    return {
        replacements: state.global.replacements,
        replacementsCount: state.global.replacementsCount
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DPrivateReplacements));



