import React from 'react';
import { Container, Row, Col } from "reactstrap";


const AccountingTableHeader = ({ monthly, mission, subcategory }) => {

    const pickColumnWord = () => {
        if (subcategory && subcategory !== "consultation") return 'Heure';
        else {
            if (mission) return 'Date';
            else return 'Heure';
        }
    }

    const pickSecondColumnWord = () => {
        if (monthly) return 'Jour';
        else {
            if (subcategory === "income" || subcategory === "outcome") return 'Raison';
            else return "Patient";
        }
    }

    return (
        <Container className="mb-3 mt-3 pl-5">
            <Row>
                {(!monthly) && <Col className="text-left tableHeaders">{pickColumnWord()}</Col>}
                {(!mission) && <Col className="text-left tableHeaders">{pickSecondColumnWord()}</Col>}
                {(subcategory === "consultation" || monthly || mission) ? <Col className="text-left tableHeaders">CB</Col> : <Col className="text-left tableHeaders"></Col>}
                {(subcategory === "consultation" || monthly || mission) ? <Col className="text-left tableHeaders">Chèque</Col> : <Col className="text-left tableHeaders"></Col>}
                {(subcategory === "consultation" || monthly || mission) ? <Col className="text-left tableHeaders">Cash</Col> : <Col className="text-left tableHeaders"></Col>}
                {(subcategory === "consultation" || monthly || mission) ? <Col className="text-left tableHeaders">SECU</Col> : <Col className="text-left tableHeaders"></Col>}
                <Col className="text-left tableHeaders">Montant</Col>
                <Col className="text-left tableHeaders"></Col>
            </Row>
        </Container>
    );
}

export default AccountingTableHeader;