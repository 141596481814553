import APIUrl from "../APIUrl";
import axios from 'axios';

export const calendarEventOverlaps = function(start, end, days_available, type, callback){
    return axios.post(APIUrl.checkCalendarEventOverlap, { start, end, days_available, type })
        .then(function(response){
            if(callback) callback(response.data.message);
        })
        .catch(function(err){
            throw err;
        });
};

export const getCalendarEventPreventAvailabilities = (substitute_id, cbk) => {
    return axios.get(APIUrl.getCalendarEventPreventAvailabilities.replace("{{substitute_id}}", substitute_id))
        .then(function (response) {
            if (cbk) cbk(response.data.message.calendarEvents);
        })
        .catch(function (err) {
            throw err;
        });
};