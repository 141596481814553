import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom'; 
import AddDoctor from './AddDoctor';
import Subscription from '../../enum/Subscription';
import { add } from "../../actions/global/global";
import CGV from './CGV';
// import Noty from 'noty';

class RegisterSubReplacement extends React.Component {


	constructor(props) {
		super(props);

		this.state = {
			choice: 0,
			choice_number_doctors: 1,
			choice_text: null,
			accept_subscription: false,
            modal: null,
			list_subscriptions: [{
				name: '',
				first_name: '',
				mail: '',
				phone: ''
			}],
			viewAddDoctor: false
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCheckBox = this.handleCheckBox.bind(this);
		this.handleChangeDoctor = this.handleChangeDoctor.bind(this);
		this.openCGV = this.openCGV.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.addDoctor  = this.addDoctor.bind(this);
	}

	componentDidMount() {
		if (this.props.match.params.category === 'succession') {
			this.setState({ choice: 3 });
		} else if (this.props.match.params.category === 'replacement-doctor') {
			this.setState({ choice: 4 });
		} else if (this.props.match.params.category === 'succession-doctor') {
			this.setState({ choice: 8 });
		}
	}

	closeModal() {
        this.setState({ modal: null });
    }

	openCGV() {
		this.setState({
			modal: <CGV 
				user={this.props.user}
				close={this.closeModal}
			/>
		})
	}

	addDoctor() {
		let list_subscriptions = Array.from(this.state.list_subscriptions);
		let newDoctor = {
			name: '',
			first_name: '',
			mail: '',
			phone: ''
		}
		list_subscriptions.push(newDoctor);

		this.setState({
			list_subscriptions
		})
	}

	handleChange(event) {
		event.preventDefault();
		let fieldname = event.currentTarget.name;
		let value = event.currentTarget.value;
		let viewAddDoctor = false;
		if (fieldname === 'choice' && (value === "5" || value === "6" || value === "7" || value === "9" || value === "10" || value === "11")) {
			let list_subscriptions = Array.from(this.state.list_subscriptions);
			let newDoctor = {
				name: '',
				first_name: '',
				mail: '',
				phone: ''
			}

			if (value === "5" || value === "9") {
				if (list_subscriptions.length > 1) {
					list_subscriptions.splice(1);
				}
			} else if (value === "6" || value === "10") {
				if (list_subscriptions.length > 2) {
					list_subscriptions.splice(0, list_subscriptions.length - 1);
				}
				list_subscriptions.push(newDoctor);
			} else if (value === "7" || value === "11") {
				viewAddDoctor = true;
				list_subscriptions.push(newDoctor);
				list_subscriptions.push(newDoctor);
			}

			this.setState({
				list_subscriptions,
				viewAddDoctor
			})
		}

		if (fieldname === 'choice' || fieldname === 'choice_number_doctors') {
			value = parseInt(value);
		}

		this.setState({
			[fieldname]: value
		});
	}

	handleChangeDoctor(index, name, value) {
		let list_subscriptions = Array.from(this.state.list_subscriptions, (elem, key) => {
			let doctor = Object.assign({}, elem)
			if(key === index) {
				doctor[name] = value;
			}
			let list_subscriptions = Object.assign({}, doctor);
			return list_subscriptions
		});

		this.setState({
			list_subscriptions
		});
	}

	handleCheckBox(event) {
		this.setState({ accept_subscription: event.currentTarget.checked });
	}
	
	handleSubmit(e) {
		e.preventDefault();

		let subscription = { 
			choice: this.state.choice,
			choice_text: Subscription.getLabel(this.state.choice),
			user_id: this.props.user._id,
			role: this.props.user.role,
			choice_number_doctors: this.state.choice_number_doctors
		};

		if ((this.props.match.params.category === 'replacement-doctor' || this.props.match.params.category === 'succession-doctor') && (this.state.choice === 5 || this.state.choice === 6 || this.state.choice === 7 || this.state.choice === 9 || this.state.choice === 10 || this.state.choice === 11)) {
			subscription.list_subscriptions = this.state.list_subscriptions;
		}

		this.props.onAdd("subscription", subscription, () => {
			this.props.history.push({
				pathname: '/home/subscription',
				state: {
					openModal: true,
					choice: this.state.choice
				}
			})
		});
	}

	render() {
		const { user } = this.props;
		const nameSubmit = this.props.intl.formatMessage({id: 'I.Subscription'});
		let values = [];
		if (this.props.match.params.category === 'replacement') {
			values = Subscription.values().filter(elem => elem.value < 3 );
		} else if (this.props.match.params.category === 'succession') {
			values = Subscription.values().filter(elem => elem.value === 3);
		} else if (this.props.match.params.category === 'replacement-doctor') {
			if (this.state.choice_number_doctors === 1) {
				values = Subscription.values().filter(elem => elem.value === 4);
			} else if (this.state.choice_number_doctors === 2) {
				values = Subscription.values().filter(elem => elem.value > 3 && elem.value <= 5 );
			} else if (this.state.choice_number_doctors === 3) {
				values = Subscription.values().filter(elem => elem.value > 3 && elem.value <= 6 );
			} else if (this.state.choice_number_doctors === 4) {
				values = Subscription.values().filter(elem => elem.value > 3 && elem.value <= 7 );
			}
		} else if (this.props.match.params.category === 'succession-doctor') {
			if (this.state.choice_number_doctors === 1) {
				values = Subscription.values().filter(elem => elem.value === 8);
			} else if (this.state.choice_number_doctors === 2) {
				values = Subscription.values().filter(elem => elem.value >= 8 && elem.value <= 9 );
			} else if (this.state.choice_number_doctors === 3) {
				values = Subscription.values().filter(elem => elem.value >= 8 && elem.value <= 10 );
			} else if (this.state.choice_number_doctors === 4) {
				values = Subscription.values().filter(elem => elem.value >= 8 && elem.value <= 11 );
			}
		}
		
		let numberDoctors = [];
		if (user && user.role === 'doctor') {
			numberDoctors = [{
				label: '1',
				value: 1
			}, {
				label: '2',
				value: 2
			}, {
				label: '3',
				value: 3
			}, {
				label: '4 et +',
				value: 4
			}]
		}
		let title = 'Service Remplacement';
		if (this.props.match.params.category === 'succession') {
			title= 'Service Installation';
		} else if (this.props.match.params.category === 'succession-doctor') {
			title= 'Service Succession/Collaboration';
		}

		return (
            <div className="step step1 mb-4 justify-content-md-center row">
				<div className="col">
					<h4 className="text-white text-center">{title}</h4>

					<div className="rounded-0 card">
						<div className="p-5 card-body">

							{user && 
								<form onSubmit={this.handleSubmit}>

									{user.role === 'doctor' &&
										<div className="form-group row">
											<label className="col-4 col-form-label" htmlFor="choice_number_doctors"><FormattedMessage id={"Numbers.Doctors"} /></label>
											<div className="col-8">
												<select className="form-control" name="choice_number_doctors" maxLength="20" value={this.state.choice_number_doctors} onChange={this.handleChange} required>
												{numberDoctors.map((elem, key) =>
													<option key={key} value={elem.value}>{elem.label}</option>
												)}
												</select>
											</div>
										</div>
									}

									<div className="form-group row">
										{user.role === 'doctor' ?
											<label className="col-4 col-form-label" htmlFor="choice">Combien êtes-vous à vous abonner ?</label>
											:
											<label className="col-4 col-form-label" htmlFor="choice"><FormattedMessage id={"Formule"} /></label>
										}	

										<div className="col-8">
											<select className="form-control" name="choice" maxLength="20" value={this.state.choice} onChange={this.handleChange} required >
											{values.map((elem, key) =>
												<option key={key} value={elem.value}>{elem.label}</option>
											)}
											</select>
										</div>
									</div>

									{user.role === 'doctor' && ((this.state.choice > 4 && this.state.choice < 8) || (this.state.choice > 8 && this.state.choice < 12)) && 
										<div className="form-group row ">
											<div className="col-md-6 offset-md-5 pt-3 pb-3">
												<b>Indiquez la liste des médecins de votre cabinet qui participent au service :</b>
											</div>
											{this.state.list_subscriptions.map((elem, key) => 
												<AddDoctor
													key={key}
													index={key}
													handleChange={this.handleChangeDoctor}
													doctor={elem}
												/>
											)}

											{this.state.viewAddDoctor === true && 
												<div className="col-md-3 offset-md-9">
													<button type="button" onClick={this.addDoctor} className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-blue btn btn-secondary">
														Ajouter un médecin
													</button>
												</div>
											}

										</div>
									}
									<h5 className="text-center">Rappel</h5>

									<div className="form-group row">
										<label className="col-4 col-form-label" htmlFor="name"><FormattedMessage id={"Name"} /></label>
										<div className="col-8">
											<input className="form-control" disabled type="text" name="name" value={user.name} />
										</div>
									</div>

									<div className="form-group row">
										<label className="col-4 col-form-label" htmlFor="first_name"><FormattedMessage id={"First.Name"} /></label>
										<div className="col-8">
											<input className="form-control" disabled type="text" name="first_name" value={user.first_name} />
										</div>
									</div>

									<div className="form-group row">
										<label className="col-4 col-form-label" htmlFor="phone_mobile"><FormattedMessage id={"Phone.Mobile"} /></label>
										<div className="col-8">
											<input className="form-control" disabled type="tel" name="phone_mobile" value={user.phone_mobile} />
										</div>
									</div>

									<div className="form-group row">
										<label className="col-4 col-form-label" htmlFor="email"><FormattedMessage id={"Email"} /></label>
										<div className="col-8">
											<input className="form-control" disabled type="email" name="email" value={user.email} />
										</div>
									</div>

									<div className="form-group row">
										<div className="col-1 offset-3">
											<label className="">
												<input checked={this.state.accept_subscription} type="checkbox" name="accept_subscription" onChange={this.handleCheckBox} required />
											</label>
										</div>

										<div className="cond-form col-7">
											<label>
												{user.role === 'doctor' ?
													<span className="clickable collapse-text" onClick={this.openCGV} >J’ai lu et j’accepte les conditions générales de vente</span>
													:
													<span className="clickable collapse-text" onClick={this.openCGV} >J’ai lu et j’accepte les conditions générales d'utilisation</span>
												}
											</label>
										</div>
									</div>

									<div className="row justify-content-center">
										<Link to="/home/subscription" className="ladda-button btn srounded pl-5 pr-5 pt-2 pb-2 text-white bg-blue btn text-center mx-auto">
											<FormattedMessage id={"Back"} />
										</Link>
										<input className="ladda-button btn srounded pl-5 pr-5 pt-2 pb-2 text-white bg-green btn text-center mx-auto" id="submitSubscription" type="submit" value={nameSubmit} />
									</div>
								</form>
							}			
						</div>

					</div>

				</div>
                {this.state.modal}
            </div>
		);
	}
}


const mapStateToProps = state => {
	return {
        user: state.global.user,
	}
}

const mapDispatchToProps = dispatch => {
	return {
        onAdd: (objName, obj, cbk) => dispatch(add(objName, obj, cbk))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RegisterSubReplacement));