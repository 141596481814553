import React from 'react';
import SubstituteProfile from './SubstituteProfile';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Util from '../../../util/Util';
import { FormattedMessage } from 'react-intl';

class SubstituteProfileModal extends React.Component {

    render() {
        const { replacement, characteristics, substitute, availability, agreement } = this.props;
        const optionsDate = {year: 'numeric', month: 'long', day: 'numeric' };

        if(!substitute) return null;
        return (
            <React.Fragment>

                <Modal size="md" isOpen={true} toggle={this.props.closeModal} className="simple-modal simple-modal-white srounded" >

                    <ModalHeader className="border-0" toggle={this.props.closeModal} style={{ minHeight: 95 }}>
                        
                        <div style={{ textAlign: "left", fontSize: "14px", textTransform: "none" }}>
                            {(!Util.null(characteristics.concludedAgreementsCount)) &&
                                <div className="">
                                    {availability &&  <><i className="fa icon-ICON__SIGNATURE_GRIS_C"></i>
                                    <b>  <FormattedMessage id="Demands.Number" />  {availability && new Date(availability.createdAt).toLocaleDateString('fr-FR', optionsDate)} </b></>}
                                </div>
                            }
                        </div>
                                
                        {availability &&  <><div style={{ textAlign: "left", fontSize: "14px", textTransform: "none" }}>
                            {(!Util.null(characteristics.concludedAgreementsCount)) &&
                                <div className="">
                                    <i className="fa icon-ICON_STHETO_GRIS_C gold"></i>
                                    <b> <FormattedMessage id="Best.retrocession.offer" /> 90 %</b>
                                </div>
                            }
                        </div></>}

                        <div style={{ textAlign: "left", fontSize: "14px", textTransform: "none" }}>
                            {/* Pour Occasionnal Regular et Gardes */}
                        </div>
                    </ModalHeader>

                    <ModalBody className="p-0">
                        <div className="mt-sm--50">
                            <SubstituteProfile
                                replacement={replacement}
                                substitute={substitute}
                                characteristics={characteristics}
                                availability={availability}
                                onSignContract={this.props.onSignContract}
                                onProposeReplacement={this.props.onProposeReplacement}
                                //Add here a method to pass to substitute profile for showing the planning
                                agreement={agreement}
                                downloadContract={this.props.downloadContract}
                                sendToCouncil={this.props.sendToCouncil}
                                cancelAgreement={this.props.cancelAgreement}
                                onDelete={this.props.onDelete}
                                openNegociationModal={this.props.openNegociationModal}
                                closeModal={this.props.closeModal}
                                dateStartAndEnd={this.props.dateStartAndEnd}
                                />
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default SubstituteProfileModal;