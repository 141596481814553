import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from '../components/header/Header.js';
import Footer from '../components/footer/footer.jsx';
import ConnectedRoutes from '../routes/ConnectedRoutes';
import GDPRModal from "../components/gdpr/GDPRModal";
import { injectIntl } from 'react-intl';
import axios from 'axios';
import UnexpectedErrorModal from '../components/modal/UnexpectedErrorModal';
import StartupPostModal from './StartupPostModal';
import { connect } from 'react-redux';
import DTransmissionReminderModal from '../views/doctors/transmissions/DTransmissionReminderModal';
import DModalEditAppointment from '../views/doctors/appointments/DModalEditAppointment.js';
import SModalEditAppointment from '../views/substitutes/appointments/SModalEditAppointment.js';
import SecretaryModalEditAppointment from '../views/secretaries/appointments/SecretaryModalEditAppointment.js';
import EventManager from '../util/EventManager';
import { get, gets, clears, getsBy, upd, clear } from '../actions/global/global';
import { getAllByDoctor, getAllBySecretary } from '../actions/secretaryManagement/secretaryManagement';
import { getClinicalStudy } from '../actions/clinicalStudy/clinicalStudy';
import { removeAppointmentModal } from '../actions/appointment/appointment';
import STransmissionModal from '../views/doctors/transmissions/STransmissionModal';
import Noty from 'noty';
import Util from '../util/Util';
import moment from 'moment';

class Homepage extends React.Component {

	constructor(props) {
		super(props);

		this.updateDimensions = this.updateDimensions.bind(this);

		this.state = {
			isOpen: false,
			width: window.innerWidth,
			modal: null,
			settings: [{
				theme: 'light',
				layout: 'horizontal',
				dir: 'ltr',
				sidebartype: 'full',
				sidebarpos: 'fixed',
				headerpos: 'fixed',
				boxed: 'boxed',
				navbarbg: 'skin1',
				sidebarbg: 'skin1',
				logobg: 'skin3'
			}],
			modalEditAppointmentDoctor: false,
			modalEditAppointmentSub: false,
			modalEditAppointmentSec: false,
			appointment: ''
		};

		this.props.history.listen((location, action) => {
			if (window.innerWidth < 767 &&
				document.getElementById('main-wrapper').className.indexOf("show-sidebar") !== -1) {
				document.getElementById('main-wrapper').classList.toggle("show-sidebar");
			}
		});

		// If a 400 error, happens, display error modal + refresh page on click
		axios.interceptors.response.use(null, async (error) => {
			if (error.response && error.response.status === 400) {
				this.setState({
					modal: <UnexpectedErrorModal error={error.response} close={() => document.location.reload(true)} />
				});
			}

			return Promise.reject(error);
		});

		this.openAppointmentModal = this.openAppointmentModal.bind(this);
		this.closeModalEditAppointment = this.closeModalEditAppointment.bind(this);
	}

	componentDidMount() {
		window.addEventListener("load", this.updateDimensions);
		window.addEventListener("resize", this.updateDimensions);
		//if (this.props.user && this.props.user.role !== "secretary") 
			this.props.getClinicalStudy();
	}

	updateDimensions() {
		let element = document.getElementById('main-wrapper');
		this.setState({
			width: window.innerWidth
		});
		switch (this.state.settings[0].sidebartype) {
			case 'full':
			case 'iconbar':
				if (this.state.width < 1170) {
					element.setAttribute("data-sidebartype", "mini-sidebar");
					element.classList.add("mini-sidebar");
				} else {
					element.setAttribute("data-sidebartype", this.state.settings[0].sidebartype);
					element.classList.remove("mini-sidebar");
				}
				break;

			case 'overlay':
				if (this.state.width < 767) {
					element.setAttribute("data-sidebartype", "mini-sidebar");
				} else {
					element.setAttribute("data-sidebartype", this.state.settings[0].sidebartype);
				}
				break;

			default:
		}
	}

	componentWillMount() {
		Promise.all([
			this.props.onGet("user"),
		]).then(() => {
			const { user } = this.props;

			if (!user) return;

			if (!user.alreadyPosted && user.role !== 'psychologist_install' && user.role !== 'radiologist_install' && user.role !== 'pediatrician_install' && user.role !== 'psychiatrist_install' && user.role !== 'secretary') this.openStartupPostModal(user);

			if (user.role === "doctor") {
				this.props.onGet("officeTimeTable");
                this.props.onGet("doctorCharacteristic");
                this.props.onGet("doctorTransmission");
                this.props.onGet("substituteTransmissionSuggestion");
				this.props.getAllByDoctor();
                // When the filter changes, reset the page to 0        
                EventManager.sub("Homepage", "contractSigned", () => {
                    this.setState({ modal: <DTransmissionReminderModal toggle={() => this.setState({modal: null})} /> });
                });
			}

			if (user.role === "substitute") {
				this.props.onGet("substituteCharacteristic");
			}

			if (user.role === "psychologist_install") {
				this.props.onGet("psychologistICharacteristic");
				this.props.onGet("officeTimeTable");
			}

			if (user.role === "radiologist_install") {
				this.props.onGet("radiologistCharacteristic");
				this.props.onGet("officeTimeTable");
			}

			if (user.role === "pediatrician_install") {
				this.props.onGet("pediatricianCharacteristic");
				this.props.onGet("officeTimeTable");
			}

			if (user.role === "psychiatrist_install") {
				this.props.onGet("psychiatristCharacteristic");
				this.props.onGet("officeTimeTable");
			}

			if (user.role === "secretary") {
				this.props.getAllBySecretary();
			}
			
			// Both roles
			this.props.onGets("notification");
		});
	}

	componentWillUnmount() {
		window.removeEventListener("load", this.updateDimensions);
		window.removeEventListener("resize", this.updateDimensions);
    }
    
    componentWillReceiveProps(prevProps){
        const { user, doctorTransmission, substituteTransmissionSuggestion, doctorCharacteristic } = prevProps;

        if(user && user.role === "doctor" && !Util.emptyObject(substituteTransmissionSuggestion) && doctorCharacteristic && !this.state.modal){

            this.setState({
                modal: <STransmissionModal
                        title={"DTransmission.Suggestion.Title"}
                        content={"DTransmission.Suggestion.Content"}
                        doctorCharacteristic={doctorCharacteristic}
                        doctorTransmission={substituteTransmissionSuggestion}
                        toggle={(resetUpd=true) => {
                            const doctorTransmissionClone = { ...doctorTransmission };
                            delete doctorTransmissionClone.doctor_id;

                            this.props.onClear("substituteTransmissionSuggestion", () => {
                                if(resetUpd) this.props.onUpd("doctorTransmission", { doctorTransmission: doctorTransmissionClone }, () =>  this.closeModal())
                            });
                        }}
                        submitCbk={(doctorTransmission, cbk) => {
                            const doctorTransmissionClone = { ...doctorTransmission };
                            delete doctorTransmissionClone.substitute_id;
                            
                            this.props.onUpd("doctorTransmission", { doctorTransmission: doctorTransmissionClone }, () => {
                                if (cbk) cbk();
        
                                new Noty({
                                    type: "info",
                                    layout: 'topRight',
                                    theme: 'bootstrap-v4',
                                    text: this.props.intl.formatMessage({ id: "Profile.Modified.Success" }),
                                    timeout: 6000,
                                }).show();
        
                                this.closeModal();

                                this.props.onGet("substituteTransmissionSuggestion");
                            })
                        }} />
            });
        }
    }

	wrap(elem) {
		return <div
			id="main-wrapper"
			dir={this.state.settings[0].dir}
			data-theme={this.state.settings[0].theme}
			data-layout={this.state.settings[0].layout}
			data-sidebartype={this.state.settings[0].sidebartype}
			data-sidebar-position={this.state.settings[0].sidebarpos}
			data-header-position={this.state.settings[0].headerpos}
			data-boxed-layout={this.state.settings[0].boxed}
		>
			{elem}
		</div>
	}

	routes(routes) {
		return (
			<>
			<Switch>
				{routes.map((prop, key) => {
					if (prop.navlabel) {
						return null;
					}
					else if (prop.collapse) {
						return prop.child.map((prop2, key2) => {
							if (prop2.collapse) {
								return prop2.subchild.map((prop3, key3) => {
									return (
										<Route path={prop3.path} component={prop3.component} key={key3} exact={prop3.exact} />
									);
								});
							}
							return (
								<Route path={prop2.path} component={prop2.component} key={key2} exact={prop2.exact} />
							);
						});
					}
					else if (prop.redirect) {
						return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
					}
					else {
						return (
							<Route path={prop.path} component={prop.component} key={key} exact={prop.exact} />
						);
					}
				})}
			</Switch>

			{this.state.modal}
			</>
		);
	}

	openStartupPostModal = (user) => {
		this.setState({
			modal: <StartupPostModal toggle={(cbk) => this.closeModal(cbk)} user={user} />
		});
	}

	closeModal = (cbk) => {
		this.setState({ modal: null }, () => {if(cbk) cbk()});
	}

	openAppointmentModal(event) {
		let id = event.currentTarget.getAttribute('data-id');

		let appointment = this.props.arrayAppointments.find((appointment) => {
			return appointment._id === id;
		})

		this.props.removeAppointmentModal(id);

		let modalEditAppointmentDoctor = false;
		let modalEditAppointmentSub = false;
		let modalEditAppointmentSec = false;
		if (this.props.user.role === 'doctor') {
			modalEditAppointmentDoctor = true;
		} else if (this.props.user.role === 'substitute') {
			modalEditAppointmentSub = true;
		} else if (this.props.user.role === 'secretary') {
			modalEditAppointmentSec = true;
		}
		
		this.setState({
			appointment,
			modalEditAppointmentDoctor,
			modalEditAppointmentSub,
			modalEditAppointmentSec
		})
	}

	closeModalEditAppointment() {
		this.setState({
			modalEditAppointmentDoctor: false,
			modalEditAppointmentSub: false,
			modalEditAppointmentSec : false
		})
	}

	render() {
		let viewReturnCalendar = false;
		let path;
		if (this.props.user) {
			if (this.props.user.role === 'doctor') {
				if (this.props.location.pathname !== '/home/dhome') {
					viewReturnCalendar = true;
					path = '#/home/dhome';
				}
			} else if (this.props.user.role === 'substitute') {
				if (this.props.location.pathname !== '/home/shome') {
					viewReturnCalendar = true;
					path = '#/home/shome';
				}
			} else if (this.props.user.role === 'secretary') {
				if (this.props.location.pathname !== '/home/sechome') {
					viewReturnCalendar = true;
					path = '#/home/sechome';
				}
			}
		}

		const { arrayAppointments } = this.props;

		return this.wrap(
			<div
				id="main-wrapper"
				dir={this.state.settings[0].dir}
				data-theme={this.state.settings[0].theme}
				data-layout={this.state.settings[0].layout}
				data-sidebartype={this.state.settings[0].sidebartype}
				data-sidebar-position={this.state.settings[0].sidebarpos}
				data-header-position={this.state.settings[0].headerpos}
				data-boxed-layout={this.state.settings[0].boxed}
			>

				<Header data={this.state} location={this.props.location}/>

				<div className="page-wrapper d-block">

					<div className="page-content container-fluid">
						{viewReturnCalendar && 
							<div className='row return-button-calendar'>
								<div className='d-flex'>
									<a href={path} className="btn border xrounded text-white w-100">
										<span className="arrowLeft">&#8592;</span><span>CALENDRIER</span>
									</a>
								</div>
							</div>
						}
						{this.routes(ConnectedRoutes)}
					</div>

					{arrayAppointments && arrayAppointments.length > 0 && 
						<div className='row appointment-minimize div-appointment-minimze'>
							{arrayAppointments.map((appointment, key) =>
								<div key={key} data-id={appointment._id} onClick={this.openAppointmentModal} className='col-2 appointment-minimize-child ml-2 mb-1 clickable jumbotron'>
									<div>{moment(appointment.appointment_date).format('DD/MM HH:mm')}</div>
									<div className='bold'>{appointment.patient.name} {appointment.patient.first_name}</div>
								</div>						
							)}
						</div>
					}

					{this.state.modalEditAppointmentDoctor === true && 
						<DModalEditAppointment
							close={this.closeModalEditAppointment}
							appointment={this.state.appointment}
							agreements={this.props.agreements}
							arrayAgreements={this.props.arrayAgreements}
							arrayOfficeHours={this.props.arrayOfficeHours}
						/>
					}

					{this.state.modalEditAppointmentSub === true && 
						<SModalEditAppointment
							close={this.closeModalEditAppointment}
							appointment={this.state.appointment}
							agreements={this.props.agreements}
							arrayAgreements={this.props.arrayAgreements}
							arrayOfficeHours={this.props.arrayOfficeHours}
						/>
					}

					{this.state.modalEditAppointmentSec === true && 
						<SecretaryModalEditAppointment
							close={this.closeModalEditAppointment}
							appointment={this.state.appointment}
							agreements={this.props.agreements}
							arrayAgreements={this.props.arrayAgreements}
							selectedUser={this.props.selectedUser}
							arrayOfficeHours={this.props.arrayOfficeHours}
						/>
					}

					<GDPRModal />

					<Footer />

					{this.state.modal}

				</div>
			</div>
		);
	}
}


const mapStateToProps = state => {
	return {
        user: state.global.user,
        doctorTransmission: state.global.doctorTransmission,
        substituteTransmissionSuggestion: state.global.substituteTransmissionSuggestion,
        doctorCharacteristic: state.global.doctorCharacteristic,
		listOfClinicalStudy: state.clinicalStudy.listOfClinicalStudy,
		arrayAppointments: state.appointments.arrayAppointments,
		arrayAgreements: state.appointments.arrayAgreements,
		agreements: state.appointments.agreements,
		secretaryManagements: state.secretaryManagement.secretaryManagements,
		selectedUser: state.appointments.selectedUser,
		arrayOfficeHours: state.appointments.arrayOfficeHours
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onGet: (objName) => dispatch(get(objName)),
		onGets: (objName) => dispatch(gets(objName)),
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),
        onUpd: (objName, obj, cbk) => dispatch(upd(objName, obj, cbk)),
        onClear: (objName, cbk) => dispatch(clear(objName, cbk)),
		getClinicalStudy: () => dispatch(getClinicalStudy()),
		removeAppointmentModal: (appointment) => dispatch(removeAppointmentModal(appointment)),
		getAllByDoctor: (cbk) => dispatch(getAllByDoctor(cbk)),
		getAllBySecretary: (cbk) => dispatch(getAllBySecretary(cbk)),

	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Homepage));
