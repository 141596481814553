import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import DTransmissionsForm from './DTransmissionsForm';


class DTransmissions extends React.Component {

	render() {

		return (
			<React.Fragment>

				<Row className="step step1 mb-4 justify-content-md-center ">
					<Col>
						<Card className="rounded-0">
							<CardBody className="p-5">

                                <div className="text-center mb-5"><FormattedMessage id="Doc.Transmissions.Expl" /></div>

                                <DTransmissionsForm 
                                    doctorCharacteristic={this.props.doctorCharacteristic}
                                    defaultValues={this.props.defaultValues}
                                    submitCbk={this.props.submitCbk}
                                    submitButtoni18nId={this.props.submitButtoni18nId}
                                    />
            
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
		);
	}
}


const mapStateToProps = state => {
	return {
		//
	}
}

const mapDispatchToProps = dispatch => {
	return {
		//
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DTransmissions);
