import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Collapse } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl';
import { upds, getBy, clears, upd } from "../../../actions/global/global";
import Util from '../../../util/Util';
import ReplacementEnt from "../../../entities/ReplacementEnt";
import Noty from 'noty';
import ConfModal from "../../../components/modal/ConfModal";
import ModalDaysExceptionView from './ModalDaysExceptionView';
import DateUtil from '../../../util/DateUtil';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { concludedAgreementOverlaps } from '../../../actions/agreement/agreement';
import 'ladda/dist/ladda-themeless.min.css';

class SubstituteReplacementProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            collapseGuards: false,
            disabled: false,
            modal: null,
            overlap: false
        };

        this.acceptRempl = this.acceptRempl.bind(this);
        this.refuseRempl = this.refuseRempl.bind(this);
        this.openNegociationModal = this.openNegociationModal.bind(this);
        this.openModalDaysException = this.openModalDaysException.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.thinkChoice = this.thinkChoice.bind(this);
    }

    componentDidMount() {
        var replacement = this.props.replacement;
        if (!replacement && this.props.replacementId) replacement = this.props.replacements.find(r => r._id === this.props.replacementId);
        if (replacement.guards && replacement.guards.length > 0) {
            this.setState({ collapseGuards: true })
        }
        if (replacement.applicants && replacement.applicants.length > 0) {
            replacement.applicants.forEach((elem) => {
                if (this.props.substitute._id === elem.substitute_id) {
                    let startDate = replacement.start_date;
                    let endDate = replacement.end_date;
                    let daysAvailable = replacement.days_available;

                    if (elem.negociation && elem.negociation.length > 0) {
                        let last_element = elem.negociation[elem.negociation.length - 1];
                        startDate = last_element.start_date;
                        endDate = last_element.end_date;
                        daysAvailable = last_element.days_available;
                    }
                    this.props.onConcludedAgreementOverlaps(startDate, endDate, replacement.type, undefined, daysAvailable, (res) => {
                        this.setState({
                            overlap: res.overlaps
                        })
                    });
                }
            })
        }
    }

    onApply = (e, replacement) => {
        e.stopPropagation();
        this.setState({ disabled: true });
        this.props.onApplyToReplacement({ replacementId: replacement._id }, () => {
            // Re-enable btn
            this.setState({ disabled: false });
            if (this.props.applyCbk) this.props.applyCbk();
        });
    };

    closeModal() {
        this.setState({ modal: null });
    }

    // Fonction pour refuser le remplaçant qui postule à une offre de l'installer
    refuseRempl(e) {
        var data = {
            replacement_id: this.props.replacement._id,
            substitute_id: this.props.substitute._id,
            substitute_status: 1,
            doctor_status: 2,
            doctor_id: this.props.replacement.doctor_id
        };

        // Dans le cas d'une invitation par un médecin installé, il faut supprimer l'invitation
        if (this.props.replacement.availability_id || this.props.replacement.invitation_by_doctor === true) {
            this.props.onDelete(e, this.props.replacement);
            return;
        }

        const onConfirm = (response) => {
            data.response_choice = response;
            this.props.onUpds("replacement/refuse", data, () => {
                this.props.closeModal();

                new Noty({
                    type: "info",
                    layout: 'topRight',
                    theme: 'bootstrap-v4',
                    text: this.props.intl.formatMessage({ id: "Refuse.Rempl" }),
                    timeout: 6000,
                }).show();
            });
        }

        const onCancel = () => {
            this.setState({ disabled: false });
            this.closeModal();
        }
        const content = <FormattedHTMLMessage id="Refuse.Proposition" />

        this.setState({
            modal: <ConfModal
                fromDoctor={true}
                content={content}
                onConfirm={onConfirm}
                onCancel={onCancel}
                toggle={onCancel} />
        });
    }

    // Fonction pour accepter le remplaçant qui postule à une offre de l'installer
    acceptRempl() {
        var data = {
            replacement_id: this.props.replacement._id,
            substitute_id: this.props.substitute._id,
            doctor_id: this.props.replacement.doctor_id,
            substitute_status: 1,
            doctor_status: 1,
            fromDoctor: true
        };

        this.props.onUpd("replacement/accept", data, () => {
            this.props.closeModal(true);

            this.props.onGetBy("agreement", ["replacement_id"], [this.props.replacement._id], (agreement) => {
                agreement.replacement = this.props.replacement;
                this.props.onSignContract(this.props.replacement, agreement, this.props.substitute, this.props.characteristics);
            });
        });
    }

    openModalDaysException(event) {
        event.preventDefault();
        let last_element;
        let daysNotAvailable;
        this.props.replacement.applicants.forEach((elem) => {
            if (elem.substitute_id === this.props.substitute._id) {
                last_element = elem.negociation[elem.negociation.length - 1];
                daysNotAvailable = DateUtil.getDaysNegociation(this.props.replacement.days_available, last_element.days_available);
                return;
            }
        })

        this.setState({
            modal: <ModalDaysExceptionView 
                close={this.closeModal}
                daysNotAvailable={daysNotAvailable}
            />
        })
    }

    // Appel la fonction qui ouvre la modal de négo
    openNegociationModal() {
        this.props.openNegociationModal(this.props.substitute._id);
    }

    // Appel la fonction qui permet au medecin de choisir de réfléchir
    thinkChoice() {
        let data = {
            doctor_status: 4,
            replacement_id: this.props.replacement._id,
            substitute_id: this.props.substitute._id,
            role: 'doctor',
            doctor_id: this.props.replacement.doctor_id
        }
        this.props.onUpd("replacement/think", data, () => {
            this.props.closeModal();
            new Noty({
                type: "info",
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: 'Vous avez choisi de réfléchir à cette proposition',
                timeout: 6000,
            }).show();
        });
    }

    render() {
        const { replacement, substitute } = this.props;
        var hasOngoingAgreement = ReplacementEnt.ongoingAgreement(replacement, substitute);
        var hasConcludedAgreement = ReplacementEnt.isConcluded(replacement);

        const cancelledBySubAgreement = ReplacementEnt.subCancelled(replacement, substitute);
        const cancelledByDocAgreement = ReplacementEnt.docCancelled(replacement, substitute);
        const cancelledAgreement = (cancelledBySubAgreement || cancelledByDocAgreement);
        //const docSignedAgreement = ReplacementEnt.docSigned(replacement, substitute);
        const hasAccordAgreement = ReplacementEnt.hasAccordAgreement(replacement, substitute);

        let startDate = DateUtil.toDate(replacement.start_date);
        let endDate;
        if(replacement.type === "guard") {
            startDate = DateUtil.toDateWithHour(replacement.start_date);
            endDate = DateUtil.toDateWithHour(replacement.end_date);
        } else if(replacement.type === "regular" && !replacement.end_date) {
            endDate = DateUtil.toDate(ReplacementEnt.getRegularReplEnd(replacement.day, replacement.duration, replacement.start_date))
        } else {
            endDate = DateUtil.toDate(replacement.end_date);
        } 

        let signContract = true;
        let negociation = false;
        let newCondition = {}
        // Permet de savoir qui est le dernier à avoir proposé une négo, conditionne l'affichage du bouton j'accepte
        let lastNegociator = false;
        let acceptByDoc = false;
        let invitation = false;
        // Cas d'une invitation par un médecin installé
        if (replacement.availability_id || replacement.invitation_by_doctor === true) {
            invitation = true;
        }
        let viewNego = true;
        let last_element;
        let viewDaysException = false;
        let numberDaysException = 0;
        let newRetrocession;
        let newDates;
        let viewThinking = false;
        let retrocession = replacement.retrocession || replacement.guard_retrocession;
        // Si y'a des applicants, on vérifie que le médecin n'a pas déjà accepté quelqu'un
        // avant de lui permettre de valider un autre médecin
        if (replacement.applicants) {
            replacement.applicants.forEach((elem) => {
                viewThinking = true;
                // Si le médecin est déjà en réflexion
                if (elem.doctor_status === 4 || elem.doctor_status === 1 || elem.doctor_status === 3 || elem.substitute_status === 4) {
                    viewThinking = false;
                }
                // si c'est une invitation et que le remplaçant n'a pas négocié, alors on cache le bouton négo à l'installer
                if (invitation === true && elem.negociation && elem.negociation.length === 0 && substitute._id === elem.substitute_id) {
                    viewNego = false; 
                }

                if (elem.negociation && elem.negociation.length === 0 && substitute._id === elem.substitute_id && this.state.overlap === false) {
                    viewNego = false; 
                }
                // Si on rentre dans ce cas de figure, le médecin a déjà donné son accord à un autre médecin
                if (elem.doctor_status === 1 && substitute._id !== elem.substitute_id && elem.substitute_status === 1) {
                    signContract = false;
                }
   
                // Si le médecin remplaçant a proposé une négociation
                if (elem.negociation && elem.negociation.length > 0 && substitute._id === elem.substitute_id) {
                    if ((elem.doctor_status === 0 || elem.doctor_status === 4) && elem.substitute_status === 3) {
                        acceptByDoc = true;
                    }
                    if (elem.doctor_status === 1 && elem.substitute_status === 1) {
                        negociation = false;
                    } else {
                        negociation = true;
                    }
                    last_element = elem.negociation[elem.negociation.length - 1];

                    // Quand y'a eu négo et que le contrat est en attente de signature
                    if(hasAccordAgreement && !cancelledAgreement) {
                        startDate = DateUtil.toDate(last_element.start_date);
                        endDate = DateUtil.toDate(last_element.end_date);
                    } 
                    retrocession = last_element.retrocession;
                    if (last_element.negociator === 'doctor') {
                        lastNegociator = true;
                    }
                    
                    // Si y'a des jours en exceptions
                    let daysNotAvailable = DateUtil.getDaysNegociation(replacement.days_available, last_element.days_available);
                    if (daysNotAvailable && daysNotAvailable.length > 0) {
                        viewDaysException = true;
                        numberDaysException = daysNotAvailable.length;
                    }
                    // récupère l'avant dernière négo
                    let previousElement = elem.negociation[elem.negociation.length - 2];
                    if (previousElement !== undefined) {
                        // Si le dernier est différent de l'avant dernier
                        if (last_element.retrocession !== previousElement.retrocession) {
                            newRetrocession = 'Nouvelle rétrocession';
                        }
                    } else if (last_element.retrocession !== replacement.retrocession) {
                        newRetrocession = 'Nouvelle rétrocession';
                    } 

                    // Récupère l'avant dernier élement de négo
                    if (previousElement !== undefined) {
                        if (last_element.start_date !== previousElement.start_date || last_element.end_date !== previousElement.end_date || last_element.days_available.length !== previousElement.days_available.length) {
                            newDates = 'une nouvelle date';
                        }
                    } else if (last_element.start_date !== replacement.start_date || last_element.end_date !== replacement.end_date || last_element.days_available.length !== replacement.days_available.length) {
                        newDates = 'une nouvelle date';
                    }

                    newCondition = {
                        start_date: last_element.start_date,
                        end_date: last_element.end_date,
                        guards: last_element.guards,
                        retrocession: replacement.type === 'guard'? last_element.guard_retrocession : last_element.retrocession
                    }
                }
            })
        }

        let guards = [];
        let newGuards;
        let countGuards = 0;
        // Si ya une garde et si y'a déjà eu une négo alors on affiche les valeurs de la négo
        if (last_element !== undefined && replacement.guards.length > 0) {
            guards = last_element.guards;
            last_element.guards.forEach((elem) => {
                if (elem.accepted === false) {
                    newGuards = 'décliner une garde'
                } else {
                    countGuards += 1;
                }
            })
        } else {
            guards = replacement.guards;
        }

        return (
            <>
            <div>
                {(negociation === true) &&
                    <div>
                        <div className="separatorNego">
                            {this.props.substitute.first_name.toUpperCase() + " vous propose :".toUpperCase()}
                            {newRetrocession !== undefined && 
                                <div className="colorNegociation">
                                    - {newRetrocession}
                                </div>
                            }
                            {newDates !== undefined && 
                                <div className="colorNegociation">
                                    - {newDates}
                                </div>
                            }
                            {newGuards !== undefined && 
                                <div className="colorNegociation">
                                    - {newGuards}
                                </div>
                            }

                        </div>
                    </div>
                }
                {this.state.overlap === true &&
                    <div className="error">
                        <p>Vous avez déjà un remplacement signé qui chevauche la période proposée, vous devez changer les dates</p>
                    </div>
                }
                <div className={"p-3 " + (negociation === true ? 'row' : '')}>
                    <div className={"profile-repl " + (negociation === true ? 'oldPropal' : '') }>
                        <div className={"profile-repl-title bg-" + replacement.type}>
                            {negociation === true &&
                                'Ancien '
                            }
                            <FormattedMessage id={"Replacement.OneLine." + Util.fstLetUpCase(replacement.type)} />
                        </div>

                        <div className="profile-repl-details">
                            <div>
                                <FormattedMessage id="Dates" />&nbsp;:&nbsp;
                                <FormattedMessage id="from" />&nbsp;{startDate}&nbsp;
                                <FormattedMessage id="To" />&nbsp;{endDate}
                            </div>

                            <div>
                                <FormattedMessage id="Proposed.Retrocession" />&nbsp;:&nbsp;
                                    {replacement.type !== 'guard' ?
                                        <span>
                                            {(negociation === false ? retrocession : replacement.retrocession)}&nbsp;%
                                        </span>
                                        :
                                        <span>
                                            {(negociation === false ? retrocession : replacement.guard_retrocession)}&nbsp;%
                                        </span>
                                    }
 
                                    </div>

                            <div>
                                <FormattedMessage id="Parking" />&nbsp;:&nbsp;{(replacement.parking) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                            </div>

                            <div>
                                <FormattedMessage id="Accommodation" />&nbsp;:&nbsp;{(replacement.housing) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                            </div>

                            {(viewDaysException === true && hasAccordAgreement && !cancelledAgreement) &&
                                <div>
                                        <FormattedMessage id="Days.Exceptions" /> : <button onClick={this.openModalDaysException}>{numberDaysException}</button>
                                    {this.state.modal}
                                </div>
                            }

                            {(replacement.type !== "guard") &&
                                <div>
                                    <FormattedMessage id="Guards" />&nbsp;:&nbsp;
                                            <span className={"guards-badge " + ((replacement.guards && replacement.guards.length > 0) ? "guards-badge-active" : "")}>
                                        {(replacement.guards) ? replacement.guards.length : 0}
                                    </span>

                                    {(replacement.guards && replacement.guards.length > 0) && negociation === false &&
                                        <>
                                            &nbsp;&nbsp;<span className="collapse-text clickable"
                                                onClick={() => this.setState({ collapseGuards: !this.state.collapseGuards })}>
                                                {(this.state.collapseGuards) ? "-" : "+"}&nbsp;
                                                        <FormattedMessage id="Details" />
                                            </span>
                                        </>
                                    }
                                </div>
                            }
                        </div>
                    </div>

                    {negociation === true && 
                        <div className="profile-repl">
                            <div className={"profile-repl-title bg-" + replacement.type}>
                            Nouveau <FormattedMessage id={"Replacement.OneLine." + Util.fstLetUpCase(replacement.type)} />
                            </div>

                            <div className="profile-repl-details">
                                {replacement.type === 'regular' &&
                                    <div>
                                        Jour : <FormattedMessage id={"Day." + DateUtil.dayToNbr(replacement.day[0])} />
                                    </div>
                                }
                                <div>
                                    <FormattedMessage id="Dates" />&nbsp;:&nbsp;
                                    {(replacement.type === "guard") ?
                                        <>
                                            <FormattedMessage id="from" />&nbsp;{DateUtil.toDateWithHour(newCondition.start_date)}&nbsp;
                                                    <FormattedMessage id="To" />&nbsp;{endDate}
                                        </>
                                        :
                                        <>
                                            <FormattedMessage id="from" />&nbsp;{DateUtil.toDate(newCondition.start_date)}&nbsp;
                                                    <FormattedMessage id="To" />&nbsp;{DateUtil.toDate(newCondition.end_date)}
                                        </>
                                    }
                                </div>

                                <div>
                                    <FormattedMessage id="Proposed.Retrocession" />&nbsp;:&nbsp;
                                    <span className="retrocessionDisplay">
                                        {newCondition.retrocession || replacement.guard_retrocession}&nbsp;%
                                    </span>
                                </div>

                                <div>
                                    <FormattedMessage id="Parking" />&nbsp;:&nbsp;{(replacement.parking) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                                </div>

                                <div>
                                    <FormattedMessage id="Accommodation" />&nbsp;:&nbsp;{(replacement.housing) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                                </div>

                                {viewDaysException === true &&
                                    <div>
                                         <FormattedMessage id="Days.Exceptions" /> : <button onClick={this.openModalDaysException}>{numberDaysException}</button>
                                        {this.state.modal}
                                    </div>
                                }

                                {(replacement.type !== "guard") &&
                                    <div>
                                        <FormattedMessage id="Guards" />&nbsp;:&nbsp;
                                                <span className={"guards-badge " + ((replacement.guards && replacement.guards.length > 0) ? "guards-badge-active" : "")}>
                                                {countGuards}
                                        </span>

                                        {(replacement.guards && replacement.guards.length > 0) &&
                                            <>
                                                &nbsp;&nbsp;<span className="collapse-text clickable"
                                                    onClick={() => this.setState({ collapseGuards: !this.state.collapseGuards })}>
                                                    {(this.state.collapseGuards) ? "-" : "+"}&nbsp;
                                                            <FormattedMessage id="Details" />
                                                </span>
                                            </>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    <Collapse isOpen={this.state.collapseGuards} className="w-100" >
                        <div className="separatorNego">
                            <h5>Garde</h5>
                        </div>
                        {(guards && guards.length > 0) &&
                            <Row className="m-0 text-center">
                                {guards.map(guard => {
                                    return (
                                        <Col sm="4" className="col-centered">
                                            <div className={"profile-repl " + (guard.accepted === false ? 'oldPropal oldPropalLine' : '') }>
                                                <div className={"profile-repl-title bg-guard"}>
                                                    <FormattedMessage id={"Replacement.Guard"} />
                                                </div>

                                                <div className="profile-repl-details mb-3">
                                                    <div>
                                                        <FormattedMessage id="Dates" />&nbsp;:&nbsp;
                                                            <FormattedMessage id="from" />&nbsp;{DateUtil.toDateWithHour(guard.start_date)}&nbsp;
                                                        </div>
                                                    <div>
                                                        <FormattedMessage id="To" />&nbsp;{DateUtil.toDateWithHour(guard.end_date)}
                                                    </div>

                                                    <div>
                                                        <FormattedMessage id="Proposed.Retrocession" />&nbsp;:&nbsp;
                                                        {(last_element) ? last_element.guard_retrocession : replacement.guard_retrocession}&nbsp;%
                                                            </div>

                                                    <div>
                                                        <FormattedMessage id="Guard.In.Office" />&nbsp;:&nbsp;
                                                                {(guard.office) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                                                    </div>

                                                    <div>
                                                        <FormattedMessage id="Guard.Visits" />&nbsp;:&nbsp;
                                                                {(guard.visits) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                                                    </div>

                                                    <div>
                                                        <FormattedMessage id="Paid.By.Fifteenth" />&nbsp;:&nbsp;
                                                                {(guard.paid_by_the_fifteenth) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                                                    </div>

                                                </div>

                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        }
                    </Collapse>
                </div>

                {(replacement && ReplacementEnt.hasApplied(replacement, substitute)) &&
                    <>
                        {(cancelledAgreement) &&
                            <div className="mb-3 w-100 text-center">
                                <LaddaButton
                                    className={"btn btn-ladda apply-btn text-white clickable greyed-btn greyed-btn-disabled"}
                                    data-style={EXPAND_LEFT}
                                    disabled={true}>
                                    {(cancelledBySubAgreement) ?
                                        <FormattedMessage id="Cancelled.By.Substitute" />
                                        :
                                        <FormattedMessage id="Cancelled.By.You" />
                                    }
                                </LaddaButton>
                            </div>
                        }

                        {!hasAccordAgreement && signContract === true &&
                            <>
                                {this.state.overlap === false && 
                                    <div>
                                    { ((invitation === false && lastNegociator === false) || acceptByDoc === true) && 
                                        <div className="col-12 mb-3 text-center d-inline-block">
                                            <LaddaButton
                                                className="btn bg-green srounded pl-5 pr-5 p-2 pb-2 text-white m-2"
                                                onClick={this.acceptRempl}
                                                data-style={EXPAND_LEFT}>
                                                <FormattedMessage id="I.Accept" />
                                            </LaddaButton>
                                        </div>
                                    }
                                    </div>
                                }
                                <div className="mb-3 text-center d-inline-block">
                                    <LaddaButton
                                        className="btn bg-red srounded pl-5 pr-5 p-2 pb-2 text-white m-2"
                                        onClick={this.refuseRempl}
                                        data-style={EXPAND_LEFT}>
                                        {(invitation === false) ?
                                            <FormattedMessage id="I.Refuse" />
                                            :
                                            <FormattedMessage id="Delete" />
                                        }
                                    </LaddaButton>
                                </div>

                                { viewNego === true && 
                                    <div className="mb-3 text-center d-inline-block">
                                        <LaddaButton
                                            className="btn bg-blue srounded pl-5 pr-5 p-2 pb-2 text-white m-2"
                                            onClick={this.openNegociationModal}
                                            data-style={EXPAND_LEFT}>
                                            <FormattedMessage id="Nego" />
                                        </LaddaButton>
                                    </div>
                                }

                                { viewThinking === true && this.state.overlap === false && 
                                    <div className="mb-3 text-center d-inline-block">
                                        <LaddaButton
                                            className="btn bg-yellow srounded pl-5 pr-5 p-2 pb-2 text-white m-2"
                                            onClick={this.thinkChoice}
                                            data-style={EXPAND_LEFT}>
                                            <FormattedMessage id="Thinking" />
                                        </LaddaButton>
                                    </div>
                                }
                            </>
                        }

                        {signContract === false &&
                            <>
                                <div className="mb-3 w-100 text-center">
                                    <LaddaButton
                                        className={"btn btn-ladda apply-btn text-white clickable greyed-btn greyed-btn-disabled"}
                                        data-style={EXPAND_LEFT}
                                        disabled={true}>
                                            Vous avez déjà validé un autre remplaçant !
                                    </LaddaButton>
                                </div>
                            </>
                        }

                        {hasAccordAgreement && !cancelledAgreement && 
                            <>
                                <div className="mb-3 text-center d-inline-block">
                                    <LaddaButton
                                        className={"btn bg-red srounded lowercase pl-5 pr-5 p-2 pb-2 text-white m-2"}
                                        onClick={this.props.cancelAgreement}
                                        data-style={EXPAND_LEFT}>
                                        <FormattedMessage id="Give.Up.Mission" />
                                    </LaddaButton>
                                </div>

                                <div className="mb-3 text-center d-inline-block">
                                    <LaddaButton
                                        className={"btn bg-blue srounded lowercase pl-5 pr-5 p-2 pb-2 text-white m-2"}
                                        onClick={() => this.props.onSignContract()}
                                        data-style={EXPAND_LEFT}>
                                        <FormattedMessage id="Sign.contract" />
                                    </LaddaButton>
                                </div>
                            </>
                        }

                        {(!cancelledAgreement && hasOngoingAgreement && hasConcludedAgreement) &&
                            <div className="mb-3 w-100 text-center">
                                <LaddaButton
                                    className={"btn btn-ladda apply-btn text-white clickable greyed-btn greyed-btn-disabled"}
                                    data-style={EXPAND_LEFT}
                                    disabled={true}>
                                    <FormattedMessage id="Concluded.Agreement" />
                                </LaddaButton>
                            </div>
                        }
                        {this.state.modal}
                    </>
                }
                {this.state.modal}
            </div>
            </>
        );
    };

}

const mapStateToProps = state => {
    return {
        replacements: state.global.replacements
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onUpds: (objName, obj, cbk) => dispatch(upds(objName, obj, cbk)),
        onUpd: (objName, obj, cbk) => dispatch(upd(objName, obj, cbk)),
        onGetBy: (objName, fields, values, callback) => dispatch(clears(objName, getBy(objName, fields, values, callback))),
		onConcludedAgreementOverlaps: (start, end, type, duration, days_available, cbk) => concludedAgreementOverlaps(start, end, type, duration, days_available, cbk)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SubstituteReplacementProfile));