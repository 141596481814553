import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import moment from 'moment';
import OccasionalOrRegularForm from '../replacements/OccasionalOrRegularForm';
import GuardForm from '../replacements/GuardForm';
import DAddCalendarEventModal from './DAddCalendarEventModal';
import DAddCalendarReplacementModal from './DAddCalendarReplacementModal';


export default class DChoiceModal extends React.Component {

    constructor(props) {
        super(props);

        moment.locale('fr')

        this.state = {
            content: null,
			replType: "occasional",
        };

        this.selectChoice = this.selectChoice.bind(this);

        this.onChangeTypeCbks = [];

        this.replacementForm = <OccasionalOrRegularForm
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            toggle={this.props.toggle}
            openBy={this.props.openBy}
            monthSelected={this.props.monthSelected}
            fromCalendar={true}
            onAddChangeTypeCbk={(cbk) => this.onChangeTypeCbks.push(cbk)}
            onChangeReplType={(replType) => this.toggle(replType)} />;

        this.guardForm = <GuardForm
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            toggle={this.props.toggle}
            openBy={this.props.openBy}
            monthSelected={this.props.monthSelected}
            fromCalendar={true}
            onAddChangeTypeCbk={(cbk) => this.onChangeTypeCbks.push(cbk)}
            onChangeReplType={(replType) => this.toggle(replType)} />;
    };

    toggle(replType) {
		this.setState({ replType: replType });
		for (let cbk of this.onChangeTypeCbks) cbk(replType);
	};

    // Affiche le choix
    selectChoice(event) {
        // Si c'est une dispo
        if (event.currentTarget.name === "newDispo") {
            this.setState({
                content: 1
            });
        }
        else if (event.currentTarget.name === "newEvent") {
            this.setState({
                content: 2
            });
        }
        else if (event.currentTarget.name === "newRempl"){
            this.setState({
                content: 3
            });
        }
    }

    render() {
        let dateDisplay = null;

        // Si c'est un rajout direct sans passer par l'agenda
        if (this.props.endDate === undefined) {
            dateDisplay = moment().format('dddd D MMMM YYYY').toUpperCase();
            // Si les dates sont identiques
        } else if (moment(this.props.startDate).isSame(this.props.endDate)) {
            dateDisplay = moment(this.props.startDate).format('dddd D MMMM YYYY').toUpperCase();
        // si c'est une période
        } else if (this.props.endDate !== undefined) {
            dateDisplay = 'Du ' + moment(this.props.startDate).format('dddd D MMMM YYYY').toUpperCase() + ' au ' + moment(this.props.endDate).format('dddd D MMMM YYYY').toUpperCase();
        }
        return (
            <Modal size="lg" isOpen={true} toggle={this.props.close} className="simple-modal simple-modal-white srounded modalCustom">

                <ModalHeader className="header-modal-choice">
                    <div className="text-left">
                        <button type="button" onClick={this.props.close} className="close d-inline-block" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div>
                        {dateDisplay}
                    </div>
                </ModalHeader>

                <ModalBody className="p-0 pb-4">

                    {this.state.content === null &&
                        <div>
                            <div className="w-100 text-center mt-4 mb-3">
                                <button type="button" name="newDispo" onClick={this.selectChoice} className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-blue btn btn-secondary">
                                    <span><FormattedMessage id="New.Replacement.Post" /></span>
                                </button>
                            </div>
                            <div className="w-100 text-center mt-4 mb-3">
                                <button type="button" name="newEvent" onClick={this.selectChoice} className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-orange btn btn-secondary">
                                    <span><FormattedMessage id="New.Calendar.Event" /></span>
                                </button>
                            </div>
                            <div className="w-100 text-center mt-4 mb-3">
                                <button type="button" name="newRempl" onClick={this.selectChoice} className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green btn btn-secondary">
                                    <span><FormattedMessage id="New.Replacement" /></span>
                                </button>
                            </div>
                        </div>
                    }

                    {this.state.content === 1 &&
                        <div className="mx-auto text-center" style={{ maxWidth: "700px" }}>

                            <div className={(this.state.replType === "occasional" || this.state.replType === "regular") ? "" : "d-none"}>
								{this.replacementForm}
							</div>

							<div className={(this.state.replType === "guard") ? "" : "d-none"}>
								{this.guardForm}
							</div>
                        </div>
                    }

                    {this.state.content === 2 &&
                        <div>
                            <DAddCalendarEventModal
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                toggle={this.props.toggle}
                            />
                        </div>
                    }

                    {this.state.content === 3 &&
                        <div>
                            <DAddCalendarReplacementModal
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                toggle={this.props.toggle}
                                doctorCharacteristic={this.props.doctorCharacteristic}
                                replacements={this.props.replacements}
                                events={this.props.events}
                                openBy={this.props.openBy}
                                monthSelected={this.props.monthSelected}
                            />
                        </div>
                    }

                </ModalBody>

            </Modal>
        );
    }
}