import React from 'react';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";

export default class ModalDaysExceptionView extends React.Component {

    render() {
        const { daysNotAvailable } = this.props;
        moment.locale('fr');
        return (

                <Modal size="md" isOpen={true} toggle={this.props.close} className="simple-modal simple-modal-white srounded">

                    <ModalHeader className="border-0 m-0">
                        <div className="close-container">
                            <button type="button" className="close d-inline-block" aria-label="Close"
                                onClick={this.props.close} ><span aria-hidden="true">×</span></button>
                        </div>
                        <div className="text-center">
                            Liste des jours indisponibles
                        </div>
                    </ModalHeader>

                    <ModalBody className="p-0">
                        <div className="w-100 text-center mt-4 mb-3">
                            <ul className="list-group list-group-flush">
                                {daysNotAvailable.map((elem, key) => 
                                    <li className="list-group-item" key={key}>
                                        {moment(elem.start).format("dddd Do MMMM YYYY").toUpperCase()}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </ModalBody>
                </Modal>
        );
    }
}