import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Subscription from '../../enum/Subscription';

export default class ValidationSubscriptionModal extends React.Component {

	render() {
        let label = Subscription.getLabel(this.props.choice);

        if (this.props.choice === 4 || this.props.choice === 5 || this.props.choice === 6 || this.props.choice === 7) {
            label = 'premium';
        } else if (this.props.choice === 8 || this.props.choice === 9 || this.props.choice === 10 || this.props.choice === 11) {
            label = 'alliance';
        }

		return (
            <Modal isOpen={true} className="simple-modal modal-lg simple-modal-white srounded modalCustom">
                <ModalBody className="justify-content-center">
                    <div className="pt-5">
                        Votre demande d'adhésion à notre service <b>{label}</b> a bien été enregistrée et nous vous en remercions. Un de nos agent vous recontactera prochainement pour finaliser la procédure.
                    </div>

                    <div className="pt-5 justify-content-center">
                        <button onClick={this.props.close} className="col-md-4 border xrounded bg-green text-white p-3">
                            OK
                        </button>
                    </div>
                </ModalBody>
            </Modal>
		);
	}
}
