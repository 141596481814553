import axios from "axios";

const rootUrl = process.env.REACT_APP_BE_URL || "http://localhost:7000";

export function getAppointmentsByUser(params, callback) {

	return dispatch => {
		dispatch(request());
        
        var url = rootUrl + "/appointments";

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure(err))
        });
	};
    

    function request() { return { type: "GETS_APPOINTMENT_REQUEST", getsAppointmentsByUsers: false }}
	function success(result) { return { type: "GETS_APPOINTMENT_SUCCESS", users: result, getsAppointmentsByUsers: true }}
	function failure(error) { return { type: "GETS_APPOINTMENT_FAILURE", getsAppointmentsByUsers: false, error: error }}
};

export function addAppointment(appointment, userId, callback) {

	return dispatch => {
		dispatch(request());

        var url = rootUrl + "/appointment";

        let params = { appointment };

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure(err))
        });
	};

    function request() { return { type: "ADD_APPOINTMENT_REQUEST", addAppointment: false, }}
	function success(result) { return { type: "ADD_APPOINTMENT_SUCCESS", addAppointment: true, appointment: result, userId: userId }}
	function failure(error) { return { type: "ADD_APPOINTMENT_FAILURE", addAppointment: false, error: error }}
};

export function editAppointment(appointment, userId, callback) {

	return dispatch => {
		dispatch(request());

        var url = rootUrl + "/appointment/" + appointment._id;

        let params = { appointment };

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure())
        });
	};

    function request() { return { type: "EDIT_APPOINTMENT_REQUEST", editAppointment: false, }}
	function success(result) { return { type: "EDIT_APPOINTMENT_SUCCESS", editAppointment: true, appointment: result, userId: userId }}
	function failure(error) { return { type: "EDIT_APPOINTMENT_FAILURE", editAppointment: false }}
};

export function deleteAppointment(appointmentId, callback) {
    return dispatch => {
		dispatch(request());
        var url = rootUrl + "/appointment/" + appointmentId;

        return axios.delete(url)
            .then(function (response) {
                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                    if (callback) callback(obj);
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "DELETE_APPOINTMENT_REQUEST", isDeleteAppointment: false}}
	function success(result) { return { type: "DELETE_APPOINTMENT_SUCCESS", isDeleteAppointment: true, appointment: result}}
	function failure() { return { type: "DELETE_APPOINTMENT_FAILURE", isDeleteAppointment: false }}
};

export function addAppointmentModal(appointment, arrayAgreements, agreements, selectedUser, arrayOfficeHours, callback) {

	return { 
        type: "ADD_APPOINTMENT_MODAL_SUCESS",
        newAppointment: appointment ,
        arrayAgreements: arrayAgreements,
        agreements: agreements,
        selectedUser,
        arrayOfficeHours
    }
};

export function removeAppointmentModal(appointment, callback) {
    
	return { 
        type: "REMOVE_APPOINTMENT_MODAL_SUCESS",
        newAppointment: appointment 
    }
};