import Homepage from '../layouts/Homepage.js';
import Landing from '../views/Landing';
import ResetPwd from '../views/resetpwd/ResetPwd';
import DSteps from '../views/registration/doctor/DSteps';
import SSteps from '../views/registration/substitute/SSteps';
import PISteps from '../views/registration/psychologist/PISteps';
import RSteps from '../views/registration/radiologist/RSteps';
import PESteps from '../views/registration/pediatrician/PESteps';
import PSSteps from '../views/registration/psychiatrist/PSSteps';
import SecSteps from '../views/registration/secretary/SecSteps';
import Terms from '../views/terms/Terms';
import TermsPatients from '../views/terms/TermsPatients';
import AboutUs from '../views/AboutUs';
import Start from '../views/registration/Start';
import Login from '../views/login/Login';
import RecoverPwd from '../views/login/RecoverPwd';
import DoctorCorrectWrongnIfo from "../views/userWronInfo/DoctorCorrectWrongnIfo";
import AcceptCGU from "../views/Patients/AcceptCGU";
import ConfirmDoctor from "../views/secretaries/ConfirmDoctor";

var indexRoutes = [
    { path: '/home', name: 'Homepage', component: Homepage },
    { path: '/reset/:token', name: 'ResetPwd', component: ResetPwd },
    { path: '/terms', name: 'Terms', component: Terms },
    { path: '/termspatients', name: 'TermsPatients', component: TermsPatients },
    { path: '/aboutus', name: 'AboutUs', component: AboutUs },
    { path: '/login', name: 'Login', component: Login },
    { path: '/recoverPwd', name: 'RecoverPwd', component: RecoverPwd },
    { path: '/registration/steps/:id?', name: 'DSteps', component: DSteps },
    { path: '/registration/subSteps/:id?', name: 'SSteps', component: SSteps },
    { path: '/registration/piSteps/:id?', name: 'PISteps', component: PISteps },
    { path: '/registration/rSteps/:id?', name: 'RSteps', component: RSteps },
    { path: '/registration/peSteps/:id?', name: 'PESteps', component: PESteps },
    { path: '/registration/psSteps/:id?', name: 'PSteps', component: PSSteps },
    { path: '/registration/secSteps/:id?', name: 'SecSteps', component: SecSteps },
    { path: '/registration/confirmDoctor/:id?', name: 'ConfirmDoctor', component: ConfirmDoctor },
    { path: '/registration', name: 'Start', component: Start },
    { path: '/userWronInfo/DoctorCorrectWrongnIfo/:userId', name: 'DoctorCorrectWrongnIfo', component: DoctorCorrectWrongnIfo },
    { path: '/cgu/:patient_id', name: 'AcceptCGU', component: AcceptCGU },

    { path: '/', name: 'Landing', component: Landing },
];

export default indexRoutes;
