import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import GoogleMapUtil from "../../util/GoogleMapUtil";
import GMapClinicalStudy from '../../components/map/GMapClinicalStudy';

import logoHydrogen from '../../assets/images/clinicalStudy/logo_hydrogen_base64';
import logoMedecin from '../../assets/images/clinicalStudy/logo_medecin_base64';
import logoClinicalStudy from '../../assets/images/clinicalStudy/logo_clinical_study_base64';
import logoGeoloc from '../../assets/images/clinicalStudy/logo_geolocalisation_base64';
import logoHorloge from '../../assets/images/clinicalStudy/logo_horloge_base64';
import logoCible from '../../assets/images/clinicalStudy/logo_cible_base64';
import logoPlus from '../../assets/images/clinicalStudy/logo_plus_base64';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class ClinicalStudyDetail extends React.Component {

    constructor(props) {
		super(props);

		this.state = {
            googleMapScriptLoaded: false,
            showMap: false,
            address: null,
            radius: null,
            lat: null,
            lng: null,
            loading: false
		}

		this.GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
		GoogleMapUtil.loadScript(
			`https://maps.googleapis.com/maps/api/js?key=${this.GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`, () =>
			this.setState({
				googleMapScriptLoaded: true
			})
		);

        this.showMap = this.showMap.bind(this);
        this.generatePDF = this.generatePDF.bind(this);
	}

    showMap(dataMap) {
        if (this.state.showMap === true && this.state.address === dataMap.address_components) {
            this.setState({
                showMap: false,
                address: null,
                radius: null,
                lat: null,
                lng: null
            })
        } else {
            this.setState({
                showMap: true,
                address: dataMap.address_components,
                radius: dataMap.radius,
                lat: dataMap.location.coordinates[1],
                lng: dataMap.location.coordinates[0]
            })
        }
    }

    generatePDF() {
        this.setState({
            loading: true
        })
        var docDefinition = {
            content: [
                {
                    stack:[
                        {
                            alignment: 'center',
                            columns:[
                                [
                                    {
                                        width: '20%',
                                        stack:[
                                            {
                                                image: logoHydrogen,
                                                fit: [60, 60]
                                            },
                                            {
                                                text: 'HYDROGEN', style: 'hydrogen',
                                            }
                                        ], 
                                        style: 'headerLogo',
                                        margin: [0, 0, 60, 10]
                                    }
                                ],
                                [
                                    {
                                        width: '50%',
                                        stack:[
                                            {
                                                text: 'www.hydrogen-rempla.com', style: 'headerTitle',
                                            },
                                            {
                                                text: '06 07 52 30 39', style: 'headerTitleTel',
                                            },
                                        ],
                                        margin: [-50, 15, 70, 0]
                                    }
                                    
                                ]
                            ]
                        },
                        {
                            canvas: [
                                { 
                                    type: 'line', x1: 100, y1: 0, x2: 450, y2: 0, lineWidth: 3
                                }
                            ]
                        }
                    ], style: 'header ' 
                },
                {
                    text: 'Nous recherchons des patients pour notre étude clinique', style: 'titleClinicalStudy'
                }, 
                {
                    text: this.props.clinicalStudy.title, style: 'titleStudy'
                }, {
                    columns: [
                        {
                            width: 75,
                            image: logoMedecin,
                            fit: [80, 80],
                        }, [
                            {
                                width: 500,
                                text: "Pathologies étudiée : ", style: 'title'
                            }, {
                                width: 500,
                                text: this.props.clinicalStudy.pathology, style: 'textTitle'
                            }
                        ], 
                        {
                            width: 100,
                            image: logoClinicalStudy,
                            fit: [100, 100],
                            absolutePosition: {x: 510, y: 250}
                        }
                    ],
                    style: 'row'
                }, {
                    columns: [
                        {
                            width: 75,
                            image: logoGeoloc,
                            fit: [40, 40]
                        }, [
                            {
                                width: 500,
                                text: 'Lieu de l\'étude : ', style: 'title'
                            }
                        ]
                    ],
                    style: 'row'
                }, {
                    columns: [
                        {
                            width: 75,
                            image: logoHorloge,
                            fit: [40, 40]
                        }, {
                            width: 500,
                            text: 'Durée de l\'étude : ' + this.props.clinicalStudy.duration + ' mois', style: 'durationCustom'
                        }
                    ],
                    style: 'row'
                }, {
                    columns: [
                        {
                            width: 75,
                            image: logoCible,
                            fit: [40, 40]
                        }, [
                            {
                                width: 500,
                                text: 'Objectifs principaux : ', style: 'title'
                            }, {
                                width: 500,
                                text: this.props.clinicalStudy.goal, style: 'textTitle'
                            }
                        ]
                    ],
                    style: 'row'
                }, {
                    columns: [
                        {
                            width: 75,
                            image: logoPlus,
                            fit: [40, 40]
                        }, [
                            {
                                width: 500,
                                text: 'Bénéfices pour les patients : ', style: 'title'
                            }, {
                                width: 500,
                                text: this.props.clinicalStudy.benefits, style: 'textTitle'
                            }
                        ]
                    ]
                }, {
                    text: 'Parlez en à votre médecin !', style: 'speakDoctor'
                }
            ],
            styles: {
                headerTitle: {
                    bold: true,
                    fontSize: 15,
                    alignment: 'center',
                },
                headerTitleTel : {
                    bold: true,
                    fontSize: 15,
                    alignment: 'center',
                },
                headerLogo: {
                    alignment: 'right',
                },
                header :{
                    padding: [0, 120, 20, 20]
                },
                row:{
                    margin: [0, 0, 0, 15]
                },
                titleClinicalStudy: {
                    color: '#2788E8',
                    bold: true,
                    fontSize: 17,
                    alignment: 'center',
                    margin: [0, 10, 0, 0],
                },
                titleStudy: {
                    bold: true,
                    fontSize: 17,
                    alignment: 'center',
                    margin: [0, 25, 0, 25]
                },
                titleContent: {
                    alignment: 'center',
                    margin: [0, 0, 0, 40]
                },
                title: {
                    fontSize: 17,
                    bold: true,
                    margin: [0, 0, 0, 5],
                    characterSpacing: 0.5
                },
                durationCustom: {
                    fontSize: 17,
                    bold: true,
                    margin: [0, 10, 0, 0]
                },
                textTitle: {
                    fontSize: 12,
                    bold: false,
                    margin: [0, 0, 0, 10]
                },
                speakDoctor: {
                    fontSize: 20,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 30, 0, 0]
                },
                serviceContent: {
                    margin: [0, 0, 0, 5]
                }
            }
        }

        if (this.props.clinicalStudy.type_clinical_study === 'hospital') {
            this.props.clinicalStudy.services.forEach((service) => {
                let servicePdf = [ {
                    width: 500,
                    text: (service.institution_name ? service.institution_name + ' - ' : '') + service.service_name +" \n" + (service.address_components.street_number ? service.address_components.street_number + ', ' : '') + service.address_components.route + ', ' + service.address_components.postal_code + ' ' + service.address_components.city, style: 'serviceContent'
                }]
    
                docDefinition.content[4].columns[1].push(servicePdf);
            })
        } else {
            let servicePdf = [ {
                width: 500,
                text: 'Votre médecin se charge de votre suivi dans son cabinet médical', style: 'serviceContent'
            }]

            docDefinition.content[4].columns[1].push(servicePdf);
        }

        pdfMake.createPdf(docDefinition).download('Etude-' + this.props.clinicalStudy.acronyme + '.pdf', () => {
            this.setState({
                loading: false
            })
        });
    }

    loading = () => <div className="animated fadeIn pt-1 text-center"><div className="spinner-border"></div></div>;

	render() {
		const { clinicalStudy } = this.props;

		return(
			<React.Fragment>
                <Modal size="lg" isOpen={this.props.isOpen} toggle={this.props.closeModal} className="simple-modal simple-modal-white srounded">
                    <ModalHeader toggle={this.props.closeModal}></ModalHeader>
                    <ModalBody className="pl-2 pr-2 pl-md-5 pr-md-5 pt-0">

                        <div className="row pb-3">
                            {this.state.loading === true ?
                                <div className="d-flex align-items-end col">
                                    {this.loading()}
                                </div>
                                :
                                <div className="d-flex align-items-end col">
                                    <button onClick={this.generatePDF} className="srounded text-white bg-green btn btn-secondary">Télécharger le pdf pour afficher en salle d’attente</button>
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="d-flex align-items-start bold">
                                <p>Cloture des inclusions le {moment(clinicalStudy.end_date).format('DD/MM/YYYY')} </p>
                            </div>
                        </div>

                        {clinicalStudy.rdv_to_plan && clinicalStudy.rdv_to_plan.length > 0 && 
                            <div className="row">
                                <div className="d-flex align-items-start bold"><b>Visites à prévoir :</b> &nbsp;<br />
                                {clinicalStudy.rdv_to_plan.map((elem, key) =>
                                    <span key={key} className="align-items-center">
                                        {elem.number} {<FormattedMessage id={elem.units} />}; &nbsp;
                                    </span>
                                )}
                                </div>
                            </div>
                        }
                        <div className="pt-3"><span className="font-very-big"><b>Titre de l’étude : </b></span></div>
                        <div className="pt-3 pb-2"><span className="font-very-big"><b>{clinicalStudy.acronyme}</b></span></div>

                        {clinicalStudy.services && clinicalStudy.services.length > 0 &&
                            <div className="pt-3">
                                {clinicalStudy.type_clinical_study === 'hospital' ? 
                                    <div className="bold">Services :</div>
                                    :
                                    <div className="bold">Entreprise :</div>
                                }
                                {clinicalStudy.services.map((elem, key) =>
                                    <div key={key}>
                                        <div className="d-inline-block text-center loc-icon-container-custom" onClick={() => this.showMap(elem)}>
                                            <div className="loc-icon-custom"></div>
                                        </div>
                                        <div className="d-inline-block align-top pl-3 mt-2">
                                            {elem.institution_name && clinicalStudy.type_clinical_study === 'hospital' ? elem.institution_name + ' - ' : ''} {elem.service_name} - {elem.name} {elem.first_name}
                                        </div>
                                    </div>
                                )}

                                {this.state.googleMapScriptLoaded && this.state.showMap &&
                                    <GMapClinicalStudy 
                                        isMarkerShown={true}
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ width: `100%`, height: `300px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />} 
                                        address={this.state.address} 
                                        radius={this.state.radius}
                                        lat={this.state.lat}
                                        lng={this.state.lng}
                                    />
                                }
                            </div>
                        }
                        {clinicalStudy.type_clinical_study === 'cabinet' &&
                            <div className="pt-3"><b>Lieu de l'étude :</b> Dans le cabinet du médecin</div>
                        }   
                        <div className="pt-3"><b>Pathologie :</b> {clinicalStudy.pathology}</div>

                        <div className="pt-3 pb-3 font-big"><b>Votre indemnisation par patient inclus : {clinicalStudy.price_per_patient} €</b></div>

                        <button disabled={clinicalStudy.number_patient_goal === clinicalStudy.patient.length} className={(clinicalStudy.number_patient_goal === clinicalStudy.patient.length) ? "srounded pl-5 pr-5 pt-2 pb-2 text-white bg-blue btn btn-secondary disabled" : "srounded pl-5 pr-5 pt-2 pb-2 text-white bg-blue btn btn-secondary"} onClick={this.props.openAddPatientModal}>{clinicalStudy.number_patient_goal === clinicalStudy.patient.length ? "Cette étude est complète" : "Inclure un patient"}</button>

                       
                        <div className="mt-2 pb-5 jumbotron ws-pl">
                            <div className="pb-3"><b>Bénéfices pour le patient :</b></div>
                            {clinicalStudy.benefits}
                        </div>
                        <div className="pt-3 pb-5 jumbotron ws-pl">
                            <div className="pb-3"><b>Rationnel de l'étude :</b></div>
                            {clinicalStudy.description}
                        </div>
                        

                    </ModalBody>
                </Modal>
			</React.Fragment>
		)
	}
}