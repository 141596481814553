import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Col, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import moment from 'moment';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";

class SModalWarningExceptionalClose extends React.Component {

    constructor(props) {
        super(props);

        moment.locale('fr')

        this.state = {

        };

        this.openModal = this.openModal.bind(this);
    };

    openModal() {
        this.props.openNewAppointment(this.props.event, true);
        this.props.close();
    }

    render() {

        return (
            <Modal size="lg" isOpen={true} toggle={this.props.close} className="simple-modal simple-modal-white srounded modalCustom">

                <ModalHeader className="header-modal-choice">
                    <div className="text-left">
                        <button type="button" onClick={this.props.close} className="close d-inline-block" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div>
                        Confirmation
                    </div>
                </ModalHeader>

                <ModalBody className="row">
                    <Col sm="12">
                        {this.props.message}
                    </Col>

                </ModalBody>

                <ModalFooter className="justify-content-center">
                    <Button color="secondary" onClick={this.props.close}>Annuler</Button>
                    <Button color="success" onClick={this.openModal}>Valider</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SModalWarningExceptionalClose));