import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage } from 'react-intl';
import DoctorSecretariat from '../../../enum/DoctorSecretariat';
import DoctorOfficeType from '../../../enum/DoctorOfficeType';
import ConsultationsMode from '../../../enum/ConsultationsMode';
import MedicalSoftware from '../../../enum/MedicalSoftwares';
import Teleconsulation from '../../../enum/Teleconsultations';
import 'ladda/dist/ladda-themeless.min.css';

export default class DoctorMoreDetailsProfile extends Component {

    render() {
        const { doctorCharacteristic } = this.props;

        return (
            <React.Fragment>

                <h5 className="">
                    <b><FormattedMessage id="More.Details" /></b>
                </h5>

                <p className="mt-0 mb-0">
                    <FormattedMessage id="Secretariat" />:&nbsp; <b>
                    <FormattedMessage id={DoctorSecretariat.getLabel(doctorCharacteristic.secretariat)} /></b>
                </p>

                <p className="mt-0 mb-0">
                    <FormattedMessage id="Office.Type" />:&nbsp; <b>
                    <FormattedMessage id={DoctorOfficeType.getLabel(doctorCharacteristic.office_type)} /></b>
                </p>

                <p className="mt-0 mb-0"><FormattedMessage id="Consultations" />:&nbsp;
                    <b><FormattedMessage id={ConsultationsMode.getLabel(doctorCharacteristic.consultation)} /></b>
                </p>

                <p className="mt-0 mb-0"><FormattedMessage id="Medical.Software" />:&nbsp;
                        {(doctorCharacteristic.medical_software === "0") ?
                        <b>{doctorCharacteristic.other_software}</b>
                        :
                        <b><FormattedMessage id={MedicalSoftware.getLabel(doctorCharacteristic.medical_software)} /></b>
                    }
                </p>
                {doctorCharacteristic.teleconsultation && 
                    <p className="mt-0 mb-0"><FormattedMessage id="Teleconsultation" />:&nbsp;
                        <b><FormattedMessage id={Teleconsulation.getLabel(doctorCharacteristic.teleconsultation)} /></b>
                    </p>
                }
                
                <p>
                    <FormattedMessage id="Average.Time.Per.Visit.Per.Week" />:&nbsp; <b>{doctorCharacteristic.duree_Moyenne_Visite}h</b>
                </p>

                </React.Fragment >
        );
    };

}