import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProfileImages from "../../../enum/ProfileImages";
import Stars from '../../../components/misc/Stars';
import RatingEnt from '../../../entities/RatingEnt';
import { getsBy } from '../../../actions/global/global';

class DAvailabilityCardBySector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: false,
        };
        
        this.ref = React.createRef();
    }
    
    shake() {
        this.setState({ shake: true });
        setTimeout(() => this.setState({ shake: false }), 1250)
    }

    render() {
        const { availability } = this.props;
        var addClassName = "";
        if (this.state.shake) addClassName = " shake ";

        if (this.props.addRef) this.props.addRef(this.ref);
        if (this.props.addShakeCbk) this.props.addShakeCbk(() => this.shake());
        return (
            <React.Fragment>

                <div className={"d-availability-card pb-0 mb-0 mb-4 clickable pt-0" + addClassName} ref={this.ref}>
                    
                    <div className="row min-height-70 ml-mr-0">
                        <div className='col-lg-3'>
                            <div className="clickable height-50 profile" style={{ marginTop: "8px" }}>
                                <img src={ProfileImages.getSrc(availability.substitute.image)} alt="user img" className="d-inline" style={{ borderRadius: "50px" }} width="50" height="50" />

                                <div className="d-inline-block ml-2 height-50 align-middle">
                                    <div className="d-inline-block pl-2 pr-2 height-50 break-word ellipsis">
                                        <b>{availability.substitute.first_name}</b>
                                    </div>
                                    <div className="d-inline-block pl-2 pr-2 height-50 align-top"><Stars nbr={RatingEnt.averageAllStars(availability.substitute.rating)} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 offset-lg-7">
                            <a className='text-white border xrounded bg-green text-white p-3 text-center' href="/#/home/subscription">
                                Entrer en contact
                            </a>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        availabilitys: state.global.availabilitys,
        user: state.global.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(getsBy(objName, fields, values, callback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DAvailabilityCardBySector);


