import React from 'react';
import { connect } from 'react-redux';
import 'react-datetime/css/react-datetime.css';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, UncontrolledTooltip } from "reactstrap";
import SReplacementTitle from './SReplacementTitle';
import ProfileCard from '../../boths/ProfileCard';
import ReplacementEnt from '../../../entities/ReplacementEnt';
import SubstituteProfileModal from '../../substitutes/profile/SubstituteProfileModal';
import SReplacementContractModal from '../replacements/SReplacementContractModal';
import ModalIntermediate from '../replacements/ModalIntermediate'
import ModalNegociation from '../../negociation/ModalNegociation';

import { upds } from "../../../actions/global/global";

class SReplacementCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            shake: false
        };

        this.ref = React.createRef();
        this.openNegociationModal = this.openNegociationModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    };

    shake() {
        this.setState({ shake: true });
        setTimeout(() => this.setState({ shake: false }), 1250);
    }

    ongoingAgreement = (replacement, user) => {
        if (!replacement.agreements) return false;

        for (let agreement of replacement.agreements) {
            if (agreement.substitute_id === user._id) return true;
        };

        return false;
    };

    signatureModal(replacement, doctor, doctorCharacteristic, officeTimeTable) {
        this.setState({
            modal: <SReplacementContractModal
                doctor={doctor}
                doctorCharacteristic={doctorCharacteristic}
                replacement={replacement}
                officeTimeTable={officeTimeTable}
                toggle={() => this.closeModal()} 
                redirectAfterSign={this.props.redirectAfterSign} />
        });
    }

    profileModal(e, user, characteristic, timeTable = {}, availability, replacementId) {
        if (!user) return;
        if (user.role === "doctor") {
            let found = false;
            let seenBySubstitute = false;
            // si c'est une invitation
            if (this.props.replacement.availability_id || this.props.replacement.invitation_by_doctor === true) {
                found = true;
            // Si y'a déjà des applicants dans le replacement
            } else if (this.props.replacement.applicants) {
                // Boucle dessus
                found = this.props.replacement.applicants.find((applicant) => {
                    // Si le médecin remplaçant est déjà dans la liste et qu'il a déjà accepté le rdv
                    return (applicant.substitute_id === this.props.user._id && (applicant.substitute_status === 1 ||  applicant.substitute_status === 3 ))
                })
                if (found) {
                    seenBySubstitute = found.seen_by_substitute;
                }
            }
            
            if (found && seenBySubstitute === false) {
                this.props.onUpds("replacement/seen/" + replacementId, {})
            }
            this.setState({
                modal: <ModalIntermediate
                    close={this.closeModal}
                    replacement={this.props.replacement}
                    replacementId={replacementId}
                    doctor={user}
                    doctorCharacteristic={characteristic}
                    officeTimeTable={timeTable}
                    onApply={(replacement, user, characteristic, timeTable) => this.signatureModal(replacement, user, characteristic, timeTable)}
                    substitute={this.props.user}
                    acceptRemplacement={found}
                    applicants={this.props.replacement.applicants}
                    openNegociationModal={this.openNegociationModal}
                />
            })
        } else if (user.role === "substitute") {
            this.setState({
                modal: (
                    <SubstituteProfileModal
                        substitute={user}
                        characteristics={characteristic}
                        availability={availability}
                        close={this.closeModal}
                    />
                )
            });
        } else {
            throw new Error("role is required!");
        }
    };

    closeModal(closeByAcceptReplacement) {
        if (closeByAcceptReplacement) {
            this.setState({ modal: null });
        } else {
            if(this.props.updateReplacementAfterAccept) {
                this.props.updateReplacementAfterAccept();
            }

            this.setState({ modal: null });
        }
    };

    // Fonction pour ouvrir la modal de négociation
    openNegociationModal() {
        this.setState({
            modal: <ModalNegociation
                replacement={this.props.replacement}
                close={this.closeModal} 
                doctor={false}
                substitute={this.props.user._id}
                />
        })
    }

    render() {
        const { user, replacement } = this.props;
        if (!user || !replacement) return null;

        var addClassName = "";
        if (this.state.shake) addClassName = " shake ";

        if (this.props.addRef) this.props.addRef(this.ref);
        if (this.props.addShakeCbk) this.props.addShakeCbk(() => this.shake());

        const cancelledBySubAgreement = ReplacementEnt.subCancelled(replacement, user);
        const cancelledByDocAgreement = ReplacementEnt.docCancelled(replacement, user);
        const refuseByDoc = ReplacementEnt.refuseByDoc(replacement, user);
        const cancelledAgreement = (cancelledBySubAgreement || cancelledByDocAgreement);
        const isDeclined = ReplacementEnt.isDeclinedBySubstitute(replacement, user._id);
        const alreadyAccepted = ReplacementEnt.alreadyAccepted(replacement)
        const acceptedByAnother = ReplacementEnt.acceptedByAnother(replacement, user._id);
        // const hasApplied = ReplacementEnt.hasApplied(replacement, user);

        return (
            <React.Fragment>

                <div className={"d-replacement-card-dim pb-0 mb-0 mb-4 pt-0 mt-0 " + addClassName} style={{ position: "relative" }} ref={this.ref}>

                    <div className={"d-replacement-card pb-0 mb-0 mb-4 " + ((replacement.deleted || cancelledAgreement || isDeclined || refuseByDoc || alreadyAccepted) ? " opacity-low " : " clickable ")}
                        onClick={(e) => {
                            if (!(replacement.deleted || cancelledAgreement)) {
                                this.profileModal(e, replacement.doctor, replacement.doctorCharacteristic, replacement.officeTimeTable, undefined, replacement._id)
                            }
                        }}>

                        <div>

                            <Row className="min-height-70 ml-mr-0">

                                <Col lg="3">
                                    <ProfileCard user={replacement.doctor} replacement={replacement} />
                                </Col>

                                <Col>
                                    <SReplacementTitle replacement={replacement} updateReplacementAfterAccept={this.props.updateReplacementAfterAccept}/>
                                </Col>

                            </Row>

                            {(ReplacementEnt.hasLiked(replacement, user)) &&
                                <div id="bubble" className="card-bubble">
                                    <i className="fa icon-ICON__COEUR_ROSE mt-2 pink"></i>
                                    <UncontrolledTooltip placement="top" target="bubble" innerClassName="white-tooltip" arrowClassName="white-arrow" autohide={false}>
                                        <p>Ajoutée à vos annonces suivies</p>
                                    </UncontrolledTooltip>
                                </div>
                            }
                        </div>

                    </div>

                    {(replacement.deleted) &&
                        <div className="card-overflow">
                            <FormattedMessage id="Deleted.Replacement" />
                        </div>
                    }

                    {(!replacement.deleted && (cancelledBySubAgreement || isDeclined)) &&
                        <div className="card-overflow">
                            <FormattedMessage id="Cancelled.By.You" />
                        </div>
                    }

                    {(!replacement.deleted && (cancelledByDocAgreement || refuseByDoc)) &&
                        <div className="card-overflow">
                            <FormattedMessage id="Cancelled.By.Doctor" />
                        </div>
                    }

                    {(!replacement.deleted && alreadyAccepted && !cancelledBySubAgreement && !cancelledByDocAgreement && !isDeclined) &&
                        <div className="card-overflow">
                            <FormattedMessage id="Already.Accepted.Replacement" />
                        </div>
                    }

                    {acceptedByAnother === true &&
                        <div className="card-overflow">
                            <FormattedMessage id="Already.Accepted.Replacement" />
                        </div>
                    }

                    {/*(!cancelledAgreement && !replacement.deleted && replacement.overlapping) &&
                        <div className="card-overflow">
                            <FormattedMessage id="Overlapping.Replacement" />
                        </div>
                    */}

                    {/* {hasApplied &&
                        <div className="card-overflow">
                            <FormattedMessage id="Already.Applied" />
                        </div>
                    } */}

                </div>

                {this.state.modal}

            </React.Fragment>
        );
    };

}

const mapStateToProps = state => {
    return {
        user: state.global.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpds: (objName, obj, cbk) => dispatch(upds(objName, obj, cbk))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SReplacementCard));