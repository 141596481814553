
import React from 'react';


export default class CGUs extends React.Component {

    render() {

        return (
            <>

            <h1 className="w-100 text-center">Politique de confidentialité du site internet HYDROGEN</h1>
            <h5 className="w-100 text-center mb-5">Version mise à jour le 18 mars 2020</h5>


            <p>Dans une démarche de transparence la société Hydrogen, vous informe qu’elle est responsable de traitement de vos données personnelles. </p>

            <p>Au sein de la présente politique de confidentialité, vous trouverez des informations sur la portée et les conséquences des traitements que nous
    réalisons à partir de vos données personnelles.</p>

            <p>A cet égard, Hydrogen, s’engage à respecter la réglementation applicable à la protection des données personnelles, notamment le règlement européen
    2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre
     circulation de ces données dit règlement général sur la protection des données (le « RGPD ») et la loi n°78-17 du 6 janvier 1978 (la « loi Informatique
     et Libertés ») dans sa version modifiée.</p>

            <p>Hydrogen met en œuvre les principes de protection de la vie privée « dès la conception » et « par défaut ». Concrètement, cela signifie qu’Hydrogen met
    en place des processus et mesures permettant de garantir une protection optimale de vos données personnelles et une collecte des données adéquate,
    pertinente et limitée à ce qui est nécessaire au regard des objectifs de leurs traitements, tels qu’ils vous sont exposés, que ce soit par son organisation
     interne, la configuration des services et la nature et le volume des données traitées.</p>

            <p>Cette politique de confidentialité s’applique à toute donnée personnelle vous concernant qu’Hydrogen collecte via le site internet Hydrogen.
    Elle est purement informative et ne crée pas d’obligation au-delà de ce qui est prévu par la réglementation précitée en matière de protection
    des données personnelles ou par les engagements contractuels entre Hydrogen et ses Utilisateurs.  Vous serez informés de toute éventuelle mise
    à jour de cette politique de confidentialité.</p>

            <h3 className="mt-5 mb-5">I.	Qui traite vos données personnelles ?</h3>

            <p>Hydrogen est une société par actions simplifiée, ayant son siège social 1047 allée Pierre Ziller, 06560 Valbonne et enregistrée au Registre du Commerce
    et des Sociétés de Grasse sous le numéro 877 575 597 (ci-après « Hydrogen »). </p>

            <p>La société Hydrogen est responsable de traitement en ce qu’elle décide des raisons pour lesquelles elle traite vos données et des moyens qu’elle met
    en œuvre pour le faire. A ce titre, elle donne des instructions précises à ses éventuels prestataires susceptibles de traiter vos données personnelles
    pour son compte, lesquels sont situés exclusivement sur le territoire de l’Union européenne et respectent les principes relatifs à la protection des
     données à caractère personnel.</p>

            <h3 className="mt-5 mb-5">II.	Comment nous contacter ?</h3>

            <p>Pour toute demande relative à vos données personnelles, vous pouvez nous contacter à l’adresse électronique suivante : support@hydrogen-rempla.com ou
    à l’adresse postale suivante : </p>

            <p>Docteur Glénet Stéphane, Délégué à la protection des données</p>
            <p>1047 Route des Dolines</p>
            <p>Allée Pierre Ziller</p>
            <p>06560 Valbonne</p>

            <h3 className="mt-5 mb-5">III.	Comment traitons-nous vos données personnelles ?</h3>

            <h4 className="mt-4 mb-4">1.	Pour la mise à disposition des services de mise en relation du site internet HYDROGEN</h4>

            <p>Hydrogen traite vos données pour vous fournir les services de son site interne de mise en relation de mèdecins pour faciliter la recherche de
    remplaçants et de remplacements médicaux.</p>

            <h4 className="mt-4 mb-4">Pourquoi Hydrogen utilise vos données ?</h4>

            <p>Certains traitements sont nécessaires à la mise à disposition des services de mise en relation pour :</p>

            <ul>
                <li>Créer, valider et mettre à jour votre compte utilisateur</li>
                <li>Faciliter la mise en relation de médecins installés et remplaçants, ayant des attentes compatibles </li>
                <li>Publier les annonces et les hiérarchiser selon les critères de recherche des médecins </li>
                <li>Gérer le planning de vos remplacements, permettre son suivi et sa visualisation</li>
                <li>Personnaliser les contrats de remplacement et permettre leur conclusion</li>
                <li>Vous fournir une assistance client</li>
            </ul>

            <p>D’autres sont soumis à votre consentement préalable, pour :</p>
            <ul>
                <li>Vous-contacter par mail ou téléphone, pour vous proposer des remplacements</li>
            </ul>

            <p>Enfin, les traitements peuvent être réalisés dans l’intérêt légitime d’Hydrogen, pour :</p>
            <ul>
                <li>Vous adresser des notifications relatives aux statuts de vos recherches et propositions de remplacements. Certaines notifications vous seront adressées par mail, dans un souci de vous
    informer en temps réel. Il est possible de désactiver ces notifications dans l’espace « RGPD » de votre compte personnel.</li>
                <li>Gérer les avis, incidents, réclamations, anomalies afin d’assurer le bon fonctionnement des services</li>
                <li>L’élaboration de statistiques quant à l’utilisation des services Hydrogen, afin de mieux comprendre vos besoins et d’améliorer nos services.</li>
            </ul>

            <h4 className="mt-4 mb-4">Quelles sont les données traitées ?</h4>

            <p>Pour les médecins installés</p>
            <ul>
                <li>vos données d’identification telles que votre nom, votre prénom, votre sexe, votre date de naissance, votre avatar, votre adresse email, votre mot de passe et votre numéro de téléphone, votre numéro RPPS ;</li>
                <li>les données relatives à votre cabinet telles que son adresse, la présence ou non d’un secrétariat afin de permettre des mises en relation pertinentes et d’éclairer le choix des médecins remplaçants ;</li>
                <li>les données relatives à votre activité et vos pratiques médicales ;</li>
                <li>les données relatives à chaque annonce de remplacement que ce soit pour les remplacements occasionnels, réguliers ou pour des gardes ;</li>
                <li>les contrats de remplacement que vous avez signés ;</li>
                <li>vos données d’utilisation nécessaires pour entretenir, optimiser et améliorer les services Hydrogen, et notamment le nombre de remplacements proposés ou les recherches que vous effectuez ;</li>
                <li>l’objet, le contenu, la date et l’heure des demandes que vous nous adressez.</li>
            </ul>

            <p>Pour les médecins remplaçants</p>
            <ul>
                <li>vos données d’identification telles que votre nom, votre prénom, votre sexe, votre date de naissance, votre avatar, votre adresse email, votre mot de passe, votre adresse, votre numéro de téléphone, votre numéro RPPS et votre numéro SIRET, et en cas de conclusion d’un contrat, votre signature électronique ; </li>
                <li>les données relatives à votre activité professionnelle et vos formations</li>
                <li>les contrats de remplacement que vous avez signés</li>
                <li>Vos données d’utilisation nécessaires pour entretenir, optimiser et améliorer les services Hydrogen, et notamment le nombre de remplacements proposés ou les recherches que vous effectuez ;</li>
                <li>l’objet, le contenu, la date et l’heure des demandes que vous nous adressez.</li>
            </ul>

            <h4 className="mt-4 mb-4">Qui a accès à vos données ?</h4>

            <p>Sont destinataires des données :</p>
            <ul>
                <li>les services internes de la société Hydrogen</li>
                <li>les autres utilisateurs dans le cadre de la mise en relation. Dans ce cadre, nous communiquons :</li>
                <li className="ml-4">votre prénom et votre âge</li>
                <li className="ml-4">les données relatives à votre cabinet et à votre activité, pour faciliter la mise en relation pertinente</li>
                <li className="ml-4">vos annonces</li>
                <li className="ml-4">l’ensemble de vos coordonnées afin de personnaliser et conclure le contrat de remplacement </li>
                <li className="ml-4">votre numéro de téléphone une fois le contrat de remplacement signé</li>
                <li>les prestataires et partenaires d’Hydrogen qui sont en charge :</li>
                <li className="ml-4">de l’hébergement des données dans le cloud</li>
                <li className="ml-4">d’analyser les données à des fins statistiques</li>
                <li className="ml-4">de la sécurité de l’application et des services Hydrogen</li>
                <li>l’ordre des médecins si l’une des parties au contrat de remplacement choisit que nous leur adressions ledit contrat via le site</li>
                <li>des consultants, avocats, comptables et autres professionnels accompagnant Hydrogen dans la conduite de son activité</li>
            </ul>

            <p>Aucun transfert de données hors de l'Union européenne n'est réalisé.</p>

            <h4 className="mt-4 mb-4">Combien de temps conservons nous vos données ?</h4>

            <p>Vos données sont conservées pour les durées suivantes :</p>
            <ul>
                <li>vos données d’identification et relatives à votre cabinet, votre activité : pour la durée de votre inscription à Hydrogen </li>
                <li>les contrats de remplacement pour la durée de votre inscription à Hydrogen si les deux parties l’ont signées, jusqu’à la date de remplacement concernée si seul le médecin remplaçant l’a signé</li>
                <li>vos données d’utilisation : le remplissage de votre planning et vos demandes de remplacements pour la durée de votre inscription à Hydrogen </li>
            </ul>

            <p>Toutefois au terme de ces durées, Hydrogen conserve les données nécessaires dans le cadre du traitement des litiges ou au respect d’une obligation légale.
    Elles sont alors archivées pour une durée n’excédant pas la durée nécessaire aux finalités pour lesquelles elles sont conservées, conformément aux dispositions en vigueur (notamment mais non exclusivement celles prévues par le code de commerce, le code civil et le code de la consommation). Elles sont ensuite anonymisées ou supprimées.</p>

            <h4 className="mt-4 mb-4">2.	Pour assurer des enquêtes et des opérations de communication</h4>

            <p>Hydrogen traite vos données pour réaliser ses opérations marketing (gérer l’envoi de la newsletter, réaliser des enquêtes de satisfactions, communiquer
    sur les mises à jour et nouveauté des services Hydrogen).</p>

            <h4 className="mt-4 mb-4">Pourquoi Hydrogen utilise vos données ?</h4>

            <p>Hydrogen traite vos données dans son intérêt légitime pour :</p>
            <ul>
                <li>faire la promotion de ses services</li>
                <li>Gérer les abonnements à la newsletter</li>
                <li>Envoyer les newsletters et des emails de prospection ou d’information relatives aux nouveautés et mises à jour des services, si vous en avez émis le souhait, ou si vous êtes déjà Utilisateur et que vous ne vous y êtes pas opposé</li>
                <li>Elaborer des statistiques sur les données conservées, liées au service d’abonnement à la newsletter</li>
                <li>améliorer ses services</li>
                <li>Réaliser des enquêtes de satisfactions et analyser les résultats de ces enquêtes</li>
            </ul>

            <p>D’autres traitements de vos données sont nécessaires pour qu’Hydrogen respecte ses obligations légales pour :</p>
            <ul>
                <li>Gérer le recueil du consentement et les durées de conservation des données</li>
            </ul>

            <h4 className="mt-4 mb-4">Quelles sont les données traitées ?</h4>

            <ul>
                <li>adresse de courrier électronique (e-mail) ;</li>
                <li>date d’abonnement à la newsletter ;</li>
                <li>données relatives à votre opposition à recevoir des sollicitation ;</li>
                <li>statistiques liées au service de newsletter (ouverture de courriel, date, ouverture des liens, etc.) nécessaires pour déterminer les sujets qui vous intéressent et améliorer le service de newsletter.</li>
                <li>Vos réponses aux questions qui vous sont posées à l’occasion d’enquêtes de satisfaction.</li>
            </ul>

            <h4 className="mt-4 mb-4">Qui a accès à vos données ?</h4>

            <p>Sont destinataires des données :</p>
            <ul>
                <li>les services internes d’Hydrogen</li>
                <li>les prestataires et partenaires d’Hydrogen qui sont en charge :</li>
                <li>de l’hébergement des données dans le cloud</li>
                <li>de la réalisation d’opération et de la fourniture d’outils marketing</li>
                <li>de la sécurité de l’application et des services Hydrogen</li>
                <li>de l’analyse des données à des fins statistiques pour le compte d’Hydrogen et afin d’améliorer les services </li>
                <li>des consultants, avocats, comptables et autres professionnels accompagnant Hydrogen dans la conduite de son activité</li>
            </ul>

            <p>Aucun transfert de données hors de l'Union européenne n'est réalisé.</p>

            <h4 className="mt-4 mb-4">Combien de temps conservons nous vos données ?</h4>

            <ul>
                <li>vos données relatives à la newsletter et aux emails envoyés : 3 ans à compter de sa collecte ou du dernier contact émanant de vous ;</li>
                <li>Les données relatives à votre opposition : 3 ans à compter de votre inscription dans la liste d’opposition</li>
                <li>Les données relatives aux enquêtes de satisfaction : pour la durée nécessaire à la réalisation de l’enquête, de l’analyse de ses résultats et pour une durée maximale de 3 ans</li>
            </ul>

            <p>Toutefois au terme de ces durées, Hydrogen conserve les données nécessaires dans le cadre du traitement des litiges ou au respect d’une obligation légale.
    Elles sont alors archivées pour une durée n’excédant pas la durée nécessaire aux finalités pour lesquelles elles sont conservées, conformément aux dispositions en vigueur (notamment mais non exclusivement celles prévues par le code de commerce, le code civil et le code de la consommation). Elles sont ensuite anonymisées ou supprimées.</p>

            <p>Vous pouvez cependant, à tout moment, vous désinscrire à la réception de newsletter en envoyant un email à l’adresse suivante : support@hydrogen-rempla.com</p>

            <h4 className="mt-4 mb-4">3.	Pour administrer le site interne Hydrogen</h4>

            <p>Hydrogen traite vos données pour l’optimisation de son site internet.</p>

            <h4 className="mt-4 mb-4">Pourquoi Hydrogen utilise vos données ?</h4>

            <ul>
                <li>Faire fonctionner son site internet ;</li>
                <li>Améliorer son site internet ;</li>
                <li>Protéger son site internet.</li>
            </ul>

            <p>Les données recueillies dans ce cadre sont des cookies. Il s’agit par exemple des données de connexion de votre appareil (adresse IP, dates et heures
    d’accès au site internet Hydrogen) et les données de plantage.</p>

            <h3 className="mt-5 mb-5">IV.	Combien de temps conservons-nous vos données ?</h3>

            <p> Chacune de vos données est conservée pour la durée qui est spécifiée ci-dessus dans la rubrique qui lui est dédiée. </p>

            <p>Toutefois, les données permettant d’établir la preuve d’un droit ou d’un contrat, ou conservées au titre du respect d’une obligation légale, font l’objet d’une
    politique d’archivage intermédiaire pour une durée n’excédant pas la durée nécessaire aux finalités pour lesquelles elles sont conservées, conformément aux
     dispositions en vigueur (notamment mais non exclusivement celles prévues par le code de commerce, le code civil et le code de la consommation). </p>

            <h3 className="mt-5 mb-5">V.	Quels sont les outils à votre disposition pour contrôler vos données personnelles</h3>

            <p>Les paramètres de confidentialité de Hydrogen permettent aux utilisateurs de paramétrer leurs préférences relatives au partage de leur position et
     à la réception de notifications mobiles. Ces paramètres, la façon de les configurer ou de les modifier, et les conséquences de leur désactivation
      sont décrits ci-dessous.</p>

            <h4 className="mt-4 mb-4">Vos données d’identification et relatives à votre cabinet</h4>
            <p>Vous pouvez mettre à jour les données relatives à votre identification (à l’exception de votre adresse email), à votre cabinet et à votre activité
     en vous rendant dans la rubrique « Paramètre du compte » du menu de votre compte.</p>

            <h4 className="mt-4 mb-4">Vos réceptions de mails </h4>

            <p>Hydrogen envoie trois types de mails :</p>
            <ul>
                <li>Des mails relatifs à vos recherches de remplacement. </li>
                <li>Des mails relatifs aux mises à jour techniques du site internet Hydrogen.</li>
                <li>Des mails relatifs à l’actualité Hydrogen, aux mises à jour fonctionnelles et aux actions de promotion des services.</li>
            </ul>

            <p>Vous pouvez les désactiver ou configurer les moyens par lesquels ces notifications vous sont adressées à partir de la rubrique « RGPD » dans
    « Paramètre du compte » du site internet Hydrogen.</p>

            <h3 className="mt-5 mb-5">VI.	Quels sont les droits dont vous disposez ?</h3>

            <p>Vos droits sont les suivants (articles 15 et suivants du RGPD) :</p>
            <ul>
                <li>Obtenir la confirmation d’Hydrogen que vos données personnelles sont ou ne sont pas traitées </li>
                <li>Lorsqu'elles le sont, accéder à vos données personnelles ainsi qu’à plusieurs informations sur les traitements en place (droit d’accès – article 15 du RGPD) ; </li>
                <li>Faire rectifier vos données personnelles qui sont inexactes (droit de rectification – article 16 du RGPD)</li>
                <li>Faire effacer vos données personnelles dans certaines conditions (droit d’effacement ou « à l’oubli » – article 17 du RGPD)</li>
                <li>Obtenir la limitation des traitements, dans les cas visés au sein de la règlementation (droit à la limitation du traitement – article 18 du RGPD)</li>
                <li>Recevoir vos données personnelles dans un format structuré, couramment utilisé et lisible par machine, et/ou demander à Hydrogen de transmettre ces données à un autre responsable du traitement, lorsque le traitement est fondé sur le consentement ou sur le contrat et que le traitement est effectué à l'aide de procédés automatisés (droit à la portabilité des données – article 20 du RGPD)</li>
                <li>Vous opposer pour motif légitime au traitement de vos données personnelles (droit d’opposition – article 21.1. du RGPD)</li>
                <li>Vous opposer au traitement des données personnelles vous concernant sans avoir à invoquer de motifs légitimes (droit d’opposition à la prospection – article 21.2. du RGPD) – ce droit est absolu - vous pouvez ainsi demander, gratuitement et à tout moment, à ne plus recevoir de communication commerciale de la part d’Hydrogen</li>
                <li>Ne pas faire l’objet d'une décision fondée exclusivement sur un traitement automatisé produisant des effets juridiques vous concernant ou l'affectant de manière significative de façon similaire (droit de ne pas faire l’objet d’une décision individuelle automatisée – article 22 du RGPD)</li>
                <li>Définir des directives relatives à la conservation, à l'effacement et à la communication de vos données personnelles après votre décès et de les modifier et révoquer à tout moment (article 40-1 de la loi Informatique et Libertés). Ces directives peuvent être générales ou particulières, étant précisé que la société Hydrogen peut être uniquement dépositaire des directives particulières concernant les données qu’elle traite. Les directives générales peuvent être recueillies et conservées par un tiers de confiance numérique certifié par la CNIL.</li>
            </ul>

            <p>Si vous désignez un tiers auquel les données vous concernant pourront être communiquées après votre décès, vous vous engagez à informer ce tiers de votre démarche
     et du fait que des données permettant de l’identifier sans ambiguïté seront transmises à notre société et à lui communiquer la présente Politique de confidentialité.</p>

            <p>L’existence ou non de ces différents droits dépend notamment du fondement juridique du traitement concerné par la demande. Ces droits ne sont pas illimités et,
     dans certains cas, la société Hydrogen pourra refuser votre demande (par exemple pour des motifs légitimes impérieux pour ce qui concerne le droit d’opposition,
     hors prospection commerciale).</p>

            <p>Dans certaines hypothèses, il est possible qu’Hydrogen ne soit pas en mesure de donner une réponse positive à votre demande et vous en informe dans les délais
    requis en en justifiant.</p>

            <h4 className="mt-4 mb-4">Comment exercer vos droits ?</h4>

            <p>Sous réserve de respecter les conditions posées par la réglementation, vous pouvez exercer vos droits en écrivant à l’adresse électronique suivante :
    support@hydrogen-rempla.com ou à l’adresse postale suivante :</p>

            <p>Docteur Glénet Stéphane, Délégué à la protection des données</p>
            <p>1047 Route des Dolines</p>
            <p>Allée Pierre Ziller</p>
            <p>06560 Valbonne.</p>

            <p>En cas de doute raisonnable de notre part sur votre identité, nous pourrons être amenés à vous demander des informations ou documents supplémentaires
    afin de vérifier votre identité.</p>

            <p>Vous pouvez également procéder à certaines modifications de vos données à partir de votre espace personnel.</p>

            <p>Aucun paiement n'est exigé pour répondre à vos demandes relatives à l’exercice des droits précités. Toutefois, conformément à l’article 12 du RGPD, lorsque
    les demandes sont manifestement infondées ou excessives, notamment en raison de leur caractère répétitif, la société Hydrogen pourra exiger le paiement de
    frais raisonnables qui tiennent compte des coûts administratifs supportés pour fournir les informations, procéder aux communications, prendre les mesures demandées ou refuser de donner suite à ces demandes.</p>

            <p>Votre demande sera traitée au plus tard dans un délai d’un mois, délai pouvant être prolongé de deux mois, compte tenu de la complexité de la demande.
        Dans ce cas, vous serez informé de cette prolongation de délai dans le délai d’un mois à compter de la réception de votre demande.</p>

            <p>En cas de non-respect de vos droits « Informatique et libertés », vous disposez également du droit d’introduire une réclamation auprès de l’autorité de
            contrôle compétente, en l’occurrence de la Commission Nationale de l’Informatique et des Libertés, la CNIL.</p>

            <p>Pour en savoir plus : www.cnil.fr</p>

            <h3 className="mt-5 mb-5">VII.	Comment vos données sont-elles protégées ?</h3>

            <p>Hydrogen met en œuvre les mesures techniques et d'organisation appropriées afin de protéger vos données personnelles contre la destruction accidentelle ou illicite,
     la perte accidentelle, l'altération, la diffusion ou l'accès non autorisé à ces données.</p>

            <p>Cependant, Hydrogen ne maîtrise pas tous les risques liés au fonctionnement d’Internet et attire votre attention sur l’existence d’éventuels risques inhérents à son
fonctionnement.</p>

            <p>Hydrogen contrôle également la façon dont ses prestataires traitent vos données personnelles, de manière à ce que ces derniers présentent les garanties suffisantes
quant à la mise en œuvre des mesures appropriées de sécurité des données. </p>

            <p>Dans le cas où vous identifieriez une faille de sécurité affectant le site d’Hydrogen, vous vous engagez à nous communiquer les informations pertinentes sur cette faille,
     de manière confidentielle.</p>

            </>
        );
    }
}
