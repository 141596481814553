import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Nav, Navbar, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, Dropdown } from 'reactstrap';
import { logout } from "../../actions/authentication/authentication";
import ProfileImages from "../../enum/ProfileImages";
import Notifications from "../../views/notifications/Notifications";

import homeImgRecherche from '../../assets/images/homes/HYDROGEN_ILLU_RECHERCHE.png';
import homeImgMission from '../../assets/images/homes/HYDROGEN_ILLU_MISSIONS.png';
import homeImgAccount from '../../assets/images/homes/HYDROGEN_ILLU_COMPTE.png';
import homeImgParam from '../../assets/images/homes/HYDROGEN_ILLU_PARAM.png';
import homeImgDeco from '../../assets/images/homes/HYDROGEN_ILLU_DECO.png';
import homeImgSubscribe from '../../assets/images/homes/HYDROGEN_ILLUS-ABONNEMENT.png';
import homeImgMenu from '../../assets/images/homes/HYDROGEN_ILLU_MENU.png';
import homeImgClinicalStudy from '../../assets/images/homes/HYDROGEN_ILLUSTRATIONS_RECHERCHESCLINIQUES.png';
import homeImgAnnonce from '../../assets/images/homes/HYDROGEN_ILLU_ANNONCE.png'; // Poster annonce

import homepng from '../../../src/Home.png';

class Header extends React.Component {

	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.showMobilemenu = this.showMobilemenu.bind(this);

		this.state = {
			toggle: false
		};
	};

	toggle() {
		this.setState({
			toggle: !this.state.toggle
		});
	};

	showMobilemenu() {
		document.getElementById('main-wrapper').classList.toggle('show-sidebar');
	};

	loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-muted mx-auto" role="status"></div></div>;

	button = (i18n, link = "", img) => {
		return (
			<div className="d-flex justify-content-center">
				<Link to={link} className="a-unstyled">
					<div>
						<img src={img} alt="" width={50} height={50} className="r"/>
					</div>
					{i18n && 
						<div className=''>
							<FormattedMessage id={i18n} />
						</div>
					}
				</Link>
			</div>
		);
	}

	render() {
		const { user, notifications, listOfClinicalStudy } = this.props;
 
		return (
			<header className="topbar navbarbg" data-navbarbg={this.props.data.settings[0].navbarbg}>

				<Navbar className={"top-navbar " + (this.props.data.settings[0].navbarbg === "skin6" ? 'navbar-light' : 'navbar-dark')} expand="xs">

					<Link to="/home" className="text-right float-right a-unstyled hover-zoom ml-2" >
						<div className="hydrogen-logo-sm d-inline-block align-middle"></div>
						<div className="ml-3 bold d-sm-inline-block align-middle fs-18 lp-3 d-none">
						</div>
					</Link>

					{(user && listOfClinicalStudy && listOfClinicalStudy.length > 0) && 
						<div className='header-clinical xrounded ml-2 bold d-md-inline-block align-middle d-none'>
							<a href="#/home/clinicalStudy" className="p-3 w-100 href-header-clinical"><FormattedMessage id="Clinical.Study.Dispo" /></a>
						</div>
					}

					<Collapse className="ml-auto navbarbg" isOpen={this.state.isOpen}
						navbar data-navbarbg={this.props.data.settings[0].navbarbg} >

						<Nav className="ml-auto float-right" navbar>

							{(!!user) ?
								<>
								
									<Dropdown  nav inNavbar className="" style={{display: "inline-block"}} isOpen={this.state.toggle} toggle={this.toggle}>
										<DropdownToggle nav caret className="pro-pic">
											<div className="mr-3 d-inline-block" style={{display: "inline-block"}}><FormattedMessage id="Hello" /> {user.first_name}!</div>

											<div className="m-0 p-0 d-inline-block" style={{ width: 54, height: "100%" }}>
												<div className="d-block position-relative">
													<img
														src={ProfileImages.getSrc(user.image)}
														alt="user"
														className="rounded-circle"
														width="54"
													/>
													{(notifications && notifications.length > 0) && <div className="notifications-circle">{Math.min(99, notifications.length)}</div>}
												</div>
											</div>

										</DropdownToggle>

										{(notifications && notifications.length > 0) &&
											<DropdownMenu right className="user-dd text-right mb-0 pb-0">

												<span className="with-arrow">
													<span className="bg-white" />
												</span>

												<div className="overflow-y-auto max-h-400px notification-dropdown">
													<Notifications notifications={notifications} toggle={this.toggle}/>
												</div>

											</DropdownMenu>
										}

									</Dropdown >
									
									<div className="inline-block" style={{display: "inline-block", marginTop: 7	}}>
												<Link to="/home" className="" >
													<img src={homepng} alt="" width={50} height={50}/>
												</Link>
											</div>
											
									<UncontrolledDropdown nav inNavbar className="" style={{display: "inline-block"}}>
										<DropdownToggle nav caret className="pro-pic text-center">
										<img src={homeImgMenu} alt="menu" style={{ width: '35px'}}/>

											{/* <div className="pt-1 mx-auto" style={{display: "inline-block"}}>
												<i className="fa fa-bars" style={{ fontSize: "22px", color: "#3D3D3D" }}></i>
											</div> */}
										</DropdownToggle>

										<DropdownMenu right className="user-dd text-right mb-0 pb-0">
											<span className="with-arrow">
												<span className="bg-white" />
											</span>

											{(user && user.role === "doctor") && <DoctorMenu 
												button={this.button}
												listOfClinicalStudy={listOfClinicalStudy}
											/>}

											{(user && user.role === "substitute") && <SubstituteMenu 
												button={this.button}
												listOfClinicalStudy={listOfClinicalStudy}
											/>}

											{(user && user.role === 'psychologist_install') && <PysIMenu 
												button={this.button}
												listOfClinicalStudy={listOfClinicalStudy}
											/>}

											{(user && user.role === 'radiologist_install') && <RadiologistMenu 
												button={this.button}
												listOfClinicalStudy={listOfClinicalStudy}
											/>}

											{(user && user.role === 'pediatrician_install') && <PediatricianMenu 
												button={this.button}
												listOfClinicalStudy={listOfClinicalStudy}
											/>}

											{(user && user.role === 'psychiatrist_install') && <PsychiatristMenu 
												button={this.button}
												listOfClinicalStudy={listOfClinicalStudy}
											/>}

											{(user && user.role === 'secretary') && <SecretaryMenu 
												button={this.button}
												listOfClinicalStudy={listOfClinicalStudy}
											/>}
										</DropdownMenu>

									</UncontrolledDropdown>
									
								</>
								:
								<>{this.loading()}</>
							}

						</Nav>

					</Collapse>
				
				</Navbar>
			</header>
		);
	};
};

const mapStateToProps = state => {
	return {
		user: state.global.user,
		agreements: state.global.agreements,
		replacements: state.global.replacements,
		availabilitys: state.global.availabilitys,
		notifications: state.global.notifications,
		listOfClinicalStudy: state.clinicalStudy.listOfClinicalStudy,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLogout: (callback) => dispatch(logout(callback))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);


// ===========================================================================================
// ===========================================================================================
// ===========================================================================================


const logoutDispatch = (dispatch) => { return { onLogout: (callback) => dispatch(logout(callback)) } };

const DoctorMenu = connect(null, logoutDispatch)(
	class DoctorMenu extends React.Component {
		render() {
			const {listOfClinicalStudy} = this.props;

			return (
				<div className='row header-menu mt-3 mb-2 ml-1 mr-1 text-center'>
					<div className='col-6 col-sm-4'>
						{this.props.button("Poster une annonce", "/home/me/replacements/public/add", homeImgAnnonce)}
					</div>
					<div className='col-6 col-sm-4'>
						{this.props.button("Chercher un remplaçant", "/home/availability/find", homeImgRecherche)}
					</div>
					<div className='col-6 col-sm-4'>
						{this.props.button("Mes contrats", "/home/me/replacements", homeImgMission)}
					</div>
					<div className='col-6 col-sm-4'>
						{this.props.button("Abonnement", "/home/subscription", homeImgSubscribe)}
					</div>
					{listOfClinicalStudy && listOfClinicalStudy.length > 0 &&
						<div className='col-6 col-sm-4 mt-2'>
							{this.props.button("Essais cliniques", "/home/clinicalStudy", homeImgClinicalStudy)}
						</div>
					}
					<div className='col-6 col-sm-4 mt-2'>
						{this.props.button("Compte", "/home/dprofile", homeImgAccount)}
					</div>
					<div className='col-6 col-sm-4 mt-2'>
						{this.props.button("Paramètres", "/home/dsettings", homeImgParam)}
					</div>
					
					<div className='col-6 col-sm-4 mt-2' onClick={() => this.props.onLogout(() => window.location.href = "/")}>
						{this.props.button("Déconnexion", "/home", homeImgDeco)}
					</div>
				</div>
			);
		}
	}
);


const SubstituteMenu = connect(null, logoutDispatch)(
	class SubstituteMenu extends React.Component {

		render() {
			const {listOfClinicalStudy} = this.props;

			return (
				<div className='row header-menu mt-3 mb-2 ml-1 mr-1 text-center'>
					<div className='col-6 col-sm-4'>
						{this.props.button("Chercher un remplacement", "/home/replacement/find", homeImgRecherche)}
					</div>
					<div className='col-6 col-sm-4'>
						{this.props.button("Mes contrats", "/home/replacements", homeImgMission)}
					</div>
					<div className='col-6 col-sm-4'>
						{this.props.button("Abonnement", "/home/subscription", homeImgSubscribe)}
					</div>
					{listOfClinicalStudy && listOfClinicalStudy.length > 0 &&
						<div className='col-6 col-sm-4 mt-2'>
							{this.props.button("Essais cliniques", "/home/clinicalStudy", homeImgClinicalStudy)}
						</div>
					}
					<div className='col-6 col-sm-4 mt-2'>
						{this.props.button("Compte", "/home/sprofile", homeImgAccount)}
					</div>

					<div className='col-6 col-sm-4 mt-2'>
						{this.props.button("Paramètres", "/home/ssettings", homeImgParam)}
					</div>
					<div className='col-6 col-sm-4 mt-2' onClick={() => this.props.onLogout(() => window.location.href = "/")}>
						{this.props.button("Déconnexion", "/home", homeImgDeco)}
					</div>
				</div>
			);
		}

	});

const PysIMenu = connect(null, logoutDispatch)(
	class PysIMenu extends React.Component {

		render() {
			const {listOfClinicalStudy} = this.props;

			return (

				<div className='row header-menu mt-3 mb-2 ml-1 mr-1 text-center'>
					<div className='col-6 col-sm-4'>
						{this.props.button("Compte", "/home/piprofile", homeImgAccount)}
					</div>
					<div className='col-6 col-sm-4'>
						{this.props.button("Paramètres", "/home/pisettings", homeImgParam)}
					</div>
					{listOfClinicalStudy && listOfClinicalStudy.length > 0 &&
						<div className='col-6 col-sm-4'>
							{this.props.button("Essais cliniques", "/home/clinicalStudy", homeImgClinicalStudy)}
						</div>
					}
					<div className='col-6 col-sm-4' onClick={() => this.props.onLogout(() => window.location.href = "/")}>
						{this.props.button("Déconnexion", "/home", homeImgDeco)}
					</div>
				</div>
			);
		}
	}
);

const RadiologistMenu = connect(null, logoutDispatch)(
	class RadiologistMenu extends React.Component {

		render() {
			const {listOfClinicalStudy} = this.props;

			return (

				<div className='row header-menu mt-3 mb-2 ml-1 mr-1 text-center'>
					<div className='col-6 col-sm-4'>
						{this.props.button("Compte", "/home/rprofile", homeImgAccount)}
					</div>
					<div className='col-6 col-sm-4'>
						{this.props.button("Paramètres", "/home/rsettings", homeImgParam)}
					</div>
					{listOfClinicalStudy && listOfClinicalStudy.length > 0 &&
						<div className='col-6 col-sm-4'>
							{this.props.button("Essais cliniques", "/home/clinicalStudy", homeImgClinicalStudy)}
						</div>
					}
					<div className='col-6 col-sm-4' onClick={() => this.props.onLogout(() => window.location.href = "/")}>
						{this.props.button("Déconnexion", "/home", homeImgDeco)}
					</div>
				</div>
			);
		}
	}
);

const PediatricianMenu = connect(null, logoutDispatch)(
	class PediatricianMenu extends React.Component {

		render() {
			const {listOfClinicalStudy} = this.props;

			return (

				<div className='row header-menu mt-3 mb-2 ml-1 mr-1 text-center'>
					<div className='col-6 col-sm-4'>
						{this.props.button("Compte", "/home/peprofile", homeImgAccount)}
					</div>
					<div className='col-6 col-sm-4'>
						{this.props.button("Paramètres", "/home/pesettings", homeImgParam)}
					</div>
					{listOfClinicalStudy && listOfClinicalStudy.length > 0 &&
						<div className='col-6 col-sm-4'>
							{this.props.button("Essais cliniques", "/home/clinicalStudy", homeImgClinicalStudy)}
						</div>
					}
					<div className='col-6 col-sm-4' onClick={() => this.props.onLogout(() => window.location.href = "/")}>
						{this.props.button("Déconnexion", "/home", homeImgDeco)}
					</div>
				</div>
			);
		}
	}
);

const PsychiatristMenu = connect(null, logoutDispatch)(
	class PsychiatristMenu extends React.Component {

		render() {
			const {listOfClinicalStudy} = this.props;

			return (

				<div className='row header-menu mt-3 mb-2 ml-1 mr-1 text-center'>
					<div className='col-6 col-sm-4'>
						{this.props.button("Compte", "/home/psprofile", homeImgAccount)}
					</div>
					<div className='col-6 col-sm-4'>
						{this.props.button("Paramètres", "/home/pssettings", homeImgParam)}
					</div>
					{listOfClinicalStudy && listOfClinicalStudy.length > 0 &&
						<div className='col-6 col-sm-4'>
							{this.props.button("Essais cliniques", "/home/clinicalStudy", homeImgClinicalStudy)}
						</div>
					}
					<div className='col-6 col-sm-4' onClick={() => this.props.onLogout(() => window.location.href = "/")}>
						{this.props.button("Déconnexion", "/home", homeImgDeco)}
					</div>
				</div>
			);
		}
	}
);

const SecretaryMenu = connect(null, logoutDispatch)(
	class SecretaryMenu extends React.Component {
		render() {

			return (
				<div className='row header-menu-width mt-3 mb-2 ml-1 mr-1 text-center'>
					<div className='col-6 col-sm-4 mt-2'>
						{this.props.button("Paramètres", "/home/secsettings", homeImgParam)}
					</div>
					
					<div className='col-6 col-sm-4 mt-2' onClick={() => this.props.onLogout(() => window.location.href = "/")}>
						{this.props.button("Déconnexion", "/home", homeImgDeco)}
					</div>
				</div>
			);
		}
	}
);