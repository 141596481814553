import React from "react";
import { Input } from "reactstrap";
import { injectIntl } from 'react-intl';

import { InputComposer, InputComponent } from '@gferrand/react-forms';
import LocalisationEnt from "../../entities/LocalisationEnt";

export default class SearchLocationInput extends InputComposer {

    constructor(name, label, validators = [], callback, options = {}) {

        super(name, label, validators, options);

        this.className = options.className;
        this.typeMap = options.typeMap;
        if (!this.typeMap) {
            this.typeMap = 'address';
        }
        this.callback = callback
    }

    mongoType() {
        return "String";
    }

    toJoi() {
        return "string()";
    }

    getComponent() {
        return <SearchLocationInputComponent callback={this.callback} typeMap={this.typeMap}/>;
    }

}

var SearchLocationInputComponent = injectIntl(class SearchLocationInputComponent extends InputComponent {
    constructor(props) {
        super(props);

        this.autoCompleteRef = React.createRef()

        this.autoComplete = null;

    }

    componentDidMount() {
        
        this.autoComplete = new window.google.maps.places.Autocomplete(
            this.autoCompleteRef.current,
            { types: [this.props.typeMap], componentRestrictions: {"country" : LocalisationEnt.getRegions()}, fields: ["name", "address_component", "place_id", "formatted_address"] }
        );
        this.autoComplete.setFields(["address_components", "formatted_address"]);
        this.autoComplete.addListener("place_changed", () =>
            this.handlePlaceSelect()
        );
    }

    async handlePlaceSelect() {
        const addressObject = this.autoComplete.getPlace();
        const { name } = addressObject;
        const { formatted_address } = addressObject;

        let city
        let postal_code
        if (addressObject && addressObject.address_components) {
            addressObject.address_components.forEach((elem) => {
                if(elem.types.includes("locality")) city = elem.long_name
                if(elem.types.includes("postal_code")) postal_code = elem.long_name
            })
            if (this.props.typeMap === 'address') {
                super.onChange(formatted_address);
            } else {
                super.onChange(city);
            }
            if(this.props.callback) this.props.callback(name, city, postal_code)
        }
    }

    onChange(value) {
        super.onChange(value);
    }

    render() {
        return super.wrap(
            <Input
                innerRef={this.autoCompleteRef}
                type="text"
                id={this.props.name}
                value={this.state.value}
                invalid={!!this.state.error}
                onChange={(e) => this.onChange(e.target.value)}
                onBlur={() => this.onBlur(this.props.name, this.state.value)}
                className={"form-control " + this.props.options.className}
                disabled={this.state.disabled || this.props.options.disabled}
                placeholder="Entrez une adresse" />
        );
    }

});