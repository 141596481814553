import React from 'react';
import { connect } from 'react-redux';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import DateUtil from '../../../util/DateUtil';
import { Row, Col } from "reactstrap";
import ReplacementEnt from '../../../entities/ReplacementEnt';
import ConfModal from '../../../components/modal/ConfModal';
import { upds } from "../../../actions/global/global";
import { declinePrivateRepl } from '../../../actions/replacements/replacements';
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";


class SReplacementTitle extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            modal: null
        };
    }

    cancelPrivateRepl(e, repl) {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.disabled) return;

        this.setState({ disabled: true });

        var data = {
            replacement_id: this.props.replacement._id,
            substitute_id: this.props.user._id,
            substitute_status: 2,
            doctor_status: 0,
            doctor_id: this.props.replacement.doctor_id
        };

        const onConfirm = (response) => {
            data.response_choice = response;

            this.props.onUpds("replacement/refuse", data, () => {
                this.closeModal();
                this.props.updateReplacementAfterAccept();

                new Noty({
                    type: "info",
                    layout: 'topRight',
                    theme: 'bootstrap-v4',
                    text: 'Vous venez de refuser ce remplacement',
                    timeout: 6000,
                }).show();
            });
        }

        const onCancel = () => {
            this.setState({ disabled: false });
            this.closeModal();
        };

        const content = <FormattedHTMLMessage id="Refuse.Invitation" />

        this.setState({
            modal: <ConfModal
                fromDoctor={false}
                content={content}
                onConfirm={onConfirm}
                onCancel={() => onCancel()}
                toggle={() => onCancel()} />
        });
    }

    closeModal = () => {
        this.setState({ modal: null });
    }

    render() {
        const { replacement } = this.props;

        if (!replacement) return null;

        const daysToRepl = DateUtil.daysTo(replacement.start_date);
        let isPrivate = ReplacementEnt.isPrivate(replacement);
        if (replacement.invitation_by_doctor === true) isPrivate = true;

        const isSeen = ReplacementEnt.isSeen(replacement, this.props.user._id);
        
        let endDate;
        if(replacement.type === "guard"){
            endDate = DateUtil.toDateWithHour(replacement.end_date);
        } else if(replacement.type === "regular") {
            // Dans le cas d'un enregistrement avec un format de donné qui correspond à l'ancien fonctionnement
            if (!replacement.end_date) {
                endDate = DateUtil.toDate(ReplacementEnt.getRegularReplEnd(replacement.day, replacement.duration, replacement.start_date));
            } else {
                endDate = DateUtil.toDate(replacement.end_date);
            }
        } else {
            endDate = DateUtil.toDate(replacement.end_date);
        }
        let viewThinking = false;
        let retrocession = replacement.retrocession || replacement.guard_retrocession;
        if (replacement.applicants && replacement.applicants.length > 0) {
            replacement.applicants.forEach((applicant) => {
                if (applicant.substitute_id === this.props.user._id && applicant.doctor_status === 4) {
                    viewThinking = true;
                }
                if (applicant.substitute_id === this.props.user._id) {
                    if (applicant.negociation && applicant.negociation.length > 0) {
                        let lastElement = applicant.negociation[applicant.negociation.length - 1];
                        retrocession = lastElement.retrocession;
                    }
                }
            })
        }
        return (
            <React.Fragment>

                <Row className="min-height-70">
                    <Col sm="5">
                        {(isPrivate) ?
                            !isSeen && <span className="text-danger"><b><FormattedMessage id="New.Invitation" /></b></span>
                            :
                            <><i className="fa icon-ICON__ENVOI_GRIS"></i>&nbsp;&nbsp;<FormattedMessage id="Proposed.Retrocession" />&nbsp;:&nbsp;
                                <b>{retrocession}&nbsp;%</b></>
                        }

                    </Col>

                    <Col sm="5" className="pr-3">
                        {(replacement.guards.length > 0) && <div className="red-light-addon ml-0 pl-0"></div>}
                        {(replacement.type === "occasional") && <div className="green-light ml-0 pl-0"></div>}
                        {(replacement.type === "regular") && <div className="blue-light ml-0 pl-0"></div>}
                        {(replacement.type === "guard") && <div className="red-light ml-0 pl-0"></div>}
                                
                        <>
                        {(replacement.type === "regular") &&
                            <><FormattedMessage id="The" />{' '}<FormattedMessage id={"Days." + DateUtil.dayToNbr(replacement.day[0])} /></>
                        }

                        {(replacement.type === "regular") ?
                            <>{' '}<FormattedMessage id="from" />{' '}</>
                            :
                            <><FormattedMessage id="From" />{' '}</>
                        }
                        <b>{(replacement.type === "guard") ? DateUtil.toDateWithHour(replacement.start_date) : DateUtil.toDate(replacement.start_date)}</b>
                        {' '}<FormattedMessage id="To" />{' '}
                        <b>{endDate}</b>
                        </>

                    </Col>

                    <Col sm="2" className="text-center pr-3">

                        {viewThinking === true && 
                            <div className="text-danger">
                                <FormattedMessage id="Thinking.Doc" />
                            </div>
                        }
                        {viewThinking === false && 
                            <span>
                                {(new Date() > new Date(replacement.end_date)) ?
                                    <b className="text-muted">Exp.</b>
                                    :
                                    <div className={"d-inline-block pl-3 pr-3 " + ((daysToRepl >= 0 && daysToRepl < 15) ? "text-danger" : "")}>
                                        <i className="fa icon-ICON__ALERTE_GRIS_C"></i>&nbsp;
                                        <b>{Math.max(daysToRepl, 0)}j</b>
                                    </div>
                                }
                            </span>
                        }
                        {(isPrivate) &&
                            <div className={"d-inline-block agreement-blue clickable agreement-btn-danger"
                                + ((this.state.disabled) ? "-disabled" : "")}
                                style={{ fontSize: "12px" }}
                                onClick={(e) => {
                                    if (!this.state.disabled) {
                                        this.cancelPrivateRepl(e, replacement);
                                    }
                                }}>
                                <i className={"fa fa icon-ICON__CROIX_GRIS"}></i>
                            </div>
                        }
                    </Col>

                </Row>

                {this.state.modal}

            </React.Fragment >
        );
    };
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpds: (objName, obj, cbk) => dispatch(upds(objName, obj, cbk)),
        onDeclinePrivateRepl: (replId, cbk) => dispatch(declinePrivateRepl(replId, cbk))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SReplacementTitle));