import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import StepZilla from 'react-stepzilla';
import Step1 from '../form/Step1';
import RStep2 from './RStep2';
import RStep3 from './RStep3';
import RStep4 from './RStep4';
import Finish from '../Finish';
import GDPRModal from "../../../components/gdpr/GDPRModal";
import { getBy, add } from "../../../actions/global/global";
import { connect } from "react-redux";
import moment from 'moment';
import UserUtil from '../../../util/UserUtil';

class RSteps extends Component {

	constructor(props) {
		super(props);

		this.state = {};
		this.store = {};

		//if (process.env.REACT_APP_IS_PRODUCTION === "false") this.initStore();
	};

	componentDidMount() {
		if (this.props.match.params.id) {
			this.props.onGetBy('user/admin', "id", this.props.match.params.id, (res) => this.updateStoreFromUser(res));
		}
	}

	updateStoreFromUser = (res) => {
		if (res) {
			let user = Object.assign({}, res)
			var radiologistCharacteristics = {};
			var officeTimeTable = Object.assign({}, res.officeTimeTable);

			if (user.birth_date) {
				user.birth_date = moment(user.birth_date).format("MM/DD/YYYY");
			}

			if (user.characteristics) {
				radiologistCharacteristics = user.characteristics.validated_radiologistCharacteristics.reduce((previousValue, item) => ({
					...previousValue,
					[item]: user.characteristics[item]
				}), {});

				UserUtil.setCharacs(radiologistCharacteristics, user.characteristics);

				delete user.characteristics;
			}
			

			this.updateStore({
				user: user,
				radiologistCharacteristic: radiologistCharacteristics,
				officeTimeTable: officeTimeTable,
				_id: res._id
			});
			this.forceUpdate();
		}
	}

	initStore = () => {
		this.store = {
			user: {
				birth_date: "08/08/1990",
				role: "radiologist_install",
				email: "rad_installer@dot.net",
				first_name: "Michel",
				gender: "man",
				name: "Dubois",
				password: "12345678",
				confirmation_mot_de_passe: "12345678",
				phone_mobile: "0601020304",
				phone_pro: "0601020304"
			},
			radiologistCharacteristic: {
				"office_type": "2",
				"secretariat": "2",
				"interpretation": "2",
				"teleconsultation": "8",
				"sector": "2",
				"office_disposal": "1",
				"interpretations_per_week_on_avg": 112,
				"RPPS": "12345678911",
				"pediatrics": 25,
				"geriatrics": 10,
				"radiology": true,
				"scanner": true,
				"echography": false,
				"irm": true,
				"scintigraphy": false,
				"tep": false,
				"bone_densitometry": false
			},
			officeTimeTable: {
				fridayAfternoonEnd: "18:00",
				fridayAfternoonStart: "14:00",
				fridayMorningEnd: "12:00",
				fridayMorningStart: "08:00",
				mondayAfternoonEnd: "18:00",
				mondayAfternoonStart: "14:00",
				mondayMorningEnd: "12:00",
				mondayMorningStart: "08:00",
				saturdayMorning: true,
				thursdayAfternoonEnd: "18:00",
				thursdayAfternoonStart: "14:00",
				thursdayMorningEnd: "12:00",
				thursdayMorningStart: "08:00",
				tuesdayAfternoonEnd: "18:00",
				tuesdayAfternoonStart: "14:00",
				tuesdayMorningEnd: "12:00",
				tuesdayMorningStart: "08:00",
				wednesdayMorning: true,
				wednesdayMorningEnd: "12:00",
				wednesdayMorningStart: "08:00",
			}
		};
	};

	getStore() {
		return this.store;
	};

	updateStore(update) {
		this.store = {
			...this.store,
			...update,
		};
	};

	render() {

		var namePIStep1 = <FormattedMessage id="My.Info"/>;
		var namePIStep2 = <FormattedMessage id="My.Profile"/>;
		var namePIStep3 = <FormattedMessage id="My.Schedule"/>;
		var namePIStep4 = <FormattedMessage id="Conditions"/>;
		var namePIStep5 = <FormattedMessage id="Confirmation.2"/>;

		var validate = true;

		const steps = [
			{ name: namePIStep1, component: <Step1 userRole={"radiologist_install"} validate={validate} getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
			{ name: namePIStep2, component: <RStep2 validate={validate} getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
			{ name: namePIStep3, component: <RStep3 validate={validate} getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
			{ name: namePIStep4, component: <RStep4 onAdd={this.props.onAdd} validate={validate} getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
			{ name: namePIStep5, component: <Finish userRole={"radiologist_install"} getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
		];
		
		return (
			<div id="registration" className='example blue-bg'>

				<div className='step-progress'>

					<div className="topbar navbarbg text-center b-fix">
						<a href="/#" className="a-unstyled">
							<div className="hydrogen-logo-sm d-inline-block align-middle"></div>
							<div className="ml-3 bold d-inline-block align-middle fs-18 lp-3">HYDROGEN</div>
						</a>
					</div>

					<h4 className="text-white uppercase w-100 text-center lp-3 mt-4 mb-3"><FormattedMessage id="I.Register" /></h4>

					<StepZilla
						steps={steps}
						stepsNavigation={true}
						preventEnterSubmission={true}
						showNavigation={false}
					/>

					<GDPRModal />

				</div>

			</div>
		);
	};
};

function mapStateToProps(state) {
	return {
		user: state.global.user
	}
};

function mapDispatchToProps(dispatch) {
	return {
		onGetBy: (objName, fields, values, callback) => dispatch(getBy(objName, fields, values, callback)),
		onAdd: (objName, obj, cbk) => dispatch(add(objName, obj, cbk))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RSteps));