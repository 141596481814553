import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Util from '../../util/Util';
import UserUtil from '../../util/UserUtil';
import { get } from "../../actions/global/global";

class GDPRModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            disabled: false
        };
    }

    acceptCookies() {
        const { user } = this.props;

        // Update the backend
        if (user && !Util.emptyObject(user) && !UserUtil.isRoleTemporary(user.role)) {
            var clonedUser = Util.clone(user);
            clonedUser.accepted_gdpr = true;

            this.props.onGet("user/gdpr");
        }

        localStorage.setItem('accepted_gdpr', "true");

        this.forceUpdate();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { user } = nextProps;

        var accepted_gdpr = localStorage.getItem('accepted_gdpr');

        if (accepted_gdpr === "true" && user && !user.accepted_gdpr && !UserUtil.isRoleTemporary(user.role)) {
            nextProps.onGet("user/gdpr");
        }

        return prevState;
    }

    render() {
        const { user } = this.props;
        var accepted_gdpr = localStorage.getItem('accepted_gdpr');

        if (user && user.accepted_gdpr) return null;
        if (accepted_gdpr === "true") return null;

        return (
            <div className="fixed-bottom row p-3 gdpr-modal">
                <div className="mx-auto">
                    <div className="d-inline-block mr-0 mr-md-4">
                        <FormattedMessage id="GDPR.Cookies" />
                    </div>

                    <div className="d-inline-block mt-3 mt-md-0">
                        <button className="btn btn-primary" disabled={this.state.disabled} onClick={() => this.acceptCookies()}>
                            <FormattedMessage id="Accept" />
                        </button>

                        <Link to="/terms" className="btn btn-outline-primary" target="_blank" disabled={this.state.disabled}>
                            <FormattedMessage id="Learn.More" />
                        </Link>

                        <Link to="/aboutus" className="btn btn-outline-primary" target="_blank" disabled={this.state.disabled}>
                            <FormattedMessage id="Who.Are.We" />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGet: (objName, obj, cbk) => dispatch(get(objName, cbk))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GDPRModal);
