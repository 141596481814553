import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { adds, getsBy, removes, clears } from '../../actions/global/global';
import { downloadCertificate } from '../../actions/replacements/replacements';
import ConfModal from '../../components/modal/ConfModal';
import AccountingDailyModal from './tables/daily/AccountingDailyModal';
import AccountingDailyModalExpense from './tables/daily/AccountingDailyModalExpense';
import AccountingTableWrapper from './AccountingTableWrapper';
import DateUtil from '../../util/DateUtil';
import AccountingDailyModalEntry from './tables/daily/AccountingDailyModalEntry';
import { Link } from "react-router-dom"

class AccountingDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            viewChoosen: 'day',
            subcategory: 'consultation',

            loading: true,

            modal: null,
            expensesModal: null,
            recettesModal: null,
            confModal: null,

            startDate: null,
            endDate: null,

            skip: 0,
            limit: 10,

            missionsPaginationSkip: 0,
            missionsPaginationLimit: 2
        }
    }

    componentDidMount() {
        this.setProperDates();
    }

    fetchData() {
        const { viewChoosen, skip, limit, startDate, endDate, subcategory } = this.state;

        this.setState({ loading: true });

        if (viewChoosen === "day") {

            return this.props.onGetsBy("accounting", ["skip", "limit", "startDate", 'endDate', 'category'], [skip, limit, startDate, endDate, subcategory],
                () => this.fetchAccountingTotalData()
            );

        } else if (viewChoosen === 'mission') {

            return this.props.onGetsBy(`agreement/accountings/substitute`, ["skip", "limit"], [0, 10],
                () => {
                    if (this.props.agreements.length > 0) {
                        this.setState({
                            endDate: new Date(this.props.agreements[0].replacement.end_date),
                            startDate: new Date(this.props.agreements[0].replacement.start_date)
                        }, () => this.fetchAccountingTotalData())
                    } else {
                        this.setState({
                            endDate: new Date(),
                            startDate: new Date()
                        }, () => this.fetchAccountingTotalData())
                    }
                }
            )

        } else return this.fetchAccountingTotalData();

    }

    fetchAccountingTotalData() {

        return this.props.onGetsBy("accountingTotal", ["startDate", "endDate"], [this.state.startDate, this.state.endDate],
            () => {
                if (this.state.viewChoosen !== "day") return this.props.onGetsBy(`accountingTotalsDay`, ["startDate", "endDate", "skip", 'limit'], [this.state.startDate, this.state.endDate, this.state.skip, this.state.limit],
                    () => this.props.onGetsBy("accountingTotal", ["startDate", "endDate"], [this.state.startDate, this.state.endDate],
                        () => this.setState({ loading: false })))
                else this.setState({ loading: false })
            });

    }

    switchViewAndFetchData = (viewChoosen) => {
        this.setState({ loading: true },
            () => this.setState({ viewChoosen },
                () => this.setProperDates()));
    }

    switchSubcategoryAndFetchData = (subcategory) => {
        this.setState({ loading: true },
            () => this.setState({ subcategory },
                () => this.fetchData()));
    }

    getMissionCertificate = (retrocession_total) => {
        const { replacement_id, doctor_id, substitute_id } = this.props.agreements[0];

        this.setState({ loading: true },
            () => this.props.onDownloadCertificate(
                replacement_id, doctor_id, substitute_id, retrocession_total,
                () => this.setState({ loading: false })
            ));
    }

    openModal = (patient) => {
        this.setState({
            modal: <AccountingDailyModal
                closeModal={this.closeModal}
                startDate={this.state.startDate}
                patient={patient}
                onSuccessCbk={() => this.fetchData()}
                user={this.props.user}
            />
        });
    }

    closeModal = () => {
        this.setState({ modal: null });
    }

    openExpensesModal = (accounting) => {
        this.setState({
            expensesModal: <AccountingDailyModalExpense
                closeExpensesModal={this.closeExpensesModal}
                onSuccessCbk={() => this.fetchData()}
                startDate={this.state.startDate}
                user={this.props.user}
                accounting={accounting}
            />
        });
    }

    closeExpensesModal = () => {
        this.setState({ expensesModal: null });
    }

    openconsultationsModal = (accounting) => {
        this.setState({
            recettesModal: <AccountingDailyModalEntry
                closeconsultationsModal={this.closeconsultationsModal}
                onSuccessCbk={() => this.fetchData()}
                startDate={this.state.startDate}
                user={this.props.user}
                accounting={accounting}
            />
        });
    }

    closeconsultationsModal = () => {
        this.setState({ recettesModal: null });
    }

    openConfModal(content, onConfirm, onCancel) {
        this.setState({
            confModal: <ConfModal
                content={content}
                onConfirm={onConfirm}
                onCancel={onCancel}
                toggle={() => onCancel()} />
        });
    }

    closeConfModalAndFetchTotalData = () => {
        this.fetchAccountingTotalData();
        this.setState({ confModal: null });
    };

    deleteAccoutingLine = (e, accounting) => {
        e.stopPropagation();

        this.openConfModal(<FormattedMessage id="Delete.Warning" />, () => {
            this.props.onRemoves("My.Accounting", accounting, () => this.closeConfModalAndFetchTotalData());
        }, () => this.closeConfModalAndFetchTotalData());
    }


    dayChoosen = (yesterday, tomorrow) => {
        if (!yesterday && !tomorrow) return DateUtil.toDate(new Date());

        if (yesterday) return this.setState({
            startDate: new Date(DateUtil.theDayBefore(this.state.startDate).setHours(0, 0, 0, 0)),
            endDate: new Date(DateUtil.theDayBefore(this.state.endDate).setHours(23, 59, 59, 999))
        }, () => this.fetchData());

        if (tomorrow) return this.setState({
            startDate: new Date(DateUtil.theDayAfter(this.state.startDate).setHours(0, 0, 0, 0)),
            endDate: new Date(DateUtil.theDayAfter(this.state.endDate).setHours(23, 59, 59, 999))
        }, () => this.fetchData());
    }

    monthChoosen = (previousMonth, nextMonth) => {
        if (!previousMonth && !nextMonth) return DateUtil.toDate(new Date());

        if (previousMonth) return this.setState({
            startDate: DateUtil.firstDayPreviousMonth(this.state.startDate),
            endDate: new Date(DateUtil.lastDayOfPreviousMonth(this.state.endDate).setHours(23, 59, 59, 999))
        }, () => this.fetchData());

        if (nextMonth) return this.setState({
            startDate: DateUtil.firstDayNextMonth(this.state.startDate),
            endDate: new Date(DateUtil.lastDayOfNextMonth(this.state.endDate).setHours(23, 59, 59, 999))
        }, () => this.fetchData());

    }

    getNextMission = () => {

        this.setState({ loading: true });

        this.setState((prevState) => {

            const numberOfAgreements = this.props.agreementsCount;

            if (prevState.skip + 1 === numberOfAgreements) return { skip: prevState.skip, limit: prevState.limit };

            else return {
                skip: prevState.skip + 1,
                limit: prevState.limit + 1
            };
        }, () => this.props.onGetsBy(`agreement/accountings/substitute`, ["skip", "limit"], [this.state.skip, this.state.limit],
            () => this.setState({
                endDate: new Date(this.props.agreements[0].replacement.end_date),
                startDate: new Date(this.props.agreements[0].replacement.start_date)
            }, () => this.fetchAccountingTotalData())
        ));
    }

    getPreviousMission = () => {

        this.setState({ loading: true });

        this.setState((prevState) => {

            if (prevState.skip - 1 < 0) return { skip: prevState.skip, limit: prevState.limit };

            else return {
                skip: prevState.skip - 1,
                limit: prevState.limit - 1
            };
        }, () => this.props.onGetsBy(`agreement/accountings/substitute`, ["skip", "limit"], [this.state.skip, this.state.limit],
            () => this.setState({
                endDate: new Date(this.props.agreements[0].replacement.end_date),
                startDate: new Date(this.props.agreements[0].replacement.start_date)
            }, () => this.fetchAccountingTotalData())
        ));
    }

    setProperDates = () => {
        if (this.state.viewChoosen === 'day') {

            return this.setState({
                startDate: new Date(new Date().setHours(0, 0, 0, 0)),
                endDate: new Date(new Date().setHours(23, 59, 59, 999)),
                skip: 0,
                limit: 10
            }, () => this.fetchData());

        }

        if (this.state.viewChoosen === 'month') {
            let startDate = new Date();

            startDate.setHours(0, 0, 0, 0)
            startDate.setDate(1);

            let endDate = new Date(DateUtil.lastDayOfCurrentMonth(startDate).setHours(23, 59, 59, 999));

            return this.setState({
                startDate,
                endDate,
                skip: 0,
                limit: 31
            }, () => this.fetchData());
        }

        if (this.state.viewChoosen === "mission") {

            return this.setState({
                startDate: new Date(new Date().setHours(0, 0, 0, 0)),
                skip: 0,
                limit: 10
            }, () => this.fetchData());
        }
    }

    switchToDayFromMonth = (dailyDate) => {
        this.setState({
            startDate: new Date(new Date(dailyDate).setHours(0, 0, 0, 0)),
            endDate: new Date(new Date(dailyDate).setHours(23, 59, 59, 999)),
            skip: 0,
            limit: 10
        }, () => this.setState({
            viewChoosen: 'day'
        }, () => this.fetchData())
        );
    }

    dailyPaginationChange = (page) => {
        this.setState({ skip: (page - 1) * this.state.limit }, this.fetchData)
    }

    missionPaginationChange = (page) => {
        this.setState({ missionsPaginationSkip: (page - 1) * this.state.missionsPaginationLimit })
    }

    getCorrectProps = () => {
        if (this.state.viewChoosen === "day") return this.props.accountings

        else if (this.state.viewChoosen === "mission") {
            if (!this.props.agreements || !this.props.accountingTotalsDays) return null;

            if (this.props.agreements.length > 0) {
                this.props.agreements[0].accountingsPaginate = [
                    ...this.props.accountingTotalsDays.slice(
                        this.state.missionsPaginationSkip,
                        this.state.missionsPaginationSkip + 2
                    )
                ]
                return this.props.agreements[0]
            }
        }
    }

    render() {
        const { accountingsCount, accountingTotalsDays, agreements, user } = this.props;

        return (
            <>

            <div className="w-100 text-white text-center uppercase mb-4">
                <h4><FormattedMessage id="My.Accounting" /></h4>
            </div>

            {(user) &&
                <div className="ml-auto text-right">

                    <div className={`d-inline-block text-white text-center mr-3 mb-2 clickable ${this.state.viewChoosen === 'month' && 'choosenMoment'}`}
                        onClick={() => this.switchViewAndFetchData('month')}>
                        <FormattedMessage id="Month" />
                    </div>
                    <div className={`d-inline-block text-white text-center ml-3 mr-3 mb-2 clickable ${this.state.viewChoosen === 'day' && 'choosenMoment'}`}
                        onClick={() => this.switchViewAndFetchData('day')}>
                        <FormattedMessage id="Day" />
                    </div>
                    {(user.role !== "doctor") &&
                        <div className={`d-inline-block text-white text-center ml-3 mb-2 clickable ${this.state.viewChoosen === 'mission' && 'choosenMoment'}`}
                            onClick={() => this.switchViewAndFetchData('mission')}>
                            <FormattedMessage id="Replacement" />
                        </div>
                    }

                            <div className={`d-inline-block text-white text-center mr-3 mb-2 clickable`}>
                                <Link to={"/home/accounting"} className="clickable bold" style={{ color: '#FFF' }}>
                                    {"< "}&nbsp;<FormattedMessage id=".Back" />
                                </Link>
                            </div>
                </div>
            }

            <AccountingTableWrapper
                accountings={this.getCorrectProps()}
                accountingTotals={this.props.accountingTotals}
                accountingTotalsDays={accountingTotalsDays}
                accountingsCount={accountingsCount}
                agreements={agreements}
                skip={this.state.skip}
                limit={this.state.limit}
                viewChoosen={this.state.viewChoosen}
                subcategory={this.state.subcategory}
                switchSubcategory={this.switchSubcategoryAndFetchData}
                openModal={this.openModal}
                openExpensesModal={this.openExpensesModal}
                openconsultationsModal={this.openconsultationsModal}
                deleteAccoutingLine={this.deleteAccoutingLine}
                dayChoosen={this.dayChoosen}
                monthChoosen={this.monthChoosen}
                startDate={this.state.startDate}
                missionsPaginationLimit={this.state.missionsPaginationLimit}
                missionsPaginationSkip={this.state.missionsPaginationSkip}
                loading={this.state.loading}
                switchToDayFromMonth={this.switchToDayFromMonth}
                getNextMission={this.getNextMission}
                getPreviousMission={this.getPreviousMission}
                getMissionCertificate={this.getMissionCertificate}
                user={this.props.user}
                dailyPaginationChange={this.dailyPaginationChange}
                missionPaginationChange={this.missionPaginationChange}
            />

            {this.state.modal}

            {this.state.expensesModal}

            {this.state.recettesModal}

            {this.state.confModal}

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountings: state.global.accountings,
        accountingsCount: state.global.accountingsCount,
        accountingTotals: state.global.accountingTotals,
        accountingTotalsDays: state.global.accountingTotalsDays,
        accountingTotalsDaysCount: state.global.accountingTotalsDaysCount,
        agreements: state.global.agreements,
        agreementsCount: state.global.agreementsCount,
        user: state.global.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),
        onAdds: (objName, obj, cbk) => dispatch(adds(objName, obj, cbk)),
        onRemoves: (objName, obj, cbk) => dispatch(removes(objName, obj, cbk)),
        onDownloadCertificate: (replacementId, doctorId, substituteId, total, cbk) => downloadCertificate(replacementId, doctorId, substituteId, total, cbk)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountingDashboard);