import React from 'react';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage } from 'react-intl';
import DateUtil from '../../../util/DateUtil';
import { Row, Col } from "reactstrap";
import Util from '../../../util/Util';

export default class AvailabilityTitle extends React.Component {

    getBusinessDatesCount(startDate, endDate) {
        var count = 0;

        var curDate = startDate;
        while (curDate <= endDate) {
            var dayOfWeek = curDate.getDay();
            if (!((dayOfWeek === 6) || (dayOfWeek === 0)))
                count++;
            curDate.setDate(curDate.getDate() + 1);
        }
        return count;
    }


    render() {
        const { availability } = this.props;

        if (!availability) return null;

        return (
            <React.Fragment>

                <Row className="min-height-70">

                    <Col sm="4" className="">
                        <FormattedMessage id="Retrocession.Wished" />&nbsp;:&nbsp;
                        <b>{availability.retrocession_wished}&nbsp;%</b>
                    </Col>

                    <Col sm="7" className="text-md-right pr-3">
                        {(availability.type === "occasional") && <div className="green-light ml-0 pl-0"></div>}
                        {(availability.type === "regular") && <div className="blue-light ml-0 pl-0"></div>}
                        {(availability.type === "guard") && <div className="red-light ml-0 pl-0"></div>}
                        {(availability.type === "regular") ? (
                            <>
                                <FormattedMessage id="Available" />{' '}
                                {availability.day.map((newDay) => 
                                    <>
                                        <FormattedMessage id="the.sing" />&nbsp;
                                        <b><FormattedMessage id={Util.fstLetUpCase(newDay)} /></b>&nbsp;
                                    </>
                                )}
                                <FormattedMessage id="from" />
                            </>
                        ) : (
                            <FormattedMessage id="Available.From" />
                        )}
                        {' '}
                        <b>{DateUtil.toDate(availability.start_date)}</b>
                        {' '}<FormattedMessage id="To" />{' '}
                        <b>{DateUtil.toDate(availability.end_date)}</b>

                    </Col>

                    <Col sm="1" className="">
                        {(availability.type === "occasional") &&    
                        <>
                            {this.getBusinessDatesCount(new Date(availability.start_date), new Date(availability.end_date))} 
                            {' '}
                            <FormattedMessage id="The.Days"/>
                         </>
                        }
                    </Col>
                </Row>

            </React.Fragment >
        );
    };

}