import React, { Component } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Form, MinLength, MaxLength, NotEmpty, SelectInput, RangeInput, NumericInput, Title, Addable, TextInput, MultiSelectInput, Min, Max } from '@gferrand/react-forms';
import moment from 'moment';
import Geocode from "react-geocode";

import ImgedSwitchInput from '../../../../components/form/ImgedSwitchInput';
import SubstituteStudiesLevel from '../../../../enum/SubstituteStudiesLevel';
import MedicalSoftwares from '../../../../enum/MedicalSoftwares';
import Teleconsultations from '../../../../enum/Teleconsultations';
import SubstituteSettlementPreference from '../../../../enum/SubstituteSettlementPreference';
import SubstituteAcceptHomeVisits from '../../../../enum/SubstituteAcceptHomeVisits';
import Util from "../../../../util/Util";
import GoogleMapUtil from "../../../../util/GoogleMapUtil"
import GMapWithoutScript from "../../../../components/map/GMapWithoutScript";
import SearchLocationInput from "../../../../components/form/SearchLocationInput";

import ecgImg from '../../../../assets/images/registration/HYDROGEN_ICONS_MED_ECG.png';
import gynecologyImg from '../../../../assets/images/registration/HYDROGEN_ICONS_MED_GYNECO_02.png';
import osteopathyImg from '../../../../assets/images/registration/HYDROGEN_ICONS_MED_OSTEO.png';
import homeopathyImg from '../../../../assets/images/registration/HYDROGEN_ICONS_MED_HOMEO_01.png';
import sportsImg from '../../../../assets/images/registration/HYDROGEN_ICONS_MED_HOMEO_SPORT.png';
import allergologyImg from '../../../../assets/images/registration/HYDROGEN_ICONS_MED_ALLERGO.png';
import emergencyImg from '../../../../assets/images/registration/HYDROGEN_ICONS_MED_URGENCE.png';

import RadiusConfort from '../../../../enum/RadiusConfort';
import Retrocession from '../../../../enum/Retrocession';
import RadiusEffort from '../../../../enum/RadiusEffort';
import Duration from '../../../../enum/Duration';


export default class SProfileForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            disabled: false,

            lat: null,
            lng: null,

            region: "",
            department: "",
            street: "",
            city: "",
            country: "",
            postal_code: "",

            loadingGMaps: false,
            previousVal: ""
        };
        
        this.GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

        // Geocode (for geocoding, no kidding)
        Geocode.setApiKey(this.GOOGLE_MAP_API_KEY);

        // GMap component
        this.loadMapCbk = null;
        this.fixMapCbk = null;
        
        this.updateCityAndPostalCode = this.updateCityAndPostalCode.bind(this)
    }

    componentDidMount() {
        GoogleMapUtil.loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${this.GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
            () => {
                const defaultValues = this.props.defaultValues
                this.buildMap(defaultValues)
                this.buildForm()
                if (!defaultValues || !defaultValues.studies_level || defaultValues.studies_level === "2") {
                    this.form.hide("license");
                };

                if(defaultValues && defaultValues.radius_preference && defaultValues.radius_preference !== "0") {
                    
                    var datas = RadiusEffort.values().filter(element =>{
                        return (parseInt(element.value) === 0 || (parseInt(element.value) >= parseInt(defaultValues.radius_preference) + 1 ))
                    });
                    this.form0.inputs[3].choices = datas;
                } 
                if(!defaultValues || !defaultValues.radius_effort || defaultValues.radius_effort === "0") {
                    this.form.hide("retrocession_wished_effort");
                } 
        
                if (!defaultValues || !defaultValues.medical_software || !defaultValues.medical_software.includes("0")) this.form.hide("other_software");

                // Utile pour l'inscription 
                this.forceUpdate();
            }
        );
    }

    buildMap(defaultValues) {
        this.map = <GMapWithoutScript 
            height="600"
            addfixMapCbk={(cbk) => this.fixMapCbk = cbk} 
            addLoadMapCbk={(cbk) => this.loadMapCbk = cbk} />;
        if (defaultValues && (defaultValues.address_street || defaultValues.address_city || defaultValues.address_postcode || defaultValues.radius_preference || defaultValues.radius_effort)) {
            this.updateMap(
                defaultValues.address_street, 
                defaultValues.address_city,
                defaultValues.address_postcode,
                defaultValues.radius_preference, 
                defaultValues.radius_effort
            );
        }
    }

    buildForm = () => {
        const { submitCbk, defaultValues, validate } = this.props;

        if (defaultValues && !defaultValues.teleconsultation) {
            defaultValues.teleconsultation = "0";
        }
        if(defaultValues && !defaultValues.year_end_internship) {
            defaultValues.year_end_internship = "2021";
        }

        if (defaultValues && defaultValues.address_street) {
            defaultValues.address_street = defaultValues.address_street + ', ' + defaultValues.address_postcode + ' ' + defaultValues.address_city + ( defaultValues.address_components ? ', ' + defaultValues.address_components.country : '' );
        }

        const _submitCbk = (data, cbk) => {
            var formData = this.form0.getRawData();
            if (this.state.lng === null || this.state.lat === null) {
                this.form0.setError('address_street', 'Votre adresse n\'est pas reconnu');
                cbk();
                return;
            }

            if (formData.address_street.length > 100) {
                this.form0.setError('address_street', '200 caractères maximum');
                return;
            }

            if(NotEmpty.valid(formData.address_street) && NotEmpty.valid(formData.radius_preference) && NotEmpty.valid(formData.retrocession_wished) && NotEmpty.valid(formData.guard_retrocession_wished)) {
                
                const fullAddress = formData.address_street;
                Geocode.fromAddress(fullAddress).then((response) => {
                    if (!response) {
                        this.form0.setError('address_street', 'Veuillez controler votre adresse');
                    }
                    data.location = { type: "Point", coordinates: [response.results[0].geometry.location.lng, response.results[0].geometry.location.lat] };
                    
                    data.address_components = {};
                    let streetNumber = '';
                    let streetName = '';
                    if (response.results[0] && response.results[0].address_components) {
                        for (let comp of response.results[0].address_components) {
                            if (comp.types.includes("street_number")) {
                                data.address_components.street_number = comp.long_name;
                                streetNumber = comp.long_name;
                            } 
                            if (comp.types.includes("route")){
                                data.address_components.route = comp.long_name;
                                streetName = comp.long_name;
                            } 
                            if (comp.types.includes("administrative_area_level_1")) data.address_components.region = comp.long_name;
                            if (comp.types.includes("administrative_area_level_2")) data.address_components.department = comp.long_name;
                            if (comp.types.includes("locality")) {
                                data.address_components.city = comp.long_name;
                                formData.address_city = comp.long_name;
                            } 
                            if (comp.types.includes("country")) data.address_components.country = comp.long_name;
                            if (comp.types.includes("postal_code")) {
                                data.address_components.postal_code = comp.long_name;
                                formData.address_postcode = comp.long_name;
                            } 
                        }

                        formData.address_street = streetNumber + ' ' + streetName;

                        if (formData.address_street.length < 2) {
                            this.form0.setError('address_street', 'Veuillez controler votre adresse');
                            cbk();
                            return;
                        }
    
                        if (!formData.address_postcode || formData.address_postcode.length < 2) {
                            this.form0.setError('address_street', 'Veuillez controler votre adresse');
                            cbk();
                            return;
                        }

                        // Little hack with Addable forms
                        var char = Object.assign({ descs: [], du_dius: [] }, formData, data);

                        return submitCbk(char, cbk);
                    } else {
                        this.form0.setError('address_street', 'Veuillez controler votre adresse');
                    }
                })
                .catch(() => {
                    this.form0.setError('address_street', 'Veuillez controler votre adresse');
                })
            } 
            if(!NotEmpty.valid(formData.address_street)) this.form0.setError('address_street', NotEmpty.error());
            if(!NotEmpty.valid(formData.radius_preference)) this.form0.setError('radius_preference', NotEmpty.error());
            if(!NotEmpty.valid(formData.retrocession_wished)) this.form0.setError('retrocession_wished', NotEmpty.error());
            if(!NotEmpty.valid(formData.guard_retrocession_wished)) this.form0.setError('guard_retrocession_wished', NotEmpty.error());
            cbk()
        };

        const _changeCbk = (input) => {
            const { name, value } = input;

            if (name === "studies_level") {
                if (value === "2") {
                    this.form.hide("license");
                } else {
                    this.form.show("license");
                }
            }
            // Cacher la retrocession souhaitée quand le périmètre d'effort est égale à aucun
            var fData = this.form0.getRawData(); 
            
            if(input.name === "radius_effort") {
                if(input.value === "0") {
                    this.form0.hide("retrocession_wished_effort");
                } else {   
                    this.form0.show("retrocession_wished_effort");
                }
                this.updateMap(fData.address_street, fData.address_city, fData.address_postcode, fData.radius_preference, input.value);
                this.forceUpdate();
            }

            // Vérification si périmètre de confort est différent de 100 Km 
            //pour afficher le périmètre d'effort et la rétrocession souhaitée
            if(input.name === "radius_preference") {
                if (input.value !== "9") {
                    this.form0.show("radius_effort");
                         
                    if(fData.radius_effort !== "0") {
                        this.form0.show("retrocession_wished_effort");
                    } else {
                        this.form0.hide("retrocession_wished_effort");
                    }
                } else {
                    this.form0.hide("radius_effort" );
                    this.form0.hide("retrocession_wished_effort");
                }

                var datas = RadiusEffort.values().filter(element =>{
                    return (parseInt(element.value) === 0 || (parseInt(element.value) >= parseInt(input.value) + 3 ))
                });
                this.form0.inputs[3].choices = datas;
                
                this.updateMap(fData.address_street, fData.address_city, fData.address_postcode, input.value, fData.radius_effort)
                this.forceUpdate();
            }

            if (input.name === 'retrocession_wished') {
                let retrocessionFilter = Retrocession.values().filter(element => {
                    return (parseInt(element.value) === 0 || (parseInt(element.value) >= parseInt(input.value)))
                });
                this.form0.inputs[4].choices = retrocessionFilter;
                this.forceUpdate();
            }

            if (input.name === "medical_software") {
                if (input.value.includes("23")) {
                    let noneChoice = { label: "Aucun", value: "23"};
                    this.form.setValue('medical_software', noneChoice);
                } else if (input.value.includes("0")) {
                    this.form.show("other_software")
                } else {
                    this.form.hide("other_software")
                }
            }
        };

        var medicalSoftwares = MedicalSoftwares.values();
        var teleconsultations = Teleconsultations.values();

        let year = moment().add(3, 'years').year();

        this.form0 = new Form({
            name:"substituteCharacteristic",
            inputs:[
                new SearchLocationInput("address_street", "Address", [MinLength(2), MaxLength(200), NotEmpty], this.updateCityAndPostalCode),

                new SelectInput("radius_preference", "Radius.Confort", RadiusConfort.values(), [NotEmpty]),
                new SelectInput("retrocession_wished", "Retrocession.Wished.Confort", Retrocession.values(), [NotEmpty]),

                new SelectInput("radius_effort", "Radius.Effort", RadiusEffort.values(), undefined, {cClassName: "inline-blocks"}),
                new SelectInput("retrocession_wished_effort", "Retrocession.Wished.Effort", Retrocession.values()),
                new SelectInput("guard_retrocession_wished", "Guard.Retrocession.Wished", Retrocession.values(), [NotEmpty]),

                ],
            changeCbk: _changeCbk,
            data: defaultValues,
            options: { validate: Util.null(validate) ? true : validate, validateAtStart: false }
        });

        this.form = new Form({
            name: "substituteCharacteristic",
            inputs: [
                
                new RangeInput("consult_capacity_per_week", "Consultation.Capacity.Per.Week", 50, 300, 10, [NotEmpty], {
                    className: "blue-range-input",
                    labelHint: <div style={{ maxWidth: 300, float: "right", marginTop: -10 }} className="text-right">
                        <i><small><FormattedMessage id="Consult.Capacity.Hint" /></small></i>
                    </div>
                }),
                new SelectInput("accept_home_visits", "Do.You.Accept.Home.Visits", SubstituteAcceptHomeVisits.values(), [NotEmpty]),

                new SelectInput("studies_level", "I.Am", SubstituteStudiesLevel.values(), [NotEmpty]),
                new NumericInput("year_end_internship", "Year.Of.End.Internship", 4, [NotEmpty, Min(1950), Max(year)]),
                new MultiSelectInput("medical_software", "Medical.Software.Sub", medicalSoftwares, [NotEmpty], { cClassName: "text-left1" }),
                new TextInput("other_software", "Other.Software", undefined, [NotEmpty]),
                new MultiSelectInput("teleconsultation", "Teleconsultation", teleconsultations, [NotEmpty], {cClassName: "text-left2"}),
                
                new SelectInput("settlement_preference", "I.Want.To.Settle", SubstituteSettlementPreference.values(), [NotEmpty]),

                new NumericInput("RPPS", "RPPS.Number", 11, [NotEmpty, MinLength(11)]),
                new TextInput("license", "Repl.License.number", 20, [NotEmpty]),
                new NumericInput("siret", "SIRET", 14, []),
            
                new Title(<div className="d-block w-100 uppercase p-2 pb-4 bold"><FormattedMessage id="Medical.Practices" /></div>),
                new ImgedSwitchInput("ecg", "ECG", [NotEmpty], { displayLabel: false, cClassName: "d-inline-block", img: ecgImg }),
                new ImgedSwitchInput("gynecology", "Gynecology", [NotEmpty], { displayLabel: false, cClassName: "d-inline-block", img: gynecologyImg }),
                new ImgedSwitchInput("osteopathy", "Osteopathy", [NotEmpty], { displayLabel: false, cClassName: "d-inline-block", img: osteopathyImg }),
                new ImgedSwitchInput("homeopathy", "Homeopathy", [NotEmpty], { displayLabel: false, cClassName: "d-inline-block", img: homeopathyImg }),
                new ImgedSwitchInput("sports_medicine", "Sports.Medicine", [NotEmpty], { displayLabel: false, cClassName: "d-inline-block", img: sportsImg }),
                new ImgedSwitchInput("allergology", "Allergology", [NotEmpty], { displayLabel: false, cClassName: "d-inline-block", img: allergologyImg }),
                new ImgedSwitchInput("emergency_medicine", "Emergency.Medicine", [NotEmpty], { displayLabel: false, cClassName: "d-inline-block", img: emergencyImg }),

                new Title(<div className="d-block w-100 uppercase p-2 pb-4 bold"><FormattedMessage id="Trainings" /></div>),
                new Addable("du_dius", "DU.DIUs", "fa fa-plus-square", () => [new TextInput("topic", "Topic", 100, [NotEmpty])]),
                new Addable("descs", "DESCs", "fa fa-plus-square", () => [new TextInput("topic", "Topic", 100, [NotEmpty])]),
                new SelectInput("duration", "Duration.Appointment", Duration.values(), [NotEmpty], {value: '15'}),
            ],
            submitCbk: _submitCbk,
            changeCbk: _changeCbk,
            data: defaultValues,
            options: { validate: Util.null(validate) ? true : validate, validateAtStart: false },
        });
    }

    row(form, inputName) {
        return (
            <Row className="form-group">
				<Col md="5">{form.getLabel(inputName)}</Col>
                <Col md="7">{form.getInput(inputName)}</Col>
            </Row>
        );
    };

    validate(cbk) {
        let isValid = true;
		let isFormValid = true;

        let fullAddress = this.form0.inputs[0]._state.value;

        return Geocode.fromAddress(fullAddress)
        .then((response) => {
            if (!response) {
                this.form0.setError('address_street', 'Veuillez controler votre adresse');
                return false;
            }
            if (!response.results[0] || !response.results[0].address_components) {
                this.form0.setError('address_street', 'Veuillez controler votre adresse');
                return false;
            }
            this.form.inputs.forEach((elem) => {
                if (elem.valid) {
                    if (elem.name === 'other_software') {
                        if (elem._state.hidden === false) {
                            isValid = elem.valid();
                        } else {
                            isValid = true;
                        }
                    } else {
                        isValid = elem.valid();
                    }
                }
    
                if (isValid === false) {
                    isFormValid = false;
                }
            })
            this.form0.inputs.forEach((elem) => {
                isValid = elem.valid();
                if (isValid === false) {
                    isFormValid = false;
                }
            })

            let address_components = {};
            let streetNumber = '';
            let streetName = '';
            let adressCity = '';
            let postalCode = '';
            for (let comp of response.results[0].address_components) {
                if (comp.types.includes("street_number")) {
                    address_components.street_number = comp.long_name;
                    streetNumber = comp.long_name;
                } 
                if (comp.types.includes("route")){
                    address_components.route = comp.long_name;
                    streetName = comp.long_name;
                } 
                if (comp.types.includes("administrative_area_level_1")) address_components.region = comp.long_name;
                if (comp.types.includes("administrative_area_level_2")) address_components.department = comp.long_name;
                if (comp.types.includes("locality")) {
                    address_components.city = comp.long_name;
                    adressCity = comp.long_name;
                } 
                if (comp.types.includes("country")) address_components.country = comp.long_name;
                if (comp.types.includes("postal_code")) {
                    address_components.postal_code = comp.long_name;
                    postalCode = comp.long_name;
                } 
            }
            let addressStreet = streetNumber + ' ' + streetName;

            if (addressStreet.length < 2) {
                this.form0.setError('address_street', 'Veuillez controler votre adresse');
                return false;
            }

            if (postalCode.length < 2) {
                this.form0.setError('address_street', 'Veuillez controler votre adresse');
                return false;
            }

            let data = Object.assign({}, this.form0.getRawData(), this.form.getRawData(), { 
                address_street: addressStreet, 
                address_city: adressCity, 
                address_postcode: postalCode,
                location: {
                    type: "Point", 
                    coordinates: [response.results[0].geometry.location.lng, response.results[0].geometry.location.lat]
                },
                address_components
            })  

            cbk(data);

            return isFormValid;
        })
        .catch(() => {
            this.form0.setError('address_street', 'Veuillez controler votre adresse');
            return false;
        })
	}

    updateMap(street = "", city = "", postcode = "", radius_preference = "0", radius_effort = "0") {
        const fullAddress = street + " " + city + " " + postcode;

        this.setState({ loadingGMaps: true });
        if (this.loadMapCbk) this.loadMapCbk();

        Geocode.fromAddress(fullAddress).then((response) => {
            const { lat, lng } = response.results[0].geometry.location;
            this.setState({ loadingGMaps: false });

            var region, department, city, country, street_number, route, postal_code;
            if (response.results[0] && response.results[0].address_components) {
                for (let comp of response.results[0].address_components) {
                    if (comp.types.includes("street_number")) street_number = comp.long_name;
                    if (comp.types.includes("route")) route = comp.long_name;
                    if (comp.types.includes("administrative_area_level_1")) region = comp.long_name;
                    if (comp.types.includes("administrative_area_level_2")) department = comp.long_name;
                    if (comp.types.includes("locality")) city = comp.long_name;
                    if (comp.types.includes("country")) country = comp.long_name;
                    if (comp.types.includes("postal_code")) postal_code = comp.long_name;
                }
            }
            // Store Geoloc data
            this.setState({ lat, lng, region, department, city, country, street_number, route, postal_code, street });
            if (this.fixMapCbk) this.fixMapCbk(lat, lng, radius_preference, radius_effort);

            this.forceUpdate();
        }, (error) => {
            console.error(error);

            this.setState({ loadingGMaps: false });
        });
    }

    updateCityAndPostalCode(street, city, postal_code) {
        const { radius_preference, radius_effort } = this.form0.getRawData();
        this.updateMap(street, city, postal_code, radius_preference, radius_effort)
    }

    validateToScrollTop() {
        window.scrollTo(0, 0)
    }

    render() {
        const { submitButtoni18nId } = this.props;
        return (
            <React.Fragment>
                <Row style={{marginBottom: "0 !important"}} className="step step1 mb-4 justify-content-md-center">
                    {this.form && this.form0 && 
                        <>
                            <Col md={12} sm={12}>
                                <Card style={{marginBottom: "0 !important"}} className="rounded-0">
                                    <CardBody style={{paddingTop: "1em"}} className="text-center">
                                        {this.row(this.form0, "address_street")}
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md={6} sm={12} className="order-sm-1">
                                <Card style={{marginBottom: "0 !important"}} className="rounded-0">
                                    <CardBody style={{paddingTop: "1em"}} className="text-center">
                                        {this.row(this.form0, "radius_preference")}
                                        {this.row(this.form0, "retrocession_wished")}
                                    </CardBody>
                                </Card>
                                <Card style={{marginBottom: "0 !important"}} className="rounded-0">
                                    <span className="text-center"><FormattedHTMLMessage id="Go.Further" /></span>
                                    <CardBody style={{paddingTop: "1em"}} className="text-center">
                                        {this.row(this.form0, "radius_effort")}
                                        {this.row(this.form0, "retrocession_wished_effort")}
                                    </CardBody>
                                </Card>
                                <Card style={{marginBottom: "0 !important"}} className="rounded-0">
                                    <CardBody style={{paddingTop: "1em"}} className="text-center">
                                        {this.row(this.form0, "guard_retrocession_wished")}
                                    </CardBody>
                                </Card>
                            </Col>
                        </>
                    }
                    <Col md={6} sm={12} className="order-sm-2" >
                        <Card style={{marginBottom: "0 !important"}} className="rounded-0">
                            <CardBody className="text-center">
                                {this.map}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="step step1 mb-4 justify-content-md-center">
                    <Col>
                        <Card style={{marginTop: "0 !important"}} className="rounded-0">
                            {this.form && this.form0 && 
                                <CardBody className="p-5 pt-0 text-center">

                                    {this.form.body()}

                                    <Row className="mt-4 pt-5">
                                        <div className="text-center w-100 ">
                                            {(this.props.onBackCbk) &&
                                                <div className="d-inline-block align-top mb-3 mb-sm-0 mx-auto">
                                                    <div
                                                        className={"btn lowercase srounded pl-5 pr-5 pt-2 pb-2 bg-blue text-white mx-auto"}
                                                        onClick={() => {
                                                            const data = Object.assign({}, this.form0.getRawData(), this.form.getRawData());
                                                            this.props.onBackCbk(data)
                                                        }}>

                                                        <FormattedHTMLMessage id="Previous.Step" />
                                                    </div>
                                                </div>
                                            }

                                            {(this.props.onBackCbk) && <div className="d-block d-sm-inline-block m-0 p-0 h-0">&nbsp;&nbsp;&nbsp;</div>}
                                            
                                            <div className="d-inline-block align-top mx-auto">
                                                <div onClick={this.validateToScrollTop} className="mx-auto form-buttons">
                                                    {this.form.submitButton(submitButtoni18nId, {
                                                        loading: this.state.loadingGMaps,
                                                        className: "srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green"
                                                    })}
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </Row>

                                </CardBody>
                            }
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}
