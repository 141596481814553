import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import DCalendarCard from './DCalendarCard';


export default class DCalendarReplacementModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            disabled: false
        };
    };

    confirm() {
        this.props.onConfirm();
        this.setState({ disabled: true });
    };

    cancel() {
        if (this.props.onCancel) this.props.onCancel();
        this.props.toggle();
    };


    render() {
        const { event } = this.props;

        return (
            <Modal isOpen={true} toggle={this.props.toggle} className="simple-modal simple-modal-white srounded">

                <ModalHeader toggle={this.props.toggle} className="pl-4 ml-0" style={{ border: "none" }}>

                </ModalHeader>

                <ModalBody className="p-0 pb-4">

                    <DCalendarCard
                        history={this.props.history}
                        key={event.original._id}
                        replacement={event.original}
                        onDelete={() => this.props.toggle()}
                        onApply={() => this.props.onApply()} />

                </ModalBody>

            </Modal>
        );

    }

}
