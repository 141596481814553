import DateUtil from "../util/DateUtil";


export default class CalendarEventEnt {

    static validate(form) {
        const formData = form.getRawData();

        if (formData.end_date) {
            // Cannot be before start date
            if (formData.start_date && DateUtil.before(formData.end_date, formData.start_date)) form.setError("end_date", "EndDate.Before.StartDate.Err");
        };

        return form;
    };

    static occasionalPeriod(startDate = new Date(), wishedEndDate) {
        // Start date cannot be in the past
        var start = new Date(startDate);
        while (DateUtil.isDay(start, 0)) start.setDate(new Date(start).getDate() - 1);

        // We set endDate to the day after startDate
        var dayAfter = new Date(start);
        dayAfter.setDate(new Date(start).getDate() + 1);
        if (!wishedEndDate) wishedEndDate = new Date(dayAfter);

        var end = DateUtil.greatest(new Date(wishedEndDate), new Date(dayAfter));
        end = DateUtil.greatest(new Date(end), new Date(start));

        // If such day is a Sunday, remove 1 day 
        if (DateUtil.isDay(end, 0)) end.setDate(new Date(end).getDate() - 1);

        return { start, end };
    }

}

