import axios from "axios";

import Geocode from "react-geocode";
const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
Geocode.setApiKey(GOOGLE_MAP_API_KEY);


const rootUrl = process.env.REACT_APP_BE_URL || "http://localhost:7000";

export function getClinicalStudy() {

    return dispatch => {
		dispatch(request());
        var url = rootUrl + "/clinicalStudy/find";

        return axios.get(url)
            .then(function (response) {

                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "FIND_CLINICAL_STUDY_REQUEST", isGettingClinicalStudy: true}}
	function success(result) { return { type: "FIND_CLINICAL_STUDY_SUCCESS", listOfClinicalStudy: result, isGettingClinicalStudy: false}}
	function failure() { return { type: "FIND_CLINICAL_STUDY_FAILURE", isGettingClinicalStudy: false}}
};

export function getClinicalStudyCabinet() {

    return dispatch => {
		dispatch(request());
        var url = rootUrl + "/clinicalStudy/find-cabinet";

        return axios.get(url)
            .then(function (response) {

                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "FIND_CLINICAL_STUDY_CABINET_REQUEST", isGettingClinicalStudyCabinet: true}}
	function success(result) { return { type: "FIND_CLINICAL_STUDY_CABINET_SUCCESS", listOfClinicalStudyCabinet: result, isGettingClinicalStudyCabinet: false}}
	function failure() { return { type: "FIND_CLINICAL_STUDY_CABINET_FAILURE", isGettingClinicalStudyCabinet: false}}
};

export function addPatient(study_id, patient, center_id, callback) {

	return dispatch => {
		dispatch(request());

        const url = rootUrl + "/clinicalStudy/add-patient";

        let params = { study_id, patient, center_id };
        const errMsg = "Veuillez contrôler votre adresse";

        let promiseAddress = new Promise((resolve, reject) => {
            return Geocode.fromAddress(patient.address).then((response) => {
                if (!response) {
                    reject(errMsg);
                }
                if (response.results[0] && response.results[0].address_components) {
                    patient.location = { type: "Point", coordinates: [response.results[0].geometry.location.lng, response.results[0].geometry.location.lat] };
                    let address_components = {};
                    for (let comp of response.results[0].address_components) {
                        if (comp.types.includes("street_number")) address_components.street_number = comp.long_name;
                        if (comp.types.includes("route")) address_components.route = comp.long_name;
                        if (comp.types.includes("administrative_area_level_1")) address_components.region = comp.long_name;
                        if (comp.types.includes("administrative_area_level_2")) address_components.department = comp.long_name;
                        if (comp.types.includes("locality")) address_components.city = comp.long_name;
                        if (comp.types.includes("country")) address_components.country = comp.long_name;
                        if (comp.types.includes("postal_code")) address_components.postal_code = comp.long_name;
                    }

                    let street_number = address_components.street_number ? address_components.street_number + ' ' : '';
                    patient.address = street_number + address_components.route + ', ' + address_components.postal_code + ' ' + address_components.city + ', ' + address_components.country;
                    
                    resolve();
                } else {
                    reject(errMsg);
                }
            }).catch((err) => {
                reject(errMsg);
            })
        })

        return promiseAddress.then(() => {
            return axios.post(url, params)
        }).then(function (response) {

            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure(err))
        });
	};

    function request() { return { type: "ADD_PATIENT_CLINICAL_STUDY_REQUEST" }}
	function success(clinicalStudy) { return { type: "ADD_PATIENT_CLINICAL_STUDY_SUCCESS", clinicalStudy }}
	function failure(error) { return { type: "ADD_PATIENT_CLINICAL_STUDY_FAILURE", error }}
};

export function acceptPatientCGU(patient_id, callback) {

	return dispatch => {
		dispatch(request());

        var url = rootUrl + "/clinicalStudy/accept-patient-cgu/" + patient_id;

        let params = { patient_id };

        return axios.post(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure())
        });
	};

    function request() { return { type: "ACCEPT_PATIENT_CGU_REQUEST", acceptedPatientCGU: false, }}
	function success() { return { type: "ACCEPT_PATIENT_CGU_SUCCESS", acceptedPatientCGU: true }}
	function failure(error) { return { type: "ACCEPT_PATIENT_CGU_FAILURE", acceptedPatientCGU: false }}
};

export function getClinicalStudyByPatientId(patient_id, callback) {

	return dispatch => {
		dispatch(request());
        
        var url = rootUrl + "/clinicalStudy/find-patient-id/" + patient_id;

        let params = { patient_id };

        return axios.get(url, params).then(function (response) {
            if (response && response.data) {
                const obj = response.data.message;
                dispatch(success(obj));
                if (callback) callback(obj);
            } else {
                if (callback) callback();
            }
        })
        .catch(function (err) {
            dispatch(failure())
        });
	};

    function request() { return { type: "FIND_CLINICAL_STUDY_BY_PATIENT_ID_REQUEST", isGettingClinicalStudy: true }}
	function success(result) { return { type: "FIND_CLINICAL_STUDY_BY_PATIENT_ID_SUCCESS", clinicalStudy: result, isGettingClinicalStudy: false }}
	function failure(error) { return { type: "FIND_CLINICAL_STUDY_BY_PATIENT_ID_FAILURE", isGettingClinicalStudy: false }}
};