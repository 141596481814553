import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import 'react-datetime/css/react-datetime.css';

import StringUtil from '../../../util/StringUtil';

import ecgImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_ECG.png';
import gynecologyImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_GYNECO_02.png';
import osteopathyImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_OSTEO.png';
import homeopathyImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_HOMEO_01.png';
import sportsImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_HOMEO_SPORT.png';
import allergologyImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_ALLERGO.png';
import emergencyImg from '../../../assets/images/registration/HYDROGEN_ICONS_MED_URGENCE.png';


export default class SubstitutePracticesProfile extends Component {

    buildRoundElem = (characteristics, field, i18n, img) => {
        if (!i18n) i18n = StringUtil.capitalize(field);

        return (
            characteristics[field] &&
            <div className="round-elem d-inline-block" style={{ opacity: 1}}>
                <div className="">
                    <img src={img} alt="" width="50" height="50" />
                </div>
                <div className="title mt-2"><FormattedMessage id={i18n} /></div>
            </div>
        );
    }

    render() {
        const { characteristics } = this.props;

        return (
            <React.Fragment>
                <h5 className="">
                    <b><FormattedMessage id="Medical.Practices" /></b>
                </h5>
                <div className="d-inline-block mx-auto w-100 text-center">
                    
                    {this.buildRoundElem(characteristics, "ecg", undefined, ecgImg)}
                    {this.buildRoundElem(characteristics, "gynecology", undefined, gynecologyImg)}
                    {this.buildRoundElem(characteristics, "osteopathy", undefined, osteopathyImg)}
                    {this.buildRoundElem(characteristics, "homeopathy", undefined, homeopathyImg)}
                    {this.buildRoundElem(characteristics, "sports_medicine", "Sports.Medicine", sportsImg)}
                    {this.buildRoundElem(characteristics, "allergology", undefined, allergologyImg)}
                    {this.buildRoundElem(characteristics, "emergency_medicine", "Emergency.Medicine", emergencyImg)}

                </div>

            </React.Fragment>
        );
    }
}


