export default function officeTimeTable(state = {}, action) {
	switch(action.type) {
		case "GETS_OFFICE_TIME_TABLE_REQUEST":
			return Object.assign({}, state, {
				getOfficeTimeTable: false
			});
		case "GETS_OFFICE_TIME_TABLE_SUCCESS":
			return Object.assign({}, state, {
				getOfficeTimeTable: true,
				officeTimeTable: action.officeTimeTable,
			});
		case "GETS_OFFICE_TIME_TABLE_FAILURE":
			return Object.assign({}, state, {
				getOfficeTimeTable: false
			});
		default:
			return state;
	}
}