import React, { Component } from 'react';
import { Row } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage } from 'react-intl';
import 'ladda/dist/ladda-themeless.min.css';

export default class DoctorOfficeTimeTableProfile extends Component {

    render() {
        const { officeTimeTable } = this.props;

        // const checkIcon = () => <div className="check-icon-tt"><i className="fa fa-check-circle" aria-hidden="true" /></div>;

        return (
            <React.Fragment>

                <Row className="pl-2 pr-2">

                    <p className="w-100 text-center"><b><FormattedMessage id="CONSULTATIONS" /></b></p>

                    <table className="table table-sm table-responsive-sm text-center office-timetable">
                        <thead>
                            <tr>
                                <th scope="col">&nbsp;</th>
                                <th scope="col"><FormattedMessage id="Monday" /></th>
                                <th scope="col"><FormattedMessage id="Tuesday" /></th>
                                <th scope="col"><FormattedMessage id="Wednesday" /></th>
                                <th scope="col"><FormattedMessage id="Thursday" /></th>
                                <th scope="col"><FormattedMessage id="Friday" /></th>
                                <th scope="col"><FormattedMessage id="Saturday" /></th>
                                <th scope="col"><FormattedMessage id="Sunday" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" rowSpan="2" className="text-left"><FormattedMessage id="Morning" /></th>
                                <td>{officeTimeTable.mondayMorningStart}</td>
                                <td>{officeTimeTable.tuesdayMorningStart}</td>
                                <td>{officeTimeTable.wednesdayMorningStart}</td>
                                <td>{officeTimeTable.thursdayMorningStart}</td>
                                <td>{officeTimeTable.fridayMorningStart}</td>
                                <td>{officeTimeTable.saturdayMorningStart}</td>
                                <td>{officeTimeTable.sundayMorningStart}</td>
                            </tr>
                            <tr>
                                <td>{officeTimeTable.mondayMorningEnd}</td>
                                <td>{officeTimeTable.tuesdayMorningEnd}</td>
                                <td>{officeTimeTable.wednesdayMorningEnd}</td>
                                <td>{officeTimeTable.thursdayMorningEnd}</td>
                                <td>{officeTimeTable.fridayMorningEnd}</td>
                                <td>{officeTimeTable.saturdayMorningEnd}</td>
                                <td>{officeTimeTable.sundayMorningEnd}</td>
                            </tr>
                            <tr>
                                <th scope="row" rowSpan="2" className="text-left"><FormattedMessage id="Afternoon" /></th>
                                <td>{officeTimeTable.mondayAfternoonStart}</td>
                                <td>{officeTimeTable.tuesdayAfternoonStart}</td>
                                <td>{officeTimeTable.wednesdayAfternoonStart}</td>
                                <td>{officeTimeTable.thursdayAfternoonStart}</td>
                                <td>{officeTimeTable.fridayAfternoonStart}</td>
                                <td>{officeTimeTable.saturdayAfternoonStart}</td>
                                <td>{officeTimeTable.sundayAfternoonStart}</td>
                            </tr>
                            <tr>
                                <td>{officeTimeTable.mondayAfternoonEnd}</td>
                                <td>{officeTimeTable.tuesdayAfternoonEnd}</td>
                                <td>{officeTimeTable.wednesdayAfternoonEnd}</td>
                                <td>{officeTimeTable.thursdayAfternoonEnd}</td>
                                <td>{officeTimeTable.fridayAfternoonEnd}</td>
                                <td>{officeTimeTable.saturdayAfternoonEnd}</td>
                                <td>{officeTimeTable.sundayAfternoonEnd}</td>
                            </tr>
                        </tbody>
                    </table>

                </Row>

                {/* <Row className="pl-2 pr-2">

                    <p className="mt-3 w-100 text-center"><b><FormattedMessage id="VISITS" /></b></p>

                    <table className="table table-sm table-responsive-sm text-center office-timetable">
                        <thead>
                            <tr>
                                <th scope="col">&nbsp;</th>
                                <th scope="col"><FormattedMessage id="Monday" /></th>
                                <th scope="col"><FormattedMessage id="Tuesday" /></th>
                                <th scope="col"><FormattedMessage id="Wednesday" /></th>
                                <th scope="col"><FormattedMessage id="Thursday" /></th>
                                <th scope="col"><FormattedMessage id="Friday" /></th>
                                <th scope="col"><FormattedMessage id="Saturday" /></th>
                                <th scope="col"><FormattedMessage id="Sunday" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" className="text-left">
                                    <FormattedMessage id="Morning" />
                                </th>
                                <td>{officeTimeTable.mondayMorning ? checkIcon() : ""}</td>
                                <td>{officeTimeTable.tuesdayMorning ? checkIcon() : ""}</td>
                                <td>{officeTimeTable.wednesdayMorning ? checkIcon() : ""}</td>
                                <td>{officeTimeTable.thursdayMorning ? checkIcon() : ""}</td>
                                <td>{officeTimeTable.fridayMorning ? checkIcon() : ""}</td>
                                <td>{officeTimeTable.saturdayMorning ? checkIcon() : ""}</td>
                                <td>{officeTimeTable.sundayMorning ? checkIcon() : ""}</td>
                            </tr>
                            <tr>
                                <th scope="row" className="text-left">
                                    <FormattedMessage id="Afternoon" />
                                </th>
                                <td>{officeTimeTable.mondayAfternoon ? checkIcon() : ""}</td>
                                <td>{officeTimeTable.tuesdayAfternoon ? checkIcon() : ""}</td>
                                <td>{officeTimeTable.wednesdayAfternoon ? checkIcon() : ""}</td>
                                <td>{officeTimeTable.thursdayAfternoon ? checkIcon() : ""}</td>
                                <td>{officeTimeTable.fridayAfternoon ? checkIcon() : ""}</td>
                                <td>{officeTimeTable.saturdayAfternoon ? checkIcon() : ""}</td>
                                <td>{officeTimeTable.sundayAfternoon ? checkIcon() : ""}</td>
                            </tr>
                        </tbody>
                    </table>

                </Row> */}

            </React.Fragment >
        );
    };

}