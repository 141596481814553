import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';
import DAgreementCard from './DAgreementCard'
import { getsBy, clears } from '../../../actions/global/global';

import Pagination from "react-js-pagination";
import contractImg from '../../../assets/images/search/HYDROGEN_ILLU_CONTRAT.png';

class DNotDoneAgreements extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            // Pagination
            skip: 0,
            limit: 10
        };
    };

    fetchData() {
        this.setState({ loading: true });
        this.props.onGetsBy("agreement/notdone", ["skip", "limit"], [this.state.skip, this.state.limit], () => this.setState({ loading: false }));
    }

    componentWillMount() {
        this.fetchData();
    };

    onRate() {
        if (!this.props.past) return;

        this.fetchData();
    }

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    render() {
        const { agreements, agreementsCount } = this.props;

        return (
            <React.Fragment>

                {(this.state.loading) && this.loading()}

                {(!this.state.loading && (!agreements || !agreements.length)) &&
                    <div className="text-center mt-5 mb-2 mx-auto" style={{ width: 250 }}>
                        <div className="mb-3 mx-auto" style={{ width: 150, height: 150 }}>
                            <img src={contractImg} alt="" width={150} height={150} />
                        </div>

                        <div className="uppercase text-white"><FormattedHTMLMessage id="You.Have.No.Pending.Agreement" /></div>

                        <a href="#/home/availability/find" alt="" className="btn srounded mt-4 pl-5 pr-5 pt-2 pb-2 text-white bg-white text-blue lp-1 mx-auto">
                            <b><FormattedHTMLMessage id="Start.Research" /></b>
                        </a>
                    </div>
                }

                {(!this.state.loading && agreements && agreements.length > 0) &&
                    <>
                        <div className="w-100 text-center text-white mb-3">
                            {agreementsCount}&nbsp;
                            {(agreementsCount > 1) ? <FormattedMessage id="Results" /> : <FormattedMessage id="Result" />}
                        </div>

                        {
                            agreements.map(agreement => {
                                return <DAgreementCard
                                    key={agreement._id}
                                    agreement={agreement}
                                    onRate={() => this.onRate()}
                                    onSignatureCbk={() => this.fetchData()}
                                    onCancelCbk={() => this.fetchData()}
                                />
                            })
                        }

                        <Pagination
                            innerClass="w-100 pagination justify-content-center"
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={Math.round(this.state.skip / this.state.limit) + 1}
                            itemsCountPerPage={this.state.limit}
                            totalItemsCount={agreementsCount}
                            pageRangeDisplayed={5}
                            onChange={(page) => this.setState({ skip: (page - 1) * this.state.limit }, this.fetchData)}
                            hideDisabled={false}
                        />

                    </>
                }

            </React.Fragment>
        );
    };
};

const mapStateToProps = state => {
    return {
        agreements: state.global.agreements,
        agreementsCount: state.global.agreementsCount
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DNotDoneAgreements);

