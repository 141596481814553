import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage } from 'react-intl';
import 'ladda/dist/ladda-themeless.min.css';

export default class PsyIAboutProfile extends Component {

    render() {
        const { psychologistICharacteristic } = this.props;

        return (
            <React.Fragment>

                <div className="">

                    <h5 className="">
                        <b><FormattedMessage id="About" /></b>
                    </h5>

                    <div className="mx-auto  text-left">
                        <p className="mt-0 mb-0">
                            <FormattedMessage id="Consultations.Per.Week.On.Average.Short" />:&nbsp; <b>{psychologistICharacteristic.consultations_per_week_on_avg}</b>
                        </p>

                        <p className="mt-0 mb-0">
                            <FormattedMessage id="Visits.Per.Week.On.Average.Short" />:&nbsp; <b>{psychologistICharacteristic.visits_per_week_on_avg}</b>
                        </p>
                    </div>
                </div>

            </React.Fragment >
        );
    };

}