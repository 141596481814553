import React, { Component } from 'react';
import { Card, CardBody, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import 'react-datetime/css/react-datetime.css';
import 'ladda/dist/ladda-themeless.min.css';
import { connect } from "react-redux";
import AddSecretaryModal from '../../secretaries/AddSecretaryModal';
import SecretaryCard from '../../secretaries/SecretaryCard';
import { getAllByDoctor } from "../../../actions/secretaryManagement/secretaryManagement";
import classnames from "classnames";
class DoctorSecretaries extends Component {

    constructor(props) {
        super(props);

        this.state = {
            addSecretaryModal: false,
            activeTab: "1"
        }

        this.openAddSecretaryModal = this.openAddSecretaryModal.bind(this);
        this.closeAddSecretaryModal = this.closeAddSecretaryModal.bind(this);
    }

    componentDidMount() {
        this.props.getAllByDoctor()
    }

    componentDidUpdate(prevProps) {
		if(prevProps.isDoctorAddingSecretary !== this.props.isDoctorAddingSecretary && !this.props.isDoctorAddingSecretary) {
			this.props.getAllByDoctor()
		}

        if(prevProps.isUpdatingSecretaryMangement !== this.props.isUpdatingSecretaryMangement && !this.props.isUpdatingSecretaryMangement) {
			this.props.getAllByDoctor()
		}
	}

    openAddSecretaryModal() {
        this.setState({ addSecretaryModal: true })
    }

    closeAddSecretaryModal() {
        this.setState({ addSecretaryModal: false })
    }

    toggle = (tab) => {
        const { activeTab } = this.state;
        if (activeTab !== tab) this.setState({ activeTab: tab });
    };

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-muted mx-auto" role="status"></div></div>;

    render() {
        const { secretaryManagements } = this.props;

        return (
            <React.Fragment>

                <Card className="srounded">
                    <div className="pt-3 bold text-center ">
                        <button className="" onClick={this.openAddSecretaryModal}><FormattedMessage id='Add.Secretary' /></button>
                    </div>
                    
                    <CardBody className="text-center pl-4 pr-4 mb-3">
                        <Row>
                            <h4 className="bold m-5 text-center"> <FormattedMessage id="List.Secretaries" /> </h4>
                        </Row>
                        <Row>
                            <Col lg="2" sm="12">
                                <ListGroup>
                                    <ListGroupItem
                                        className={classnames("text-color-black clickable", {
                                            active: this.state.activeTab === "1",
                                        })}
                                        onClick={() => { this.toggle("1"); }}
                                    >
                                        Secrétaires validées
                                    </ListGroupItem>
                                    <ListGroupItem
                                        className={classnames("text-rolor-black clickable", {
                                            active: this.state.activeTab === "2",
                                        })}
                                        onClick={() => { this.toggle("2"); }}
                                        action
                                    >
                                        Demande en cours
                                    </ListGroupItem>
                                    <ListGroupItem
                                        className={classnames("text-rolor-black clickable", {
                                            active: this.state.activeTab === "3",
                                        })}
                                        onClick={() => { this.toggle("3"); }}
                                        action
                                    >
                                        Refus
                                    </ListGroupItem>
                                </ListGroup>
                            </Col>
                            <Col lg="10" sm="12">
                                {!secretaryManagements && this.loading()}

                                {/* Valid managments */}
                                {this.state.activeTab === "1" && secretaryManagements && secretaryManagements.filter((secretaryManagement) => (secretaryManagement.status_active === true)).map((secretaryManagement, index) => {
                                    return <SecretaryCard
                                        type="validmanagement"
                                        secretaryManagement={secretaryManagement}
                                        key={index}
                                    />
                                })}

                                {/* ongoingrequests */}
                                {this.state.activeTab === "2" && secretaryManagements && secretaryManagements.filter((secretaryManagement) => ((secretaryManagement.status_active === false && secretaryManagement.status === "0") || (secretaryManagement.status_active === false && secretaryManagement.status === "3"))).map((secretaryManagement, index) => {
                                    return <SecretaryCard
                                        type="ongoingrequest"
                                        secretaryManagement={secretaryManagement}
                                        key={index}
                                    />
                                })}

                                {/* Refusals */}
                                {this.state.activeTab === "3" && secretaryManagements && secretaryManagements.filter((secretaryManagement) => ((secretaryManagement.status_active === false && secretaryManagement.status === "5") || (secretaryManagement.status_active === false && secretaryManagement.status === "2"))).map((secretaryManagement, index) => {
                                    return <SecretaryCard
                                        type="refusal"
                                        secretaryManagement={secretaryManagement}
                                        key={index}
                                    />
                                })}
                            </Col>
                            {/* </Row>
                        <Row> */}


                        </Row>
                    </CardBody>
                </Card>

                {this.state.addSecretaryModal &&
                    <AddSecretaryModal
                        close={this.closeAddSecretaryModal} />
                }


            </React.Fragment >
        );
    };

}

function mapStateToProps(state) {
    return {
        secretaryManagements: state.secretaryManagement.secretaryManagements,
        isGettingsecretaryManagements: state.secretaryManagement.isGettingsecretaryManagements,
		isUpdatingSecretaryMangement: state.secretaryManagement.isUpdatingSecretaryMangement,
        isDoctorAddingSecretary: state.secretary.isDoctorAddingSecretary
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllByDoctor: () => dispatch(getAllByDoctor())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorSecretaries);