import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import homeImgClinicalStudy from '../../assets/images/homes/HYDROGEN_ILLUSTRATIONS_RECHERCHESCLINIQUES.png';
import { getClinicalStudy } from '../../actions/clinicalStudy/clinicalStudy'


class PSHome extends React.Component {

	componentDidMount() {
		this.props.getClinicalStudy()
	}

	loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

	button = (i18n, link = "", img) => {
		return (
			<Col xs="6" sm="3" className="p-0 m-0">
				<div className="d-inline-block text-center mb-2">
					<Link to={link} className="d-inline-block mb-3 img-btn" style={{ width: 150, height: 150 }}>
						<img src={img} alt="" width={150} height={150} />
					</Link>
					<div className="uppercase text-white"><FormattedHTMLMessage id={i18n} /></div>
				</div>
			</Col>
		);
	}

	inactiveLink = (i18n, img) => {
		return (
			<Col xs="6" sm="3" className="p-0 m-0">
				<div className="d-inline-block text-center mb-2">
					<div className="d-inline-block mb-3" style={{ width: 150, height: 150 }}>
						<img src={img} alt="" width={150} height={150} />
					</div>
					<div className="uppercase text-white"><FormattedHTMLMessage id={i18n} /></div>
				</div>
			</Col>
		);
	}

	render() {
		const { listOfClinicalStudy } = this.props;
		return (
			<>
				<div className="text-center">
					<Row className="p-0 m-0">
						{listOfClinicalStudy && listOfClinicalStudy.length > 0 ? 
							this.button("Make.Research.Better", "/home/clinicalStudy", homeImgClinicalStudy)
						:
							this.inactiveLink("No.Clinical.Study", homeImgClinicalStudy)
						}
					</Row>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		listOfClinicalStudy: state.clinicalStudy.listOfClinicalStudy
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getClinicalStudy: () => dispatch(getClinicalStudy())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PSHome);
