import React from 'react';
import { connect } from 'react-redux';
import StepZilla from 'react-stepzilla';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import moment from 'moment';

import AddPatientStep1 from './AddPatientStep1';
import AddPatientStep2 from './AddPatientStep2';
import AddPatientStep3 from './AddPatientStep3';
import AddPatientStep4 from './AddPatientStep4';
import AddPatientValidation from './AddPatientValidation';

import { addPatient } from "../../../actions/clinicalStudy/clinicalStudy";

/**
 * @description modal with step forms and a confirmation modal
 * @param clinicalStudy
 * @param isOpen
 */
export class AddPatientModal extends React.Component {
	constructor(props){
		super(props);
		
		this.state = {
			allowStepsNavigation: true,
			isOpenConfirmation: false,
			step: 0
		}

		this.store = {};

		this.getStore = this.getStore.bind(this);
		this.updateStore = this.updateStore.bind(this);
		this.resetStore = this.resetStore.bind(this);
		this.submitCbk = this.submitCbk.bind(this);
		this.onStepChange = this.onStepChange.bind(this);
		this.close = this.close.bind(this);
		this.confirm = this.confirm.bind(this);
		this.reject = this.reject.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isOpen !== this.props.isOpen && !this.props.isOpen) {
			this.resetStore();
		}
	}

	getStore() {
		return this.store;
	}

	updateStore(updatedStore, step) {
		this.store = {
			...this.store,
			...updatedStore,
		}

		this.setState({
			step
		})
	}

	resetStore() {
		this.store = {};
		this.setState({
			step: 0
		})
	}

	submitCbk(data, submitCbk) {
		const dataKeys = ["first_name", "name", "gender", "mobile_phone", "phone"];
		let patient = {
			doctor_id: this.props.user._id,
			address: this.store.google_address
		}
		patient.birth_date = moment(this.store.birth_date, 'DD-MM-YYYY');
		dataKeys.forEach(key => {
			patient[key] = this.store[key];
		});

		const formatCriteria = (crit) => {
			return {_id: crit._id, checked: crit.checked}
		};
		patient.criteria_inclusion = this.store.criteria_inclusion.map(formatCriteria);
		patient.criteria_non_inclusion = this.store.criteria_non_inclusion.map(formatCriteria);

		this.props.addPatient(this.props.clinicalStudy._id, patient, this.props.clinicalStudy.services[0]._id, submitCbk)
	}

	onStepChange(step) {
		if(step === 4) {
			this.setState({
				allowStepsNavigation: false
			})
		} else if (!this.state.allowStepsNavigation) {
			this.setState({
				allowStepsNavigation: true
			})
		}
	}

	close() {
		// Pour fermer automatiquement la modal quand on est sur l'étape de validation
		if (this.state.allowStepsNavigation) {
			this.setState({
				isOpenConfirmation: true
			})
		} else {
			this.props.close();
		}
	}

	confirm() {
		this.setState({
			isOpenConfirmation: false
		})
		this.props.close()
	}

	reject() {
		this.setState({
			isOpenConfirmation: false
		})
	}

	render() {
		const nameStep1 = <FormattedMessage id="Inclusion.Criteria" />;
		const nameStep2 = <FormattedMessage id="Not.Inclusion.Criteria" />;
		const nameStep3 = <FormattedMessage id="Patient.Info" />;
		const nameStep4 = <FormattedMessage id="Confirmation.2" />;
		const nameStep5 = <FormattedMessage id="Validation" />;

		const steps = [
			{ name: nameStep1, component: <AddPatientStep1 getStore={this.getStore} updateStore={this.updateStore} criteria={this.props.clinicalStudy.criteria_inclusion} cancelCbk={this.props.close} /> },
			{ name: nameStep2, component: <AddPatientStep2 getStore={this.getStore} updateStore={this.updateStore} criteria={this.props.clinicalStudy.criteria_non_inclusion} /> },
			{ name: nameStep3, component: <AddPatientStep3 error={this.props.error} getStore={this.getStore} updateStore={this.updateStore} /> },
			{ name: nameStep4, component: <AddPatientStep4 error={this.props.error} getStore={this.getStore} updateStore={this.updateStore} submitCbk={this.submitCbk} /> },
			{ name: nameStep5, component: <AddPatientValidation resetStore={this.resetStore} cancelCbk={this.props.close} /> }
		];

		return(
			<React.Fragment>
				<Modal size="lg" isOpen={this.props.isOpen && !this.state.isOpenConfirmation} toggle={this.close} className="simple-modal simple-modal-white srounded">
					<ModalHeader toggle={this.close}>
						Ajouter un patient à l'étude
					</ModalHeader>

					<ModalBody>
						<StepZilla
							steps={steps}
							stepsNavigation={this.state.allowStepsNavigation}
							preventEnterSubmission={true}
							showNavigation={false}
							onStepChange={this.onStepChange}
							startAtStep={this.state.step}
						/>
					</ModalBody>
				</Modal>

				<Modal size="md" isOpen={this.state.isOpenConfirmation} toggle={this.reject} className="simple-modal simple-modal-white srounded">
					<ModalHeader toggle={this.reject}>
						Êtes vous sûr ?
					</ModalHeader>

					<ModalBody>
						<p>Souhaitez vous fermer la fenêtre d'ajout de patient ?</p>
						<p>En quittant cette fenêtre, les données ne seront pas enregistrées.</p>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white mx-auto" onClick={this.confirm}>Je suis sûr</Button>
						<Button color="secondary" className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white mx-auto" onClick={this.reject}>Annuler</Button>
					</ModalFooter>
				</Modal>
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
        user: state.global.user,
		error: state.clinicalStudy.error
	}
}

function mapDispatchToProps(dispatch) {
	return {
		addPatient: (study_id, patient, center_id, cbk) => dispatch(addPatient(study_id, patient, center_id, cbk))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddPatientModal));