import moment from 'moment';
import OfficeTimeTableEnt from '../entities/OfficeTimeTableEnt';


export default class DateUtil {

    static toDate(date) {
        date = new Date(date);
        let dd = date.getDate();
        let MM = date.getMonth() + 1;
        const yyyy = date.getFullYear();

        if (dd < 10) dd = `0${dd}`;
        if (MM < 10) MM = `0${MM}`;

        return `${dd}/${MM}/${yyyy}`;
    }

    static toDateReverted(date) {
        date = new Date(date);
        let dd = date.getDate();
        let MM = date.getMonth() + 1;
        const yyyy = date.getFullYear();

        if (dd < 10) dd = `0${dd}`;
        if (MM < 10) MM = `0${MM}`;

        return `${MM}/${dd}/${yyyy}`;
    }

    static toyyyyMMdd(date) {
        if (!date) return "";

        var inputDate = new Date(date);

        var year = inputDate.getFullYear();
        var month = inputDate.getMonth() + 1;
        var day = inputDate.getDate();

        if (month < 10) month = "0" + month;
        if (day < 10) day = "0" + day;

        var formattedDate = `${year}-${month}-${day}`;

        return formattedDate;
    }

    static addMonths(isoDate, numberMonths) {
        var dateObject = new Date(isoDate),
            day = dateObject.getDate(); // returns day of the month number

        // avoid date calculation errors
        dateObject.setHours(20);

        // add months and set date to last day of the correct month
        dateObject.setMonth(dateObject.getMonth() + numberMonths + 1, 0);

        // set day number to min of either the original one or last day of month
        dateObject.setDate(Math.min(day, dateObject.getDate()));

        return dateObject.toISOString().split('T')[0];
    }

    static toDateWithHour(date) {
        date = new Date(date);
        let dd = date.getDate();
        let MM = date.getMonth() + 1;
        const yyyy = date.getFullYear();

        if (dd < 10) dd = `0${dd}`;
        if (MM < 10) MM = `0${MM}`;

        let hh = date.getHours();
        let mm = date.getMinutes();

        if (mm < 10) mm = `0${mm}`;

        return `${dd}/${MM}/${yyyy} ${hh}:${mm}`;
    }

    static toHour(date) {
        date = new Date(date);

        let hh = date.getHours();
        let mm = date.getMinutes();
        let ss = date.getSeconds();

        if (mm < 10) mm = `0${mm}`;

        return `${hh}:${mm}:${ss}`;
    }

    static toHourWithoutSeconds(date) {
        date = new Date(date);

        let hh = date.getHours();
        let mm = date.getMinutes();

        if (mm < 10) mm = `0${mm}`;

        return `${hh}:${mm}`;
    }

    static inThePast(date) {
        return new Date(date) < new Date().setHours(0, 0, 0, 0);
    }

    static isDay(date, day) {
        return new Date(date).getDay() === day;
    }

    static sameDay(date, days) {
        let sameDay = false;
        if (!Array.isArray(days)) days = [days];
        days.forEach((day) => {
            if (new Date(date).getDay() === DateUtil.dayToNbr(day)) {
                return sameDay = true;
            }
        })
        return sameDay;
    }

    static before(date1, date2) {
        return new Date(date1) < new Date(date2);
    }

    static beforeNoStrict(date1, date2) {
        return new Date(date1) <= new Date(date2);
    }

    static after(date1, date2) {
        return new Date(date1) > new Date(date2);
    }

    static afterNoStrict(date1, date2) {
        return new Date(date1) >= new Date(date2);
    }

    static startOfWeek(date) {
        return moment(date).startOf('week').toDate();
    }

    static endOfWeek(date) {
        return moment(date).endOf('week').toDate();
    }

    static startOfMonth(date) {
        return moment(date).startOf('month').toDate();
    }

    static endOfMonth(date) {
        return moment(date).endOf('month').toDate();
    }

    static days(start_date, end_date) {
        if (!start_date || !end_date) return 0;

        if (DateUtil.isSameDay(start_date, end_date)) return 1;

        var oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
        var nbrDays = Math.round(Math.abs((new Date(start_date).getTime() - new Date(end_date).getTime()) / (oneDay)));
        return Math.max(1, nbrDays + 1);
    }

    static hours(start_date, end_date) {
        if (!start_date || !end_date) return 0;
        var oneHour = 60 * 60 * 1000; // minutes * seconds * milliseconds
        var nbrHours = Math.round((new Date(end_date).getTime() - new Date(start_date).getTime()) / (oneHour));
        return nbrHours;
    }

    static years(date0, date1) {
        return new Date(new Date(date0) - new Date(date1)).getFullYear() - 1970;
    }

    static overlapStrict(start0, end0, start1, end1) {
        start0 = new Date(start0);
        end0 = new Date(end0);
        start1 = new Date(start1);
        end1 = new Date(end1);

        if (start0 <= start1 && start1 <= end0) return true; // b starts in a
        if (start0 <= end1 && end1 <= end0) return true; // b ends in a
        if (start1 < start0 && end0 < end1) return true; // a in b
        return false;
    }

    static overlap(start0, end0, start1, end1) {
        start0 = new Date(start0);
        end0 = new Date(end0);
        start1 = new Date(start1);
        end1 = new Date(end1);

        if (start0 < start1 && start1 < end0) return true; // b starts in a
        if (start0 < end1 && end1 <= end0) return true; // b ends in a
        if (start1 <= start0 && end0 < end1) return true; // a in b
        return false;
    }

    static nbrToDay(day) {
        if (day === 0) return "sunday";
        else if (day === 1) return "monday";
        else if (day === 2) return "tuesday";
        else if (day === 3) return "wednesday";
        else if (day === 4) return "thursday";
        else if (day === 5) return "friday";
        else if (day === 6) return "saturday";
    }

    static dayToNbr(day) {
        if (day === "sunday") return 0;
        else if (day === "monday") return 1;
        else if (day === "tuesday") return 2;
        else if (day === "wednesday") return 3;
        else if (day === "thursday") return 4;
        else if (day === "friday") return 5;
        else if (day === "saturday") return 6;
    }

    static nextDay(day, date = new Date()) {
        date = new Date(date);
        date.setDate(date.getDate() + (day + 7 - date.getDay()) % 7);
        return date;
    }

    static day(date) {
        return new Date(date).getDay();
    }

    static hour(date) {
        return new Date(date).getHours();
    }

    static isSameOrNextDay(date1, date2) {
        return (DateUtil.isSameDay(date1, date2) || DateUtil.isNextDay(date1, date2));
    }

    static isSameDay(date1, date2) {
        var date1Strict = new Date(date1);
        date1Strict.setHours(0, 0, 0, 0);

        var date2Strict = new Date(date2);
        date2Strict.setHours(0, 0, 0, 0);

        return (date1Strict.getTime() === date2Strict.getTime());
    }

    static isNextDay(date1, date2) {
        var date1Strict = new Date(date1);
        date1Strict.setHours(0, 0, 0, 0);

        var date2Strict = new Date(date2);
        date2Strict.setHours(0, 0, 0, 0);

        return (date2Strict.getTime() - date1Strict.getTime() === 24 * 3600 * 1000);
    }

    static isMidnight(date) {
        return (new Date(date).getHours() === 0 &&
            new Date(date).getMinutes() === 0 &&
            new Date(date).getSeconds() === 0 &&
            new Date(date).getMilliseconds() === 0)
    }

    static incrementDays(date, days) {
        let t = date;
        t.setDate(t.getDate() + days);
        return `${t.getFullYear()}-${String(t.getMonth() + 1).padStart(2, '0')}-${String(
            t.getDate()
        ).padStart(2, '0')}`;
    }

    static decrementDays(date, days) {
        let t = date;
        t.setDate(t.getDate() - days);
        return `${t.getFullYear()}-${String(t.getMonth() + 1).padStart(2, '0')}-${String(
            t.getDate()
        ).padStart(2, '0')}`;
    }

    static dateAndTimeToDate(date, time) {
        var d = new Date(date);
        var times = time.split(":");
        d.setHours(times[0], times[1], times[2] || "00");

        return d;
    }

    static theDayBefore(date) {
        date = new Date(date);

        var yesterday = new Date(date);
        yesterday.setDate(date.getDate() - 1);

        var yesterDayFormated = new Date(yesterday);
        return yesterDayFormated;
    }

    static theDayAfter(date) {
        date = new Date(date);

        var tomorrow = new Date(date);
        tomorrow.setDate(date.getDate() + 1);

        var tomorrowDayFormated = new Date(tomorrow);
        return tomorrowDayFormated;
    }

    static firstDayPreviousMonth(date) {
        date = new Date(date);
        var previousMonth = new Date(date);

        previousMonth.setMonth(date.getMonth() - 1);
        previousMonth.setHours(0, 0, 0, 0);
        previousMonth.setDate(1);

        var previousMonthFormated = new Date(previousMonth);
        return previousMonthFormated;
    }

    static firstDayNextMonth(date) {
        date = new Date(date);
        var nextMonth = new Date(date);

        nextMonth.setMonth(date.getMonth() + 1);
        nextMonth.setHours(0, 0, 0, 0);
        nextMonth.setDate(1);

        var nextMonthFormated = new Date(nextMonth);
        return nextMonthFormated;
    }


    static lastDayOfPreviousMonth(date) {
        let startDate = new Date(date);

        let month = startDate.getMonth();
        let fullyear = startDate.getFullYear();

        let endDate = new Date(fullyear, month, 0);

        return endDate;
    }

    static lastDayOfCurrentMonth(date) {
        let startDate = new Date(date);

        let month = startDate.getMonth();
        let fullyear = startDate.getFullYear();

        let endDate = new Date(fullyear, month + 1, 0);

        return endDate;
    }

    static lastDayOfNextMonth(date) {
        let startDate = new Date(date);

        let month = startDate.getMonth();
        let fullyear = startDate.getFullYear();

        let endDate = new Date(fullyear, month + 2, 0);

        return endDate;
    }

    static getWeekDayNumberForCalendar(dayString) {
        let weekDayNumber = "";

        switch (dayString) {
            case "monday": weekDayNumber = 1; break;
            case "tuesday": weekDayNumber = 2; break;
            case "wednesday": weekDayNumber = 3; break;
            case "thursday": weekDayNumber = 4; break;
            case "friday": weekDayNumber = 5; break;
            case "saturday": weekDayNumber = 6; break;
            case "sunday": weekDayNumber = 7; break;
            default: weekDayNumber = 0;
        }

        return weekDayNumber;
    }

    static greatest(d0, d1) {
        if (!d0) return d1;
        if (!d1) return d0;

        return (new Date(d0).getTime() > new Date(d1).getTime()) ? new Date(d0) : new Date(d1);
    }

    static lowest(d0, d1) {
        if (!d0) return d1;
        if (!d1) return d0;

        return (new Date(d0).getTime() < new Date(d1).getTime()) ? new Date(d0) : new Date(d1);
    }

    static daysTo(date) {
        function datediff(first, second) {
            return Math.round((second - first) / (1000 * 60 * 60 * 24));
        }

        return datediff(new Date(), new Date(date));
    }

    static age(birthday) {
        var ageDifMs = Date.now() - new Date(birthday).getTime();
        var ageDate = new Date(ageDifMs);

        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    static daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    static stringifyMonth(month) {
        let monthToReturn = "";
        switch (month) {
            case 0: monthToReturn = "Janvier"; break;
            case 1: monthToReturn = "Février"; break;
            case 2: monthToReturn = "Mars"; break;
            case 3: monthToReturn = "Avril"; break;
            case 4: monthToReturn = "Mai"; break;
            case 5: monthToReturn = "Juin"; break;
            case 6: monthToReturn = "Juillet"; break;
            case 7: monthToReturn = "Août"; break;
            case 8: monthToReturn = "Septembre"; break;
            case 9: monthToReturn = "Octobre"; break;
            case 10: monthToReturn = "Novembre"; break;
            case 11: monthToReturn = "Décembre"; break;
            default: monthToReturn = 0;
        }
        return monthToReturn;
    }

    static parseHour(hour) {
        let hourToNumber = parseInt(hour);

        if (hourToNumber <= 9) hourToNumber = `0${hourToNumber}`;

        return hourToNumber;
    }

    static parseMinutes(minutes) {
        let minutesToNumber = parseInt(minutes);

        if (minutesToNumber < 10) minutesToNumber = `0${minutesToNumber}`;

        return minutesToNumber;
    }


    static stringToDate(string, date) {
        const parts = string.split(':');

        const dateToReturn = new Date(date).setHours(parts[0], parts[1]);
        return dateToReturn;
    }

    static dateToString(date) {
        date = new Date(date);
        const hour = date.getHours();
        let minutes = date.getMinutes();

        if (minutes < 9) minutes = `0${minutes}`;

        return `${this.parseHour(hour)}:${minutes}`
    }

    static toNoon = (date) => {
        var result = new Date(date);
        result.setHours(0, 0, 0, 0);

        return result;
    };

    static toMidnight = (date) => {
        var result = new Date(date);
        result.setHours(23, 59, 59, 999);

        return result;
    };

    static roundMinutes(date) {
        date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
        date.setMinutes(0);

        return date;
    }

    static nowMinusDays = (days) => {
        var d = new Date();
        d.setDate(d.getDate() - days);
        
        return d;
    }

    static nowPlusDays = (days) => {
        var d = new Date();
        d.setDate(d.getDate() + days);
        
        return d;
    }

    static getDaysAvailable = (start, end, type, day, daysNotAvailable, officeTimeTable) => {
        let days_available = [];
        let startDate = moment(start);
        let endDate = moment(end);
        while(startDate.isSameOrBefore(endDate, 'day')) {
            if (startDate.day() !== 0 && type !== 'regular') {
                let startDateTemp = moment(startDate);
                let endDateTemp = moment(startDate).hours(23).minutes(59).seconds(59);
                if (officeTimeTable) {
                    let scheduleForDay = OfficeTimeTableEnt.getScheduleForDay(type, officeTimeTable, startDate.day());
                    if (scheduleForDay[0].start) {
                        if (scheduleForDay[1].start) {
                            let splitHoursStart0 = scheduleForDay[0].start.slice(0, 2);
                            let splitMinutesStart0 = scheduleForDay[0].start.slice(3, 5);
                            let splitHoursStart1 = scheduleForDay[1].start.slice(0, 2);
                            let splitMinutesStart1 = scheduleForDay[1].start.slice(3, 5);
                            // Compare pour prendre la plus petite heure de début
                            if (splitHoursStart0 < splitHoursStart1) {
                                startDateTemp = startDateTemp.hours(splitHoursStart0).minutes(splitMinutesStart0).seconds(0);
                            } else {
                                startDateTemp = startDateTemp.hours(splitHoursStart1).minutes(splitMinutesStart1).seconds(0);
                            }

                            let splitHoursEnd0 = scheduleForDay[0].end.slice(0, 2);
                            let splitMinutesEnd0 = scheduleForDay[0].end.slice(3, 5);
                            let splitHoursEnd1 = scheduleForDay[1].end.slice(0, 2);
                            let splitMinutesEnd1 = scheduleForDay[1].end.slice(3, 5);
                            // Compare pour prendre la plus grande heure de fin
                            if (splitHoursEnd0 > splitHoursEnd1) {
                                endDateTemp = endDateTemp.hours(splitHoursEnd0).minutes(splitMinutesEnd0).seconds(0);
                            } else {
                                endDateTemp = endDateTemp.hours(splitHoursEnd1).minutes(splitMinutesEnd1).seconds(0);
                            }
                        } else {
                            let splitHoursStart = scheduleForDay[0].start.slice(0, 2);
                            let splitMinutesStart = scheduleForDay[0].start.slice(3, 5);
                            let splitHoursEnd = scheduleForDay[0].end.slice(0, 2);
                            let splitMinutesEnd = scheduleForDay[0].end.slice(3, 5);
                            startDateTemp = startDateTemp.hours(splitHoursStart).minutes(splitMinutesStart).seconds(0);
                            endDateTemp = endDateTemp.hours(splitHoursEnd).minutes(splitMinutesEnd).seconds(0);
                        }
                    } else {
                        // Si pas d'horaire le jour concerné, on fixe les horaires à 09h00-17h00
                        startDateTemp = startDateTemp.hours(9).minutes(0);
                        endDateTemp = endDateTemp.hours(17).minutes(0).seconds(0);
                    }
                } 
                days_available.push({
                    start: startDateTemp.toDate(),
                    end: endDateTemp.toDate()
                });
            } else if (startDate.day() === 0 && type === 'guard') {
                let startDateTemp = moment(startDate);
                let endDateTemp = moment(startDate);
                days_available.push({
                    start: startDateTemp.toDate(),
                    end: endDateTemp.hours(23).minutes(59).seconds(59).toDate()
                });
            } else if (startDate.day() !== 0 && type === 'regular') {
                let push = false;
                if (day === 'monday' && startDate.day() === 1) {
                    push = true;
                }
                if (day === 'tuesday' && startDate.day() === 2) {
                    push = true;
                } 
                if (day === 'wednesday' && startDate.day() === 3) {
                    push = true;
                }
                if (day === 'thursday' && startDate.day() === 4) {
                    push = true;
                } 
                if (day === 'friday' && startDate.day() === 5) {
                    push = true;
                } 
                if (day === 'saturday' && startDate.day() === 6) {
                    push = true;
                } 
                if (push === true) {
                    let startDateTemp = moment(startDate);
                    let endDateTemp = moment(startDate).hours(23).minutes(59).seconds(59);
                    if (officeTimeTable) {
                        let scheduleForDay = OfficeTimeTableEnt.getScheduleForDay(type, officeTimeTable, startDate.day());
                        if (scheduleForDay[0].start) {
                            if (scheduleForDay[1].start) {
                                let splitHoursStart0 = scheduleForDay[0].start.slice(0, 2);
                                let splitMinutesStart0 = scheduleForDay[0].start.slice(3, 5);
                                let splitHoursStart1 = scheduleForDay[1].start.slice(0, 2);
                                let splitMinutesStart1 = scheduleForDay[1].start.slice(3, 5);
                                if (splitHoursStart0 < splitHoursStart1) {
                                    startDateTemp = startDateTemp.hours(splitHoursStart0).minutes(splitMinutesStart0).seconds(0);
                                } else {
                                    startDateTemp = startDateTemp.hours(splitHoursStart1).minutes(splitMinutesStart1).seconds(0);
                                }

                                let splitHoursEnd0 = scheduleForDay[0].end.slice(0, 2);
                                let splitMinutesEnd0 = scheduleForDay[0].end.slice(3, 5);
                                let splitHoursEnd1 = scheduleForDay[1].end.slice(0, 2);
                                let splitMinutesEnd1 = scheduleForDay[1].end.slice(3, 5);
                                if (splitHoursEnd0 > splitHoursEnd1) {
                                    endDateTemp = endDateTemp.hours(splitHoursEnd0).minutes(splitMinutesEnd0).seconds(0);
                                } else {
                                    endDateTemp = endDateTemp.hours(splitHoursEnd1).minutes(splitMinutesEnd1).seconds(0);
                                }
                            } else {
                                let splitHoursStart = scheduleForDay[0].start.slice(0, 2);
                                let splitMinutesStart = scheduleForDay[0].start.slice(3, 5);
                                let splitHoursEnd = scheduleForDay[0].end.slice(0, 2);
                                let splitMinutesEnd = scheduleForDay[0].end.slice(3, 5);
                                startDateTemp = startDateTemp.hours(splitHoursStart).minutes(splitMinutesStart).seconds(0);
                                endDateTemp = endDateTemp.hours(splitHoursEnd).minutes(splitMinutesEnd).seconds(0);
                            }
                        } else {
                            // Si pas d'horaire le jour concerné, on fixe les horaires à 09h00-17h00
                            startDateTemp = startDateTemp.hours(9).minutes(0);
                            endDateTemp = endDateTemp.hours(17).minutes(0).seconds(0);
                        }
                    } 
                    days_available.push({
                        start: startDateTemp.toDate(),
                        end: endDateTemp.toDate()
                    });
                }
            }
            startDate.add(1, 'day');
        }

        let daysAvailableFilter = Array.from(days_available);

        if (daysNotAvailable && daysNotAvailable.length > 0) {
            daysAvailableFilter = days_available.filter((elem) => {
                return !daysNotAvailable.find((dayNotAvailable) => {
                    return moment(elem.start).isSame(dayNotAvailable.start, 'day');
                })
            })
        }

        return daysAvailableFilter;
    }

    static getDaysAvailableGuard = (start, end, officeTimeTable) => {

        let days_available = [{
            start: start,
            end: end
        }];
        return days_available;
    }


    static startAndEndDays = (days_available) => {
        let daysAvailableTemp = Array.from(days_available);
        if (daysAvailableTemp.length === 0) {
            return {
                start: null,
                end: null
            };
        } 
        daysAvailableTemp.sort(function(a, b){
            return moment(a.start).isBefore(moment(b.start)) ? -1 : 1;
        });

        let startAndEndDays = {
            start: daysAvailableTemp[0].start,
            end: daysAvailableTemp[daysAvailableTemp.length - 1].end
        }

        return startAndEndDays;
    }

    static sortDaysAvailable = (days_available) => {
        let daysAvailableTemp = Array.from(days_available);

        daysAvailableTemp.sort(function(a, b){
            return moment(a.start).isBefore(moment(b.start)) ? -1 : 1;
        });
        return daysAvailableTemp;
    }

    static getDaysAvailableForEvent = (start, end) => {
        let days_available = [];
        let startDate = moment(start);
        let endDate = moment(end);
        while(startDate.isSameOrBefore(endDate)) {
            let startDateTemp = moment(startDate);
            let endDateTemp = moment(startDate);
            days_available.push({
                start: startDateTemp.toDate(),
                end: endDateTemp.hours(23).minutes(59).seconds(59).toDate()
            });
            startDate.add(1, 'day');
        }

        let endDaysAvailable = days_available[days_available.length - 1].end;
        // Le dernier jour, on set l'heure et les minutes choisi par le user
        days_available[days_available.length - 1].end = moment(endDaysAvailable).hours(moment(end).hours()).minutes(moment(end).minutes());        
        return days_available;
    }

    static getDaysAvailableFromInvitation = (start, end, days_available, officeTimeTable , type) => {
        // transformer en créneau
        let startDate = moment(start);
        let endDate = moment(end).hours(23).minutes(59).seconds(59);
        let daysAvailableSort = days_available.sort(function(a, b){
            return moment(a.start).isBefore(moment(b.start)) ? -1 : 1;
        });
        let daysAvailableFilter = [];
        daysAvailableSort.forEach((elem) => {
            let day = moment(elem.start);
            if (day.isSameOrAfter(startDate, 'day') && day.isSameOrBefore((endDate))) {

                let startDateTemp = moment(elem.start);
                let endDateTemp = moment(elem.end).hours(23).minutes(59).seconds(59);
                if (officeTimeTable) {
                    let scheduleForDay = OfficeTimeTableEnt.getScheduleForDay(type, officeTimeTable, startDate.day());
                    if (scheduleForDay[0].start) {
                        if (scheduleForDay[1].start) {
                            let splitHoursStart0 = scheduleForDay[0].start.slice(0, 2);
                            let splitMinutesStart0 = scheduleForDay[0].start.slice(3, 5);
                            let splitHoursStart1 = scheduleForDay[1].start.slice(0, 2);
                            let splitMinutesStart1 = scheduleForDay[1].start.slice(3, 5);
                            if (splitHoursStart0 < splitHoursStart1) {
                                startDateTemp = startDateTemp.hours(splitHoursStart0).minutes(splitMinutesStart0).seconds(0);
                            } else {
                                startDateTemp = startDateTemp.hours(splitHoursStart1).minutes(splitMinutesStart1).seconds(0);
                            }

                            let splitHoursEnd0 = scheduleForDay[0].end.slice(0, 2);
                            let splitMinutesEnd0 = scheduleForDay[0].end.slice(3, 5);
                            let splitHoursEnd1 = scheduleForDay[1].end.slice(0, 2);
                            let splitMinutesEnd1 = scheduleForDay[1].end.slice(3, 5);
                            if (splitHoursEnd0 > splitHoursEnd1) {
                                endDateTemp = endDateTemp.hours(splitHoursEnd0).minutes(splitMinutesEnd0).seconds(0);
                            } else {
                                endDateTemp = endDateTemp.hours(splitHoursEnd1).minutes(splitMinutesEnd1).seconds(0);
                            }
                        } else {
                            let splitHoursStart = scheduleForDay[0].start.slice(0, 2);
                            let splitMinutesStart = scheduleForDay[0].start.slice(3, 5);
                            let splitHoursEnd = scheduleForDay[0].end.slice(0, 2);
                            let splitMinutesEnd = scheduleForDay[0].end.slice(3, 5);
                            startDateTemp = startDateTemp.hours(splitHoursStart).minutes(splitMinutesStart).seconds(0);
                            endDateTemp = endDateTemp.hours(splitHoursEnd).minutes(splitMinutesEnd).seconds(0);
                        }
                    } else {
                        // Si pas d'horaire le jour concerné, on fixe les horaires à 09h00-17h00
                        startDateTemp = startDateTemp.hours(9).minutes(0);
                        endDateTemp = endDateTemp.hours(17).minutes(0).seconds(0);
                    }
                } 
                
                let day = {
                    start: startDateTemp.toDate(),
                    end: endDateTemp.toDate()
                };
                daysAvailableFilter.push(day);
            }
        })

        return daysAvailableFilter;
    }

    static getDaysNegociation = (days_available_original, days_available_negociation) => {
        const results = days_available_original.filter(({ start: id1 }) => {
            return !days_available_negociation.some(({ start: id2 }) => {
                return moment(id2).isSame(id1, 'day');
            }) 
        });
        return results;
    }

}