export default class UserUtil{

    static setCharacs = (newCharacs, characs, substitute = false) => { // Set obligatories values for registration after internal registration
        let obligatoryKeys = ["location", "office_address_city", "office_address_postcode", "office_address_street", "address_components"];
        if(substitute) obligatoryKeys = ["location", "address_city", "address_postcode", "address_street", "address_components"];

        
        obligatoryKeys.forEach(key => {
            newCharacs[key] = characs[key];
        });
    }


    static isRoleTemporary(role) {
        if(role.startsWith('temporary')) return true;
        else return false;
    }

}   