import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { allByDoctor } from '../../actions/replacements/replacements';
import { getsBy, clears } from '../../actions/global/global';
import DCalendar from '../doctors/calendar/DCalendar';
import DCalendarWeeks from '../doctors/appointments/DCalendarWeeks';
// import SecretaryCalendar from '../secretaries/calendar/SecretaryCalendar';
import SecretaryCalendarWeeks from '../secretaries/appointments/SecretaryCalendarWeeks';
import SCalendar from '../substitutes/calendar/SCalendar';
import SCalendarWeeks from '../substitutes/appointments/SCalendarWeeks';
import DateUtil from '../../util/DateUtil';
import moment from 'moment';

class CalendarController extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // showCalendar: false,
            // showCalendarWeeks: true,
            // view: 'week'
            officeTimeTable: null,
            showCalendar: false,
            showCalendarWeeks: true,
            view: 'week'
        };

        this.changeView = this.changeView.bind(this);
    };

    componentDidMount() {
        let startDate = DateUtil.decrementDays(DateUtil.startOfMonth(new Date()), 7);
        let endDate = moment().add(1, 'year').format('YYYY-MM-DD');

        this.props.onGetsBy("calendarEvent", ["start_date", "end_date"], [startDate, endDate]);
        if (this.props.role === 'doctor') {
            this.props.allByDoctor();
        }

        if (this.props.role === 'substitute') {
            this.props.onGetsBy("availability", ["start_date", "end_date"], [startDate, endDate]);
        }
    }

    changeView(view) {
        if (view === 'week') {
            this.setState({
                showCalendar: false,
                showCalendarWeeks: true,
                view: 'week'
            })
        } else if (view === 'month') {
            this.setState({
                showCalendar: true,
                showCalendarWeeks: false,
                view: 'month'
            })
        } else {
            this.setState({
                showCalendar: true,
                showCalendarWeeks: false,
                view: 'year'
            })
        }
    }

    render() {
        const { calendarEvents, replacements, availabilitys, data } = this.props;
        // console.log("eeee", this.props)
        return (
            <React.Fragment>
                {this.props.role === 'doctor' &&
                    <>
                        {this.state.showCalendar === true &&
                            <DCalendar
                                changeView={this.changeView}
                                view={this.state.view}
                                calendarEvents={calendarEvents}
                                replacements={replacements}
                            />
                        }

                        {this.state.showCalendarWeeks === true &&
                            <DCalendarWeeks
                                changeView={this.changeView}
                                calendarEvents={calendarEvents}
                                replacements={replacements}
                            />
                        }
                    </>
                }

                {this.props.role === 'substitute' &&
                    <>
                        {this.state.showCalendar === true &&
                            <SCalendar
                                changeView={this.changeView}
                                view={this.state.view}
                                calendarEvents={calendarEvents}
                                availabilitys={availabilitys}
                            />
                        }

                        {this.state.showCalendarWeeks === true &&
                            <SCalendarWeeks
                                changeView={this.changeView}
                                calendarEvents={calendarEvents}
                                availabilitys={availabilitys}
                            />
                        }
                    </>
                }

                {this.props.role === 'secretary' &&
                    <>
                        {/* {this.state.showCalendarWeeks === true &&  */}
                            <SecretaryCalendarWeeks
                                changeView={this.changeView}
                                calendarEvents={calendarEvents}
                                replacements={replacements}
                                userData={data}
                            />
                        {/* } */}

                        {/* {this.state.showCalendar === true && 
                            <SecretaryCalendar
                                changeView={this.changeView}
                                calendarEvents={calendarEvents}
                                replacements={replacements}
                                secretaryManagements={secretaryManagements}
                        />} */}
                        

                    </>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        calendarEvents: state.global.calendarEvents,
        replacements: state.replacements.replacements,
        availabilitys: state.global.availabilitys,
        secretaryManagements: state.global.secretaryManagements
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),
        allByDoctor: () => dispatch(allByDoctor()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CalendarController));
