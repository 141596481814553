import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Row, Col } from "reactstrap";
import homeImgAnnonce from '../assets/images/homes/HYDROGEN_ILLU_ANNONCE.png';
import homeImgPlanning from '../assets/images/homes/HYDROGEN_ILLU_PLANNING.png';


export default class InfoModal extends React.Component {

    render() {
        const { user } = this.props;

        let title, content, button;

        if (user.role === "doctor") {
            title = <FormattedMessage id="DInfoModal.Title" />;
            content = <FormattedMessage id="DInfoModal.Content" />;
            button = (
                <Row className="p-0 m-0">
                    <Col xs="12" className="p-0 m-0">

                        <div className="text-center mb-2 mx-auto" style={{ width: 200 }}>
                            <div className="img-btn mb-3 mx-auto clickable"
                                onClick={() => this.props.toggle(() => { window.location.href = "#/home/me/replacements/public/add" })}
                                style={{ width: 150, height: 150 }}>
                                <img src={homeImgAnnonce} alt="" width={150} height={150} />
                            </div>

                            <div className="uppercase text-white"><FormattedHTMLMessage id="Post.New.Replacement" /></div>
                        </div>

                    </Col>
                </Row>
            );
        }
        else if (user.role === "substitute") {
            title = <FormattedMessage id="SInfoModal.Title" />;
            content = <FormattedMessage id="SInfoModal.Content" />;
            button = (
                <Row className="p-0 m-0">
                    <Col xs="12" className="p-0 m-0">

                        <div className="text-center mb-2 mx-auto" style={{ width: 200 }}>
                            <div className="img-btn mb-3 mx-auto clickable"
                                onClick={() => this.props.toggle(() => { window.location.href = "#/home/scalendar/add" })}
                                style={{ width: 150, height: 150 }}>
                                <img src={homeImgPlanning} alt="" width={150} height={150} />
                            </div>

                            <div className="uppercase text-white"><FormattedHTMLMessage id="Post.New.Availability" /></div>
                        </div>

                    </Col>
                </Row>
            );
        }

        return (
            <Modal isOpen={true} toggle={() => this.props.toggle()} size="md" className="simple-modal simple-modal-blue srounded">

                <ModalHeader toggle={() => this.props.toggle()}>
                    <button type="button" className="close d-inline-block b-green" aria-label="Close" onClick={() => this.props.toggle()} ><span aria-hidden="true">×</span></button>
                </ModalHeader>

                <ModalBody className="p-4">

                    <h3 className="mb-4">{title}</h3>

                    <p>{content}</p>

                    {button}
{/* 
                    <div className="mt-4 mb-3">
                        <Button
                            className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-blue bg-white btn opacity-1 mr-0"
                            onClick={() => this.props.toggle()}>
                            <FormattedMessage id="Not.Yet" />&nbsp;!
                        </Button>
                    </div> */}

                </ModalBody>

            </Modal>
        );

    }

}
