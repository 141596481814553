import React, { Component } from 'react';
import { connect } from 'react-redux';
import { adds, upds } from '../../../../actions/global/global'
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, Row, Col, Collapse } from 'reactstrap';
import { Form, TextInput, DecimalInput, RadioInput, NotEmpty, TimeInput } from '@gferrand/react-forms';
import DateUtil from '../../../../util/DateUtil';
import AccountingEnt from '../../../../entities/AccountingEnt';
import CheckboxInput from '../../../../components/form/CheckBoxInput';


class AccountingDailyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {}

        this.isUpdate = (!!this.props.patient);


        var submitCbk = (data, endCbk) => {
            data.categorie = 'consultation';
            data.date = DateUtil.stringToDate(data.date, this.props.startDate)

            const successCbk = () => {
                endCbk();
                if (this.props.onSuccessCbk) this.props.onSuccessCbk();
                this.props.closeModal();
            };

            if (this.isUpdate) {
                data._id = this.props.patient._id;
                this.props.onUpds("accounting", data, () => successCbk());

            } else {
                if (this.props.user.role === "doctor") return this.props.onAdds("accounting/doctor", data, () => successCbk());
                else return this.props.onAdds("accounting/substitute", data, () => successCbk());
            }
        };

        const paymentMethods = [
            { label: "Bank.Card", value: "credit_card" },
            { label: "Check", value: "cheque" },
            { label: "Cash", value: "cash" },
            { label: "Social.Safety", value: "secu" }
        ]

        const defaultValues = {
            date: `${DateUtil.parseHour(new Date().getHours())}:${DateUtil.parseMinutes(new Date().getMinutes())}`,
        }

        const changeCbk = (a) => {
            if (a.name === "payment_methods") {
                if (a.value === "secu") this.form.hide("third_party_payer")
                else this.form.show("third_party_payer")
            }

            this.forceUpdate();
        };

        this.form = new Form({
            name: "user",
            inputs: [
                new TextInput("patient", "Patient", undefined, [NotEmpty], { placeholder: "Patient.Name" }),
                new TimeInput("date", "Start.DateTime", [NotEmpty]),
                new TextInput("diagnosis", "Diagnosis.Form", undefined, [], { placeholder: "Transmission" }),
                new DecimalInput("total", "Amount", [NotEmpty]),
                new RadioInput("payment_methods", 'Payment.Methods', paymentMethods, [NotEmpty],
                    { groupClassName: "text-center", className: "text-center srounded mr-3 pl-4 pr-4 lowercase bg-blue text-white mb-1 mb-sm-0" }),
                new RadioInput("third_party_payer", "Third.Party.Payment", [{ label: "Yes", value: true }, { label: "No", value: false }], [],
                    { groupClassName: " ", className: "srounded mr-3 pl-4 pr-4 lowercase bg-blue text-white mb-1 mb-sm-0" }),
                new CheckboxInput("vital_card", "Patient.Without.Vital.Card", undefined),
                new CheckboxInput("pay_later", "Patient.Will.Pay.Later", undefined),
            ],
            submitCbk,
            changeCbk,
            options: { validateAtStart: false },
            data: this.props.patient ? AccountingEnt.parsePatientDate(this.props.patient) : defaultValues
        });
    }

    render() {
        const rawData = this.form.getRawData();
        const showThirdPartyInput = (!rawData.payment_methods || rawData.payment_methods !== "secu");

        return (
            <Modal isOpen={true} size="md" toggle={() => this.props.closeModal()} className="simple-modal simple-modal-white srounded">

                <ModalHeader toggle={() => this.props.closeModal()}>
                </ModalHeader>

                <ModalBody className="pl-2 pr-2 pl-md-5 pr-md-5 pt-0">
                    <h4 className="w-100 uppercase text-center pb-4"><FormattedMessage id="New.Consultation" /></h4>

                    <div className="mx-auto" style={{ maxWidth: "450px" }}>

                        <div className="mb-3">
                            {this.form.getInput("patient")}
                        </div>

                        <div className="mb-3">
                            {this.form.getInput("date")}
                        </div>

                        <div className="mb-3">
                            {this.form.getInput("diagnosis")}
                        </div>

                        <div className="mb-3">
                            <Row className="form-group text-left forceLeft">
                                <Col md="4">{this.form.getLabel("total")}</Col>
                                <Col md="8">{this.form.getInput("total")}</Col>
                            </Row>
                        </div>

                        <div className="mb-3 text-center errorCentered">
                            {this.form.getInput("payment_methods")}
                        </div>

                        <Collapse isOpen={showThirdPartyInput}>
                            <div className="mb-3">
                                <Row className="form-group text-left forceLeft">
                                    <Col md="4">{this.form.getLabel("third_party_payer")}</Col>
                                    <Col md="8">{this.form.getInput("third_party_payer")}</Col>
                                </Row>
                            </div>
                        </Collapse>

                        <div className="mb-3">
                            <Row className="form-group text-left forceLeft">
                                <Col md="1">{this.form.getInput("vital_card")}</Col>
                                <Col md="10">{this.form.getLabel("vital_card")}</Col>
                            </Row>
                        </div>


                        <div className="mb-3">
                            <Row className="form-group text-left forceLeft">
                                <Col md="1">{this.form.getInput("pay_later")}</Col>
                                <Col md="10">{this.form.getLabel("pay_later")}</Col>
                            </Row>
                        </div>

                        <div className="text-center form-buttons w-100 mt-5 mb-4">
                            {this.form.submitButton("Add.2", { className: "srounded pl-5 pr-5 pt-2 pb-2 text-white bg-blue min-w-200px min-h-60px bold lp-2" })}
                        </div>

                    </div>

                </ModalBody>

            </Modal>
        );
    }
}
const mapStateToProps = state => {
    return {
        //
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAdds: (objName, obj, cbk) => dispatch(adds(objName, obj, cbk)),
        onUpds: (objName, obj, cbk) => dispatch(upds(objName, obj, cbk))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountingDailyModal);