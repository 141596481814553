import DateUtil from "../util/DateUtil";


export default class AvailabilityEnt {

    // ======================================================================================
    // ==================================== Validation ======================================
    // ======================================================================================

    static validate = (form) => {
        var formData = form.getRawData();

		// Start date input
		if (formData.start_date) {
			form.setError("start_date");

			// * Cannot be in the past
			if (DateUtil.inThePast(formData.start_date)) form.setError("start_date", "Start.Past.Err");
		};

		// End date input
		if (formData.end_date) {
			form.setError("end_date");

			// Cannot be in the past
			if (DateUtil.inThePast(formData.end_date)) form.setError("end_date", "End.Past.Err");

			// Cannot be before start date
			if (formData.start_date && DateUtil.before(formData.end_date, formData.start_date)) form.setError("end_date", "EndDate.Before.StartDate.Err");
        };
        
        return form;
    };

}

