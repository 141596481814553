import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { clears, getsBy } from "../../../actions/global/global";
import SReplacementCard from './SReplacementCard';

import ReplacementEnt from '../../../entities/ReplacementEnt';

import Pagination from "react-js-pagination";

import lampImg from '../../../assets/images/search/HYDROGEN_ILLU_LAMPE.png';

class SWatchedReplacements extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            // Pagination
            skip: 0,
            limit: 10
        };
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.setState({ loading: true });
        this.props.clear("replacement", () => {
            this.props.onGetsBy("replacement/watched", ["skip", "limit"], [this.state.skip, this.state.limit], () => this.setState({ loading: false }));
        })
    }

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    render() {
        const { replacements, replacementsCount } = this.props;
        return (
            <React.Fragment>

                {(this.state.loading) && this.loading()}

                {(!this.state.loading && (!replacements || !replacements.length)) &&
                    <div className="text-center mt-5 mb-2 mx-auto" style={{ width: 250 }}>
                        <div className="mb-3 mx-auto" style={{ width: 150, height: 150 }}>
                            <img src={lampImg} alt="" width={150} height={150} />
                        </div>

                        <div className="uppercase text-white"><FormattedHTMLMessage id="You.Are.Watching.No.Mission.Yet" /></div>

                        <a href="#/home/replacement/find" alt="" className="btn srounded mt-4 pl-5 pr-5 pt-2 pb-2 text-white bg-white text-blue lp-1 mx-auto">
                            <b><FormattedHTMLMessage id="Start.Research" /></b>
                        </a>
                    </div>
                }

                {(!this.state.loading && replacements && replacements.length > 0) &&
                    <>
                        <div className="w-100 text-center text-white mb-3">
                            {replacementsCount}&nbsp;
                            {(replacementsCount > 1) ? <FormattedMessage id="Results" /> : <FormattedMessage id="Result" />}
                        </div>

                        {
                            replacements
                                .sort((r0, r1) => new Date(r1.createdAt) - new Date(r0.createdAt))
                                .filter(r => ReplacementEnt.hasLiked(r, this.props.user) || (!ReplacementEnt.hasLiked(r, this.props.user) && !ReplacementEnt.hasAccordAgreement(r, this.props.user)))
                                .filter(r => ReplacementEnt.hasLiked(r, this.props.user) || (!ReplacementEnt.hasLiked(r, this.props.user) && !ReplacementEnt.concludedAgreementCancelByDoctor(r, this.props.user)))
                                .filter(r => ReplacementEnt.hasLiked(r, this.props.user) || (!ReplacementEnt.hasLiked(r, this.props.user) && !ReplacementEnt.concludedAgreementCancelBySub(r, this.props.user)))
                                .map(r => <SReplacementCard key={r._id} replacement={r} redirectAfterSign={this.props.redirectAfterSign}/>)
                        }

                        <Pagination
                            innerClass="w-100 pagination justify-content-center"
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={Math.round(this.state.skip / this.state.limit) + 1}
                            itemsCountPerPage={this.state.limit}
                            totalItemsCount={replacementsCount}
                            pageRangeDisplayed={5}
                            onChange={(page) => this.setState({ skip: (page - 1) * this.state.limit }, this.fetchData)}
                            hideDisabled={false}
                        />

                    </>
                }

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
        replacements: state.global.replacements,
        replacementsCount: state.global.replacementsCount
    }
};

const mapDispatchToProps = dispatch => {
    return {
        clear: (objName, callback) => dispatch(clears(objName, callback)),
        onGetsBy: (objName, fields, values, callback) => dispatch(getsBy(objName, fields, values, callback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SWatchedReplacements);


