var values=[
{label: "Ten.Km",       value: 10},
{label: "Twenty.Km",    value: 20},
{label: "Thirty.Km",    value: 30},
{label: "Fourty.Km",    value: 40},
{label: "Fifty.Km",     value: 50},
{label: "Sixty.Km",     value: 60},
{label: "Seventy.Km",   value: 70},
{label: "Eighty.Km",    value: 80},
{label: "Ninety.Km",    value: 90},
{label: "Hundred.Km",   value: 100}
]

export default class RadiusConfort {
  
    static values() {
      return values;
    }
  
    static getLabel(nbr) {
      for (let value of values) {
        if (value.value === nbr) return value.label;
      }
    }
  
  }