import React from 'react';
import { FormattedMessage } from 'react-intl';
import SignaturePad from 'react-signature-pad-wrapper';


export default class SignatureInput extends React.Component {

    constructor(props) {
        super(props);

        this.signaturePad = null;
    }

    clear = () => {
        if (this.signaturePad) this.signaturePad.instance.clear();

        this.notifCanvasChange();
    }

    undo = () => {
        if (!this.signaturePad) return;

        var data = this.signaturePad.toData();

        if (data) {
            data.pop(); // remove the last dot or line
            this.signaturePad.fromData(data);
        }

        this.notifCanvasChange();
    }

    notifCanvasChange(){
        if(!this.signaturePad) return;

        if(this.signaturePad.isEmpty()) this.props.onEnd(null);
        else this.props.onEnd(this.signaturePad.toDataURL());
    }

    render() {
        return (
            <>

                <div id="signature-container" className={"signature-input " + ((this.props.error) ? "signature-error" : "")}>

                    <SignaturePad
                        onEnd={() => this.props.onEnd(this.sigCanvas.toDataURL())}
                        ref={ref => {
                            this.signaturePad = ref;
                            if (this.signaturePad){
                                this.signaturePad._signaturePad.onEnd = () => this.notifCanvasChange();
                            } 
                        }}
                        redrawOnResize={true}
                    />

                </div>
                    
                {(this.props.error) && <small className="text-danger">{this.props.error}</small>}

                <div className="mt-3 d-inline-block text-lowercase clickable mr-3 text-muted" onClick={this.undo}>
                    <u><FormattedMessage id="Undo" /></u>
                </div>

                <div className="mt-3 d-inline-block text-lowercase clickable ml-3 text-muted" onClick={this.clear}>
                    <u><FormattedMessage id="Clear" /></u>
                </div>

            </>
        );
    }

}