import React from 'react';
import { connect } from 'react-redux';
import { cancelAgreement } from "../../../actions/agreement/agreement";
import { FormattedMessage } from 'react-intl';
import ConfModal from '../../../components/modal/ConfModal';
import { Card, Row, Col } from 'reactstrap';
import { gets } from '../../../actions/global/global';
import AgreementEnt from '../../../entities/AgreementEnt';
import ReplacementEnt from '../../../entities/ReplacementEnt';
import DAgreementTitle from './DAgreementTitle';
import ProfileRateCard from '../../boths/ProfileRateCard';
import SubstituteProfileModal from "../../substitutes/profile/SubstituteProfileModal";
import DReplacementContractModal from '../replacements/DReplacementContractModal';
import ProfileCard from '../../boths/ProfileCard';
import { downloadContract } from "../../../actions/agreement/agreement";
import { upds } from "../../../actions/global/global";

class DAgreementCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            modal: null,
            disabled: false,
        };
    };

    // ================================================================================
    //          Potential actions regarding concluded agreements
    // ================================================================================

    downloadContract(agreementId, successCbk) {
        this.props.onDownloadContract(agreementId, successCbk);
    }

    sendToCouncil(agreementId, successCbk) {
        this.props.onUpds("agreement/send/" + agreementId, {}, () => {
            successCbk();
            this.closeModal();
        });
    }

    cancelAgreement(agreementId, successCbk) {
        // Ajouter la confirmation de suppression

        this.props.onUpds("agreement/cancel/" + agreementId, {}, () => {
            successCbk();
            this.closeModal();
        });
    }

    // ================================================================================
    // ================================================================================
    // ================================================================================

    openConfModal(content, onConfirm, onCancel) {
        this.setState({
            modal: <ConfModal
                content={content}
                onConfirm={onConfirm}
                onCancel={onCancel}
                toggle={() => onCancel()} />
        });
    }

    profileModal(e, substitute, characteristics, agreement) {
        this.setState({
            modal: <SubstituteProfileModal
                substitute={substitute}
                characteristics={characteristics}
                agreement={agreement}
                closeModal={() => this.closeModal()}
                onSignContract={() => this.openContractModal(agreement, substitute, characteristics)}
                downloadContract={(agreementId, successCbk) => this.downloadContract(agreementId, successCbk)}
                sendToCouncil={(agreementId, successCbk) => this.sendToCouncil(agreementId, successCbk)}
                cancelAgreement={(agreementId, successCbk) => this.cancelAgreement(agreementId, successCbk)}
            />
        });
    };

    openContractModal = (agreement, applicant, substituteCharacteristic) => {
        const { officeTimeTable } = this.props;

        var modal = <DReplacementContractModal
            agreement={agreement}
            applicant={applicant}
            substituteCharacteristic={substituteCharacteristic}
            officeTimeTable={officeTimeTable}
            toggle={() => this.closeModal()}
            onSignatureCbk={(agreement) => {
                
            }}
        />;

        if (this.state.modal) {
            this.setState({ modal: null }, () => this.setState({ modal: modal }));
        } else {
            this.setState({ modal: modal });
        }
    }

    closeModal() {
        this.setState({ modal: null });
    };

    render() {
        const { agreement, user } = this.props;
        if (!agreement || !user) return null;

        const { replacement, substitute, substituteCharacteristic } = this.props.agreement;
        return (
            <React.Fragment key={Math.random()}>

                <div className={"d-replacement-card-dim pb-0 mb-0 mb-4 pt-0 mt-0 "} style={{ position: "relative" }}>
                    <Card className={"border " + ((
                        agreement.cancelled_by_doctor ||
                        agreement.cancelled_by_substitute ||
                        agreement.doctor_filled ||
                        agreement.substitute_overlap || replacement.deleted) ? " opacity-low " : " clickable ")}>

                        <Row className="ml-mr-0"
                            onClick={(e) => {
                                if (!agreement.cancelled_by_doctor && !agreement.cancelled_by_substitute) {
                                    this.profileModal(e, substitute, substituteCharacteristic, agreement);
                                }
                            }}>

                            <Col lg="3">
                                {(AgreementEnt.isConcluded(agreement) && ReplacementEnt.isRatable(agreement.replacement) && !AgreementEnt.alreadyRated(user, agreement)) ?
                                    <ProfileRateCard user={substitute} agreement={agreement} onRate={() => { if (this.props.onRate) this.props.onRate() }} />
                                    :
                                    <ProfileCard user={substitute} agreement={agreement} />
                                }
                            </Col>

                            <Col>
                                <DAgreementTitle
                                    replacement={replacement}
                                    agreement={agreement}
                                    onSignatureCbk={this.props.onSignatureCbk}
                                    onCancelCbk={this.props.onCancelCbk}
                                    downloadContract={(agreementId, successCbk) => this.downloadContract(agreementId, successCbk)}
                                    sendToCouncil={(agreementId, successCbk) => this.sendToCouncil(agreementId, successCbk)}
                                    cancelAgreement={(agreementId, successCbk) => this.cancelAgreement(agreementId, successCbk)}
                                />
                            </Col>

                        </Row>

                    </Card>

                    {(agreement.cancelled_by_doctor || replacement.deleted) &&
                        <div className="card-overflow">
                            <FormattedMessage id="Cancelled.By.You" />
                        </div>
                    }

                    {(agreement.cancelled_by_substitute && !replacement.deleted) &&
                        <div className="card-overflow">
                            <FormattedMessage id="Cancelled.By.Substitute" />
                        </div>
                    }

                    {(!agreement.cancelled_by_substitute && !agreement.cancelled_by_doctor && agreement.doctor_filled && !replacement.deleted) &&
                        <div className="card-overflow">
                            <FormattedMessage id="Filled" />
                        </div>
                    }

                    {(!agreement.cancelled_by_doctor && !agreement.cancelled_by_substitute && agreement.substitute_overlap && !replacement.deleted) &&
                        <div className="card-overflow">
                            <FormattedMessage id="Substitute.Unavailable" />
                        </div>
                    }

                </div>

                {this.state.modal}

            </React.Fragment >
        );
    };
};

const mapStateToProps = state => {
    return {
        agreements: state.global.agreements,
        user: state.global.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGets: (objName) => dispatch(gets(objName)),
        onCancelAgreement: (obj, cbk) => dispatch(cancelAgreement(obj, cbk)),
        onDownloadContract: (agreementId, cbk) => downloadContract(agreementId, cbk),
        onUpds: (objName, obj, cbk) => dispatch(upds(objName, obj, cbk))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DAgreementCard);