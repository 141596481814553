import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Form, TextInput, EmailInput, PhoneInput, PasswordInput, RadioInput, MinLength, NotEmpty } from '@gferrand/react-forms';
import { emailExists } from '../../../../actions/user/user';
import Util from '../../../../util/Util';
import ImagePicker from "../../../../components/image-picker/ImagePicker";
import ProfileImages from "../../../../enum/ProfileImages";
import TextDateInput from '../../../../components/form/TextDateInput';


class PEInfoForm extends Component {
    constructor(props) {
        super(props);

        const { submitCbk, defaultValues, validate, disableEmail } = this.props;

        if (defaultValues && defaultValues.password) {
            defaultValues.confirmation_mot_de_passe = defaultValues.password;
        }

        this.state = {image: 0};

        // Listen to the onChange event to check email unicity
        var changeCbk = (input) => {
            if (input.name === "gender" && !ProfileImages.isCorrectGender(this.state.image, input.value)) {
                this.setState({ image: ProfileImages.defaultForGender(input.value) }, () => {
                    if (this.openAvatarPicker) this.openAvatarPicker();
                });
            };

            if (input.name === "email") {
                var callback = (msg) => {
                    if (msg.emailExists && msg.emailExists !== "temporary_ped") input.setError("Email.Already.Exists");
                };

                this.props.onEmailExists(input.value, callback);
            }

            var formData = this.form.getRawData();

            if (!Util.emptyString(formData.password) && !Util.emptyString(formData.confirmation_mot_de_passe) && formData.password !== formData.confirmation_mot_de_passe) {
                this.form.setError("confirmation_mot_de_passe", "Passwords.Not.Identical");
            }

            if (!Util.emptyString(formData.confirmation_mot_de_passe) && !Util.emptyString(formData.password) && formData.confirmation_mot_de_passe === formData.password) {
                this.form.setError("confirmation_mot_de_passe", null);
            }

            if (input.name === 'phone_mobile') {
                let regexPhone = new RegExp('^((06)|(07))');
                if (input.value && input.value.length > 1 && !regexPhone.test(input.value)) {
                    this.form.setError("phone_mobile", "Error.Start.Number.Phone.Mobile");
                }
            }
        };

        this.form = new Form({
            name: "user",
            inputs: [
                new TextInput("name", "Name", undefined, [MinLength(2), NotEmpty]),
                new TextInput("first_name", "First.Name", undefined, [MinLength(2), NotEmpty]),
                new TextDateInput("birth_date", "DateOfBirth", [NotEmpty], { placeholder: "jj/mm/aaaa" }),
                new RadioInput("gender", "Gender", [{ label: "Gender.Woman", value: "woman" }, { label: "Gender.Man", value: "man" }], [NotEmpty],
                    { groupClassName: " ", className: "srounded mr-3 pl-4 pr-4 lowercase bg-blue text-white mb-1 mb-sm-0" }),
                new EmailInput("email", "Email", 100, [NotEmpty], { disabled: disableEmail }),
                new PasswordInput("password", "Password", 40, [MinLength(8), NotEmpty]),
                new PasswordInput("confirmation_mot_de_passe", "Confirmation.Password", 40, [MinLength(8), NotEmpty]),
                new PhoneInput("phone_mobile", "Phone.Mobile", 10, [MinLength(10), NotEmpty]),
                new PhoneInput("phone_pro", "Office.Phone", 10, [MinLength(10)]),
            ],
            submitCbk: (data, cbk) => {
                // Add image!
                data.image = this.state.image;
                data.role = "pediatrician_install";
                submitCbk(data, cbk);
            },
            changeCbk: changeCbk,
            data: defaultValues,
            options: { validate: Util.null(validate) ? true : validate, validateAtStart: false },
        });

        this.openAvatarPicker = null;
    }

    componentDidMount(){
		const {defaultValues} = this.props;
		this.setProfilePicture(defaultValues);
	}

	componentDidUpdate(prevProps) {
		const {defaultValues} = this.props;
		if (prevProps.defaultValues !== defaultValues) {
			this.form.inputs.forEach((elem) => {
				this.form.setValue(elem.name, defaultValues[elem.name]);
			});
			this.setProfilePicture(defaultValues);
		}
	}

	setProfilePicture(defaultValues){
		let image = 0;
		if(defaultValues){
			if (defaultValues.image) image = defaultValues.image;
			else if (defaultValues.gender) image = ProfileImages.defaultForGender(defaultValues.gender);
		}
		this.setState({image});
	}

    validate() {
        let isValid = true;
        let isFormValid = true;
        this.form.inputs.forEach((elem) => {
            isValid = elem.valid();
            if (isValid === false) {
                isFormValid = false;
            }
        })
        return isFormValid;
    }

    row(form, inputName) {
        return (
            <Row className="form-group">
                <Col md="5">{form.getLabel(inputName)}</Col>
                <Col md="7">{form.getInput(inputName)}</Col>
            </Row>
        );
    }

    validateToScrollTop() {
        window.scrollTo(0, 0)
    }

    render() {
        var { submitButtoni18nId, defaultValues } = this.props;

        const rawData = this.form.getRawData();
        if (!defaultValues) defaultValues = {};

        const imgGender = (rawData.gender || defaultValues.gender || "man");

        return (
            <React.Fragment>

                <Row className="step step1 mb-4 justify-content-md-center ">
                    <Col>
                        <Card className="rounded-0">
                            <CardBody className="p-5">

                                {this.row(this.form, "name")}
                                {this.row(this.form, "first_name")}
                                {this.row(this.form, "birth_date")}
                                {this.row(this.form, "gender")}

                                {this.state.image !== 0 &&
                                    <Row className="text-center justify-content-md-center mb-4 mx-auto">
                                        <div className="text-center">
                                            <ImagePicker
                                                defaultValue={this.state.image}
                                                images={((imgGender === "woman") ? ProfileImages.getWomenImgs() : ProfileImages.getMenImgs())}
                                                onPick={(image) => this.setState({ image })}
                                                setOpenCbk={(cbk) => this.openAvatarPicker = cbk}
                                            />
                                        </div>
                                    </Row>
                                }

                                {this.row(this.form, "email")}
                                {this.row(this.form, "password")}
                                {this.row(this.form, "confirmation_mot_de_passe")}

                                <Row className="form-group">
                                    <Col md="5">
                                        <div>{this.form.getLabel("phone_mobile")}</div>
                                    </Col>
                                    <Col md="7">{this.form.getInput("phone_mobile")}</Col>
                                </Row>

                                {this.row(this.form, "phone_pro")}

                                <Row className="mt-5">

                                    <div onClick={this.validateToScrollTop} className="mx-auto form-buttons">
                                        {this.form.submitButton(submitButtoni18nId, { className: "srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green" })}
                                    </div>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        //
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEmailExists: (email, cbk) => emailExists(email, cbk)
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PEInfoForm);