import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage } from 'react-intl';
import 'ladda/dist/ladda-themeless.min.css';
import MedicalSoftwares from '../../../enum/MedicalSoftwares';
import Util from '../../../util/Util';


export default class DoctorTransmission extends Component {

    render() {
        const { doctorTransmission, substituteTransmission, doctorCharacteristic } = this.props;

        const transmission = { ...doctorTransmission, ...substituteTransmission };

        let softwareName;
        if (doctorCharacteristic.medical_software === "0") softwareName = doctorCharacteristic.other_software;
        else softwareName = MedicalSoftwares.getLabel(doctorCharacteristic.medical_software);

        return (
            <React.Fragment>

                <div className="p-3 position-relative" style={{ minHeight: 150 }}>

                    <h5 className="">
                        <b><FormattedMessage id="Transmissions" /></b>
                    </h5>

                    <div className="modify-icon" onClick={() => this.props.openTransmissionFormModal()}>
                        <i className="fa fa-gear clickable"></i>
                    </div>

                    {(Util.emptyObject(transmission)) ?
                        <div className="mt-4 text-secondary"><em><FormattedMessage id="No.Transmission.Yet" /></em></div>
                        :    
                        <>
                            <div className="mx-auto text-left">
                                {(transmission.code_cps) && <div><FormattedMessage id="CPS.Code" />: <b>{transmission.code_cps}</b></div>}
                            </div>

                            <div className="mx-auto text-left">
                                {(transmission.entry_code) && <div><FormattedMessage id="Entry.Code" />: <b>{transmission.entry_code}</b></div>}
                            </div>

                            <div className="mx-auto text-left">
                                {(transmission.computer_code) && <div><FormattedMessage id="Computer.Code" />: <b>{transmission.computer_code}</b></div>}
                            </div>

                            <div className="mx-auto text-left">
                                {(transmission.software_code) && <div><FormattedMessage id="Has.Software.Code" values={{soft: softwareName}} />: <b>{transmission.software_code}</b></div>}
                            </div>

                            <br/>

                            <div className="mx-auto text-left">
                                {(transmission.other_softwares && transmission.other_softwares.length > 0) && 
                                    <>
                                        <div className="mx-auto text-left"> <FormattedMessage id="Other.SoftWares" /> : </div>
                                        <ul>
                                            {transmission.other_softwares.map(soft => {
                                                return (
                                                    <li>
                                                        <b>{soft.software_name}</b>
                                                        &nbsp;/&nbsp; 
                                                        <span className="lowercase"><FormattedMessage id="Password" /></span> : <b>{soft.software_password}</b>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </>
                                }
                            </div>

                            <div className="mx-auto text-left">
                                {(transmission.bio_checkup_inbox_name) && 
                                    <div><FormattedMessage id="Has.Bio.Checkup.Inbox" />:&nbsp;
                                    <ul>
                                        <li>
                                            <b>{transmission.bio_checkup_inbox_name}</b>
                                            {(transmission.bio_checkup_inbox_code) && 
                                                <>
                                                &nbsp;/&nbsp; 
                                                <span className="lowercase"><FormattedMessage id="Code" /></span> : <b>{transmission.bio_checkup_inbox_code}</b>
                                                </>
                                            }
                                        </li>
                                    </ul>
                                    </div>
                                }
                            </div>

                            <div className="mx-auto text-left">
                                {(transmission.mail_inbox_name) && 
                                    <div><FormattedMessage id="Has.Mail.Inbox" />:&nbsp;
                                    <ul>
                                        <li>
                                        <b>{transmission.mail_inbox_name}</b>
                                        {(transmission.mail_inbox_code) && 
                                            <>
                                            &nbsp;/&nbsp; 
                                            <span className="lowercase"><FormattedMessage id="Code" /></span> : <b>{transmission.mail_inbox_code}</b>
                                            </>
                                        }
                                        </li>
                                    </ul>
                                    </div>
                                }
                            </div>

                            <div className="mx-auto text-left">
                                {(transmission.retirement_homes && transmission.retirement_homes.length > 0) && 
                                    <>
                                        <div className="mx-auto text-left"> <FormattedMessage id="Retirement.Homes" /> : </div>
                                        <ul>
                                            {transmission.retirement_homes.map(home => {
                                                return (
                                                    <li>
                                                        <b>{home.retirement_home_name}</b>
                                                        &nbsp;:&nbsp; 
                                                        {(home.retirement_home_entry_code) && 
                                                            <>
                                                                <span className="lowercase"><FormattedMessage id="Retirement.Home.Entry.Code" /></span>: <b>{home.retirement_home_entry_code}</b>
                                                                &nbsp;,&nbsp; 
                                                            </>
                                                        }
                                                        <span className="lowercase"><FormattedMessage id="Retirement.Home.Computer.Code" /></span>: <b>{home.retirement_home_computer_code}</b>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </>
                                }
                            </div>
                        </>
                    }

                
                </div>

            </React.Fragment>
        );
    }

}