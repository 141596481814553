import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from "reactstrap";

const AccountingTableFooter = ({ daily, openModal, openExpensesModal, openconsultationsModal, subcategory, loading }) => {
    return (
        <>
            <Container>
                {daily &&
                    <Row>
                        {(subcategory === 'consultation') &&
                            <Col className="mb-4 align">
                                <div disabled={loading ? true : false} className="btn srounded bg-orange text-white pl-4 pr-4 pt-3 pb-3"
                                    onClick={() => openModal()} ><FormattedMessage id="Add.Consult" />
                                </div>
                            </Col>
                        }
                        {(subcategory === 'income') &&
                            <Col className="mb-4 align">
                                <div disabled={loading ? true : false } className="btn srounded bg-orange text-white pl-4 pr-4 pt-3 pb-3"
                                    onClick={() => openconsultationsModal()} ><FormattedMessage id="Add.Entry" />
                                </div>
                            </Col>
                        }
                        {(subcategory === 'outcome') &&
                            <Col className="mb-4 align">
                                <div disabled={loading ? true : false } className="btn srounded bg-orange text-white pl-4 pr-4 pt-3 pb-3"
                                    onClick={() => openExpensesModal()} ><FormattedMessage id="Add.An.Expenses" />
                                </div>
                            </Col>
                        }
                    </Row>
                }
            </Container>
        </>
    );
}

export default AccountingTableFooter;