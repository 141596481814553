var values = [
    { label: "Aucun", value: "0"},
    { label: "Doctolib", value: "1" },
    { label: "Qare", value: "2" },
    { label: "Medadom", value: "3" },
    { label: "Blablacall", value: "4" },
    { label: "Maiia", value: "5" },
    { label: "Livi", value: "6" },
    { label: "Doctoconsult", value: "7"},
    { label: "AvecMonDoc", value: "8"},
    { label: "Medaviz", value: "9"},
    { label: "Leah", value: "10"},
    { label: "HelloCare", value: "11"},
    { label: "MedVu", value: "12"},
    { label: "CLICKDOC", value: "13"},
    { label: "Consulib", value: "14"},
    { label: "Other.Software", value: "15"}
    
];

export default class Teleconsulation {

    static values() {
        let sortedValues = values.sort((a, b) => {
            if(a.label === "Other.Software") return 1
            else if (b.label === "Other.Software") return -1
            else if(a.label > b.label) return 1
            else if (a.label < b.label) return -1
            else return 0
        });
        return sortedValues
    }

    static getLabel(nbr) {
        for (let value of values) {
            if (value.value === nbr) return value.label;
        }
    }
};