import React from 'react';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import { FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import CalendarController from '../calendar/CalendarController';

class SHome extends React.Component {

    button = (i18n, link="", img) => {
        return (
            <Col xs="6" sm="3" className="p-0 m-0">
                <div className="d-inline-block text-center mb-2">
                    <Link to={link} className="d-inline-block mb-3 img-btn" style={{ width: 150, height: 150 }}>
                        <img src={img} alt="" width={150} height={150} />
                    </Link>

                    <div className="uppercase text-white"><FormattedHTMLMessage id={i18n} /></div>
                </div>
            </Col>
        );
    }

    inactiveLink = (i18n, img) => {
		return (
			<Col xs="6" sm="3" className="p-0 m-0">
				<div className="d-inline-block text-center mb-2">
					<div className="d-inline-block mb-3" style={{ width: 150, height: 150 }}>
						<img src={img} alt="" width={150} height={150} />
					</div>
					<div className="uppercase text-white"><FormattedHTMLMessage id={i18n} /></div>
				</div>
			</Col>
		);
	}

    render() {
        return (
            <CalendarController  history={this.props.history} role='substitute' />
        );
    }
}


const mapStateToProps = state => {
	return {
	}
}

const mapDispatchToProps = dispatch => {
	return {
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SHome);
