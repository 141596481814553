var values = [
  { label: "Secretariat.OnTheSpot", value: "1" },
  { label: "Secretariat.Remotely", value: "2" },
  { label: "Secretariat.No", value: "3" },
  {label: "OnSIte.Remote", value: "4"}
];

export default class DoctorSecretariat {

  static values() {
    return values;
  }

  static getLabel(nbr) {
    for (let value of values) {
      if (value.value === nbr) return value.label;
    }
  }

};