import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import indexRoutes from './routes/index.jsx';
import { Route, Switch, HashRouter, withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';

import './assets/scss/style.css';

import { getBearerToken } from './actions/authentication/authentication';
import ConnectedIntlProvider from './components/i18n/ConnectedIntlProvider';
import rootReducer from './reducers';

// Env conf file
require('dotenv').config();

const store = createStore(rootReducer, applyMiddleware(thunk));

// If we have authentication data in the session storage, use it right away
var token = sessionStorage.getItem('token');
var jwt = sessionStorage.getItem('jwt');
if (token) axios.defaults.headers['Authorization'] = "Bearer " + token;
if (jwt) axios.defaults.headers['jwtToken'] = jwt;

// If a 401 error happens, get a new bearer token and issue the request again
axios.interceptors.response.use(null, async (error) => {
    if (error.response && error.response.status === 401) {
        var callback = (token) => {
            // Add to header
            axios.defaults.headers['Authorization'] = "Bearer " + token;
            error.config.headers['Authorization'] = "Bearer " + token;

            // Add to storage
            sessionStorage.setItem('token', token);

            // Do the failing call again
            return axios.request(error.config).then((res) => {
                return Promise.resolve(res)
            });
        }

        // Get a new token
        return getBearerToken(callback);
    }
    else if (error.response && error.response.status === 403) {
        const hash = window.location.hash;

        if (hash.indexOf("login") === -1 && hash.indexOf("recoverPwd") === -1) window.location.href = "/";
    }

    return Promise.reject(error);
});

//const hist = createBrowserHistory();

const ScrollToTop = withRouter(class ScrollToTop extends React.Component {

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
})


ReactDOM.render(

    <Provider store={store}>
        <ConnectedIntlProvider onError={() => {}}>
            <HashRouter>
                <ScrollToTop>
                    <Switch>
                        {indexRoutes.map((prop, key) => {
                            return <Route path={prop.path} key={key} component={prop.component} />;
                        })}
                    </Switch>
                </ScrollToTop>
            </HashRouter>
        </ConnectedIntlProvider>
    </Provider>

    , document.getElementById('root')); 
