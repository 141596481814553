import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage } from 'react-intl';
import StringUtil from '../../../util/StringUtil';
import 'ladda/dist/ladda-themeless.min.css';

import echographyImg from "../../../assets/images/registration/HYDROGEN_ICONS_ECHOGRAPHIE.png";
import irmImg from "../../../assets/images/registration/HYDROGEN_ICONS_IRM.png";
import radiologyImg from "../../../assets/images/registration/HYDROGEN_ICONS_RADIOLOGIE.png";
import scannerImg from "../../../assets/images/registration/HYDROGEN_ICONS_SCANNER.png";
import scintigraphyImg from "../../../assets/images/registration/HYDROGEN_ICONS_SCINTIGRAPHIE.png";
import tepImg from "../../../assets/images/registration/HYDROGEN_ICONS_TEP.png";
import boneDensitometryImg from "../../../assets/images/registration/HYDROGEN_ICONS_RHUMATOLOGIE.png";

export default class RadiologistPracticesProfile extends Component {

    buildRoundElem = (characteristics, field, i18n, img) => {
        if (!i18n) i18n = StringUtil.capitalize(field);

        return (
            <div className="round-elem d-inline-block" style={{ opacity: (characteristics[field]) ? 1 : .4 }}>
                <div className="">
                    <img src={img} alt="" width="50" height="50" />
                </div>
                <div className="title mt-2"><FormattedMessage id={i18n} /></div>
            </div>
        );
    }

    render() {
        const { radiologistCharacteristic } = this.props;

        return (
            <React.Fragment>
                <div className="d-inline-block mx-auto w-100">
                    {this.buildRoundElem(radiologistCharacteristic, "radiology", 'Radiology', radiologyImg)}
                    {this.buildRoundElem(radiologistCharacteristic, "scanner", "Scanner", scannerImg)}
                    {this.buildRoundElem(radiologistCharacteristic, "echography", "Echography", echographyImg)}
                    {this.buildRoundElem(radiologistCharacteristic, "irm", "Irm", irmImg)}
                    {this.buildRoundElem(radiologistCharacteristic, "scintigraphy", "Scintigraphy", scintigraphyImg)}
                    {this.buildRoundElem(radiologistCharacteristic, "tep", "Tep", tepImg)}
                    {this.buildRoundElem(radiologistCharacteristic, "bone_densitometry", "Bone.Densitometry", boneDensitometryImg)}
                </div>
            </React.Fragment >
        );
    };

}