import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import LaddaButton from 'react-ladda/dist/LaddaButton';
import { EXPAND_LEFT } from 'react-ladda';

export default function SubmitFunction (props) {
    return(
        <LaddaButton
            className={"srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white" + (props.disabled ? " bg-grey" : " bg-green")}
            style={{ opacity: (props.disabled) ? ".6" : "1" }}
            loading={props.loading}
            onClick={() => props.form.submit()}
            data-style={EXPAND_LEFT}>
                {props.children ? props.children : <FormattedHTMLMessage id="Register" />}
        </LaddaButton>
    );
}