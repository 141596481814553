var values=[
    {label: "Sixty.Percent", value: 60},
    {label: "SixtyTwo.Percent", value: 62.5},
    {label: "SixtyFive.Percent", value: 65},
    {label: "SixtySeven.Percent", value: 67.5},
    {label: "Seventy.Percent", value: 70},
    {label: "SeventyTwo.Percent", value: 72.5},
    {label: "SeventyFive.Percent", value: 75},
    {label: "SeventySeven.Percent", value: 77.5},
    {label: "Eighty.Percent", value: 80},
    {label: "EightyTwo.Percent", value: 82.5},
    {label: "EightyFive.Percent", value: 85},
    {label: "EightySeven.Percent", value: 87.5},
    {label: "Ninety.Percent", value: 90},
    {label: "NinetyTwo.Percent", value: 92.5},
    {label: "NinetyFive.Percent", value: 95},
    {label: "NinetySeven.Percent", value: 97.5},
    {label: "Hundred.Percent", value: 100}
    ]
    
    export default class Retrocession {
      
        static values() {
          return values;
        }
      
        static getLabel(nbr) {
          for (let value of values) {
            if (value.value === nbr) return value.label;
          }
        }
      
      }