import {
    CLEAR,
    GET,
    UPD,
    // REMOVE,
    ADDS,
    CLEARS,
    GETS,
    UPDS,
    REMOVES
} from "../../actions/global/global";
import Logger from "../../util/Logger";

import Util from '../../util/Util';


//! Version 0.0.2

//! Remember to change the version if any changes are made !

// Use for *plural* actions! Action.obj must be an array!
const mergeActionIntoReduxs = (action, state) => {
    // Object already exists in the state
    if (state[action.objName]) {
        // Build a new array from the state, replace obj if in action
        var newArray = state[action.objName].map((elem) => {
            for (let i = 0; i < action.obj.length; i++) {
                // Element must be updated. Keep action value and remove from action.
                if (elem && elem._id === action.obj[i]._id) return action.obj.splice(i, 1)[0];
            }

            return elem;
        });

        let newState = { ...state, [action.objName]: [...newArray, ...action.obj] };

        // If the count is included, add it
        if (!Util.null(action.count)) newState[action.objName + "Count"] = action.count;

        return newState;
    }
    // Object does not exist. Create it
    else {
        let newState = { ...state, [action.objName]: action.obj };

        // If the count is included, add it
        if (!Util.null(action.count)) newState[action.objName + "Count"] = action.count;

        return newState;
    }
};

export default function global(state = {}, action) {

    switch (action.type) {
        // ================================================================================
        // =================================== SINGLE =====================================
        // ================================================================================

        case CLEAR: {
            Logger.log("REDUX - CLEAR")
            Logger.log(Util.parse(Util.stringify(action)));

            return { ...state, [action.objName]: {} };
        }

        case GET: {
            Logger.log("REDUX - GET")
            Logger.log(Util.parse(Util.stringify(action)));

            return { ...state, [action.objName]: action.obj };
        }

        case UPD: {
            Logger.log("REDUX - UPD");
            Logger.log(Util.parse(Util.stringify(action)));

            return { ...state, [action.objName]: action.obj };
        }

        // ================================================================================
        // ==================================== MANY ======================================
        // ================================================================================

        case ADDS: {
            Logger.log("REDUX - ADDS")
            Logger.log(Util.parse(Util.stringify(action)));

            let newState = mergeActionIntoReduxs(action, state);
            if (newState[action.objName + "Count"]) newState[action.objName + "Count"]++;

            return newState;
        }

        case CLEARS: {
            Logger.log("REDUX - CLEARS")
            Logger.log(Util.parse(Util.stringify(action)));

            return { ...state, [action.objName]: [] };
        }

        case GETS: {
            Logger.log("REDUX - GETS")
            Logger.log(Util.parse(Util.stringify(action)));
            return Object.assign({}, state, {[action.objName]: action.obj, [action.objName + "Count"]: action.count})
            //return mergeActionIntoReduxs(action, state);
        }

        case UPDS: {
            Logger.log("REDUX - UPDS");
            Logger.log(Util.parse(Util.stringify(action)));

            return mergeActionIntoReduxs(action, state);
        }

        case REMOVES: {
            Logger.log("REDUX - REMOVES");
            Logger.log(Util.parse(Util.stringify(action)));

            let newState = (
                {
                    ...state,
                    [action.objName]: state[action.objName].filter((elem) => elem._id !== action.objId)
                }
            );
            if (newState[action.objName + "Count"]) newState[action.objName + "Count"]--;

            return newState;
        }

        default:
            return state;
    }

};