export default function secretaryManagement(state = {}, action) {
	switch (action.type) {
		case "SECRETARY_FIND_MANAGEMENTS_REQUEST":
			return Object.assign({}, state, {
				isGettingsecretaryManagements: true
			});
		case "SECRETARY_FIND_MANAGEMENTS_SUCCESS":
			return Object.assign({}, state, {
				secretaryManagements: action.secretaryManagements,
				isGettingsecretaryManagements: false
			});
		case "SECRETARY_FIND_MANAGEMENTS_FAILURE":
			return Object.assign({}, state, {
				isGettingsecretaryManagements: false
			});
		case "ACCEPT_SECRETARY_MANAGEMENT_REQUEST":
			return Object.assign({}, state, {
				acceptedsecretaryMaanagement: false,
				isUpdatingSecretaryMangement: true
			});
		case "ACCEPT_SECRETARY_MANAGEMENT_SUCCESS":
			return Object.assign({}, state, {
				acceptedsecretaryMaanagement: true,
				isUpdatingSecretaryMangement: false
			});
		case "ACCEPT_SECRETARY_MANAGEMENT_FAILURE":
			return Object.assign({}, state, {
				acceptedsecretaryMaanagement: false,
				isUpdatingSecretaryMangement: false
			});
		case "REFUSE_SECRETARY_MANAGEMENT_REQUEST":
			return Object.assign({}, state, {
				acceptedsecretaryMaanagement: false,
				isUpdatingSecretaryMangement: true
			});
		case "REFUSE_SECRETARY_MANAGEMENT_SUCCESS":
			return Object.assign({}, state, {
				acceptedsecretaryMaanagement: true,
				isUpdatingSecretaryMangement: false
			});
		case "REFUSE_SECRETARY_MANAGEMENT_FAILURE":
			return Object.assign({}, state, {
				acceptedsecretaryMaanagement: false,
				isUpdatingSecretaryMangement: false
			});
		case "DELETE_SECRETARY_MANAGEMENT_REQUEST":
			return Object.assign({}, state, {
				deletedsecretaryMaanagement: false,
				isUpdatingSecretaryMangement: true
			});
		case "DELETE_SECRETARY_MANAGEMENT_SUCCESS":
			return Object.assign({}, state, {
				deletedsecretaryMaanagement: true,
				isUpdatingSecretaryMangement: false
			});
		case "DELETE_SECRETARY_MANAGEMENT_FAILURE":
			return Object.assign({}, state, {
				deletedsecretaryMaanagement: false,
				isUpdatingSecretaryMangement: false
			});
		case "DOCTOR_FIND_MANAGEMENTS_REQUEST":
			return Object.assign({}, state, {
				secretaryManagements: action.secretaryManagements,
				isGettingsecretaryManagements: false
			});
		case "DOCTOR_FIND_MANAGEMENTS_SUCCESS":
			return Object.assign({}, state, {
				secretaryManagements: action.secretaryManagements,
				isGettingsecretaryManagements: false
			});
		case "DOCTOR_FIND_MANAGEMENTS_FAILURE":
			return Object.assign({}, state, {
				secretaryManagements: action.secretaryManagements,
				isGettingsecretaryManagements: false
			});
		default:
			return state;
	}
}