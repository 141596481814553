export default function appointments(state = {}, action) {
	switch(action.type) {
		case "ADD_EXCEPTIONAL_OPEN_REQUEST":
			return Object.assign({}, state, {
				addExceptionalOpen: false
			});
		case "ADD_EXCEPTIONAL_OPEN_SUCCESS":
			let exceptionalOpen = Array.from(state.exceptionalOpen);
			exceptionalOpen.push(action.exceptionalOpen.exceptionalOpen);
			return Object.assign({}, state, {
				addExceptionalOpen: true,
				exceptionalOpen: exceptionalOpen
			});
		case "ADD_EXCEPTIONAL_OPEN_FAILURE":
			return Object.assign({}, state, {
				addExceptionalOpen: false
			});
		case "GETS_EXCEPTIONAL_OPEN_REQUEST":
			return Object.assign({}, state, {
				getExceptionalOpen: false
			});
		case "GETS_EXCEPTIONAL_OPEN_SUCCESS":
			return Object.assign({}, state, {
				getExceptionalOpen: true,
				exceptionalOpen: action.exceptionalOpen.exceptionalOpens
			});
		case "GETS_EXCEPTIONAL_OPEN_FAILURE":
			return Object.assign({}, state, {
				getExceptionalOpen: false
			});
		case "EDIT_EXCEPTIONAL_OPEN_REQUEST":
			return Object.assign({}, state, {
				editExceptionalOpen: false
			});
		case "EDIT_EXCEPTIONAL_OPEN_SUCCESS":
			let arrayExceptionalOpen = Array.from(state.exceptionalOpen);
			arrayExceptionalOpen = arrayExceptionalOpen.filter((exceptionalOpen) => {
				return exceptionalOpen._id !== action.exceptionalOpen.exceptionalOpen._id;
			})
			arrayExceptionalOpen.push(action.exceptionalOpen.exceptionalOpen);

			return Object.assign({}, state, {
				editExceptionalOpen: true,
				exceptionalOpen: arrayExceptionalOpen
			});
		case "EDIT_EXCEPTIONAL_OPEN_FAILURE":
			return Object.assign({}, state, {
				editExceptionalOpen: false
			});
		case "DELETE_EXCEPTIONAL_OPEN_REQUEST":
			return Object.assign({}, state, {
				isDeleteExceptionalOpen: false
			});
		case "DELETE_EXCEPTIONAL_OPEN_SUCCESS":
			let arrayExceptionalOpenFilter = state.exceptionalOpen.filter((exceptionalOpen) => {
				return exceptionalOpen._id !== action.exceptionalOpen.exceptionalOpen._id;
			})
			return Object.assign({}, state, {
				isDeleteExceptionalOpen: true,
				exceptionalOpen: arrayExceptionalOpenFilter
			});
		case "DELETE_EXCEPTIONAL_OPEN_FAILURE":
			return Object.assign({}, state, {
				isDeleteExceptionalOpen: false
			});
		case "GETS_EXCEPTIONAL_OPEN_BY_DOCTORS_REQUEST":
			return Object.assign({}, state, {
				getExceptionalOpenByDoctors: false
			});
		case "GETS_EXCEPTIONAL_OPEN_BY_DOCTORS_SUCCESS":
			return Object.assign({}, state, {
				getExceptionalOpenByDoctors: true,
				exceptionalOpen: action.exceptionalOpen.exceptionalOpens
			});
		case "GETS_EXCEPTIONAL_OPEN_BY_DOCTORS_FAILURE":
			return Object.assign({}, state, {
				getExceptionalOpenByDoctors: false
			});
		default:
			return state;
	}
}