export default class RatingEnt {

    static averageAllStars = (rating) => {
        if (!rating || !rating.count || rating.count < 3) return 0;

        var sum = rating.avg_criteria_1 + rating.avg_criteria_2 + rating.avg_criteria_3 + rating.avg_criteria_4 + rating.avg_criteria_5;
        var total = sum / 5;
        return total;
    };
    
};
