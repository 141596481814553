import moment from 'moment';
import OfficeTimeTableEnt from '../entities/OfficeTimeTableEnt';

export default class AppointmentUtil {

    // Verifie si les rdv patient ne sont pas en dehors des horaires ou sur un autre rdv
    static checkAppointment(startDate, duration, appointmentId, arrayOfficeHours, appointmentsByUsers, exceptionalOpen, exceptionalClose, userId) {
        let check = {
            officeHours: false,
            appointments: false
        };

        let endDate = moment(startDate).add(duration, 'minutes');

        let isInPeriod = false;

        if (arrayOfficeHours.length > 0) {
            arrayOfficeHours.forEach((officeHours) => {
                if (officeHours) {
                    officeHours.forEach((hours) => {
                        if (hours) {
                            if (moment(hours.end).isAfter(startDate) && moment(hours.start).isBefore(endDate)) {
                                isInPeriod = true;
                            } 
                        }
                    })
                }
            })
        }

        if (exceptionalOpen && exceptionalOpen.length > 0) {
            exceptionalOpen.forEach((elem) => {
                let startDateOpen = moment(elem.date).set({
                    hour: moment(elem.start_date, 'HH:mm').hour(),
                    minute: moment(elem.start_date, 'HH:mm').minute(),
                });
                let endDateOpen = moment(elem.date).set({
                    hour: moment(elem.end_date, 'HH:mm').hour(),
                    minute: moment(elem.end_date, 'HH:mm').minute(),
                });

                if (moment(startDate).isSameOrAfter(startDateOpen, 'minutes') && moment(startDate).isBefore(endDateOpen, 'minutes')) {
                    isInPeriod = true;
                }
            })
        }

        if (exceptionalClose && exceptionalClose.length > 0) {
            exceptionalClose.forEach((elem) => {
                if (moment(startDate).isSameOrAfter(elem.start_date, 'minutes') && moment(startDate).isBefore(elem.end_date, 'minutes')) {
                    isInPeriod = false;
                }
            })
        }

        if (isInPeriod === false) {
            check.officeHours = true;
        } 

        if (appointmentsByUsers.length > 0) {
            for (let appointments of appointmentsByUsers) {
                if (appointments._id === userId) {
                    for (let appointment of appointments.appointments) {
                        let appointmentDateStart = moment(appointment.appointment_date);
                        let appointmentDateEnd = moment(appointment.appointment_date).add(appointment.duration, 'minutes');
        
                        if (moment(startDate).isBetween(appointmentDateStart, appointmentDateEnd, 'minutes', '[]') && appointmentId !== appointment._id) {
                            check.appointments = true;
                        }
                    }
                }
            }
        }

        return check;
    }

    // Mets en forme les rdv patients
    static formatAppointments(appointmentsByUsers, userId) {
        var arrayAppointments = [];
        if (appointmentsByUsers) {
            for (let user of appointmentsByUsers) {
                if (user._id === userId) {
                    if (user.appointments.length > 0 && user.appointments[0]._id) {
                        for (let appointment of user.appointments) {

                            let event = {};

                            let title = appointment.patient.name.toUpperCase() + ' ' + appointment.patient.first_name;
                            let startDate = moment(appointment.appointment_date);
                            let endDate = moment(appointment.appointment_date);
                            endDate.add(appointment.duration, 'minutes');
            
                            event.allDay = false
                            event.title = title;
                            event.color = 'Replacement';
                            event.start = new Date(startDate);
                            event.end = new Date(endDate);
                            event.css = 'appointment';
                            event.cancel_for_close = appointment.cancel_for_close;
                            event.original = appointment;
            
                            arrayAppointments.push(event);
                        }
                    }
                }
            }
        }

        return arrayAppointments;
    }

    // Mets en forme les rdv patients
    static formatAppointmentsForSecretary(appointmentsByUsers, userIds) {
        var arrayAppointments = [];
        if (appointmentsByUsers) {
            for (let user of appointmentsByUsers) {
                if (userIds.includes(user._id)) {
                    if (user.appointments.length > 0 && user.appointments[0]._id) {
                        for (let appointment of user.appointments) {
                            let event = {};

                            let title = appointment.patient.name.toUpperCase() + ' ' + appointment.patient.first_name;
                            let startDate = moment(appointment.appointment_date);
                            let endDate = moment(appointment.appointment_date);
                            endDate.add(appointment.duration, 'minutes');
            
                            event.allDay = false
                            event.title = title;
                            event.color = 'Replacement';
                            event.start = new Date(startDate);
                            event.end = new Date(endDate);
                            event.css = 'appointment';
                            event.cancel_for_close = appointment.cancel_for_close;
                            event.original = appointment;
            
                            arrayAppointments.push(event);
                        }
                    }
                }
            }
        }

        return arrayAppointments;
    }

    // Fonction qui met en vert les emplacements libres pour les patients
    static emptyAppointments(appointments, arrayOfficeHours, exceptionalOpens, exceptionalCloses, userId) {
        let arrayAppointments = [];

        if (arrayOfficeHours && arrayOfficeHours.length > 0) {
            arrayOfficeHours = arrayOfficeHours.flat();

            let appointmentsFormat = this.formatAppointments(appointments, userId);

            for (let officeHours of arrayOfficeHours) {
                if (officeHours === undefined) continue;

                let startDate = moment(officeHours.start);
                let endDate = moment(officeHours.end);
                
                while(startDate.isBefore(endDate, 'minute')) {
                    let event = {};

                    let endDate = moment(startDate);
                    endDate.add(15, 'minutes');
        
                    event.allDay = false
                    event.title = 'libre +';
                    event.color = 'Replacement';
                    event.start = new Date(startDate);
                    event.end = new Date(endDate);
                    event.css = 'emptyAppointment';

                    let push = true;
                    if (appointmentsFormat && appointmentsFormat.length > 0) {
                        appointmentsFormat.forEach((elem) => {
                            if (moment(startDate).isSameOrAfter(elem.start, 'minutes') && moment(startDate).isBefore(elem.end, 'minutes')) {
                                push = false;
                            }
                        })
                    }

                    if (push === true) {
                        arrayAppointments.push(event);
                    }

                    startDate.add(15, 'minutes');
                }
            }
        }

        if (exceptionalOpens && exceptionalOpens.length > 0) {
            for (let exceptionalOpen of exceptionalOpens) {
                let startDate = moment(exceptionalOpen.date).set({
                    hour: moment(exceptionalOpen.start_date, 'HH:mm').hour(),
                    minute: moment(exceptionalOpen.start_date, 'HH:mm').minute(),
                });
                let endDate = moment(exceptionalOpen.date).set({
                    hour: moment(exceptionalOpen.end_date, 'HH:mm').hour(),
                    minute: moment(exceptionalOpen.end_date, 'HH:mm').minute(),
                });

                
                while(startDate.isBefore(endDate, 'minute')) {
                    let event = {};

                    let endDate = moment(startDate);
                    endDate.add(15, 'minutes');

                    event.allDay = false
                    event.title = 'libre +';
                    event.color = 'Replacement';
                    event.start = new Date(startDate);
                    event.end = new Date(endDate);
                    event.css = 'emptyAppointment';
                    event.original = exceptionalOpen;
    
                    arrayAppointments.push(event);

                    startDate.add(15, 'minutes');
                }
            }
        }

        if (exceptionalCloses && exceptionalCloses.length > 0) {
            for (let exceptionalClose of exceptionalCloses) {
                let startDate = moment(exceptionalClose.start_date);
                let endDate = moment(exceptionalClose.end_date);

                while(startDate.isBefore(endDate, 'minute')) {
                    arrayAppointments.forEach((appointment, key) => {
                        if (moment(startDate).isSameOrAfter(appointment.start, 'minutes') && moment(startDate).isBefore(appointment.end, 'minutes')) {
                            arrayAppointments.splice(key, 1);
                        }
                    })
                    startDate.add(15, 'minutes');
                }
            }
        }

        return arrayAppointments;
    }

    static exceptionalOpen(exceptionalOpens) {
        let arrayExceptionalOpens = [];
        
        if (exceptionalOpens && exceptionalOpens.length > 0) {
            for (let exceptionalOpen of exceptionalOpens) {
                let event = {};

                let startDate = moment(exceptionalOpen.date).set({
                    hour: moment(exceptionalOpen.start_date, 'HH:mm').hour(),
                    minute: moment(exceptionalOpen.start_date, 'HH:mm').minute(),
                });

                let endDate = moment(exceptionalOpen.date).set({
                    hour: moment(exceptionalOpen.end_date, 'HH:mm').hour(),
                    minute: moment(exceptionalOpen.end_date, 'HH:mm').minute(),
                });

                event.allDay = false
                event.title = 'Ouverture exceptionnelle';
                event.color = 'Replacement';
                event.start = new Date(startDate);
                event.end = new Date(endDate);
                event.css = 'exceptionalOpen';
                event.original = exceptionalOpen;

                arrayExceptionalOpens.push(event);
            }
        }

        return arrayExceptionalOpens;
    }

    static exceptionalClose(exceptionalCloses) {
        let arrayExceptionalCloses = [];
        
        if (exceptionalCloses && exceptionalCloses.length > 0) {
            for (let exceptionalClose of exceptionalCloses) {
                if (moment(exceptionalClose.start_date).isSame(exceptionalClose.end_date, 'day')) {
                    let event = {
                        allDay: false,
                        title: 'Fermeture exceptionnelle',
                        color: 'Replacement',
                        css: 'exceptionalClose',
                        original: exceptionalClose,
                        start: new Date(exceptionalClose.start_date),
                        end: new Date(exceptionalClose.end_date)
                    }
                    arrayExceptionalCloses.push(event);
                } else {

                    let startDate = moment(exceptionalClose.start_date);
                    let endDate = moment(exceptionalClose.end_date);


                    while(startDate.isSameOrBefore(endDate, 'day')) {
                        let event = {
                            allDay: false,
                            title: 'Fermeture exceptionnelle',
                            color: 'Replacement',
                            css: 'exceptionalClose',
                            original: exceptionalClose,
                        }

                        if (startDate.isSame(exceptionalClose.start_date, 'day')) {
                            event.start = new Date(exceptionalClose.start_date);
                            event.end = new Date(moment(startDate).set({'hours': 23, 'minutes': 59}));
                        } else if (startDate.isSame(exceptionalClose.end_date, 'day')) {
                            event.start = new Date(moment(startDate).set({'hours': 0, 'minutes': 0}));
                            event.end = new Date(exceptionalClose.end_date);
                        } else {
                            event.start = new Date(moment(startDate).set({'hours': 0, 'minutes': 0}));
                            event.end = new Date(moment(startDate).set({'hours': 23, 'minutes': 59}));
                        }
                        arrayExceptionalCloses.push(event);

                        startDate.add(1, 'day');
                    }
                }
            }
        }
        
        return arrayExceptionalCloses;
    }
    
    static titleAccessor(event, showViewAppointment, showViewExceptionalOpen, showViewExceptionalClose) {
        let classNameCustom;
        if (event.css === 'replacement') {
            classNameCustom = 'bg-occasional';
        } else if (event.css === 'appointment') {
            if (showViewAppointment === true) {
                classNameCustom = 'clickable background-calendar-weeks-appointment-grey';
            } else if (showViewExceptionalOpen === true || showViewExceptionalClose === true) {
                classNameCustom = 'clickable background-calendar-weeks-appointment-grey';
            } else {
                if (event.cancel_for_close === true) {
                    classNameCustom = 'clickable close-for-doctor';
                } else {
                    if (parseInt(event.original.duration) < 7) {
                        classNameCustom = 'clickable background-calendar-weeks-appointment-short';
                    } else {
                        classNameCustom = 'clickable background-calendar-weeks-appointment';
                    }
                }
            }
        } else if (event.css === 'emptyAppointment') {
            classNameCustom = 'clickable bg-occasional';
        }  else if (event.css === 'exceptionalOpen') {
            classNameCustom = 'clickable background-calendar-weeks-exceptional-open';
        } else if (event.css === 'exceptionalClose') {
            classNameCustom = 'clickable background-calendar-weeks-exceptional-close';
        } else if (event.css === 'calendarEvents') {
            classNameCustom = 'background-calendar-weeks-calendar-events';
        } else if (event.css === 'announcement') {
            classNameCustom = 'background-calendar-weeks-announcement';
        }

        return {
            className: classNameCustom,
        };
    }

    static customSlotPropGetterDoctor(date, arrayOfficeHours, exceptionalOpen, exceptionalClose) {
        let className = 'background-calendar-weeks-not-work';

        if (arrayOfficeHours && arrayOfficeHours.length > 0) {
            arrayOfficeHours.forEach((officeHours) => {
                if (officeHours) {
                    officeHours.forEach((hours) => {
                        if (hours) {
                            if (moment(date).isSameOrAfter(hours.start, 'minutes') && moment(date).isBefore(hours.end, 'minutes')) {
                                className = 'background-calendar-weeks-work';
                            }
                        }
                    })
                }
            })
        }

        if (exceptionalOpen && exceptionalOpen.length > 0) {
            exceptionalOpen.forEach((elem) => {
                let start_date = moment(elem.date).set({
                    hour: moment(elem.start_date, 'HH:mm').hour(),
                    minute: moment(elem.start_date, 'HH:mm').minute(),
                });

                let end_date = moment(elem.date).set({
                    hour: moment(elem.end_date, 'HH:mm').hour(),
                    minute: moment(elem.end_date, 'HH:mm').minute(),
                });

                if (moment(date).isSameOrAfter(start_date, 'minutes') && moment(date).isBefore(end_date, 'minutes')) {
                    className = 'background-calendar-weeks-open';
                }
            })
        }

        if (exceptionalClose && exceptionalClose.length > 0) {
            exceptionalClose.forEach((elem) => {
                if (moment(date).isSameOrAfter(elem.start_date, 'minutes') && moment(date).isBefore(elem.end_date, 'minutes')) {
                    className = 'background-calendar-weeks-close';
                }
            })
        }
                    
        return {
            className: className,
        };
    }

    static customSlotPropGetterSub(date, arrayOfficeHours, exceptionalOpen, agreements, exceptionalClose) {
        let className = 'background-calendar-weeks-not-work';

        if (arrayOfficeHours && arrayOfficeHours.length > 0) {
            arrayOfficeHours.forEach((officeHours) => {
                if (officeHours) {
                    officeHours.forEach((hours) => {
                        if (hours) {
                            if (moment(date).isSameOrAfter(hours.start, 'minutes') && moment(date).isBefore(hours.end, 'minutes')) {
                                className = 'background-calendar-weeks-work';
                            }
                        }
                    })
                }
            })
        }

        if (exceptionalOpen && exceptionalOpen.length > 0) {
            const foundedExceptionnalOpen = exceptionalOpen.find((elem) => {
                let start_date = moment(elem.date).set({
                    hour: moment(elem.start_date, 'HH:mm').hour(),
                    minute: moment(elem.start_date, 'HH:mm').minute(),
                });

                let end_date = moment(elem.date).set({
                    hour: moment(elem.end_date, 'HH:mm').hour(),
                    minute: moment(elem.end_date, 'HH:mm').minute(),
                });

                return (moment(date).isSameOrAfter(start_date, 'minutes') && moment(date).isBefore(end_date, 'minutes'))
            })

            const foundedAgreement = agreements.find((agreement) => {
                return agreement.replacement.days_available.find((day)=> {
                    return moment(day.start).isSame(date, 'day')
                })
            })
            if(foundedExceptionnalOpen && foundedAgreement) {
                if(foundedExceptionnalOpen.doctor_id === foundedAgreement.doctor_id) {
                    className = 'background-calendar-weeks-open';
                }
            }
        }

        if (exceptionalClose && exceptionalClose.length > 0) {
            const foundedExceptionnalClose = exceptionalClose.find((elem) => {
                return (moment(date).isSameOrAfter(elem.start_date, 'minutes') && moment(date).isBefore(elem.end_date, 'minutes'))
            })

            const foundedAgreement = agreements.find((agreement) => {
                return agreement.replacement.days_available.find((day)=> {
                    return moment(day.start).isSame(date, 'day')
                })
            })
            if(foundedExceptionnalClose && foundedAgreement) {
                if(foundedExceptionnalClose.doctor_id === foundedAgreement.doctor_id) {
                    className = 'background-calendar-weeks-close';
                }
            }
        }
                    
        return {
            className: className,
        };
    }

    // Définit ce qui peut-être déplacé sur le calendrier
    static draggableAccessor(event) {
        if (event.css === 'appointment') {
            return true;
        } else {
            return false;
        }
    }

    static checkOverlapWithAppointment(start_date, end_date, startHour, endHour, appointments, userId) {
        let overlap = '';
        
        if (appointments && appointments.length > 0) {
            let startDate = moment(start_date).set({
                hour: moment(startHour, 'HH:mm').hour(),
                minute: moment(startHour, 'HH:mm').minute(),
            });
    
            let endDate = moment(end_date).set({
                hour: moment(endHour, 'HH:mm').hour(),
                minute: moment(endHour, 'HH:mm').minute(),
            });


            appointments.forEach((elem) => {
                if (elem._id === userId) {
                    elem.appointments.forEach((appointment) => {
                        let appointmentDateStart = moment(appointment.appointment_date);
                        let appointmentDateEnd = moment(appointment.appointment_date).add(appointment.duration, 'minutes');

                        if (moment(appointmentDateStart).isSame(start_date, 'day') && moment(endDate).isAfter(appointmentDateStart, 'minutes') && moment(startDate).isBefore(appointmentDateEnd, 'minutes')) {
                            overlap = 'Vous êtes sur le point de poser une absence sur une période durant laquelle des consultations sont déjà prévus.';
                            return;
                        }
                    })
                }
            })
        }

        return overlap;
    }

    static officeHours(date, role, officeTimeTable, agreements) {
        let startDateForWhile = moment(date).startOf('week');
        let endDateForWhile = moment(date).endOf('week').subtract(1, 'day');

        let arrayOfficeHours = [];

        if (role === 'doctor') {
            while(startDateForWhile.isSameOrBefore(endDateForWhile, 'day')) {
                let hours = OfficeTimeTableEnt.getScheduleForDayForWeekCalendar(officeTimeTable, startDateForWhile);
                let push = true;

                if (!hours[0]) {
                    push = false;
                } else if (hours[0] && !hours[1]) {
                    hours.splice(1, 1);
                }
                if (push === true) {
                    arrayOfficeHours.push(hours);
                }
                startDateForWhile.add(1, 'day');
            }
        } else if (role === 'substitute') {
            if (agreements && agreements.length > 0) {
                while(startDateForWhile.isSameOrBefore(endDateForWhile, 'day')) {
                    let data = {
                        push: false,
                        officeTimeTable: []
                    };

                    agreements.forEach((agreement) => {
                        agreement.replacement.days_available.forEach((day) => {
                            if (moment(day.start).isSame(startDateForWhile, 'day')) {
                                data.push = true;
                                data.officeTimeTable = agreement.officeTimeTable
                            }
                        })
                    })

                    if (data.push === true) {
                        arrayOfficeHours.push(OfficeTimeTableEnt.getScheduleForDayForWeekCalendar(data.officeTimeTable, startDateForWhile))
                    }
                    startDateForWhile.add(1, 'day');
                }
            }
        } else if (role === 'secretary') {
            while (startDateForWhile.isSameOrBefore(endDateForWhile, 'day')) {
                let hours = OfficeTimeTableEnt.getScheduleForDayForWeekCalendar(officeTimeTable, startDateForWhile);
                let push = true;

                if (!hours[0]) {
                    push = false;
                } else if (hours[0] && !hours[1]) {
                    hours.splice(1, 1);
                }
                if (push === true) {
                    arrayOfficeHours.push(hours);
                }
                startDateForWhile.add(1, 'day');
            }
        }

        return arrayOfficeHours;
    }

    static formatCalendarEvents(calendarEvents) {
        let arrayCalendarEvents = [];

        if (calendarEvents && calendarEvents.length > 0) {
            for (let calendarEvent of calendarEvents) {
                
                let event = {
                    title: calendarEvent.title,
                    start: new Date(calendarEvent.start_date),
                    end: new Date(calendarEvent.end_date),
                    allDay: true,
                    color: 'Replacement',
                    css: 'calendarEvents',
                    original: calendarEvent
                };
    
                arrayCalendarEvents.push(event);
            }
        }

        return arrayCalendarEvents;
    }

    static formatReplacements(replacements) {
        let arrayReplacements = [];
        for (let replacement of replacements) {
            if (replacement.invitation_by_doctor === true) {
                let statusSub = 0;
                if (replacement.applicants) {
                    statusSub = replacement.applicants[0].substitute_status;
                }
                if (statusSub === 2) continue;
            }

            if (replacement.concludedAgreements) {
                if (replacement.concludedAgreements.doctor_initials && replacement.concludedAgreements.doctor_signature && replacement.concludedAgreements.substitute_initials && replacement.concludedAgreements.substitute_signature) {
                    continue;
                }
            };

            let events = [];

            replacement.days_available.forEach((elem) => {
                let event = {};
                event.allDay = true;
                event.title = 'Annonce'
                event.color = replacement.type;
                event.start = new Date(elem.start);
                event.end = new Date(elem.end);
                event.original = replacement;
                event.css = 'announcement';

                events.push(event);
            })

            arrayReplacements = arrayReplacements.concat(events);
        }
        
        return arrayReplacements;
    };
    
}
