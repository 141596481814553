var values = [
    { label: "Other", value: "0" },
    { label: "Boucoupsy", value: "1" },
    { label: "BaseDpsy", value: "2" },
    { label: "Getapp", value: "3" },
    { label: "Psyapps", value: "4" },
    { label: "Magestionpsy", value: "5" },
    { label: "Scriboupsy", value: "6" },
    { label: "Moncabinetliberal", value: "7" },
    { label: "Psyconseil", value: "8" },
    { label: "Lepsy", value: "9" },
    { label: "None", value: "10"},
];

export default class PsychologistMedicalSoftwares {

    static values() {
        return values;
    }

    static getLabel(nbr) {
        for (let value of values) {
            if (value.value === nbr) return value.label;
        }
    }

};