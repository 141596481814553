import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { FormattedHTMLMessage } from 'react-intl';


export default class DTransmissionReminderModal extends React.Component {

    redirect(){
        window.location.href = "#/home/dsettings/transmissions";
        this.props.toggle();
    }

    render() {
        return (
            <Modal isOpen={true} toggle={() => this.props.toggle()} size="md" className="simple-modal simple-modal-blue srounded">

                <ModalHeader toggle={() => this.props.toggle()}>
                    <button type="button" className="close d-inline-block b-green" aria-label="Close" onClick={() => this.props.toggle()} ><span aria-hidden="true">×</span></button>
                </ModalHeader>

                <ModalBody className="p-4">

                    <h3 className="mb-4"> <FormattedMessage id="DTransmissionReminderModal.Title" /></h3>

                    <div className="w-100 mx-auto text-center mb-3">
                        <div className="text-center mx-auto" style={{maxWidth: 500}}>
                            <FormattedHTMLMessage id="DTransmissionReminderModal.Content" />
                        </div>
                    </div>

                    <div className="mt-4 mb-3">
                        <Button
                            className="srounded pl-5 pr-5 pt-2 pb-2 lowercase text-blue bg-white btn opacity-1 mr-0"
                            onClick={() => this.redirect()}>
                            <FormattedMessage id="DTransmissionReminderModal.Button" />&nbsp;!
                        </Button>
                    </div>

                </ModalBody>

            </Modal>
        );

    }

}
