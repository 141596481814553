import APIUrl from "../APIUrl";
import axios from "axios";
import { updsAction } from "../global/global";
import FileUtil from "../../util/FileUtil";

const rootUrl = process.env.REACT_APP_BE_URL || "http://localhost:7000";

export const payAgreement = (obj, cbk) => {
    return function (dispatch) {
        return axios.post(APIUrl.payAgreement, obj)
            .then(function (response) {
                if (cbk) cbk();
            })
            .catch(function (err) {
                throw err;
            });
    };
};

export const cancelAgreement = (obj, cbk) => {
    return function (dispatch) {
        return axios.post(APIUrl.cancelAgreement, obj)
            .then(function (response) {
                if (cbk) cbk();
                if (response && response.data) dispatch(updsAction("agreement", response.data.message.agreement));
            })
            .catch(function (err) {
                throw err;
            });
    };
};

export const downloadContract = (agreementId, cbk) => {
    return axios.get(APIUrl.downloadContract.replace("{{agreementId}}", agreementId), { responseType: "arraybuffer" })
        .then(function (response) {
            if (cbk) cbk();

            FileUtil.downloadPDF("repl-contract-" + agreementId, response.data);
        })
        .catch(function (err) {
            throw err;
        });
};


export const concludedAgreementOverlaps = function(start, end, type, duration, days_available, callback){
    return axios.post(APIUrl.concludedAgreementOverlaps, { start, end, type, duration, days_available })
        .then(function(response){
            if(callback) callback(response.data.message);
        })
        .catch(function(err){
            throw err;
        });
};

export const getAgreementConcluded = (substitute_id, cbk) => {
    return axios.get(APIUrl.getAgreementConcluded.replace("{{substitute_id}}", substitute_id))
        .then(function (response) {
            if (cbk) cbk(response.data.message.agreements);
        })
        .catch(function (err) {
            throw err;
        });
};

export const getAgreementCount = (cbk) => {
    return axios.get(APIUrl.getAgreementCount)
        .then(function (response) {
            if (cbk) cbk(response.data.message.agreementsCount);
        })
        .catch(function (err) {
            throw err;
        });
};

export function allBySubWithDate(startDate, endDate) {
    return dispatch => {
		dispatch(request());
        var url = rootUrl + "/agreements/all-by-sub/" + startDate + '/' + endDate;

        return axios.get(url)
            .then(function (response) {
                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "GETS_AGREEMENTS_SUB_REQUEST", getsAgreements: false, isGetReplacementsWithDate: false }}
	function success(result) { return { type: "GETS_AGREEMENTS_SUB_SUCCESS", agreements: result.agreements,  getsAgreements: true, isGetReplacementsWithDate: true}}
	function failure() { return { type: "GETS_AGREEMENTS_SUB_FAILURE", getsAgreements: false, isGetReplacementsWithDate: false}}
};

export function allByDoctorWithDate(startDate, endDate) {
    return dispatch => {
		dispatch(request());
        var url = rootUrl + "/agreements/all-by-doctor/" + startDate + '/' + endDate;

        return axios.get(url)
            .then(function (response) {
                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "GETS_AGREEMENTS_DOC_REQUEST", isGettingReplacementsWtihDate: true, isGetReplacementsWithDate: false}}
	function success(result) { return { type: "GETS_AGREEMENTS_DOC_SUCCESS", agreements: result.agreements, isGettingReplacementsWtihDate: false, isGetReplacementsWithDate: true}}
	function failure() { return { type: "GETS_AGREEMENTS_DOC_FAILURE", isGettingReplacementsWtihDate: false, isGetReplacementsWithDate: false}}
};

export function getDoctorAgreementsBySecretaryWithDate(doctorId, startDate, endDate) {
    return dispatch => {
        // console.log('La')
		dispatch(request());
        var url = `${rootUrl}/agreements/secretary/all-by-doctor/${doctorId}/${startDate}/${endDate}` ;
        // const params = { doctor_id, startDate, endDate };

        return axios.get(url)
            .then(function (response) {
                if (response && response.data) {
                    const obj = response.data.message;
                    dispatch(success(obj));
                } else {
                    dispatch(failure())
                }
            })
            .catch(function (err) {
				dispatch(failure())
            });
    };
	function request() { return { type: "GETS_AGREEMENTS_DOC_REQUEST", isGettingReplacementsWtihDate: true, isGetReplacementsWithDate: false}}
	function success(result) { return { type: "GETS_AGREEMENTS_DOC_SUCCESS", agreements: result.agreements, isGettingReplacementsWtihDate: false, isGetReplacementsWithDate: true}}
	function failure() { return { type: "GETS_AGREEMENTS_DOC_FAILURE", isGettingReplacementsWtihDate: false, isGetReplacementsWithDate: false}}
};