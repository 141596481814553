import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Collapse } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { applyToReplacement } from "../../../actions/replacements/replacements";
import Util from '../../../util/Util';
import ReplacementEnt from "../../../entities/ReplacementEnt";
import ModalDaysExceptionView from './../../substitutes/profile/ModalDaysExceptionView';
import { concludedAgreementOverlaps } from '../../../actions/agreement/agreement';
import { calendarEventOverlaps } from '../../../actions/calendarEvent/calendarEvent';
import DateUtil from '../../../util/DateUtil';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import AgreementEnt from '../../../entities/AgreementEnt';
import moment from 'moment';

class DoctorReplacementProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            collapseGuards: false,
            disabled: false,
            modal: null,
            overlap: false,
            overlapCalendarEvent: false
        };
        moment.locale('fr');

        this.openModalDaysException = this.openModalDaysException.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        var replacement = this.props.agreementReplacement;
        if (!replacement && this.props.replacementId) replacement = this.props.replacements.find(r => r._id === this.props.replacementId);

        this.props.onCalendarEventOverlaps(replacement.start_date, replacement.end_date, replacement.days_available, replacement.type, (res) => {
            this.setState({ overlapCalendarEvent: res.overlaps });
        });

        if (replacement.guards && replacement.guards.length > 0) {
            this.setState({ collapseGuards: true })
        }
        if (replacement.applicants && replacement.applicants.length > 0) {
            replacement.applicants.forEach((elem) => {
                if (elem.negociation && elem.negociation.length > 0 && this.props.user._id === elem.substitute_id) {
                    let last_element = elem.negociation[elem.negociation.length - 1];
                    if (elem.doctor_status === 3 && elem.substitute_status === 0) {
                        this.props.onConcludedAgreementOverlaps(last_element.start_date, last_element.end_date, replacement.type, undefined, last_element.days_available, (res) => {
                            this.setState({
                                overlap: res.overlaps
                            })
                        });
                        this.props.onCalendarEventOverlaps(last_element.start_date, last_element.end_date, last_element.days_available, replacement.type, (res) => {
                            this.setState({ overlapCalendarEvent: res.overlaps });
                        });
                    }
                }
            })
        }
    }

    closeModal() {
        this.setState({ modal: null });
    }

    openModalDaysException(event) {
        event.preventDefault();
        var replacement = this.props.agreementReplacement;
        let replacementId = this.props.replacementId;

        if (!replacement && replacementId) replacement = this.props.replacements.find(r => r._id === replacementId);
        let last_element;
        let daysNotAvailable;
        replacement.applicants.forEach((elem) => {
            last_element = elem.negociation[elem.negociation.length - 1];
            daysNotAvailable = DateUtil.getDaysNegociation(replacement.days_available, last_element.days_available);
            return;
        });
        
        this.setState({
            modal: <ModalDaysExceptionView 
                close={this.closeModal}
                daysNotAvailable={daysNotAvailable}
            />
        })
    }

    render() {
        const { replacementId, replacements, user, agreementReplacement, doctor } = this.props;
        var agreement = this.props.agreement
        // Do we have a replacement to add to the card?
        var replacement = agreementReplacement;

        if (!replacement && replacementId) replacement = replacements.find(r => r._id === replacementId);

        var hasPendingAgreement, hasConcludedAgreement, hasSignedAgreement, 
            hasApplied = ReplacementEnt.hasApplied(replacement, user),
            alreadySent = AgreementEnt.alreadySent(agreement);

        if (!agreement && replacement.agreements) agreement = replacement.agreements.find(a => a.substitute_id === user._id);

        if (agreement) {
            hasPendingAgreement = (!!agreement);
            // Medecin installé
            hasConcludedAgreement = AgreementEnt.isConcluded(agreement);
            // Medecin remplaçant
            hasSignedAgreement = AgreementEnt.isSigned(agreement);
        }

        let startDate = DateUtil.toDate(replacement.start_date);
        let endDate;
        if (replacement.type === "guard"){
            startDate = DateUtil.toDateWithHour(replacement.start_date)
            endDate = DateUtil.toDateWithHour(replacement.end_date);
        } else if (replacement.type === "regular") {
            if (!replacement.end_date) {
                endDate = DateUtil.toDate(ReplacementEnt.getRegularReplEnd(replacement.day, replacement.duration, replacement.start_date));
            } else {
                endDate = DateUtil.toDate(replacement.end_date);
            }
        } else {
            endDate = DateUtil.toDate(replacement.end_date);
        } 

        // Si le medecin a invité le remplaçant
        let waitSubsValidation = replacement.applicants.find(t => user._id === t.substitute_id && t.doctor_status === 1 && (t.substitute_status === 0 || t.substitute_status === 4))
        let newCondition = {}
        let negociation = false;
        let acceptBySub = false;
        let last_element;
        let viewDaysException = false;
        let numberDaysException = 0;
        let newRetrocession;
        let newDates;
        let viewThinking = false;
        let viewNego = true;
        let retrocession = replacement.retrocession || replacement.guard_retrocession;

        replacement.applicants.forEach((elem) => {
            if (elem.substitute_id === user._id) {
                if (elem.negociation && elem.negociation.length === 0 && !replacement.availability_id) {
                    viewNego = false; 
                }
                viewThinking = true;
                if (elem.doctor_status === 4 || elem.substitute_status === 4 || elem.substitute_status === 1 ||  elem.substitute_status === 3) {
                    viewThinking = false;
                }
                if (elem.negociation && elem.negociation.length > 0) {
                    if (elem.doctor_status === 3 && (elem.substitute_status === 0 || elem.substitute_status === 4)) {
                        acceptBySub = true;
                    }
                    if (elem.doctor_status === 1 && elem.substitute_status === 1) {
                        negociation = false;
                    } else {
                        negociation = true;
                    }
                    last_element = elem.negociation[elem.negociation.length - 1];
                    // Quand le contrat est en attente de signature, pour que ce soit les bonnes dates qui soient affichés
                    if(agreement && !hasSignedAgreement) {
                        startDate = DateUtil.toDate(last_element.start_date);
                        endDate = DateUtil.toDate(last_element.end_date);
                    }
                    retrocession = last_element.retrocession;
                    // Si y'a des jours en exceptions
                    let daysNotAvailable = DateUtil.getDaysNegociation(replacement.days_available, last_element.days_available);
                    if (daysNotAvailable && daysNotAvailable.length > 0) {
                        viewDaysException = true;
                        numberDaysException = daysNotAvailable.length;
                    }
    
                    // Récupère l'avant dernier élement de négo
                    let previousElement = elem.negociation[elem.negociation.length - 2];
                    if (previousElement !== undefined) {
                        // Si le dernier est différent de l'avant dernier
                        if (last_element.retrocession !== previousElement.retrocession) {
                            newRetrocession = 'Nouvelle rétrocession';
                        }
                    // Si c'est la 1er négo
                    } else if (last_element.retrocession !== replacement.retrocession) {
                        newRetrocession = 'Nouvelle rétrocession';
                    } 
    
                    // Récupère l'avant dernier élement de négo
                    if (previousElement !== undefined) {
                        if (last_element.start_date !== previousElement.start_date || last_element.end_date !== previousElement.end_date || last_element.days_available.length !== previousElement.days_available.length) {
                            newDates = 'une nouvelle date';
                        }
                    } else if (last_element.start_date !== replacement.start_date || last_element.end_date !== replacement.end_date || last_element.days_available.length !== replacement.days_available.length) {
                        newDates = 'une nouvelle date';
                    }
                    newCondition = {
                        start_date: last_element.start_date,
                        end_date: last_element.end_date,
                        guards: last_element.guards,
                        retrocession: replacement.type === 'guard' ? last_element.guard_retrocession : last_element.retrocession
                    }
                }
            }
        })

        let guards = [];
        let newGuards;
        let countGuards = 0;
        // Si ya une garde et si y'a déjà eu une négo alors on affiche les valeurs de la négo
        if (last_element !== undefined && replacement.guards.length > 0) {
            guards = last_element.guards;
            last_element.guards.forEach((elem) => {
                if (elem.accepted === false) {
                    newGuards = 'décliner une garde'
                } else {
                    countGuards += 1;
                }
            })
        } else {
            guards = replacement.guards;
        }
        return (
            <>
            <div>
                {(negociation === true) &&
                    <div>
                        <div className="separatorNego">
                            {this.props.doctor.first_name.toUpperCase() + " vous propose :".toUpperCase()}
                            {newRetrocession !== undefined && 
                                <div className="colorNegociation">
                                    - {newRetrocession}
                                </div>
                            }
                            {newDates !== undefined && 
                                <div className="colorNegociation">
                                    - {newDates}
                                </div>
                            }
                            {newGuards !== undefined && 
                                <div className="colorNegociation">
                                    - {newGuards}
                                </div>
                            }

                        </div>
                    </div>
                }
                {this.state.overlap === true &&
                    <div className="error">
                        <p>Vous avez déjà un remplacement signé qui chevauche la période proposée, vous devez changer les dates</p>
                    </div>
                }
                <div className={"p-2 pt-0 " + (negociation === true ? 'row' : '')}>
                    <div className={"profile-repl " + (negociation === true ? 'oldPropal' : '') }>
                        <div className={"profile-repl-title bg-" + replacement.type}>
                            {negociation === true &&
                                'Ancien '
                            }
                            <FormattedMessage id={"Replacement.OneLine." + Util.fstLetUpCase(replacement.type)} />
                            {replacement.type === 'regular' &&
                                <span>
                                    &nbsp;(<FormattedMessage id={"Day." + DateUtil.dayToNbr(replacement.day[0])} />)
                                </span>
                            }
                        </div>

                        <div className="profile-repl-details">
                            <div>
                                <FormattedMessage id="Dates" />&nbsp;:&nbsp;
                                <FormattedMessage id="from" />&nbsp;{startDate}&nbsp;
                                <FormattedMessage id="To" />&nbsp;{endDate}

                            </div>

                            {replacement.overlapping &&
                                <div className="text-danger" ><FormattedMessage id="Overlapping.Replacement" /></div>
                            }

                            <div>
                                <FormattedMessage id="Proposed.Retrocession" />
                                {replacement.type !== 'guard' ?
                                    <span>
                                        &nbsp;:&nbsp;{(negociation === false ? retrocession : replacement.retrocession)}&nbsp;%
                                    </span>
                                    :
                                    <span>
                                        &nbsp;:&nbsp;{(negociation === false ? retrocession : replacement.guard_retrocession)}&nbsp;%
                                    </span>
                                }
                            </div>

                            <div>
                                <FormattedMessage id="Parking" />&nbsp;:&nbsp;{(replacement.parking) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                            </div>

                            {(viewDaysException === true && agreement) &&
                                <div>
                                        <FormattedMessage id="Days.Exceptions" /> : <button onClick={this.openModalDaysException}>{numberDaysException}</button>
                                    {this.state.modal}
                                </div>
                            }

                            <div>
                                <FormattedMessage id="Accommodation" />&nbsp;:&nbsp;{(replacement.housing) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                            </div>
                            {(replacement.type !== "guard") &&
                                <div>
                                    <FormattedMessage id="Guards" />&nbsp;:&nbsp;
                                            <span className={"guards-badge " + ((replacement.guards && replacement.guards.length > 0) ? "guards-badge-active" : "")}>
                                        {(replacement.guards) ? replacement.guards.length : 0}
                                    </span>

                                    {(replacement.guards && replacement.guards.length > 0) && negociation === false &&
                                        <>
                                            &nbsp;&nbsp;<span className="collapse-text clickable"
                                                onClick={() => this.setState({ collapseGuards: !this.state.collapseGuards })}>
                                                {(this.state.collapseGuards) ? "-" : "+"}&nbsp;
                                                        <FormattedMessage id="Details" />
                                            </span>
                                        </>
                                    }
                                </div>
                            }
                            {this.state.overlapCalendarEvent === true && 
                                <div className="text-danger" ><FormattedMessage id="Overlapping.Events" /></div>
                            }

                        </div>

                    </div>

                    {negociation === true && 
                        <div className="profile-repl">
                            <div className={"profile-repl-title bg-" + replacement.type}>
                                Nouveau <FormattedMessage id={"Replacement.OneLine." + Util.fstLetUpCase(replacement.type)} />
                                {replacement.type === 'regular' &&
                                <span>
                                    &nbsp;(<FormattedMessage id={"Day." + DateUtil.dayToNbr(replacement.day[0])} />)
                                </span>
                            }
                            </div>

                            <div className="profile-repl-details">
                                <div>
                                    <FormattedMessage id="Dates" />&nbsp;:&nbsp;
                                            {(replacement.type === "guard") ?
                                        <>
                                            <FormattedMessage id="from" />&nbsp;{DateUtil.toDateWithHour(newCondition.start_date)}&nbsp;
                                                    <FormattedMessage id="To" />&nbsp;{endDate}
                                        </>
                                        :
                                        <>
                                            <FormattedMessage id="from" />&nbsp;{DateUtil.toDate(newCondition.start_date)}&nbsp;
                                                    <FormattedMessage id="To" />&nbsp;{DateUtil.toDate(newCondition.end_date)}
                                        </>
                                    }
                                </div>

                                <div>
                                    <FormattedMessage id="Proposed.Retrocession" />&nbsp;:&nbsp;
                                    <span className="retrocessionDisplay">
                                        {newCondition.retrocession || newCondition.guard_retrocession}&nbsp;%
                                    </span>
                                </div>

                                <div>
                                    <FormattedMessage id="Parking" />&nbsp;:&nbsp;{(replacement.parking) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                                </div>

                                <div>
                                    <FormattedMessage id="Accommodation" />&nbsp;:&nbsp;{(replacement.housing) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                                </div>

                                {viewDaysException === true &&
                                    <div>
                                         <FormattedMessage id="Days.Exceptions" /> : <button onClick={this.openModalDaysException}>{numberDaysException}</button>
                                        {this.state.modal}
                                    </div>
                                }

                                {(replacement.type !== "guard") &&
                                    <div>
                                        <FormattedMessage id="Guards" />&nbsp;:&nbsp;
                                                <span className={"guards-badge " + ((replacement.guards && replacement.guards.length > 0) ? "guards-badge-active" : "")}>
                                                    {countGuards}
                                        </span>

                                        {(replacement.guards && replacement.guards.length > 0) &&
                                            <>
                                                &nbsp;&nbsp;<span className="collapse-text clickable"
                                                    onClick={() => this.setState({ collapseGuards: !this.state.collapseGuards })}>
                                                    {(this.state.collapseGuards) ? "-" : "+"}&nbsp;
                                                            <FormattedMessage id="Details" />
                                                </span>
                                            </>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    <Collapse isOpen={this.state.collapseGuards} className="w-100">
                        <div className="separatorNego">
                            <h5>Garde</h5>
                        </div>
                        {(guards && guards.length > 0) &&
                            <Row className="m-0 text-center">
                                {guards.map((guard, i) => {
                                    return (
                                        <Col sm="4" className="col-centered" key={i}>
                                            <div className={"profile-repl " + (guard.accepted === false ? 'oldPropal oldPropalLine' : '') }>
                                                <div className={"profile-repl-title bg-guard"}>
                                                    <FormattedMessage id={"Replacement.Guard"} />
                                                </div>

                                                <div className="profile-repl-details mb-3">
                                                    <div>
                                                        <FormattedMessage id="Dates" />&nbsp;:&nbsp;
                                                            <FormattedMessage id="from" />&nbsp;{DateUtil.toDateWithHour(guard.start_date)}&nbsp;
                                                        </div>
                                                    <div>
                                                        <FormattedMessage id="To" />&nbsp;{DateUtil.toDateWithHour(guard.end_date)}
                                                    </div>

                                                    <div>
                                                        <FormattedMessage id="Proposed.Retrocession" />&nbsp;:&nbsp;
                                                            {(last_element) ? last_element.guard_retrocession : replacement.guard_retrocession}&nbsp;%
                                                            </div>

                                                    <div>
                                                        <FormattedMessage id="Guard.In.Office" />&nbsp;:&nbsp;
                                                                {(guard.office) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                                                    </div>

                                                    <div>
                                                        <FormattedMessage id="Guard.Visits" />&nbsp;:&nbsp;
                                                                {(guard.visits) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                                                    </div>

                                                    <div>
                                                        <FormattedMessage id="Paid.By.Fifteenth" />&nbsp;:&nbsp;
                                                                {(guard.paid_by_the_fifteenth) ? <FormattedMessage id="Yes" /> : <FormattedMessage id="No" />}
                                                    </div>

                                                </div>

                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        }
                    </Collapse>
                </div>

                {this.state.overlap === false &&
                    <div>
                        {//Substitute must not be in applicants
                            ((!hasApplied || waitSubsValidation) || acceptBySub === true) &&
                            <div className={"col-12 mb-3 text-center d-inline-block " + (this.state.overlapCalendarEvent || moment(startDate, "DD/MM/YYYY").isBefore(moment(), 'days') ? "disabled" : "")}>
                                <LaddaButton
                                    className= "btn btn-ladda srounded pl-5 pr-5 pt-2 pb-2 text-white btn bg-green"
                                    loading={this.state.disabled}
                                    onClick={() => this.props.acceptRempl()}
                                    disabled={this.state.overlapCalendarEvent || moment(startDate, "DD/MM/YYYY").isBefore(moment(), 'days')}
                                    >
                                    <FormattedMessage id={!hasApplied ? "I.Am.Interested" : "I.Accept"} />

                                </LaddaButton>
                            </div>
                        }
                    </div>
                }

                { (hasApplied && !agreement && !this.props.viewByCalendar) && 
                    <LaddaButton
                        className="btn btn-ladda srounded pl-5 pr-5 pt-2 pb-2 text-white btn bg-red"
                        data-style={EXPAND_LEFT}
                        loading={this.state.disabled}
                        onClick={this.props.refuseRempl}
                        disabled={ReplacementEnt.isConcluded(replacement) || ReplacementEnt.ongoingAgreement(replacement, user) || this.state.disabled}>
                        <FormattedMessage id={waitSubsValidation ? "I.Refuse" : "Unapply" } />
                    </LaddaButton>
                }

                {agreement && !this.props.viewByCalendar && 
                    // Substitute must be in applicants and doctor_status should equal 0 and there is no agreement yet
                    <div className={"btn bg-red srounded lowercase pl-3 pr-3 text-white lp-2 d-inline-block m-2 " + ((alreadySent || this.state.disabled) ? "disabled" : "")}
                        onClick={(e) => {
                            if (alreadySent || this.state.disabled) return;
                            this.props.cancelAgreement(e, agreement._id)
                        }}>
                        <FormattedMessage id="Give.Up.Mission" />
                    </div>
                }

                {(!hasPendingAgreement && viewNego === true && !this.props.viewByCalendar) && 
                    <div className="mb-3 text-center d-inline-block">
                        <LaddaButton onClick={this.props.openNegociationModal} className="btn btn-ladda srounded pl-5 pr-5 pt-2 pb-2 text-white btn bg-blue"><FormattedMessage id="Nego" /></LaddaButton>
                    </div>
                }

                {(this.state.overlap === false && !hasPendingAgreement && viewThinking === true) && 
                    <div className="mb-3 text-center d-inline-block">
                        <LaddaButton onClick={this.props.thinkChoice} className="btn btn-ladda srounded pl-5 pr-5 pt-2 pb-2 text-white btn bg-yellow"><FormattedMessage id="Thinking" /></LaddaButton>
                    </div>
                }
                
                {agreement && !hasSignedAgreement &&
                    <div className="mb-3 text-center d-inline-block">
                        <LaddaButton
                            className={"btn bg-blue srounded lowercase pl-5 pr-5 p-2 pb-2 text-white m-2"}
                            onClick={() => this.props.onApply()}
                            data-style={EXPAND_LEFT}>
                            <FormattedMessage id={hasConcludedAgreement ? "Sign.contract" : "Sign.contract"} />
                        </LaddaButton>
                    </div>
                }

                {// Agreement should have been sent to council
                (agreement && !agreement.invitation && agreement.doctor_initials && agreement.substitute_initials && !this.props.viewByCalendar) &&
                    <div className={"btn bg-blue srounded lowercase pl-5 pr-5 text-white lp-2 d-inline-block m-2 " +
                        ((this.state.disabled) ? "disabled" : "")}
                        onClick={(e) => this.props.downloadContract(e, agreement._id)}>
                        <FormattedHTMLMessage id="Download.Contract" />
                    </div>
                }
                {// Agreement should exists (not ouside Hydrogen)
                (alreadySent && agreement.invitation) &&
                    <div className={"btn bg-blue srounded lowercase pl-5 pr-5 text-white lp-2 d-inline-block m-2 disabled"}>
                        <FormattedHTMLMessage id="Contract.Not.Hydrogen" />
                    </div>
                }
                {//Doctor and Substitute should have signed agreement
                hasConcludedAgreement && hasSignedAgreement && !alreadySent && !this.props.viewByCalendar &&
                    <div className={"btn bg-green srounded lowercase pl-3 pr-3 text-white lp-2 d-inline-block m-2 " + ((alreadySent || this.state.disabled) ? "disabled" : "")}
                        onClick={(e) => {
                            if (alreadySent || this.state.disabled) return;
                            this.props.sendToCouncil(e, agreement._id, doctor)
                        }}>
                        <FormattedHTMLMessage id="Transmit.To.Council" />
                    </div>
                }
            </div>
            </>
        );
    };

}

const mapStateToProps = state => {
    return {
        user: state.global.user,
        replacements: state.global.replacements
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onApplyToReplacement: (replacementId, callback) => dispatch(applyToReplacement(replacementId, callback)),
        onConcludedAgreementOverlaps: (start, end, type, duration, days_available, cbk) => concludedAgreementOverlaps(start, end, type, duration, days_available, cbk),
		onCalendarEventOverlaps: (start, end, days_available, type, cbk) => calendarEventOverlaps(start, end, days_available, type, cbk),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorReplacementProfile);