import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Card } from 'reactstrap';
import { clears, getsBy } from '../../../actions/global/global';
import RadiologistProfile from "./RadiologistProfile";
import RadiologistPracticesProfile from './RadiologistPracticesProfile';
import RadiologistAboutProfile from './RadiologistAboutProfile';
import RadiologistMoreDetailsProfile from './RadiologistMoreDetailsProfile';
import OfficeTimetable from '../../doctors/office/OfficeTimetable';

class RadiologistAccountProfile extends Component {

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    render() {
        const { user, radiologistCharacteristic, officeTimeTable } = this.props;

        if (!user || !radiologistCharacteristic || !officeTimeTable) return this.loading();

        return (
            <React.Fragment>

                <React.Fragment>

                    <div className="w-100 text-white text-center uppercase mb-4 lp-2">
                        <h4><FormattedMessage id="My.Profile" /></h4>
                    </div>

                    <Row>

                        <Col sm="12" md="6">

                            <Card className="pt-5 rounded-0">
                                <RadiologistProfile
                                    radiologist={user}
                                    radiologistCharacteristic={radiologistCharacteristic}
                                    officeTimeTable={officeTimeTable}
                                    showChar={false}
                                />
                            </Card>
                        </Col>

                        <Col sm="12" md="6">

                            <Card className="p-5 text-center rounded-0" style={{ minHeight: "639px" }}>

                                <div className="text-left mx-auto">

                                    <div className="text-center">
                                        <RadiologistPracticesProfile radiologistCharacteristic={radiologistCharacteristic} />
                                    </div>

                                    <div className="mt-4">
                                        <RadiologistAboutProfile radiologistCharacteristic={radiologistCharacteristic} />
                                    </div>

                                    <div className="mt-5 mb-4">
                                        <RadiologistMoreDetailsProfile radiologistCharacteristic={radiologistCharacteristic} />
                                    </div>

                                </div>

                            </Card>

                        </Col>

                        <Col sm="12" md="12">

                            <div className="max-w-800px mx-auto m-0 p-0">
                                <OfficeTimetable
									register={false}
                                />
                            </div>

                        </Col>
                    </Row>

                </React.Fragment>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
        radiologistCharacteristic: state.global.radiologistCharacteristic,
        officeTimeTable: state.global.officeTimeTable
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RadiologistAccountProfile);