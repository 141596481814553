import React, { Component } from 'react';
import DoctorForm from "./forms/DoctorForm";
import { Row, Col } from 'reactstrap';

export default class SecStep2 extends Component {

	constructor(props) {
		super(props);

		this.form = React.createRef();
	}

	isValidated() {
		return this.form.current.validate();
	}

	back(data) {
		this.props.jumpToStep(0);
	};

	render() {
		// Hide or show inputs based on previously entered data
		const defaultValues = this.props.getStore();

		const submitCbk = (data) => {
			const callback = () => {
				this.props.jumpToStep(2);
				if (window.scrollTo) window.scrollTo(0, 0);
			}

			this.props.submitSecretary(callback)
		};

		return (
			<React.Fragment>

				<Row className="step step1 mt-2 justify-content-md-center ">
					<Col lg="8">
						<DoctorForm
							defaultValues={defaultValues}
							submitCbk={submitCbk}
							validate={this.props.validate}
							submitButtoni18nId={"Validate"}
							onBackCbk={(data)=> this.back(data)}
							ref={this.form} />
					</Col>
				</Row>

			</React.Fragment>
		)
	}
}