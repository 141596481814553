import React from "react";
import ReplacementEnt from "../entities/ReplacementEnt";
import RatingEnt from '../entities/RatingEnt';
import Stars from '../components/misc/Stars';
import UserEnt from "../entities/UserEnt";

import { FormattedMessage } from 'react-intl';
import ProfileImages from "../enum/ProfileImages";


export default class DReplCardBuilder {

    static applicants(replacement, openSubProfileModal) {
        // Boucle sur applicants_user au lieu de applicants
        const applicantsNode = replacement.applicants_users && replacement.applicants_users.map(a => {
            const char = replacement.applicantsCharacteristics.find(c => c.substitute_id === a._id);
            if (!char) return null;

            const hasConcludedAgreement = ReplacementEnt.concludedAgreement(replacement, a);
            const hasOngoingAgreement = ReplacementEnt.ongoingAgreement(replacement, a);
            const hasCancelledAgreement = ReplacementEnt.subCancelled(replacement, a);
            const docCancelledAgreement = ReplacementEnt.docCancelled(replacement, a);
            const hasAccordAgreement = ReplacementEnt.hasAccordAgreement(replacement, a);
            const refuseByDoc = ReplacementEnt.refuseByDoc(replacement, a);
            const refuseBySub = ReplacementEnt.isDeclinedBySubstitute(replacement, a._id);
            const isNegociate = ReplacementEnt.isNegociate(replacement, a._id);
            const isThinking = ReplacementEnt.isThinking(replacement, a._id);
            const waitForResponseNegociation = ReplacementEnt.waitForResponseNegociation(replacement, a._id);

            let status = (
                <>
                    <p className="text-success"><b><FormattedMessage id="Applies.To.Your.Repl" /></b></p>
                    <p><u><FormattedMessage id="Answer" /></u></p>
                </>
            );

            if (hasConcludedAgreement) {
                status = <p className="text-success"><b><FormattedMessage id="Concluded.Agreement" /></b></p>;
            } else if (docCancelledAgreement) {
                status = <p className="text-danger"><b><FormattedMessage id="Cancelled.By.You" /></b></p>;
            } else if (hasCancelledAgreement || refuseBySub) {
                status = <p className="text-danger"><b><FormattedMessage id="Cancelled.By.Substitute" /></b></p>;
            } else if (hasOngoingAgreement) {
                status = <p className="text-success"><b><FormattedMessage id="Waiting.For.My.Signature" /></b></p>;
            } else if (hasAccordAgreement) {
                status = <p className="text-success"><b><FormattedMessage id="Waiting.For.My.Signature" /></b></p>;
            } else if (refuseByDoc) {
                status = <p className="text-danger"><b><FormattedMessage id="Declined" /></b></p>;
            } else if (isNegociate) {
                status = <p className="text-danger"><b><FormattedMessage id="Propose.Negociation" /></b></p>;
            } else if (isThinking !== false && isThinking.doctor_status === 4) {
                status = <p className="text-danger"><b><FormattedMessage id="Thinking.Time" values={{ days: isThinking.diff }}/></b></p>;
            } else if (waitForResponseNegociation === true) {
                status = <p className="text-danger"><b><FormattedMessage id="Waiting.For.Response" /></b></p>;
            }

            return (
                <div key={Math.random()} className="small-profile d-inline-block align-top clickable shadow-hover" onClick={(!docCancelledAgreement && !refuseBySub) ? (e) => openSubProfileModal(e, replacement, a, char) : ''}>
                    <div className="picture">
                        <img src={ProfileImages.getSrc(a.image)} className="d-block mx-auto mb-3" width="100" alt="" style={{ borderRadius: "100px" }} />
                    </div>
                    <div className="info">
                        <p><b>{(hasConcludedAgreement) ? UserEnt.fullName(a) : UserEnt.pseudo(a)}</b></p>
                        <p><Stars nbr={RatingEnt.averageAllStars(a.rating)} /></p>
                        {status}
                    </div>
                </div>
            );
        });

        return applicantsNode;
    }

    static watchers(replacement, openSubProfileModal) {
        const watchersNode = replacement.watchers.map(w => {
            const char = replacement.watchersCharacteristics.find(c => c.substitute_id === w._id);
            if (!char) return null;

            return (
                <div className="small-profile d-inline-block align-top clickable" onClick={(e) => openSubProfileModal(e, replacement, w, char)}>
                    <div className="picture">
                        <img src={ProfileImages.getSrc(w.image)} className="d-block mx-auto mb-3" width="100" alt="" style={{ borderRadius: "100px" }} />
                    </div>
                    <div className="info">
                        <p><b>{UserEnt.pseudo(w)}</b></p>
                        <p><Stars nbr={RatingEnt.averageAllStars(w.rating)} /></p>
                        <p className="text-primary"><b><FormattedMessage id="Watches.Your.Repl" /></b></p>
                        <p><FormattedMessage id="Usual.Retrocession" /> : <b>{char.retrocession_wished_confort}%</b></p>
                    </div>
                </div>
            );
        });

        return watchersNode;
    }

    static rightSide(replacement, onDeleteRepl, onOpenRetroModal) {
        let isPrivate = ReplacementEnt.isPrivate(replacement);
        const isConcluded = ReplacementEnt.isConcluded(replacement);
        const hasAgreements = ReplacementEnt.hasAgreements(replacement);
        if (replacement.invitation_by_doctor === true) isPrivate = true;

        var isThinking = false
        if (replacement.availabilityOwner) {
            isThinking = ReplacementEnt.isThinking(replacement, replacement.availabilityOwner._id);
        }
        
        // =======================================================================================
        // ================================= PRIVATE REPL ========================================
        // =======================================================================================
        if (isPrivate) {
            const subName = replacement.availabilityOwner.first_name;
            const isNegociate = ReplacementEnt.isNegociate(replacement, replacement.availabilityOwner._id);
            let txt = (
                <div className="text-muted">
                    <b><FormattedMessage id="Has.Not.Answered.yet" values={{ name: subName }} /></b>
                </div>
            );

             if (isNegociate === true) {
                txt = (
                    <div className="text-danger clickable">
                        <b><FormattedMessage id="Propose.Negociation"/></b>
                    </div>
                );
            } else if (ReplacementEnt.privateWatches(replacement)) {
                txt = (
                    <div className="text-primary">
                        <b><FormattedMessage id="Watches.Your.Invitation" values={{ name: subName }} /></b>
                    </div>
                );
            } else if (ReplacementEnt.privateApplied(replacement)) {
                txt = (
                    <div className="text-success clickable">
                        <b><FormattedMessage id="Accepted.Your.Invitation" values={{ name: subName }} /></b>
                    </div>
                );
            } else if (ReplacementEnt.isDeclinedBySubstitute(replacement, replacement.availabilityOwner._id)) {
                txt = (
                    <div className="text-danger clickable">
                        <b><FormattedMessage id="Declined" values={{ name: subName }} /></b>
                    </div>
                );
            } else if (isThinking !== false) {
                txt = (
                    <div className="text-danger clickable">
                        <b><FormattedMessage id="Thinking.Sub" values={{ sub: subName }} /></b>
                    </div>
                );
            }

            if (ReplacementEnt.ongoingAgreement(replacement, replacement.availabilityOwner)) {
                txt = (
                    <div className="text-muted clickable">
                        <b><FormattedMessage id="Ongoing.Agreement" values={{ name: subName }} /></b>
                    </div>
                );
            }

            return (
                <>
                <div className="d-inline-block align-top float-right text-center mr-3" style={{ width: "30px", height: "70px", fontSize: "12px" }}>
                    <i className={"fa icon-ICON__CROIX_GRIS clickable " + ((isConcluded || hasAgreements) ? "disabled" : "")}
                        onClick={onDeleteRepl}></i>
                </div>

                <div className="d-inline-block align-top float-right text-center mr-3"
                    style={{ height: "70px", fontSize: "12px", paddingTop: "7px", width: "50px" }}>
                    <img src={ProfileImages.getSrc(replacement.availabilityOwner.image)}
                        className="d-block mx-auto mb-3" width="50" alt="" style={{ borderRadius: "100px" }} />
                </div>

                <div className="d-inline-block align-top float-right text-center mr-3" style={{ height: "70px" }}>
                    {txt}
                </div>
                </>
            );
        }

        // =======================================================================================
        // ================================== PUBLIC REPL ========================================
        // =======================================================================================
        else {
            return (
                <>
                <div className="d-inline-block align-top float-right text-center mr-3" style={{ width: "30px", height: "70px", fontSize: "12px" }}>
                    <i className={"fa icon-ICON__CROIX_GRIS clickable " + ((isConcluded || hasAgreements) ? "disabled" : "")}
                        onClick={onDeleteRepl}></i>
                </div>

                <div className={"d-inline-block align-top float-right text-center clickable-block"}
                    style={{ width: "70px", height: "70px", fontSize: "16px" }}
                    onClick={onOpenRetroModal}>

                    <i className={"d-inline-block fa icon-ICON__CRAYON_GRIS"}></i>

                    <div className={"d-inline-block"}
                        style={{ fontSize: 12, marginLeft: "6px" }}> {replacement.retrocession || replacement.guard_retrocession}%</div>
                </div>
                </>
            );
        }

    }
}