import React from 'react';
import { connect } from 'react-redux';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import DateUtil from '../../../util/DateUtil';
import { Row, Col } from "reactstrap";
import { downloadContract } from "../../../actions/agreement/agreement";
import AgreementEnt from '../../../entities/AgreementEnt';
import { upds, getBy, clear } from "../../../actions/global/global";
import ConfModal from "../../../components/modal/ConfModal";
import SReplacementContractModal from '../replacements/SReplacementContractModal';
import RetrocessionCertificateHome from '../../retrocessionCertificate/RetrocessionCertificateHome';
import moment from 'moment';

class AgreementTitle extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            waitForSign: false
        };

        this.openRetrocessionCertificate = this.openRetrocessionCertificate.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        if (this.props.replacement) {
            this.props.onGetBy("retrocessionCertificate", ["replacement_id"], [this.props.replacement._id])
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.retrocessionCertificate !== this.props.retrocessionCertificate) {
            if (this.props.retrocessionCertificate && this.props.retrocessionCertificate.length > 0) {
                let lastRetrocessionCertificate = this.props.retrocessionCertificate[this.props.retrocessionCertificate.length - 1];
                if (lastRetrocessionCertificate.doctor_initials && lastRetrocessionCertificate.doctor_signature && !lastRetrocessionCertificate.cancelled_by_doctor && !lastRetrocessionCertificate.substitute_initials && !lastRetrocessionCertificate.substitute_signature && lastRetrocessionCertificate.replacement_id === this.props.replacement._id) {
                    this.setState({
                        waitForSign: true
                    })
                }
            }
        }
    }

    downloadContract(e, agreementId) {
        e.preventDefault();
        e.stopPropagation();

        if (!this.state.disabled) {
            this.setState({ disabled: true });

            this.props.downloadContract(agreementId, () => this.setState({ disabled: false }));
        }
    }

    sendToCouncil(e, agreementId, agreement) {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.disabled) return;

        this.setState({ disabled: true });

        const onConfirm = () => {
            this.props.sendToCouncil(agreementId, () => this.setState({ disabled: false, modal: null }));
        };

        const onCancel = () => {
            this.setState({ disabled: false });
            this.closeModal();
        }
        const content = <FormattedHTMLMessage id="Confirm.Send.To.Council" values={{ name: agreement.doctor.first_name }} />

        this.setState({
            modal: <ConfModal
                content={content}
                onConfirm={onConfirm}
                onCancel={() => onCancel()}
                toggle={() => onCancel()} />
        });
    }

    cancelAgreement(e, agreementId) {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.disabled) return;

        this.setState({ disabled: true });

        const onConfirm = () => {
            this.props.cancelAgreement(agreementId, () => {
                this.setState({ disabled: false });
                if (this.props.onCancelCbk) this.props.onCancelCbk();
            });
        };

        const onCancel = () => {
            this.setState({ disabled: false });
            this.closeModal();
        };

        const content = <FormattedHTMLMessage id="Confirm.Cancel.Agreement" />

        this.setState({
            modal: <ConfModal
                content={content}
                onConfirm={onConfirm}
                onCancel={() => onCancel()}
                toggle={() => onCancel()} />
        });
    }

    closeModal() {
        this.setState({ modal: null });
    };

    openContractModal = (e, agreement) => {
        e.stopPropagation();

        var modal = <SReplacementContractModal
            applicant={agreement.substitute}
            agreement={agreement}
            doctor={agreement.doctor}
            doctorCharacteristic={agreement.doctorCharacteristic}
            replacement={agreement.replacement}
            officeTimeTable={agreement.officeTimeTable}
            onSignatureCbk={this.props.onSignatureCbk}
            toggle={() => this.closeModal()} />

        if (this.state.modal) {
            this.setState({
                modal: null
            }, () => this.setState({
                modal: modal
            }));
        } else {
            this.setState({
                modal: modal
            });
        }
    }

    openRetrocessionCertificate(event) {
        event.preventDefault();
        event.stopPropagation();

        let doctor = Object.assign({}, this.props.agreement.doctor, this.props.agreement.doctorCharacteristic);
        let replacement = this.props.agreement.replacement;

        this.setState({
            modal: <RetrocessionCertificateHome
                    toggle={this.closeModal}
                    doctor={doctor}
                    agreement={this.props.agreement}
                    replacement={replacement}
                    fromDoctor={false}
                />
        })
    }

    render() {
        const { replacement, agreement } = this.props;

        if (!replacement) return null;

        const isCancelled = (agreement.cancelled_by_doctor || agreement.cancelled_by_substitute);
        const alreadySent = AgreementEnt.alreadySent(agreement);
        const hasStarted = new Date().getTime() > new Date(replacement.start_date).getTime();
        const isSigned = AgreementEnt.isSigned(agreement);
        const isStep3Complete = AgreementEnt.isConcluded(agreement) && isSigned;

        let startDate = replacement.start_date;
        let endDate = replacement.end_date;
        let retrocession = agreement.replacement.retrocession;

        if(replacement.type === "guard") {
            endDate = DateUtil.toDateWithHour(replacement.end_date)
            retrocession = agreement.replacement.guard_retrocession;
        }
        var hasConcludedAgreement, hasSignedAgreement;
        if (agreement) {
            hasConcludedAgreement = AgreementEnt.isConcluded(agreement);
            hasSignedAgreement = AgreementEnt.isSigned(agreement);
        }
        let viewPhone = false; 
        if (replacement && replacement.availability_id) {
            viewPhone = true;
        } 
    
        if (agreement.replacement.applicants && agreement.replacement.applicants.length > 0) {
            replacement.applicants.forEach((applicant) => {
                if (applicant.substitute_id === agreement.substitute_id) {
                    if (applicant.substitute_status === 1 && applicant.doctor_status === 1) {
                        viewPhone = true;
                    }
                    if (applicant.negociation && applicant.negociation.length > 0) {
                        let lastElement = applicant.negociation[applicant.negociation.length - 1];
                        retrocession = lastElement.retrocession;
                        startDate = lastElement.start_date;
                        endDate = lastElement.end_date;
                    }
                }
            })
        }

        let viewRetrocessionCertificate = false;

        if (moment(startDate).isSameOrBefore(moment(), 'day') && hasConcludedAgreement && hasSignedAgreement) {
            viewRetrocessionCertificate = true;
        }
        return (
            <React.Fragment>

                <Row className="min-height-70"                    
                    onMouseUp={e => { if (this.state.modal) { e.preventDefault(); e.stopPropagation() } }}
                    onClick={e => { if (this.state.modal) { e.preventDefault(); e.stopPropagation() } }}>

                    <Col sm="6">
                        {(replacement.guards.length > 0) && <div className="red-light-addon ml-0 pl-0"></div>}
                        {(replacement.type === "occasional") && <div className="green-light ml-0 pl-0"></div>}
                        {(replacement.type === "regular") && <div className="blue-light ml-0 pl-0"></div>}
                        {(replacement.type === "guard") && <div className="red-light ml-0 pl-0"></div>}

                        {(replacement.type === "guard") ?
                            <>
                                <FormattedMessage id="From" />{' '}
                                <b>{DateUtil.toDate(startDate)}</b>
                                {' '}<FormattedMessage id="To" />{' '}
                                <b>{endDate}</b>
                                <br></br>
                                <FormattedMessage id="Retrocession" /> : &nbsp;
                                <b>{retrocession} % </b>
                            </>
                            :
                            <>
                                <FormattedMessage id="From" />{' '}
                                <b>{DateUtil.toDate(startDate)}</b>
                                {' '}<FormattedMessage id="To" />{' '}
                                <b>{DateUtil.toDate(endDate)}</b>
                                <br></br>
                                <FormattedMessage id="Retrocession" /> : &nbsp;
                                <b>{retrocession} % </b>
                            </>
                        }

                    </Col>

                    {viewPhone &&
                        <Col sm="1">
                            <div className="w-100 text-center mt-5">
                                <div className="phone-icon text-blue phone-icon-listing">
                                    <i className="fa fa-phone"></i>
                                </div>
                            </div>
                        </Col>
                    }

                    <Col sm={(viewPhone) ? 5 : 6} className="pr-3 text-right pr-4">

                        <div>  
                            {(!isStep3Complete) && 
                                <span>
                                    {isSigned ? 
                                        <span className="text-muted mr-3">
                                            <b><FormattedMessage id="Waiting.For.Doctor.Signature" /></b>
                                        </span>
                                        :
                                        <span  onClick={(e) => { this.openContractModal(e, agreement) }} className="text-danger hover-red p-1 mr-1">
                                            <b><FormattedMessage id="Waiting.For.My.Signature" /></b>
                                        </span>
                                    }
  
                                </span>
                            }

                            {(isStep3Complete && !isCancelled && alreadySent) &&
                                <span className="agreement-blue mr-3"><b><FormattedMessage id="Validated" /></b></span>
                            }

                            {//Doctor and Substitute should have signed agreement
                                hasConcludedAgreement && hasSignedAgreement && !alreadySent && 
                                <div className={"btn bg-green srounded lowercase pl-3 pr-3 text-white lp-2 d-inline-block m-2 " + ((alreadySent || this.state.disabled) ? "disabled" : "")}
                                    onClick={(e) => {
                                        if (alreadySent || this.state.disabled) return;
                                        this.sendToCouncil(e, agreement._id, agreement)
                                    }}>
                                    <FormattedHTMLMessage id="Transmit.To.Council" />
                                </div>
                            }

                            {viewRetrocessionCertificate === true &&
                                <div className={"btn srounded lowercase pl-3 pr-3 text-white lp-2 d-inline-block m-2 " + (this.state.waitForSign === false ? 'bg-green' : 'bg-blue') }
                                    onClick={this.openRetrocessionCertificate} >
                                    {this.state.waitForSign === true ?
                                        <FormattedHTMLMessage id="Wait.Sign.Retrocession.Certificate" />
                                        :
                                        <FormattedHTMLMessage id="Create.Retrocession.Certificate" />
                                    }
                                </div>
                            }

                            {(!isStep3Complete && !isCancelled && !alreadySent && !hasStarted) &&
                                <div className={"d-inline-block agreement-blue clickable agreement-btn-danger"
                                    + ((this.state.disabled) ? "-disabled" : "")}
                                    style={{ fontSize: "12px" }}
                                    onClick={(e) => {
                                        if (!this.state.disabled) {
                                            this.cancelAgreement(e, agreement._id);
                                        }
                                    }}>
                                    <i className={"fa fa icon-ICON__CROIX_GRIS"}></i>
                                </div>
                            }

                        </div>

                    </Col>
                    {this.state.modal}

                </Row>
            </React.Fragment>
        );
    };
}

const mapStateToProps = state => {
    return {
        retrocessionCertificate: state.global.retrocessionCertificate
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpds: (objName, obj, cbk) => dispatch(upds(objName, obj, cbk)),
        onDownloadContract: (agreementId, cbk) => downloadContract(agreementId, cbk),
        onGetBy: (objName, params, values) => dispatch(clear(objName, getBy(objName, params, values)))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgreementTitle);