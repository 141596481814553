export default function replacements(state = {}, action) {
	switch(action.type) {
		case "FIND_REPLACEMENTS_REQUEST":
			return Object.assign({}, state, {
				isGettingReplacements: true,
				isGetReplacements: false
			});
		case "FIND_REPLACEMENTS_SUCCESS":
			return Object.assign({}, state, {
				replacements: action.replacements,
				isGettingReplacements: false,
				isGetReplacements: true
			});
		case "FIND_REPLACEMENTS_FAILURE":
			return Object.assign({}, state, {
				isGettingReplacementsWtihDate: false,
				isGetReplacementsWithDate: false
			});
		case "DELETE_REPLACEMENTS_REQUEST":
			return Object.assign({}, state, {
				isDeleteReplacement: false
			});
		case "DELETE_REPLACEMENTS_SUCCESS":
			return Object.assign({}, state, {
				isDeleteReplacement: true,
			});
		case "DELETE_REPLACEMENTS_FAILURE":
			return Object.assign({}, state, {
				isDeleteReplacement: false,
			});
		case "SEARCH_REPLACEMENT_BY_SECTOR_REQUEST":
			return Object.assign({}, state, {
				isGettingReplacement: true,
			});
		case "SEARCH_REPLACEMENT_BY_SECTOR_SUCCESS":
			return Object.assign({}, state, {
				replacementBySector: action.replacementBySector,
				isGettingReplacement: false,
			});
		case "SEARCH_REPLACEMENT_BY_SECTOR_FAILURE":
			return Object.assign({}, state, {
				isGettingReplacement: false,
			});
		default:
			return state;
	}
}