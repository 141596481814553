import React from 'react';
import { FormattedMessage } from 'react-intl';
import blueBG from '../../assets/images/login/RIDEAU_BLEU.svg';
import yellowBG from '../../assets/images/login/RIDEAU_JAUNE.svg';
import GDPRModal from "../../components/gdpr/GDPRModal";


export default class Start extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show_register_doctor: false
        }

        this.showRegisterDoctor = this.showRegisterDoctor.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    showRegisterDoctor() {

        this.setState({
            show_register_doctor: true
        })
    }

    goBack() {
        this.setState({
            show_register_doctor: false
        })
    }

    render() {
        return (
            <>

                <div className="container-fluid bg-blue m-0 p-0 min-h-100 w-100 b-fix">
                    <div className="w-100 h-100 position-absolute m-0 p-0 d-none d-xl-block" style={{ top: -2 }}>
                        <img src={blueBG} alt="bg-img" className="h-100" style={{ marginLeft: -220 }} />
                        <img src={yellowBG} alt="bg-img" className="h-100 position-absolute" style={{ right: -220 }} />
                    </div>

                    <div className="row">
                        <div className="col-md-2">
                            <a href="#/" className="btn border xrounded text-white p-3 w-100 mb-2"><span className="arrowLeft">&#8592;</span><FormattedMessage id="Back" /></a>
                        </div>
                    </div>
                    <div className={"position-relative mx-auto text-center " + (this.state.show_register_doctor === false ? 'max-w-600px' : 'max-w-350px')} style={{ marginTop: "calc(35vh - 118px)" }}>

                        {this.state.show_register_doctor === false &&
                            <div className='border xrounded bg-white text-center h-100 custom-home-choice'>
                                <div className='mb-5'>
                                    <h3><FormattedMessage id="Practicians" /></h3>
                                </div>
                                <div className='row justify-content-center'>
                                    <div className='col-md-4 offset-md-1 col-sm-12 clickable btn border xrounded bg-blue text-white p-3 w-100 mb-3' onClick={this.showRegisterDoctor}>
                                        Médecin généraliste
                                    </div>
                                    <a href="#/registration/piSteps" className='col-md-4 offset-md-1 col-sm-12 clickable custom-color-a custom-color-button-psychologist btn border xrounded text-white p-3 w-100 mb-3'>
                                        <FormattedMessage id="Psychologist" />
                                    </a>
                                    <a href="#/registration/rSteps" className='col-md-4 offset-md-1 col-sm-12 clickable custom-color-a custom-color-button-radiologist btn border xrounded text-white p-3 w-100 mb-3'>
                                        <FormattedMessage id="Radiologist" />
                                    </a>
                                    {/* <a href="#/registration/peSteps" className='col-md-4 offset-md-1 col-sm-12 clickable custom-color-a custom-color-button-pediatrician btn border xrounded text-white p-3 w-100 mb-3'>
                                    <FormattedMessage id="Pediatrician" />
                                </a> */}
                                    <a href="#/registration/psSteps" className='col-md-4 offset-md-1 col-sm-12 clickable custom-color-a custom-color-button-psychiatrist btn border xrounded text-white p-3 w-100 mb-3'>
                                        <FormattedMessage id="Psychiatrist" />
                                    </a>
                                </div>
                                {/* <div className='mt-3 mb-5'>
                                    <h3><FormattedMessage id="Secretarys.And.Assistants" /></h3>
                                </div>
                                <div className='row justify-content-center'>
                                    <a href="#/registration/secSteps" className='col-md-4  col-sm-12 clickable custom-color-a custom-color-button-secretary btn border xrounded text-white p-3 w-100 mb-3'>
                                        <FormattedMessage id="Secretary" />
                                    </a>
                                </div> */}
                            </div>
                        }

                        {this.state.show_register_doctor === true &&
                            <div className="border xrounded bg-white text-center p-4 mx-auto mx-md-0">

                                <div className="row">
                                    <button className="btn bg-grey xrounded" onClick={this.goBack}>
                                        <span className="arrowLeft">&#8592;</span>
                                        <FormattedMessage id="Back" />
                                    </button>
                                </div>

                                <div className="mt-2 mb-2">
                                    <h5 className="bold mb-4"><FormattedMessage id="I.Am" /></h5>
                                    <a href="#/registration/steps" className="btn border xrounded bg-blue text-white p-3 w-100 mb-3"><FormattedMessage id="Installed.Doctor" /></a>
                                    <a href="#/registration/subSteps" className="btn border xrounded bg-yellow text-white p-3 w-100"><FormattedMessage id="Substitute" /></a>
                                </div>

                                <div className="w-100 mt-3">
                                    <a href="#/aboutus" target="_blank" className="underline lowercase text-secondary"><FormattedMessage id="Who.Are.We" /></a>
                                </div>

                            </div>
                        }
                    </div>
                </div>

                <GDPRModal />
            </>
        );
    }
}
