import React, { Component } from 'react';
import Image from './Image';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';


class ImagePicker extends Component {

    constructor(props) {
        super(props)

        this.state = {
            picked: this.props.defaultValue || null
        }
    }

    handleImageClick(image) {
        this.setState({ picked: image.value });

        this.props.onPick(image.value);
    }

    renderImage(image, i) {
        return (
            <div className="d-inline-block" style={{ width: 160, height: 150, marginBottom: "4px" }}>
                <Image
                    src={image.src}
                    isSelected={this.state.picked && this.state.picked === image.value}
                    onImageClick={() => this.handleImageClick(image)}
                    key={i}
                />
            </div>
        )
    }

    render() {
        const { images } = this.props;

        return (
            <Modal isOpen={true} toggle={this.props.onClose} size="lg" className="simple-modal simple-modal-white srounded">

                <ModalHeader toggle={this.props.onClose}>
                    <button type="button" className="close d-inline-block" aria-label="Close" onClick={this.props.onClose} ><span aria-hidden="true">×</span></button>
                </ModalHeader>

                <ModalBody className="pl-2 pr-2 pl-md-5 pr-md-5 pt-0">
                    <h4 className="w-100 uppercase text-center pb-4"><FormattedMessage id="Choose.Your.Avatar" /></h4>

                    <div className="image_picker text-center">
                        {images.map((img, i) => this.renderImage(img, i))}
                        <div className="clear" />
                    </div>

                    {this.state.modal}
                </ModalBody>

                <ModalFooter toggle={this.props.onClose}>
                    <Button className="srounded px-4 py-2 text-white bg-green" onClick={this.props.onClose} >
                        <FormattedMessage id="Confirm"/>
                    </Button>
                </ModalFooter>

            </Modal>
        );
    }
}


export default ImagePicker;