import React from 'react';
import Cleave from 'cleave.js/react';
import { injectIntl } from 'react-intl';
import { InputComposer, InputComponent } from '@gferrand/react-forms';

import IsDate from './IsDate';
import DateUtil from '../../util/DateUtil';

export default class TextDateInput extends InputComposer {

    constructor(name, label, validators = [], options = {}) {
        validators.push(IsDate);

        super(name, label, validators, options);
    }

    mongoType() {
        return "String";
    }

    toJoi() {
        return "string()";
    }

    getComponent() {
        return <TextDateInputComponent />;
    }

    get value() { 
        if (typeof this._state.value === "string" && this._state.value.includes("/") && this._state.value.length === 10) {
            let dateParse = this._state.value.split('/');
            let day = dateParse[0];
            let month = dateParse[1] - 1;
            let year = dateParse[2];
            return new Date(year, month, day);
        } else {
            return this._state.value;
        }
    }

    castValue(value) {
        if (typeof value === "string" && value.includes("-")) {
            return new Date(value);
        }

        return value;
    }

}

var TextDateInputComponent = injectIntl(class TextDateInputComponent extends InputComponent {

    constructor(props) {
        super(props);

        this.state = {
            rawValue: "",
        }

        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        if(this.state.value) {
            this.setState({
                value: DateUtil.toDate(this.state.value)
            })
        }
    }

    onChange(event) {

        this.setState({
            rawValue: event.target.rawValue
        })

        return super.onChange(event.target.value);
    }

    render() {
        return super.wrap(
            <Cleave 
                placeholder={this.props.options.placeholder}
                value={this.state.value}
                options={{date: true, datePattern: ['d', 'm', 'Y'], delimiter: '/'}}
                onChange={this.onChange}
                className={"form-control " + this.props.options.className} />
        );
    }

});