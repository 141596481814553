var values = [
    {label: "1 minutes", value: "1"},
    {label: "2 minutes", value: "2"},
    {label: "3 minutes", value: "3"},
    {label: "4 minutes", value: "4"},
    {label: "5 minutes", value: "5"},
    {label: "6 minutes", value: "6"},
    {label: "7 minutes", value: "7"},
    {label: "8 minutes", value: "8"},
    {label: "9 minutes", value: "9"},
    {label: "10 minutes", value: "10"},
    {label: "11 minutes", value: "11"},
    {label: "12 minutes", value: "12"},
    {label: "13 minutes", value: "13"},
    {label: "14 minutes", value: "14"},
    {label: "15 minutes", value: "15"},
    {label: "16 minutes", value: "16"},
    {label: "17 minutes", value: "17"},
    {label: "18 minutes", value: "18"},
    {label: "19 minutes", value: "19"},
    {label: "20 minutes", value: "20"},
    {label: "21 minutes", value: "21"},
    {label: "22 minutes", value: "22"},
    {label: "23 minutes", value: "23"},
    {label: "24 minutes", value: "24"},
    {label: "25 minutes", value: "25"},
    {label: "26 minutes", value: "26"},
    {label: "27 minutes", value: "27"},
    {label: "28 minutes", value: "28"},
    {label: "29 minutes", value: "29"},
    {label: "30 minutes", value: "30"},
    {label: "31 minutes", value: "31"},
    {label: "32 minutes", value: "32"},
    {label: "33 minutes", value: "33"},
    {label: "34 minutes", value: "34"},
    {label: "35 minutes", value: "35"},
    {label: "36 minutes", value: "36"},
    {label: "37 minutes", value: "37"},
    {label: "38 minutes", value: "38"},
    {label: "39 minutes", value: "39"},
    {label: "40 minutes", value: "40"},
    {label: "41 minutes", value: "41"},
    {label: "42 minutes", value: "42"},
    {label: "43 minutes", value: "43"},
    {label: "44 minutes", value: "44"},
    {label: "45 minutes", value: "45"},
    {label: "46 minutes", value: "46"},
    {label: "47 minutes", value: "47"},
    {label: "48 minutes", value: "48"},
    {label: "49 minutes", value: "49"},
    {label: "50 minutes", value: "50"},
    {label: "51 minutes", value: "51"},
    {label: "52 minutes", value: "52"},
    {label: "53 minutes", value: "53"},
    {label: "54 minutes", value: "54"},
    {label: "55 minutes", value: "55"},
    {label: "56 minutes", value: "56"},
    {label: "57 minutes", value: "57"},
    {label: "58 minutes", value: "58"},
    {label: "59 minutes", value: "59"},
    {label: "60 minutes", value: "60"},
  ];
  
    export default class Duration {
    
      static values() {
        return values;
      }
    
      static getLabel(nbr) {
        for (let value of values) {
          if (value.value === nbr) return value.label;
        }
      }
    
    }