import React from 'react';
import { withGoogleMap, GoogleMap, Marker, Circle } from "react-google-maps";

export class GMapClinicalStudy extends React.Component {
	// constructor(props) {
	// 	super(props);
	// }

	shouldComponentUpdate(nextProps) {
		if(nextProps.lat === this.props.lat && nextProps.lng === this.props.lng && nextProps.radius === this.props.radius ) return false
		if(nextProps.radius !== this.props.radius && nextProps.radius) return true
		return true
	}

	render() {
		const { lat, lng, radius } = this.props;
		const optionsRadius = {
            fillColor: "#0357ff",
            strokeColor: "#0357ff",
            strokeOpacity: 1,
            fillOpacity: 0,
        }
		return(
			<GoogleMap
				defaultZoom={8}
				center={{ lat, lng }}
				defaultOptions={{
					fullscreenControl: false,
					gestureHandling: 'none',
					mapTypeControl: false,
					zoomControl: true,
					draggable: true,
					streetViewControl: false
				}}
			>
				<Marker position={{ lat, lng }} />

				{radius > 0 &&
					<Circle
						options={optionsRadius} 
						center={{
							lat: parseFloat(lat),
							lng: parseFloat(lng)
						}}
						radius={(parseInt(radius) - 1) * 1000}
					/>
				}

			</GoogleMap>
		)
	}
}

export default withGoogleMap(GMapClinicalStudy);