import React from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { Form, NumericInput, RadioInput, TextInput, Addable, NotEmpty } from '@gferrand/react-forms';
import Util from '../../../util/Util';
import MedicalSoftwares from '../../../enum/MedicalSoftwares';


class DTransmissionsForm extends React.Component {

    constructor(props){
        super(props);

        if(this.props.getDataCbk){
            this.props.getDataCbk(() => this.getData());
        }

        const { defaultValues, doctorCharacteristic } = this.props;

        const submitCbk = (formData, endCbk) => {
            formData = this.getData(formData);

            this.props.submitCbk(formData, endCbk)
        };

        const changeCbk = (input) => {
            if(input.name === "has_entry_code"){
                if(input.value) this.form.show("entry_code");
                else this.form.hide("entry_code");
            }

            if(input.name === "has_computer_code"){
                if(input.value) this.form.show("computer_code");
                else this.form.hide("computer_code");
            }

            if(input.name === "has_software_code"){
                if(input.value) this.form.show("software_code");
                else this.form.hide("software_code");
            }

            if(input.name === "has_bio_checkup_inbox"){
                if(input.value) {
                    this.form.show("bio_checkup_inbox_name");
                    this.form.show("bio_checkup_inbox_code");
                } else {
                    this.form.hide("bio_checkup_inbox_name");
                    this.form.hide("bio_checkup_inbox_code");
                }
            }

            if(input.name === "has_mail_inbox"){
                if(input.value) {
                    this.form.show("mail_inbox_name");
                    this.form.show("mail_inbox_code");
                } else {
                    this.form.hide("mail_inbox_name");
                    this.form.hide("mail_inbox_code");
                }
            }
        }

        let data = {
            has_entry_code: false,
            has_computer_code: false,
            has_software_code: false,
            has_bio_checkup_inbox: false,
            has_mail_inbox: false,
        };

        if(defaultValues) data = { 
            has_entry_code: !Util.emptyString(defaultValues.entry_code),
            has_computer_code: !Util.emptyString(defaultValues.computer_code),
            has_software_code: !Util.emptyString(defaultValues.software_code),
            has_bio_checkup_inbox: !Util.emptyString(defaultValues.bio_checkup_inbox_name) || !Util.emptyString(defaultValues.bio_checkup_inbox_code),
            has_mail_inbox: !Util.emptyString(defaultValues.mail_inbox_name) || !Util.emptyString(defaultValues.mail_inbox_code),
            ...defaultValues
        };

        let softwareName;
        if (doctorCharacteristic.medical_software === "0") softwareName = doctorCharacteristic.other_software;
        else softwareName = MedicalSoftwares.getLabel(doctorCharacteristic.medical_software);

        this.form = new Form({
            name: "doctorTransmission",
            inputs: [
                new NumericInput("code_cps", "CPS.Code", 4, []),

                new RadioInput("has_entry_code", "Has.Entry.Code", [{ label: "Yes", value: true }, { label: "No", value: false }], [], 
                    { gClassName: "text-left", className: "srounded pl-4 pr-4 lowercase bg-blue text-white mb-1 mb-sm-0"}),
                new TextInput("entry_code", "Entry.Code", 30, [NotEmpty]),

                new RadioInput("has_computer_code", "Has.Computer.Code", [{ label: "Yes", value: true }, { label: "No", value: false }], [], 
                    { gClassName: "text-left", className: "srounded pl-4 pr-4 lowercase bg-blue text-white mb-1 mb-sm-0"}),
                new TextInput("computer_code", "Computer.Code", 30, [NotEmpty]),

                new RadioInput("has_software_code", this.props.intl.formatMessage({ id:"Has.Software.Code"}, { soft: softwareName }), 
                    [{ label: "Yes", value: true }, { label: "No", value: false }], [],
                    { gClassName: "text-left", className: "srounded pl-4 pr-4 lowercase bg-blue text-white mb-1 mb-sm-0"}),
                new TextInput("software_code", "Software.Code", 30, [NotEmpty]),

                new Addable("other_softwares", "Other.Softwares", "fa fa-plus-square", () => [
                    new TextInput("software_name", "Other.Software.Name", 100, [NotEmpty]), 
                    new TextInput("software_password", "Other.Software.Password", 30, [NotEmpty])
                ]),

                new RadioInput("has_bio_checkup_inbox", "Has.Bio.Checkup.Inbox", [{ label: "Yes", value: true }, { label: "No", value: false }], [], 
                    { gClassName: "text-left", className: "srounded pl-4 pr-4 lowercase bg-blue text-white mb-1 mb-sm-0"}),
                new TextInput("bio_checkup_inbox_name", "Bio.Checkup.Inbox.Name", 80, [NotEmpty]),
                new TextInput("bio_checkup_inbox_code", "Bio.Checkup.Inbox.Code", 30),

                new RadioInput("has_mail_inbox", "Has.Mail.Inbox", [{ label: "Yes", value: true }, { label: "No", value: false }], [],
                    { gClassName: "text-left", className: "srounded pl-4 pr-4 lowercase bg-blue text-white mb-1 mb-sm-0"}),
                new TextInput("mail_inbox_name", "Mail.Inbox.Name", 80),
                new TextInput("mail_inbox_code", "Mail.Inbox.Code", 30),

                new Addable("retirement_homes", "Retirement.Homes", "fa fa-plus-square", () => [
                    new TextInput("retirement_home_name", "Retirement.Home.Name", 100, [NotEmpty]), 
                    new TextInput("retirement_home_entry_code", "Retirement.Home.Entry.Code", 30),
                    new TextInput("retirement_home_computer_code", "Retirement.Home.Computer.Code", 30, [])
                ]),
            ],
            submitCbk,
            changeCbk,
            data
        });

        // Hide defaults
        if(!data.has_entry_code) this.form.hide("entry_code");
        if(!data.has_computer_code) this.form.hide("computer_code");
        if(!data.has_software_code) this.form.hide("software_code");
        if(!data.has_bio_checkup_inbox) this.form.hide("bio_checkup_inbox_name");
        if(!data.has_bio_checkup_inbox) this.form.hide("bio_checkup_inbox_code");
        if(!data.has_mail_inbox) this.form.hide("mail_inbox_name");
        if(!data.has_mail_inbox) this.form.hide("mail_inbox_code");
    }

    getData(formData){
        if(!formData) formData = this.form.getRawData();

        // Addables
        if(!formData.other_softwares) formData.other_softwares = [];
        if(!formData.retirement_homes) formData.retirement_homes = [];

        if(!formData.has_entry_code) formData.entry_code = null;
        if(!formData.has_computer_code) formData.computer_code = null;
        if(!formData.has_software_code) formData.software_code = null;
        if(!formData.has_bio_checkup_inbox){
            formData.bio_checkup_inbox_name = null;
            formData.bio_checkup_inbox_code = null;
        }
        if(!formData.has_mail_inbox){
            formData.mail_inbox_name = null;
            formData.mail_inbox_code = null;
        }

        delete formData.has_entry_code;
        delete formData.has_computer_code;
        delete formData.has_software_code;
        delete formData.has_bio_checkup_inbox;
        delete formData.has_mail_inbox;

        return formData;
    }

	render() {

		return (
			<>

				<div className="text-center">

					{this.form.body()}

                    <Row className="mt-5">

                        <div className="mx-auto form-buttons">
                            {this.form.submitButton(this.props.submitButtoni18nId, { className: "srounded pl-5 pr-5 pt-2 pb-2 lowercase text-white bg-green" })}
                        </div>

                    </Row>

				</div>

			</>
		);
	}
}


const mapStateToProps = state => {
	return {
		//
	}
}

const mapDispatchToProps = dispatch => {
	return {
		//
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DTransmissionsForm));
