import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getsBy, clears } from "../../../actions/global/global";
import DReplacementCard from '../replacements/DReplacementCard';

import Pagination from "react-js-pagination";

class DReplacementProposals extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            // Pagination
            skip: 0,
            limit: 10
        };
    };

    componentWillMount() {
        this.fetchData();
    }

    fetchData() {
        this.setState({ loading: true });

        this.props.onGetsBy("replacement/private", ["skip", "limit"], [this.state.skip, this.state.limit],
            () => this.setState({ loading: false }));
    }

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    render() {
        const { replacements, replacementsCount } = this.props;
        return (
            <React.Fragment>
                {(this.state.loading) && this.loading()}

                {(!this.state.loading && (!replacements || !replacements.length)) &&
                    <div className="w-100 text-center text-white"><FormattedMessage id="No.Element" /></div>
                }

                {(!this.state.loading && replacements && replacements.length > 0) &&
                    <>
                        {
                            replacements
                                .sort((r0, r1) => new Date(r1.createdAt) - new Date(r0.createdAt))
                                .map(r => <DReplacementCard key={r._id} replacement={r} />)
                        }

                        <Pagination
                            innerClass="w-100 pagination justify-content-center"
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={Math.round(this.state.skip / this.state.limit) + 1}
                            itemsCountPerPage={this.state.limit}
                            totalItemsCount={replacementsCount}
                            pageRangeDisplayed={5}
                            onChange={(page) => this.setState({ skip: (page - 1) * this.state.limit }, this.fetchData)}
                            hideDisabled={false}
                        />

                    </>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        replacements: state.global.replacements,
        replacementsCount: state.global.replacementsCount
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback))),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DReplacementProposals);


