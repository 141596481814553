import React from 'react';
import { FormattedMessage } from 'react-intl';


export default class IsDate {

    static name() {
        return "IsDate";
    }

    static valid(value) {
        if(typeof value === "string" && value.includes("/") && value.length !== 10) return false;

        return (!isNaN(new Date(value).getTime()));
    }

    static error() {
        return <FormattedMessage id="Incorrect.Date" />;
    }

    static debug() {
        return `IsDate`;
    }

    static toJoi() {
        return `.date()`;
    }

};