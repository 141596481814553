import React, { Component } from 'react';
import { connect } from 'react-redux';
import { adds, upds } from '../../../../actions/global/global'
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { Form, TextInput, DecimalInput, NotEmpty, TimeInput } from '@gferrand/react-forms';
import DateUtil from '../../../../util/DateUtil';
import AccountingEnt from '../../../../entities/AccountingEnt';

class AccountingDailyModalConsultation extends Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.isUpdate = (!!this.props.accounting);

        var submitCbk = (data, endCbk) => {
            data.categorie = 'income';
            data.date = DateUtil.stringToDate(data.date, this.props.startDate)

            const successCbk = () => {
                endCbk();
                if (this.props.onSuccessCbk) this.props.onSuccessCbk();
                this.props.closeconsultationsModal();
            };

            if (this.isUpdate) {
                data._id = this.props.accounting._id;
                this.props.onUpds("accounting/annex", data, () => successCbk());

            } else {
                if (this.props.user.role === "doctor") return this.props.onAdds("accounting/annex/doctor", data, () => successCbk());
                else return this.props.onAdds("accounting/annex/substitute", data, () => successCbk());
            }
        };

        const defaultValues = {
            date: `${DateUtil.parseHour(new Date().getHours())}:${DateUtil.parseMinutes(new Date().getMinutes())}`,
        }

        this.form = new Form({
            name: "user",
            inputs: [
                new TextInput("reason", "Reason", undefined, [NotEmpty], { placeholder: "Motive" }),
                new TimeInput("date", "Start.DateTime", [NotEmpty]),
                new DecimalInput("total", "Total.Amount", [NotEmpty])
            ],
            submitCbk,
            options: { validateAtStart: false },
            data: this.props.accounting ? AccountingEnt.parsePatientDate(this.props.accounting) : defaultValues
        });
    }

    render() {
        return (
            <Modal isOpen={true} size="md" toggle={() => this.props.closeconsultationsModal()} className="simple-modal simple-modal-white srounded">

                <ModalHeader toggle={() => this.props.closeconsultationsModal()}>
                </ModalHeader>

                <ModalBody className="pl-2 pr-2 pl-md-5 pr-md-5 pt-0">
                    <h4 className="w-100 uppercase text-center pb-4"><FormattedMessage id="New.Revenue" /></h4>

                    <div className="mx-auto" style={{ maxWidth: "450px" }}>

                        <div className="mb-3">
                            {this.form.getInput("reason")}
                        </div>

                        <div className="mb-3">
                            {this.form.getInput("date")}
                        </div>

                        <div className="mb-3">
                            <Row className="form-group text-left forceLeft">
                                <Col md="4">{this.form.getLabel("total")}</Col>
                                <Col md="8">{this.form.getInput("total")}</Col>
                            </Row>
                        </div>


                        <div className="text-center form-buttons w-100 mt-5 mb-4">
                            {this.form.submitButton("Add.2", { className: "srounded pl-5 pr-5 pt-2 pb-2 text-white bg-blue min-w-200px min-h-60px bold lp-2" })}
                        </div>

                    </div>

                </ModalBody>

            </Modal>
        );
    }
}
const mapStateToProps = state => {
    return {
        //
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAdds: (objName, obj, cbk) => dispatch(adds(objName, obj, cbk)),
        onUpds: (objName, obj, cbk) => dispatch(upds(objName, obj, cbk))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountingDailyModalConsultation);