import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import StepZilla from 'react-stepzilla';
import GDPRModal from "../../../components/gdpr/GDPRModal";
import Step1 from '../form/Step1';
import SStep2 from './SStep2';
import SStep3 from './SStep3';
import Finish from '../Finish';
import { getBy, add } from "../../../actions/global/global";
import { connect } from "react-redux";
import UserUtil from '../../../util/UserUtil';


class SSteps extends Component {

	constructor(props) {
		super(props);

		this.state = {};
		this.store = {};

		//if (process.env.REACT_APP_IS_PRODUCTION === "false") this.init();
	}

	componentDidMount() {
		if (this.props.match.params.id) {
			this.props.onGetBy('user/admin', "id", this.props.match.params.id, (res) => this.updateStoreFromUser(res));
		}
	}
	
	updateStoreFromUser = (res) => {
		if (res) {
			var user = Object.assign({}, res);
			var substituteCharacteristics = {};

			if(user.characteristics) {
				substituteCharacteristics = user.characteristics.validated_substituteCharacteristics.reduce((previousValue, item) => ({
					...previousValue,
					[item]: user.characteristics[item]
				}), {});

				UserUtil.setCharacs(substituteCharacteristics, user.characteristics, true);

				delete user.characteristics;
			}

			this.updateStore({
				user: user,
				substituteCharacteristic: substituteCharacteristics,
				_id: res._id
			});
			this.forceUpdate();
		}
	}

	init = () => {
		this.store = {
			user: {
				birth_date: "01/01/2000",
				role: "substitute",
				email: "top@gmail.com",
				first_name: "Issa",
				gender: "man",
				name: "Sani",
				password: "password",
				confirmation_mot_de_passe: "password",
				phone_mobile: "0745987509",
			},
			substituteCharacteristic: {
				// address_city: "REIMS",
				// address_postcode: "51100",
				// address_street: "72 rue du Barbâtre",
				studies_level: "1",
				settlement_preference: "2",
				medical_software: ["12", "13", "14"],
				license: "946514",
				siret: "01234567891023",
				accept_home_visits: "0",
				year_end_internship: "",
				consult_capacity_per_week: 130,
				teleconsultation: ["1", "2", "14"],
				RPPS: "65431465465",
				radius_preference: 10,
				retrocession_wished: 70,
				radius_effort: 20,
				retrocession_wished_effort: 80,
				guard_retrocession_wished: 0,
				sports_medicine: false,
				ecg: false,
				homeopathy: false,
				emergency_medicine: false,
				gynecology: false,
				allergology: false,
				osteopathy: false,
				du_dius: [
					{ topic: "Cancérologie" },
					{ topic: "Médecine de la douleur et médecine palliative" },
					{ topic: "Médecine d’urgence" }
				],
				descs: [
					{ topic: "Cancérologie" },
					{ topic: "Médecine de la douleur et médecine palliative" },
					{ topic: "Médecine d’urgence" },
				]
			}
		};
	}

	getStore() {
		return this.store;
	}

	updateStore(update) {
		this.store = {
			...this.store,
			...update,
		};
	}

	render() {
		var nameSStep1 = <FormattedMessage id="My.Info" />;
		var nameSStep2 = <FormattedMessage id="My.Profile" />;
		var nameSStep3 = <FormattedMessage id="Conditions" />;
		var nameSStep4 = <FormattedMessage id="Confirmation.2" />;

		var nextButton = this.props.intl.formatMessage({ id: "Next" });

		var validate = true;

		const steps =
			[
				{ name: nameSStep1, component: <Step1 userRole={"substitute"} validate={validate} getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
				{ name: nameSStep2, component: <SStep2 validate={validate} getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
				{ name: nameSStep3, component: <SStep3 onAdd={this.props.onAdd} validate={validate} getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
				{ name: nameSStep4, component: <Finish userRole={"substitute"} getStore={() => (this.getStore())} updateStore={(u) => { this.updateStore(u) }} /> },
			];

		return (
			<div id="registration" className='example blue-bg'>

				<div className='step-progress'>

					<div className="topbar navbarbg text-center b-fix">
						<a href="/#" className="a-unstyled">
							<div className="hydrogen-logo-sm d-inline-block align-middle"></div>
							<div className="ml-3 bold d-inline-block align-middle fs-18 lp-3">HYDROGEN</div>
						</a>
					</div>

					<h4 className="text-white uppercase w-100 text-center lp-3 mt-4 mb-3"><FormattedMessage id="I.Register" /></h4>

					<StepZilla
						steps={steps}
						stepsNavigation={true}
						preventEnterSubmission={true}
						showNavigation={false}
						nextButtonText={nextButton}
						nextButtonCls={"btn btn-prev btn-primary btn-lg pull-right"}
					/>

					<GDPRModal />

				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.global.user
	}
};

function mapDispatchToProps(dispatch) {
	return {
		onGetBy: (objName, fields, values, callback) => dispatch(getBy(objName, fields, values, callback)),
        onAdd: (objName, obj, cbk) => dispatch(add(objName, obj, cbk))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SSteps));