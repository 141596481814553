import React, { Component } from 'react';
import PEInfoForm from "./forms/PEInfoForm";
import { Row, Col } from 'reactstrap';
import Util from '../../../util/Util';


export default class PEStep1 extends Component {

	constructor(props) {
		super(props);

		this.form = React.createRef();
	}

	isValidated() {
		let form = Object.assign({}, this.form.current.form.getRawData(), { role: 'pediatrician_install', image: this.form.current.state.image });
		this.props.updateStore({ user: form });
		return this.form.current.validate(); 
	}

	render() {
		const submitCbk = (data) => {
			this.props.updateStore({ user: data });
			this.props.jumpToStep(1);

			if (window.scrollTo) window.scrollTo(0, 0);
		};

		var defaultValues = Object.assign({}, this.props.getStore().user);
		if(Util.emptyObject(defaultValues)) defaultValues = undefined;

		return (

			<React.Fragment>

				<Row className="step step1 mt-2 justify-content-md-center ">
					<Col lg="8">

						<PEInfoForm
							defaultValues={defaultValues}
							validate={this.props.validate}
							submitCbk={submitCbk}
							submitButtoni18nId={"Next.Step"}
							ref={this.form} />
					</Col>
				</Row>

			</React.Fragment>
		);
	};
};