import React from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import ConfModal from "../../../components/modal/ConfModal";
import SAgreementTitle from './SAgreementTitle';
import ProfileCard from '../../boths/ProfileCard';
import ProfileRateCard from '../../boths/ProfileRateCard';
import DoctorProfileModal from '../../doctors/profile/DoctorProfileModal';
import ReplacementEnt from '../../../entities/ReplacementEnt';
import AgreementEnt from '../../../entities/AgreementEnt';
import { downloadContract } from "../../../actions/agreement/agreement";
import { upds } from "../../../actions/global/global";
import SReplacementContractModal from '../replacements/SReplacementContractModal';

class SAgreementCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null
        };
    }

    // ================================================================================
    //          Potential actions regarding concluded agreements
    // ================================================================================

    downloadContract(agreementId, successCbk) {
        this.props.onDownloadContract(agreementId, successCbk);
    }

    sendToCouncil(agreementId, successCbk) {
        this.props.onUpds("agreement/send/" + agreementId, {}, () => {
            successCbk();
            this.closeModal();
        });
    }

    cancelAgreement(agreementId, successCbk) {
        this.props.onUpds("agreement/cancel/" + agreementId, {}, () => {
            successCbk();
            this.closeModal();
        });
    }

    signatureModal(agreement, doctor, doctorCharacteristic, officeTimeTable) {
        let replacement = {
            _id: this.props.agreement.replacement_id
        }
        this.setState({
            modal: <SReplacementContractModal
                doctor={doctor}
                doctorCharacteristic={doctorCharacteristic}
                agreement={agreement}
                replacement={replacement}
                officeTimeTable={officeTimeTable}
                toggle={() => this.closeModal()} />
        });
    }

    // ================================================================================
    // ================================================================================
    // ================================================================================

    openConfModal(content, onConfirm, onCancel) {
        this.setState({
            modal: <ConfModal
                content={content}
                onConfirm={onConfirm}
                onCancel={onCancel}
                toggle={() => onCancel()} />
        });
    }

    profileModal(e, user, characteristic, timeTable = {}, agreement, replacementId, agreementReplacement) {
        
        this.setState({
            modal: <DoctorProfileModal
                replacementId={replacementId}
                agreement={agreement}
                agreementReplacement={agreementReplacement}
                doctor={user}
                doctorCharacteristic={characteristic}
                officeTimeTable={timeTable}
                close={() => this.closeModal()}
                downloadContract={(agreementId, successCbk) => this.downloadContract(agreementId, successCbk)}
                sendToCouncil={(agreementId, successCbk) => this.sendToCouncil(agreementId, successCbk)}
                cancelAgreement={(agreementId, successCbk) => this.cancelAgreement(agreementId, successCbk)}
                onApply={() => this.signatureModal(agreement, user, characteristic, timeTable)}
            />
        });
    };

    closeModal() {
        this.setState({ modal: null });
    };

    render() {
        const { agreement, user } = this.props;

        if (!agreement || !user) return null;
        const { replacement, doctor, doctorCharacteristic, officeTimeTable } = this.props.agreement;

        return (
            <React.Fragment key={Math.random()}>

                <div className={"d-replacement-card-dim pb-0 mb-0 mb-4 pt-0 mt-0 "} style={{ position: "relative" }}>

                    <Card className={"border " + ((
                        agreement.cancelled_by_doctor ||
                        agreement.cancelled_by_substitute ||
                        agreement.doctor_filled ||
                        agreement.substitute_overlap || replacement.deleted) ? " opacity-low " : " clickable ")}>

                        <Row className="ml-mr-0"
                            onClick={(e) => {
                                if (!agreement.cancelled_by_doctor && !agreement.cancelled_by_substitute) {
                                    this.profileModal(e, doctor, doctorCharacteristic, officeTimeTable, agreement, undefined, agreement.replacement)
                                }
                            }}>

                            <Col lg="3">
                                {(AgreementEnt.isConcluded(agreement) && ReplacementEnt.isRatable(agreement.replacement) && !AgreementEnt.alreadyRated(user, agreement)) ?
                                    <ProfileRateCard user={doctor} agreement={agreement} onRate={() => { if (this.props.onRate) this.props.onRate() }} />
                                    :
                                    <ProfileCard user={doctor} agreement={agreement} />
                                }

                            </Col>

                            <Col>
                                <SAgreementTitle
                                    replacement={replacement}
                                    agreement={agreement}
                                    onCancelCbk={this.props.onCancelCbk}
                                    downloadContract={(agreementId, successCbk) => this.downloadContract(agreementId, successCbk)}
                                    sendToCouncil={(agreementId, successCbk) => this.sendToCouncil(agreementId, successCbk)}
                                    cancelAgreement={(agreementId, successCbk) => this.cancelAgreement(agreementId, successCbk)} />
                            </Col>

                        </Row>

                    </Card>

                    {(agreement.cancelled_by_doctor || replacement.deleted) &&
                        <div className="card-overflow">
                            <FormattedMessage id="Cancelled.By.Doctor" />
                        </div>
                    }

                    {(agreement.cancelled_by_substitute && !replacement.deleted) &&
                        <div className="card-overflow">
                            <FormattedMessage id="Cancelled.By.You" />
                        </div>
                    }

                    {(!agreement.cancelled_by_substitute && !agreement.cancelled_by_doctor && agreement.doctor_filled && !replacement.deleted) &&
                        <div className="card-overflow">
                            <FormattedMessage id="Filled" />
                        </div>
                    }

                    {(!agreement.cancelled_by_doctor && !agreement.cancelled_by_substitute && agreement.substitute_overlap && !replacement.deleted) &&
                        <div className="card-overflow">
                            <FormattedMessage id="Overlapping.Replacement" />
                        </div>
                    }

                </div>


                {this.state.modal}

            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDownloadContract: (agreementId, cbk) => downloadContract(agreementId, cbk),
        onUpds: (objName, obj, cbk) => dispatch(upds(objName, obj, cbk))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SAgreementCard);

