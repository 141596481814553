import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import DoctorProfile from './DoctorProfile';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import DateUtil from "../../../util/DateUtil";
import { watchReplacement, } from "../../../actions/replacements/replacements";
import ReplacementEnt from '../../../entities/ReplacementEnt';
import Noty from 'noty';
import "../../../../node_modules/noty/lib/noty.css";
import "../../../../node_modules/noty/lib/themes/bootstrap-v4.css";

class DoctorProfileModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            disabled: false,
        };
    }

    onWatch = (e, replacement) => {
        e.stopPropagation();

        this.setState({ disabled: true });

        const { user } = this.props;

        this.props.onWatchReplacement({ replacementId: replacement._id }, (replacement) => {
            this.setState({ disabled: false });

            if (user && replacement.watchers) {
                if (replacement.watchers.find(id => id === user._id)) {
                    new Noty({
                        type: "info",
                        layout: 'topRight',
                        theme: 'bootstrap-v4',
                        text: this.props.intl.formatMessage({ id: "Replacement.Watch.Add.Success" }),
                        timeout: 6000,
                    }).show();
                } else {
                    new Noty({
                        type: "info",
                        layout: 'topRight',
                        theme: 'bootstrap-v4',
                        text: this.props.intl.formatMessage({ id: "Replacement.Watch.Remove.Success" }),
                        timeout: 6000,
                    }).show();
                }
            }
        });
    };

    render() {
        const { doctor, user, doctorCharacteristic, officeTimeTable, replacementId, agreement, agreementReplacement, replacements } = this.props;

        if (!doctor || !doctorCharacteristic || !officeTimeTable) return null;

        // Do we have a replacement to add to the card?
        var replacement = agreementReplacement;
        if (!replacement && replacementId) replacement = replacements.find(r => r._id === replacementId);
        return (
            <React.Fragment>

                <Modal size="md" isOpen={true} toggle={() => this.props.close()} className="simple-modal simple-modal-white srounded">

                    <ModalHeader className="border-0 m-0">
                        <div className="text-left repl-views">

                            {(replacement) &&
                                <div className="">
                                    <div className="d-inline-block lowercase">
                                        {(!replacement.availability_id) &&
                                            <div>
                                                <i className="fa fa-eye"></i>&nbsp;&nbsp;{replacement.views}&nbsp;
                                                <FormattedMessage id="Views" />
                                            </div>
                                        }

                                        <div>
                                            <i className="fa fa-pencil-square"></i>&nbsp;&nbsp;
                                            <FormattedMessage id="Posted.On" />&nbsp;{DateUtil.toDate(replacement.createdAt)}
                                        </div>

                                        <div>
                                            {(replacement.guards.length > 0) && <div className="red-light-addon ml-0 pl-0"></div>}
                                            {(replacement.type === "occasional") && <div className="green-light ml-0 pl-0"></div>}
                                            {(replacement.type === "regular") && <div className="blue-light ml-0 pl-0"></div>}
                                            {(replacement.type === "guard") && <div className="red-light ml-0 pl-0"></div>}
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>

                        <div className="close-container">
                            {(!agreementReplacement && replacement.watchers) &&
                                <div className="repl-heart">
                                    <i
                                        className={"fa icon-ICON__COEUR_ROSE clickable " +
                                            ((ReplacementEnt.hasLiked(replacement, user)) ? " pink " : " ") +
                                            ((ReplacementEnt.isConcluded(replacement) ||
                                                ReplacementEnt.ongoingAgreement(replacement, user) ||
                                                this.state.disabled) ? " disabled " : "")}
                                        onClick={(e) => {
                                            if (!ReplacementEnt.isConcluded(replacement) &&
                                                !ReplacementEnt.ongoingAgreement(replacement, user) &&
                                                !this.state.disabled) this.onWatch(e, replacement)
                                        }}>
                                    </i>
                                </div>
                            }

                            <button type="button" className="close d-inline-block" aria-label="Close"
                                onClick={() => this.props.close()} ><span aria-hidden="true">×</span></button>
                        </div>

                    </ModalHeader>

                    <ModalBody className="p-0">

                        <div className="mt-sm--50">
                            <DoctorProfile
                                agreement={agreement}
                                agreementReplacement={agreementReplacement}
                                replacementId={(this.props.agreement) ? this.props.agreement.replacement_id : agreementReplacement._id}
                                doctor={doctor}
                                doctorCharacteristic={doctorCharacteristic}
                                officeTimeTable={officeTimeTable}
                                onApply={this.props.onApply}
                                downloadContract={(agreementId, successCbk) => this.props.downloadContract(agreementId, successCbk)}
                                sendToCouncil={(agreementId, successCbk) => this.props.sendToCouncil(agreementId, successCbk)}
                                cancelAgreement={(agreementId, successCbk) => this.props.cancelAgreement(agreementId, successCbk)}
                                viewByCalendar={this.props.viewByCalendar}
                            />
                        </div>

                    </ModalBody>

                </Modal>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
        replacements: state.global.replacements
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onWatchReplacement: (replacementId, callback) => dispatch(watchReplacement(replacementId, callback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DoctorProfileModal));