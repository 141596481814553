export default function clinicalStudy(state = {}, action) {
	switch(action.type) {
		case "FIND_CLINICAL_STUDY_REQUEST":
			return Object.assign({}, state, {
				isGettingClinicalStudy: true
			});
		case "FIND_CLINICAL_STUDY_SUCCESS":
			return Object.assign({}, state, {
				listOfClinicalStudy: action.listOfClinicalStudy,
				isGettingClinicalStudy: false
			});
		case "FIND_CLINICAL_STUDY_FAILURE":
			return Object.assign({}, state, {
				isGettingClinicalStudy: false
			});
		case "FIND_CLINICAL_STUDY_CABINET_REQUEST":
			return Object.assign({}, state, {
				isGettingClinicalStudyCabinet: true
			});
		case "FIND_CLINICAL_STUDY_CABINET_SUCCESS":
			return Object.assign({}, state, {
				listOfClinicalStudyCabinet: action.listOfClinicalStudyCabinet,
				isGettingClinicalStudyCabinet: false
			});
		case "FIND_CLINICAL_STUDY_CABINET_FAILURE":
			return Object.assign({}, state, {
				isGettingClinicalStudyCabinet: false
			});
		case "ADD_PATIENT_CLINICAL_STUDY_REQUEST":
			return Object.assign({}, state, {
				isPatientAdded: false
			});
		case "ADD_PATIENT_CLINICAL_STUDY_SUCCESS":
			let listOfClinicalStudyFilter = Array.from(state.listOfClinicalStudy, (elem) => {
				if (elem._id === action.clinicalStudy._id) {
					return Object.assign({}, elem, { patient: action.clinicalStudy.patient })
				}
				return elem;
			})

			return Object.assign({}, state, {
				isPatientAdded: true,
				listOfClinicalStudy: listOfClinicalStudyFilter
			});
		case "ADD_PATIENT_CLINICAL_STUDY_FAILURE":
			return Object.assign({}, state, {
				isPatientAdded: false,
				error: action.error
			});

		case "ACCEPT_PATIENT_CGU_REQUEST":
			return Object.assign({}, state, {
				acceptedPatientCGU: false
			});
		case "ACCEPT_PATIENT_CGU_SUCCESS":
			return Object.assign({}, state, {
				acceptedPatientCGU: true
			});
		case "ACCEPT_PATIENT_CGU_FAILURE":
			return Object.assign({}, state, {
				acceptedPatientCGU: false
			});
		case "FIND_CLINICAL_STUDY_BY_PATIENT_ID_REQUEST":
		return Object.assign({}, state, {
			isGettingClinicalStudy: true
		});
		case "FIND_CLINICAL_STUDY_BY_PATIENT_ID_SUCCESS":
		return Object.assign({}, state, {
			clinicalStudy: action.clinicalStudy,
			isGettingClinicalStudy: false
		});
		case "FIND_CLINICAL_STUDY_BY_PATIENT_ID_FAILURE":
		return Object.assign({}, state, {
			isGettingClinicalStudy: false
		});

		default:
			return state;
	}
}