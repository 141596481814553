import React from 'react';
import { Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { InputComposer, InputComponent } from '@gferrand/react-forms';
import Util from '../../util/Util';


export default class TimeSelInput extends InputComposer {

    constructor(name, label, startHour, endHour, minutesSteps, validators=[], options={}){
        // Add custom validator
        validators.push(TimeSelValidator);
        
        super(name, label, validators, options);

        this.startHour = startHour;
        this.endHour = endHour;
        this.minutesSteps = minutesSteps;
    }

    mongoType() {
        return "String";
    }

    toJoi() {
        return "string().regex(/^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/)";
    }

    getComponent() {
        return <TimeSelInputComponent startHour={this.startHour} endHour={this.endHour} minutesSteps={this.minutesSteps} />;
    }

}

class TimeSelInputComponent extends InputComponent {

    onChange(newValue, time) {
        var currentTime = this.state.value;
        var currentHours = this.getHours(currentTime) || this.format(this.props.startHour);
        var currentMinutes = this.getMinutes(currentTime) || "00";
        var newTime = this.state.value;

        if(time === "hours") newTime = newValue + ":" + currentMinutes;
        else if (time === "minutes") newTime = currentHours + ":" + newValue;

        super.onChange(newTime, () => this.onBlur());
    }

    format(time){
        if(time < 10) return "0" + time;
        return time;
    }

    getHours(time){
        return time.split(":")[0];
    }

    getMinutes(time){
        return time.split(":")[1];
    }

    render() {
        const { startHour, endHour, minutesSteps } = this.props;

        const hours = [];
        for (let i = startHour; i <= endHour; i += 1) hours.push(this.format(i));

        const minutes = [];
        for (let i = 0; i < 60; i += minutesSteps) minutes.push(this.format(i));

        const hoursChoices = hours.map(hour => <option key={hour} value={hour}>{hour}</option>);
        const minutesChoices = minutes.map(minute => <option key={minute} value={minute}>{minute}</option>);

        return super.wrap(
            <>

                <div className="form-inline">
                    <Input
                        id={this.props.name}
                        type="select"
                        onChange={(e) => { this.onChange(e.target.value, "hours"); }}
                        value={this.getHours(this.state.value)}
                        invalid={!!this.state.error}
                        className={this.props.options.className}
                        disabled={this.state.disabled || this.props.options.disabled}>
                        <option key="" value=""></option>
                        {hoursChoices}
                    </Input>
                    H
                    &nbsp;:&nbsp;
                    <Input
                        id={this.props.name + "-minutes"}
                        type="select"
                        onChange={(e) => { this.onChange(e.target.value, "minutes"); }}
                        value={this.getMinutes(this.state.value)}
                        invalid={!!this.state.error}
                        className={this.props.options.className}
                        disabled={this.state.disabled || this.props.options.disabled}>
                        <option key="" value=""></option>
                        {minutesChoices}
                    </Input>
                    Min

                </div>

            </>
        );
    }

}

class TimeSelValidator{

    static name(){
        return "TimeSelValidator";
    }

    static valid(value){
        if(!value || Util.emptyString(value)) return true;

        var hours = value.split(":")[0];
        var minutes = value.split(":")[1];

        // If user specified the hours but not the minutes (or vice-versa), display an error
        if((!Util.emptyString(hours) && Util.emptyString(minutes)) || (!Util.emptyString(minutes) && Util.emptyString(hours))){
            return false;
        } 

        return true;
    }

    static error(){
        return <FormattedMessage id="Form.Validator.TimeSelValidator" />;
    }

    static toJoi(){
        return "";
    }
}