export default function agreements(state = {}, action) {
	switch(action.type) {
		case "GETS_AGREEMENTS_SUB_REQUEST":
			return Object.assign({}, state, {
				getsAgreements: false,
				isGetReplacementsWithDate: false
			});
		case "GETS_AGREEMENTS_SUB_SUCCESS":
			return Object.assign({}, state, {
				getsAgreements: true,
				agreements: action.agreements,
				isGetReplacementsWithDate: true
			});
		case "GETS_AGREEMENTS_SUB_FAILURE":
			return Object.assign({}, state, {
				getsAgreements: false,
				isGetReplacementsWithDate: false
			});
		case "GETS_AGREEMENTS_DOC_REQUEST":
			return Object.assign({}, state, {
				isGettingReplacementsWtihDate: true,
				isGetReplacementsWithDate: false
			});
		case "GETS_AGREEMENTS_DOC_SUCCESS":
			return Object.assign({}, state, {
				agreements: action.agreements,
				isGettingReplacementsWtihDate: false,
				isGetReplacementsWithDate: true
			});
		case "GETS_AGREEMENTS_DOC_FAILURE":
			return Object.assign({}, state, {
				isGettingReplacements: false,
				isGetReplacements: false
			});
		default:
			return state;
	}
}