import Maths from '../util/Maths';
import DateUtil from '../util/DateUtil';

export default class AccountingEnt {

    static calculateCorrectPrices = (accounting) => {

        if (accounting.third_party_payer === true) {
            let third_party_amount = (70 / 100) * accounting.total;
            let payedByThirdParty = Maths.round(third_party_amount);

            accounting.payed_by_third_party = payedByThirdParty;
            accounting[accounting.payment_methods] = Maths.round(accounting.total - payedByThirdParty)

        } else {
            accounting[accounting.payment_methods] = accounting.total
        }

        return accounting;
    }

    static calculateTotals = (accountings) => {

        const total = {
            credit_card_total: 0,
            cash_total: 0,
            cheque_total: 0,
            secu_total: 0,
            third_party_payer: 0,
            totalAmount: 0,
            retrocession_total: 0
        };

        accountings.forEach((accounting) => {
            total.credit_card_total += accounting.credit_card_total_consultation
            total.cash_total += accounting.cash_total_consultation
            total.cheque_total += accounting.cheque_total_consultation
            total.secu_total += accounting.secu_total_consultation
            total.third_party_payer += accounting.third_party_payer_consultation
            total.totalAmount += accounting.totalAmountconsultation
            total.retrocession_total += accounting.retrocession_total_consultation
        })

        return total
    }

    static orderByDate(array) {
        return array.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
        });
    }

    static parsePatientDate = (patient) => {
        // eslint-disable-next-line
        const regex = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/
        if (regex.test(patient.date)) patient.date = DateUtil.dateToString(patient.date);
        return patient
    }

}