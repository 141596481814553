
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import SAvailabilityCard from './SAvailabilityCard';
import AddAvailabilityModal from './AddAvailabilityModal';
import { getsBy, clears } from '../../../actions/global/global';
import Pagination from "react-js-pagination";

import Util from '../../../util/Util';

class Availabilities extends Component {

    constructor(props) {
        super(props);

        this.state = {
            disabled: false,
            modal: null,
            loading: false,

            // Pagination
            skip: 0,
            limit: 10
        };

        this.displayDispoCbk = null;
    }

    toggleModal = () => {
        if (this.state.modal) {
            this.setState({
                modal: null
            });
        } else {
            this.setState({
                modal: <AddAvailabilityModal
                    key={this.props.substituteCharacteristic._id}
                    toggle={() => this.toggleModal()}
                    substituteCharacteristic={this.props.substituteCharacteristic}
                />
            });
        }
    };

    componentWillMount() {
        this.fetchData();
    }

    fetchData(cbk) {
        this.setState({ loading: true });

        this.props.onGetsBy("availability",
            ["skip", "limit"], [this.state.skip, this.state.limit],
            () => {
                this.setState({ loading: false });
                if (cbk) cbk();
            });
    }

    loading = () => <div className="w-100 text-center mt-2 mb-2"><div className="spinner-border text-white mx-auto" role="status"></div></div>;

    render() {
        const { availabilitys, availabilitysCount } = this.props;

        if (!availabilitys || Util.null(availabilitysCount)) return this.loading();

        return (
            <React.Fragment>

                <div className="text-center form-buttons w-100">
                    <Button className="btn mb-4 text-center" size="lg" color="primary" onClick={() => this.toggleModal()}>
                        +&nbsp;<FormattedMessage id="Add.Availability" />
                    </Button>
                </div>

                {(this.state.loading) && this.loading()}

                {(!this.state.loading && availabilitys && availabilitys.length > 0) &&
                    <>
                        {availabilitys
                            .sort((r0, r1) => new Date(r1.createdAt) - new Date(r0.createdAt))
                            .map(a => <SAvailabilityCard key={a._id} availability={a} onApply={(cbk) => this.fetchData(cbk)} />)}

                        <Pagination
                            innerClass="w-100 pagination justify-content-center"
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={Math.round(this.state.skip / this.state.limit) + 1}
                            itemsCountPerPage={this.state.limit}
                            totalItemsCount={Math.max(availabilitysCount, availabilitys.length)}
                            pageRangeDisplayed={5}
                            onChange={(page) => this.setState({ skip: (page - 1) * this.state.limit }, this.fetchData)}
                            hideDisabled={false}
                        />

                    </>
                }

                {this.state.modal}

            </React.Fragment >
        )
    }
}

const mapStateToProps = state => {
    return {
        availabilitys: state.global.availabilitys,
        substituteCharacteristic: state.global.substituteCharacteristic,
        availabilitysCount: state.global.availabilitysCount
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetsBy: (objName, fields, values, callback) => dispatch(clears(objName, getsBy(objName, fields, values, callback)))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Availabilities);