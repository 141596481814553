import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { get, upd, getBy } from "../../../actions/global/global";
import ProfileImages from "../../../enum/ProfileImages";
import StringUtil from '../../../util/StringUtil';
import UserEnt from '../../../entities/UserEnt';
import ConfModal from "../../../components/modal/ConfModal";
import DateUtil from '../../../util/DateUtil';
import GoogleMapUtil from "../../../util/GoogleMapUtil"
import 'ladda/dist/ladda-themeless.min.css';
import PsyICharacteristicsProfile from "./PsyICharacteristicsProfile";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { downloadCertificate } from "../../../actions/retrocessionCertificate/retrocessionCertificate";

export class PsyIProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            disabled: false,
            driving: null,
            transit: null,
            cycling: null,
            showMap: false,
            mapshown: false,
            modal: null
        };

        const { doctor, psychologistICharacteristic } = this.props;

        this.GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
        this.sendGMAPSRequests = this.sendGMAPSRequests.bind(this);

        GoogleMapUtil.loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${this.GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
            () => {
                this.buildMap(doctor, psychologistICharacteristic)
                // this.forceUpdate()
            }
        );

        this.sendToCouncil = this.sendToCouncil.bind(this);
        this.downloadContract = this.downloadContract.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.downloadCertificate = this.downloadCertificate.bind(this);
    }

    componentDidMount() {
        if (this.props.user.role === "substitute") this.props.onGet("substituteCharacteristic");
        if(this.props.replacementId){
            this.props.onGetBy("retrocessionCertificate", ["replacement_id"], [this.props.replacementId])
        }
    }

    buildMap(doctor, psychologistICharacteristic) {
        this.mapComponent = withGoogleMap((props) =>
            <GoogleMap
                defaultZoom={11}
                defaultCenter={{ lat: psychologistICharacteristic.location.coordinates[1], lng: psychologistICharacteristic.location.coordinates[0] }}
                defaultOptions={{
                    mapTypeControl: false,
                    fullscreenControl: false,
                    gestureHandling: 'none',
                    zoomControl: false,
                    draggable: false,
                    streetViewControl: false
                }}
            >
                <Marker
                    key={Math.random()}
                    icon={{
                        url: ProfileImages.getSrc(doctor.image), // url
                        scaledSize: new window.google.maps.Size(50, 50), // scaled size
                        origin: new window.google.maps.Point(0, 0), // origin
                        anchor: new window.google.maps.Point(0.5, 0.5) // anchor
                    }}
                    position={{
                        lat: parseFloat(psychologistICharacteristic.location.coordinates[1]),
                        lng: parseFloat(psychologistICharacteristic.location.coordinates[0])
                    }} />
            </GoogleMap>
        );
    }

    pointingArrow = () => {
        if (this.state.collapsed === "details") return "-";
        return "+";
    }

    buildRoundElem = (characteristics, field, i18n) => {
        if (!i18n) i18n = StringUtil.capitalize(field);

        return (
            <div className="round-elem">
                <div className={"round" + ((characteristics[field]) ? "-success" : "-failure")}>
                    {(characteristics[field]) ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}
                </div>
                <div className="title"><FormattedMessage id={i18n} /></div>
            </div>
        );
    }

    sendGMAPSRequests() {

        const DistanceMatrixService = new window.google.maps.DistanceMatrixService();

        const geocoder = new window.google.maps.Geocoder();

        const changestate = (name, value) => {
            this.setState({ [name]: value })
        }
        const address1 = this.props.psychologistICharacteristic.office_address_street + "," + this.props.psychologistICharacteristic.office_address_city + "," + this.props.psychologistICharacteristic.office_address_postcode
        var address1lng;
        var address1lat;
        const address2lng = this.props.substituteCharacteristic ? this.props.substituteCharacteristic.location.coordinates[0] : null
        const address2lat = this.props.substituteCharacteristic ? this.props.substituteCharacteristic.location.coordinates[1] : null

        const that = this;

        geocoder.geocode({ 'address': address1 }, function (results, status) {

            if (status === 'OK') {
                address1lat = results[0].geometry.location.lat()
                address1lng = results[0].geometry.location.lng()

                if (that.state.mapshown === false && that.props.user.role === "substitute") {
                   
                    DistanceMatrixService.getDistanceMatrix({
                         destinations: [{ lat: address1lat, lng: address1lng }],
                         origins: [{ lng: address2lng, lat: address2lat }],
                        travelMode: "DRIVING",
                    }, (result) => {
                        if (result.rows[0].elements[0]) {
                            changestate("driving", result.rows[0].elements[0])
                        }
                    });

                    DistanceMatrixService.getDistanceMatrix({
                         destinations: [{ lat: address1lat, lng: address1lng }],
                         origins: [{ lng: address2lng, lat: address2lat }],
                        travelMode: "BICYCLING",
                    }, (result) => {
                        if (result.rows[0].elements[0]) {
                            changestate("cycling", result.rows[0].elements[0])
                        }
                    });

                    DistanceMatrixService.getDistanceMatrix({
                        destinations: [{ lat: address1lat, lng: address1lng }],
                        origins: [{ lng: address2lng, lat: address2lat }],
                        travelMode: "TRANSIT",
                    }, (result) => {
                        if (result.rows[0].elements[0]) {
                            changestate("transit", result.rows[0].elements[0])
                        }
                    });
                }
                that.setState({ mapshown: true })
            }
        });
        this.setState({ showMap: !this.state.showMap })
    }

    downloadContract(e, agreementId) {
        e.preventDefault();
        e.stopPropagation();

        if (!this.state.disabled) {
            this.setState({ disabled: true });

            this.props.downloadContract(agreementId, () => this.setState({ disabled: false }));
        }
    }

    sendToCouncil(e, agreementId, doctor) {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.disabled) return;

        this.setState({ disabled: true });

        const onConfirm = () => {
            this.props.sendToCouncil(agreementId, () => this.setState({ disabled: false, modal: null }));
            this.closeModal();
        };

        const onCancel = () => {
            this.setState({ disabled: false });
            this.closeModal();
        }

        const content = <FormattedHTMLMessage id="Confirm.Send.To.Council" values={{ name: doctor.first_name }} />

        this.setState({
            modal: <ConfModal
                content={content}
                onConfirm={onConfirm}
                onCancel={() => onCancel()}
                toggle={() => onCancel()} />
        });
    }

    cancelAgreement(e, agreementId) {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.disabled) return;

        this.setState({ disabled: true });

        const onConfirm = () => {
            this.props.cancelAgreement(agreementId, () => {
                this.setState({ disabled: false });
                if (this.props.onCancelCbk) this.props.onCancelCbk();
            });
        };

        const onCancel = () => {
            this.setState({ disabled: false });
            this.closeModal();
        };

        const content = <FormattedHTMLMessage id="Confirm.Cancel.Agreement" />

        this.setState({
            modal: <ConfModal
                content={content}
                onConfirm={onConfirm}
                onCancel={() => onCancel()}
                toggle={() => onCancel()} />
        });
    }

    closeModal() {
        this.setState({ modal: null });
    }

    downloadCertificate(certificateId, token) {
        this.props.onDownloadCertificate(certificateId, token);
    }

    render() {
        const { user, doctor, psychologistICharacteristic, officeTimeTable } = this.props;


        let age = DateUtil.age(doctor.birth_date);

        return (
            <React.Fragment>

                <div className="w-100 text-center mb-4">
                    <img src={ProfileImages.getSrc(doctor.image)} className="d-block mx-auto mb-3"
                        width="100" alt="" style={{ borderRadius: "100px" }} />

                    <div className="">
                        <div className="d-inline-block text-center loc-icon-container-custom" onClick={this.sendGMAPSRequests}>
                            <div className="loc-icon-custom"></div>
                        </div>

                        <div className="d-inline-block align-top">
                            <h4 className="mb-0">{UserEnt.pseudo(doctor)}</h4>
                            {(psychologistICharacteristic && psychologistICharacteristic.address_components && psychologistICharacteristic.address_components.city) && <div>{psychologistICharacteristic.address_components.city}</div>}
                            {age > 18 &&
                                <div>{DateUtil.age(doctor.birth_date)} <FormattedMessage id="Years.Old" /></div>
                            }
                        </div>

                        <div className="d-inline-block ml-3" style={{ width: "40px" }}>
                        </div>
                    </div>

                    <Collapse isOpen={this.state.showMap}>
                        <div className="profile-map">
                            {user.role === "substitute" &&
                                <div>
                                    { this.state.driving && this.state.driving.distance && <> <i className="fas fa-car"></i> ({this.state.driving.distance.text},{this.state.driving.duration.text}) </>}
                                    { this.state.transit && this.state.transit.distance && <> <i className="fas fa-bus"></i> ({this.state.transit.distance.text},{this.state.transit.duration.text}) </>}
                                    { this.state.cycling && this.state.cycling.distance && <> <i className="fas fa-bicycle"></i> ({this.state.cycling.distance.text},{this.state.cycling.duration.text}) </>}
                                </div>
                            }
                            <>
                                {(this.mapComponent) &&
                                    <this.mapComponent
                                        style={{ marginTop: "-10px" }}
                                        isMarkerShown={true}
                                        googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + this.GOOGLE_MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places"}
                                        loadingElement={<div style={{ height: `100%`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                                        containerElement={<div style={{ height: `400px`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                                        mapElement={<div style={{ height: `100%`, borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }} />}
                                    />
                                }
                            </>
                        </div>
                    </Collapse>
                </div>

                {(doctor.role === "psychologist_install") &&
                    <>
                        <Collapse isOpen={(this.state.collapsed === "details")} className="modal-collapse pl-3 pr-3 pl-md-5 pr-md-5 w-100" >
                            <PsyICharacteristicsProfile
                                doctor={doctor}
                                psychologistICharacteristic={psychologistICharacteristic}
                                officeTimeTable={officeTimeTable}
                            />
                        </Collapse>

                    </>
                }
                {this.state.modal}

            </React.Fragment >
        );
    }

    collapse(category) {
        if (this.state.collapsed === category) this.setState({ collapsed: null });
        else this.setState({ collapsed: category });
    }
}

const mapStateToProps = state => {
    return {
        user: state.global.user,
        substituteTransmission: state.global.substituteTransmission,
        substituteCharacteristic: state.global.substituteCharacteristic,
        availabilitysUser: state.global.availabilitysUser,
        availabilitys: state.global.availabilitys,
        retrocessionCertificate: state.global.retrocessionCertificate
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGet: (objName) => dispatch(get(objName)),
        onUpd: (objName, obj, cbk) => dispatch(upd(objName, obj, cbk)),
        onGetBy: (objName, paramNames, params, cbk) => dispatch(getBy(objName, paramNames, params, cbk)),
        onDownloadCertificate: (certificateId, cbk) => downloadCertificate(certificateId, cbk),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PsyIProfile));
