import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import { FormattedMessage } from 'react-intl';
import 'ladda/dist/ladda-themeless.min.css';

export default class PsychiatristAboutProfile extends Component {

    render() {
        const { psychiatristCharacteristic } = this.props;

        return (
            <React.Fragment>

                <div className="">

                    <h5 className="">
                        <b><FormattedMessage id="About" /></b>
                    </h5>

                    <div className="mx-auto  text-left">
                        <p className="mt-0 mb-0">
                            <FormattedMessage id="Sector" />:&nbsp; <b>{psychiatristCharacteristic.sector}</b>
                        </p>

                        <p className="mt-0 mb-0">
                            <FormattedMessage id="Consultations.Per.Week.On.Average.Short" />:&nbsp; <b>{psychiatristCharacteristic.consultations_per_week_on_avg}</b>
                        </p>

                        <p className="mt-0 mb-0">
                            <FormattedMessage id="Visits.Per.Week.On.Average.Short" />:&nbsp; <b>{psychiatristCharacteristic.visits_per_week_on_avg}</b>
                        </p>


                        {(psychiatristCharacteristic.sector === "2") &&
                            <>
                                <p className="mt-0 mb-0">
                                    <FormattedMessage id="Consultation.Price" />:&nbsp; <b>{psychiatristCharacteristic.consultation_price}€</b>
                                </p>

                                <p className="mt-0 mb-0">
                                    <FormattedMessage id="Visit.Price" />:&nbsp; <b>{psychiatristCharacteristic.visit_price}€</b>
                                </p>
                            </>
                        }
                    </div>

                </div>

            </React.Fragment >
        );
    };

}