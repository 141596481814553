import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { resetPwd } from "../../actions/authentication/authentication";
import { Input, Form, Button } from 'reactstrap';
import blueBG from '../../assets/images/login/RIDEAU_BLEU.svg';
import yellowBG from '../../assets/images/login/RIDEAU_JAUNE.svg';
import SimpleMsgModal from '../../components/modal/SimpleMsgModal';


class RecoverPwd extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            error: null,
            disabled: false,
            modal: null,
        };
    }

    resetPwd(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ disabled: true });

        this.props.onResetPwd(
            { email: this.state.email },
            (err) => {

                if (!err) {
                    this.openSuccessModal((' ' + this.state.email).slice(1));
                } else {
                    this.openErrorModal((' ' + this.state.email).slice(1));
                }

                this.setState({ disabled: false, email: "" });
            }
        );
    }

    openSuccessModal(email){
        const content = (
            <div>
                <div><FormattedMessage id="Your.Password.Was.Reset" /></div>
                <div><FormattedMessage id="Reset.Mail.Sent" /> {email}</div>
            </div>
        );

        this.setState({ modal: <SimpleMsgModal content={content} onClose={() => this.closeModal()} /> });
    }

    openErrorModal(email){
        const content = (
            <div>
                <div>Cette adresse email n'existe pas.</div>
                <div>Veuillez vérifier l'adresse email : {email}</div>
            </div>
        );

        this.setState({ modal: <SimpleMsgModal content={content} onClose={() => this.closeModal()} /> });
    }


    closeModal(){
        this.setState({ modal: null }, () => window.location.href = "#/");
    }

    render() {
        return (
            <>
                <div className="container-fluid bg-blue m-0 p-0 min-h-100 w-100 b-fix">

                    <div className="w-100 h-100 position-absolute m-0 p-0 d-none d-xl-block" style={{ top: -2 }}>
                        <img src={blueBG} alt="bg-img" className="h-100" style={{ marginLeft: -220 }} />
                        <img src={yellowBG} alt="bg-img" className="h-100 position-absolute" style={{ right: -220 }} />
                    </div>

                    <div className="position-relative max-w-350px mx-auto text-center" style={{ marginTop: "calc(50vh - 107px)" }}>

                        <div className="border xrounded bg-white text-center p-4 mx-auto mx-md-0">

                            <div className="mt-2 mb-2 text-center">
                                <Form onSubmit={(e) => this.resetPwd(e)}>

                                <h5 className="bold mb-4"><FormattedMessage id="Recover.My.Password" /></h5>

                                <div className="mb-4">
                                    <Input 
                                        type="email"
                                        name="email"
                                        bsSize="md"
                                        id="Name"
                                        placeholder="email"
                                        required
                                        onChange={(e) => this.setState({ email: e.target.value })}
                                        value={this.state.email} />
                                    <div className="text-danger mt-2"><small>{this.state.error}</small></div>
                                </div>

                                <Button
                                    disabled={this.state.disabled}
                                    className="btn bg-blue text-white w-100 srounded pl-5 pr-5 pt-3 pb-3"
                                    size="lg"
                                    block>
                                    <FormattedMessage id="Reset" />
                                </Button>

                                </Form>
                            </div>

                        </div>

                    </div>

                </div>

                {this.state.modal}
            </>
            // <React.Fragment>

            //     <Modal size="sm" isOpen={true}>

            //         <ModalHeader className="border-0" toggle={() => this.props.close()}>
            //         </ModalHeader>

            //         <ModalBody className="p-0">

            //             <div className="">

            //                 <div className=" pl-5 pr-5 pb-4 no-block justify-content-center align-items-center">
            //                     <div className="">

            //                         <h3 className="w-100 text-center mb-4"><FormattedMessage id="Connection" /></h3>

            //                         <Form className="mt-3" onSubmit={(e) => this.onLogin(e)}>

            //                             <InputGroup className="mb-3 ">

            //                                 <InputGroupAddon addonType="prepend">
            //                                     <InputGroupText>
            //                                         <i className="ti-user"></i>
            //                                     </InputGroupText>
            //                                 </InputGroupAddon>

            //                                 <Input
            //                                     type="email"
            //                                     placeholder={this.props.intl.formatMessage({ id: "Email" })}
            //                                     required
            //                                     onChange={(e) => this.setState({ "username": e.target.value, error: null })}
            //                                 />

            //                             </InputGroup>

            //                             <InputGroup className="mb-3 ">

            //                                 <InputGroupAddon addonType="prepend">
            //                                     <InputGroupText>
            //                                         <i className="ti-pencil"></i>
            //                                     </InputGroupText>
            //                                 </InputGroupAddon>

            //                                 <Input
            //                                     disabled={this.state.disabled}
            //                                     type="password"
            //                                     minLength="8"
            //                                     maxLength="40"
            //                                     placeholder={this.props.intl.formatMessage({ id: "Password" })}
            //                                     required onChange={(e) => this.setState({ "password": e.target.value, error: null })}
            //                                 />

            //                             </InputGroup>

            //                             <div className="text-danger"><small>{this.state.error}</small></div>

            //                             <div className="d-flex no-block align-items-center mb-3">
            //                                 <div className="ml-auto">

            //                                     <a
            //                                         href="#/recoverform"
            //                                         id="to-recover"
            //                                         onClick={() => this.setState({ collapse: !this.state.collapse })}
            //                                         className="forgot text-dark float-right "
            //                                     >
            //                                         <i className="fa fa-lock mr-1 shadow" />
            //                                         <FormattedMessage id="Forgot.Password" /> ?
            //                                         </a>

            //                                 </div>
            //                             </div>

            //                             <Row className="mb-3 ">
            //                                 <Col xs="12">

            //                                     <a href="#/dashboard/calendar">
            //                                         <Button
            //                                             className="turquoise-btn"
            //                                             size="lg" block>
            //                                             <FormattedMessage id="Login" />
            //                                         </Button>
            //                                     </a>
            //                                 </Col>
            //                             </Row>

            //                         </Form>


            //                         <Collapse isOpen={this.state.collapse}>

            //                             <hr />

            //                             <div id="recoverform" className="mt-4">

            //                                 <h6 className="mt-2 mb-0"><FormattedMessage id="Recover.password" /></h6>

            //                                 <Row className="mt-2">
            //                                     <Col xs="12">
            //                                         <Form onSubmit={(e) => this.resetPwd(e)}>

            //                                             <FormGroup>

            //                                                 <Input type="email"
            //                                                     name="email"
            //                                                     bsSize="md"
            //                                                     id="Name"
            //                                                     placeholder="email"
            //                                                     required
            //                                                     value={this.state.email}
            //                                                     onChange={(e) => this.setState({ recover_email: e.target.value })} />

            //                                                 <Row className="mt-3">

            //                                                     <Col xs="12">
            //                                                         <Button color="danger" size="lg" type="submit" block>
            //                                                             <FormattedMessage id="Reset" />
            //                                                         </Button>
            //                                                     </Col>

            //                                                 </Row>

            //                                             </FormGroup>

            //                                         </Form>
            //                                     </Col>
            //                                 </Row>

            //                             </div>

            //                         </Collapse>
            //                     </div>
            //                 </div>
            //             </div>

            //         </ModalBody>

            //     </Modal>

            // </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        //
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onResetPwd: (data, callback) => resetPwd(data, callback)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RecoverPwd));