import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import 'react-datetime/css/react-datetime.css';
import SubstituteAcceptHomeVisits from '../../../enum/SubstituteAcceptHomeVisits';
import Retrocession from '../../../enum/Retrocession';

export default class SubstituteInfoProfile extends Component {

    render() {
        const { characteristics } = this.props;

        if (!characteristics.descs || !characteristics.descs.length) return null;

        return (
            <React.Fragment>

                <h5 className="">
                    <b><FormattedMessage id="Extra.infos" /></b>
                </h5>

                <div className="text-left max-w-100">
                    <div><FormattedMessage id="Consultation.Capacity.Per.Week" /> : {characteristics.consult_capacity_per_week}</div>
                    <div><FormattedMessage id="Home.Visits" /> : <FormattedMessage id={SubstituteAcceptHomeVisits.getLabel(characteristics.accept_home_visits)} /></div>
                    <div><FormattedMessage id="Retrocession.Wished.Confort" /> :&nbsp;<FormattedMessage id={Retrocession.getLabel(characteristics.retrocession_wished)} /></div>
                    <div><FormattedMessage id="Year.Of.End.Internship" /> : {characteristics.year_end_internship}</div>
                </div>

            </React.Fragment>
        );
    }
}


